<template>
  <yxt-drawer custom-class="yxtbiz-selectKng"
              size="960px"
              :title="drawerTitle"
              :visible.sync="openPage"
              :before-close="clearData"
              :destroy-on-close="destroyOnClose">
    <div class="yxtbiz-selectKng__top-btn" v-if="isOpenUpload||canSelectLibKng">
      <yxt-button type="primary" class="mr10" @click="selectingNewkng = true">{{$t('pc_biz_kng_btn_selectKng')}}</yxt-button>
      <Upload-kng v-if="isOpenUpload"
                  :disabled="disabledType"
                  :uploadType="kngSelectType"
                  :courseId="courseId"
                  :catalogId="catalogId"
                  @submit="submitKng"></Upload-kng>
    </div>

    <!-- 头部tab如果都设置为false，则不展示选择课程 -->
    <div v-if="IsDisplayAllMenu">
      <!-- 头部menu -->
      <template v-if="!isShowSingle">
        <yxt-tabs v-model="activeIndex" @tab-click="handleSelect" drawer-top>
           <!-- 课程库中的 -->
          <yxt-tab-pane v-if="IsKngMenu" :label="$t('pc_biz_kng_lbl_inKng')" lazy name="1"></yxt-tab-pane>
          <!-- 按次购买的 -->
          <yxt-tab-pane v-if="IsBuyCourseMenu" :label="$t('pc_biz_kng_lbl_buyCourse')" lazy name="2"></yxt-tab-pane>
          <!-- 按项目购买的 -->
          <yxt-tab-pane v-if="IsBuyProjectMenu" :label="$t('pc_biz_kng_lbl_buyProjectCourse')" lazy name="3"></yxt-tab-pane>
        </yxt-tabs>
      </template>

      <div class="yxtbiz-knglist" :class="isShowSingle ? 'yxtbiz-knglist-padding_notop' : 'yxtbiz-knglist-padding'">
        <!-- 知识库目录开始 -->
        <template v-if="IsKngMenu">
          <yxt-row v-show="isKnglib" class="flex-1 yxtbiz-knglist-table over-hidden">
            <div>
              
                <div class="pull-left mr12 mb16">
                  <yxt-button plain class="filter-btn w-128 ph12 pv8" @click.stop="boxVisible = !boxVisible">
                    <yxt-svg
                      width="20px"
                      height="20px"
                      icon-class="filter"
                      class="filter-icon mt1" />
                    <span class="ml25">{{ $t('pc_biz_common_lbl_moreFilter'/* 更多筛选 */) }}</span>
                    <i :class='boxVisible ? "yxt-icon-arrow-up": "yxt-icon-arrow-down"' class="ml3"></i>
                  </yxt-button>
                </div>

                <!-- 关键字搜索 -->
                <div class="d-in-block w200">
                  <yxt-input v-model="kngInput"
                            :placeholder="$t('pc_biz_kng_lbl_keyword'/*请输入关键词搜索*/)"
                            searchable
                            clearable
                            @clear="searchLists(1)"
                            @search="searchLists(1)"></yxt-input>
                </div>

                <!-- 更多筛选box -->
                <div v-if="boxVisible" class="template5-filter-box p24">
                  <div>
                    <div class="d-in-block mr12 mb16" v-if="enableGroupCorp">
                      <yxtbiz-group-org-select
                        :dataPermissionCode="orgDataPermissionCode"
                        :functionCode="orgFunctionCode"
                        :placeholder="$t('pc_biz_kng_lbl_chooseorg')"
                        setCurrentOrg
                        @change="orgChange">
                      </yxtbiz-group-org-select>
                    </div>

                    <div v-if="isCreateTime" class="d-in-block mr12 mb16">
                      <span class="mr12 color-gray-9">{{$t('pc_biz_kng_lbl_createTime')}}</span>
                      <yxt-date-picker
                        v-model="creatTimes"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        @change="handleTimeChane">
                      </yxt-date-picker>
                    </div>
                    
                    <select-popover ref="selectPopover" :selectOpition="selectOpition" class="d-in-block w200 mr12 mb16">
                      <div class="yxtbiz-kng-tree table_tree">
                        <yxt-input v-model="filterKngTree"
                                  :placeholder="$t('pc_biz_kng_lbl_searchCatalog')"
                                  searchable
                                  @search="searchCatalog"></yxt-input>
                        <yxt-tree ref="filterTree"
                                  v-loading="treeLoading"
                                  style="width: 100%;"
                                  class="yxtbiz-biz-tree-funs mt16"
                                  node-key="id"
                                  :empty-text="$t('pc_biz_kng_lbl_noCatalog')"
                                  :class="kngTreeData.length > 0 ? 'd-in-block' : ''"
                                  :data="kngTreeData"
                                  :props="defaultProps"
                                  :filter-node-method="filterNode"
                                  :default-expanded-keys="treeExpand"
                                  :expand-on-click-node="false"
                                  @node-click="ClickTreeNode">
                        </yxt-tree>
                      </div>
                    </select-popover>
                    <!-- 上传环境筛选 -->
                    <div v-if="isShowLocal && env === 2" class="d-in-block mr12 mb16">
                      <yxt-select v-model="envFlag"
                                  class="w144"
                                  :placeholder="$t('pc_biz_kng_lbl_selectEnv')"
                                  clearable>
                        <yxt-option :label="$t('pc_biz_kng_lbl_localCloud')" :value="0"></yxt-option>
                        <yxt-option :label="$t('pc_biz_kng_lbl_publicCloud')" :value="1"></yxt-option>
                      </yxt-select>
                    </div>
                    <template v-if="!disabled">
                      <yxt-select v-model="kngValue"
                                  class="w120 mr12 mb16"
                                  :placeholder="$t('pc_biz_kng_lbl_kngType')"
                                  clearable>
                        <yxt-option v-for="item in options"
                                    :key="item.value + Math.random()"
                                    :label="item.label"
                                    :value="item.value"
                                    :disabled="item.disabled">
                          <span class="font-size-14">{{ item.label }}</span>
                        </yxt-option>
                      </yxt-select>
                    </template>
                    <yxt-select v-if="auditOptions.length"
                                v-model="auditValue"
                                class="w120 mr12 mb16"
                                :placeholder="$t('pc_biz_kng_lbl_allStatus')"
                                clearable>
                      <yxt-option v-for="item in auditOptions"
                                  :key="'audit-' + item.value"
                                  :label="item.label"
                                  :value="item.value">
                        <span class="font-size-14">{{ item.label }}</span>
                      </yxt-option>
                    </yxt-select>
                    
                    <div class="checkbox-filter">
                      <div v-if="isRemoveHide">
                        <yxt-checkbox v-model="kngChecked" class="yui-txt-grey font-size-14">{{$t('pc_biz_kng_lbl_hiddenCourse')}}</yxt-checkbox>
                      </div>
                      <div v-if="isRemoveDelete" class="d-in-block ml36">
                        <yxt-checkbox v-model="deleteChecked" class="yui-txt-grey font-size-14">{{$t('pc_biz_kng_lbl_removeCourse')}}</yxt-checkbox>
                      </div>
                    </div>
                    <!-- <div v-for="(option, index) in moreFilters" :key="index" class="pr24 pb16 d-in-block">
                      <span>{{ option.name }}</span>

                      <yxt-select
                        v-model="option.value"
                        class="ml16 w-136"
                        clearable
                        :placeholder="$t('pc_kng_mgmt_tip_select')"
                      >
                        <yxt-option
                          v-for="item in selectArr('filters', option.id)"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"></yxt-option>
                      </yxt-select>
                    </div> -->

                    <div>
                      <yxt-button type="primary" class="w-auto" @click="filterChange">{{ $t('pc_biz_core_btn_determine'/* 确定 */) }}</yxt-button>
                      <yxt-button plain @click="boxVisible = false">{{ $t('pc_biz_common_cancel_mini'/* 取消 */) }}</yxt-button>
                    </div>
                  </div>
                </div>

            </div>
            <div class="flex-1 flex mt4 h0 pb1">
              <div class="flex-stretch width-percent-100">
                <yxt-table ref="kngTable"
                        :custom-column-props="customColumnProps"
                        v-loading="kngListLoading"
                        height="100%"
                        :data="KngTableData"
                        :row-key="getRowKey"
                        @custom-column-change="handlerColumnChange"
                        @select="singleCancel"
                        @selection-change="selectionChange"
                        @select-all="handleKngSelectAll"
                        @sort-change="kngSortChange">
                <yxt-table-column type="selection"
                                  width="40"
                                  :selectable="isSelectable"
                                  reserve-selection
                                  :show-overflow-tooltip='false'
                                  hide-column="disable"></yxt-table-column>
                <yxt-table-column prop="title" :label="$t('pc_biz_kng_lbl_title')" :min-width="300" hide-column="disable" class-name="cell-title">
                  <template slot-scope="scope">
                    <div class="title-flex">
                      <div class="yxtbiz-knglist-tb-cell">
                        <yxt-svg class="flex-shrink-0 mr4" width="16px" height="20px" :remote-url="kngFileTypeIconUrl" :icon-class="getKngFileTypeIcon(scope.row.fileType)"/>
                      <yxt-tooltip
                        placement="top"
                        open-filter
                        :max-width="300"
                        :content="handlerTitle(scope.row)">
                        <span class="v-mid font-size-14 yxtbiz-knglist-tb-title" :style="handlerTitleColor(scope.row)">
                          {{ handlerTitle(scope.row) }}
                        </span>
                      </yxt-tooltip>
                      <yxt-popover
                        placement="bottom-start"
                        effect="dark"
                        trigger="hover">
                        <span class="popup-text">{{$t('pc_biz_kng_tip_localCloud')}}</span>
                        <yxt-svg
                          v-if="scope.row.envFlag === 0"
                          slot="reference"
                          class="ml8 v-mid flex-shrink-0"
                          width="30px"
                          height="20px"
                          :remote-url="`${$staticBaseUrl}ufd/407a24/kng/pc/svg/PC`"
                          icon-class="private-cloud" />
                      </yxt-popover>
                      <span v-if="scope.row.shareFlag === 2" class="icon-span-share">{{$t('pc_biz_ote_btn_share')}}</span>
                      <yxt-tag v-if="scope.row.effective === 0" class="ml8 flex-shrink-0 mb0" type="danger" size="mini">{{ $t('pc_biz_kng_lbl_invalid'/**未生效 */) }}</yxt-tag>
                    </div>
                  </div>
                  </template>
                </yxt-table-column>
                <yxt-table-column v-if="showUniqueCode"
                                  prop="uniqueCode"
                                  :label="$t('kng_lbl_unique_code'/*平台课程编号*/)"
                                  width="120"
                                  column-key="uniqueCode"
                                  :show-overflow-tooltip="true"
                                  :hide-column="isUniqueCodeChecked"
                                  ></yxt-table-column>
                <yxt-table-column v-if="showCustomCode"
                                  prop="customCode"
                                  :label="$t('kng_lbl_custom_code'/*自定义课程编号*/)"
                                  width="120"
                                  column-key="customCode"
                                  :show-overflow-tooltip="true"
                                  :hide-column="isCustomCodeChecked"
                                  >                                  
                  <template slot-scope="{row}">
                    {{ row.customCode|| '--'}}
                  </template>
                </yxt-table-column>
                <!-- 商城课编号 -->
                <yxt-table-column v-if="!excludeFileType"
                                  :label="$t('pc_biz_kng_lbl_zoCode'/*商城课编号*/)"
                                  width="120"
                                  column-key="zoCode"
                                  :show-overflow-tooltip="true"
                                  hide-column="isZoCodeChecked"
                                  >
                  <template slot-scope="{row}">
                    {{ row.zoCode|| '--'}}
                  </template>
                </yxt-table-column>
                <yxt-table-column v-if="isAudit"
                                  :label="$t('pc_biz_kng_lbl_status')"
                                  width="80"
                                  :show-overflow-tooltip="false"
                                  hide-column="disable">
                  <template slot-scope="{row}">
                    <yxt-tooltip v-if="row.auditStatus === 0"
                      placement="top"
                      :content="$t('pc_biz_kng_tip_unAudit')"
                      :max-width="300"
                      effect="dark">
                      <span class="color-gray-6">{{$t('pc_biz_kng_lbl_unAudit')}}</span>
                    </yxt-tooltip>
                    <span v-else-if="isPublished(row)">{{$t('pc_biz_kng_lbl_published')}}</span>
                    <span v-else>--</span>
                  </template>
                </yxt-table-column>
                <yxt-table-column v-if="isHideScore"
                                  prop="studyScore"
                                  :label="$t('pc_biz_kng_lbl_studyScore')"
                                  width="90"
                                  sortable="custom"
                                  :sort-orders="sortOrders"
                                  hide-column="disable"></yxt-table-column>

                <yxt-table-column prop="studyHours"
                                  :label="$t('pc_biz_kng_lbl_studyHours')"
                                  width="100"
                                  sortable="custom"
                                  :sort-orders="sortOrders"
                                  show-overflow-tooltip
                                  hide-column="disable">
                  <template slot-scope="scope">
                    <span>{{ getMinuteSecondFormat(scope.row.studyHours) }}</span>
                  </template>
                </yxt-table-column>

                <yxt-table-column prop="createFullname"
                                  :label="$t('pc_biz_kng_lbl_createFullname')"
                                  width="100"
                                  :sortable="'custom'"
                                  :sort-orders="sortOrders"
                                  :show-overflow-tooltip="false"
                                  hide-column="disable">
                  <template slot-scope="scope">
                    <yxt-tooltip
                      open-filter
                      placement="top"
                      :max-width="300"
                      effect="dark">
                      <div class="ellipsis">
                        <yxtbiz-user-name :name="scope.row.createFullname" />
                      </div>
                      <div slot="content">
                        <yxtbiz-user-name :name="scope.row.createFullname" />
                      </div>
                    </yxt-tooltip>
                  </template>
                </yxt-table-column>

                <yxt-table-column prop="expirationDate"
                                  fixed="right"
                                  :label="$t('pc_biz_kng_lbl_expirationDate')"
                                  width="130"
                                  sortable="custom"
                                  :sort-orders="sortOrders"
                                  hide-column="disable">
                  <template slot-scope="scope">
                    <span>{{ !scope.row.expirationDate ? '--' : scope.row.expirationDate.slice(0, 10) }}</span>
                  </template>
                </yxt-table-column>
              </yxt-table>
              </div>
            </div>
            <div class="mt16 flex-mid h28">
              <yxt-button type="text" :loading="selectAllBtnLoading" :disabled="!KngTableData.length" @click="selectAll">
                {{$t('pc_biz_kng_selectall'/** 筛选全部结果 */)}}
              </yxt-button>
              <div class="flex-1"></div>
              <yxt-pagination v-if="totalKng > 0" 
                              :total="totalKng"
                              :page-size.sync="perKngPage"
                              :current-page.sync="currentKngPage"
                              :pager-count="5"
                              show-on-single-page
                              layout="total, prev, pager, next, sizes"
                              @current-change="handleKngCurrentChange"
                              @size-change="handleKngSizeChange">
              </yxt-pagination>
            </div>
          </yxt-row>
        </template>
        <!-- 知识库目录结束 -->

        <!-- 按次购买的课程开始 -->
        <template v-if="IsBuyCourseMenu">
          <div v-show="isbuyCourse" class="flex-1 yxtbiz-knglist-table over-hidden">
            <div class="flex-mid">
              <div class="d-in-block mr12" v-if="enableGroupCorp">
                  <yxtbiz-group-org-select
                    :dataPermissionCode="orgDataPermissionCode"
                    :functionCode="orgFunctionCode"
                    :placeholder="$t('pc_biz_kng_lbl_chooseorg')"
                    setCurrentOrg
                    @change="orgChange">
                  </yxtbiz-group-org-select>
                </div>
              <div class="d-in-block w288">
                <yxt-input v-model="buyInput"
                          :placeholder="$t('pc_biz_kng_lbl_kngTitle')"
                          searchable
                          clearable
                          @clear="searchLists(2)"
                          @search="searchLists(2)"></yxt-input>
              </div>
            </div>
            <div class="flex-1 flex mt16 h0 pb1">
              <div class="flex-stretch width-percent-100">
                <yxt-table ref="buyTable"
                           height="100%"
                           v-loading="buyListLoading"
                           :data="buyTableData"
                           :row-key="getRowKey"
                           :default-sort = "{prop: 'modifiedDate', order: 'descending'}"
                           @select="singleCancel"
                           @selection-change="selectionBuyChange"
                           @select-all="handleBuySelectAll"
                           @sort-change="buySortChange">
                  <yxt-table-column type="selection"
                                    width="40"
                                    reserve-selection
                                    :show-overflow-tooltip='false'></yxt-table-column>

                  <yxt-table-column prop="title" :label="$t('pc_biz_kng_lbl_title')" :min-width="300" class-name="cell-title">
                    <template slot-scope="scope">
                      <div class="title-flex">
                        <div class="yxtbiz-knglist-tb-cell">
                        <yxt-svg class="flex-shrink-0 mr4" width="16px" height="20px" :remote-url="kngFileTypeIconUrl" :icon-class="getKngFileTypeIcon(scope.row.fileType)"/>
                          <yxt-tooltip placement="top"
                                      open-filter 
                                      :max-width="300"
                                      :content="scope.row.title">
                            <span class="v-mid font-size-14 yxtbiz-knglist-tb-title">{{ scope.row.title }}</span>
                          </yxt-tooltip>
                          <span v-if="scope.row.shareFlag === 2" class="icon-span-share">{{$t('pc_biz_ote_btn_share')}}</span>
                          <yxt-tag v-if="scope.row.effective === 0" class="ml8 flex-shrink-0 mb0"  type="danger" size="mini">{{ $t('pc_biz_kng_lbl_invalid'/**未生效 */) }}</yxt-tag>
                        </div>
                      </div>
                    </template>
                  </yxt-table-column>

                  <!-- 商城课编号 -->
                  <yxt-table-column width="120"
                                    :label="$t('pc_biz_kng_lbl_zoCode')"
                                    :show-overflow-tooltip="true">                                  
                    <template slot-scope="scope">
                      {{ scope.row.zoCode|| '--'}}
                    </template>
                  </yxt-table-column>

                  <yxt-table-column prop="studyHours" width="120" :label="$t('pc_biz_kng_lbl_studyHours')">
                    <template slot-scope="scope">
                      <span>{{ getMinuteSecondFormat(scope.row.studyHours) }}</span>
                    </template>
                  </yxt-table-column>

                  <yxt-table-column prop="orderCount"
                                    width="120"
                                    :label="$t('pc_biz_kng_lbl_orderCount')"
                                    sortable="custom"
                                    :sort-orders="sortOrders"></yxt-table-column>

                  <yxt-table-column prop="avlCount"
                                    width="140"
                                    :label="$t('pc_biz_kng_lbl_avlCount')"
                                    sortable="custom"
                                    :sort-orders="sortOrders"></yxt-table-column>
                </yxt-table>
              </div>
            </div>
            <div class="mt16 flex-mid h28">
              <yxt-button type="text" :loading="selectAllBtnLoading" :disabled="!buyTableData.length" @click="selectAll">
                {{$t('pc_biz_kng_selectall'/** 筛选全部结果 */)}}
              </yxt-button>
              <div class="flex-1"></div>
              <yxt-pagination v-if="totalBuy > perBuyPage" 
                              class="pull-right"
                              :total="totalBuy"
                              :pager-count="5"
                              :page-size="perBuyPage"
                              :current-page.sync="currentBuyPage"
                              layout="total, prev, pager, next"
                              @current-change="handleBuyCurrentChange">
              </yxt-pagination>
            </div>
          </div>
        </template>
        <!-- 按次购买的课程结束 -->

        <!-- 按项目购买的课程开始 -->
        <template v-if="IsBuyProjectMenu">
          <div v-show="isbuyProjectCourse" class="flex-1 yxtbiz-knglist-table over-hidden">
            <div>
              <div class="d-in-block w288">
                <yxt-input v-model="buyProjectInput"
                          :placeholder="$t('pc_biz_kng_lbl_kngTitle')"
                          searchable
                          clearable
                          @clear="searchLists(3)"
                          @search="searchLists(3)"></yxt-input>
              </div>
            </div>
            <div class="flex-1 flex mt16 h0 pb1">
              <div class="flex-stretch width-percent-100">
                <yxt-table ref="buyProjectTable"
                          v-loading="buyProjectListLoading"
                          :data="buyProjectTableData"
                          :row-key="getRowKey"
                          height="100%"
                          @select="singleCancel"
                          @selection-change="selectionBuyProjectChange"
                          @select-all="handleBuyProjectSelectAll"
                          >
                  <yxt-table-column type="selection"
                                    width="40"
                                    reserve-selection
                                    :show-overflow-tooltip='false'></yxt-table-column>

                  <yxt-table-column prop="prodName" :label="$t('pc_biz_kng_lbl_title')" :min-width="300" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="flex">
                        <div class="yxtbiz-knglist-tb-cell">
                          <yxt-svg class="flex-shrink-0 mr4" width="16px" height="20px" :remote-url="kngFileTypeIconUrl" :icon-class="getKngFileTypeIcon(scope.row.fileType)"/>
                          <yxt-tooltip placement="top"
                                      open-filter 
                                      :max-width="300"
                                      :content="scope.row.prodName">
                            <span class="v-mid font-size-14 yxtbiz-knglist-tb-title" style="color: rgba(0,0,0,0.65);">{{ scope.row.prodName }}</span>
                          </yxt-tooltip>
                          <yxt-tag v-if="scope.row.effective === 0" class="ml8 flex-shrink-0 mb0"  type="danger" size="mini">{{ $t('pc_biz_kng_lbl_invalid'/**未生效 */) }}</yxt-tag>
                        </div>
                      </div>
                      <!-- <span v-if="scope.row.shareFlag" class="icon-span-share">{{ $t('pc_biz_ote_btn_share') }}</span> -->
                    </template>
                  </yxt-table-column>

                  <yxt-table-column prop="studyHours" width="150" :label="$t('pc_biz_kng_lbl_studyHours')">
                    <template slot-scope="scope">
                      <span>{{ getMinuteSecondFormat(scope.row.studyHours) }}</span>
                    </template>
                  </yxt-table-column>
                  <yxt-table-column v-if="!middlePlatform"
                                    prop="orderCount"
                                    width="150"
                                    :label="$t('pc_biz_kng_lbl_IssueCount')">
                    <template slot-scope="scope">
                      <span>{{ scope.row.buyOut ? $t('pc_biz_kng_lbl_buyout') : scope.row.orderCount }}</span>
                    </template>
                  </yxt-table-column>
                  <yxt-table-column v-if="!middlePlatform"
                                    prop="avlCount"
                                    width="140"
                                    :label="$t('pc_biz_kng_lbl_avlCount')">
                    <template slot-scope="scope">
                      <span>{{ scope.row.buyOut ? $t('pc_biz_kng_lbl_buyout') : scope.row.avlCount }}</span>
                    </template>
                  </yxt-table-column>
                </yxt-table>
              </div>
            </div>
            <div class="mt16 flex-mid h28">
              <yxt-button type="text" :loading="selectAllBtnLoading" :disabled="!buyProjectTableData.length" @click="selectAll">
                {{$t('pc_biz_kng_selectall'/** 筛选全部结果 */)}}
              </yxt-button>
              <div class="flex-1"></div>
              <yxt-pagination v-if="totalBuyProject > perBuyPage" class="pull-right"
                              :total="totalBuyProject"
                              :page-size="perBuyProjectPage"
                              :pager-count="5"
                              :current-page.sync="currentBuyProjectPage"
                              layout="total, prev, pager, next"
                              @current-change="handleBuyProjectCurrentChange">
              </yxt-pagination>
            </div>
          </div>
        </template>
        <!-- 按项目购买的课程结束 -->

        <div class="yxtbiz-selectKng__side">
          <div v-if="maxNum">
            <span>{{$t('pc_biz_kng_lbl_selected')}}</span>
            <span>{{ checkIdLists.length }}</span>
            <span> / </span>
            <span>{{ maxNum }}</span>
          </div>
          <div v-else><span>{{$t('pc_biz_kng_lbl_selectedCount', [checkIdLists.length])}}</span></div>
          <div class="yxtbiz-selectKng__side--bottom mt12 flex">
            <div class="yxtbiz-selectKng__scroll-wrap">
              <yxtbiz-virtual-list class="yxtbiz-hp100" is-scrollbar :estimate-size="34" :keeps="50" :data-sources="checkIdLists" data-key="id" > 
                <div slot-scope="{source}">
                    <yxt-tag type="info"
                             closable
                             @close="closeKng(source)">
                        <yxt-tooltip :content="source.title" open-filter>
                          <span class="kng-tag-size">{{ source.title }}</span>
                        </yxt-tooltip>
                    </yxt-tag>
                  </div>
              </yxtbiz-virtual-list>
              <!-- <yxt-scrollbar :fit-height="true">
                  <div v-for="tag in checkIdLists" :key="tag.id">
                    <yxt-tag type="info"
                             closable
                             @close="closeKng(tag)">
                        <yxt-tooltip :content="tag.title" open-filter>
                          <span class="kng-tag-size">{{ tag.title }}</span>
                        </yxt-tooltip>
                    </yxt-tag>
                  </div>
              </yxt-scrollbar> -->
            </div>
          </div>
          <div class="mt12">
            <yxt-button :disabled="checkIdLists.length === 0"
                      type="text"
                      @click="clearAllSelection">
            {{$t('pc_biz_kng_btn_clearAll')}}
          </yxt-button>
          </div>
        </div>

      </div>
    </div>
    <!-- 不展示所有tab页的提示的信息 -->
    <div v-else class="yui-txt-sub h100">
      <main class="flex-center h100">
        <yxt-empty :empty-text="$t('pc_biz_kng_lbl_noKng')"/>
      </main>
    </div>

    <yxt-drawer
      :title="$t('pc_biz_kng_btn_selectKng')"
      destroy-on-close
      v-if="canSelectLibKng"
      :visible.sync="selectingNewkng"
      size="960px"
      :before-close="(done) => { newKngs = []; done(); }"
      :direction="'rtl'">
      <yxtbiz-select-newkng :zip="false" v-model="newKngs" :max="100"></yxtbiz-select-newkng>
      <div slot="footer">
        <yxt-button plain @click="cancleNewkngSelect()">{{$t('pc_biz_kng_btn_cancel')}}</yxt-button>
        <yxt-button type="primary" @click="saveNewkngSelect()">{{$t('pc_biz_kng_btn_ok')}}</yxt-button>
      </div>
    </yxt-drawer>

    <div slot="footer">
      <yxt-button plain @click="clearData">{{$t('pc_biz_kng_btn_cancel')}}</yxt-button>
      <yxt-button type="primary" style="margin-left: 10px;" @click="completeDialog">{{$t('pc_biz_kng_btn_ok')}}</yxt-button>
    </div>
  </yxt-drawer>
</template>


<script>
import { publicRemoveRepeat, getMinuteSecondFormat, isUndefined, getKngFileTypeIcon, kngFileTypeIconUrl } from './utils';
import YxtbizSelectNewkng from 'yxt-biz-pc/packages/select-newkng';
import YxtbizVirtualList from 'yxt-biz-pc/packages/virtual-list';
import SelectPopover from '../../check-person-range/src/components/SelectPopover';
import UploadKng from './component/Upload';
import api from './service.js';
import { Tabs, TabPane, Drawer, Empty, Scrollbar, Table, TableColumn, Tree, Select, Option, Pagination, Tag, Tooltip } from 'yxt-pc';
import { enmuFunctions } from '../../function-points';
import commonUtil from '../../common-util';

export default {
  name: 'YxtbizSelectKng',
  components: {
    YxtTabs: Tabs,
    YxtTabPane: TabPane,
    YxtDrawer: Drawer,
    YxtEmpty: Empty,
    YxtScrollbar: Scrollbar,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtTree: Tree,
    YxtSelect: Select,
    YxtOption: Option,
    YxtPagination: Pagination,
    YxtTag: Tag,
    YxtTooltip: Tooltip,
    SelectPopover,
    UploadKng,
    YxtbizSelectNewkng,
    YxtbizVirtualList
  },
  props: {
    // 最大选择知识数量
    maxNum: {
      type: Number,
      default: 0
    },
    // 需要排除的文件类型，当前有效值为 course ---默认不排除课程
    excludeFileType: {
      type: Boolean,
      default: false
    },
    // 是否展示知识库目录menu
    IsKngMenu: {
      type: Boolean,
      default: true
    },
    // 是否展示按次购买的课程menu
    IsBuyCourseMenu: {
      type: Boolean,
      default: true
    },
    // 是否展示按项目购买的课程menu
    IsBuyProjectMenu: {
      type: Boolean,
      default: false // 为了兼容其他项目引用不需要修改
    },
    // SPU商品Code
    spuCode: {
      type: String,
      default: ''
    },
    // SPU版本
    spuVersion: {
      type: String,
      default: ''
    },
    // 项目id
    projectId: {
      type: String,
      default: ''
    },
    // 机构id
    orgId: {
      type: String,
      default: ''
    },
    // 是否是中间平台
    middlePlatform: {
      type: Boolean,
      default: false
    },
    // sku的code集合
    skuCodeList: {
      type: Array,
      default: () => []
    },
    // 默认是否打开
    openPage: {
      type: Boolean,
      default: false
    },
    // 传入的数组
    beforeKng: {
      type: Array,
      default: () => []
    },
    // 默认搜索的知识类型
    kngSelectType: {
      type: Number,
      default: 999
    },
    // 是否需要禁用select框
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否需要排除zip
    isRemoveZip: {
      type: Boolean,
      default: true
    },
    isRemoveHide: {
      type: Boolean,
      default: true
    },
    isRemoveDelete: {
      type: Boolean,
      default: false
    },
    pmType: {
      type: Number,
      default: 0
    },
    isOpenUpload: {
      type: Boolean,
      default: false
    },
    isHideScore: {
      type: Boolean,
      default: true
    },
    destroyOnClose: {
      type: Boolean,
      default: false
    },
    isNoNeedAuth: {
      type: Boolean,
      default: false
    },
    isAudit: { // 是否需要筛选审核状态
      type: Boolean,
      default: false
    },
    isCreateTime: { // 是否需要筛选创建时间
      type: Boolean,
      default: false
    },
    catalogId: {
      type: String,
      default: ''
    },
    courseId: {
      type: String,
      default: ''
    },
    // 是否可以选新知识库的知识
    canSelectLibKng: {
      type: Boolean,
      default: false
    },
    collegeId: {
      type: String,
      default: ''
    },
    filterClosedCollegeData: {
      type: Boolean,
      default: false
    },
    groupSelect: { // 集团版场景，跨机构选择
      type: Boolean,
      default: false
    },
    orgDataPermissionCode: { // 集团机构权限code
      type: String,
      default: ''
    },
    orgFunctionCode: { // 集团版页面导航code
      type: String,
      default: ''
    },
    showAllBuyCount: {
      type: Boolean,
      default: false
    },
    disabledRowIds: {
      type: Array,
      default: () => []
    },
    env: {
      type: Number,
      default: 2
    },
    includeTypes: {
      type: Array,
      default: () => []
    },
    needKngList: {
      type: Boolean,
      default: false
    },
    sourceType: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      kngFileTypeIconUrl,
      isUniqueCodeChecked: false,
      isZoCodeChecked: false,
      isCustomCodeChecked: false,
      showUniqueCode: false,
      showCustomCode: false,
      customColumnProps: {
        enable: true,
        showDiasbledAttributes: true
      },
      getMinuteSecondFormat,
      selectOpition: {
        placeholder: this.$t('pc_biz_kng_lbl_selectCatalog'),
        checkWord: ''
      },
      activeIndex: '1',
      selectingNewkng: false,
      newKngs: [],
      sortOrders: ['desc', 'asc', null],
      // 目录参数
      treeLoading: true,
      kngTreeData: [],
      filterKngTree: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      kngTreeId: '', // 左侧知识树点击时候保存的id
      // table参数
      options: this.getKngOptions(),
      kngValue: this.kngSelectType === 999 ? '' : this.kngSelectType,
      kngInput: '',
      kngChecked: true, // 隐藏知识
      deleteChecked: false, // 删除的知识
      auditValue: null,
      localSwithEnable: false, // 是否开启私有云部署
      envFlag: null, // 0:私有云, 1:公有云

      isKnglib: true, // 是否显示当前知识库目录的页卡
      selectionKng: [], // 选中的知识库目录知识
      kngListLoading: true,
      KngTableData: [],
      // 分页数据
      totalKng: 0,
      perKngPage: 20,
      currentKng: 1,
      currentKngPage: 1,
      kngSort: '',
      kngSortType: '',
      kngIndex: 1,
      kngSelectIndex: 1,
      // 知识库结束
      // 按次购买的课程开始
      buyInput: '',
      isbuyCourse: false, // 是否显示当前购买的课程的页卡
      selectionBuy: [], // 选中的购买的课程知识
      buyListLoading: true,
      buyTableData: [],
      // 分页数据
      totalBuy: 0,
      perBuyPage: 20,
      currentBuy: 1,
      currentBuyPage: 1,
      buySort: '',
      buySortType: '',
      buyIndex: 1,
      buySelectIndex: 1,
      // 按次购买的课程结束

      // 按项目购买的课程开始
      buyProjectInput: '',
      isbuyProjectCourse: false, // 是否显示按项目购买的课程的页卡
      selectionBuyProject: [], // 选中的购买的课程知识
      buyProjectListLoading: true,
      buyProjectTableData: [],
      // 分页数据
      totalBuyProject: 0,
      perBuyProjectPage: 20,
      currentBuyProject: 1,
      currentBuyProjectPage: 1,
      buyProjectIndex: 1,
      buyProjectSelectIndex: 1,
      // 按项目购买的课程结束

      passKngLists: [],
      disabledType: this.disabled,
      collegeIdSearch: this.collegeId,
      treeExpand: [''],
      auditOptions: [], // 审核选项
      creatTimes: [], // 创建时间
      selectAllBtnLoading: false,
      boxVisible: false // 更多筛选下拉框
    };
  },
  computed: {
    enableGroupCorp() {
      return this.$store.state.navManageStore.groupCorpInfo.enableGroupCorp && this.groupSelect;
    },
    IsDisplayAllMenu() {
      return this.IsKngMenu || this.IsBuyCourseMenu || this.IsBuyProjectMenu;
    },
    checkIdLists() {
      return this.checkKngLists.map(list => {
        return {
          ...list,
          photoUrl: list.coverUrl || '',
          type: parseInt(list.type, 10),
          id: list.kngId ? list.kngId : list.id
        };
      });
    },
    checkKngLists() {
      let kngList = [];
      let buyList = [];
      let buyProjectList = [];
      kngList = this.selectionKng.map(item => {
        item.sign = 0;
        return item;
      });

      buyList = this.selectionBuy.map(item => {
        item.sign = 1;
        // item.type = '0';
        return item;
      });
      buyProjectList = this.selectionBuyProject.map(item => {
        item.sign = 1;
        // item.type = '0';
        return item;
      });

      return publicRemoveRepeat([...this.passKngLists, ...kngList, ...buyList, ...buyProjectList]);
    },
    isShowSingle() {
      return (Boolean(this.IsKngMenu) + Boolean(this.IsBuyCourseMenu) + Boolean(this.IsBuyProjectMenu)) === 1;
    },
    drawerTitle() {
      if (this.isShowSingle) {
        if (this.IsKngMenu) {
          return this.$t('pc_biz_kng_lbl_inKng');
        }
        if (this.IsBuyCourseMenu) {
          return this.$t('pc_biz_kng_lbl_buyCourse');
        }
        if (this.IsBuyProjectMenu) {
          return this.$t('pc_biz_kng_lbl_buyProjectCourse');
        }
      }
    },
    isShowLocal() { // 是否显示私有云筛选
      // 课程包 不需要筛选环境
      return this.localSwithEnable && this.kngSelectType !== 0;
    }
  },
  async created() {
    this.getCustomAttributeKeyName();
    this.getActiveIndex();
    // 由于M1压缩包只能在PC端下载，M1默认压缩包类型隐藏
    // if (!this.isRemoveZip) {
    //   let obj = {value: 7, label: '压缩包'};
    //   this.options.push(obj);
    // }
    if (this.isAudit) {
      if (await this.getAuditEnable()) {
        this.auditOptions.push({
          value: 2,
          label: this.$t('pc_biz_kng_lbl_unAudit')
        });
      }
      this.auditOptions.push({
        value: 1,
        label: this.$t('pc_biz_kng_lbl_published')
      });
    }
    if (this.kngSelectType === 0) {
      this.disabledType = false;
    }
    this.checkLocalInfo();
  },
  methods: {
    getKngFileTypeIcon,
    // 更多筛选点击确认
    filterChange() {
      console.log('orgChange handleTimeChane ClickTreeNode envFlag kngValue auditValue kngChecked deleteChecked');
      this.boxVisible = false;
      this.searchLists(1);
    },
    // 当展示列发生改变的时候保存到后端
    handlerColumnChange(data) {
      api.postCustomAttributeCheckedKey({
        value: [
          data.uniqueCode && data.uniqueCode.hide ? '' : '9',
          data.customCode && data.customCode.hide ? '' : '12',
          data.zoCode && data.zoCode.hide ? '' : '10'
        ],
        type: 1
      });
    },
    getCustomAttributeKeyName() {
      api.getCustomAttributeKeyName().then(res => {
        // 是否显示编号列
        this.showUniqueCode = !res.codeDisabled;
        // 是否勾选编号列
        this.isUniqueCodeChecked = !res.value.includes('9');
        // 是否勾选编号列
        this.isZoCodeChecked = !res.value.includes('10');
        // 是否勾选自定义编号
        this.isCustomCodeChecked = !res.value.includes('12');
      });

      api.getOrgpParameter('custom_kng_code', window.localStorage.orgId).then(res=>{
        this.showCustomCode = res.value === '1';
      });
    },
    checkLocalInfo() {
      api.getLocalConfig().then(res => {
        this.localSwithEnable = !!res.localSwith;
      });
    },
    getKngOptions() {
      let options = [
        { value: 0, label: this.$t('pc_biz_kng_lbl_course') }, // 课程包
        { value: 1, label: this.$t('pc_biz_knglib_lbl_doc') }, // 文档
        { value: 2, label: this.$t('pc_biz_knglib_lbl_video') }, // 视频
        { value: 3, label: this.$t('pc_biz_knglib_lbl_audio') }, // 音频
        { value: 4, label: this.$t('pc_biz_knglib_lbl_weike') }, // 微课
        { value: 5, label: 'SCORM' },
        { value: 6, label: 'HTML' }
      ];
      // 排除课程包
      if (this.excludeFileType) {
        options = options.filter(item => item.value !== 0);
      }

      // 处理外链
      const newLinkCode = commonUtil.checkTimeOutFnc(enmuFunctions.SHOW_NEWLINK);
      // functionValue >= 2 已购买, 过期不会影响展示
      if (newLinkCode >= 2) {
        options.push({ value: 8, label: this.$t('pc_biz_knglib_lbl_newlink')});
      }

      // 处理隐藏【微课】
      const showNewWeike = commonUtil.checkTimeOutFnc(enmuFunctions.SHOW_NEW_WEIKE);
      const showOldWeike = commonUtil.checkTimeOutFnc(enmuFunctions.SHOW_OLD_WEIKE);
      showNewWeike !== 2 && showOldWeike !== 2 && options.splice(3, 1);

      // 根据includeTypes 来过滤选项
      if (this.includeTypes.length > 0) {
        options = options.filter(item => this.includeTypes.includes(item.value));
      }

      return options;
    },
    getAuditEnable() {
      return new window.Promise(resolve => {
        api.getAuditConf().then(res => {
          resolve(~~res.enabled === 1);
        }).catch(() => {
          resolve(false);
        });
      });
    },
    isPublished(row) {
      return row.auditStatus === 1 || row.auditStatus === null;
    },
    isSelectable(row) {
      return this.isPublished(row) && !this.disabledRowIds.includes(row.id);
    },
    async saveNewkngSelect() {
      if (!this.newKngs || this.newKngs.length === 0) {
        this.$message.error(this.$t('pc_biz_kng_tip_pleaseSelect'));
        return;
      }
      // 选择知识加入知识库后，刷新抽屉数据的方法
      window.selectedKngsEnd = () => {
        this.activeIndex = '1';
        this.kngInput = '';
        this.kngTreeId = '';
        this.selectOpition.checkWord = '';
        this.$refs.filterTree && this.$refs.filterTree.setCurrentKey('');
        if (this.kngIndex === 1 || this.kngSelectIndex === 1) {
          this.kngIndex = this.kngSelectIndex = 2;
        }
        if (this.kngValue !== '') {
          this.kngValue = '';
        }
        this.searchLists(1);
        window.selectedKngsEnd = undefined;
      };

      let useNew = false;
      try {
        const res = await api.getOrgpParameter('KngNewUploader', localStorage.orgId);
        useNew = res && res.value === '1';
      } catch (e) {}
      if (useNew) {
        const tempKey = `kng_temp_${new Date().getTime()}`;
        localStorage.setItem(tempKey, JSON.stringify(this.newKngs));
        const path = this.collegeId ? '/kng/#/college/operator/public' : '/kng/#/courseware/operator/public';
        window.open(`${path}?collegeId=${this.collegeId || ''}&tempKey=${tempKey}&from=ulcd`);
      } else {
        window.localStorage['kng_selectedKngs'] = JSON.stringify(this.newKngs);
        window.open(`/kng/#${ this.collegeId ? 'college' : '' }/back/upload?fs=1&fsulcd=1&collegeId=${this.collegeId}`);
      }
      this.newKngs = [];
      this.selectingNewkng = false;
    },
    cancleNewkngSelect() {
      this.newKngs = [];
      this.selectingNewkng = false;
    },
    cancelAndCheckBeforeKng(data, ref) {
      if (this.maxNum && data.length > 0) {
        this.$nextTick(() => {
          ref && ref.clearSelection();
        });

        this.selectedTrue(data, ref);
      }
    },
    // 切换头部导航
    handleSelect(tab) {
      switch (tab.name) {
        case '1':
          this.isKnglib = true;
          this.isbuyCourse = false;
          this.isbuyProjectCourse = false;
          if (this.kngIndex === 1 || this.kngSelectIndex === 1) {
            this.getKngTree();
            this.getKngLists(this.kngTreeId, this.kngSort, this.kngSortType);
            this.kngIndex = this.kngSelectIndex = 2;
          }
          break;
        case '2':
          this.isKnglib = false;
          this.isbuyCourse = true;
          this.isbuyProjectCourse = false;
          if (this.buyIndex === 1 || this.buySelectIndex === 1) {
            this.getBuyLists(this.buySort, this.buySortType);
            this.buyIndex = this.buySelectIndex = 2;
          }
          break;
        case '3':
          this.isKnglib = false;
          this.isbuyCourse = false;
          this.isbuyProjectCourse = true;
          if (this.buyProjectIndex === 1 || this.buyProjectSelectIndex === 1) {
            this.getBuyProjectLists();
            this.buyProjectIndex = this.buyProjectSelectIndex = 2;
          }
          break;
      }
    },
    searchCatalog(value) {
      this.$refs.filterTree && this.$refs.filterTree.filter(value);
    },
    // 知识库目录树筛选的方法
    filterNode(value, data) {
      if (!value) return true;
      let name = data.label.toLowerCase();
      let cValue = value.toLowerCase();
      return name.indexOf(cValue) !== -1;
    },
    // 获取知识库目录左侧的树
    async getKngTree() {
      this.treeLoading = true;

      await commonUtil.preCheckFunctions(enmuFunctions);
      const val = commonUtil.checkTimeOutFnc(enmuFunctions.ONLINE_ClASS_SELECT_CATALOG);

      let interfaceName = this.isNoNeedAuth ? api.getKngTreeReport({ pmType: 1, collegeId: this.collegeIdSearch }) : api.getKngTree({ pmType: this.pmType, collegeId: this.collegeIdSearch, filter: this.filterClosedCollegeData ? 1 : 0 });
      // 如果显示平台选择框
      if (this.enableGroupCorp) {
        interfaceName = api.getGroupKngTree(this.orgId, {collegeId: this.collegeIdSearch});
      }
      interfaceName.then(res => {
        let all = res;
        // 如果未增购企课堂，只显示主学院，如果传参要求显示禁用课堂就不再过滤
        if (val !== undefined && !(val >= 2 && val <= 4) && !this.isNoNeedAuth) {
          all = res.filter(item => {
            return item.mainCollege;
          });
          this.collegeIdSearch = all[0].collegeId;
        }
        this.treeLoading = false;
        this.kngTreeData = all;
        if (all.length === 1) {
          this.$nextTick(() => {
            this.treeExpand = [all[0].id];
          });
        }
      }).catch(err => {
        this.treeLoading = false;
        err.error.message && this.$message.error(err.error.message);
      });
    },
    handlerTitle(row) {
      if (!row.visiable) {
        return row.title + this.$t('pc_biz_kng_lbl_hidden');
      }

      if (row.deleted) {
        return row.title + this.$t('pc_biz_kng_lbl_remove');
      }

      return row.title;
    },
    handlerTitleColor(row) {
      if (!row.visiable || row.deleted) {
        return 'color:rgba(153, 153, 153, 0.65);';
      }

      return 'color: rgba(0, 0, 0, 0.65);';
    },
    // 点击左侧树的节点的事件
    ClickTreeNode(obj, node, _this) {
      if (obj.id === obj.collegeId) {
        // 选择的学院
        this.kngTreeId = '';
      } else {
        this.kngTreeId = obj.id;
      }
      this.collegeIdSearch = obj.collegeId;
      this.$refs['selectPopover'].cancel();
      this.selectOpition.checkWord = obj.id ? obj.label : '';
      // this.getKngLists(this.kngTreeId, this.kngSort, this.kngSortType);
    },
    // 知识库目录的列表知识
    async getKngLists(kngTreeId, sort, sortType) {
      this.kngListLoading = true;
      // 在ulcd使用时url上会有 app=来源 的字段，
      // 如果是o2o来的，在增购 中欧课程模块 后只显示商城购买课程，
      await commonUtil.preCheckFunctions(enmuFunctions);

      let code = '';
      const val = commonUtil.checkTimeOutFnc(enmuFunctions.O2O_TRAIN_TASK_CEIBS);
      if (val !== undefined && val >= 2 && val <= 4) {
        const hash = window.location.hash;
        if (hash) {
          code = hash.match(/app=[^&*]+/) && hash.match(/app=[^&*]+/)[0];
          code = code && code.split('=');
          if (Array.isArray(code) && code[1]) {
            code = code[1];
          }
        }
      }
      let linkParams = this.getLinkParams(1, isUndefined(sort), isUndefined(sortType));
      let bodyParams = this.getBodyParams(this.kngInput.trim(), this.kngValue, this.kngChecked, kngTreeId);
      bodyParams.kngCatalogId = isUndefined(kngTreeId);
      bodyParams.collegeId = this.collegeIdSearch;
      if (this.sourceType !== undefined) {
        bodyParams.sourceType = this.sourceType;
      }
      if (code === 'o2o') {
        bodyParams.sourceType = 2;
      }
      if (!this.isRemoveHide) {
        bodyParams.isShowHidden = 0;
      }
      bodyParams.excludeFileType = this.excludeType();
      if (this.enableGroupCorp) {
        bodyParams.orgId = this.orgId;
      }
      if (!this.isNoNeedAuth) {
        bodyParams.filter = this.filterClosedCollegeData ? 1 : 0;
      }
      bodyParams.needKngList = this.needKngList ? 1 : 0;
      this.bodyParams1 = bodyParams;
      const interfaceName = this.isNoNeedAuth ? api.postChoosedKngReport(linkParams, bodyParams) : api.getKngLists(linkParams, bodyParams);

      interfaceName.then(async res => {
        this.KngTableData = res.datas;
        this.totalKng = res.paging.count;
        this.selectedTrue(res.datas, this.$refs.kngTable);

        this.kngListLoading = false;
      }).catch(err => {
        this.kngListLoading = false;
        err.error.message && this.$message.error(err.error.message);
      });
    },
    // 按次购买的课程的列表知识
    getBuyLists(sort, sortType) {
      this.buyListLoading = true;
      let linkParams = this.getLinkParams(2, isUndefined(sort), isUndefined(sortType));
      let bodyParams = {
        name: this.buyInput.indexOf(this.$t('pc_biz_kng_lbl_hidden')) === -1 ? this.buyInput : this.buyInput.replace(this.$t('pc_biz_kng_lbl_hidden'), ''),
        checkedIds: this.maxNum ? [] : this.handleExcludeId(this.beforeKng),
        available: this.showAllBuyCount ? 0 : 1,
        buyType: 1,
        saveKng: -1,
        collegeId: this.collegeId,
        filter: this.filterClosedCollegeData ? 1 : 0,
        needKngList: this.needKngList ? 1 : 0
      };
      if (this.enableGroupCorp) {
        bodyParams.orgId = this.orgId;
      }
      this.bodyParams2 = bodyParams;
      api.getBuyLists(linkParams, bodyParams).then(async res => {
        if (res.datas.length) {
          this.buyTableData = res.datas.map(item => {
            item.id = item.prodKngId;
            item.title = item.prodName;
            item.fileType = 'course';
            item.type = 0;
            return item;
          });
        } else {
          this.buyTableData = [];
        }
        this.totalBuy = res.paging.count;

        this.selectedTrue(res.datas, this.$refs.buyTable);
        this.buyListLoading = false;
      }).catch(err => {
        this.buyListLoading = false;
        err.error.message && this.$message.error(err.error.message);
      });
    },
    // 按项目购买的课程的列表知识
    getBuyProjectLists() {
      this.buyProjectListLoading = true;
      let linkParams = this.getLinkParams(3);
      let bodyParams = {
        name: this.buyProjectInput.indexOf(this.$t('pc_biz_kng_lbl_hidden')) === -1 ? this.buyProjectInput : this.buyProjectInput.replace(this.$t('pc_biz_kng_lbl_hidden'), ''),
        checkedIds: this.maxNum ? [] : this.handleExcludeId(this.beforeKng),
        spuCode: this.spuCode,
        spuVersion: this.spuVersion,
        projectId: this.projectId,
        orgId: this.orgId,
        skuCodeList: this.skuCodeList,
        filter: this.filterClosedCollegeData ? 1 : 0
      };
      this.bodyParams3 = bodyParams;
      api.getBuyProjectLists(linkParams, bodyParams).then(async res => {
        if (res.datas.length) {
          this.buyProjectTableData = res.datas.map(item => {
            item.id = item.prodKngId;
            item.title = item.prodName;
            item.fileType = 'course';
            item.type = 0;
            return item;
          });
        } else {
          this.buyProjectTableData = [];
        }
        this.totalBuyProject = res.paging.count;

        this.selectedTrue(res.datas, this.$refs.buyProjectTable);
        this.buyProjectListLoading = false;
      }).catch(err => {
        this.buyProjectListLoading = false;
        err.error.message && this.$message.error(err.error.message);
      });
    },
    selectAll() {
      this.selectAllBtnLoading = true;
      let query = {};
      if (this.activeIndex === '1') {
        query = this.getLinkParams(1, isUndefined(this.kngSort), isUndefined(this.kngSortType));
        query.limit = this.maxNum > 0 ? this.maxNum : this.totalKng;
        query.offset = 0;
        let request = this.isNoNeedAuth ? api.postChoosedKngReport : api.getKngLists;
        request(query, this.bodyParams1)
          .then(res => {
            this.selectionKng = this.getFiteredData(res.datas, this.selectionKng);
            this.passKngLists = this.checkIdLists;
            this.selectedTrue(this.KngTableData, this.$refs.kngTable);
          }).finally(() =>{
            this.selectAllBtnLoading = false;
          });
      } else if (this.activeIndex === '2') {
        query = this.getLinkParams(2, isUndefined(this.kngSort), isUndefined(this.kngSortType));
        query.limit = this.maxNum > 0 ? this.maxNum : this.totalBuy;
        query.offset = 0;
        api.getBuyLists(query, this.bodyParams2)
          .then(res => {
            let list = (res.datas || []).map(item => {
              item.id = item.prodKngId;
              item.title = item.prodName;
              item.fileType = 'course';
              item.type = 0;
              return item;
            });
            this.selectionBuy = this.getFiteredData(list, this.selectionBuy);
            this.passKngLists = this.checkIdLists;
            this.selectedTrue(this.buyTableData, this.$refs.buyTable);
          }).finally(() =>{
            this.selectAllBtnLoading = false;
          });
      } else if (this.activeIndex === '3') {
        query = this.getLinkParams(3);
        query.limit = this.maxNum > 0 ? this.maxNum : this.totalBuyProject;
        query.offset = 0;
        api.getBuyProjectLists(query, this.bodyParams3)
          .then(res => {
            let list = (res.datas || []).map(item => {
              item.id = item.prodKngId;
              item.title = item.prodName;
              item.fileType = 'course';
              item.type = 0;
              return item;
            });
            this.selectionBuyProject = this.getFiteredData(list, this.selectionBuyProject);
            this.passKngLists = this.checkIdLists;
            this.selectedTrue(this.buyProjectTableData, this.$refs.buyProjectTable);
          }).finally(() =>{
            this.selectAllBtnLoading = false;
          });
      }
    },
    getFiteredData(list, originList) {
      // 还剩下多少可以添加 number 0 无限制
      let number = this.maxNum > 0 ? (this.maxNum - this.checkIdLists.length) : 0;
      let checkedList = JSON.parse(JSON.stringify(originList));
      let count = 0;
      const idSet = new Set([...originList, ...this.checkIdLists].map(item => item.id));
      for (let item of list) {
        if (this.maxNum > 0 && count >= number) {
          this.$message.error(this.$t('pc_biz_kng_tip_overSelected'));
          return checkedList;
        }
        if (!idSet.has(item.id)) {
          checkedList.push(item);
          count++;
        }
      }
      return checkedList;
    },

    // 将selected知识设为true
    selectedTrue(data, refTable) {
      this.$nextTick(() => {
        const ids = (this.passKngLists || []).map(item => item.id);
        const courseIds = (this.passKngLists || []).map(item => item.courseId);
        data.forEach(kng => {
          if (ids.includes(kng.id)) {
            refTable.toggleRowSelection(kng, true);
          }
          if (this.needKngList && kng.children) {
            kng.children.forEach(item => {
              if (ids.includes(item.id) && courseIds.includes(item.courseId)) {
                refTable.toggleRowSelection(item, true);
              }
            });
          }
        });
      });
    },
    // 排除fileType
    excludeType() {
      // 0-课程、1-文档、2-视频、3-音频、4-微课、5-SCORM、6-HTML、7-压缩包(M1 无压缩包)、8-外链
      // 包含文件类型 course(课程)、video(视频)、img(图片)、xuanyes(微课)、zip(压缩包)、audio(音频)、excel、html、pdf、ppt、scorm、word、newlink
      const TypeEnmu = {
        0: ['course'],
        1: ['img', 'excel', 'pdf', 'ppt', 'word'],
        2: ['video'],
        3: ['audio'],
        4: ['xuanyes'],
        5: ['scorm'],
        6: ['html'],
        7: ['zip'],
        8: ['newlink']
      };

      let excludeArr = [];

      console.log(this.includeTypes);
      if (this.includeTypes.length > 0) {
        Object.keys(TypeEnmu).forEach(key => {
          if (!this.includeTypes.includes(parseInt(key, 10))) {
            excludeArr = excludeArr.concat(TypeEnmu[key]);
          }
        });
      }

      if (this.excludeFileType && !excludeArr.includes('course')) {
        excludeArr.push('course');
      }

      if (this.isRemoveZip && !excludeArr.includes('zip')) {
        excludeArr.push('zip');
      }

      return excludeArr;
    },
    // 公共处理linkParams的地方
    getLinkParams(pageIndex, sort, sortType) {
      let linkParams = {};
      switch (pageIndex) {
        case 1:
          linkParams.orderBy = sort;
          linkParams.orderType = sortType;
          linkParams.limit = this.perKngPage;
          linkParams.offset = (this.currentKng - 1) * this.perKngPage;
          break;
        case 2:
          linkParams.orderby = sort;
          linkParams.direction = sortType;
          linkParams.limit = this.perBuyPage;
          linkParams.offset = (this.currentBuy - 1) * this.perBuyPage;
          break;
        case 3:
          linkParams.limit = this.perBuyProjectPage;
          linkParams.offset = (this.currentBuyProject - 1) * this.perBuyProjectPage;
          break;
      }
      return linkParams;
    },
    getBodyParams(input, value, checked) {
      let bodyParams = {
        title: input.indexOf(this.$t('pc_biz_kng_lbl_hidden')) === -1 ? input : input.replace(this.$t('pc_biz_kng_lbl_hidden'), ''),
        kngType: value.toString(),
        isShowHidden: Number(checked),
        checkedIds: this.maxNum ? [] : this.handleExcludeId(this.beforeKng),
        pmType: this.pmType,
        deleted: Number(this.deleteChecked),
        auditStatus: this.isAudit ? (this.auditValue || 0) : null,
        envFlag: this.env !== 2 ? this.env : this.envFlag
      };
      if (this.creatTimes && this.creatTimes.length > 1) {
        bodyParams.startTime = this.creatTimes[0] + ' 00:00:00';
        bodyParams.endTime = this.creatTimes[1] + ' 23:59:59';
      }
      return bodyParams;
    },
    // 知识库目录下一页操作
    handleKngCurrentChange(val) {
      this.currentKng = val;
      this.getKngLists(this.kngTreeId, this.kngSort, this.kngSortType);
    },
    // 每页显示数量变更
    handleKngSizeChange() {
      this.currentKng = 1;
      this.getKngLists(this.kngTreeId, this.kngSort, this.kngSortType);
    },
    // 创建日期变更
    handleTimeChane() {
      this.currentKng = 1;
      // this.getKngLists(this.kngTreeId, this.kngSort, this.kngSortType);
    },
    // 按次购买的课程下一页操作
    handleBuyCurrentChange(val) {
      this.currentBuy = val;
      this.getBuyLists(this.buySort, this.buySortType);
    },
    // 按项目购买的课程下一页操作
    handleBuyProjectCurrentChange(val) {
      this.currentBuyProject = val;
      this.getBuyProjectLists();
    },
    // 搜索操作
    searchLists(index) {
      switch (index) {
        case 1:
          this.perKngPage = 20;
          this.currentKng = this.currentKngPage = 1;
          this.getKngLists(this.kngTreeId, this.kngSort, this.kngSortType);
          break;
        case 2:
          this.perBuyPage = 20;
          this.currentBuy = this.currentBuyPage = 1;
          this.getBuyLists(this.buySort, this.buySortType);
          break;
        case 3:
          this.perBuyProjectPage = 20;
          this.currentBuyProject = this.currentBuyProjectPage = 1;
          this.getBuyProjectLists();
          break;
      }
    },
    // 知识库目录选择项触发事件
    selectionChange(selection) {
      this.selectionKng = selection;
    },
    // 按次购买的课程选择项触发事件
    selectionBuyChange(selection) {
      this.selectionBuy = selection;
    },
    // 按项目购买的课程选择项触发事件
    selectionBuyProjectChange(selection) {
      this.selectionBuyProject = selection;
    },
    // 当单个选择知识大于最大的值的时候将选中的知识取消勾选,判断只有有当前的页卡才会去清除对应选中的知识
    singleCancel(selection, row) {
      this.IsKngMenu && this.singleEvent(this.$refs.kngTable, row);
      this.IsBuyCourseMenu && this.singleEvent(this.$refs.buyTable, row);
      this.IsBuyProjectMenu && this.singleEvent(this.$refs.buyProjectTable, row);
      this.filterRemove(row);
    },
    // 单个checkbox点击公共事件
    singleEvent(refTable, row) {
      setTimeout(() => {
        if (this.checkIdLists.length > this.maxNum && this.maxNum > 0) {
          this.$nextTick(() => {
            refTable.toggleRowSelection(row, false);
          });
        }
      });
    },
    filterRemoveKng(row) {
      this.selectionKng = this.selectionKng.filter(item => item.id !== row.id);
      this.selectionBuy = this.selectionBuy.filter(item => item.id !== row.id);
      this.selectionBuyProject = this.selectionBuyProject.filter(item => item.id !== row.id);
      this.passKngLists = this.passKngLists.filter(item => item.id !== row.id);
    },
    filterRemove(row) {
      if (this.passKngLists.length) {
        this.filterRemoveKng(row);
      }
    },
    // 底部tab，在取消选中时，同时取消其他页卡里相同id的知识
    closeKng(kng) {
      this.checkKngLists.map(v => {
        if (v.id === kng.id) {
          this.selectedFalse(this.selectionKng, this.$refs.kngTable, kng);
          this.selectedFalse(this.selectionBuy, this.$refs.buyTable, kng);
          this.selectedFalse(this.selectionBuyProject, this.$refs.buyProjectTable, kng);
        }
      });

      this.filterRemove(kng);
    },
    selectedFalse(data, refTable, row) {
      if (refTable) {
        data.forEach(kng => {
          if (row.id === kng.id) {
            refTable.toggleRowSelection(kng, false);
          }
          if (this.needKngList && kng.children && row.courseId) {
            kng.children.forEach(item => {
              if (row.id === item.id && row.courseId === item.courseId) {
                refTable.toggleRowSelection(item, false);
              }
            });
          }
        });
      }
    },
    // 清除所有选中的知识,判断只有有当前的页卡才会去清除对应选中的知识
    clearAllSelection() {
      this.IsKngMenu && this.$refs.kngTable && this.$refs.kngTable.clearSelection();
      this.IsBuyCourseMenu && this.$refs.buyTable && this.$refs.buyTable.clearSelection();
      this.IsBuyProjectMenu && this.$refs.buyProjectTable && this.$refs.buyProjectTable.clearSelection();

      this.selectionKng = this.selectionBuy = this.selectionBuyProject = [];
      this.passKngLists = [];
    },
    removeAllLists(selection, array) {
      if (!this.passKngLists.length) return;

      const kngIds = selection.map(item => item.id);
      const selected = array.filter(item => {
        return kngIds.includes(item.id);
      });

      if (!selected.length) {
        let ids = array.map(item => item.id);
        this.passKngLists = this.passKngLists.filter(item => {
          return !ids.includes(item.id);
        });
      }
    },
    // 选中所有知识库目录的知识
    handleKngSelectAll() {
      this.removeAllLists(this.selectionKng, this.KngTableData);
      this.handleSelectAll(this.$refs.kngTable, this.KngTableData);
    },
    // 选中所有按次购买的课程的知识
    handleBuySelectAll() {
      this.removeAllLists(this.selectionBuy, this.buyTableData);
      this.handleSelectAll(this.$refs.buyTable, this.buyTableData);
    },
    // 选中所有按项目购买的课程的知识
    handleBuyProjectSelectAll() {
      this.removeAllLists(this.selectionBuyProject, this.buyProjectTableData);
      this.handleSelectAll(this.$refs.buyProjectTable, this.buyProjectTableData);
    },
    // 选中所有的知识公共事件
    handleSelectAll(refTable, data, num) {
      let that = this;
      setTimeout(() => {
        if (this.checkIdLists.length > this.maxNum) {
          // 取出在所有选中的list中与之前选中的值的差集
          let newArrLists = [];
          let idArr = this.oldValue.map(item => item.id);
          newArrLists = this.checkIdLists.filter(list => {
            return !idArr.includes(list.id);
          });
          // 判断列表页和筛选出的差集相同的对象，把它取消选中
          const arrIds = newArrLists.map(item => item.id);
          data.forEach(list => {
            if (arrIds.includes(list.id)) {
              that.$nextTick(() => {
                refTable.toggleRowSelection(list, false);
              });
            }
          });
        }
      });
    },
    // 获取table的row-key
    getRowKey(row) {
      return this.needKngList ? row.id + (row.courseId || '') : row.id;
    },
    // 知识列表排序
    kngSortChange(column, prop, order) {
      let sort = '';
      let sortType = '';
      if (column.order !== null) {
        switch (column.prop) {
          case 'expirationDate':
            sort = 'expiredTime';
            break;
          case 'createFullname':
            sort = 'createFullname';
            break;
          case 'studyHours':
            sort = 'studyHours';
            break;
          case 'studyScore':
            sort = 'studyScore';
            break;
          default:
            sort = 'expiredTime';
            break;
        }
        sortType = this.getSortType(column.order);
      }
      this.kngSort = sort;
      this.kngSortType = sortType;
      if (this.isKnglib) {
        this.getKngLists(this.kngTreeId, sort, sortType);
      }
    },
    // 按次购买的课程列表排序
    buySortChange(column, prop, order) {
      let sort = '';
      let sortType = '';
      // 因为购买的课程列表有默认的排序，所以在这里应该设置成默认的排序
      if (column.order !== null) {
        switch (column.prop) {
          case 'avlCount':
          case 'orderCount':
            sort = column.prop;
            break;
        }
        sortType = this.getSortType(column.order);
      }
      this.buySort = sort;
      this.buySortType = sortType;
      if (this.isbuyCourse) {
        this.getBuyLists(this.buySort, this.buySortType);
      }
    },
    // 获取公共的sort
    getSortType(order) {
      let sortType = 'desc';
      switch (order) {
        case 'desc':
        case 'asc':
          sortType = order;
          break;
      }
      return sortType;
    },
    // 不控制数量时候排除的id的集合
    handleExcludeId(info) {
      if (info.length === 0) return [];
      return info.map(v => v.id);
    },
    // 关闭弹窗触发事件
    clearData() {
      this.kngInput = '';
      this.buyInput = '';
      this.buyProjectInput = '';
      this.kngTreeId = '';
      this.selectOpition.checkWord = '';
      this.kngValue = '';
      this.kngChecked = true;
      this.deleteChecked = false;

      this.clearAllSelection();
      this.$emit('closePage');
    },
    // 确定按钮操作
    completeDialog() {
      if (this.IsDisplayAllMenu) {
        if (this.orgId) {
          this.checkIdLists.forEach(item => {
            item.orgId = this.orgId;
          });
        }
        this.checkIdLists.length ? this.$emit('getKngInfo', this.checkIdLists) : this.$message.error(this.$t('pc_biz_kng_tip_noSelected'));

        if (!this.maxNum && this.checkIdLists.length) {
          this.clearAllSelection();
          this.clearData();
        }
      } else {
        this.clearData();
      }
    },
    clearPageCache() {
      // 知识库-购买的课程
      this.totalKng = this.totalBuy = 0;
      this.perKngPage = this.perBuyPage = 20;
      this.currentKng = this.currentBuy = 1;
      this.currentKngPage = this.currentBuyPage = 1;
    },
    loadInfo() {
      setTimeout(() => {
        if (this.isKnglib) {
          this.getKngTree();
          this.getKngLists();
          this.kngIndex = 2;
        }

        if (this.isbuyCourse) {
          this.getBuyLists();
          this.buyIndex = 2;
        }

        if (this.isbuyProjectCourse) {
          this.getBuyProjectLists();
          this.buyProjectIndex = 2;
        }
      }, 200);
    },
    submitKng(data) {
      this.$emit('submit', JSON.parse(JSON.stringify(data)));
    },
    // 判断首次加载什么tab数据
    getActiveIndex() {
      if (this.IsKngMenu) {
        this.activeIndex = '1';
        this.isKnglib = true;
        this.isbuyCourse = false;
        this.isbuyProjectCourse = false;
      } else if (this.IsBuyCourseMenu) {
        this.activeIndex = '2';
        this.isKnglib = false;
        this.isbuyCourse = true;
        this.isbuyProjectCourse = false;
      } else if (this.IsBuyProjectMenu) {
        this.activeIndex = '3';
        this.isKnglib = false;
        this.isbuyCourse = false;
        this.isbuyProjectCourse = true;
      }
    },
    orgChange(data) {
      // childList: null
      // disabled: false
      // initStatus: 1
      // masterStatus: 0
      // orgId: "74d72c41-d65b-42eb-a3b3-db7cfde1b893"
      // orgName: "集团版分测试机构-slave08"
      // parentOrgId: "588924b8-1bae-46d7-8a4b-638e117de8c4"
      // showed: true
      this.orgId = data.orgId;

      this.kngInput = '';
      this.filterKngTree = '';
      this.kngTreeId = '';
      this.envFlag = null;

      if (this.activeIndex === '2') {
        this.searchLists(2);
      } else if (this.activeIndex === '3') {
        this.getKngTree();
        this.getKngLists(this.kngTreeId);
      } else if (this.activeIndex === '1') {
        console.log('org change');
      }
    }
  },
  watch: {
    collegeId() {
      this.collegeIdSearch = this.collegeId;
      if (this.openPage) {
        this.loadInfo();
      }
    },
    checkIdLists(v, oldValue) {
      if (v.length > this.maxNum && this.maxNum) {
        this.$message.error(this.$t('pc_biz_kng_tip_overSelected'));
        this.oldValue = oldValue;
      }
    },
    kngChecked(v) {
      // this.currentKng = this.currentKngPage = 1;
      // this.getKngLists(this.kngTreeId, this.kngSort, this.kngSortType);
    },
    deleteChecked(v) {
      // this.currentKng = this.currentKngPage = 1;
      // this.getKngLists(this.kngTreeId, this.kngSort, this.kngSortType);
    },
    async openPage(v) {
      if (v) {
        // 控制数量时请求
        if (this.maxNum && this.kngIndex === 1 && this.buyIndex === 1 && this.buyProjectIndex === 1) {
          this.currentKngPage = 1;
          this.loadInfo();
        }

        if (this.IsDisplayAllMenu) {
          if (this.maxNum) {
            this.passKngLists = this.beforeKng;
          }
          // 查询beforeKng中没有名字的课;未了兼容装修那边只传了kng的课程
          let notNameList = this.passKngLists.filter(item => !item.title);
          if (notNameList.length) {
            // 查询名字
            try {
              const data = await api.getCourseNames({kngIds: notNameList.map(item => item.id), orgId: this.orgId || localStorage.orgId});
              this.passKngLists.forEach(item => {
                let t = data.find(obj => obj.id === item.id);
                if (t) this.$set(item, 'title', t.title);
              });
            } catch (e) {
              console.error(e);
            }
          }

          // TODO 控制数量后并且beforeKng有值则判断把值传递给
          if (this.maxNum) {
            if (this.beforeKng.length) {
              // 根据sign区分商城还是自主知识
              this.selectionKng = this.selectionBuy = this.selectionBuyProject = [];
              this.selectionKng = this.passKngLists.filter(item => item.sign === 0);
              this.selectionBuy = this.passKngLists.filter(item => item.sign === 1);
              this.selectionBuyProject = this.passKngLists.filter(item => item.sign === 1);
            }

            if (this.activeIndex === '1') {
              this.cancelAndCheckBeforeKng(this.KngTableData, this.$refs.kngTable);
            } else if (this.activeIndex === '2') {
              this.cancelAndCheckBeforeKng(this.buyTableData, this.$refs.buyTable);
            } else {
              this.cancelAndCheckBeforeKng(this.buyProjectTableData, this.$refs.buyProjectTable);
            }
          } else {
            // TODO 避免selectType未更新成最新值就请求接口
            setTimeout(() => {
              if (this.activeIndex === '1') {
                if (this.isKnglib) {
                  this.getKngTree();
                  this.getKngLists();
                  this.kngIndex = 2;
                }
              } else if (this.activeIndex === '2') {
                this.isbuyCourse && this.getBuyLists();
                this.buyIndex = 2;
              } else {
                this.isbuyProjectCourse && this.getBuyProjectLists();
                this.buyProjectIndex = 2;
              }
            }, 200);
          }
        }
      } else {
        if (!this.maxNum) {
          this.clearPageCache();

          // 未控制数量时，重新初始化
          this.getActiveIndex();
          this.kngIndex = this.buyIndex = this.buyProjectIndex = 1;
          // 外面使用组件的人可能是在收到submit回调的时候更改的openPage值，为了避免外面取不到，这里延后一个栈执行
          setTimeout(_=>{
            this.selectionKng = [];
          });
        }
      }

      // 是否排除了课程包
      // if (this.excludeFileType && this.options[0].value === 0) {
      //   this.options.splice(0, 1);
      //   return;
      // }

      // if (!this.excludeFileType) {
      //   if (this.options[0].value === 0) return;

      //   let obj = {value: 0, label: this.$t('pc_biz_kng_lbl_course')};
      //   let isHavaCourse = this.options.some(item => item.value === 0);
      //   if (!isHavaCourse) {
      //     this.options.unshift(obj);
      //   }
      // }
    },
    kngSelectType(v) {
      this.disabledType = v === 0 ? false : this.disabled;
      this.kngValue = v === 999 ? '' : v;
      this.kngSelectIndex = this.buySelectIndex = this.buyProjectSelectIndex = 1;
      this.clearPageCache();
    },
    excludeFileType() {
      // 未控制数量时，重新初始化
      this.getActiveIndex();
      this.kngIndex = this.buyIndex = this.buyProjectIndex = 1;
      this.searchLists(this.activeIndex);
    },
    kngValue(v) {
      if (this.activeIndex === '1') {
        // this.searchLists(1);
      }
    },
    auditValue() {
      if (this.activeIndex === '1') {
        // this.searchLists(1);
      }
    },
    envFlag() {
      // if (this.activeIndex === '1') {
      //   this.searchLists(1);
      // }
    }
  }
};
</script>
