import { udpApi } from 'yxt-biz-pc/packages/api';

// 获取所属集团下的机构列表
export const getGroupOrgList = (t, navCode, dataPermissionCode)=>{
  return udpApi.get(`groupcorps/orginfos?formatTree=${t}&navCode=${navCode || ''}&dataPermissionCode=${dataPermissionCode || ''}`);
};

export const getGroupOrgInfo = (orgId)=>{
  return udpApi.get(`groupcorps/${orgId}/groupinfo`);
};

// 强模式全集团选择岗位
export const getGroupPositions = (data) => {
  return udpApi.post('groupcorps/positions/batch', data);
};
