class WaterMarkCanvas {
  constructor(options = {}) {
    const canvasDom = document.getElementById('watermark_canvas_warp');
    if (canvasDom) canvasDom.remove();
    this.canvas = null;
    this.ctx = null;
    this.timer = null; // 定时器
    this.targetDom = options.el;
    const {
      width: elWidth,
      height: elHeight
    } = this.targetDom.getBoundingClientRect();
    const position = options.position;
    this.options = {
      text: '',
      randomRotate: options.randomRotate, // 是否随机滚动
      platformType: options.platformType, // 平台类型 pc / h5
      rotateAngle: 30,
      fontColor: 'red',
      fontSize: 16,
      density: 2, // 密度等级，3: 高，2: 中，1: 低
      opacity: 1, // 透明度，0 ～ 1
      speed: 0, // 动画速度等级
      width: options.width || elWidth,
      height: options.height || elHeight,
      wrapWidth: options.width || elWidth,
      wrapHeight: options.height || elHeight,
      position: {
        top: (position && position.top) || 0,
        left: (position && position.left) || 0
      }
    };
    this.init();
  }
  init() {
    this.createCanvasMap();
    this.appendToHtml();
  }

  createCanvasMap() {
    // 创建画布
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    this.canvas = canvas;
    this.ctx = ctx;
    this.updateCanvasStyle();
  }

  fillText(text = '', x = 0, y = 0, rotate = 0) {
    const ctx = this.ctx;
    ctx.save();
    // ctx.textAlign = 'center'
    ctx.translate(x, y);
    ctx.rotate((-rotate * Math.PI) / 180);
    ctx.fillText(text, 0, 0);
    ctx.restore();
  }

  draw(options = {}) {
    this.clearMap();
    if (this.timer) window.clearInterval(this.timer);
    Object.assign(this.options, options);
    console.log(this.options, '----');
    this.updateCanvasStyle();
    const { text, rotateAngle, density, randomRotate } = this.options;
    if (randomRotate) return this.randomRotateCanvas(); // 随机滚动
    const points = this.densityPoints(density);
    points.forEach(point => {
      this.fillText(text, point[0], point[1], rotateAngle);
    });
  }

  randomRotateCanvas() {
    const { text, width, height, speed = 0, fontSize } = this.options;
    const startDirection = Math.floor((Math.random() * 10) % 4); // 起始方向 0，1，2，3，表示上右下左

    const animationTime = [15, 12.5, 10, 7.5, 5];
    const fps = Math.floor(1000 / 60);
    const textWidth = this.ctx.measureText(text).width;
    // const distance = Math.sqrt(width * width + height * height) + textWidth

    const startX = Math.floor(Math.random() * width);
    const startY = Math.floor(Math.random() * height);
    const endX = Math.floor(Math.random() * (width - startX));
    const endY = Math.floor(Math.random() * (height - startY));

    let count = 0;
    let loopCount = animationTime[speed] * 60;

    let moveLenX = 0;
    let moveLenY = 0;

    let startPosition = [0, 0];

    if ([0, 2].includes(startDirection)) {
      startPosition[0] = startX;
      startPosition[1] = 0 - fontSize; // 位置补正
      moveLenX = (endX - startPosition[0]) / loopCount;
      moveLenY = (height + fontSize) / loopCount;
      if (startDirection === 2) {
        // 下方向
        startPosition[1] = height;
        moveLenY = -(height + fontSize) / loopCount;
      }
    } else {
      startPosition[0] = 0 - textWidth; // 位置补正
      startPosition[1] = startY;
      moveLenX = (width + textWidth) / loopCount;
      moveLenY = (endY - startPosition[1]) / loopCount;
      if (startDirection === 1) {
        // 右方向
        startPosition[0] = width;
        moveLenX = -(width + textWidth) / loopCount;
      }
    }

    this.timer = window.setInterval(() => {
      if (count < loopCount) {
        count++;
        this.clearMap();
        this.fillText(text, startPosition[0], startPosition[1], 0);
        startPosition[0] = startPosition[0] + moveLenX;
        startPosition[1] = startPosition[1] + moveLenY;
      } else {
        window.clearInterval(this.timer);
        this.randomRotateCanvas();
      }
    }, fps);
  }

  updateCanvasStyle() {
    const {
      fontColor,
      width,
      height,
      wrapWidth,
      wrapHeight,
      opacity,
      fontSize,
      position
    } = this.options;
    const ctx = this.ctx;
    const canvas = this.canvas;
    ctx.beginPath();
    // 设置canvas样式宽高
    canvas.style.width = wrapWidth + 'px';
    canvas.style.height = wrapHeight + 'px';
    canvas.style.opacity = opacity;

    canvas.style.position = 'absolute';
    canvas.style.top = position.top + 'px';
    canvas.style.left = position.left + 'px';
    canvas.style.zIndex = 9999;
    canvas.id = 'watermark_canvas_warp';
    canvas.style.pointerEvents = 'none';

    // 设置canvas画布宽高为样式的2倍
    canvas.width = width * 2;
    canvas.height = height * 2;

    ctx.scale(2, 2); // 整体放大2倍
    ctx.textBaseline = 'top';
    ctx.fillStyle = fontColor;
    ctx.font = `${fontSize}px serif`;
  }

  clearMap() {
    // 清空画布
    const { width, height } = this.options;
    this.ctx.clearRect(0, 0, width, height);
  }

  densityPoints(level) {
    switch (level) {
      case 3:
        return this.densityHeightPoints();
      case 2:
        return this.densityMidPoints();
      default:
        return this.densityLowPoints();
    }
  }

  densityH5Points(level) {
    const { width, fontSize } = this.options;
    const pointConfig = {
      initPoint: [20, 100], // 第一个点位坐标，用于后续点位生成的基准坐标
      ySpace: 120, // 纵向间距
      xSpace: width / 2 + 20, // 横向间隔
      col: 2, // 列数
      row: 20, // 行数
      topDiff: 120 / 2 - fontSize // y方向偏移量
    };

    if (level === 2) {
      pointConfig.initPoint = [20, 150];
      pointConfig.ySpace = 200;
      pointConfig.topDiff = 0;
      pointConfig.row = 3;
    }

    if (level === 1) {
      pointConfig.initPoint = [100, 150];
      pointConfig.ySpace = 200;
      pointConfig.topDiff = 0;
      pointConfig.row = 3;
      pointConfig.col = 1;
    }

    const points = [];
    for (let i = 0; i < pointConfig.col; i++) {
      for (let j = 0; j < pointConfig.row; j++) {
        points.push([
          pointConfig.initPoint[0] + i * pointConfig.xSpace,
          pointConfig.initPoint[1] +
            j * pointConfig.ySpace -
            pointConfig.topDiff * i
        ]);
      }
    }
    return points;
  }

  densityHeightPoints() {
    // 高密度
    const { platformType } = this.options;

    if (platformType === 'h5') {
      return this.densityH5Points(3);
    }

    const pointConfig = {
      initPoint: [100, 100], // 第一个点位坐标，用于后续点位生成的基准坐标
      ySpace: 300, // 纵向间距
      xSpace: 250, // 横向间隔
      col: 20, // 列数
      topDiff: 210 // y方向偏移量
    };

    const points = [];
    for (let i = 0; i < pointConfig.col; i++) {
      for (let j = 0; j < 20; j++) {
        points.push([
          pointConfig.initPoint[0] + i * pointConfig.xSpace,
          pointConfig.initPoint[1] +
            j * pointConfig.ySpace -
            pointConfig.topDiff * i
        ]);
      }
    }
    return points;
  }

  densityMidPoints() {
    // 中密度
    const { width, height, platformType } = this.options;

    if (platformType === 'h5') {
      return this.densityH5Points(2);
    }

    const leftX = 50;
    const heightY = 100;
    const topDiff = 60; // 错位补正
    const points = [
      [leftX, heightY],
      [leftX, height - heightY + topDiff],
      [(width - 200 - leftX) / 2 + leftX, height / 2 - topDiff],
      [width - 200, heightY],
      [width - 200, height - heightY]
    ];
    return points;
  }

  densityLowPoints() {
    // 低密度
    const { width, height, platformType } = this.options;

    if (platformType === 'h5') {
      return this.densityH5Points(1);
    }

    const leftX = 50;
    const heightY = 100;
    const points = [
      [leftX, heightY],
      [(width - 200 - leftX) / 2 + leftX, height / 2],
      [width - 200, height - heightY]
    ];
    return points;
  }

  appendToHtml() {
    this.targetDom.appendChild(this.canvas);
  }
}

export default WaterMarkCanvas;
