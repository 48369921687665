import { surveyApi } from 'yxt-biz-pc/packages/api';

// 获取调查列表
export const getSurveyListData = ({ limit, current, orderby, direction, groupFuncReq, queryOrgId, ...body }) => {
  return surveyApi.post('/project/component/search', body, { params: { limit, current, orderby, direction, groupFuncReq, queryOrgId } });
};

// 获取调查列表
export const getSurveyListDataNoPage = ({ limit, current, orderby, direction, groupFuncReq, queryOrgId, ...body }) => {
  return surveyApi.post('/project/component/search/all', body, { params: { limit, current, orderby, direction, groupFuncReq, queryOrgId } });
};

/**
 * 分类列表查询
 * @returns
 */
export const getCategoryListData = () => surveyApi.get('/temp/category/list');
