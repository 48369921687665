/**
 * 获取window上有没有挂载sensors
 * @returns
 */
const getSensors = () => {
  let sensors = null;
  if (window.sensors || window.sensorsDataAnalytic201505) {
    sensors = window.sensors || window.sensorsDataAnalytic201505;
  } else {
    sensors = {
      init: () => {},
      login: () => {},
      quick: () => {},
      registerPage: () => {},
      track: () => {}
    };
  }
  return sensors;
};

export default getSensors;
