import { knglibApi } from 'yxt-biz-pc/packages/api';

// 拼接URL参数
const linkSubString = (url, data) => {
  if (url === null || url === '') {
    return url;
  }
  let queryString = '';
  if (typeof data === 'object') {
    for (var i in data) {
      queryString += i + '=' + data[i] + '&';
    }
  }
  if (url.indexOf('?') > url.indexOf('/')) {
    url += '&';
  } else {
    url += '?';
  }
  if (queryString !== '') {
    queryString = queryString.substr(0, queryString.length - 1);
  }
  url += queryString;
  return url;
};

export default {
  // 获取知识库目录树
  getKngTree(bodyParams) {
    return knglibApi.post('/external/kngtree', bodyParams);
  },
  // 获取知识库列表数据
  getKngLists(queryParams, bodyParams) {
    return knglibApi.post(linkSubString('/external/knglist', queryParams), bodyParams);
  }
};
