var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fw-600 font-size-20 pr",
      class: _vm.noBorder ? null : "border-b-e9 pb20 pl32",
    },
    [
      _vm._m(0),
      _c(
        "yxt-popover",
        {
          attrs: { placement: "bottom-start", width: "560", trigger: "hover" },
        },
        [
          _c("uploadFAQ", { staticClass: "p10" }),
          _c("i", {
            staticClass: "yxt-icon-question ml16 v-mid hand color-gray-6",
            attrs: { slot: "reference" },
            slot: "reference",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "v-mid" }, [
      _vm._v("上传课件"),
      _c("i", { staticClass: "yui-name-icon v-mid" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }