var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.isLoad,
          expression: "!isLoad",
        },
      ],
      staticClass: "yxtbiz-question-preview",
    },
    [
      _vm.isLoad
        ? _c("ques-view-info", {
            attrs: { quesId: _vm.id, quesBody: _vm.quesBody },
          })
        : _vm._e(),
      _vm.showUpdater
        ? _c("div", { staticClass: "yxtbiz-question-preview__updater mt32" }, [
            _c(
              "div",
              { staticClass: "yxtbiz-question-preview__updater-left" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("pc_biz_ote_lbl_updater" /* 更新人 */)) +
                    "\n    "
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "yxtbiz-question-preview__updater-right" },
              [
                _c("yxtbiz-user-name", {
                  attrs: { name: _vm.quesBody.updateFullname },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.showUpdater
        ? _c("div", { staticClass: "yxtbiz-question-preview__updater mt16" }, [
            _c(
              "div",
              { staticClass: "yxtbiz-question-preview__updater-left" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("pc_biz_ote_lbl_updatetime" /* 更新日期 */)) +
                    "\n    "
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "yxtbiz-question-preview__updater-right" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.dateToString(
                        _vm.quesBody.updateTime,
                        "yyyy-MM-dd HH:mm:ss"
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }