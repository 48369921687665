import UserName from 'yxt-biz-pc/packages/user-name';
import DeptName from 'yxt-biz-pc/packages/dept-name';
import { isOpenData } from 'yxt-biz-pc/src/utils/shared';

export default {
  components: {
    YxtbizUserName: UserName,
    YxtbizDeptName: DeptName
  },
  computed: {
    isOpenData() {
      return isOpenData();
    }
  }
};
