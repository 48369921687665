var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "currentEle",
      staticClass: "yxtbiz-dept-tree",
      class: { "yxtbiz-dept-tree--autosize": _vm.autosize },
    },
    [
      _c(
        "yxt-row",
        { attrs: { type: _vm.selectorAlign ? "" : "flex" } },
        [
          _vm.enableGroupCorp && _vm.visibleOrgSelector
            ? _c("yxtbiz-group-org-select", {
                staticClass: "mr12 mb12",
                style: _vm.selectorAlign ? "width: 100%" : "",
                attrs: {
                  selectFirst: true,
                  setCurrentOrg: _vm.setCurrentOrg,
                  functionCode: _vm.functionCode,
                  dataPermissionCode: _vm.dataPermissionCode,
                  clearable: false,
                  targetOrgId: _vm.targetOrgId,
                  visibleOrgIds: _vm.visibleOrgIds,
                  disabled: _vm.disabled,
                  size: _vm.size,
                },
                on: { change: _vm.handleOrgChange },
              })
            : _vm._e(),
          _vm.filterable && !_vm.isOpenData
            ? _c("yxt-input", {
                ref: "input",
                staticClass: "yxtbiz-dept-tree__input",
                style: { width: _vm.inputW },
                attrs: {
                  placeholder: _vm.placeholder,
                  size: _vm.size,
                  searchable: "",
                },
                on: { search: _vm.filter },
                model: {
                  value: _vm.filterText,
                  callback: function ($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("yxtbiz-funs-tree", {
        ref: "tree",
        attrs: {
          props: _vm.props,
          "highlight-current": _vm.highlightCurrent,
          "node-key": _vm.nodeKey,
          "check-strictly": _vm.checkStrictly,
          "show-checkbox": _vm.multiple && _vm.showCheckbox,
          "default-expanded-keys": _vm.expandTempShow,
          functions: _vm.multiple && _vm.functions,
          "wrap-width": _vm.wrapWidth,
          functionsRender: _vm.functionsRenderMid,
          "filter-node-method": _vm.filterNode,
          count: "",
          native: _vm.native,
          placement: _vm.functionsPlacement,
          "default-checked-keys": _vm.defaultCheckedKeys,
          dropdownToBody: _vm.dropdownToBody,
          virtual: _vm.virtual,
          keeps: 50,
          buffer: 10,
        },
        on: {
          "node-click": _vm.nodeClick,
          "node-expand": _vm.nodeExpand,
          "node-collapse": _vm.nodeCollapse,
          check: _vm.handlerCheck,
          "check-change": _vm.handleCheckChange,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ data, node }) {
              return [
                !_vm.multiple && data.hasAllDeptPermission
                  ? _c(
                      "span",
                      { staticClass: "yxtbiz-dept-tree-radio" },
                      [
                        _c("yxt-radio", {
                          attrs: { label: data.id },
                          model: {
                            value: _vm.deptTreeSingle,
                            callback: function ($$v) {
                              _vm.deptTreeSingle = $$v
                            },
                            expression: "deptTreeSingle",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("yxtbiz-dept-name", { attrs: { name: node.label } }),
                _vm.core === "team"
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.count && data.id && data.hasAllDeptPermission
                            ? `(${data.userCount})`
                            : ""
                        )
                      ),
                    ])
                  : _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.deptCountAvailable,
                            expression: "deptCountAvailable",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.count && data.id ? `(${data.userCount})` : ""
                          )
                        ),
                      ]
                    ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }