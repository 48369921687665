var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-select-newkng" }, [
    _c("div", { staticClass: "clearfix yxtbiz-select-newkng__tb flex-1" }, [
      _c(
        "div",
        { staticClass: "yxtbiz-select-newkng__operate" },
        [
          _c(
            "yxt-select",
            {
              model: {
                value: _vm.dataOrigin,
                callback: function ($$v) {
                  _vm.dataOrigin = $$v
                },
                expression: "dataOrigin",
              },
            },
            [
              _c(
                "yxt-option",
                {
                  attrs: {
                    value: 0,
                    label: _vm.$t(
                      "pc_biz_knglib_lbl_allow_import" /** 允许引用 */
                    ),
                  },
                },
                [
                  _c("span", { staticClass: "font-size-14" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("pc_biz_knglib_lbl_allow_import" /** 允许引用 */)
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "yxt-option",
                {
                  attrs: {
                    value: 1,
                    label: _vm.$t(
                      "pc_biz_knglib_lbl_create_by_me" /** 我创建的 */
                    ),
                  },
                },
                [
                  _c("span", { staticClass: "font-size-14" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("pc_biz_knglib_lbl_create_by_me" /** 我创建的 */)
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm.dataOrigin === 0
            ? _c(
                "select-popover",
                {
                  ref: "selectPopover",
                  staticClass: "d-in-block w210 ml12",
                  attrs: { selectOpition: _vm.selectOpition },
                },
                [
                  _c(
                    "div",
                    { staticClass: "yxtbiz-select-newkng__tree" },
                    [
                      _c("yxt-input", {
                        attrs: {
                          placeholder: _vm.$t("pc_biz_knglib_lbl_pleaseSearch"),
                          searchable: "",
                        },
                        on: { search: _vm.searchCatalog },
                        model: {
                          value: _vm.filterKngTree,
                          callback: function ($$v) {
                            _vm.filterKngTree = $$v
                          },
                          expression: "filterKngTree",
                        },
                      }),
                      _c("yxt-tree", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.treeLoading,
                            expression: "treeLoading",
                          },
                        ],
                        ref: "filterTree",
                        staticClass: "yxtbiz-biz-tree-funs mt16",
                        class: _vm.kngTreeData.length > 0 ? "d-in-block" : "",
                        attrs: {
                          "node-key": "id",
                          "empty-text": _vm.$t("pc_biz_kng_lbl_noCatalog"),
                          data: _vm.kngTreeData,
                          "filter-node-method": _vm.filterNode,
                          "default-expanded-keys": [""],
                          "expand-on-click-node": false,
                        },
                        on: { "node-click": _vm.ClickTreeNode },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "yxt-select",
            {
              staticClass: "ml12",
              attrs: {
                placeholder: _vm.$t("pc_biz_knglib_lbl_allType"),
                clearable: "",
              },
              model: {
                value: _vm.kngType,
                callback: function ($$v) {
                  _vm.kngType = $$v
                },
                expression: "kngType",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c(
                "yxt-option",
                {
                  key: item.value + Math.random(),
                  attrs: { label: item.label, value: item.value },
                },
                [
                  _c("span", { staticClass: "font-size-14" }, [
                    _vm._v(_vm._s(item.label)),
                  ]),
                ]
              )
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "d-in-block ml12" },
            [
              _c("yxt-input", {
                staticClass: "yxtbiz-select-newkng__search",
                attrs: {
                  placeholder: _vm.$t("pc_biz_knglib_lbl_searchKngName"),
                  maxLength: "20",
                  searchable: "",
                  clearable: "",
                },
                on: { clear: _vm.searchLists, search: _vm.searchLists },
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "keyword",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-1 h0 pb1 mt16", staticStyle: { width: "692px" } },
        [
          _c(
            "yxt-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.kngListLoading,
                  expression: "kngListLoading",
                },
              ],
              ref: "kngTable",
              attrs: {
                height: "100%",
                data: _vm.KngTableData,
                "row-key": _vm.getRowKey,
              },
              on: {
                select: _vm.singleCancel,
                "selection-change": _vm.selectionChange,
                "select-all": _vm.handleKngSelectAll,
                "sort-change": _vm.kngSortChange,
              },
            },
            [
              _c("yxt-table-column", {
                attrs: {
                  type: "selection",
                  width: "40",
                  "reserve-selection": "",
                  "show-overflow-tooltip": false,
                },
              }),
              _c("yxt-table-column", {
                attrs: {
                  prop: "title",
                  label: _vm.$t("pc_biz_kng_lbl_title"),
                  width: "350",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "yxtbiz-select-newkng__tb-row",
                            attrs: { id: "divname-" + scope.row.id },
                          },
                          [
                            _c("yxtf-svg", {
                              staticClass:
                                "yxtbiz-select-newkng__tb-row-tag mr12",
                              attrs: {
                                width: "24px",
                                height: "24px",
                                "icon-class":
                                  "icons/f_kng-" + scope.row.fileType,
                              },
                            }),
                            _c(
                              "yxt-tooltip",
                              {
                                staticClass:
                                  "yxtbiz-select-newkng__tb-row-name",
                                attrs: {
                                  content: scope.row.title + scope.row.fileExt,
                                  "open-filter": "",
                                  effect: "dark",
                                  placement: "top-start",
                                  "max-width": 500,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-size-14 ellipsis d-in-block",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.title + scope.row.fileExt
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("yxt-table-column", {
                attrs: {
                  prop: "libName",
                  label: _vm.$t("pc_biz_knglib_lbl_libName"),
                  width: "140",
                  "sort-orders": _vm.sortOrders,
                },
              }),
              _c("yxt-table-column", {
                attrs: {
                  prop: "updateFullname",
                  label: _vm.$t("pc_biz_knglib_lbl_updateFullname"),
                  width: "120",
                  sortable: _vm.SOURCE_100_WXISV ? false : "custom",
                  "sort-orders": _vm.sortOrders,
                  "show-overflow-tooltip": false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "yxt-tooltip",
                          {
                            attrs: {
                              "open-filter": "",
                              placement: "top",
                              "max-width": 300,
                              effect: "light",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "ellipsis" },
                              [
                                _c("yxtbiz-user-name", {
                                  attrs: { name: scope.row.updateFullname },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _c("yxtbiz-user-name", {
                                  attrs: { name: scope.row.updateFullname },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("yxt-table-column", {
                attrs: {
                  prop: "updateTime",
                  label: _vm.$t("pc_biz_knglib_lbl_updateTime"),
                  width: "170",
                  sortable: "custom",
                  "sort-orders": _vm.sortOrders,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.dateToString(
                                scope.row.updateTime,
                                "yyyy-MM-dd HH:mm"
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.totalKng > 0
        ? _c(
            "div",
            { staticClass: "mt16 flex-center-end" },
            [
              _c("div", { staticClass: "flex-1" }),
              _c("yxt-pagination", {
                attrs: {
                  total: _vm.totalKng,
                  "show-on-single-page": "",
                  "page-size": _vm.limit,
                  "current-page": _vm.pageIndex,
                  layout: "total, prev, pager, next, sizes",
                },
                on: {
                  "update:pageSize": function ($event) {
                    _vm.limit = $event
                  },
                  "update:page-size": function ($event) {
                    _vm.limit = $event
                  },
                  "update:currentPage": function ($event) {
                    _vm.pageIndex = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.pageIndex = $event
                  },
                  "size-change": _vm.handleKngSizeChange,
                  "current-change": _vm.handleKngCurrentChange,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "yxtbiz-select-newkng__right" }, [
      _c("div", { staticClass: "font-size-14 color-gray-10" }, [
        _vm.max
          ? _c("div", { staticClass: "d-in-block" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("pc_biz_kng_lbl_selected")))]),
              _c("span", [_vm._v(_vm._s(_vm.checkIdLists.length))]),
              _c("span", [_vm._v(" / ")]),
              _c("span", [_vm._v(_vm._s(_vm.max))]),
            ])
          : _c("div", { staticClass: "d-in-block" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("pc_biz_kng_lbl_selectedCount", [
                      _vm.checkIdLists.length,
                    ])
                  )
                ),
              ]),
            ]),
      ]),
      _c(
        "div",
        { staticClass: "yxtbiz-select-newkng__right-main mt12" },
        [
          _c("yxtbiz-virtual-list", {
            staticClass: "yxtbiz-hp100",
            attrs: {
              "is-scrollbar": "",
              "estimate-size": 34,
              keeps: 50,
              "data-sources": _vm.checkIdLists,
              "data-key": "id",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ source }) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "yxt-tag",
                        {
                          attrs: { type: "info", closable: "" },
                          on: {
                            close: function ($event) {
                              return _vm.closeKng(source)
                            },
                          },
                        },
                        [
                          _c(
                            "yxt-tooltip",
                            {
                              attrs: {
                                content: source.title,
                                "open-filter": "",
                              },
                            },
                            [
                              _c("span", { staticClass: "kng-tag-size" }, [
                                _vm._v(_vm._s(source.title)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt12" },
        [
          _c(
            "yxt-button",
            {
              attrs: { disabled: _vm.checkIdLists.length === 0, type: "text" },
              on: { click: _vm.clearAllSelection },
            },
            [
              _vm._v(
                "\n                  " +
                  _vm._s(_vm.$t("pc_biz_kng_btn_clearAll")) +
                  "\n      "
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }