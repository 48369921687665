
import { getChatInfo } from '../service/groupChat';
import { pick } from '../tools';
import { i18n } from 'yxt-i18n/es';
import Eco from 'yxt-eco/es';
const EcoType = Eco.EcoType;
const ecoConfig = Eco.config;
/**
 * chatType对应的生态类型对象
 */
const ECO_MAP = {
  0: EcoType.dingTalk,
  1: EcoType.fs,
  2: EcoType.qywx
};
/**
 * chatType对应的生态类型对象
 */
const CHAT_PROTOCOL_MAP = {
  0: {
    name: 'biz.chat.toConversation',
    pick: ['corpId', ['openId', 'chatId']],
    // 环境不支持时唤起客户端
    openClient() {
      location.href = 'dingtalk://dingtalkclient/page/link';
    },
    // 环境不支持时唤起客户端 钉钉不支持在PC客户端内打开
    invalid() {
      if (window.dd && window.dd.pc) {
        return openFailData(0);
      }
    }
  },
  1: {
    name: 'client/chat/open',
    pick: [['openId', 'openChatId']]
  },
  2: {
    name: 'openExistedChatWithMsg',
    pick: [['openId', 'chatId']],
    openClient() {
      location.href = 'wxwork://';
    }
  }
};

/**
 * 根据API返回的生态类型获取生态名称
 * @param type 回去群聊信息API返回的生态类型
 * @return 生态名称
 */
const getClientName = (type) => {
  const clientName = {
    0: i18n.t('biz_lbl_dingtalk').d('钉钉'),
    1: i18n.t('biz_lbl_feishu').d('飞书'),
    2: i18n.t('biz_lbl_wxwork').d('企业微信')
  }[type];
  return clientName;
};

/**
 * 获取唤起失败后需要返回的错误对象
 * @param type 获取群聊信息API返回的生态类型
 * @returns Object: { error: { message, key } };
 */
const openFailData = (type) => {
  const clientName = getClientName(type);
  return {
    error: {
      message: i18n.t('biz_eco_msg_oepnchaterror', [clientName]).d(`无法唤起群聊，请稍后重试，或直接打开${clientName}聊天窗口`),
      key: 'dont support the current platform (pc)'
    }
  };
};

/**
 * YXTAPP下需要返回的错误对象
 * @param type 获取群聊信息API返回的生态类型
 * @returns Object: { error: { message, key } };
 */
const openFailDataApp = (type) => {
  const clientName = getClientName(type);
  return {
    error: {
      message: i18n.t('biz_eco_msg_oepnchaterror_yxtapp', [clientName]).d(`无法唤起群聊，请直接打开${clientName}聊天窗口`),
      key: 'dont support the current platform (yxtapp)'
    }
  };
};

/**
 * 打开群会话窗口
 * @param {string} chatId
 * 示例：群会话ID
 * @param {object} data
 * @returns Promise
 */
export const openGroupChat = async(chatId) => {
  const chatInfo = await getChatInfo(chatId);
  // 服务端返回的生态类型：0-钉钉，1-飞书，2-企业微信
  const chatType = chatInfo.chatType;
  if (window.isApp || (window.yxtCore && window.yxtCore.isApp)) return Promise.reject(openFailDataApp(chatType));
  // 唤起失败后返回的错误对象
  const openErrorMsg = openFailData(chatType);
  // 获取唤起群聊协议配置，获取不到返回错误提示
  const chatProtocolConfig = CHAT_PROTOCOL_MAP[chatType];
  if (!chatProtocolConfig) return Promise.reject(openErrorMsg);

  // 获取生态鉴权对象
  const configInstanse = ecoConfig(ECO_MAP[chatType], {
    agentId: chatInfo.agentId,
    corpId: chatInfo.corpId
  });
  // 环境不支持时，唤起客户端或者返回错误
  if (!configInstanse.support) {
    if (typeof chatProtocolConfig.openClient === 'function') {
      chatProtocolConfig.openClient();
      return true;
    } else {
      return Promise.reject(openErrorMsg);
    }
  }
  // 根据生态类型，进行鉴权，返回 onReady 鉴权结果
  const configResult = await configInstanse.onReady();
  if (!configResult) return Promise.reject(openErrorMsg);
  // 鉴权成功后，验证协议的有效性
  if (typeof chatProtocolConfig.invalid === 'function') {
    const invalidData = chatProtocolConfig.invalid();
    // 执行
    if (invalidData) return Promise.reject(invalidData);
  }
  // 协议执行结果，执行失败返回false
  const protocolResult = await configInstanse.protocol(chatProtocolConfig.name, pick(chatInfo, chatProtocolConfig.pick)).catch(() => {
    return false;
  });
  if (!protocolResult) return Promise.reject(openErrorMsg);
  // 鉴权成功后，打开会话窗口
  return protocolResult;
};

export default openGroupChat;
