import { udpApi, miscApi } from 'yxt-biz-pc/packages/api';

export const getTree = (params) => {
  return udpApi.get('depts/flattree', { params });
};

export const getTreeChilds = (params) => {
  return udpApi.get('depts/flattree/children/idname', { params });
};

export const treeSearch = (params) => {
  return udpApi.get('depts/flattree/search', { params });
};

export const transformDeptIds = (ids) => {
  return udpApi.post('depts/idroutingpaths', ids);
};

// 获取机构参数
export const getOrgDeptSelectLimit = () => {
  const code = 'orgDeptComponentSelectMaxCount';
  const orgId = window.localStorage.orgId;
  return miscApi.get(`orgparsetting/code?code=${code}&orgId=${orgId}`);
};
