import { Message } from 'yxt-pc';
import { setPlatform } from './service';
import { defaultProductAllPath, defaultProductPath } from './const';
/**
 * 获取导航名称
 * @param {Object} data 导航
 * @param {String} key 名称key
 */
export const Globalize = (data, key) => {
  let oriKey = key;
  let lang = localStorage.lang || 'zh-CN';
  if (lang === 'zh-TW') {
    key = 'haName';
  } else if (lang === 'en') {
    key = 'enName';
  }
  return data[key] || data[oriKey];
};

/**
 * 导向导航页面
 * @param {Object} goPage 导航
 * @param {String} pathname 项目路径（kng、udp...）
 */
export const goNavPage = (goPage, pathname, vm) => {
  if (goPage.type === 5) {
    window.localStorage.setItem('workBenchNavCode', goPage.code); // 记录工作台导航
  }

  let url = goPage.pageUrl;

  if (goPage.openMode !== 1) {
    if (url && url.indexOf(pathname + '#/') > -1) {
      let arr = url.split(pathname + '#/');
      vm.$router.push('/' + arr[1]);
      return;
    }
    if (url) {
      location.href = url;
      return;
    }
  }
  if (url) {
    window.open(url);
    return;
  }
  // hack
  Message.error('无法跳转连接，跳转地址为空');
};

/**
 * 导向导航下的第一子页
 * @param {Object} pageGo 导航
 * @param {String} pathname 项目路径（kng、udp...）
 */
export const goSubNavPage = (pageGo, pathname, vm) => {
  if (pageGo.id === '_app_all') {
    return false; // 全部应用去除点击跳转
  }
  if (
    !pageGo.pageUrl &&
    pageGo.subMenuInfoList &&
    pageGo.subMenuInfoList.length > 0
  ) {
    goSubNavPage(pageGo.subMenuInfoList[0], pathname, vm);
  } else {
    goNavPage(pageGo, pathname, vm);
  }
};

/**
 * 导向第一个有权限的页面
 * @param {*} navs 所有导航信息
 * @param {*} pathname 项目路径（kng、udp...）
 * @param {*} vm vue instance
 * @param {*} firstPageCode 指定编号下的第一个有权限页面
 */
export const goFirstPage = (navs, pathname, vm, firstPageCode) => {
  let isFind = false;
  if (!navs || navs.length === 0) {
    window.location.href = `${defaultProductAllPath}/#/login`; // 前往登录
    return;
  }
  // 导初始页时会寻找第一个此导航下有权限的页面
  navs.forEach(navFirst => {
    if (isFind) {
      return;
    }
    if (
      navFirst.showed &&
      (navFirst.code === firstPageCode || !firstPageCode)
    ) {
      isFind = true;
      let pageTo = navFirst; // 一级
      while (pageTo.subMenuInfoList && pageTo.subMenuInfoList.length > 0) {
        pageTo = pageTo.subMenuInfoList[0];
      }
      console.log(pageTo);
      if (pageTo.pageUrl.indexOf(pathname) >= 0) {
        let urlTo = pageTo.pageUrl.replace(pathname + '#', '');
        vm.$router.replace({
          path: urlTo
        });
      } else {
        window.location.replace(pageTo.pageUrl);
      }
    }
  });
  // 如果导航都隐藏，跳转到无权限页面
  if (!isFind) {
    window.location.replace(`${window.location.origin}/default-pages/403.html`); // 不在白名单，跳转到白名单页面
  }
};

/**
 * 检查导航是否出现更多
 * @param {String} containerId 菜单容器ID
 * @param {String} moreId 更多菜单ID
 * @param {String} mainContainerId 菜单最外主容器ID
 * @param {String} isNotLi 样式控制
 * @param {String} className moreId同级的class
 */
export const checkNavMore = (
  containerId,
  moreId,
  mainContainerId,
  isNotLi,
  className,
  parentPadding = 80
) => {
  let menusContainer = document.getElementById(containerId);
  if (!menusContainer) {
    return {
      max: 999,
      more: false
    };
  }
  let mainContainer = document.getElementById(mainContainerId || containerId);
  let mainContainerP = mainContainer.parentElement;
  let lis = className
    ? menusContainer.getElementsByClassName(className)
    : menusContainer.children;
  let widthNow = 0;
  let widthMax = mainContainer.offsetWidth + 1;
  // 计算菜单容器的最大宽度
  if (mainContainerP) {
    let widthMaxDeal = mainContainerP.offsetWidth + 1 - parentPadding;
    for (let index = 0; index < mainContainerP.children.length; index++) {
      const element = mainContainerP.children[index];
      if (element !== mainContainer) {
        widthMaxDeal -= element.offsetWidth;
      }
    }
    widthMax = Math.min(widthMaxDeal, widthMax);
  }
  let indexMax = 0;
  let more = false;

  for (let index = 0; index < lis.length; index++) {
    const li = lis[index];
    let isHide = li.style.display === 'none';
    let liWidth = li.offsetWidth;
    // 隐藏的恢复显示，获取宽度后再还原
    if (isHide) {
      li.style.display = isNotLi ? 'inline-block' : 'list-item';
      liWidth = li.offsetWidth;
      li.style.display = 'none';
    }
    widthNow += liWidth;
    if (widthNow <= widthMax) {
      indexMax = index;
    } else {
      widthNow -= liWidth;
      break;
    }
  }
  // 计算能展示的最大个数
  if (indexMax < lis.length - 1) {
    more = true;
    // 超出了，需要计算更多的宽度
    let menuMore = document.getElementById(moreId);
    menuMore.style.display = isNotLi ? 'inline-block' : 'list-item';
    if (widthNow + menuMore.offsetWidth > widthMax) {
      indexMax--;
    }
  }
  return {
    max: indexMax + 1,
    more: more
  };
};

/**
 * 获取元素滚动的高度
 * @param {HTMLElement} dom 菜单容器ID
 */
export const getScrollTop = dom => {
  if (dom) {
    if (dom.parentElement) {
      if (dom.style.position === 'fixed') {
        return dom.scrollTop + dom.offsetTop;
      } else {
        return dom.scrollTop + getScrollTop(dom.parentElement);
      }
    } else {
      return dom.scrollTop;
    }
  }
  return 0;
};

/**
 * 是否中文字符
 * @param {string} letter
 */
const isChinese = letter => {
  return /^[\u4e00-\u9fa5]$/.test(letter);
};

/**
 * 截取字符串
 * @param {string} name 字符串
 */
export const getShortName = (name, maxLen = 4) => {
  if (name.length <= maxLen) return name;
  maxLen = maxLen * 2; // 中文字算长度，英文2个算1个
  let arr = name.split('');
  let nameArr = [];
  let leng = 0;
  for (let index = 0; index < arr.length; index++) {
    let letter = arr[index];
    let weight = isChinese(letter) ? 2 : 1;
    if (leng + weight <= maxLen) {
      nameArr.push(letter);
      leng += weight;
    } else {
      nameArr.push('...');
      break;
    }
  }
  return nameArr.join('');
};

/**
 * 用户登出方法
 * @param {string} platform 1-管理端 2-学习平台
 */
export const uerLogout = platform => {
  // 退出前记录用户平台
  setPlatform(platform)
    .catch()
    .then(() => {
      window.location.href = '/#/logout'; // 退出登录
    });
};

/**
 * 用户切换到其他平台
 * @param {number} platform 1-管理端 2-学习平台（获取用户记录的管理端还是学员端，进行跳转） 3-学员端
 * @param {string} url 页面地址
 * @param {string} path 页面path
 */
export const platformDirect = (platform, url, path = '') => {
  // 记录用户平台
  let newPath = path || defaultProductPath;

  setPlatform(platform)
    .catch()
    .then(() => {
      if (url) {
        window.location.href = url;
      } else {
        switch (platform) {
          case 1:
            window.location.href = `${newPath}/#/index`; // 管理端首页
            break;
          case 2:
            window.location.href = `${newPath}/#/studyindex?norequest=1`; // 学习首页(norequest:不请求用户平台的接口)
            break;
          default:
            break;
        }
      }
    });
};
// 100 微信isv本
export const SOURCE_100_WXISV =
  window.localStorage.getItem('sourceCode') &&
  window.localStorage.getItem('sourceCode') === '100';
// 300 工具版本
export const EDITION_TOOL =
  window.localStorage.getItem('editionCode') &&
  window.localStorage.getItem('editionCode') === '10';

export function trackError(val) {
  if (window.sensors) {
    window.sensors.track('errorLog', {
      logCode: 'nav_error',
      logContent: JSON.stringify(val)
    });
  }
}
