import { msgApi, orginitApi, getDomains, emptyInstance, qidaApi } from 'yxt-biz-pc/packages/api';
const getGroupLoginAuthCode = (data) => {
  return qidaApi.post('/generateGroupCode', data);
};

export const domainBaseUrl = (module) => {
  return getDomains()[module];
};

/**
 * 获取当前机构的移动端域名
 */
export const getMobileDomain = async() => {
  let h5Domain = '';
  try {
    let config = await msgApi.get('/ding/h5');
    if (config && config.isCustomDingDingApp && config.url) {
      h5Domain = config.url;
    } else {
      h5Domain = domainBaseUrl('commonDomainH5');
    }
  } catch (error) {
    h5Domain = domainBaseUrl('commonDomainH5');
  }
  return h5Domain;
};

/**
 * 获取当前机构的域名
 */
export const getDomain = async() => {
  let isAny = window.localStorage.isVisitor === '1';
  let h5Domain = '';
  let pcDomain = window.location.origin + '/';
  try {
    let config = await msgApi.get(isAny ? '/ding/temp/h5' : 'ding/h5');
    pcDomain = config.pcUrl;
    if (config && config.isCustomDingDingApp && config.url) {
      h5Domain = config.url;
    } else {
      h5Domain = domainBaseUrl('commonDomainH5');
    }
  } catch (error) {
    h5Domain = domainBaseUrl('commonDomainH5');
  }
  return { pcDomain, h5Domain };
};

/**
 * 获取短地址
 * @param {*} pcUrl PC端的项目地址
 * @param {*} h5Url h5端的项目地址
 * @param {*} isv 是否支持游客 0 登录模式，1 游客模式
 * @param {*} orgId 机构ID, 默认为当前localstorage获取
 * @returns 生成的短连接
 */
export const getShortUrl = async(pcUrl, h5Url, isv, orgId) => {
  orgId = orgId || window.localStorage.orgId || '';
  const { shortUrl } = await orginitApi.post('/isv/scanentryurl/info', {
    'pcUrl': pcUrl || '',
    'h5Url': h5Url || '',
    'isv': isv ? 1 : 0,
    'orgId': orgId
  });
  return shortUrl;
};

/**
 *  解析短地址
 *  @param {string} shortUrl 短链地址
 *
 */
export const parseShortUrl = async(shortUrl) => {
  let url = shortUrl;
  let currOrgId = window.localStorage.getItem('orgId');
  let targetOrgId = '';
  let domain = '';
  let search = '';
  try {
    let { data } = await emptyInstance.get(shortUrl + '/url');
    if (data.pcUrl) {
      url = data.pcUrl;
    } else if (data.url) {
      url = data.url;
    } else {
      return (window.location.href = url);
    }
  } catch (e) {
    return (window.location.href = url);
  }
  url.replace(/(.*)auth\/scanentry\/([^?]{1,})\?(.*)/, (res, _domain, orgId, _search)=>{
    domain = _domain;
    targetOrgId = orgId;
    search = _search;
    return res;
  });
  if (currOrgId === targetOrgId) {
    window.location.href = url;
  } else {
    getGroupLoginAuthCode({
      targetDomain: domain,
      targetOrgId: targetOrgId
    }).then(res=>{
      if (res.code) {
        // 切换域名跳转至登录授权页
        window.location.href = `${domain}/#/group/auth?code=${res.code}&${search}`;
      } else {
        window.location.href = url;
      }
    }).catch(()=>{
      window.location.href = url;
    });
  }
};

/**
 * 获取配置中心下发的静态cdn资源地址
 */
export const getStaticCdnUrl = () => {
  const baseCommon = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common);
  const imagesBaseUrl = (baseCommon && window.feConfig.common.imagesBaseUrl) || 'https://images.yxt.com/';
  const staticBaseUrl = (baseCommon && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/';
  return { imagesBaseUrl, staticBaseUrl };
};
