import { Image } from 'yxt-pc';
import Vue from 'vue';

const closestEl = (el, s) => {
  if (el.closest) {
    return el.closest(s);
  }
  do {
    if (el.matches(s)) return el;
    el = el.parentElement || el.parentNode;
  } while (el !== null && el.nodeType === 1);
  return null;
};

/**
 * 绑定图片预览事件
 * @param {*} mainEl 绑定的主元素dom, 此元素下的 [rich-text] 属性内的图片点击会弹出预览窗口
 * @returns
 */
const bindImgPreviewer = (mainEl) =>{
  try {
    // 父元素处理过就不需要处理了
    if (!(mainEl && mainEl.addEventListener && !mainEl.hasAttribute('data-ue-img-preview') &&
          (!closestEl(mainEl, '[data-ue-img-preview]') || mainEl.hasAttribute('data-rich-text-stop')))) {
      return;
    }

    // 指定单个内容绑定时，如果没有图片直接跳过
    if (mainEl.hasAttribute('data-rich-text-stop') && !mainEl.querySelector('img')) {
      return;
    }

    // 打标记， 防止重复绑定
    mainEl.setAttribute('data-ue-img-preview', '1');

    // 增加富文本图片点击放大预览的功能
    mainEl.addEventListener(
      'click',
      imgPreviewerHandler
    );
  } catch (error) {
  }
};

const removeImgPreviewer = (mainEl) => {
  // 移除
  if (mainEl) {
    mainEl.removeEventListener(
      'click',
      imgPreviewerHandler
    );
    mainEl.removeAttribute('data-ue-img-preview');
  }
};

let pointerEventsIsNone = false;
let previewImgUrl = '';
let imgPreviewer = null;
const imgPreviewerHandler = (e) => {
  try {
    const clickedElement = e.target;
    const richDom = closestEl(clickedElement, '[data-rich-text="1"]');
    if (richDom) {
      let src = '';
      pointerEventsIsNone = false;
      // 提取点击的图片地址
      if (clickedElement.tagName === 'IMG') {
        src = clickedElement.src;
      } else if (
        clickedElement.children &&
          clickedElement.children.length > 0
      ) {
        const clickX = e.clientX;
        const clickY = e.clientY;
        // 处理p标签内包裹行级图片，导致click触发对象为p的问题。这里判断是否落点为子元素的p标签
        for (let index = 0; index < clickedElement.children.length; index++) {
          const element = clickedElement.children[index];
          if (element.tagName === 'IMG' && !src) {
            const imgRect = element.getBoundingClientRect();
            if (
              clickX >= imgRect.left &&
                clickX <= imgRect.right &&
                clickY >= imgRect.top &&
                clickY <= imgRect.bottom
            ) {
              src = element.src;
              // 防作弊可能会让图片不可操作，这里预览也需要处理
              pointerEventsIsNone = true;
            }
          }
        }
      }
      if (src) {
        previewImgUrl = src;
        if (!imgPreviewer) {
          imgPreviewer = new (Vue.extend(Image))({
            propsData: {
              class: 'd-none',
              src: previewImgUrl,
              zIndex: 10000,
              previewSrcList: [previewImgUrl]
            }
          });
          imgPreviewer.$mount();
          imgPreviewer.$el.style.display = 'none';

          try {
            imgPreviewer.$refs.imageViewer.addEventListener('contextmenu', (e) => {
            // 禁止右键下载， 直接去掉pointerEvent会影响正常操作
              pointerEventsIsNone && e.preventDefault();
            });
          } catch (error) {
          }

          document.body.appendChild(imgPreviewer.$el);
        } else {
          imgPreviewer.src = previewImgUrl;
          imgPreviewer.previewSrcList = [previewImgUrl];
        }

        imgPreviewer.clickHandler();

        e.preventDefault();

        richDom.hasAttribute('data-rich-text-stop') && e.stopPropagation();
      }
    }
  } catch (error) {
  }
};

export {
  bindImgPreviewer,
  removeImgPreviewer
};
