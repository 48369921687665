import Api from '../api';
import { source } from '../api/configDomain';
import privateContactAdapter from '../api/privateContactAdapter';

const getApi = function(productType) {
  const http = Api.create({
    baseURL: `${window.feConfig.common['apiBaseUrl']}core/`,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      source,
      'x-yxt-product': productType
    },
    validateStatus: status => {
      return status < 400;
    },
    adapter: privateContactAdapter
  });
  return http;
};

/**
 * 检查导航编号下的权限点权限
 * @param {String} pageCode 页面编号
 * @param {String} actionCode 操作编号
 */
let checkActionPermission = function(pageCode, actionCode, vm) {
  // debugger
  try {
    if (!vm) {
      vm = this;
    }
    if (
      !pageCode ||
      !actionCode ||
      !vm ||
      !vm.$store ||
      !vm.$store.state.navManageStore
    ) {
      return false;
    }
    let per = false;
    let navsAll = vm.$store.state.navManageStore.navList;
    if (navsAll && navsAll.length > 0) {
      for (let index = 0; index < navsAll.length; index++) {
        const element = navsAll[index];
        if (
          element.code === pageCode &&
          element.pointActions &&
          element.pointActions.length > 0 &&
          element.pointActions.indexOf(actionCode) >= 0
        ) {
          per = true;
          break;
        }
      }
    }
    return per;
  } catch (error) {
    // error
  }
  return false;
};
export default checkActionPermission;

/**
 * 获取导航数据接口
 * @param {Number} type  导航类型 非必传 1:pc学员端,2:pc管理端,默认管理端
 * @param {String} productType 产品类型 非必传 学发:'',人发:'sp'
 */
export const getNavData = async function({ type = 2, productType = '' }) {
  const navCatchKey = !productType ? `nav_datas_${type}` : `${productType}_nav_datas_${type}`;
  let navAllDatas = JSON.parse(localStorage[navCatchKey] || '[]');
  let navDatas = (navAllDatas && navAllDatas[0] && navAllDatas[0].datas) || [];
  if (!navDatas.length) {
    try {
      const api = getApi(productType);
      const res = await api.get(`user/nav/self?type=${type}`);
      // 和localstorage结构保持一致
      navDatas = res.datas;
    } catch (error) {
      console.error('获取导航失败', error);
      navDatas = [];
    }
  }
  return navDatas;
};

/**
 * 异步获取导航下的权限点
 * @param {String} pageCode 页面编号,非必传,不传则默认当前页面的pageCode
 * @param {String} actionCode 操作编号,必传
 * @param {Number} type  导航类型 非必传 1:pc学员端,2:pc管理端,默认管理端
 * @param {String} productType 产品类型 非必传 学发:'',人发:'sp'
 */
export const asyncCheckActionPermission = async function({
  pageCode,
  actionCode,
  type = 2,
  productType = '',
  vm
}) {
  try {
    if (!vm) {
      vm = this;
    }
    if (!pageCode) {
      pageCode =
        vm.$route.matched.meta.functionCode || vm.$route.matched.meta.pageCode;
    }
    if (!pageCode || !actionCode || !vm) {
      return false;
    }
    let navsAll = [];
    if (!type) {
      // 没有指定导航类型，则默认获取当前导航
      if (vm.$store && vm.$store.state.navManageStore) {
        navsAll = vm.$store.state.navManageStore.navList;
      }
    } else {
      // 有指定导航类型，则获取指定导航数据
      navsAll = await getNavData({type, productType});
    }
    let per = false;
    if (navsAll && navsAll.length > 0) {
      for (let index = 0; index < navsAll.length; index++) {
        const element = navsAll[index];
        if (
          element.code === pageCode &&
          element.pointActions &&
          element.pointActions.length > 0 &&
          element.pointActions.indexOf(actionCode) >= 0
        ) {
          per = true;
          break;
        }
      }
    }
    return per;
  } catch (error) {}
  return false;
};
