import DeviceInfo from './device.js';
export default class WebSocketClass {
  /**
   * @description: 初始化实例属性，保存参数
   * @param {String} url ws的接口
   * @param {Function} msgCallback 服务器信息的回调传数据给函数
   * @param {String} name 可选值 用于区分ws，用于debugger
   */
  constructor(url, msgCallback, name = 'default') {
    this.url = url;
    this.msgCallback = msgCallback;
    this.name = name;
    this.ws = null; // websocket对象
    this.status = null; // websocket是否关闭
    this.data = null;
    this.deviceId = DeviceInfo.createUuid(); // 获取设置ID
    this.heartBeatTimer = 0; // 心跳监测timer
    this.heartBeatTimeout = 5000; // 心跳监测间隔时间
    this.heartBeatNum = 10; // 心跳监测次数
    this.reConnectTimer = 0; // 断开重连timer
    this.reConnectTimeout = 5000; // 断开重连时间间隔
  }
  /**
   * @description: 初始化 连接websocket或重连webSocket时调用
   * @param {*} 可选值 要传的数据
   */
  connect(data) {
    // 重连时保证参数存在
    if (!this.data && data) this.data = data;

    this.data = {
      ...(this.data || {}),
      messageId: this.deviceId
    };

    // 新建 WebSocket 实例
    this.ws = new WebSocket(this.url);
    this.ws.onopen = e => {
      // 连接ws成功回调
      this.status = 'open';
      console.log(`${this.name}连接成功`, e);
      this.heartBeatHandle();
      if (data !== undefined) {
        // 有要传的数据,就发给后端
        return this.ws.send(JSON.stringify(this.data));
      }
    };
    // 监听服务器端返回的信息
    this.ws.onmessage = e => {
      const data = JSON.parse(e.data) || {};
      if (data && data.state === 3) { // 超过最大链接数
        this.closeMyself();
        return;
      } else if (data && data.state === 4) { // 心跳返回值\
        this.heartBeatHandle();
        return;
      }
      this.heartBeatHandle();
      if (data.messageId === this.deviceId) {
        return this.msgCallback(e.data);
      }
    };
    // ws关闭回调
    this.ws.onclose = e => {
      this.closeHandle(e); // 判断是否关闭
    };
    // ws出错回调
    this.onerror = e => {
      this.closeHandle(e); // 判断是否关闭
    };
  }
  // 获取心跳参数
  getHeartBeatPrams() {
    return {// 心跳监测参数
      'messageId': DeviceInfo.createUuid(), // 消息id，每一次请求，一个唯一值，可直接生成
      'type': 'heartbeat', // 心跳类型
      'body': {}, // 扩展字段，非必填
      'createTime': new Date() // 消息创建时间
    };
  }
  // 心跳监测函数
  heartBeatHandle() {
    if (this.heartBeatTimer) {
      clearTimeout(this.heartBeatTimer);
    }
    this.heartBeatTimer = setTimeout(() => {
      this.ws && (this.ws.send(JSON.stringify(this.getHeartBeatPrams())));
    }, this.heartBeatTimeout);
  }
  // 重连
  reconnect() {
    this.connect(this.data);
  }
  // 发送信息给服务器
  sendHandle(data, cb) {
    if (!data) return;
    if (this.ws.readyState !== 1) return;
    this.deviceId = DeviceInfo.createUuid();
    data = {
      ...data,
      messageId: this.deviceId
    };
    console.log(`${this.name}发送消息给服务器:`, data);
    cb && cb(data);
    return this.ws.send(JSON.stringify(data));
  }
  closeHandle(e = 'err') {
    // 因为webSocket并不稳定，规定只能手动关闭(调closeMyself方法)，否则就重连
    // if (this.status !== 'close') {
    //   console.log(`${this.name}断开，重连websocket`, e)
    //   this.reConnectTimer = setTimeout(() => {
    //     this.connect() // 重连
    //   }, this.reConnectTimeout)
    // } else {
    //   console.log(`${this.name}websocket手动关闭`)
    // }
  }
  // 手动关闭WebSocket
  closeMyself() {
    clearTimeout(this.reConnectTimer);
    console.log(`关闭${this.name}`);
    this.status = 'close';
    return this.ws.close();
  }
}
