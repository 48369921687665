import { addResizeListener, removeResizeListener } from 'yxt-biz-pc/src/utils/resize-event';
import scrollbarWidth from 'yxt-biz-pc/src/utils/scrollbar-width';

export default {
  data() {
    return {
      scrollHeight: 0
    };
  },
  computed: {
    gutter() {
      return scrollbarWidth();
    },
    scrollWrapHeight() {
      return `calc(100% + ${this.gutter}px)`;
    }
  },
  mounted() {
    this.tableHeight = this.$refs[this.refTable].$el.clientHeight;
    console.log('tableHeight', this.tableHeight);
    if (this.$refs.tree) {
      this.$refs.tree.$el.querySelector('.yxt-scrollbar__wrap').style.height = this.scrollWrapHeight;
    }
    addResizeListener(document.body, this.updateSize);
  },
  updated() {
    if (this.$refs.tree) {
      this.$refs.tree.$el.querySelector('.yxt-scrollbar__wrap').style.height = this.scrollWrapHeight;
    }
  },
  beforeDestroy() {
    removeResizeListener(document.body, this.updateSize);
  },
  methods: {
    updateSize() {
      if (this.$refs[this.refTable]) {
        this.tableHeight = this.$refs[this.refTable].$el.clientHeight;
        this.$refs[this.refTable].doLayout();
      }
    }
  }
};
