import { surveyApi } from 'yxt-biz-pc/packages/api';

export const getTemplates = (query) => {
  const { current, limit, direction, orderby, ...body } = Object.assign({}, query);

  return surveyApi.post('/templates/choose/list', body, {
    params: {
      current,
      limit,
      direction,
      orderby
    }
  });
};

export const getPreviewUrl = (templateId) => {
  return surveyApi.get('/outer/urls/preview', {
    objId: templateId,
    objType: 3
  });
};

export const getCreateUrl = (types) => {
  return surveyApi.post('/temp/templates', {
    types
  });
};

export const getCatalogSelefList = () => {
  return surveyApi.get('/temp/category/self/list');
};

