<template>
  <div class="yxt-user-selector-wrap yxt-user-single-wrap">
    <yxt-row class="yxt-user-selector-container">
      <yxt-col class="yxt-user-tree">
        <dept-tree
          :wrap-width="260"
          :enableGroupCorp="enableGroupCorp"
          :functionCode='functionCode'
          :dataPermissionCode='dataPermissionCode'
          :targetOrgId='userData.targetOrgId'
          @nodeClick="selectDept"
          :filterable="true"
          ref="tree"
          @org-change="handleOrgChange"
        ></dept-tree>
      </yxt-col>
      <yxt-col class="yxt-user-right">
        <yxt-input
          :placeholder="$t('pc_biz_te_tip_enternameaccountsearch')"
          v-model="userValue"
          style="width:240px"
          searchable
          @search="searchUser"
          class='mb16'
        >
        </yxt-input>
        <yxt-table
          v-loading="loading"
          ref="userTable"
          class="yxt-user-table"
          :height="tableHeight"
          :data="users"
        >
          <template slot="empty">
            <div style="line-height:normal">
              <yxt-empty />
            </div>
          </template>
          <yxt-table-column :show-overflow-tooltip="true" :label="$t('pc_biz_udp_lbl_employee')" popover-html>
            <template slot-scope="scope">
              <yxtbiz-user-name v-if='isOpenData' :name='scope.row.fullname' />
              <span
                v-else
                v-html="scope.row.fullname + '(' + scope.row.username + ')'"
              ></span>
            </template>
          </yxt-table-column>
          <yxt-table-column
            :label="$t('biz_udp_position')"
            :show-overflow-tooltip="true"
            :formatter="formatPostion"
          ></yxt-table-column>
          <yxt-table-column :label="$t('pc_biz_udp_lbl_operation')" width="108" align="left">
            <template slot-scope="scope">
              <div class="text-right">
                <yxt-link
                  @click="selectUser(scope.row)"
                  :underline="false"
                  type="primary"
                  >{{ value.id === scope.row.id ? $t('biz_udp_cancel_selected') : $t('pc_biz_udp_lbl_select') }}</yxt-link
                >
              </div>
            </template>
          </yxt-table-column>
        </yxt-table>
        <div class="yxtbiz-user-selector-mask mt16 clearfix">
          <yxt-pagination
            v-if="totalPage > 1"
            class="pull-right"
            :page-size="userData.limit"
            :current-page="page"
            @size-change="sizeChange"
            @current-change="pageChange"
            :page-count="totalPage"
            simple-total
            layout="total, prev, pager, next"
            :total="totalCount"
          ></yxt-pagination>
        </div>
      </yxt-col>
    </yxt-row>
  </div>
</template>

<script>
import { getUserList } from './service';
import deptTree from '../../dept-tree';
import resizeTable from '../../user-selector/mixins/resizeTable';
import { Empty, Table, Pagination, TableColumn, Link } from 'yxt-pc';
import { isOpenData } from 'yxt-biz-pc/src/utils/shared';
import UserName from 'yxt-biz-pc/packages/user-name';

export default {
  name: 'YxtSingleUser',
  mixins: [resizeTable],
  components: {
    deptTree,
    YxtEmpty: Empty,
    YxtTable: Table,
    YxtPagination: Pagination,
    YxtTableColumn: TableColumn,
    YxtLink: Link,
    YxtbizUserName: UserName
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    scope: {
      type: Number,
      default: 0
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    enableGroupCorp: {
      type: Boolean,
      default: false
    },
    targetOrgId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      refTable: 'userTable',
      userValue: '',
      page: 1,
      totalCount: 0,
      tableHeight: 0,
      userData: {
        scope: this.scope,
        navCode: this.functionCode,
        dataPermissionCode: this.dataPermissionCode,
        deptId: '',
        searchKey: '',
        limit: 20,
        offset: 0,
        type: 1
      },
      loading: true,
      totalPage: 0,
      users: [],
      formatPostion: row => {
        if (row.positionName === undefined) {
          return (
            row.positionSet
              .map(item => {
                return item.name;
              })
              .join(';') || '--'
          );
        } else {
          const parttimePosition = row.parttimePositionSet ? Array.from(row.parttimePositionSet) : [];
          const posList = parttimePosition.map(item => item.name);
          posList.push(row.positionName || '');
          return posList.filter(item => item !== '').join(';') || '--';
        }
      }
    };
  },
  computed: {
    isOpenData() {
      return isOpenData();
    }
  },
  created() {
    this.userData.targetOrgId = this.targetOrgId;
    if (!this.enableGroupCorp) this.getUserData();
  },
  methods: {
    handleOrgChange(data) {
      this.userData.targetOrgId = data.orgId;
      this.userData.deptId = '';
      this.getUserData();
    },
    selectDept(item) {
      this.userData.deptId = item.id;
      this.userData.offset = 0;
      this.page = 1;
      this.getUserData();
    },
    getUserData() {
      this.loading = true;
      getUserList(this.userData, this.enableGroupCorp)
        .then(result => {
          this.users = result.datas;
          this.totalPage = result.paging.pages;
          this.totalCount = result.paging.count;
          this.loading = false;
        })
        .catch(e => {
          this.users = [];
          this.loading = false;
        });
    },
    searchUser() {
      this.userData.searchKey = this.userValue;
      this.userData.offset = 0;
      this.page = 1;
      this.getUserData();
    },
    sizeChange(size) {
      this.userData.limit = size;
      this.page = 1;
      this.userData.offset = 0;
      this.getUserData();
    },
    pageChange(page) {
      this.page = page;
      this.userData.offset = (page - 1) * this.userData.limit;
      this.getUserData();
    },
    selectUser(item) {
      this.$emit('input', item.id === this.value.id ? {} : item);
    }
  }
};
</script>

<style lang="scss" scoped>
.yxt-user-single-wrap .yxt-user-tree {
  width: 360px!important;
}
</style>
