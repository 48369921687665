<template>
  <div class="yxtbiz-qs-random">
    <yxt-button type="primary" @click="open">
      <!--   快速抽题   -->
      {{ $t('pc_biz_ote_lbl_quickques')
      }}<yxt-svg
        class="v-mid ml4 mb2"
        width="14px"
        height="14px"
        :icon-class="show ? 'arrow-up' : 'arrow-down'"
      />
    </yxt-button>
    <yxt-drawer
      size="960px"
      :title="$t('pc_biz_ote_lbl_quesbasket')"
      :wrapperClosable="true"
      :append-to-body="true"
      :before-close="handleCancel"
      :visible.sync="show"
    >
      <yxt-form label-position="top">
        <yxt-form-item
          :label="$t('pc_biz_ote_lbl_getquestype')"
          size="small"
          required
        >
          <yxt-radio-group
            v-model="selectQStyle"
            :direction="'row'"
            class="yxtbiz-qs-random__types"
            @change="getSelectQStyleCount"
          >
            <template 
              v-for="(item, index) in selectQStyleList">
              <yxt-radio
                :key="index"
                :label="index"
                class="d-in-flex-i"
              >
                <div>{{ $t(item) }}</div>
                <div
                  class="yxtbiz-qs-random__type-items"
                  v-loading="loadingCount"
                  v-if="selectQStyle === index && index === 0"
                >
                  <yxt-table :data="quesList">
                    <!-- 题型 -->
                    <yxt-table-column
                      :show-overflow-tooltip="true"
                      :label="$t('pc_biz_ote_lbl_questiontype')"
                      min-width="80"
                      prop="type"
                      fixed
                    >
                    </yxt-table-column>
                    <!-- 难易度 -->
                    <yxt-table-column
                      :show-overflow-tooltip="false"
                      min-width="158"
                      v-for="lvt in QUES_LEVEL_NAMES.values"
                      :label="$t(QUES_LEVEL_NAMES[lvt])"
                      :key="'lvt_' + lvt"
                    >
                      <template slot-scope="scope">
                        <span class="yxtbiz-qs-random__inputer">
                          <yxt-input-number
                            v-model="scope.row['num' + lvt]"
                            controls-position="right"
                            :min="0"
                            :max="quesMaxInut(scope.row.val[lvt])"
                            :formatter="(e) => e || 0"
                            size="small"
                            :step="1"
                            step-strictly
                          ></yxt-input-number>
                          <span class="standard-size-12"> ≤{{ quesMaxMethod(scope.row.val[lvt]) }}</span>
                        </span>
                      </template>
                    </yxt-table-column>
                  </yxt-table>
                </div>
                <div
                  class="yxtbiz-qs-random__type-items"
                  v-loading="loadingCount"
                  v-else-if="selectQStyle === index && index === 1"
                >
                  <div v-for="(ql,index) in quesList" :key="'qt_' + ql.quesType"
                  :class="{
                    'pr24': index < quesList.length -1
                  }">
                    <div>{{ ql.type }}</div>
                    <div class="mt8">
                      <yxt-input-number
                        v-model="ql.num0"
                        controls-position="right"
                        :min="0"
                        :max="quesMaxInut(ql.val[0])"
                        :formatter="(e) => e || 0"
                        size="small"
                        :step="1"
                        step-strictly
                      ></yxt-input-number>
                      ≤{{ quesMaxMethod(ql.val[0]) }}
                    </div>
                  </div>
                </div>
                <div
                  class="yxtbiz-qs-random__type-items"
                  v-loading="loadingCount"
                  v-else-if="selectQStyle === index && index === 2"
                >
                  <div v-for="lvt in QUES_LEVEL_NAMES.values" :key="'lvt_' + lvt" class="mr24">
                    <div>{{ $t(QUES_LEVEL_NAMES[lvt]) }}</div>
                    <div class="mt8">
                      <yxt-input-number
                        v-model="quesList[0]['num' + lvt]"
                        controls-position="right"
                        :min="0"
                        :max="quesMaxInut(quesList[0].val[lvt])"
                        :formatter="(e) => e || 0"
                        size="small"
                        :step="1"
                        step-strictly
                      ></yxt-input-number>
                      ≤{{ quesMaxMethod(quesList[0].val[lvt]) }}
                    </div>
                  </div></div>
                <div
                  class="yxtbiz-qs-random__type-items"
                  v-loading="loadingCount"
                  v-else-if="selectQStyle === index && index === 3"
                >
                  <div>
                      <yxt-input-number
                        v-model="quesList[0].num0"
                        controls-position="right"
                        :min="0"
                        :max="quesMaxInut(quesList[0].val[0])"
                        :formatter="(e) => e || 0"
                        size="small"
                        :step="1"
                        step-strictly
                      ></yxt-input-number>
                      ≤{{ quesMaxMethod(quesList[0].val[0]) }}
                    </div>
                  </div>
              </yxt-radio>
              <br :key="'br_'+index"/>
            </template>
          </yxt-radio-group>
        </yxt-form-item>
      </yxt-form>
      <div slot="footer">
        <yxt-button plain @click="handleCancel()">{{
          $t('pc_biz_ote_btn_cancle' /* 取消 */)
        }}</yxt-button>
        <yxt-button
          type="primary"
          :disabled="loadingBtn"
          @click="save"
          >{{ $t('pc_biz_ote_btn_confirm' /* 确定 */) }}</yxt-button
        >
      </div>
    </yxt-drawer>
  </div>
</template>

<script>
import * as examService from '../service';
import { QUES_TYPE_NAMES, QUES_LEVEL_NAMES, handleError } from '../utils';

export default {
  name: 'randomSelect',
  props: {
    quesTypes: {
      type: Array,
      default: []
    },
    pointIds: {
      default() {
        return [];
      },
      type: Array
    }
  },
  data() {
    return {
      QUES_LEVEL_NAMES,
      selectQStyle: 0, // 选题方式(0:按试题题型+试题难度; 1:按试题题型; 2:按试题难度; 3:试题数量)
      selectQStyleList: [
        'pc_biz_ote_lbl_getquestypeof0',
        'pc_biz_ote_lbl_getquestypeof1',
        'pc_biz_ote_lbl_getquestypeof2',
        'pc_biz_ote_lbl_getquestypeof3'
      ],
      selectQStyleCountInfo: [],
      loadingBtn: false, // 按钮loading
      loadingCount: false,
      show: false, // 打开弹框
      quesList: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    handleError,
    open() {
      if (this.pointIds.length === 0) {
        this.$message.warning(this.$t('pc_biz_ote_msg_selqueslib'));
        return;
      }
      this.show = !this.show;
      this.selectQStyle = 0;

      this.getSelectQStyleCount();
    },
    init() {
      this.quesList = this.quesTypes.map(e => {
        return {
          type: this.$t(QUES_TYPE_NAMES[e]),
          quesType: e,
          val: [0, 0, 0, 0, 0],
          num0: 0,
          num1: 0,
          num2: 0,
          num3: 0,
          num4: 0
        };
      });
    },
    getSelectQStyleCount() {
      if (this.selectQStyleCountInfo[this.selectQStyle]) {
        this.changeSelectQStyle(this.selectQStyleCountInfo[this.selectQStyle]);
        return;
      }

      this.loadingCount = true;
      examService
        .getQuesChoiceQty(this.pointIds, this.selectQStyle, this.quesTypes)
        .then(resOrigin => {
          this.selectQStyleCountInfo[this.selectQStyle] = resOrigin;
          this.changeSelectQStyle(resOrigin);
        })
        .catch(err => {
          this.loadingCount = false; // 关闭loading
          this.handleError.bind(this)(err);
        });
    },
    changeSelectQStyle(resOrigin) {
      let res = resOrigin;
      if (this.selectQStyle === 1) {
        res = [resOrigin];
      } else if (this.selectQStyle === 3) {
        res = [
          {
            levelType: 0,
            maxQty: resOrigin
          }
        ];
      }

      this.init();
      this.loadingCount = false;

      let arr = [
        'singleMaxQty',
        'multiMaxQty',
        'judgeMaxQty',
        'fillMaxQty',
        'qaMaxQty',
        'compositeMaxQty'
      ]; // Max选题
      if (this.selectQStyle === 2) {
        QUES_LEVEL_NAMES.values.forEach((lv, index) => {
          const lvMaxData = res.find(e => e.levelType === lv);
          this.quesList[0].val[lv] = lvMaxData ? lvMaxData['maxQty'] : 0;
        });
      } else if (this.selectQStyle === 3) {
        this.quesList[0].val[0] = res[0]['maxQty'];
      } else {
        this.quesList.forEach((item, index) => {
          const t = item.quesType;
          QUES_LEVEL_NAMES.values.forEach(lv => {
            const lvMaxData = res.find(e => e.levelType === lv);
            item.val[lv] = lvMaxData ? lvMaxData[arr[t]] : 0;
          });
        });
      }
    },
    save() {
      this.loadingBtn = true;
      const reqObj = {
        pointIds: this.pointIds,
        types: this.quesTypes
      };
      let count = 0;
      switch (this.selectQStyle) {
        case 0:
          reqObj.quickChoice4GetList = [];
          for (let i = 0; i < QUES_LEVEL_NAMES.values.length; i++) {
            const numKey = 'num' + i;
            const data = {
              singleQty: this.getTypeSet(0)[numKey],
              multiQty: this.getTypeSet(1)[numKey],
              judgeQty: this.getTypeSet(2)[numKey],
              fillQty: this.getTypeSet(3)[numKey],
              qaQty: this.getTypeSet(4)[numKey],
              compositeQty: this.getTypeSet(5)[numKey],
              levelType: i
            };

            reqObj.quickChoice4GetList.push(data);
            count += data.singleQty +
                    data.multiQty +
                    data.judgeQty +
                    data.fillQty +
                    data.qaQty +
                    data.compositeQty;
          }
          break;
        case 1:
          const numKey = 'num0';
          reqObj.quickChoice4QuestionType = {
            singleQty: this.getTypeSet(0)[numKey],
            multiQty: this.getTypeSet(1)[numKey],
            judgeQty: this.getTypeSet(2)[numKey],
            fillQty: this.getTypeSet(3)[numKey],
            qaQty: this.getTypeSet(4)[numKey],
            compositeQty: this.getTypeSet(5)[numKey]
          };
          count += reqObj.quickChoice4QuestionType.singleQty +
          reqObj.quickChoice4QuestionType.multiQty +
          reqObj.quickChoice4QuestionType.judgeQty +
          reqObj.quickChoice4QuestionType.fillQty +
          reqObj.quickChoice4QuestionType.qaQty +
          reqObj.quickChoice4QuestionType.compositeQty;
          break;
        case 2:
          reqObj.quickChoice4LevelTypeGets = [];
          for (let i = 0; i < QUES_LEVEL_NAMES.values.length; i++) {
            reqObj.quickChoice4LevelTypeGets.push({
              maxQty: this.getTypeSet(0)['num' + i],
              levelType: i
            });
            count += this.getTypeSet(0)['num' + i];
          }
          break;
        case 3:
          reqObj.quickChoice4QuestionQty = this.quesList[0].num0;
          count = reqObj.quickChoice4QuestionQty;
          break;
        default:
          break;
      }

      // 没填数量就不走接口了，直接关掉
      if (count === 0) {
        this.loadingBtn = false;
        this.show = false;
        this.$emit('setQuestion', []);
        return;
      }

      examService
        .getQuesChoiceQuestion(reqObj)
        .then(res => {
          this.loadingBtn = false;
          this.show = false;
          this.$emit('setQuestion', res);
        })
        .catch(err => {
          this.loadingBtn = false;
          this.handleError.bind(this)(err);
        });
    },
    handleCancel(done) {
      done && done();
      this.show = false;
      this.selectQStyleCountInfo = [];
    },
    getTypeSet(t) {
      return this.quesList.find(ts => {
        return ts.quesType === t;
      }) || {
        num0: 0,
        num1: 0,
        num2: 0,
        num3: 0,
        num4: 0
      };
    },
    quesMaxInut(val) {
      return val >= 1000 ? 999 : val;
    },
    quesMaxMethod(val) {
      return val >= 1000 ? '999+' : val;
    }
  },
  beforeDestroy() {}
};
</script>
