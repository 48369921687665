import Upload from './src/main.vue';

/* istanbul ignore next */
Upload.install = function(Vue) {
  Vue.component(Upload.name, Upload);
};

Upload.setConfig = (config) => {
  Upload.env = config.env || 'dev';
};

export default Upload;
