import axios from 'axios';
import { common, enmuFunctions } from 'yxt-biz-pc/packages/common-util/index.common';

const privateContactAdapter = ((defaultAdapter) => {
  return config => {
    const resp = defaultAdapter(config); // 函数直接在里面处理
    if (common.isFunPointEnableShow(enmuFunctions.TONGXUNLU_JIAMI) && typeof window.sdcs_response_interceptor === 'function') {
      return window.sdcs_response_interceptor(resp); // 直接在里面处理
    } else if (window.sdcs_response_interceptor && typeof window.sdcs_response_interceptor === 'function') {
      // 先直接判断存不存在解密的方法，存在就代表要解密，用于处理登录场景下没有要素的情况
      return window.sdcs_response_interceptor(resp);
    }
    return resp;
  };
})(axios.defaults.adapter);

export default privateContactAdapter;
