var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-ability-selector" },
    [
      _c(`CheckAbility`, {
        ref: "ability",
        tag: "component",
        attrs: { identify: 0, limit: _vm.limit, list: _vm.selectedData },
        on: { update: _vm.updateList },
      }),
      _c(
        "div",
        { staticClass: "yxt-list__wrapper" },
        [
          _c("checked-list", {
            attrs: {
              limit: _vm.limit,
              list: _vm.selectedData,
              virtualRender: _vm.virtualRender,
            },
            on: { clear: _vm.clear, close: _vm.closeTag },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }