var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "yxtbiz-dept-tree-v2",
    },
    [
      _c(
        "div",
        {
          staticClass: "yxtbiz-dept-tree-v2-search",
          style: { height: _vm.contentHeight + "px" },
        },
        [
          _c(
            "yxt-row",
            { attrs: { type: _vm.selectorAlign ? "" : "flex" } },
            [
              _vm.enableGroupCorp && _vm.visibleOrgSelector
                ? _c("yxtbiz-group-org-select", {
                    staticClass: "mr12 mb12",
                    style: _vm.selectorAlign ? "width: 100%" : "",
                    attrs: {
                      selectFirst: true,
                      setCurrentOrg: _vm.setCurrentOrg,
                      functionCode: _vm.functionCode,
                      dataPermissionCode: _vm.dataPermissionCode,
                      clearable: false,
                      targetOrgId: _vm.targetOrgId,
                      visibleOrgIds: _vm.visibleOrgIds,
                      disabled: _vm.disabled,
                      size: "small",
                    },
                    on: { change: _vm.handleOrgChange },
                  })
                : _vm._e(),
              _vm.isShowSearch
                ? _c("yxt-input", {
                    ref: "input",
                    staticClass: "yxtbiz-dept-tree__input",
                    staticStyle: { "max-width": "320px" },
                    attrs: {
                      placeholder: _vm.placeholder,
                      size: "small",
                      searchable: "",
                    },
                    on: { search: _vm.filter },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSearchTree,
              expression: "isSearchTree",
            },
          ],
          staticClass: "yxtbiz-dept-tree-v2-content",
          style: { height: `calc(100% - ${_vm.contentHeight}px)` },
        },
        [
          _c("BaseTree", {
            ref: "searchTree",
            attrs: {
              data: _vm.searchData,
              props: _vm.props,
              "node-key": "id",
              "wrap-width": "100%",
              "highlight-current": false,
              "check-strictly": _vm.checkStrictly,
              "show-checkbox": _vm.checkShowCheckbox,
              functions: _vm.checkFunctions,
              "expand-on-click-node": false,
              openScroll: true,
              dropdownToBody: _vm.dropdownToBody,
              functionsRender: _vm.functionsRenderMid,
              "default-expanded-keys": _vm.searchTreeExpandedKeys,
              virtual: "",
            },
            on: {
              "node-click": _vm.nodeClick,
              check: _vm.nodeCheck,
              "check-change": _vm.checkChange,
              "node-expand": _vm.nodeExpand,
              "node-collapse": _vm.nodeCollapse,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ data, node }) {
                  return [
                    !_vm.multiple && data.hasAllDeptPermission
                      ? _c(
                          "span",
                          { staticClass: "yxtbiz-dept-tree-v2-radio" },
                          [
                            _c("yxt-radio", {
                              attrs: { label: data.id },
                              model: {
                                value: _vm.deptTreeSingle,
                                callback: function ($$v) {
                                  _vm.deptTreeSingle = $$v
                                },
                                expression: "deptTreeSingle",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("yxtbiz-dept-name", { attrs: { name: node.label } }),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.count && data.id ? ` (${data.userCount})` : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isSearchTree,
              expression: "!isSearchTree",
            },
          ],
          staticClass: "yxtbiz-dept-tree-v2-content",
          style: { height: `calc(100% - ${_vm.contentHeight}px)` },
        },
        [
          _c("BaseTree", {
            ref: "tree",
            attrs: {
              "node-key": "id",
              "wrap-width": "100%",
              "highlight-current": false,
              "check-strictly": _vm.checkStrictly,
              data: _vm.treeData,
              props: _vm.props,
              "show-checkbox": _vm.checkShowCheckbox,
              functions: _vm.checkFunctions,
              "expand-on-click-node": false,
              openScroll: true,
              dropdownToBody: _vm.dropdownToBody,
              functionsRender: _vm.functionsRenderMid,
              "default-expanded-keys": _vm.expandedKeys,
              virtual: "",
            },
            on: {
              "node-click": _vm.nodeClick,
              check: _vm.nodeCheck,
              "check-change": _vm.checkChange,
              "node-expand": _vm.nodeExpand,
              "node-collapse": _vm.nodeCollapse,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ data, node }) {
                  return [
                    !_vm.multiple && data.hasAllDeptPermission
                      ? _c(
                          "span",
                          { staticClass: "yxtbiz-dept-tree-v2-radio" },
                          [
                            _c("yxt-radio", {
                              attrs: { label: data.id },
                              model: {
                                value: _vm.deptTreeSingle,
                                callback: function ($$v) {
                                  _vm.deptTreeSingle = $$v
                                },
                                expression: "deptTreeSingle",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("yxtbiz-dept-name", { attrs: { name: node.label } }),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.count && data.id
                            ? ` (${data.userCount || 0})`
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }