import { downApi } from 'yxt-biz-pc/packages/api';

// 提示下载中心推送一次已下载成功的未读消息
export const downloadMsgPush = (userId) => {
  return downApi.get(`info/push?userId=${userId}`);
};

export const setReadDownloadMsg = (userId) => {
  return downApi.get(`info/read?userId=${userId}`);
};
