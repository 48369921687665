<template>
    <div v-if="options.length > 1">
        <label class="font-size-14 color-gray-10"><slot></slot></label>
        <yxt-select v-model="value"  @change="change" :size="size" :style="style">
            <yxt-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
            >
            </yxt-option>
        </yxt-select>
    </div>
</template>

<script>
import { getAuthSelectData } from './service';
import {
  Select,
  Option
} from 'yxt-pc';

const CREATE_BY_SELF = 1; // 我创建与负责的
const CREATE_BY_SUB = 2; // 直属员工创建与负责的
const CREATE_BY_EQUAL = 3; // 所辖部门员工创建与负责的

const PROJECT_CHECK = '1'; // 项目查看
const SOURCE_SELECT = '2'; // 资源选择
const SOURCE_ADMIN = '3'; // 资源管理

const TEXT_TYPE_1 = [
  {
    value: CREATE_BY_SELF,
    label: '我创建与负责的'
  },
  {
    value: CREATE_BY_SUB,
    label: '直属员工创建与负责的'
  },
  {
    value: CREATE_BY_EQUAL,
    label: '所辖部门员工创建与负责的'
  }
];

const TEXT_TYPE_2 = [
  {
    value: CREATE_BY_SELF,
    label: '我创建的'
  },
  {
    value: CREATE_BY_SUB,
    label: '直属员工创建的'
  },
  {
    value: CREATE_BY_EQUAL,
    label: '所辖部门员工创建的'
  }
];

export default {
  name: 'YxtbizAuthSelector',
  components: {
    YxtSelect: Select,
    YxtOption: Option
  },
  props: {
    type: {
      type: String,
      validator(value) {
        if ([PROJECT_CHECK, SOURCE_SELECT, SOURCE_ADMIN].indexOf(value) < 0) {
          throw new Error('type is error, must 1 , 2 , 3');
        } else {
          return true;
        }
      }
    },
    defaultValue: {
      type: Number,
      default: CREATE_BY_SELF
    },
    size: {
      type: String,
      default: ''
    },
    width: {
      type: String
    }
  },
  data() {
    return {
      value: CREATE_BY_SELF,
      isDeptManage: 0, // 是否为部门主管
      isHavaManagePermission: 0, // 是否有管辖部门
      isDirectManage: 0 // 是否为直属经理
    };
  },
  computed: {
    defaultOptions() {
      return this.type === PROJECT_CHECK ? TEXT_TYPE_1 : TEXT_TYPE_2;
    },
    options() {
      return this.defaultOptions
        .filter(v => this.isDirectManage ? true : v.value !== CREATE_BY_SUB);
    },
    style() {
      return {
        width: this.width + 'px'
      };
    }
  },
  created() {
    this.value = this.defaultValue;
    getAuthSelectData({type: this.type}).then(res => {
      this.isDeptManage = res.isDeptManage;
      this.isHavaManagePermission = res.isHavaManagePermission;
      this.isDirectManage = res.isDirectManage;
      this.$emit('change', this.value);
    }).catch(() => {
      this.$emit('change', this.defaultValue);
    });
  },
  methods: {
    change(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style scoped>

</style>
