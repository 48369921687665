import SelectKngSource from './src/main.vue';

/* istanbul ignore next */
SelectKngSource.install = function(Vue) {
  Vue.component(SelectKngSource.name, SelectKngSource);
};

SelectKngSource.setConfig = (config) => {
  SelectKngSource.env = config.env || 'dev';
};

export default SelectKngSource;
