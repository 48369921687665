import {i18n, setLanguage as i18nSetLanguage} from 'yxt-i18n/es';
import { i18n as YxtPcI18n } from 'yxt-pc';
import { globalApi } from 'yxt-biz-pc/packages/api';

/**
 * yxt-i18n 没权限 暂时放这里
 * @param {*} params
 * @returns
 */
export const getLangFiles = (params) => {
  return globalApi.post('langfiles/search', params);
};

// 设置语言
const SetLanguage = () => {
  YxtPcI18n((key, value) => i18n.t(key, value));
  return i18nSetLanguage;
};
export const setLanguage = SetLanguage();

// export const getLanguage = yxtI18n.getLanguage; // 获取当前语言
// export const getLangList = yxtI18n.getLangList; // 获取语种列表
// export const saveLangKey = yxtI18n.saveLangKey; // 保存当前多语言翻译
// export const i18n = yxtI18n.i18n;

export { i18n, getLangList, getLanguage, saveLangKey } from 'yxt-i18n/es';

