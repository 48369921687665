/**
 * 日期格式化
 * @param {Object} value 日期值
 * @param {String} part 格式
 */
export const dateFormat = (value, part = 'yyyy-MM-dd HH:mm') => {
  let date;
  if (value instanceof Date) {
    date = value;
  } else if (typeof value === 'number') { // 时间戳
    date = new Date(value);
  } else {
    if (!value || value === '-' || value.indexOf('0001') >= 0 || value.indexOf('1900') >= 0) {
      return '-';
    }
    if (value.length > 10) {
      value = value.replace(/T/, ' ');
    }
    date = new Date(value.replace(/-/g, '/').split('.')[0]);
  }

  if (date === new Date(1970, 0, 1)) {
    return '-';
  }

  let redate = '';

  const y = (date.getFullYear()).toString();
  const M = (date.getMonth() + 1).toString();
  const d = (date.getDate()).toString();
  const H = (date.getHours()).toString();
  const m = (date.getMinutes()).toString();
  const s = (date.getSeconds()).toString();
  const MM = ('0' + M).slice(-2);
  const dd = ('0' + d).slice(-2);
  const HH = ('0' + H).slice(-2);
  const mm = ('0' + m).slice(-2);
  const ss = ('0' + s).slice(-2);
  redate = part.replace('yyyy', y)
    .replace('MM', MM)
    .replace('dd', dd)
    .replace('HH', HH)
    .replace('mm', mm)
    .replace('ss', ss)
    .replace('M', M)
    .replace('d', d)
    .replace('H', H)
    .replace('m', m)
    .replace('s', s);

  return redate;
};

export const Sort = ()=>{
  return {
    // column: any,
    // order: string,
    // prop: string
  };
};
