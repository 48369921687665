var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-exam-selector" }, [
    _c(
      "div",
      { staticClass: "yxtbiz-exam-selector__left" },
      [
        _c(
          "div",
          [
            _c("yxt-input", {
              staticClass: "mb16",
              attrs: {
                placeholder: _vm.$t("pc_biz_ote_tip_catalogsearch"),
                searchable: "",
                maxlength: "50",
              },
              model: {
                value: _vm.examCatalogSearch,
                callback: function ($$v) {
                  _vm.examCatalogSearch =
                    typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "examCatalogSearch",
              },
            }),
          ],
          1
        ),
        _c("yxt-tree", {
          ref: "tree",
          attrs: {
            data: _vm.datasCatalog,
            props: _vm.defaultProps,
            "node-key": "id",
            "wrap-width": 260,
            "filter-node-method": _vm.filterNode,
            "default-expanded-keys": ["root"],
          },
          on: { "node-click": _vm.selectCatalog },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "yxtbiz-exam-selector__right" },
      [
        _c(
          "div",
          { staticClass: "clearfix font-size-14" },
          [
            _c("common-drop-selector", {
              staticClass: "mr12",
              attrs: {
                size: "small",
                clearable: false,
                customWidthClass: "width160",
                dataList: _vm.rangeList,
              },
              on: {
                change: function ($event) {
                  return _vm.getExamList(true)
                },
              },
              model: {
                value: _vm.examSearch.examRange,
                callback: function ($$v) {
                  _vm.$set(_vm.examSearch, "examRange", $$v)
                },
                expression: "examSearch.examRange",
              },
            }),
            _c("yxt-input", {
              staticClass: "width240",
              attrs: {
                placeholder: _vm.$t("pc_biz_ote_tip_exampapersearch"),
                searchable: "",
                maxlength: "50",
              },
              on: { search: _vm.examNameSearch },
              model: {
                value: _vm.examSearch.name,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.examSearch,
                    "name",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "examSearch.name",
              },
            }),
          ],
          1
        ),
        _c(
          "yxt-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "examTable",
            staticClass: "mt16 yxtbiz-exam-table",
            attrs: {
              height: _vm.tableHeight,
              data: _vm.examsList,
              "default-sort": { prop: "updateTime", order: "desc" },
            },
            on: { "sort-change": _vm.sortChange },
          },
          [
            _c("yxt-table-column", {
              attrs: {
                label: _vm.$t("pc_biz_ote_lbl_exampapername"),
                "show-overflow-tooltip": true,
                "min-width": "240",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      !scope.row.secrecy ||
                      scope.row.shared === 1 ||
                      scope.row.createUserId === _vm.userId ||
                      _vm.isAdmin === "1"
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "yxtbiz-exam-selector__title color-primary-6 hand",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.goExamPreview(scope.row.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(scope.row.name))]
                          )
                        : _c("span", [_vm._v(_vm._s(scope.row.name))]),
                    ]
                  },
                },
              ]),
            }),
            _c("yxt-table-column", {
              attrs: {
                label: _vm.$t("pc_biz_ote_lbl_exampapertype"),
                prop: "buildType",
                align: "left",
                width: "140",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.buildType === 0
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("pc_biz_ote_lbl_exampapertype0"))
                            ),
                          ])
                        : _vm._e(),
                      scope.row.buildType === 1
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("pc_biz_ote_lbl_exampapertype1"))
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("yxt-table-column", {
              attrs: {
                "show-overflow-tooltip": true,
                "popover-html": true,
                label: _vm.$t("pc_biz_lbl_creator" /*创建人*/),
                prop: "createFullname",
                width: "180",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("yxtbiz-user-name", {
                        attrs: { name: scope.row.createFullname },
                      }),
                      _vm._v("\n          ("),
                      _c("yxtbiz-user-name", {
                        attrs: { name: scope.row.createUserName },
                      }),
                      _vm._v(")\n        "),
                    ]
                  },
                },
              ]),
            }),
            _c("yxt-table-column", {
              attrs: {
                label: _vm.$t("pc_biz_ote_lbl_updatetime"),
                prop: "updateTime",
                width: "140",
                sortable: "custom",
                formatter: _vm.shortDate,
                align: "left",
              },
            }),
            _c("yxt-table-column", {
              attrs: {
                label: _vm.$t("pc_biz_ote_btn_operate"),
                width: "100",
                fixed: "right",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "yxt-button",
                        {
                          staticClass: "color-primary-6 hand",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.selectExam(scope.row)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.value && _vm.value.id === scope.row.id
                                  ? _vm.$t("pc_biz_ote_btn_cancleselect")
                                  : _vm.$t("pc_biz_ote_btn_select")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt16 clearfix" },
          [
            _c("yxt-pagination", {
              staticClass: "pull-right",
              attrs: {
                "page-size": _vm.examSearch.limit,
                total: _vm.count,
                "current-page": _vm.page,
                layout: "total, prev, pager, next",
              },
              on: {
                "current-change": _vm.pageChange,
                "size-change": _vm.sizeChange,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }