var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-drawer",
    {
      attrs: {
        "custom-class": "yxtbiz-selectKng",
        size: "960px",
        title: _vm.drawerTitle,
        visible: _vm.openPage,
        "before-close": _vm.clearData,
        "destroy-on-close": _vm.destroyOnClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.openPage = $event
        },
      },
    },
    [
      _vm.isOpenUpload || _vm.canSelectLibKng
        ? _c(
            "div",
            { staticClass: "yxtbiz-selectKng__top-btn" },
            [
              _c(
                "yxt-button",
                {
                  staticClass: "mr10",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.selectingNewkng = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_kng_btn_selectKng")))]
              ),
              _vm.isOpenUpload
                ? _c("Upload-kng", {
                    attrs: {
                      disabled: _vm.disabledType,
                      uploadType: _vm.kngSelectType,
                      courseId: _vm.courseId,
                      catalogId: _vm.catalogId,
                    },
                    on: { submit: _vm.submitKng },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.IsDisplayAllMenu
        ? _c(
            "div",
            [
              !_vm.isShowSingle
                ? [
                    _c(
                      "yxt-tabs",
                      {
                        attrs: { "drawer-top": "" },
                        on: { "tab-click": _vm.handleSelect },
                        model: {
                          value: _vm.activeIndex,
                          callback: function ($$v) {
                            _vm.activeIndex = $$v
                          },
                          expression: "activeIndex",
                        },
                      },
                      [
                        _vm.IsKngMenu
                          ? _c("yxt-tab-pane", {
                              attrs: {
                                label: _vm.$t("pc_biz_kng_lbl_inKng"),
                                lazy: "",
                                name: "1",
                              },
                            })
                          : _vm._e(),
                        _vm.IsBuyCourseMenu
                          ? _c("yxt-tab-pane", {
                              attrs: {
                                label: _vm.$t("pc_biz_kng_lbl_buyCourse"),
                                lazy: "",
                                name: "2",
                              },
                            })
                          : _vm._e(),
                        _vm.IsBuyProjectMenu
                          ? _c("yxt-tab-pane", {
                              attrs: {
                                label: _vm.$t(
                                  "pc_biz_kng_lbl_buyProjectCourse"
                                ),
                                lazy: "",
                                name: "3",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "yxtbiz-knglist",
                  class: _vm.isShowSingle
                    ? "yxtbiz-knglist-padding_notop"
                    : "yxtbiz-knglist-padding",
                },
                [
                  _vm.IsKngMenu
                    ? [
                        _c(
                          "yxt-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isKnglib,
                                expression: "isKnglib",
                              },
                            ],
                            staticClass:
                              "flex-1 yxtbiz-knglist-table over-hidden",
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "pull-left mr12 mb16" },
                                [
                                  _c(
                                    "yxt-button",
                                    {
                                      staticClass: "filter-btn w-128 ph12 pv8",
                                      attrs: { plain: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          _vm.boxVisible = !_vm.boxVisible
                                        },
                                      },
                                    },
                                    [
                                      _c("yxt-svg", {
                                        staticClass: "filter-icon mt1",
                                        attrs: {
                                          width: "20px",
                                          height: "20px",
                                          "icon-class": "filter",
                                        },
                                      }),
                                      _c("span", { staticClass: "ml25" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "pc_biz_common_lbl_moreFilter" /* 更多筛选 */
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("i", {
                                        staticClass: "ml3",
                                        class: _vm.boxVisible
                                          ? "yxt-icon-arrow-up"
                                          : "yxt-icon-arrow-down",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-in-block w200" },
                                [
                                  _c("yxt-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "pc_biz_kng_lbl_keyword" /*请输入关键词搜索*/
                                      ),
                                      searchable: "",
                                      clearable: "",
                                    },
                                    on: {
                                      clear: function ($event) {
                                        return _vm.searchLists(1)
                                      },
                                      search: function ($event) {
                                        return _vm.searchLists(1)
                                      },
                                    },
                                    model: {
                                      value: _vm.kngInput,
                                      callback: function ($$v) {
                                        _vm.kngInput = $$v
                                      },
                                      expression: "kngInput",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.boxVisible
                                ? _c(
                                    "div",
                                    { staticClass: "template5-filter-box p24" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _vm.enableGroupCorp
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-in-block mr12 mb16",
                                                },
                                                [
                                                  _c(
                                                    "yxtbiz-group-org-select",
                                                    {
                                                      attrs: {
                                                        dataPermissionCode:
                                                          _vm.orgDataPermissionCode,
                                                        functionCode:
                                                          _vm.orgFunctionCode,
                                                        placeholder: _vm.$t(
                                                          "pc_biz_kng_lbl_chooseorg"
                                                        ),
                                                        setCurrentOrg: "",
                                                      },
                                                      on: {
                                                        change: _vm.orgChange,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isCreateTime
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-in-block mr12 mb16",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "mr12 color-gray-9",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "pc_biz_kng_lbl_createTime"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("yxt-date-picker", {
                                                    attrs: {
                                                      type: "daterange",
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleTimeChane,
                                                    },
                                                    model: {
                                                      value: _vm.creatTimes,
                                                      callback: function ($$v) {
                                                        _vm.creatTimes = $$v
                                                      },
                                                      expression: "creatTimes",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "select-popover",
                                            {
                                              ref: "selectPopover",
                                              staticClass:
                                                "d-in-block w200 mr12 mb16",
                                              attrs: {
                                                selectOpition:
                                                  _vm.selectOpition,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "yxtbiz-kng-tree table_tree",
                                                },
                                                [
                                                  _c("yxt-input", {
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "pc_biz_kng_lbl_searchCatalog"
                                                      ),
                                                      searchable: "",
                                                    },
                                                    on: {
                                                      search: _vm.searchCatalog,
                                                    },
                                                    model: {
                                                      value: _vm.filterKngTree,
                                                      callback: function ($$v) {
                                                        _vm.filterKngTree = $$v
                                                      },
                                                      expression:
                                                        "filterKngTree",
                                                    },
                                                  }),
                                                  _c("yxt-tree", {
                                                    directives: [
                                                      {
                                                        name: "loading",
                                                        rawName: "v-loading",
                                                        value: _vm.treeLoading,
                                                        expression:
                                                          "treeLoading",
                                                      },
                                                    ],
                                                    ref: "filterTree",
                                                    staticClass:
                                                      "yxtbiz-biz-tree-funs mt16",
                                                    class:
                                                      _vm.kngTreeData.length > 0
                                                        ? "d-in-block"
                                                        : "",
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      "node-key": "id",
                                                      "empty-text": _vm.$t(
                                                        "pc_biz_kng_lbl_noCatalog"
                                                      ),
                                                      data: _vm.kngTreeData,
                                                      props: _vm.defaultProps,
                                                      "filter-node-method":
                                                        _vm.filterNode,
                                                      "default-expanded-keys":
                                                        _vm.treeExpand,
                                                      "expand-on-click-node": false,
                                                    },
                                                    on: {
                                                      "node-click":
                                                        _vm.ClickTreeNode,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm.isShowLocal && _vm.env === 2
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-in-block mr12 mb16",
                                                },
                                                [
                                                  _c(
                                                    "yxt-select",
                                                    {
                                                      staticClass: "w144",
                                                      attrs: {
                                                        placeholder: _vm.$t(
                                                          "pc_biz_kng_lbl_selectEnv"
                                                        ),
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value: _vm.envFlag,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.envFlag = $$v
                                                        },
                                                        expression: "envFlag",
                                                      },
                                                    },
                                                    [
                                                      _c("yxt-option", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "pc_biz_kng_lbl_localCloud"
                                                          ),
                                                          value: 0,
                                                        },
                                                      }),
                                                      _c("yxt-option", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "pc_biz_kng_lbl_publicCloud"
                                                          ),
                                                          value: 1,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.disabled
                                            ? [
                                                _c(
                                                  "yxt-select",
                                                  {
                                                    staticClass:
                                                      "w120 mr12 mb16",
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "pc_biz_kng_lbl_kngType"
                                                      ),
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value: _vm.kngValue,
                                                      callback: function ($$v) {
                                                        _vm.kngValue = $$v
                                                      },
                                                      expression: "kngValue",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.options,
                                                    function (item) {
                                                      return _c(
                                                        "yxt-option",
                                                        {
                                                          key:
                                                            item.value +
                                                            Math.random(),
                                                          attrs: {
                                                            label: item.label,
                                                            value: item.value,
                                                            disabled:
                                                              item.disabled,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-size-14",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.label
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          _vm.auditOptions.length
                                            ? _c(
                                                "yxt-select",
                                                {
                                                  staticClass: "w120 mr12 mb16",
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "pc_biz_kng_lbl_allStatus"
                                                    ),
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value: _vm.auditValue,
                                                    callback: function ($$v) {
                                                      _vm.auditValue = $$v
                                                    },
                                                    expression: "auditValue",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.auditOptions,
                                                  function (item) {
                                                    return _c(
                                                      "yxt-option",
                                                      {
                                                        key:
                                                          "audit-" + item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "font-size-14",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.label)
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "checkbox-filter" },
                                            [
                                              _vm.isRemoveHide
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "yxt-checkbox",
                                                        {
                                                          staticClass:
                                                            "yui-txt-grey font-size-14",
                                                          model: {
                                                            value:
                                                              _vm.kngChecked,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.kngChecked =
                                                                $$v
                                                            },
                                                            expression:
                                                              "kngChecked",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "pc_biz_kng_lbl_hiddenCourse"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isRemoveDelete
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-in-block ml36",
                                                    },
                                                    [
                                                      _c(
                                                        "yxt-checkbox",
                                                        {
                                                          staticClass:
                                                            "yui-txt-grey font-size-14",
                                                          model: {
                                                            value:
                                                              _vm.deleteChecked,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.deleteChecked =
                                                                $$v
                                                            },
                                                            expression:
                                                              "deleteChecked",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "pc_biz_kng_lbl_removeCourse"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "yxt-button",
                                                {
                                                  staticClass: "w-auto",
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: _vm.filterChange,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pc_biz_core_btn_determine" /* 确定 */
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "yxt-button",
                                                {
                                                  attrs: { plain: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.boxVisible = false
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pc_biz_common_cancel_mini" /* 取消 */
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex-1 flex mt4 h0 pb1" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-stretch width-percent-100",
                                  },
                                  [
                                    _c(
                                      "yxt-table",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.kngListLoading,
                                            expression: "kngListLoading",
                                          },
                                        ],
                                        ref: "kngTable",
                                        attrs: {
                                          "custom-column-props":
                                            _vm.customColumnProps,
                                          height: "100%",
                                          data: _vm.KngTableData,
                                          "row-key": _vm.getRowKey,
                                        },
                                        on: {
                                          "custom-column-change":
                                            _vm.handlerColumnChange,
                                          select: _vm.singleCancel,
                                          "selection-change":
                                            _vm.selectionChange,
                                          "select-all": _vm.handleKngSelectAll,
                                          "sort-change": _vm.kngSortChange,
                                        },
                                      },
                                      [
                                        _c("yxt-table-column", {
                                          attrs: {
                                            type: "selection",
                                            width: "40",
                                            selectable: _vm.isSelectable,
                                            "reserve-selection": "",
                                            "show-overflow-tooltip": false,
                                            "hide-column": "disable",
                                          },
                                        }),
                                        _c("yxt-table-column", {
                                          attrs: {
                                            prop: "title",
                                            label: _vm.$t(
                                              "pc_biz_kng_lbl_title"
                                            ),
                                            "min-width": 300,
                                            "hide-column": "disable",
                                            "class-name": "cell-title",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "title-flex",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "yxtbiz-knglist-tb-cell",
                                                          },
                                                          [
                                                            _c("yxt-svg", {
                                                              staticClass:
                                                                "flex-shrink-0 mr4",
                                                              attrs: {
                                                                width: "16px",
                                                                height: "20px",
                                                                "remote-url":
                                                                  _vm.kngFileTypeIconUrl,
                                                                "icon-class":
                                                                  _vm.getKngFileTypeIcon(
                                                                    scope.row
                                                                      .fileType
                                                                  ),
                                                              },
                                                            }),
                                                            _c(
                                                              "yxt-tooltip",
                                                              {
                                                                attrs: {
                                                                  placement:
                                                                    "top",
                                                                  "open-filter":
                                                                    "",
                                                                  "max-width": 300,
                                                                  content:
                                                                    _vm.handlerTitle(
                                                                      scope.row
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "v-mid font-size-14 yxtbiz-knglist-tb-title",
                                                                    style:
                                                                      _vm.handlerTitleColor(
                                                                        scope.row
                                                                      ),
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          _vm.handlerTitle(
                                                                            scope.row
                                                                          )
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "yxt-popover",
                                                              {
                                                                attrs: {
                                                                  placement:
                                                                    "bottom-start",
                                                                  effect:
                                                                    "dark",
                                                                  trigger:
                                                                    "hover",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "popup-text",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "pc_biz_kng_tip_localCloud"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                scope.row
                                                                  .envFlag === 0
                                                                  ? _c(
                                                                      "yxt-svg",
                                                                      {
                                                                        staticClass:
                                                                          "ml8 v-mid flex-shrink-0",
                                                                        attrs: {
                                                                          slot: "reference",
                                                                          width:
                                                                            "30px",
                                                                          height:
                                                                            "20px",
                                                                          "remote-url": `${_vm.$staticBaseUrl}ufd/407a24/kng/pc/svg/PC`,
                                                                          "icon-class":
                                                                            "private-cloud",
                                                                        },
                                                                        slot: "reference",
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            scope.row
                                                              .shareFlag === 2
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "icon-span-share",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "pc_biz_ote_btn_share"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            scope.row
                                                              .effective === 0
                                                              ? _c(
                                                                  "yxt-tag",
                                                                  {
                                                                    staticClass:
                                                                      "ml8 flex-shrink-0 mb0",
                                                                    attrs: {
                                                                      type: "danger",
                                                                      size: "mini",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "pc_biz_kng_lbl_invalid" /**未生效 */
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1332493489
                                          ),
                                        }),
                                        _vm.showUniqueCode
                                          ? _c("yxt-table-column", {
                                              attrs: {
                                                prop: "uniqueCode",
                                                label: _vm.$t(
                                                  "kng_lbl_unique_code" /*平台课程编号*/
                                                ),
                                                width: "120",
                                                "column-key": "uniqueCode",
                                                "show-overflow-tooltip": true,
                                                "hide-column":
                                                  _vm.isUniqueCodeChecked,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.showCustomCode
                                          ? _c("yxt-table-column", {
                                              attrs: {
                                                prop: "customCode",
                                                label: _vm.$t(
                                                  "kng_lbl_custom_code" /*自定义课程编号*/
                                                ),
                                                width: "120",
                                                "column-key": "customCode",
                                                "show-overflow-tooltip": true,
                                                "hide-column":
                                                  _vm.isCustomCodeChecked,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ row }) {
                                                      return [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              row.customCode ||
                                                                "--"
                                                            ) +
                                                            "\n                "
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2062406049
                                              ),
                                            })
                                          : _vm._e(),
                                        !_vm.excludeFileType
                                          ? _c("yxt-table-column", {
                                              attrs: {
                                                label: _vm.$t(
                                                  "pc_biz_kng_lbl_zoCode" /*商城课编号*/
                                                ),
                                                width: "120",
                                                "column-key": "zoCode",
                                                "show-overflow-tooltip": true,
                                                "hide-column":
                                                  "isZoCodeChecked",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ row }) {
                                                      return [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              row.zoCode || "--"
                                                            ) +
                                                            "\n                "
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1158501447
                                              ),
                                            })
                                          : _vm._e(),
                                        _vm.isAudit
                                          ? _c("yxt-table-column", {
                                              attrs: {
                                                label: _vm.$t(
                                                  "pc_biz_kng_lbl_status"
                                                ),
                                                width: "80",
                                                "show-overflow-tooltip": false,
                                                "hide-column": "disable",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ row }) {
                                                      return [
                                                        row.auditStatus === 0
                                                          ? _c(
                                                              "yxt-tooltip",
                                                              {
                                                                attrs: {
                                                                  placement:
                                                                    "top",
                                                                  content:
                                                                    _vm.$t(
                                                                      "pc_biz_kng_tip_unAudit"
                                                                    ),
                                                                  "max-width": 300,
                                                                  effect:
                                                                    "dark",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "color-gray-6",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "pc_biz_kng_lbl_unAudit"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm.isPublished(row)
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "pc_biz_kng_lbl_published"
                                                                  )
                                                                )
                                                              ),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v("--"),
                                                            ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                900682076
                                              ),
                                            })
                                          : _vm._e(),
                                        _vm.isHideScore
                                          ? _c("yxt-table-column", {
                                              attrs: {
                                                prop: "studyScore",
                                                label: _vm.$t(
                                                  "pc_biz_kng_lbl_studyScore"
                                                ),
                                                width: "90",
                                                sortable: "custom",
                                                "sort-orders": _vm.sortOrders,
                                                "hide-column": "disable",
                                              },
                                            })
                                          : _vm._e(),
                                        _c("yxt-table-column", {
                                          attrs: {
                                            prop: "studyHours",
                                            label: _vm.$t(
                                              "pc_biz_kng_lbl_studyHours"
                                            ),
                                            width: "100",
                                            sortable: "custom",
                                            "sort-orders": _vm.sortOrders,
                                            "show-overflow-tooltip": "",
                                            "hide-column": "disable",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getMinuteSecondFormat(
                                                            scope.row.studyHours
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3982326064
                                          ),
                                        }),
                                        _c("yxt-table-column", {
                                          attrs: {
                                            prop: "createFullname",
                                            label: _vm.$t(
                                              "pc_biz_kng_lbl_createFullname"
                                            ),
                                            width: "100",
                                            sortable: "custom",
                                            "sort-orders": _vm.sortOrders,
                                            "show-overflow-tooltip": false,
                                            "hide-column": "disable",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "yxt-tooltip",
                                                      {
                                                        attrs: {
                                                          "open-filter": "",
                                                          placement: "top",
                                                          "max-width": 300,
                                                          effect: "dark",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ellipsis",
                                                          },
                                                          [
                                                            _c(
                                                              "yxtbiz-user-name",
                                                              {
                                                                attrs: {
                                                                  name: scope
                                                                    .row
                                                                    .createFullname,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "content",
                                                            },
                                                            slot: "content",
                                                          },
                                                          [
                                                            _c(
                                                              "yxtbiz-user-name",
                                                              {
                                                                attrs: {
                                                                  name: scope
                                                                    .row
                                                                    .createFullname,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2737522561
                                          ),
                                        }),
                                        _c("yxt-table-column", {
                                          attrs: {
                                            prop: "expirationDate",
                                            fixed: "right",
                                            label: _vm.$t(
                                              "pc_biz_kng_lbl_expirationDate"
                                            ),
                                            width: "130",
                                            sortable: "custom",
                                            "sort-orders": _vm.sortOrders,
                                            "hide-column": "disable",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          !scope.row
                                                            .expirationDate
                                                            ? "--"
                                                            : scope.row.expirationDate.slice(
                                                                0,
                                                                10
                                                              )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4054329216
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "mt16 flex-mid h28" },
                              [
                                _c(
                                  "yxt-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      loading: _vm.selectAllBtnLoading,
                                      disabled: !_vm.KngTableData.length,
                                    },
                                    on: { click: _vm.selectAll },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "pc_biz_kng_selectall" /** 筛选全部结果 */
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "flex-1" }),
                                _vm.totalKng > 0
                                  ? _c("yxt-pagination", {
                                      attrs: {
                                        total: _vm.totalKng,
                                        "page-size": _vm.perKngPage,
                                        "current-page": _vm.currentKngPage,
                                        "pager-count": 5,
                                        "show-on-single-page": "",
                                        layout:
                                          "total, prev, pager, next, sizes",
                                      },
                                      on: {
                                        "update:pageSize": function ($event) {
                                          _vm.perKngPage = $event
                                        },
                                        "update:page-size": function ($event) {
                                          _vm.perKngPage = $event
                                        },
                                        "update:currentPage": function (
                                          $event
                                        ) {
                                          _vm.currentKngPage = $event
                                        },
                                        "update:current-page": function (
                                          $event
                                        ) {
                                          _vm.currentKngPage = $event
                                        },
                                        "current-change":
                                          _vm.handleKngCurrentChange,
                                        "size-change": _vm.handleKngSizeChange,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.IsBuyCourseMenu
                    ? [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isbuyCourse,
                                expression: "isbuyCourse",
                              },
                            ],
                            staticClass:
                              "flex-1 yxtbiz-knglist-table over-hidden",
                          },
                          [
                            _c("div", { staticClass: "flex-mid" }, [
                              _vm.enableGroupCorp
                                ? _c(
                                    "div",
                                    { staticClass: "d-in-block mr12" },
                                    [
                                      _c("yxtbiz-group-org-select", {
                                        attrs: {
                                          dataPermissionCode:
                                            _vm.orgDataPermissionCode,
                                          functionCode: _vm.orgFunctionCode,
                                          placeholder: _vm.$t(
                                            "pc_biz_kng_lbl_chooseorg"
                                          ),
                                          setCurrentOrg: "",
                                        },
                                        on: { change: _vm.orgChange },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "d-in-block w288" },
                                [
                                  _c("yxt-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "pc_biz_kng_lbl_kngTitle"
                                      ),
                                      searchable: "",
                                      clearable: "",
                                    },
                                    on: {
                                      clear: function ($event) {
                                        return _vm.searchLists(2)
                                      },
                                      search: function ($event) {
                                        return _vm.searchLists(2)
                                      },
                                    },
                                    model: {
                                      value: _vm.buyInput,
                                      callback: function ($$v) {
                                        _vm.buyInput = $$v
                                      },
                                      expression: "buyInput",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex-1 flex mt16 h0 pb1" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-stretch width-percent-100",
                                  },
                                  [
                                    _c(
                                      "yxt-table",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.buyListLoading,
                                            expression: "buyListLoading",
                                          },
                                        ],
                                        ref: "buyTable",
                                        attrs: {
                                          height: "100%",
                                          data: _vm.buyTableData,
                                          "row-key": _vm.getRowKey,
                                          "default-sort": {
                                            prop: "modifiedDate",
                                            order: "descending",
                                          },
                                        },
                                        on: {
                                          select: _vm.singleCancel,
                                          "selection-change":
                                            _vm.selectionBuyChange,
                                          "select-all": _vm.handleBuySelectAll,
                                          "sort-change": _vm.buySortChange,
                                        },
                                      },
                                      [
                                        _c("yxt-table-column", {
                                          attrs: {
                                            type: "selection",
                                            width: "40",
                                            "reserve-selection": "",
                                            "show-overflow-tooltip": false,
                                          },
                                        }),
                                        _c("yxt-table-column", {
                                          attrs: {
                                            prop: "title",
                                            label: _vm.$t(
                                              "pc_biz_kng_lbl_title"
                                            ),
                                            "min-width": 300,
                                            "class-name": "cell-title",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "title-flex",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "yxtbiz-knglist-tb-cell",
                                                          },
                                                          [
                                                            _c("yxt-svg", {
                                                              staticClass:
                                                                "flex-shrink-0 mr4",
                                                              attrs: {
                                                                width: "16px",
                                                                height: "20px",
                                                                "remote-url":
                                                                  _vm.kngFileTypeIconUrl,
                                                                "icon-class":
                                                                  _vm.getKngFileTypeIcon(
                                                                    scope.row
                                                                      .fileType
                                                                  ),
                                                              },
                                                            }),
                                                            _c(
                                                              "yxt-tooltip",
                                                              {
                                                                attrs: {
                                                                  placement:
                                                                    "top",
                                                                  "open-filter":
                                                                    "",
                                                                  "max-width": 300,
                                                                  content:
                                                                    scope.row
                                                                      .title,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "v-mid font-size-14 yxtbiz-knglist-tb-title",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .title
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            scope.row
                                                              .shareFlag === 2
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "icon-span-share",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "pc_biz_ote_btn_share"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            scope.row
                                                              .effective === 0
                                                              ? _c(
                                                                  "yxt-tag",
                                                                  {
                                                                    staticClass:
                                                                      "ml8 flex-shrink-0 mb0",
                                                                    attrs: {
                                                                      type: "danger",
                                                                      size: "mini",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "pc_biz_kng_lbl_invalid" /**未生效 */
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            582139337
                                          ),
                                        }),
                                        _c("yxt-table-column", {
                                          attrs: {
                                            width: "120",
                                            label: _vm.$t(
                                              "pc_biz_kng_lbl_zoCode"
                                            ),
                                            "show-overflow-tooltip": true,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          scope.row.zoCode ||
                                                            "--"
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1590965797
                                          ),
                                        }),
                                        _c("yxt-table-column", {
                                          attrs: {
                                            prop: "studyHours",
                                            width: "120",
                                            label: _vm.$t(
                                              "pc_biz_kng_lbl_studyHours"
                                            ),
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getMinuteSecondFormat(
                                                            scope.row.studyHours
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3982326064
                                          ),
                                        }),
                                        _c("yxt-table-column", {
                                          attrs: {
                                            prop: "orderCount",
                                            width: "120",
                                            label: _vm.$t(
                                              "pc_biz_kng_lbl_orderCount"
                                            ),
                                            sortable: "custom",
                                            "sort-orders": _vm.sortOrders,
                                          },
                                        }),
                                        _c("yxt-table-column", {
                                          attrs: {
                                            prop: "avlCount",
                                            width: "140",
                                            label: _vm.$t(
                                              "pc_biz_kng_lbl_avlCount"
                                            ),
                                            sortable: "custom",
                                            "sort-orders": _vm.sortOrders,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "mt16 flex-mid h28" },
                              [
                                _c(
                                  "yxt-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      loading: _vm.selectAllBtnLoading,
                                      disabled: !_vm.buyTableData.length,
                                    },
                                    on: { click: _vm.selectAll },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "pc_biz_kng_selectall" /** 筛选全部结果 */
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "flex-1" }),
                                _vm.totalBuy > _vm.perBuyPage
                                  ? _c("yxt-pagination", {
                                      staticClass: "pull-right",
                                      attrs: {
                                        total: _vm.totalBuy,
                                        "pager-count": 5,
                                        "page-size": _vm.perBuyPage,
                                        "current-page": _vm.currentBuyPage,
                                        layout: "total, prev, pager, next",
                                      },
                                      on: {
                                        "update:currentPage": function (
                                          $event
                                        ) {
                                          _vm.currentBuyPage = $event
                                        },
                                        "update:current-page": function (
                                          $event
                                        ) {
                                          _vm.currentBuyPage = $event
                                        },
                                        "current-change":
                                          _vm.handleBuyCurrentChange,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.IsBuyProjectMenu
                    ? [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isbuyProjectCourse,
                                expression: "isbuyProjectCourse",
                              },
                            ],
                            staticClass:
                              "flex-1 yxtbiz-knglist-table over-hidden",
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "d-in-block w288" },
                                [
                                  _c("yxt-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "pc_biz_kng_lbl_kngTitle"
                                      ),
                                      searchable: "",
                                      clearable: "",
                                    },
                                    on: {
                                      clear: function ($event) {
                                        return _vm.searchLists(3)
                                      },
                                      search: function ($event) {
                                        return _vm.searchLists(3)
                                      },
                                    },
                                    model: {
                                      value: _vm.buyProjectInput,
                                      callback: function ($$v) {
                                        _vm.buyProjectInput = $$v
                                      },
                                      expression: "buyProjectInput",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex-1 flex mt16 h0 pb1" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-stretch width-percent-100",
                                  },
                                  [
                                    _c(
                                      "yxt-table",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.buyProjectListLoading,
                                            expression: "buyProjectListLoading",
                                          },
                                        ],
                                        ref: "buyProjectTable",
                                        attrs: {
                                          data: _vm.buyProjectTableData,
                                          "row-key": _vm.getRowKey,
                                          height: "100%",
                                        },
                                        on: {
                                          select: _vm.singleCancel,
                                          "selection-change":
                                            _vm.selectionBuyProjectChange,
                                          "select-all":
                                            _vm.handleBuyProjectSelectAll,
                                        },
                                      },
                                      [
                                        _c("yxt-table-column", {
                                          attrs: {
                                            type: "selection",
                                            width: "40",
                                            "reserve-selection": "",
                                            "show-overflow-tooltip": false,
                                          },
                                        }),
                                        _c("yxt-table-column", {
                                          attrs: {
                                            prop: "prodName",
                                            label: _vm.$t(
                                              "pc_biz_kng_lbl_title"
                                            ),
                                            "min-width": 300,
                                            "show-overflow-tooltip": "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      { staticClass: "flex" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "yxtbiz-knglist-tb-cell",
                                                          },
                                                          [
                                                            _c("yxt-svg", {
                                                              staticClass:
                                                                "flex-shrink-0 mr4",
                                                              attrs: {
                                                                width: "16px",
                                                                height: "20px",
                                                                "remote-url":
                                                                  _vm.kngFileTypeIconUrl,
                                                                "icon-class":
                                                                  _vm.getKngFileTypeIcon(
                                                                    scope.row
                                                                      .fileType
                                                                  ),
                                                              },
                                                            }),
                                                            _c(
                                                              "yxt-tooltip",
                                                              {
                                                                attrs: {
                                                                  placement:
                                                                    "top",
                                                                  "open-filter":
                                                                    "",
                                                                  "max-width": 300,
                                                                  content:
                                                                    scope.row
                                                                      .prodName,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "v-mid font-size-14 yxtbiz-knglist-tb-title",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "rgba(0,0,0,0.65)",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .prodName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            scope.row
                                                              .effective === 0
                                                              ? _c(
                                                                  "yxt-tag",
                                                                  {
                                                                    staticClass:
                                                                      "ml8 flex-shrink-0 mb0",
                                                                    attrs: {
                                                                      type: "danger",
                                                                      size: "mini",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "pc_biz_kng_lbl_invalid" /**未生效 */
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2197746621
                                          ),
                                        }),
                                        _c("yxt-table-column", {
                                          attrs: {
                                            prop: "studyHours",
                                            width: "150",
                                            label: _vm.$t(
                                              "pc_biz_kng_lbl_studyHours"
                                            ),
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getMinuteSecondFormat(
                                                            scope.row.studyHours
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3982326064
                                          ),
                                        }),
                                        !_vm.middlePlatform
                                          ? _c("yxt-table-column", {
                                              attrs: {
                                                prop: "orderCount",
                                                width: "150",
                                                label: _vm.$t(
                                                  "pc_biz_kng_lbl_IssueCount"
                                                ),
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row.buyOut
                                                                ? _vm.$t(
                                                                    "pc_biz_kng_lbl_buyout"
                                                                  )
                                                                : scope.row
                                                                    .orderCount
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3422137757
                                              ),
                                            })
                                          : _vm._e(),
                                        !_vm.middlePlatform
                                          ? _c("yxt-table-column", {
                                              attrs: {
                                                prop: "avlCount",
                                                width: "140",
                                                label: _vm.$t(
                                                  "pc_biz_kng_lbl_avlCount"
                                                ),
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row.buyOut
                                                                ? _vm.$t(
                                                                    "pc_biz_kng_lbl_buyout"
                                                                  )
                                                                : scope.row
                                                                    .avlCount
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                987152168
                                              ),
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "mt16 flex-mid h28" },
                              [
                                _c(
                                  "yxt-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      loading: _vm.selectAllBtnLoading,
                                      disabled: !_vm.buyProjectTableData.length,
                                    },
                                    on: { click: _vm.selectAll },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "pc_biz_kng_selectall" /** 筛选全部结果 */
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "flex-1" }),
                                _vm.totalBuyProject > _vm.perBuyPage
                                  ? _c("yxt-pagination", {
                                      staticClass: "pull-right",
                                      attrs: {
                                        total: _vm.totalBuyProject,
                                        "page-size": _vm.perBuyProjectPage,
                                        "pager-count": 5,
                                        "current-page":
                                          _vm.currentBuyProjectPage,
                                        layout: "total, prev, pager, next",
                                      },
                                      on: {
                                        "update:currentPage": function (
                                          $event
                                        ) {
                                          _vm.currentBuyProjectPage = $event
                                        },
                                        "update:current-page": function (
                                          $event
                                        ) {
                                          _vm.currentBuyProjectPage = $event
                                        },
                                        "current-change":
                                          _vm.handleBuyProjectCurrentChange,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _c("div", { staticClass: "yxtbiz-selectKng__side" }, [
                    _vm.maxNum
                      ? _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("pc_biz_kng_lbl_selected"))),
                          ]),
                          _c("span", [_vm._v(_vm._s(_vm.checkIdLists.length))]),
                          _c("span", [_vm._v(" / ")]),
                          _c("span", [_vm._v(_vm._s(_vm.maxNum))]),
                        ])
                      : _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("pc_biz_kng_lbl_selectedCount", [
                                  _vm.checkIdLists.length,
                                ])
                              )
                            ),
                          ]),
                        ]),
                    _c(
                      "div",
                      {
                        staticClass: "yxtbiz-selectKng__side--bottom mt12 flex",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "yxtbiz-selectKng__scroll-wrap" },
                          [
                            _c("yxtbiz-virtual-list", {
                              staticClass: "yxtbiz-hp100",
                              attrs: {
                                "is-scrollbar": "",
                                "estimate-size": 34,
                                keeps: 50,
                                "data-sources": _vm.checkIdLists,
                                "data-key": "id",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ source }) {
                                      return _c(
                                        "div",
                                        {},
                                        [
                                          _c(
                                            "yxt-tag",
                                            {
                                              attrs: {
                                                type: "info",
                                                closable: "",
                                              },
                                              on: {
                                                close: function ($event) {
                                                  return _vm.closeKng(source)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "yxt-tooltip",
                                                {
                                                  attrs: {
                                                    content: source.title,
                                                    "open-filter": "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "kng-tag-size",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(source.title)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                905369422
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "mt12" },
                      [
                        _c(
                          "yxt-button",
                          {
                            attrs: {
                              disabled: _vm.checkIdLists.length === 0,
                              type: "text",
                            },
                            on: { click: _vm.clearAllSelection },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("pc_biz_kng_btn_clearAll")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            2
          )
        : _c("div", { staticClass: "yui-txt-sub h100" }, [
            _c(
              "main",
              { staticClass: "flex-center h100" },
              [
                _c("yxt-empty", {
                  attrs: { "empty-text": _vm.$t("pc_biz_kng_lbl_noKng") },
                }),
              ],
              1
            ),
          ]),
      _vm.canSelectLibKng
        ? _c(
            "yxt-drawer",
            {
              attrs: {
                title: _vm.$t("pc_biz_kng_btn_selectKng"),
                "destroy-on-close": "",
                visible: _vm.selectingNewkng,
                size: "960px",
                "before-close": (done) => {
                  _vm.newKngs = []
                  done()
                },
                direction: "rtl",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.selectingNewkng = $event
                },
              },
            },
            [
              _c("yxtbiz-select-newkng", {
                attrs: { zip: false, max: 100 },
                model: {
                  value: _vm.newKngs,
                  callback: function ($$v) {
                    _vm.newKngs = $$v
                  },
                  expression: "newKngs",
                },
              }),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "yxt-button",
                    {
                      attrs: { plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.cancleNewkngSelect()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("pc_biz_kng_btn_cancel")))]
                  ),
                  _c(
                    "yxt-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveNewkngSelect()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("pc_biz_kng_btn_ok")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "yxt-button",
            { attrs: { plain: "" }, on: { click: _vm.clearData } },
            [_vm._v(_vm._s(_vm.$t("pc_biz_kng_btn_cancel")))]
          ),
          _c(
            "yxt-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.completeDialog },
            },
            [_vm._v(_vm._s(_vm.$t("pc_biz_kng_btn_ok")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }