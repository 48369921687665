import { kngApi, fileApi, qidaApi } from 'yxt-biz-pc/packages/api';
import * as util from './utils';

export default {
  // 获取知识库目录树
  getKngTree(bodyParams) {
    // return kngApi.post('kngCatalog/treeCache', bodyParams);
    return kngApi.post('kngCatalog/tree', bodyParams);
  },
  // 集团版
  getGroupKngTree(orgId, bodyParams) {
    // return kngApi.post('kngCatalog/treeCache', bodyParams);
    return kngApi.post(`classificate/share/list/${orgId}`, bodyParams);
  },
  postUlcdUpload(bodyParams) {
    return kngApi.post('ulcd/upload/kngs', bodyParams);
  },
  // 获取知识库列表数据
  getKngLists(linkParams, bodyParams) {
    return kngApi.post(util.linkSubString('knowledge/choosedKng/all', linkParams), bodyParams);
  },
  // 获取按次购买的课程列表数据
  getBuyLists(linkParams, bodyParams) {
    return kngApi.post(util.linkSubString('/course/facade/search', linkParams), bodyParams);
  },
  // 获取按项目购买的课程列表数据
  getBuyProjectLists(linkParams, bodyParams) {
    return kngApi.post(util.linkSubString('/project/search', linkParams), bodyParams);
  },
  // 获取我的分享的知识
  getMySharedKng(linkParams, bodyParams) {
    return kngApi.post(util.linkSubString('knowledge/choosedKng/myShared', linkParams), bodyParams);
  },
  // 获取部门员工分享的知识列表
  getDepartmentSharedKng(linkParams, bodyParams) {
    return kngApi.post(util.linkSubString('knowledge/choosedKng/departmentShared', linkParams), bodyParams);
  },
  // 保存最近挑选的知识
  keepKng(bodyParams) {
    return kngApi.post('knowledge/choosedKng', bodyParams);
  },
  // 获取最近挑选的知识列表
  getChoosedKng(linkParams, bodyParams) {
    return kngApi.post(util.linkSubString('knowledge/choosedKng/lately', linkParams), bodyParams);
  },

  // 只针对报表使用
  postChoosedKngReport(linkParams, bodyParams) {
    return kngApi.post(util.linkSubString('knowledge/choosedKng/report', linkParams), bodyParams);
  },
  // 获取知识库目录树，只针对报表使用
  getKngTreeReport(bodyParams) {
    return kngApi.post('kngCatalog/report', bodyParams);
  },
  // 获取企业是否开启审核
  getAuditConf() {
    return kngApi.get('audit/conf');
  },
  // 获取企业是否开启混合式部署
  getLocalConfig() {
    return fileApi.get('local/baseConfigById');
  },
  // 获取课的名字
  getCourseNames(params) {
    return kngApi.post('knowledge/queryKngInfoBatch', params);
  },
  // 列表页面需要的获取自定义属性值-编号
  getCustomAttributeKeyName() {
    return kngApi.get('attribute/item?type=1');
  },
  // 设置列表页面选中的展示列-编号
  postCustomAttributeCheckedKey(body) {
    return kngApi.post('attribute/set/item', body);
  },
  // 获取机构参
  getOrgpParameter(code, orgId) {
    return qidaApi.get(`orgparsetting/code?code=${code}&orgId=${orgId}`);
  }
};
