<template>
  <div class="yxtbiz-question-selector">
    <div class="yxtbiz-question-selector__treetable">
      <div class="yxtbiz-question-selector__treetable-main">
        <!-- 顶部搜索框 -->
        <div class="yxtbiz-question-flex mt32 yxtbiz-question-flex-wrap">
          <div class="yxtbiz-flex-shrink-0 yxtbiz-question-flex-grow mb16">
            <random-select
              v-if="multimedia"
              ref="randomSelectRef"
              class="d-in-block"
              :pointIds="pointIdsArr"
              :quesTypes="quesTypes"
              @setQuestion="setQuestion"
            ></random-select>
            <yxt-button class="ml8" type="second" @click="openSelectorDrawer">{{ $t('pc_biz_ote_lbl_choose_queslib_point' /* 选择题库/考点 */) }}</yxt-button>
          </div>

          <div class="yxtbiz-question-flex yxtbiz-question-flex-wrap mb16">
            <yxt-select
              v-model="quesSearchData._questionType"
              clearable
              class="mr8 width144"
              size="small"
              :placeholder="$t('pc_biz_ote_lbl_questiontype')"
              @change="filterAllQues(true)"
            >
              <yxt-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></yxt-option>
            </yxt-select>
            <yxt-select
              v-model="quesSearchData._levelType"
              clearable
              class="mr8 width144"
              size="small"
              :placeholder="$t('pc_biz_ote_lbl_queslevel')"
              @change="filterAllQues(true)"
            >
              <yxt-option
                v-for="item in levelTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></yxt-option>
            </yxt-select>
            <yxt-input
              v-model="quesSearchData.keyWord"
              class="width240"
              searchable
              size="small"
              :placeholder="$t('pc_biz_ote_lbl_searchques')"
              @search="filterAllQues(true)"
            >
            </yxt-input>
          </div>
        </div>
        <!-- 试题详细数据 -->
        <yxt-table
          ref="examTable"
          v-loading="loading"
          :data="selectTableList"
          :height="tableHeight"
          @select-all="quesSelectedOprateAll"
          @select="selectionChange"
        >
          <div slot="empty">
            <yxt-empty>
              <span slot="text">{{ $t('pc_biz_ote_lbl_tips_queslib_point' /* 请选择题库或考点 */) }}</span>
              <div class="mt16">
                <yxt-button type="second" @click="openSelectorDrawer">{{ $t('pc_biz_ote_lbl_go_select' /* 去选择 */) }}</yxt-button>
              </div>
            </yxt-empty>
          </div>
          <yxt-table-column
            type="selection"
            :show-overflow-tooltip="false"
            width="38"
            align="right"
            :selectable="checkRowSelectable"
            class-name="yxtbiz-question-selector__treetable-checkbox"
            clear-padding="left-right"
          >
          </yxt-table-column>
          <!-- 试题名称 -->
          <yxt-table-column
            :show-overflow-tooltip="false"
            :label="$t('pc_biz_ote_lbl_quesname')"
            prop="summary"
            min-width="170"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.summary" class="yxtbiz-table-row">
                <audio-video-icon
                  class="mr5 vertical-align-middle"
                  :type="scope.row.quesType"
                ></audio-video-icon>
                <yxt-tooltip
                  open-filter
                  class="yxtbiz-table-row__name color-primary-6 hand"
                  :max-width="300"
                  effect="dark"
                  :content="scope.row.summary"
                  placement="top"
                >
                  <span
                    :class="{
                      'color-bf cursor-normal': !checkRowSelectable(scope.row)
                    }"
                    class="ellipsis"
                    @click="oprate(scope.row, 2)">
                    {{ scope.row.summary }}
                  </span>
                </yxt-tooltip>
              </div>
              <!-- 题干纯图片 -->
              <span v-if="!scope.row.summary" @click="oprate(scope.row, 2)">
                <audio-video-icon
                  class="mr5 vertical-align-middle"
                  :type="scope.row.quesType"
                ></audio-video-icon>
                <yxt-svg
                  :remote-url="svgRemote"
                  icon-class="ques-image"
                  class="hand vertical-align-middle"
                  width="20px"
                  height="20px"
                ></yxt-svg>
              </span>
              <div class="font-size-12 color-bf">
                {{ isNull(scope.row.libName) }}
              </div>
            </template>
          </yxt-table-column>
          <!-- 考核点 -->
          <yxt-table-column
            :show-overflow-tooltip="true"
            :label="$t('pc_biz_ote_lbl_assessmentpoint')"
            prop="pointNames"
            :width="$isChinese ? '150' : '180'"
          >
          <template slot-scope="scope">
              <span
                :class="{
                  'color-bf': !checkRowSelectable(scope.row)
                }"
                >{{ scope.row.pointNames }}</span
              >
            </template>
          </yxt-table-column>
          <!-- 题型 -->
          <yxt-table-column
            :show-overflow-tooltip="false"
            :label="$t('pc_biz_ote_lbl_questiontype')"
            prop="type"
            :width="$isChinese ? '90' : '190'"
          >
            <template slot-scope="scope">
              <span
                :class="{
                  'color-bf': !checkRowSelectable(scope.row)
                }"
                >{{ getQuestionType(scope.row.type) }}</span
              >
            </template>
          </yxt-table-column>
          <!-- 难度 -->
          <yxt-table-column
            :show-overflow-tooltip="false"
            :label="$t('pc_biz_ote_lbl_queslevel')"
            prop="levelType"
            :width="$isChinese ? '80' : '120'"
          >
            <template slot-scope="scope">
              <span
                :class="{
                  'color-bf': !checkRowSelectable(scope.row)
                }"
                >{{ getQuestionLevalType(scope.row.levelType) }}</span
              >
            </template>
          </yxt-table-column>
        </yxt-table>
        <div class="block">
          <yxt-row class="mt16 text-right yxtbiz-question-selector__pager">
            <div class="yxtbiz-question-selector__pager-left">
              <yxt-button
                :loading="loadingAll"
                v-show="selectTableList && selectTableList.length"
                type="text"
                size="large"
                @click="addAllBasketData()"
              >
                {{ $t('pc_biz_ote_btn_allselect') }}
              </yxt-button>
            </div>
            <div class="yxtbiz-question-selector__pager-right">
              <yxt-pagination
                class="pull-right"
                :page-size="pageSize"
                :total="selectTableListCount"
                :current-page="currentPage"
                simple-total
                layout="total, prev, pager, next"
                @current-change="handleCurrentChange"
              ></yxt-pagination>
            </div>
          </yxt-row>
        </div>
      </div>
      <div class="yxtbiz-question-selector__basket">
        <span
          class="yxtbiz-question-selector__basket-open"
          @click="openBasketBox()"
        >
          <yxt-svg
            :remote-url="svgRemote"
            icon-class="exam-closeBacket"
            width="13px"
            height="10px"
          ></yxt-svg>
        </span>
        <div class="vertical-text hand" @click="openBasketBox">
          <div class="ml12">
            <!-- 试题篮 -->
            <span class="color-gray-8"
              ><yxt-tooltip
                class="item"
                effect="dark"
                :max-width="300"
                :content="$t('pc_biz_ote_lbl_quesbasket')"
                placement="top"
                ><yxt-badge
                  :max="999"
                  :value="basketBoxData.selectData.length"
                  :top="'-4px'"
                  class="item"
                >
                  <yxt-svg
                    :remote-url="svgRemote"
                    icon-class="ques-basket"
                    width="16px"
                    height="16px"
                  ></yxt-svg> </yxt-badge></yxt-tooltip
            ></span>
          </div>
        </div>
      </div>
    </div>
    <yxt-drawer
      size="640px"
      :title="$t('pc_biz_ote_lbl_quesbasket')"
      :wrapperClosable="true"
      :append-to-body="true"
      :before-close="handleClose"
      :visible.sync="basketBoxData.isShow"
    >
      <div class="yxtbiz-question-basket">
        <div class="lh20 mb16">
          {{ $t('pc_biz_ote_lbl_chosenquestions') }}：
          {{ basketBoxData.selectData.length }}{{ $t('pc_biz_ote_lbl_questi') }}
        </div>
        <div class="mb16">
          <!-- 批量移除 -->
          <yxt-button
            plain
            class=""
            @click.native="batchRemoveBasketData"
          >
            {{ $t('pc_biz_ote_btn_batchremove') }}
          </yxt-button>
          <!-- 清空 -->
          <yxt-button
            plain
            class=""
            @click.native="removeAllBasketData"
          >
            {{ $t('pc_biz_ote_lbl_clearall') }}
          </yxt-button>
        </div>
        <!-- 题型 -->
        <div class="">
          <yxt-select
            v-model="basketBoxData.searchData._questionType"
              size="small"
            class="mr12 mb16 width144"
            clearable
            :placeholder="$t('pc_biz_ote_lbl_questiontype')"
            @change="getBasketSelectData()"
          >
            <yxt-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></yxt-option>
          </yxt-select>
          <!-- 难度 -->
          <yxt-select
            v-model="basketBoxData.searchData._levelType"
            size="small"
            class="mr12 mb16 width144"
            clearable
            :placeholder="$t('pc_biz_ote_lbl_queslevel')"
            @change="getBasketSelectData()"
          >
            <yxt-option
              v-for="item in levelTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></yxt-option>
          </yxt-select>
          <yxt-input
            v-model="basketBoxData.searchData.keyWord"
            size="small"
            class="mb16 width200"
            searchable
            :placeholder="$t('pc_biz_ote_lbl_searchques')"
            @search="filterSelectedQues"
          >
          </yxt-input>
        </div>
        <div class="">
          <yxt-table
            v-loading="loading"
            class="clazz_width_100"
            :data="basketBoxData.currentSelectData"
            :default-sort="{ prop: 'updateTime', order: 'descending' }"
            @selection-change="basketBoxSelectionChange"
          >
            <yxt-table-column
              type="selection"
              :show-overflow-tooltip="false"
              width="38"
              align="right"
              clear-padding="left-right"
            ></yxt-table-column>
            <!-- 试题名称 -->
            <yxt-table-column
              :label="$t('pc_biz_ote_lbl_quesname')"
              prop="summary"
              :show-overflow-tooltip="false"
              min-width="300px"
            >
              <template slot-scope="scope">
                <div
                  v-if="scope.row.summary"
                  class="yxtbiz-table-row"
                  @click="oprate(scope.row, 2)"
                >
                  <audio-video-icon
                    class="mr5 vertical-align-middle"
                    :type="scope.row.quesType"
                  ></audio-video-icon>
                  <yxt-tooltip
                    class="yxtbiz-table-row__name color-primary-6 hand"
                    open-filter
                    :max-width="300"
                    effect="dark"
                    :content="scope.row.summary"
                    placement="top"
                  >
                    <span class="ellipsis">{{ scope.row.summary }}</span>
                  </yxt-tooltip>
                </div>
                <!-- 题干纯图片 -->
                <span v-if="!scope.row.summary" @click="oprate(scope.row, 2)">
                  <audio-video-icon
                    class="mr5 vertical-align-middle"
                    :type="scope.row.quesType"
                  ></audio-video-icon>
                  <yxt-svg
                    :remote-url="svgRemote"
                    icon-class="ques-image"
                    class="hand vertical-align-middle"
                    width="20px"
                    height="20px"
                  ></yxt-svg>
                </span>
                <div class="font-size-12 color-bf">
                  {{ isNull(scope.row.libName) }}
                </div>
              </template>
            </yxt-table-column>
            <yxt-table-column
              :label="$t('pc_biz_ote_btn_operate')"
              align="left"
              width="80"
            >
              <template slot-scope="scope">
                <!-- 删除 -->
                <yxt-link
                  :underline="false"
                  :type="scope.row.status != 2 ? 'text' : 'primary'"
                  :disabled="scope.row.status != 2"
                  @click="quesSelectedOprate(scope.row, 1)"
                >
                  {{ $t('pc_biz_ote_btn_delete') }}
                </yxt-link>
              </template>
            </yxt-table-column>
          </yxt-table>
          <div class="block">
            <yxt-row class="mt16 text-right lh30">
              <yxt-pagination
                class="pull-right"
                :page-size="basketBoxData.searchData.limit"
                :total="basketBoxData.filterData.length"
                :current-page="basketBoxData.searchData.current"
                layout="total, prev, pager, next"
                @current-change="quesHandleCurrentChange"
              ></yxt-pagination>
            </yxt-row>
          </div>
        </div>
      </div>
    </yxt-drawer>
    <!-- 试题查看 -->
    <yxt-drawer
      ref="quesViewDrawer"
      size="640px"
      :title="$t('pc_biz_ote_lbl_quesview')"
      :visible.sync="quesDetailData.dialogVisible"
      :before-close="handleClose"
      :destroyOnClose="true"
    >
      <ques-view :id="quesDetailData.id"></ques-view>
    </yxt-drawer>
    <!-- 试题选择题库/考核点 -->
    <yxt-drawer
      size="480px"
      :title="$t('pc_biz_ote_lbl_choose_queslib_point')"
      :visible.sync="quesLeftDrawer.visible"
      :before-close="handleClose"
      :destroyOnClose="true"
    >
      <ques-selector-left ref="selectorLeft" :functionCode="functionCode"></ques-selector-left>
      <div slot="footer">
        <yxt-button plain @click="quesLeftDrawer.visible = false">{{ $t('pc_biz_ote_btn_cancle' /* 取消 */) }}</yxt-button>
        <yxt-button type='primary' @click="confirmLeftPoints">{{ $t('pc_biz_ote_btn_confirm' /* 确定 */) }}</yxt-button>
      </div>
    </yxt-drawer>
  </div>
</template>

<script type='text/ecmascript-6'>
import * as quesService from './service.js';
import {
  htmlEncode,
  QUES_TYPE_NAMES,
  QUES_LEVEL_NAMES,
  initQuesLevelType
} from './utils';
import quesView from './quesView';
import resizeTable from '../../user-selector/mixins/resizeTable';
import audioVideoIcon from './selector/audioVideoIcon';
import RandomSelect from './selector/randomSelect';
import quesSelectorLeft from './quesSelectorLeft.vue';
export default {
  name: 'YxtbizQuestionSelector',
  props: {
    // 是否包含主管题
    subjectivity: {
      type: Boolean,
      default: true
    },
    // 包含的题型。 试题题型 0-单选题 1-多选题 2-判断题 3-填空题 4-问答题 5-组合题。 例子：只需要单选、判断时传 [1, 3, 4, 5]
    includeQuesTypes: {
      type: Array,
      default: () => [0, 1, 2, 3, 4, 5]
    },
    // 是否包含多媒体内容的题目（视频、音频、图片）
    multimedia: {
      type: Boolean,
      default: true
    },
    // 页面功能编号（暂时都走的测练这边的权限，暂时不能改）
    functionCode: {
      type: String,
      default: 'ote_ques_lib_mgmt'
    },
    // 已选择的试题信息
    quesList: {
      type: Array,
      default: () => []
    },
    // 是否打开试题蓝
    basket: {
      type: Boolean,
      default: false
    }
  },
  mixins: [resizeTable],
  components: {
    RandomSelect,
    quesView,
    audioVideoIcon,
    quesSelectorLeft
  },
  data() {
    return {
      svgRemote: `${this.$staticBaseUrl}ufd/55a3e0/ote/pc/svg`,
      pointIdsArr: [],
      dialogEdited: false,
      loaded: false,
      loadingAll: false,
      cloneArr: [],
      currentPage: 1,
      refTable: 'examTable',
      pageSize: 10,
      selectTableListCount: 0,
      selectTableList: [],
      tableHeight: 400,

      quesDetailData: {
        dialogVisible: false,
        id: ''
      },

      selectData: [], // 外部试题池列表已勾选的数据
      selectIds: [], // 外部试题池列表已勾选的数据ids
      basketBoxData: {
        // 试题篮数据
        selectIds: [],
        isShow: false,
        selectNum: 0,
        searchData: {
          keyWord: '',
          _questionType: '',
          _levelType: '',
          limit: 6,
          current: 1,
          pointIds: [],
          count: 0
        },
        currentSelectData: [],
        filterData: [],
        selectData: []
      },
      loading: false,
      tableData: [],
      selectAllQuesList: [], // 试题池所有试题列表
      filterAllQuesList: [], // 筛选后的试题池所有试题列表
      selectQuesList: [], // 试题池列表
      searchData: {
        // 试题池搜索参数query
        limit: 8,
        count: 0
      },
      quesSearchData: {
        // 试题池搜索参数parma
        navCode: this.functionCode,
        keyWord: '',
        levelType: -1,
        pointIds: [],
        questionType: -1
      },

      // 难度
      levelTypeList: QUES_LEVEL_NAMES.values.map((v) => ({ label: this.$t(QUES_LEVEL_NAMES[v]), value: v })),
      // 是否列表接口未返回信息
      waitListLoading: false,
      quesLeftDrawer: {
        visible: false
      }
    };
  },
  created() {
    initQuesLevelType(() => {
      this.levelTypeList = QUES_LEVEL_NAMES.values.map((v) => ({ label: this.$t(QUES_LEVEL_NAMES[v]), value: v }));
    });

    // 初始化已选的试题
    if (this.quesList && this.quesList.length > 0) {
      this.selectDataTobasket(this.quesList);
      this.$nextTick(() => {
        this.basket && this.openBasketBox();
      });
    }
  },
  computed: {
    typeList() {
      // 题型 0-单选题 1-多选题 2-判断题 3-填空题 4-问答题
      return this.quesTypes.map(e => {
        return {
          value: e,
          label: this.$t(QUES_TYPE_NAMES[e])
        };
      });
    },
    quesTypes() {
      const subjectivityType = [3, 4, 5];
      let types = [];
      for (let index = 0; index <= 5; index++) {
        if (
          this.includeQuesTypes.indexOf(index) >= 0 &&
          !(!this.subjectivity && subjectivityType.indexOf(index) >= 0)
        ) {
          types.push(index);
        }
      }
      return types;
    }
  },
  watch: {
    basketBoxData: {
      deep: true,
      handler: function() {
        this.dialogEdited = this.loaded;
      }
    },
    'searchData.type'(val, old) {
      if (val !== old) {
        this.getBasketSelectData();
        this.dialogEdited = this.loaded;
      }
    },
    'searchData.levelType'(val, old) {
      if (val !== old) {
        this.getBasketSelectData();
        this.dialogEdited = this.loaded;
      }
    },
    'basketBoxData.searchData.keywords'(val) {
      this.dialogEdited = this.loaded;
    },
    'basketBoxData.searchData._questionType'(val, old) {
      if (val !== old) {
        this.filterSelectedQues();
        this.dialogEdited = this.loaded;
      }
    },
    'basketBoxData.searchData._levelType'(val, old) {
      if (val !== old) {
        this.filterSelectedQues();
        this.dialogEdited = this.loaded;
      }
    },
    waitListLoading(v) {
      !v && this.getAllQues();
    }
  },
  methods: {
    checkRowSelectable(row, index) {
      return !row.isMultiMediaType;
    },
    // 获取选中的试题信息，暴露给使用方的方法
    getSelected() {
      return this.basketBoxData.selectData;
    },

    /**
     * 如果列表loading过程中先把waitListLoading置为true
     * 列表请求结束后把waitListLoading置为false
     * 判断是false再去请求一把列表接口
     */
    ifLoadingSetWait() {
      if (this.loading) {
        this.waitListLoading = true;
      } else {
        this.getAllQues();
      }
    },
    // 获取试题
    getAllQues() {
      if (this.quesSearchData.pointIds.length <= 0) {
        return;
      }
      this.quesSearchData.isSignMultiMedia = this.multimedia ? 0 : 1;
      this.quesSearchData.isFilterSubjectiveQues = this.subjectivity ? 0 : 1;
      this.quesSearchData.types = this.includeQuesTypes;
      this.loading = true;
      quesService
        .getQuesByPoints(
          { current: this.currentPage, limit: this.pageSize },
          this.quesSearchData
        )
        .then(res => {
          if (this.waitListLoading) return;
          this.selectTableList = res.datas;
          this.selectTableListCount = res.paging.count;
          this.selectTableList.forEach((v, index) => {
            this.basketBoxData.selectData.forEach((j, k) => {
              if (j.id === v.id) {
                this.$nextTick(item => {
                  this.$refs.examTable.toggleRowSelection(v, true);
                });
              }
            });
          });
        })
        .catch(err => {
          this.$message.warning(err.message);
        })
        .finally(() => {
          this.loading = false;
          this.waitListLoading = false;
        });
    },
    // 筛选试题池所有试题
    filterAllQues(isResetPage) {
      if (isResetPage) {
        this.currentPage = 1;
        this.searchData.current = 1; // 分页重置
      }
      let questionType =
        this.quesSearchData._questionType === ''
          ? -1
          : this.quesSearchData._questionType;
      let levelType =
        this.quesSearchData._levelType === ''
          ? -1
          : this.quesSearchData._levelType;
      let keywords = this.quesSearchData.keyWord.trim();
      // 试题池搜索参数parma
      this.quesSearchData = Object.assign(this.quesSearchData, {
        keyWord: keywords,
        levelType: levelType,
        questionType
      });
      // 重新获取当前页数据
      this.ifLoadingSetWait();
    },
    // 试题池分页搜索
    handleCurrentChange(val) {
      this.currentPage = val;
      this.ifLoadingSetWait();
    },
    // 批量选择
    batchAddBasketData() {
      if (this.selectIds.length <= 0) {
        this.$message.warning(this.$t('pc_biz_ote_lbl_pleaseenterques'));
        return;
      }
      let idVal = this.basketBoxData.selectData.map(item => item.id);
      let arr = this.selectData.filter(item => !idVal.includes(item.id));
      this.basketBoxData.selectData = this.basketBoxData.selectData.concat(arr);
      this.basketBoxData.filterData = this.basketBoxData.selectData;
      this.basketBoxData.searchData.count = this.selectData.length;
      // 重新获取当前页数据
      this.getBasketSelectData();
    },
    // 批量改为未选择
    batchChangeQuesStatus(list, status) {
      list.forEach((s, h) => {
        this.$set(s, 'isSelect', status);
      });
    },
    // 获取筛选出来的全部试题，不分页
    addAllBasketData() {
      if (this.quesSearchData.pointIds.length < 1) {
        this.selectAllQuesList = [];
        this.filterAllQuesList = [];
        this.selectQuesList = [];
        this.searchData.pages = 0;
        this.searchData.count = 0;
        return false;
      }

      this.quesSearchData.questionType =
        this.quesSearchData._questionType === ''
          ? -1
          : this.quesSearchData._questionType;
      this.quesSearchData.levelType =
        this.quesSearchData._levelType === ''
          ? -1
          : this.quesSearchData._levelType;
      this.quesSearchData.keyWord = this.quesSearchData.keyWord.trim();
      this.quesSearchData.types = this.quesTypes;
      this.loadingAll = true;
      quesService
        .getNoPageQuesByLibAndPoint(this.quesSearchData)
        .then(res => {
          this.selectDataTobasket(res);
          this.loadingAll = false;
        })
        .catch(err => {
          this.$message.warning(err.message);
          this.loadingAll = false;
        });
    },
    selectDataTobasket(quesList) {
      this.selectTableList.forEach((v, index) => {
        quesList.find(e => e.id === v.id) &&
              this.$nextTick(() => {
                this.$refs.examTable.toggleRowSelection(v, true);
              });
      });
      quesList.forEach((v, index) => {
        let isExited = false;
        this.basketBoxData.selectData.forEach((j, k) => {
          if (j.id === v.id) {
            isExited = true;
          }
        });
        if (!isExited) {
          this.basketBoxData.selectData.push(v);
          this.basketBoxData.selectNum++;
        }
      });
      this.basketBoxData.filterData = this.basketBoxData.selectData;
    },
    // 试题选择多选事件
    selectionChange(val, row) {
      if (!val.map(item => item.id).includes(row.id)) {
        this.basketBoxData.selectData.forEach((item2, index) => {
          if (item2.id === row.id) {
            this.basketBoxData.selectData.splice(index, 1);
          }
        });
        this.basketBoxData.filterData = this.basketBoxData.selectData;
        this.basketBoxData.searchData.count = this.selectData.length;
        // 重新获取当前页数据
        this.getBasketSelectData();
      } else {
        let idArry = [];
        for (var key in val) {
          idArry.push(val[key].id);
        }
        this.selectIds = idArry;
        this.selectData = val;
        if (this.selectData.length > 0) {
          this.batchAddBasketData();
        }
      }
    },
    // 初始化已选择的试题数据
    initSelectedData(quesList) {
      if (!quesList || quesList.length === 0) {
        return;
      }
      this.selectIds = quesList.map(q => q.id);
      this.selectData = quesList;

      this.batchAddBasketData();
    },
    // 全选
    quesSelectedOprateAll(val) {
      if (val && val.length) {
        let idVal = this.basketBoxData.selectData.map(item => item.id);
        let arr = val.filter(item => !idVal.includes(item.id));

        this.basketBoxData.selectData =
          this.basketBoxData.selectData.concat(arr);

        this.basketBoxData.filterData = this.basketBoxData.selectData;
        this.basketBoxData.searchData.count = this.selectData.length;
        // 重新获取当前页数据
        this.getBasketSelectData();
      } else {
        let idVal = this.selectTableList.map(item => item.id);
        let arr = this.basketBoxData.selectData.filter(
          item => !idVal.includes(item.id)
        );
        this.basketBoxData.selectData = arr;
        this.basketBoxData.filterData = this.basketBoxData.selectData;
      }
      this.$forceUpdate();
    },
    // 页面部分操作
    oprate(e, type) {
      if (!this.checkRowSelectable(e)) {
        return;
      }

      // 查看
      if (type === 2) {
        this.quesDetailData.id = e.id;
        this.quesDetailData.dialogVisible = true;
        return;
      }
      // 删除
      this.selectQuesList.forEach((v, index) => {
        if (v.id === e.id) {
          if (type === 0) {
            // 选择
            this.basketBoxData.selectNum++;
            this.basketBoxData.selectData.push(e);
            this.basketBoxData.searchData.count =
              this.basketBoxData.selectData.length;
          } else if (type === 1) {
            // 取消选择
            this.basketBoxData.selectNum--;
            let tdata = [];
            this.basketBoxData.selectData.forEach((j, k) => {
              if (j.id !== e.id) {
                tdata.push(j);
              }
            });
            this.basketBoxData.selectData = tdata;
            this.basketBoxData.searchData.count =
              this.basketBoxData.selectData.length;
          }
        }
      });
    },
    // 随机抽题
    setQuestion(val = []) {
      if (val.length === 0) {
        return;
      }
      let idVal = this.basketBoxData.selectData.map(item => item.id);
      let arr = val.filter(item => !idVal.includes(item.id));
      this.basketBoxData.selectData = this.basketBoxData.selectData.concat(arr);
      this.basketBoxData.filterData = this.basketBoxData.selectData;
      this.basketBoxData.searchData.count = this.selectData.length;
      // 重新获取当前页数据
      this.selectTableList.forEach((v, index) => {
        this.basketBoxData.selectData.forEach((j, k) => {
          if (j.id === v.id) {
            this.$nextTick(item => {
              this.$refs.examTable.toggleRowSelection(v, true);
            });
          }
        });
      });
      this.getBasketSelectData();
    },
    // 试题蓝获取当前页筛选数据
    getBasketFilterData(batch) {
      let currentPage = this.basketBoxData.searchData.current;
      let limit = this.basketBoxData.searchData.limit;
      let start = (currentPage - 1) * limit;
      let end = currentPage * limit;
      let data = this.basketBoxData.filterData;
      let cData = data.slice(start, end);
      this.basketBoxData.currentSelectData = JSON.parse(JSON.stringify(cData));
      if (batch) {
        this.filterSelectedQues();
      }
      this.$forceUpdate();
    },
    // 清空试题篮试题
    removeAllBasketData() {
      if (this.basketBoxData.selectData.length <= 0) {
        this.$message.warning(this.$t('pc_biz_ote_lbl_pleaseenterques'));
        return;
      }
      this.$confirm('', this.$t('pc_biz_ote_msg_sureemptybasket'), {
        confirmButtonText: this.$t('pc_biz_ote_btn_confirm'),
        cancelButtonText: this.$t('pc_biz_ote_btn_cancle'),
        type: 'warning'
      }).then(() => {
        this.selectTableList.forEach(item => {
          this.basketBoxData.selectData.forEach(item2 => {
            if (item2.id === item.id) {
              this.$refs.examTable.toggleRowSelection(item, false);
            }
          });
        });
        this.batchChangeQuesStatus(this.selectAllQuesList, false);
        this.batchChangeQuesStatus(this.filterAllQuesList, false);
        this.batchChangeQuesStatus(this.selectQuesList, false);
        this.basketBoxData.selectNum = 0;
        this.basketBoxData.selectIds = [];
        this.basketBoxData.selectData = [];
        this.basketBoxData.filterData = [];
        this.basketBoxData.currentSelectData = [];
        this.basketBoxData.searchData.count = 0;
      });
    },
    // 批量移除试题篮试题
    batchRemoveBasketData() {
      if (this.basketBoxData.selectIds.length < 1) {
        this.$message.warning(this.$t('pc_biz_ote_lbl_pleaseenterques'));
        return;
      }
      // 已选试题篮去除该试题
      this.basketBoxData.selectData = this.basketBoxData.selectData.filter(
        (item, index) => !this.basketBoxData.selectIds.includes(item.id)
      );
      this.basketBoxData.filterData = this.basketBoxData.selectData;
      // 已选试题的当前页去除该试题
      this.selectTableList.forEach(item => {
        this.basketBoxData.selectIds.forEach(item2 => {
          if (item2 === item.id) {
            this.$refs.examTable.toggleRowSelection(item, false);
          }
        });
      });

      // 重新获取当前页数据
      this.getBasketFilterData(true);
    },
    // 试题篮分页
    getBasketSelectData() {
      let currentPage = this.basketBoxData.searchData.current;
      let limit = this.basketBoxData.searchData.limit;
      let start = (currentPage - 1) * limit;
      let end = currentPage * limit;
      let data = this.basketBoxData.selectData;
      let cData = data.slice(start, end);
      this.basketBoxData.currentSelectData = JSON.parse(JSON.stringify(cData));
      this.$forceUpdate();
    },
    // 试题篮删除试题
    quesSelectedOprate(e) {
      // 已选试题总列表去除该试题
      this.basketBoxData.selectData.forEach((m, k) => {
        if (m.id === e.id) {
          this.basketBoxData.selectData.splice(k, 1);
        }
      });
      // 已选试题的当前页去除该试题
      this.selectTableList.forEach((item, index) => {
        if (item.id === e.id) {
          this.$refs.examTable.toggleRowSelection(item, false);
        }
      });
      this.basketBoxData.currentSelectData.forEach((j, s) => {
        if (j.id === e.id) {
          this.basketBoxData.currentSelectData.splice(s, 1);
        }
      });
      this.basketBoxData.filterData.forEach((j, s) => {
        if (j.id === e.id) {
          this.basketBoxData.filterData.splice(s, 1);
        }
      });
      // 试题篮数量减1
      this.basketBoxData.searchData.count =
        this.basketBoxData.selectData.length;
      // 重新获取当前页数据
      this.getBasketFilterData();
      this.$forceUpdate();
    },
    // 试题篮筛选事件
    filterSelectedQues() {
      this.basketBoxData.searchData.current = 1; // 分页重置
      let arr = this.basketBoxData.selectData;
      let keywords = this.basketBoxData.searchData.keyWord.trim();
      let levelType =
        this.basketBoxData.searchData._levelType === ''
          ? -1
          : this.basketBoxData.searchData._levelType;
      let questionType =
        this.basketBoxData.searchData._questionType === ''
          ? -1
          : this.basketBoxData.searchData._questionType;
      let arr2 = arr.filter((value, key) => {
        let filter1 = true;
        let filter2 = true;
        let filter3 = true;
        if (levelType > -1) {
          filter1 = value.levelType === levelType;
        }
        if (questionType > -1) {
          filter2 = value.type === questionType;
        }
        if (keywords) {
          filter3 = value.summary.indexOf(keywords) > -1;
        }
        return filter3 && filter1 && filter2;
      });
      this.basketBoxData.filterData = arr2;
      // 重新获取当前页数据
      this.getBasketFilterData();
    },
    // 试题篮多选事件
    basketBoxSelectionChange(val) {
      let idArry = [];
      for (var key in val) {
        idArry.push(val[key].id);
      }
      this.basketBoxData.selectIds = idArry;
    },
    // 试题篮分页搜索
    quesHandleCurrentChange(val) {
      this.basketBoxData.searchData.current = val;
      this.getBasketSelectData();
    },
    // 打开试题篮列表弹窗
    openBasketBox() {
      this.basketBoxData.searchData.current = 1;
      this.basketBoxData.searchData._questionType = '';
      this.basketBoxData.searchData._levelType = '';
      this.basketBoxData.searchData.keyWord = '';
      this.getBasketSelectData();
      this.basketBoxData.isShow = true;
    },
    // 关闭试题篮列表弹窗
    closeBasketBox() {
      this.basketBoxData.isShow = false;
    },
    // 获取考核点移入html
    getPointNamesHtml(pointNames) {
      let _html = '';
      let names = pointNames.split(';');
      for (let i = 0; i < names.length; i++) {
        _html += htmlEncode(names[i]) + '<br>';
      }
      return _html;
    },
    // 获取难度名称
    getQuestionLevalType(val) {
      return QUES_LEVEL_NAMES[val]
        ? this.$t(QUES_LEVEL_NAMES[val])
        : '';
    },
    // 获取题型名称
    getQuestionType(val) {
      return QUES_TYPE_NAMES.length > val ? this.$t(QUES_TYPE_NAMES[val]) : '';
    },
    isNull(e) {
      return e || '-';
    },
    handleClose(done) {
      done();
    },

    // 打开题库/考核点选择抽屉
    openSelectorDrawer() {
      this.quesLeftDrawer.visible = true;

      this.$refs.randomSelectRef && this.$refs.randomSelectRef.handleCancel();
    },

    // 确认选中的考核点和题库
    confirmLeftPoints() {
      const points = this.$refs.selectorLeft && this.$refs.selectorLeft.points;

      if (points.length) {
        this.quesSearchData.pointIds = points.map(item => {
          return item.id;
        });
        this.pointIdsArr = this.quesSearchData.pointIds;

        this.filterAllQues(true);
      }

      this.quesLeftDrawer.visible = false;
    }
  }
};
</script>
