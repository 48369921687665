var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-upload__dragger color-primary-6",
      class: {
        "yxtbiz-upload__dragger--dragover": _vm.dragover,
      },
      on: {
        drop: function ($event) {
          $event.preventDefault()
          return _vm.onDrop.apply(null, arguments)
        },
        dragover: function ($event) {
          $event.preventDefault()
          return _vm.onDragover.apply(null, arguments)
        },
        dragleave: function ($event) {
          $event.preventDefault()
          _vm.dragover = false
        },
      },
    },
    [
      _vm.$slots.default
        ? _vm._t("default")
        : [
            _vm.dragover
              ? _c("div", { staticClass: "font-size-16 color-gray-10" }, [
                  _vm._v(_vm._s(_vm.$t("pc_biz_upload_lbl_dragover"))),
                ])
              : _c(
                  "div",
                  [
                    _c("yxt-svg", {
                      staticClass: "yxtbiz-upload__icon color-primary-6",
                      attrs: { "icon-class": _vm.iconClass },
                    }),
                    _c("div", { staticClass: "color-gray-10 font-size-16" }, [
                      _vm._v(_vm._s(_vm.$t("pc_biz_upload_lbl_drag"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "color-gray-6 font-size-14 mt5" },
                      [_vm._t("tip")],
                      2
                    ),
                  ],
                  1
                ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }