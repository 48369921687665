var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-select-course" }, [
    _c(
      "div",
      { staticClass: "clearfix yxtbiz-select-course__tb" },
      [
        _c(
          "div",
          { staticClass: "yxtbiz-select-course__operate" },
          [
            _c(
              "select-popover",
              {
                ref: "selectPopover",
                staticClass: "d-in-block w240 mr16",
                attrs: { selectOpition: _vm.selectOpition },
              },
              [
                _c(
                  "div",
                  { staticClass: "yxtbiz-kng-tree table_tree" },
                  [
                    _c("yxt-input", {
                      attrs: {
                        placeholder: _vm.$t("pc_biz_kng_lbl_searchCatalog"),
                        searchable: "",
                      },
                      on: { search: _vm.searchCatalog },
                      model: {
                        value: _vm.filterKngTree,
                        callback: function ($$v) {
                          _vm.filterKngTree = $$v
                        },
                        expression: "filterKngTree",
                      },
                    }),
                    _c("yxt-tree", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.treeLoading,
                          expression: "treeLoading",
                        },
                      ],
                      ref: "filterTree",
                      staticClass: "yxtbiz-biz-tree-funs mt16",
                      class: _vm.kngTreeData.length > 0 ? "d-in-block" : "",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "node-key": "id",
                        "empty-text": _vm.$t("pc_biz_kng_lbl_noCatalog"),
                        data: _vm.kngTreeData,
                        props: _vm.defaultProps,
                        "filter-node-method": _vm.filterNode,
                        "default-expanded-keys": _vm.treeExpand,
                        "expand-on-click-node": false,
                      },
                      on: { "node-click": _vm.ClickTreeNode },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "d-in-block" },
              [
                _c("yxt-input", {
                  staticClass: "yxtbiz-select-course__search w240",
                  attrs: {
                    placeholder: _vm
                      .$t("pc_biz_course_tip_search_kngandteaname")
                      .d("请输入课程名称/讲师姓名搜索"),
                    maxLength: "20",
                    searchable: "",
                  },
                  on: { search: _vm.searchLists },
                  model: {
                    value: _vm.keyword,
                    callback: function ($$v) {
                      _vm.keyword = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "keyword",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "yxt-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.kngListLoading,
                expression: "kngListLoading",
              },
            ],
            ref: "kngTable",
            staticClass: "mt16",
            attrs: { data: _vm.KngTableData, "row-key": _vm.getRowKey },
            on: {
              select: _vm.singleCancel,
              "selection-change": _vm.selectionChange,
              "select-all": _vm.handleKngSelectAll,
            },
          },
          [
            !_vm.singleMode
              ? _c("yxt-table-column", {
                  attrs: {
                    type: "selection",
                    width: "40",
                    "reserve-selection": "",
                    "show-overflow-tooltip": false,
                  },
                })
              : _vm._e(),
            _c("yxt-table-column", {
              attrs: {
                prop: "courseName",
                width: "278",
                label: _vm.$t("pc_biz_lbl_course_title" /*课程名称*/),
                "show-overflow-tooltip": "",
              },
            }),
            _vm.isShowCourseType
              ? _c("yxt-table-column", {
                  attrs: {
                    prop: "type",
                    label: _vm.$t("pc_biz_lbl_course_type" /*课程类别*/),
                    width: "140",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.COURSETYPE[scope.row.type]) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2459523558
                  ),
                })
              : _vm._e(),
            _c("yxt-table-column", {
              attrs: {
                prop: "teacherListName",
                label: _vm.$t("pc_biz_lbl_certified_lecture" /*认证讲师*/),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "yxt-tooltip",
                        {
                          attrs: {
                            placement: "top",
                            "open-filter": "",
                            effect: "dark",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ellipsis" },
                            [
                              _c("yxtbiz-user-name", {
                                attrs: {
                                  name: _vm.handLecturer(
                                    scope.row.teacherListName
                                  ),
                                  split: ",",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("yxtbiz-user-name", {
                            attrs: {
                              slot: "content",
                              name: scope.row.teacherListName,
                              split: ",",
                            },
                            slot: "content",
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("yxt-table-column", {
              attrs: {
                prop: "courseVersion",
                label: _vm.$t("pc_biz_lbl_version" /*版本*/),
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.courseVersion || "--") +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm.singleMode
              ? _c("yxt-table-column", {
                  attrs: {
                    label: _vm.$t("pc_biz_te_lbl_operation"),
                    width: "100",
                    align: "left",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "yxt-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectTea(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.value &&
                                      (_vm.value.id === scope.row.id ||
                                        (_vm.value[0] &&
                                          _vm.value[0].id === scope.row.id))
                                      ? _vm.$t(
                                          "pc_biz_te_btn_cancelselect" /*取消选择*/
                                        )
                                      : _vm.$t("pc_biz_te_btn_select" /*选择*/)
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2522519279
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
        _c("yxt-pagination", {
          staticClass: "mt16 pull-right",
          attrs: {
            total: _vm.totalKng,
            "page-size": _vm.limit,
            "current-page": _vm.pageIndex,
            layout: "total, prev, pager, next",
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.pageIndex = $event
            },
            "update:current-page": function ($event) {
              _vm.pageIndex = $event
            },
            "current-change": _vm.handleKngCurrentChange,
          },
        }),
      ],
      1
    ),
    !_vm.singleMode
      ? _c(
          "div",
          { staticClass: "font-size-14 color-gray-10 mt16" },
          [
            _vm.max
              ? _c("div", { staticClass: "d-in-block" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("pc_biz_kng_lbl_selected"))),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.checkIdLists.length))]),
                  _c("span", [_vm._v(" / ")]),
                  _c("span", [_vm._v(_vm._s(_vm.max))]),
                ])
              : _c("div", { staticClass: "d-in-block" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("pc_biz_kng_lbl_selectedCount", [
                          _vm.checkIdLists.length,
                        ])
                      )
                    ),
                  ]),
                ]),
            _c(
              "yxt-button",
              {
                staticClass: "pull-right",
                attrs: {
                  disabled: _vm.checkIdLists.length === 0,
                  type: "text",
                },
                on: { click: _vm.clearAllSelection },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("pc_biz_kng_btn_clearAll")) +
                    "\n    "
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    !_vm.singleMode
      ? _c(
          "div",
          { staticClass: "yxtbiz-select-course__choiced" },
          [
            _c(
              "yxt-scrollbar",
              { attrs: { "fit-height": true } },
              _vm._l(_vm.checkIdLists, function (tag) {
                return _c(
                  "yxt-tag",
                  {
                    key: tag.id,
                    staticClass: "mr10 mb6",
                    attrs: { type: "info", closable: "" },
                    on: {
                      close: function ($event) {
                        return _vm.closeKng(tag)
                      },
                    },
                  },
                  [
                    _c(
                      "yxt-tooltip",
                      {
                        attrs: {
                          placement: "top",
                          "open-filter": "",
                          content: tag.courseName,
                          effect: "dark",
                        },
                      },
                      [
                        _c("span", { staticClass: "course-tag-size" }, [
                          _vm._v(_vm._s(tag.courseName)),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }