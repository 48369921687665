var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yxtbiz-select-catelog-source",
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "div",
        { staticStyle: { width: "200px" } },
        [
          _c("yxt-input", {
            attrs: {
              width: "200",
              placeholder: _vm.$t(
                "pc_biz_kng_lbl_search_category_name" /* 搜索分类名称 */
              ),
              maxlength: "20",
              size: "small",
              searchable: "",
            },
            on: { search: _vm.searchCatalog },
            model: {
              value: _vm.catalogName,
              callback: function ($$v) {
                _vm.catalogName = $$v
              },
              expression: "catalogName",
            },
          }),
        ],
        1
      ),
      _c("yxt-tree", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.treeLoading,
            expression: "treeLoading",
          },
        ],
        ref: "kngTree",
        staticClass: "select-kng-catalog__calc-height mt16",
        attrs: {
          "wrap-width": "613px",
          data: _vm.kngTreeData,
          props: _vm.defaultProps,
          "current-node-key": _vm.currentId,
          "node-key": "id",
          "filter-node-method": _vm.filterCatalogNode,
          "default-checked-keys": _vm.checkLists,
          "default-expanded-keys": _vm.expandCatalog,
          "check-strictly": _vm.checkStrictly,
          "highlight-current": !_vm.isNeedCheck,
          "show-checkbox": _vm.isNeedCheck,
        },
        on: {
          "node-click": _vm.nodeClick,
          "current-change": _vm.currentNodeChange,
          check: _vm.checkChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }