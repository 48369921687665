<template>
  <div class="yxtbiz-select__wrapper" v-clickOutside="cancel">
    <yxt-popover
      popper-class="select-popper"
      v-model="showPopper"
      :append-to-body="false"
      width="648px"
      placement="bottom-start"
      :visible-arrow="false"
      trigger="manual"
    >
      <div>
        <!-- <yxt-input
          class="search-input"
          v-bind="$attrs.searchInputOption"
          placeholder="请输入部门名称搜索"
          :size="size"
          v-model="filterText"
        >
        </yxt-input> -->
        <div class="tree-wrapper" v-if="showPopper">
          <slot></slot>
        </div>
      </div>
      <div
        v-if="showCustom"
        slot="reference"
        @click="show"
        class="search-input yxt-input yxt-input--suffix yxt-popover__reference"
      >
        <div
          class="yxt-input__inner yxtbiz-popover-dept"
          :class="{
            'yxtbiz-popover-placeholder': !$attrs.selectOpition.checkWord
          }"
        >
          <yxtbiz-dept-name
            v-if="$attrs.selectOpition.checkWord"
            :name="$attrs.selectOpition.checkWord"
          ></yxtbiz-dept-name
          ><span v-else>{{ $t('biz_udp_select_dept') }}</span>
        </div>
        <!-- <input type="text" readonly="readonly" autocomplete="off" placeholder="选择部门" checkword="2021测试部" > -->
        <span class="yxt-input__suffix">
          <span class="yxt-input__suffix-inner">
            <i
              class="yxt-input__icon"
              :class="{
                'yxt-icon-arrow-up': showPopper,
                'yxt-icon-arrow-down': !showPopper
              }"
            ></i>
          </span>
        </span>
      </div>
      <yxt-input
        v-else
        slot="reference"
        @click.native="show"
        class="search-input"
        :size="size"
        v-bind="$attrs.selectOpition"
        :suffix-icon="showPopper ? 'yxt-icon-arrow-up' : 'yxt-icon-arrow-down'"
        readonly
        v-model="$attrs.selectOpition.checkWord"
      >
        <template slot="suffix"></template>
      </yxt-input>
    </yxt-popover>
  </div>
</template>

<script>
import ClickOutside from 'yxt-biz-pc/src/utils/clickoutside';
import { Popover } from 'yxt-pc';
import openDataMixin from 'yxt-biz-pc/packages/user-selector/mixins/openData';

export default {
  inheritAttrs: false,
  mixins: [openDataMixin],
  props: {
    size: {
      type: String,
      default: ''
    },
    isDept: {
      type: Boolean,
      default: false
    }
  },
  components: {
    YxtPopover: Popover
  },
  data() {
    return {
      checkWord: '',
      filterText: '',
      showPopper: false
    };
  },
  computed: {
    showCustom() {
      return this.isOpenData && this.isDept;
    }
  },
  watch: {
    filterText(val) {
      try {
        const vm = this.$slots.default[0].children[0].componentInstance;
        vm.filter(val);
      } catch (error) {
        console.error(error);
      }
      // this.$refs.tree.filter(val);
    }
  },
  methods: {
    show() {
      this.showPopper = !this.showPopper;
    },
    cancel() {
      this.showPopper = false;
    }
  },
  directives: { ClickOutside }
};
</script>

<style lang="scss" scoped></style>
