import {
  qidaApi,
  sspApi,
  decorateApi,
  oteApi,
  o2oApi,
  enrollApi,
  kngApi,
  bsetApi,
  udpApi,
  supportApi,
  fileApi,
  noticeApi,
  auditCenterApi,
  miscApi,
  apiBaseUrl
} from 'yxt-biz-pc/packages/api';

import { productionUtil } from '../../../common-util/productionUtil';
let defaultProduct = productionUtil.getProductionInfo();
let defaultProductCode = defaultProduct.code || '';

const navRequest = {
  resolve: (config)=> {
    config.headers['x-yxt-product'] = window.goProduct || defaultProductCode;
    return config;
  },
  reject: error => {
    return Promise.reject('req', error);
  }
};

// 旧导航接口
export const GetNavBytoken = (type = 2) => {
  return qidaApi.get(`nav/user?type=${type}`);
};
// m2的新导航接口
export const GetNavBytokenV2 = (type = 2) => {
  window.goProduct && qidaApi.interceptors.request.use(navRequest.resolve, navRequest.reject);
  return qidaApi.get(`user/nav/self?type=${type}`);
};
// m2的应用下导航接口
export const GetAppNavBytoken = (type = 2) => {
  if (type !== 2) {
    return new window.Promise(function(reslove, reject) {
      reslove([]);
    });
  }
  return qidaApi.get('user/nav/app/self');
};

// 用户平台设置
export const setPlatform = (type = 2) => {
  return qidaApi.post(`userinfo/userChoosePlatform/${type}`);
};

export const GetUserInfo = () => {
  return qidaApi.get('frw/user/info');
};

// 获取机构信息
export const getOrgInfoByDomain = (params) => {
  return qidaApi.get('org/orgname', params);
};

// 个人学分及相关配置
export const GetScoreInfo = () => {
  return sspApi.get('/user/score/self');
};
// 个人积分及相关配置
export const GetPointInfo = () => {
  return sspApi.get('/user/point/self/today');
};

// 个人学分排行
export const GetScoreRank = () => {
  return sspApi.get('/score/top/user');
};
// 获取学院端导航配置
export const GetStuNavConfig = () => {
  return decorateApi.get('/rangemap/mobile?type=0');
};
// 获取是否有管理员权限
export const GetIsManage = () => {
  return qidaApi.get('/user/nav/manage');
};
// 获取是否有我的团队、教学管理、审核中心导航
export const GetUserNavBox = () => {
  return bsetApi.get('/usernavbox');
};
// 获取是否有保密协议
export const GetIsSecretprotocol = (orgId) => {
  return qidaApi.get(`/secretprotocol/${orgId}`);
};

// 获取工作台 作业批阅数量
export const GetWorkReviewCount = () => {
  return o2oApi.get('/review/count');
};
// 获取工作台 待批阅试卷数量
export const GetExamUnreadCount = () => {
  return oteApi.get('/external/mark/pendcount');
};

// 获取工作台 报名审核数量
export const GetCheckSignCount = () => {
  return enrollApi.get('/check/count');
};
// 获取工作台 知识审核数量
export const GetCheckKnowledgeCount = () => {
  return kngApi.get('/knowledge/audit/num');
};
// 获取工作台 待审核试题数量
export const GetQuesAuditCount = () => {
  return oteApi.get('/ques/list/audit/count');
};
// 获取工作台 待审核试卷数量
export const GetExamAuditCount = () => {
  return oteApi.get('/exam/list/audit/count');
};
// 获取页脚的版权信息
export const GetDecorateOrgConfig = () => {
  const orgId = window.localStorage.orgId || '';
  const domain = window.location.host;
  return decorateApi.get(`/orgconfig/support?orgId=${orgId}&domainName=${encodeURIComponent(domain)}`);
};

// 最新公告列表
export const getNotice = (data) => {
  if (data.noticeType === null) {
    return noticeApi.get(`notice/list?edition=${data.edition}&isManage=${data.isManage}`);
  } else {
    return noticeApi.get(`notice/list?type=${data.noticeType}&edition=${data.edition}&isManage=${data.isManage}`);
  }
};

// 设置语言
export const setLocale = (data) => {
  return udpApi.put('users/locale', data);
};

// 获取语言列表
export const getOrgInfoByOrgId = (orgId) => {
  return miscApi.get(`org/orgname?orgId=${orgId}`);
};
// 公告已读关闭
export const setReadedClosed = (data) => {
  return noticeApi.post('/notice/action', data);
};

// 公告关闭
export const setNoticeClosed = (data) => {
  return noticeApi.post('/notice/action', data);
};

// 获取发版公告关联详情
export const getNoticeRelationDetail = (id, fileType) => {
  return supportApi.get(`/third-api/upgrade/${id}?fileType=${fileType}`);
};

// 获取发版公告下载地址
export const getNoticeDownLoadUrl = (id) => {
  return fileApi.get('/download/saveas?fileId=' + id);
};

// 未读消息数量---管理端
export const getUnReadCnt = (id) => {
  return noticeApi.get('/notice/unreadcount');
};

// 审核中心数量---学员端
export const getAuditCount = () => {
  return auditCenterApi.get('/audit/getauditcount');
};

// 集团版兼职机构列表
export const getPermisisionOrgList = () => {
  return miscApi.get('/org/getGroupCorpUserParttimeOrgs');
};

// 集团版切换机构获取一次性授权码
export const getGroupLoginAuthCode = (data) => {
  return qidaApi.post('/generateGroupCode', data);
};

// 获取企业装修信息
export const getOrgDecorateInfo = () => {
  return qidaApi.get('org/orgs/decorate');
};

// 获取pc工作台
export const getWorkBenchList = () => {
  return qidaApi.get(`/user/nav/platform?orgId=${window.localStorage.orgId}&userId=ac0a267c-044e-4a28-b489-6be16e0ab788`);
};

// 获取第三方授权url----绚星学堂入口使用
export const getThirdAuthUrl = (data) => {
  return qidaApi.post('/auth/third/url', data);
};
/**
 * 获取配置的导航模板
 * @param {*} templateCode
 * @returns
 */
export const getNavTemplateByCode = (templateCode) => {
  return qidaApi.get('/navnew/nav/template', {
    params: {
      templateCode
    }
  });
};

// 获取机构参，可以根据orgId/domain查询，不需要进行token校验
export const getOrgParamsNoToken = (params) => {
  return miscApi.get('/orgparsetting/code/open', {
    params: {
      code: params.code,
      orgId: params.orgId,
      domain: params.domain
    }
  });
};

// 获取机构参，根据orgId和code查询
export const getOrgParams = (code, orgId) => {
  return miscApi.get(`/orgparsetting/code?code=${code}&orgId=${orgId}`);
};

/**
 * 获取当前用户的导航模板
 * @param {*} type 导航组类型(1：pc学员端，2：PC管理员，3：移动端)
 * @returns
 */
export const getNavVersion = (type) => {
  return qidaApi.get(`/user/nav/latest?type=${type}`);
};

// 获取当前机构的保密协议&隐私协议 开关（新）
export function GetIsSecretprotocolNew(orgId) {
  return qidaApi.get(`/secretprotocol/privacy/${orgId}`);
};

// 获取ai入口展示逻辑
export const getAiIconStatus = () =>{
  return apiBaseUrl.get('/aiassist/access/check');
};
