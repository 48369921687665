var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-check-single-position" }, [
    _c(
      "div",
      { staticClass: "table_tree" },
      [
        _vm.enableGroupCorp
          ? _c("yxtbiz-group-org-select", {
              staticClass: "mb12",
              attrs: { selectFirst: true, clearable: false },
              on: { change: _vm.handleOrgChange },
            })
          : _vm._e(),
        (_vm.enableGroupCorp && _vm.params.targetOrgId) || !_vm.enableGroupCorp
          ? _c("yxtbiz-pos-tree", {
              ref: "posTree",
              attrs: {
                enableGroupCorp: _vm.enableGroupCorp,
                visibleOrgSelector: false,
                "wrap-width": 260,
                targetOrgId: _vm.params.targetOrgId,
              },
              on: { nodeClick: _vm.nodeClick },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table_wrapper" },
      [
        _c("yxt-input", {
          staticClass: "keyword",
          staticStyle: { width: "240px" },
          attrs: {
            size: _vm.size,
            placeholder: _vm.$t("biz_udp_enter_positionname"),
            searchable: "",
          },
          on: {
            search: function ($event) {
              return _vm.getPositionData(true)
            },
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.getPositionData(true)
            },
          },
          model: {
            value: _vm.params.name,
            callback: function ($$v) {
              _vm.$set(_vm.params, "name", $$v)
            },
            expression: "params.name",
          },
        }),
        _c(
          "yxt-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "positionTable",
            staticClass: "pos_table",
            attrs: {
              "row-key": "id",
              data: _vm.tableData,
              height: _vm.tableHeight,
            },
            on: {
              select: _vm.handleSingleSeletct,
              "select-all": _vm.handleSelectAll,
            },
          },
          [
            _vm.multiple
              ? _c("yxt-table-column", {
                  attrs: {
                    "show-overflow-tooltip": false,
                    "class-name": "table-selection-odd",
                    type: "selection",
                    width: "30",
                  },
                })
              : _vm._e(),
            _c("yxt-table-column", {
              attrs: {
                prop: "name",
                "show-overflow-tooltip": "",
                label: _vm.$t("biz_udp_pos_name"),
                "min-width": "180",
              },
            }),
            _c("yxt-table-column", {
              attrs: {
                prop: "gradeList",
                "show-overflow-tooltip": "",
                label: _vm.$t("biz_udp_occupation_level"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        _vm._s(
                          (scope.row.gradeList || [])
                            .map((item) => item.name)
                            .join() || "--"
                        )
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("yxt-table-column", {
              attrs: {
                "show-overflow-tooltip": "",
                prop: "catalogName",
                label: _vm.$t("biz_udp_pos_category"),
                "min-width": "180",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.catalogName || "--") +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            !_vm.multiple
              ? _c("yxt-table-column", {
                  attrs: {
                    label: _vm.$t("pc_biz_te_lbl_operation"),
                    width: "108",
                    align: "right",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "yxt-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.choose(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.id ===
                                      (_vm.checkedPositionsTemp.length
                                        ? _vm.checkedPositionsTemp[0].id
                                        : "")
                                      ? _vm.$t("biz_udp_cancel_selected")
                                      : _vm.$t("pc_biz_te_btn_select")
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    626673154
                  ),
                })
              : _vm._e(),
            _c("template", { slot: "empty" }, [
              _c(
                "div",
                { staticStyle: { "line-height": "normal" } },
                [_c("yxt-empty")],
                1
              ),
            ]),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "yxtbiz-user-selector-mask clearfix" },
          [
            _c("yxt-pagination", {
              staticClass: "pull-right mt16",
              attrs: {
                "page-size": _vm.params.limit,
                "current-page": _vm.currentPage,
                layout: "total, prev, pager, next",
                "simple-total": "",
                total: _vm.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "update:currentPage": function ($event) {
                  _vm.currentPage = $event
                },
                "update:current-page": function ($event) {
                  _vm.currentPage = $event
                },
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }