<template>
  <div class="yxtbiz-survey-template">
    <div class="yxtbiz-survey-template__operation"  v-if="!customizedHeader">
      <div>
        <!-- 新建模板 -->
        <yxt-button v-if="!disabledCreate && showCreate && !surveyCenterStatus.hidden"
                    :disabled="surveyCenterStatus.disabled"
                    type="primary"
                    @click="createClick">
          {{ $t('pc_biz_survey_btn_newtemplate') }}
        </yxt-button>
      </div>
      <div>
        <!-- 筛选类型 -->
        <yxt-select
          v-if="isShowSelect"
          v-model="query.type"
          size="small"
          class="width160"
          clearable
          @change="changeType"
        >
          <yxt-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </yxt-select>
        <!-- 模板/创建人搜索 -->
        <yxt-input
          v-model="query.name"
          size="small"
          :placeholder="$t('pc_biz_survey_lbl_template_creator_search' /* 请输入模板名称/创建人搜索 */)"
          searchable
          clearable
          class=""
          :class="`yxtbiz-survey-template__keywords${$isChinese ? '' : '-en'} ml8`"
          @search="getData(true)"
          @clear="getData(true)"
        />
      </div>
    </div>
    <div class="flex justify-between" v-else>
      <div>
        <yxt-button v-if="!surveyManageStatus.hidden" @disabled="surveyManageStatus.disabled" @click="createO2oTemplate" class="mr12">{{ $t('pc_ulcd_manage_template') }}</yxt-button>
        <yxt-input v-model="query.name" size="small" searchable @search="getData(true)"
        class="yxtbiz-survey-template__keywords" :placeholder="$t('pc_biz_survey_lbl_template_creator_search' /* 请输入模板名称/创建人搜索 */)">
      </yxt-input>
      </div>
      <yxt-button type="text" @click="query.name='';getData(true)"><div class="flex align-items-center"><i class="yxt-icon-refresh p1 mr3"></i>{{ $t('pc_ulcd_refresh_template') }}</div></yxt-button>
    </div>
    <yxt-table :data="list" class="yxtbiz-survey-template__table mt16" v-loading="loading" :default-sort="defaultSort" @sort-change="sortChange">
      <yxt-table-column :label="$t('pc_biz_survey_lbl_name')" prop="name" min-width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="yxtbiz-survey-template__name color-primary-6" @click="nameClick(scope.row.id)">{{scope.row.name}}</div>
        </template>
      </yxt-table-column>
      <yxt-table-column :label="$t('pc_biz_survey_lbl_type')" prop="type" :formatter="typeFormatter" width="150"></yxt-table-column>
      <yxt-table-column :label="$t('pc_biz_lbl_creator' /* 创建人 */)" prop="createFullname" min-width="90" show-overflow-tooltip :popover-html="true">
        <template slot-scope="scope">
          <yxtbiz-user-name :name="scope.row.createFullname" />
        </template>
      </yxt-table-column>
      <yxt-table-column :label="$t('pc_biz_survey_lbl_createtime')" prop="createTime" :formatter="timeFormatter" sortable="custom" :sort-orders="['asc', 'desc']" width="166"></yxt-table-column>
      <yxt-table-column :label="$t('pc_biz_survey_lbl_oprate')" width="100">
        <template slot-scope="scope">
          <template v-if="scope.row.prjCorrect === 1">
            <yxt-button v-if="scope.row.id === selected" type="text" @click="templateSelect(null)">{{ $t('pc_biz_survey_btn_cancleselect') }}</yxt-button>
            <yxt-button v-else type="text" @click="templateSelect(scope.row)">{{ $t('pc_biz_survey_btn_select') }}</yxt-button>
          </template>
          <yxt-tooltip v-else effect="dark" :content="$t('pc_biz_survey_tip_emptytemplate')" placement="top">
            <span><yxt-button type="text" disabled>{{ $t('pc_biz_survey_btn_select') }}</yxt-button></span>
          </yxt-tooltip>
        </template>
      </yxt-table-column>
    </yxt-table>
    <yxt-pagination class="mt16 text-right" v-show="total > 0" :current-page.sync="query.current" :total="total" :page-size="query.limit"
      @current-change="getData" layout="total, prev, pager, next"></yxt-pagination>
  </div>
</template>

<script>
import { getTemplates, getPreviewUrl, getCreateUrl, getCatalogSelefList } from './service';
import dateUtil from 'yxt-biz-pc/src/utils/date';
import commonUtil from '../../common-util';
import { Table, TableColumn, Pagination, Tooltip } from 'yxt-pc';
import { getFunStatus } from '../../common-util/factorUtils';
import { enmuFunctions } from '../../function-points';

const types = [0, 1, 2, 5, 6]; // 模板类型，0：问卷调查（T01）、1：评价表单（T02)、2：投票评选（T03）、5：鉴定表判断题  6：鉴定表滑动打分题

export default {
  name: 'YxtbizSurveyTemplate',
  components: {
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtTooltip: Tooltip
  },
  props: {
    customizedHeader: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      validator: (value) => {
        return value === null || types.indexOf(value) > -1;
      }
    },
    catalogId: String,
    disabledCreate: Boolean,
    selected: String,
    functionCode: String,
    dataPermissionCode: String,
    isShowSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const defaultSort = {
      prop: 'createTime',
      order: 'desc'
    };
    return {
      defaultSort,
      query: {
        name: '',
        limit: 10,
        current: 1,
        orderby: defaultSort.prop,
        direction: defaultSort.order,
        navCode: this.functionCode,
        dataPermissionCode: this.dataPermissionCode
      },
      list: [],
      total: 0,
      loading: false,
      showCreate: commonUtil.checkActionPermission('survey_template', 'investigate_moudle_add', this), // 新建模板 权限
      typeList: [
        { value: 0, label: this.$t('pc_survey_lbl_questionnaire' /* 问卷调查 */) },
        { value: 1, label: this.$t('pc_survey_lbl_template_evaluation' /* 评价、满意度调查 */) },
        { value: 2, label: this.$t('pc_survey_lbl_voteselect' /* 投票评选 */) },
        { value: 3, label: this.$t('pc_survey_lbl_sign' /* 报名登记 */) },
        { value: 5, label: this.$t('pc_biz_survey_lbl_appraisal_judge' /* 判断鉴定表 */) },
        { value: 6, label: this.$t('pc_biz_survey_lbl_appraisal_score' /* 打分鉴定表 */) }
      ]
    };
  },
  computed: {
    surveyCenterStatus() {
      return getFunStatus(enmuFunctions.SURVEY_EDIT);
    },
    surveyManageStatus() {
      return getFunStatus(enmuFunctions.SURVEY_TEMPLATE_MANAGE);
    }
  },
  created() {
    this.query.status = 2;
  },
  mounted() {
    this.getTypeList();
    this.getData();
  },
  methods: {
    createO2oTemplate() {
      window.open('/o2o/#/templatedepository/identify');
    },

    // 获取所有类型
    getTypeList() {
      getCatalogSelefList().then(res => {
        if (res && res.data && res.data.length) {
          res.data.forEach(list => {
            this.typeList.push({
              value: list.cateIdentifyId,
              label: list.name
            });
          });
        }

        console.log('ote', JSON.parse(JSON.stringify(this.typeList)));
      }).catch(err => { console.log(err); });
    },

    getData(goFirst) {
      goFirst === true && (this.query.current = 1); // current-change事件也绑定了getData，所以判断参数等于true
      this.loading = true;

      let typeObj = {};
      // 如果有分类id，优先使用分类id；否则使用type
      if (this.catalogId) {
        this.query.cateIdentifyId = this.catalogId;
      } else {
        // 0 和 空值都有意义，传递给接口处理成undefined
        const { type } = this.query;
        this.query.type = [0, ''].includes(type) ? type : (type || this.type);

        if (this.query.type === '') {
          typeObj = {
            cateIdentifyId: undefined,
            type: undefined
          };
        } else {
          if (!types.includes(this.query.type)) {
            typeObj = {
              cateIdentifyId: this.query.type,
              type: undefined
            };
          }
        }
      }
      getTemplates({ ...this.query, ...typeObj }).then(res => {
        this.loading = false;
        this.list = res.datas;
        this.total = ~~res.paging.count;
      }).catch(() => {
        this.loading = false;
      });
    },
    sortChange({ prop, order }) {
      this.query.orderby = prop;
      this.query.direction = order;
      this.getData(true);
    },
    typeFormatter(row, column, cellValue, index) {
      if (row.categoryName) {
        return row.categoryName;
      }
      switch (cellValue) {
        case 0:
          return this.$t('pc_biz_survey_lbl_typename0');
        case 1:
          return this.$t('pc_biz_survey_lbl_typename1');
        case 2:
          return this.$t('pc_biz_survey_lbl_typename2');
        case 3:
          return this.$t('pc_biz_survey_lbl_typename3');
      }
      return '--';
    },
    timeFormatter(row, column, cellValue, index) {
      if (!cellValue) {
        return '--';
      }
      if (typeof cellValue === 'string') {
        cellValue = cellValue.replace(/-/g, '/');
      }
      return dateUtil.format(new Date(cellValue), 'yyyy-MM-dd HH:mm');
    },
    nameClick(templateId) {
      getPreviewUrl(templateId).then(res => {
        window.open(res.data);
      });
    },
    createClick() {
      // 这里如果指定了调查类型则直接创建对应的调查。未指定时由于暂时没有选择的中间页，先往问卷模板管理列表页跳
      if (this.type || this.type === 0) {
        getCreateUrl(types).then(res => {
          window.open(res.data);
        });
      } else {
        window.open('/survey/#/template?fc=1');
      }
    },
    templateSelect(row) {
      this.$emit('select', row ? { ...row } : null);
    },

    changeType(val) {
      this.getData(true);
    }
  }
};
</script>
