import { envNow } from 'yxt-biz-pc/packages/api';
import { i18n } from 'yxt-i18n/es';

// 去除公共的数组里的重复的对象
export function publicRemoveRepeat(selection) {
  let result = [];
  let obj = {};
  // 进行数组里对象的去重
  for (let i = 0; i < selection.length; i++) {
    if (!obj[selection[i].id]) {
      result.push(selection[i]);
      obj[selection[i].id] = true;
    }
  }
  return result;
}

// 拼接URL参数
export const linkSubString = (url, data) => {
  if (url === null || url === '') {
    return url;
  }
  let queryString = '';
  if (typeof data === 'object') {
    for (var i in data) {
      queryString += i + '=' + data[i] + '&';
    }
  }
  if (url.indexOf('?') > url.indexOf('/')) {
    url += '&';
  } else {
    url += '?';
  }
  if (queryString !== '') {
    queryString = queryString.substr(0, queryString.length - 1);
  }
  url += queryString;
  return url;
};

export const comSecond = (time) => {
  if (!time) return i18n.t('pc_biz_knglib_lbl_timespan1', [0]); // 0秒
  let t = parseInt(time, 10);
  let d = Math.floor(t / (24 * 60 * 60));
  let h = Math.floor((t - (d * 24 * 60 * 60)) / (60 * 60));
  let m = Math.floor((t - (d * 24 * 60 * 60) - (h * 60 * 60)) / 60);
  let s = t - (d * 24 * 60 * 60) - (h * 60 * 60) - m * 60;

  if (d > 0) {
    return i18n.t('pc_biz_knglib_lbl_timespan4', [d, h, m, s]); // {0}天{1}时{2}分{3}秒
  }
  if (h > 0) {
    return i18n.t('pc_biz_knglib_lbl_timespan3', [h, m, s]); // {0}时{1}分{2}秒
  }
  if (m > 0) {
    return i18n.t('pc_biz_knglib_lbl_timespan2', [m, s]); // {0}分{1}秒
  }
  return i18n.t('pc_biz_knglib_lbl_timespan1', [s]); // {0}秒
};

export const getMinuteSecondFormat = (seconds) => {
  let h = Math.floor(seconds / 60) || '0';
  let s = seconds - (h * 60) || '0';
  let kngStandardHour = seconds ? h.toString() : '0';
  let kngStandardSecond = seconds ? s.toString() : '0';
  return i18n.t('pc_biz_knglib_lbl_timespan2', [kngStandardHour, kngStandardSecond]); // {0}分{1}秒
};

export const isUndefined = (val) => {
  return val === undefined ? '' : val;
};

export const getKngType = (file) => {
  let iconClass;
  switch (file.fileType) {
    case 'doc':
      switch (file.fileClass) {
        case 'word':
        case 'excel':
        case 'pdf':
        case 'ppt':
          iconClass = file.fileClass;
          break;
      }
      break;
    case 'image':
    case 'video':
    case 'audio':
    case 'zip':
      iconClass = file.fileType;
      break;
    default:
      iconClass = 'unknow';
      break;
  };
  return 'kng_' + iconClass;
};

// 处理剩余时间
export const restUploadTime = (value) => {
  let secondTime = parseInt(value, 10); // 秒
  let minuteTime = 0; // 分
  let hourTime = 0; // 小时
  if (secondTime > 60) { // 如果秒数大于60，将秒数转换成整数
    // 获取分钟，除以60取整数，得到整数分钟
    minuteTime = parseInt(secondTime / 60, 10);
    // 获取秒数，秒数取佘，得到整数秒数
    secondTime = parseInt(secondTime % 60, 10);
    // 如果分钟大于60，将分钟转换成小时
    if (minuteTime > 60) {
      // 获取小时，获取分钟除以60，得到整数小时
      hourTime = parseInt(minuteTime / 60, 10);
      // 获取小时后取佘的分，获取分钟除以60取佘的分
      minuteTime = parseInt(minuteTime % 60, 10);
    }
  }
  let result;
  result = '00:00:' + getValue(parseInt(secondTime, 10));
  if (minuteTime > 0) {
    result = '00:' + getValue(parseInt(minuteTime, 10)) + ':' + getValue(parseInt(secondTime, 10));
  }
  if (hourTime > 0) {
    result = getValue(parseInt(hourTime, 10)) + ':' + getValue(parseInt(minuteTime, 10)) + ':' + getValue(parseInt(secondTime, 10));
  }
  return result;
};

function getValue(value) {
  return value >= 10 ? value : ('0' + value);
};

export const handleFileSize = (value) => {
  if (value === null || value === '') {
    return '0 Bytes';
  }
  let unitArr = [' Bytes', ' KB', ' MB', ' GB', ' TB'];
  let index = 0;
  let srcsize = parseFloat(value);
  index = Math.floor(Math.log(srcsize) / Math.log(1024));
  var size = srcsize / Math.pow(1024, index);
  size = size.toFixed(2); // 保留的小数位数
  return [size + unitArr[index], size, unitArr[index]];
};

// 获取封面类型
export const getCoverImgType = (coverObj) => {
  let type = '';
  switch (coverObj.fileType) {
    case 'image':
    case 'audio':
    case 'video':
    case 'scorm':
    case 'ebook':
    case 'xuanyes':
    case 'course':
    case 'excel':
    case 'pdf':
    case 'ppt':
    case 'word':
      type = coverObj.fileType;
      break;
    case 'img':
      type = 'image';
      break;
    case 'zip':
      type = 'sourcecode';
      break;
    case 'doc':
      switch (coverObj.fileClass) {
        case 'excel':
        case 'pdf':
        case 'ppt':
        case 'word':
          type = coverObj.fileClass;
          break;
      }
      break;
    default:
      type = 'ukn';
      break;
  }
  return type;
};

function getFullUrl() {
  if (envNow === 'dev' || envNow === 'feature') {
    return 'https://streamobd-test.yunxuetang.cn';
  }
  return 'https://streamobs.yunxuetang.cn';
}

export const getDefaultKngTypeCover = (type) => {
  let imgUrl;
  let url = getFullUrl() + '/media/kngdefaultimg/zh/';

  switch (type) {
    case 'audio':
      imgUrl = url + 'audio.png';
      break;
    case 'course':
      imgUrl = url + 'course.png';
      break;
    case 'ebook':
      imgUrl = url + 'ebook.png';
      break;
    case 'excel':
      imgUrl = url + 'excel.png';
      break;
    case 'image':
      imgUrl = url + 'image.png';
      break;
    case 'pdf':
      imgUrl = url + 'pdf.png';
      break;
    case 'ppt':
      imgUrl = url + 'ppt.png';
      break;
    case 'scorm':
      imgUrl = url + 'scorm.png';
      break;
    case 'video':
      imgUrl = url + 'video.png';
      break;
    case 'xuanyes':
      imgUrl = url + 'weike.png';
      break;
    case 'word':
      imgUrl = url + 'word.png';
      break;
    case 'smart':
      imgUrl = url + 'smart.png';
      break;
    case 'exam':
      imgUrl = url + 'exam.png';
      break;
    case 'newlink':
      imgUrl = url + 'newlink.png';
      break;
    default:
      imgUrl = url + 'ukn.png';
      break;
  };
  return imgUrl;
};

export const comType = (type) => {
  let cType = -1;
  switch (type) {
    case 'course':
      cType = 0;
      break;
    case 'image':
    case 'doc':
      cType = 1;
      break;
    case 'video':
      cType = 2;
      break;
    case 'audio':
      cType = 3;
      break;
    case 'weike':
      cType = 4;
      break;
    case 'scorm':
      cType = 5;
      break;
    case 'html':
      cType = 6;
      break;
    case 'zip':
      cType = 7;
      break;
    default:
      cType = -1;
      break;
  }
  return cType;
};

export const comFileType = (file) => {
  let type = '';
  switch (file.fileType) {
    case 'image':
      type = 'img';
      break;
    case 'video':
    case 'audio':
    case 'zip':
      type = file.fileType;
      break;
    case 'doc':
      type = file.fileClass;
      break;
  }
  return type;
};

// 根据configKey选择重新上传的size&type
export const getSizeType = (type) => {
  let size = 0;
  let configType = [];
  let eType = parseInt(type, 10);
  // 1-文档、2-视频、3-音频
  switch (eType) {
    case 1:
      size = 10 * 1024 * 1024;
      configType = ['.jpg', '.jpeg', '.gif', '.png', '.bmp', '.ico', '.xls', '.xlsx', '.pdf', '.ppt', '.pptx', '.pps', '.doc', '.docx'];
      break;
    case 2:
      size = 1024 * 1024 * 1024;
      configType = ['.wmv', '.mp4', '.flv', '.avi', '.rmvb', '.mpg', '.mkv', '.mov'];
      break;
    case 3:
      size = 200 * 1024 * 1024;
      configType = ['.w4v', '.m4a', '.wma', '.wav', '.mp3', '.amr'];
      break;
  }
  return [size, configType];
};
