
import DeptTreeV1 from './main.vue';
import DeptTreeV2 from 'yxt-biz-pc/packages/dept-tree-v2/index';
import { getOrgpParameter } from './service';

let DeptTree = async function(resolve) {
  try {
    const res = await getOrgpParameter();
    if (res && res.value === '1') {
      resolve(DeptTreeV2);
    } else {
      resolve(DeptTreeV1);
    }
  } catch (error) {
    resolve(DeptTreeV1);
  }
};

export default {
  name: 'YxtbizDeptTree',
  functional: true,
  render(h, context) {
    if (context.props.version === 'v1') {
      DeptTree = DeptTreeV1;
    }
    return h(DeptTree, { ...context.data });
  }
};
