import { kngApi } from 'yxt-biz-pc/packages/api';
import * as util from './utils';

export default {
  getKngTree(orgId, bodyParams) {
    // return kngApi.post('kngCatalog/treeCache', bodyParams);
    return kngApi.post(`classificate/share/list/${orgId}`, bodyParams);
  },
  getkngListsSource(linkParams, bodyParams) {
    return kngApi.post(util.linkSubString('knowledge/share/all', linkParams), bodyParams);
  }
};
