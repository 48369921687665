import { cerApi } from 'yxt-biz-pc/packages/api';

// 拼接URL参数
const linkSubString = (url, data) => {
  if (url === null || url === '') {
    return url;
  }
  let queryString = '';
  if (typeof data === 'object') {
    for (var i in data) {
      queryString += i + '=' + data[i] + '&';
    }
  }
  if (url.indexOf('?') > url.indexOf('/')) {
    url += '&';
  } else {
    url += '?';
  }
  if (queryString !== '') {
    queryString = queryString.substr(0, queryString.length - 1);
  }
  url += queryString;
  return url;
};

// 获取证书选择列表
export const getCerList = (query, data) => {
  return cerApi.post(linkSubString('/cer/temp/list/select', query), data);
};

// 获取证书分类
export const getCatalogList = (query) => {
  return cerApi.get('/catalog/list', { params: query });
};

// 获取目录列表
export const getCatalogListOG = () => {
  return cerApi.get('/catalog/share/list');
};

// 获取模板证书选择列表
export const getCerListOG = (query) => {
  return cerApi.get('/cer/share/list', { params: query });
};
