/**
 * axios 请求异常信息上报
 * @param {AxiosError} error 响应拦截器捕获的异常
 */
const axiosErrorReport = (error) => {
  try {
    if (window.yxtRPT && window.yxtRPT.report) {
      const { config = {}, request = {}, response = {}, message, isAxiosError } = error || {};
      window.yxtRPT.report({
        category: 499,
        msg: message || 'Axios Error',
        c1: JSON.stringify({
          isAxiosError,
          url: config.url,
          method: config.method,
          status: request.status,
          baseURL: config.baseURL,
          params: config.params,
          data: config.data,
          result: response.data
        })
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export default axiosErrorReport;
