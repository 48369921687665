import { enmuFunctions } from '../function-points/index';
import { checkTimeOutFnc } from './getFactor';
import { udpApi } from 'yxt-biz-pc/packages/api';
// 是否增购要素,解决循环依赖，单独声明此方法
const isFunPointHide = point => {
  // 无 point code 不隐藏元素
  if (!point) return false;
  // 没有匹配状态 hide
  return [undefined, null, 0, 1, 3, 5].includes(checkTimeOutFnc(point));
};
const codeMap = {
  xxv2: 'xxv2',
  sp: 'sp'
};
const baseInfo = {
  path: '',
  code: '',
  name: '',
  allPath: ''
};

const productionUtilFun = () => {
  const productionUtil = {
    allCodeMap: codeMap,
    /**
     * 全量产品列表
     */
    getAllProductList: () => {
      const allProductList = [
        {
          path: '',
          code: codeMap.xxv2,
          name: '绚星云学习',
          langkey: 'pc_biz_nav_xxv2'
        },
        {
          path: 'sp',
          code: codeMap.sp,
          name: '人才发展',
          langkey: 'pc_biz_nav_telent'
        }
      ];
      return allProductList;
    },
    /**
     * 要素code和产品的映射关系
     */
    factoryMap: {
      xxv2: enmuFunctions.XXV2MGMT, // 绚星云学习
      sp: enmuFunctions.TALENTMANAGEMENT // 人才发展
    },
    /**
     * 获取当前机构产品列表,增购哪些要素显示哪些
     * @returns []
     */
    getOrgProductList: () => {
      const allList = productionUtil.getAllProductList();
      const factoryMap = productionUtil.factoryMap;
      const list = [];
      console.log(allList);
      allList.forEach(item => {
        console.log(!isFunPointHide(factoryMap[item.code]));
        if (!isFunPointHide(factoryMap[item.code])) {
          list.push(item);
        }
      });
      console.log(list);
      return list;
    },
    /**
     * 获取当前用户的产品列表，用于导航入口展示等
     * @param {*} code
     * @returns []
     */
    getUserProductList: () => {
      const orgProductList = productionUtil.getOrgProductList();
      // 兼容product取不到的情况，默认绚星
      const codes = localStorage.getItem('productCodes') || codeMap.xxv2;
      const userProductCodes = codes.includes('[') ? JSON.parse(codes) : codes.split(',');
      const list = [];
      userProductCodes.forEach(code => {
        const findItem = orgProductList.find(item => item.code === code);
        if (findItem) {
          findItem.allPath = findItem.path
            ? `${location.origin}/${findItem.path}`
            : `${location.origin}`;
          list.push(findItem);
        }
      });
      console.log(list);
      return list;
    },
    /**
     * 获取产品信息，1.不传，默认返回当前平台产品信息 2.无效code，返回当前平台信息
     * @param  {...any} args  产品code,eg:getProductionInfo('xuanxing')
     * @returns {code:'',path:'',allPath:''} code:header的产品code path: 页面跳转的路径 allPath:完整的页面路径
     */
    getProductionInfo: code => {
      const productList = productionUtil.getAllProductList();
      let info;
      if (!code) {
        // 无效code根据当前平台,通过pathname获取
        info = productList.find(item =>
          location.pathname.startsWith(`/${item.path}/`)
        );
        if (!info) {
          info = productList.find(item => item.code === codeMap.xxv2);
        }
      } else {
        info = productList.find(item => item.code === code);
      }
      if (info) {
        info.allPath = info.path
          ? `${location.origin}/${info.path}`
          : `${location.origin}`;
      }
      return info || baseInfo;
    },
    /**
     * 当前平台是否是某个产品,根据path来判断
     * @param {*} code
     * @returns
     */
    isOneProduct: code => {
      const list = productionUtil.getAllProductList();
      if (!code) return false;
      if (code === codeMap.xxv2) {
        // 绚星
        const findItem = list.find(item => {
          if (item.path) {
            return location.pathname.includes(`/${item.path}/`);
          }
          return false;
        });
        return !findItem;
      } else {
        // 非绚星
        const findItem = list.find(item => item.code === code);
        return findItem ? location.pathname.includes(`/${findItem.path}/`) : false;
      }
    },
    /**
     * 更新用户产品版本
     */
    updateUserProduct: async() => {
      try {
        const res = await udpApi.get('users/product/code');
        const productCodes = (res && res.datas) || [];
        localStorage.setItem('productCodes', productCodes);
      } catch (err) {
        console.log(err);
      }
    }
  };
  return productionUtil;
};

export const productionUtil = productionUtilFun();
