<template>
  <div id="trainCatalogSelector">
    <yxt-popover
      v-model="selecting"
      placement="bottom-start"
      :popper-class="'yxtbiz-pop-cata-selector'"
      :width="width"
      trigger="click"
      :disabled="disabled"
      :visible-arrow="false"
      @show="iconChange = false"
      @hide="iconChange = true"
    >
      <div>
        <yxt-input
          v-model="filterText"
          :placeholder="
            searchToolTip || $t('pc_biz_ote_tip_catalogsearch')
          "
          :disabled="disabled"
          :size="size"
          searchable
          class="mb16"
        >
        </yxt-input>
        <div class="height300">
          <div class="yxtbiz-pop-cata-selector__tree-scroll">
            <yxt-tree
              ref="tree"
              refs="tree"
              :fitHeight="true"
              class="filter-tree"
              :wrap-width="width"
              :data="data"
              :props="defaultProps"
              :filter-node-method="filterNode"
              node-key="id"
              :current-node-key="catalogIdselected"
              :default-expanded-keys="[catalogIdselected]"
              @node-click="selectCatalog"
            >
            </yxt-tree>
          </div>
        </div>
      </div>
      <span slot="reference" class="text-left font-size-12 hand">
        <yxt-input
          :disabled="disabled"
          class="hand ote-input-hand"
          :placeholder="$t(dropToolTip)"
          :suffix-icon="
            iconChange == true ? 'yxt-icon-arrow-down' : 'yxt-icon-arrow-up'
          "
          :value="catalogNameSelected || catalogName"
          readonly
          :size="size"
          type="text"
        >
        </yxt-input>
      </span>
    </yxt-popover>
  </div>
</template>

<script>
import { scrollbarWidth } from 'yxt-pc';
import rootCatalog from './rootCatalog';
export default {
  data() {
    return {
      filterText: '',
      selecting: false,
      iconChange: true,
      catalogNameSelected: '',
      catalogIdselected: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  mixins: [rootCatalog],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    // 当前分类ID
    catalogId: {
      type: String,
      default: ''
    },
    // 当前分类名
    catalogName: {
      type: String,
      default: ''
    },
    // 分类的数据源
    data: {
      type: Array,
      default: () => []
    },
    // 搜索站位提示
    searchToolTip: {
      type: String,
      default: ''
    },
    // 下拉框提示
    dropToolTip: {
      type: String,
      default: 'pc_biz_ote_lbl_pleaseselect'
    },
    // 宽度
    width: {
      type: [Number, String],
      default: 468
    },
    type: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: 'medium'
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    catalogId: {
      handler(val, old) {
        if (val !== old) {
          this.catalogIdselected = this.catalogId || this.ROOT_CATALOG_ID;
          this.catalogNameSelected = this.catalogName;
        }
      },
      immediate: true
    }
  },
  computed: {
    gutter() {
      return scrollbarWidth();
    },
    scrollWrapHeight() {
      return `calc(100% + ${this.gutter}px)`;
    }
  },
  created() {
    this.setCatalogType(this.type);
  },
  methods: {
    filterNode(value, data) {
      return !value || (data.label || '').indexOf(value) !== -1;
    },
    selectCatalog(cata) {
      if (this.disabled) {
        return;
      }
      this.$emit('change', cata);
      this.$emit('update:catalogId', cata.id);
      this.$emit('update:catalogName', cata.name);
      this.catalogNameSelected = cata.label;
      this.selecting = false;
    }
  },
  mounted() {
    if (this.$refs.tree) {
      this.$refs.tree.$el.querySelector('.yxt-scrollbar__wrap').style.height =
        this.scrollWrapHeight;
    }
  }
};
</script>
