import {
  Tree
} from 'yxt-pc';
import TreeSlot from './tree-slot.vue';

const renderTreeSlot = (h, props, context) => {
  return h(
    TreeSlot,
    {
      props: {
        ...props,
        functionsRender: context.props.functionsRender,
        functions: context.props.functions,
        dropdownToBody: context.props.dropdownToBody
      },
      scopedSlots: context.data.scopedSlots
    },
  );
};

export default {
  name: 'YxtbizBaseTree',
  functional: true,
  props: {
    functions: {
      // 是否展示右侧功能按钮
      default: true,
      type: Boolean
    },
    functionsRender: {
      // 功能操作项
      type: Function,
      default: () => {
        return [];
      }
    },
    dropdownToBody: {
      type: Boolean,
      default: true
    }
  },
  render(h, context) {
    return h(Tree, {
      ...context.data,
      scopedSlots: {
        default: (props) => h(
          'span',
          {
            class: ['yxtbiz-base-tree-node']
          },
          [renderTreeSlot(h, props, context)]
        )
      }
    }, context.children);
  }
};
