<template>
  <div class="yxtbiz-teacher-selector">
    <yxt-tabs
      v-if="setTabs.length > 1"
      @tab-click='tabClick'
      v-model='activeName'
      :drawer-top='drawerTop'
    >
      <yxt-tab-pane
        :key='index'
        v-for='(tab, index) in setTabs'
        :name='tab.value'
      >
        <span slot='label'>{{ tab.label }}</span>
      </yxt-tab-pane>
    </yxt-tabs>
    <yxt-single-user
      class='yxtbiz-teacher-selector__user'
      v-model='user'
      v-if='isSelectUser && singleMode'
      :dataPermissionCode='selectUserPermissionCode'
      :functionCode='functionCode'
    />
    <yxt-user-selector
      class='yxtbiz-teacher-selector__user'
      v-if='isSelectUser && !singleMode'
      :limit='500'
      :data='users'
      :tabs="['persons']"
      @change='changeFn'
      :functionCode='functionCode'
      :dataPermissionCode='selectUserPermissionCode'
    ></yxt-user-selector>
    <div class="yxtbiz-teacher-container font-size-14" v-if="!isSelectUser">
      <!-- 选择平台机构 -->
      <div class="yxtbiz-teacher-container__flex">
        <select-popover
          class="mr12"
          style="width: 144px"
          ref="selectPopover"
          is-dept
          :selectOpition="selectOpition"
        >
          <dept-tree
            :functionCode="functionCode"
            :dataPermissionCode="dataPermissionCode"
            :filterable="true"
            :visibleOrgSelector="false"
            :targetOrgId="teSearch.orgId"
            @nodeClick="nodeClick"
          ></dept-tree>
        </select-popover>
        <!-- 选择岗位 -->
        <yxt-select
          class='mr12'
          v-model="posVal"
          :placeholder="$t('biz_udp_no_limit_pos')"
          icon-name="more"
          hidden-option
          collapse-tags
          value-key="key"
          prop-label="value"
          @visible-change="visiblePos=true"
        >
          <template slot='tag' slot-scope='{ data }'>
            <span>{{ data.currentLabel }}</span>
          </template>
        </yxt-select>
        <yxt-drawer :title="$t('pc_biz_udp_tit_selectPotision')" :visible.sync="visiblePos" size="960px">
          <check-single-position ref="refPosition" :checkedPositions.sync="checkedPositions" @choose='handlePositionChoose'/>
        </yxt-drawer>
        <!-- 请输入姓名/账号搜索 -->
        <yxt-input
          style="width: 183px"
          :placeholder="$t('pc_biz_te_tip_enternameaccountsearch')"
          v-model="teSearch.keyword"
          searchable
          maxlength="50"
          @search="teNameSearch"
        >
        </yxt-input>
      </div>
    </div>
    <div class="yxtbiz-teacher-selector__main mt16 mb16" v-if="!isSelectUser">
      <yxt-table
        ref="stb"
        class="yxtbiz-teacher-selector__table"
        @select-all="selectAll"
        @select="select"
        v-loading="loading"
        :key="`t${renderCount}`"
        :data="teaList"
        :height="tableHeight"
        :default-sort="{ prop: 'updateTime', order: 'desc' }"
        @sort-change="sortChange"
      >
        <yxt-table-column
          v-if="!isSingle"
          :selectable="selectable"
          :show-overflow-tooltip="false"
          class-name="yxtbiz-table-selection"
          type="selection"
          width="40"
        ></yxt-table-column>
        <!-- 讲师姓名/账号 -->
        <yxt-table-column
          :label="$t('pc_biz_te_lbl_tutornameaccount')"
          :show-overflow-tooltip='false'
        >
          <template slot-scope="scope">
            <div class="yxtbiz-teacher-flex-center">
              <yxt-tooltip effect="dark" placement="top" :open-filter="true">
                <div slot="content">
                  <span
                  ><yxtbiz-user-name :name="scope.row.fullname" />{{
                      (scope.row.username && scope.row.username != '') ? `（${scope.row.username}）` : ''
                    }}</span
                  >
                </div>
                <div class="ellipsis nowrap">
                  <span
                  ><yxtbiz-user-name :name="scope.row.fullname" />{{
                      (scope.row.username && scope.row.username != '') ? `（${scope.row.username}）` : ''
                    }}</span
                  >
                </div>
              </yxt-tooltip>
            </div>
          </template>
        </yxt-table-column>
        <!-- 部门 -->
        <yxt-table-column
          show-overflow-tooltip
          popover-html
          :label="$t('pc_biz_te_lbl_department')"
          prop="deptName"
          align="left"
        >
          <template slot-scope="scope">
            <yxtbiz-dept-name
              :name="scope.row.deptName || '--'"
            /> </template
          ></yxt-table-column>
        <!-- 岗位 -->
        <yxt-table-column
          show-overflow-tooltip
          :label="$t('biz_udp_position')"
          prop="positionName"
          align="left"
        >
          <template slot-scope="scope">
            {{ scope.row.positionName || '--' }}
          </template>
        </yxt-table-column>
        <!-- 操作 -->
        <yxt-table-column
          v-if="isSingle"
          :label="$t('pc_biz_te_lbl_operation')"
          width="100"
          align="left"
        >
          <template slot-scope="scope">
            <!-- 取消选择/选择 -->
            <yxt-link
              @click="selectTea(scope.row)"
              :underline="false"
              type="primary"
            >{{
                value &&
                (value.id === scope.row.id ||
                  (value[0] && value[0].id === scope.row.id))
                  ? $t('pc_biz_te_btn_cancelselect')
                  : $t('pc_biz_te_btn_select')
              }}</yxt-link
            >
          </template>
        </yxt-table-column>
        <template slot="empty">
          <div style="line-height: normal">
            <yxt-empty
              :empty-text="
                activeName === 'certifiedLecturer'
                  ? $t(
                      'pc_biz_te_no_certification_lecturer' /*暂无认证讲师，您可以在讲师库选择其他讲师*/
                    )
                  : $t('pc_biz_lbl_nodata' /*暂无数据*/)
              "
            />
          </div>
        </template>
      </yxt-table>
      <div class="mt16 clearfix pr">
        <yxt-pagination
          class="pull-right"
          :page-size="teSearch.limit"
          :total="count"
          :current-page="page"
          layout="total, prev, pager, next"
          @current-change="pageChange"
          @size-change="sizeChange"
        ></yxt-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getTeList } from './service';
import dateUtil from 'yxt-biz-pc/src/utils/date';
import resizeTable from '../../user-selector/mixins/resizeTable';
import SingleUser from 'yxt-biz-pc/packages/single-user';
import UserName from 'yxt-biz-pc/packages/user-name';
import DeptName from 'yxt-biz-pc/packages/dept-name';
import { Table, TableColumn, Pagination, Link, Select, Option } from 'yxt-pc';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import SelectPopover from '../../check-person-range/src/components/SelectPopover.vue';
import deptTree from '../../dept-tree';
import checkSinglePosition from '../../check-single-position';
export default {
  name: 'YxtbizTutorSelector',
  components: {
    YxtSingleUser: SingleUser,
    YxtbizUserName: UserName,
    YxtbizDeptName: DeptName,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtLink: Link,
    YxtSelect: Select,
    YxtOption: Option,
    SelectPopover,
    deptTree,
    checkSinglePosition
  },
  mixins: [resizeTable],
  props: {
    value: {
      type: Array | Object,
      default: () => {
        return {};
      }
    },
    // 是否可以有选人模式
    allowSelectUser: {
      type: Boolean,
      default: false
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    selectUserPermissionCode: {
      type: String,
      default: ''
    },
    // 是否包含所有类型的数据（禁用的）
    showAll: {
      type: Boolean,
      default: false
    },
    // 是否强制单选模式， 默认会根据传入的数据类型修改。true时强制单选模式
    singleMode: {
      type: Boolean,
      default: false
    },
    // 导师对应UDP用户状态
    udpDisabled: {
      type: Number,
      default: -1
    },
    // 增加表格是否可选参数
    selectable: {
      type: Function,
      default: () => true
    },
    drawerTop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      renderCount: 0,
      page: 1,
      refTable: 'stb',
      tableHeight: 0,
      teSearch: {
        dataPermissionCode: this.dataPermissionCode,
        deptId: '', // 部门id
        disabled: -1,
        keyword: '',
        limit: 10,
        navCode: this.functionCode,
        offset: 0,
        positionId: ''
      },
      loaded: 1,
      loading: false,
      totalPage: 0,
      count: 0,
      teaList: [],
      teType: '',
      cerTempList: [],
      selected: {},
      user: {},
      users: [],
      isSingle: true, // m2只有单选模式
      isSingleVal: true,
      isCanSelectUser: false,
      isSelectUser: false,
      setTabs: [
        {
          label: this.$t('pc_biz_te_lbl_tutor'),
          value: 'teacher'
        }
      ],
      activeName: 'teacher',
      teSelected: [],
      teData: [],
      firstFlag: false,
      selectOpition: {
        placeholder: i18n.t('pc_biz_te_dept' /* 选择部门 */),
        checkWord: ''
      },
      levelList: [], // 级别列表
      levelVal: '',
      query: {
        showNoClassification: '',
        orgId: ''
      },
      visiblePos: false,
      posVal: { },
      checkedPositions: []
    };
  },
  computed: {
  },
  created() {
    this.isSingleVal =
      !this.value || (!this.value.length && this.value.length !== 0);
    this.isSingle = this.singleMode || this.isSingleVal;
    this.teData = this.value;
    if (this.singleMode) {
      this.user = this.teData[0] || {};
    }
    this.formatModel(this.value);
    this.initChooseType();
    this.getTeList();
  },
  methods: {
    shortDate(row, col, val, index) {
      if (!val) return '';
      return dateUtil.format(new Date(val), 'yyyy-MM-dd');
    },
    filterNode(value, data) {
      return !value || data.label.indexOf(value) !== -1;
    },
    // 列表数据
    getTeList(isReset) {
      if (isReset) {
        this.page = 1;
        this.teSearch.offset = 0;
      }
      this.teSearch.keyword = this.teSearch.keyword.trim();
      this.teSearch.disabled = this.showAll ? -1 : 0;
      this.teSearch.positionId = this.posVal.key;
      this.teSearch.udpDisabled = this.udpDisabled;
      this.loading = true;
      getTeList(this.teSearch)
        .then(result => {
          this.teaList = result.datas;
          this.totalPage = result.paging.pages;
          this.count = result.paging.count;
          this.loaded--;
          this.loading = false;
          this.$nextTick(() => {
            this.setChecked();
          });
        })
        .catch(e => {
          this.loading = false;
          console.log(e);
        });
    },
    // 多选是否显示tab
    initChooseType() {
      this.isCanSelectUser = this.allowSelectUser;
      if (this.isCanSelectUser) {
        this.setTabs = [
          {
            label: this.$t('pc_biz_te_lbl_tutor'), // 导师
            value: 'teacher'
          },
          {
            label: this.$t('biz_te_user'), // 员工
            value: 'person'
          }
        ];
      }
    },
    teNameSearch() {
      this.teSearch.searchKey = this.teCatalogSearch;
      this.getTeList(true);
    },
    sortChange(sort) {
      this.teSearch.direction = sort.order;
      this.teSearch.orderby = sort.prop;
      this.getTeList();
      return false;
    },
    sizeChange(size) {
      this.teSearch.limit = size;
      this.getTeList(true);
    },
    pageChange(page) {
      this.page = page;
      this.teSearch.offset = (page - 1) * this.teSearch.limit;
      this.getTeList();
    },
    selectTea(item) {
      if (this.isSingleVal) {
        if (this.value && (!item || this.value.id === item.id)) {
          this.$emit('input', null);
          this.$emit('select', null);
          this.teData = null;
        } else {
          this.$emit('input', item);
          this.$emit('select', item);
          this.teData = item;
        }
      } else {
        // 兼容之前的多选模式的数组型数据源&& 不是选人场景不可以反选选人的值
        if (
          this.value.length > 0 &&
          (!item || this.value[0].id === item.id) &&
          !(item && item.isUser && !this.isSelectUser)
        ) {
          this.$emit('input', []);
          this.$emit('select', []);
          this.teData = [];
        } else {
          this.$emit('input', item ? [item] : []);
          this.$emit('select', item ? [item] : []);
          this.teData = item ? [item] : [];
        }
      }
    },
    selectUser() {
      if (this.user && this.user.id) {
        this.selectTea({
          id: this.user.id,
          fullname: this.user.fullname,
          isUser: true
        });
      } else {
        this.selectTea();
      }
    },
    getCatasOfTree(arrNew, pId, cataAll) {
      cataAll.forEach(cata => {
        if (cata.parentId === pId || (!pId && !cata.parentId)) {
          cata.label = cata.name;
          cata.children = [];
          this.getCatasOfTree(cata.children, cata.id, cataAll);
          arrNew.push(cata);
        }
      });
    },
    // 选择某个
    select(item, row) {
      let isAdd = item.some(v => {
        return v.id === row.id;
      });
      if (!isAdd) {
        delete this.selected[row.id];
      } else {
        this.selected[row.id] = row;
      }
      this.outPut();
    },
    // 全选
    selectAll(items) {
      let isAdd =
        items.filter(item => {
          return this.selectable(item);
        }).length !== 0;
      this.teaList.forEach(obj => {
        if (this.selectable(obj)) {
          if (!isAdd) {
            delete this.selected[obj.id];
          } else {
            this.selected[obj.id] = obj;
          }
        }
      });
      this.outPut();
    },
    // 删除已选择
    deleteItem(item) {
      let row = item;
      for (let index = 0; index < this.teaList.length; index++) {
        const element = this.teaList[index];
        if (element.id === item.id) {
          row = element;
          break;
        }
      }
      if (row) {
        this.$refs['stb'].toggleRowSelection(row, false);
      }
      delete this.selected[item.id];
      this.$forceUpdate();
      this.outPut();
    },
    // 清空
    clear() {
      let row = null;
      for (const key in this.selected) {
        if (this.selected.hasOwnProperty(key)) {
          for (let index = 0; index < this.teaList.length; index++) {
            const element = this.teaList[index];
            if (element.id === key) {
              row = element;
              break;
            }
          }
          if (row) {
            this.$refs['stb'].toggleRowSelection(row, false);
          }
        }
      }
      this.selected = {};
      this.outPut();
    },
    outPut() {
      this.teSelected = Object.values(this.selected);
      let reArry = this.reduceFn([...this.teSelected]);
      if (reArry.length > this.limit) {
        this.showLimitError();
        return;
      }
      this.$emit('input', reArry);
      this.$emit('select', reArry);
      this.teData = reArry;
    },
    showLimitError() {
      this.$alert(
        this.$t('pc_biz_udp_msg_outRange'),
        this.$t('biz_tcm_limitnum', { x: this.limit }),
        {
          confirmButtonText: this.$t('biz_tcm_checkbtn'),
          type: 'error'
        }
      );
    },
    setChecked() {
      if (this.isSelectUser) {
        return;
      }
      this.teaList.forEach(obj => {
        if (this.selected.hasOwnProperty(obj.id)) {
          this.$refs['stb'].toggleRowSelection(obj, true);
        } else {
          this.$refs['stb'] && this.$refs['stb'].toggleRowSelection(obj, false);
        }
      });
    },
    formatModel(v) {
      this.selected = {};
      if (v) {
        if (this.isSingle) {
          // 单选入参v也存在array类型
          if (Object.prototype.toString.call(v) === '[object Object]') {
            if (v.id) {
              this.selected[v.id] = v;
            }
          } else {
            v.forEach(obj => {
              this.selected[obj.id] = obj;
            });
          }
        } else {
          v.forEach(obj => {
            this.selected[obj.id] = obj;
          });
        }
      }
    },
    // tab切换后重新勾选上已选择的讲师
    tabClick() {
      this.selectOpition.checkWord = '';
      this.teSearch.deptId = ''; // 清空部门选项
      this.teType = this.type === -1 ? '' : this.type; // 清除讲师已选类型
      this.levelVal = ''; // 清除已选讲师等级
      this.teSearch.keyword = '';
      if (this.activeName !== 'person') this.getTeList();
      this.isSelectUser = this.activeName === 'person';
      this.firstFlag = true;
      if (this.teData && this.teData.length > 0) {
        if (!this.singleMode) {
          this.users = this.teData;
        }
        // 切换到选人tab后，数据不一致清空user
        if (this.isSelectUser && this.user && this.user.id && this.teData[0].id !== this.user.id) {
          this.user = {};
        }
      } else {
        this.users = [];
        this.user = {};
      }
      this.formatModel(this.teData);
      this.$nextTick(() => {
        this.setChecked();
      });
    },
    // 选择员工
    changeFn(users) {
      this.teSelected = Object.values(this.selected);
      if (users && users.length > 0) {
        let listArry = [];
        users.forEach(e => {
          if (e && e.fullname) {
            listArry.push({
              id: e.id,
              fullname: e.fullname,
              isUser: true
            });
          }
        });
        let reArry = this.reduceFn([...listArry]);
        this.$emit('input', reArry);
        this.$emit('select', reArry);
        this.teData = reArry;
      } else {
        this.$emit('input', []);
        this.$emit('select', []);
        this.teData = [];
      }
    },
    // 数组根据ID去重
    reduceFn(arryData) {
      // return arryData;
      let obj = {};
      return arryData.reduce((item, next) => {
        obj[next.id] ? '' : (obj[next.id] = true && item.push(next || ''));
        return item;
      }, []);
    },
    // 选择部门
    nodeClick(a) {
      this.teSearch.deptId = a.id;
      this.selectOpition.checkWord = a.id ? a.name : '';
      this.$refs['selectPopover'].cancel();
      this.getTeList(true);
    },
    // 选择级别
    changeLevel() {
      this.teSearch.levelId = this.levelVal === '-1' ? '' : this.levelVal;
      this.getTeList(true);
    },
    // 选择岗位
    handlePositionChoose(selectOption) {
      if (selectOption.length > 0) {
        this.posVal = {
          key: selectOption[0].id,
          value: selectOption[0].name
        };
      } else {
        this.posVal = { };
      }
      this.visiblePos = false;
      this.getTeList(true);
    }
  },
  watch: {
    activeName(newVal) {
      if (newVal) this.getTeList(true);
    },
    teaList() {
      this.renderCount++;
    },
    teCatalogSearch(val) {
      this.$refs.tree.filter(val);
    },
    value(v) {
      this.formatModel(v);
      this.setChecked();
    },
    user() {
      this.selectUser();
    }
  }
};
</script>
