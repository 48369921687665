var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-upload", on: { click: _vm.handleClick } },
    [
      _vm.drag
        ? _c(
            "dragger",
            {
              attrs: { disabled: _vm.disabled, "icon-class": _vm.iconClass },
              on: { file: _vm.uploadFiles },
            },
            [_vm._t("default"), _vm._t("tip", null, { slot: "tip" })],
            2
          )
        : _vm._t("default"),
      _c("form", { ref: "form", staticClass: "yxtbiz-upload__input" }, [
        _c("input", {
          ref: "input",
          attrs: { type: "file", multiple: _vm.multipe, accept: _vm.accept },
          on: { change: _vm.handleFileChange },
        }),
      ]),
      _vm.disabled
        ? _c("div", { staticClass: "yxtbiz-upload--disabled" })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }