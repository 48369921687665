<template>
  <yxt-drawer
    class="yxtbiz-hwk-template-selector"
    :visible.sync="visible"
    :before-close="beforeClose"
    @closed="handleClose"
    size="960px"
  >
  <template slot="title">
    <span class="yxtbiz-hwk-template-selector__title">{{ $t('pc_biz_appraise_choose_template').d('选择模板') }}</span>
    <span class="yxtbiz-hwk-template-selector__describe">{{ $t('pc_biz_lbl_select_edit_content_template_operate').d('选择后已编辑内容将被模板替换，请谨慎操作') }}</span>
  </template>
  <yxtbiz-range-selector
    v-model="selectData"
    :tabs="tabs"
    model="array"
    @change="handleTabsClick"
  >
    <div slot='customTab'>
      <yxt-input
        v-model.trim="keyword"
        class="yxtbiz-hwk-template-selector__search"
        size="small"
        :placeholder="this.$t('pc_biz_survey_tip_namesearch').d('请输入模板名称搜索')"
        searchable
        clearable
        @search="searchData(true)"
        @clear="searchData(true)"
        @keyup.enter.native="searchData(true)">
      </yxt-input>
      <yxt-table
        v-loading="loading"
        :row-key="getRowKey"
        :height="getHeight"
        ref="tableRefs"
        class="yxtbiz-hwk-template-selector__table"
        :data="tableData"
      >
        <yxt-table-column width="40">
          <template slot-scope="scope">
            <yxt-radio v-model="resRadio"
                      :label="scope.row.id"
                      @change.native="getCurrentRow(scope.row)" />
          </template>
        </yxt-table-column>
        <!-- 作业名称 -->
        <yxt-table-column
          width="180"
          show-overflow-tooltip
          :label="$t('pc_biz_lbl_work_name').d('作业名称')">
          <template slot-scope="{row}">
             <yxt-link
                  type="primary"
                  class="yxtbiz-hwk-template-selector__name"
                  :underline="false"
                  @click="linkName(row)">
          {{row.templateName }}
        </yxt-link>
          </template>
        </yxt-table-column>
        <!-- 作业要求 -->
        <yxt-table-column
          min-width="220"
          show-overflow-tooltip
          :label="$t('pc_biz_work_require').d('作业要求')">
          <template slot-scope="{row}">
            {{ cleanStyle(row.requirement) }}
          </template>
        </yxt-table-column>
        <!-- 作业模式 -->
        <yxt-table-column
          min-width="120"
          show-overflow-tooltip
          :label="$t('pc_biz_lbl_model').d('模式')">
          <template slot-scope="{row}">
            {{ $t(row.multiItem ? 'pc_biz_lbl_row_model': 'pc_biz_lbl_signle_model') }}
          </template>
        </yxt-table-column>
        <!-- 更新人 -->
        <yxt-table-column
          min-width="120"
          show-overflow-tooltip
          :label="$t('pc_biz_o2o_updater').d('更新人')">
          <template slot-scope="{row}">
            <yxtbiz-user-name :name="row.updateFullname || '--'" />
          </template>
        </yxt-table-column>
        <!-- 更新时间 -->
        <yxt-table-column
          min-width="180"
          show-overflow-tooltip
          :label="$t('pc_biz_global_lbl_updateTime').d('更新时间')">
          <template slot-scope="{row}">
            {{row.updateTime ? dateFormat(row.updateTime) : '--'}}
          </template>
        </yxt-table-column>
      </yxt-table>
      <!-- 分页 -->
      <yxt-pagination
        class="pull-right mt16 yxtbiz-hwk-template-selector__page"
        layout="total, prev, pager, next"
        :current-page="page.current"
        :page-size="page.limit"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </yxtbiz-range-selector>

    <div slot="footer">
      <yxt-button plain @click="handleCancel">{{ $t('pc_biz_common_cancel_mini').d("取消") }}</yxt-button>
      <yxt-button type="primary" @click="handleSure" :loading="dLoading">{{ $t('pc_biz_common_btn_sure').d("确定") }}</yxt-button>
    </div>
  </yxt-drawer>
</template>

<script>
import { getO2oTemplatePaged, getUlcdTemplatePaged, getTemplateDetail } from './service';

export default {
  name: 'YxtbizHwkTemplateSelector',
  props: {
    code: {
      type: String,
      required: true,
      validator(val) {
        return ['o2o', 'ulcd'].includes(val);
      }
    },
    value: { // 被选中的值
      type: Object,
      default: () => ({})
    },
    visible: Boolean
  },
  data() {
    return {
      loading: false,
      dLoading: false,
      currentRadio: {},
      resRadio: null,
      tableData: [],
      selectData: [],
      tabs: [
        {
          type: 'customTab',
          dataName: 'templateName'
        }
      ],
      page: {
        current: 1,
        total: 0,
        limit: 20
      },
      keyword: ''
    };
  },
  watch: {
    visible: {
      handler(v) {
        if (v) {
          this.searchData(true, v);
        }
      },
      immediate: true
    }
  },
  computed: {
    getHeight() {
      const h = this.page.total > this.page.limit ? '92' : '44';
      return `calc(100% - ${h}px)`;
    }
  },
  methods: {
    linkName(row) {
      window.open(`${window.location.origin}/o2o/#/examplepreview?id=${row.id}`);
    },
    cleanStyle(str) {
      if (!str) {
        return '--';
      } else {
        str = str.replace(/<p.*?>|<\/p>/g, '').replace(/<a.*?>|<\/a>/g, '').replace(/<div.*?>|<\/div>/g, '').replace(/<br>/g, '').replace(/&nbsp;/g, '');
        return str;
      }
    },
    getRowKey(row) {
      return row.id;
    },
    handleTabsClick(data) {
      if (data.length === 0) {
        this.currentRadio = {};
        this.resRadio = null;
      }
    },
    searchData(toFirstPage, show) {
      if (!['o2o', 'ulcd'].includes(this.code)) {
        return;
      }
      if (toFirstPage) {
        this.page.current = 1;
      }
      this.loading = true;

      const api = this.code === 'o2o' ? getO2oTemplatePaged : getUlcdTemplatePaged;
      const { current, limit } = this.page;

      api({ keyword: this.keyword, current, limit }).then(res => {
        const { datas, paging } = res;

        this.tableData = datas;
        this.page.total = paging.count;
        if (this.value && this.value.id && show) {
          this.getCurrentRow(datas.find(item => item.id === this.value.id));
        }
      }).catch((err)=>{
        if (err) {
          if (typeof err.error === 'string') {
            err.message = err.error;
          }
          this.$message.error(err.message);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    getCurrentRow(row) {
      if (!row) return;
      this.resRadio = row.id;
      this.currentRadio = row;
      this.selectData = [row];
    },
    handleSizeChange(size) {
      this.page.limit = size;
      this.searchData(true);
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.searchData();
    },
    handleClose() {
      this.currentRadio = {};
      this.resRadio = null;
      this.selectData = [];
      this.keyword = '';
      this.$emit('update:visible', false);
    },
    handleCancel() {
      this.handleClose();
      this.$emit('cancel');
    },
    beforeClose(done) {
      if (this.value && this.value.id) {
        done(this.value.id !== this.resRadio);
      } else {
        done(this.resRadio && this.currentRadio);
      }
    },
    handleSure() {
      if (!(this.resRadio && this.currentRadio)) {
        return this.$message.info(this.$t('pc_biz_appraise_template').d('请选择模板'));
      }
      this.dLoading = true;
      getTemplateDetail({ templateId: this.resRadio }).then(res => {
        this.$emit('update:visible', false);
        this.$emit('input', this.currentRadio);
        this.$emit('confirm', res);
      }).catch((err)=>{
        if (err) {
          if (typeof err.error === 'string') {
            err.message = err.error;
          }
          this.$message.error(err.message);
        }
      }).finally(() => {
        this.dLoading = false;
      });

    },
    dateFormat(date, fmt = 'yyyy-MM-dd HH:mm') {
      if (!date) {
        return '--';
      }
      if (typeof date === 'string') {
        date = date.replace(/-/g, '/');
      }
      date = new Date(date);
      const obj = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'H+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds() // 毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substring(4 - RegExp.$1.length));
      }
      for (let key in obj) {
        if (new RegExp('(' + key + ')').test(fmt)) {
          fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? obj[key] : ('00' + obj[key]).substring(('' + obj[key]).length));
        }
      }
      return fmt;
    }
  }
};
</script>

<style lang="scss" scoped>
.yxtbiz-hwk-template-selector {
  &__title,
  &__describe {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__title {
    flex: initial;
    padding: 0 16px 0 4px;
    font-size: 16px;
    font-weight: 500;
    color: #262626;
  }
  &__describe {
    font-size: 14px;
    font-weight: 400;
    color: #BFBFBF;
    padding-right: 18px;
  }
}
</style>
