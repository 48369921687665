<!--
 * @Author: 徐昕
 * @Date: 2021-05-19 09:42:38
 * @LastEditTime: 2021-10-09 10:53:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /yxt-biz-pc/packages/tcm-select/src/main.vue 微课选择
-->
<template>
  <div class="yxtbiz-tcm-select" v-loading="loading">
    <!-- {{isOpenData}} -->
    <!-- 搜索框 -->
    <div class="yxtbiz-tcm-select__search">
      <yxt-input
        class="yxtbiz-tcm-select__searchinput"
        :placeholder="$t('pc_biz_tcm_tip_search')"
        v-model="courseName"
        searchable
        @search="searchName"
        maxlength="50"
      >
      </yxt-input>
    </div>
    <!-- 主体 -->
    <div>
      <!-- 表格-->
      <yxt-table
        :loading="loading"
        :data="tcmList"
        :row-key="getRowKey"
        :virtual="true"
        :rowHeight="100"
        @row-click="rowClick"
        @selection-change="handleSelectionChange"
        :row-style="rowStyle"
        class="yxtbiz-tcm-select__table"
        ref="tcmSelectList"
      >
        <!-- 多选 -->
        <yxt-table-column
          v-if="!singleMode"
          type="selection"
          :reserve-selection="true"
          width="55">
        </yxt-table-column>
        <!-- 文档名称 -->
        <yxt-table-column
          prop="name"
          :label="$t('pc_biz_tcm_documentname')"
          :show-overflow-tooltip="false"
          min-width="385px"
        >
          <template slot-scope="{ row }">
            <!-- 单选框 -->
            <div
              class="yxtbiz-tcm-select__single-select"
              v-if="singleMode"
            >
              <yxt-radio
                v-model="choose"
                :label="row.id"
                :hideLabel="true"
                @click.native.prevent.stop="
                  radioClick(row.id, row)
                "
              >
             
              </yxt-radio>
            </div>
            <!-- 微课封面 -->
            <div class="yxtbiz-tcm-select__covers">
              <yxt-image
                class="yxtbiz-tcm-select__covers-image"
                :src="row.courseCover"
                fit="cover"
              ></yxt-image>
            </div>
            <!-- 文件名 -->
            <div class="yxtbiz-tcm-select__filename">
              <yxt-tooltip
                class="item"
                effect="light"
                :open-filter="true"
                max-width="400"
                :content="row.courseName"
                placement="top"
              >
                <div class="yxtbiz-tcm-select__name">
                  {{ row.courseName }}
                </div>
              </yxt-tooltip>
            </div>
          </template>
        </yxt-table-column>
        <!-- 状态 -->
        <yxt-table-column
          :label="$t('pc_biz_tcm_status')"
          prop="courseStatus"
          :show-overflow-tooltip="false"
          width="108px"
        >
          <template slot-scope="{ row }">
            <span
              :class="['yxtbiz-tcm-select__dot' + row.courseStatus, 'yxtbiz-tcm-select__dot']"
            ></span>
            <span>{{ getStatusName(row.courseStatus) }}</span>   
          </template>
        </yxt-table-column>
        <!-- 时长 -->
        <yxt-table-column
          prop="duration"
          :label="$t('pc_biz_tcm_duration')"
          :show-overflow-tooltip="true"
          width="118px"
          :formatter="durationFormatter"
        ></yxt-table-column>
        <!-- 讲师 -->
        <yxt-table-column
          prop="teacherName"
          :label="$t('pc_biz_tcm_lecturer')"
          :show-overflow-tooltip="true"
          width="98px"
          :popover-html="true"
        >
          <template slot-scope="scope">
            <!-- :formatter="teacherFormatter" -->
            <yxtbiz-user-name v-if='isOpenData' :name="scope.row.userName === '' || !scope.row.userName ? '--' : scope.row.userName" />
            <span
              v-else
              v-html="`${scope.row.userName === '' || !scope.row.userName ? '--' : scope.row.userName}`"
            ></span>
          </template>
        </yxt-table-column>
      </yxt-table>
      <!-- 分页控件 -->
      <yxt-pagination
        class="mt12 text-right"
        v-if="paging.pages > 1"
        @current-change="handlePageChange"
        :page-size="paging.limit"
        :total="paging.count"
        :current-page="paging.current"
        simple-total
        layout="total, prev, pager, next"
      >
      </yxt-pagination>
    </div>
  </div>
</template>

<script>
import { getTcmList } from './service';
import { Table, TableColumn, Pagination, Image } from 'yxt-pc';
import { isOpenData } from 'yxt-biz-pc/src/utils/shared';

const courseStatus = {
  noSend: 0,
  send: 1,
  all: -1
};
export default {
  name: 'YxtbizTcmSelect',
  components: {
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtImage: Image
  },
  props: {
    // 是否单选模式， 默认会根据传入的数据类型修改。true时强制单选模式
    singleMode: {
      type: Boolean,
      default: false
    },
    // 已选微课
    hasSelected: {
      type: Array,
      default: () => []
    },
    // 最多选中微课数
    chooseLimit: {
      type: Number,
      default: -1
    },
    // 微课列表筛选状态， 默认已发布
    tcmStatus: {
      type: Number,
      default: courseStatus.send
    },
    // 导航的功能权限
    functionCode: {
      type: String,
      default: ''
    },
    // 数据权限code
    dataPermissionCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      courseStatus: courseStatus,
      types: {
        [courseStatus.send]: this.$t('pc_biz_tcm_published'),
        [courseStatus.noSend]: this.$t('pc_biz_tcm_nopublished')
      },
      tcmSearch: {
        courseName: '',
        current: 1,
        limit: 10,
        direction: 'desc',
        orderby: 'release_time'
      },
      courseName: '',
      loading: false,
      tcmList: [],
      rowStyle: {
        cursor: 'pointer'
      },
      paging: {
        limit: 10,
        count: 100,
        pages: 10,
        current: 1
      },
      statusList: [-1, 0, 1],
      selectList: [],
      oldSelectList: [],
      choose: -1,
      singleChoose: {}
    };
  },
  computed: {
    isOpenData() {
      return isOpenData();
    }
  },
  watch: {
    tcmSearch: {
      handler: function(val) {
        this.getTcmList();
      },
      deep: true
    }
  },
  created() {
    this.getTcmList();
  },
  mounted() {
    this.$nextTick(() => {
      this.checkStatus();
    });
  },
  methods: {
    getTcmList() {
      this.loading = true;
      const params = { ...this.tcmSearch };
      // 增加微课发布状态传参
      const hasStatus = this.statusList.indexOf(this.tcmStatus) > 0;
      if (hasStatus) {
        params.courseStatus = this.tcmStatus;
      }
      getTcmList(params).then(res => {
        if (res) {
          this.tcmList = res.datas;
          this.paging.count = res.paging.count;
          this.paging.pages = res.paging.pages;
        }
      }).catch(e => {
        this.tcmList = [];
        this.paging.count = 0;
        this.paging.pages = 0;
        console.error(e);
      }).finally(() => {
        this.loading = false;
      });
    },
    // 验证是否选中
    checkStatus() {
      if (Array.isArray(this.hasSelected)) {
        if (this.singleMode && this.hasSelected.length > 0) {
          this.choose = this.hasSelected[0].id;
          this.singleChoose = { ...this.hasSelected[0] };
          this.selectList = this.hasSelected.concat();
          return;
        }
        if (this.hasSelected.length === 0) {
          this.$refs['tcmSelectList'].clearSelection();
          this.selectList = [];
        } else {
          this.hasSelected.forEach(tcm => {
            // this.tcmList.hasOwnProperty(tcm.id)
            this.$refs['tcmSelectList'].toggleRowSelection(tcm, true);
            this.selectList = this.hasSelected.concat();
          });
        }
      }
    },
    searchName() {
      this.tcmSearch = Object.assign(this.tcmSearch, {
        current: 1,
        courseName: this.courseName
      });
    },
    handleSelectionChange(val) {
      this.selectList = val;
      this.outPut();
    },
    radioClick(id, row) {
      this.choose = this.choose === id ? -1 : id;
      this.singleChoose = this.choose === id ? row : {};
      this.selectList = this.choose === id ? [this.singleChoose] : [];
      this.outPut();
    },
    rowClick(row) {
      // 切换当前点击行选中状态
      if (!row.hasOwnProperty('id')) return;
      // 区分单，多选模式
      if (!this.singleMode) {
        const findIndex = this.selectList.findIndex((item) => { return item.id === row.id; });
        this.$refs['tcmSelectList'].toggleRowSelection(row, !(findIndex > -1));
        return;
      }
      this.radioClick(row.id, row);
    },
    getSelected() {
      const justifyLimit = this.isNumber(this.chooseLimit) &&
        this.chooseLimit > -1 &&
        this.selectList.length > this.chooseLimit &&
        !this.singleMode;
      if (justifyLimit) {
        this.showLimitError();
        return [];
      }
      return this.selectList.map((item) => item);
    },
    outPut() {
      this.$emit('select', this.selectList.concat());
    },
    clearData() {
      this.selectList = [];
      this.singleChoose = {};
      this.choose = -1;
    },
    isNumber(value) {
      return typeof value === 'number' && !isNaN(value);
    },
    showLimitError() {
      if (this.chooseLimit === -1) return;
      this.$alert(
        this.$t('pc_biz_tcm_tip_overlimit'),
        `${this.$t('pc_biz_tcm_tip_limit', [this.chooseLimit])}`, {
          confirmButtonText: this.$t('pc_biz_tcm_btn_check'),
          type: 'error'
        });
    },
    // 以下为表格展示配置区
    getRowKey(row) {
      return row.id;
    },
    handlePageChange(page) {
      this.tcmSearch.current = page;
    },
    getStatusName(status) {
      return this.types[status] || '--';
    },
    durationFormatter(row) {
      const number = row.duration;
      const hour = parseInt(number / 3600, 10);
      const minutes = parseInt((number % 3600) / 60, 10);
      const seconds = (number % 3600) % 60;
      const time =
        hour !== 0
          ? `${hour}${this.$t('pc_biz_tcm_hour')}${minutes}${this.$t('pc_biz_tcm_minute')}${seconds}${this.$t('pc_biz_tcm_second')}`
          : `${minutes}${this.$t('pc_biz_tcm_minute')}${seconds}${this.$t('pc_biz_tcm_second')}`;
      return time;
    },
    teacherFormatter(row) {
      return row.userName === '' || !row.userName ? '--' : row.userName;
    }
  }
};
</script>
