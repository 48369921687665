import { getStaticCdnUrl } from 'yxt-biz-pc/packages/common-util/domain';

const cdnUrl = getStaticCdnUrl();
const StcOssCorePc = `${cdnUrl.staticBaseUrl}ufd/2338a3/core/pc`;
const StcOssCorePcSvg = StcOssCorePc + '/svg';
// const ImgOss = `${cdnUrl.imagesBaseUrl}`;

export const genStrMixin = () => {
  return {
    computed: {
      strStcOssCorePcSvg() {
        return StcOssCorePcSvg;
      },
      strAvatar() {
        return `${cdnUrl.staticBaseUrl}/ufd/3f5568/core/pc/img/avatar.png`;
      }
    }
  };
};
