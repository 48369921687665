<template>
  <div class="yxtbiz-choose-project">
    <div class="yxtbiz-selector" @click="selectDraw = false">
        <!-- 左侧筛选项及table -->
        <div class="yxtbiz-selector__left">
          <!-- 筛选项 -->
          <div class="clearfix font-size-14">
            <yxt-button
              v-if="enableCreate"
              type="primary"
              class="pull-left mr12"
              size="large"
              @click="createProjectHandler"
            >{{$t('pc_biz_project_create')}}</yxt-button>
            <!-- 新增机构选择 -->
            <yxtbiz-group-org-select
              v-if="groupSelect && this.fromType !== 'sp'"
              class="pull-left mr10 mb10 ml10"
              :placeholder="$t('pc_biz_kng_lbl_chooseorg' /*请选择机构*/)"
              set-current-org
              function-code="group_data_center"
              data-permission-code="data_manage_platform"
              :clearable="false"
              @change="orgIdchange"
              :disabledOrgIds="[]"
              :hideOrgIds="[]"
              :visibleOrgIds="[]"
              :selected="[]"
            />
            <yxt-popover
              :append-to-body='false'
              popper-class='yxtbiz-project-selector__popover'
              trigger='manual'
              placement='bottom-start'
              :visible-arrow='false'
              v-model='selectDraw'
            >
              <yxt-button plain slot='reference' class="yxtbiz-filter-btn pull-left mr12" :disabled="moreSearch" @click.stop="selectDraw = !selectDraw">
                <yxt-svg class="v-mid mr2 mb4" width="18px" height="18px" icon-class="filter" />
                {{ $t('pc_biz_common_lbl_moreFilter' /*更多筛选*/) }}
                <yxt-svg v-if="selectDraw" class="v-mid ml8" width="14px" height="14px" icon-class="arrow-up" />
                <yxt-svg v-else class="v-mid ml8" width="14px" height="14px" icon-class="arrow-down" />
              </yxt-button>
              <div v-show="selectDraw" class="clearfix font-size-14 more-select show-more" @click="selectDraw = true">
                <!-- 项目发布时间 -->
                <div v-if="fromType !== 'sp'" class="pull-left mb10">
                  <label
                    class="reportor-form__label draw-label"
                    style="margin-right: 12px;"
                    v-text="$t('pc_biz_project_publish_time' /* 项目发布时间 */)"
                  />
                  <yxt-date-picker
                    v-model="dataRangeVal"
                    class="reportor-form__content w330"
                    type="daterange"
                    :range-separator="$t('pc_biz_ls_to' /* 至 */)"
                    :start-placeholder="$t('pc_biz_ls_start' /* 开始日期 */)"
                    :end-placeholder="$t('pc_biz_ls_end' /* 结束日期 */)"
                    @change="onChangeDate"
                  />
                </div>
                <!-- 项目开始时间 -->
                <div v-if="fromType !== 'sp'" class="pull-left mb10">
                  <label
                    class="reportor-form__label draw-label"
                    style="margin-right: 12px;"
                    v-text="$t('pc_biz_project_create_time' /* 项目开始时间 */)"
                  />
                  <yxt-date-picker
                    v-model="createRangeVal"
                    class="reportor-form__content w330"
                    type="daterange"
                    :range-separator="$t('pc_biz_ls_to' /* 至 */)"
                    :start-placeholder="$t('pc_biz_ls_start' /* 开始日期 */)"
                    :end-placeholder="$t('pc_biz_ls_end' /* 结束日期 */)"
                    @change="onChangeCreateDate"
                  />
                </div>
                <!-- 项目结束时间 -->
                <div v-if="fromType !== 'sp'" class="pull-left mb10">
                  <label
                    class="reportor-form__label draw-label"
                    style="margin-right: 12px;"
                    v-text="$t('pc_biz_project_end_time' /* 项目结束时间 */)"
                  />
                  <yxt-date-picker
                    v-model="endRangeVal"
                    class="reportor-form__content w330"
                    type="daterange"
                    :range-separator="$t('pc_biz_ls_to' /* 至 */)"
                    :start-placeholder="$t('pc_biz_ls_start' /* 开始日期 */)"
                    :end-placeholder="$t('pc_biz_ls_end' /* 结束日期 */)"
                    @change="onChangeEndDate"
                  />
                </div>
                <!-- 下拉搜索 -->
                <div class="pull-left mb10">
                  <label class="reportor-form__label draw-label mr14" v-text="$t('pc_biz_lbl_prjStatus' /* 项目状态*/)" />
                  <yxt-select v-model="statusName" clearable class="reportor-form__content draw-select w330" @change="onStausSelect($event)">
                    <yxt-option v-for="option in selectStatusArr" :key="option.type" :label="$t(option.name)" :value="option.name" />
                  </yxt-select>
                </div>
                <div class="pull-left mb10">
                  <label class="reportor-form__label draw-label mr14" v-text="$t('pc_biz_lbl_prjCatalog' /* 项目分类*/)" />
                  <yxt-select
                    ref="categorySelect"
                    v-model="selectCategory"
                    value-key="id"
                    prop-label="name"
                    clearable
                    class="reportor-form__content draw-select w330"
                    @clear="handleCategoryClear"
                  >
                    <div class="yxtbiz-project-selector__project-category" slot="empty">
                      <yxt-input
                        :placeholder="$t('pc_biz_ote_tip_catalogsearch')"
                        class="yxtbiz-project-selector__project-category-input"
                        v-model="filterText"
                        @search="handleSearchCategory"
                        searchable
                      >
                      </yxt-input>
                      <div class="yxtbiz-project-selector__project-category-tree">
                        <yxt-tree
                          class="filter-tree"
                          :data="projectCategoryData"
                          node-key="id"
                          :props="{
                            children: 'children',
                            label: 'name'
                          }"
                          default-expand-all
                          :filter-node-method="filterProjectNode"
                          ref="tree"
                          @node-click="handleSelectCategory"
                        >
                        </yxt-tree>
                      </div>
                    </div>
                  </yxt-select>
                </div>
                <div class="pull-left mb10">
                  <label class="reportor-form__label draw-label mr14" v-text="$t('pc_biz_lbl_prjTimeType' /* 项目模式*/)" />
                  <yxt-select v-model="typeName" clearable class="reportor-form__content draw-select w330" @change="onTypeSelect($event)">
                    <yxt-option v-for="option in selectTypeArr" :key="option.type" :label="$t(option.name)" :value="option.name" />
                  </yxt-select>
                </div>
                <div v-if="fromType !== 'sp'" class="pull-left mb10">
                  <label class="reportor-form__label draw-label mr14" v-text="$t('pc_biz_lbl_includefileimport' /* 包含档案导入*/)" />
                  <yxt-select v-model="includeImport" clearable class="reportor-form__content draw-select w330" @change="onImpSelect($event)">
                    <yxt-option v-for="option in selectImpArr" :key="option.type" :label="$t(option.name)" :value="option.name" />
                  </yxt-select>
                </div>
                <div class="pull-left" />
              </div>
            </yxt-popover>
            <!-- 管辖范围-->
            <yxt-select
              v-model="range"
              clearable
              class="pull-left mr12"
              style="width: 144px;"
              @change="getProjectList"
            >
              <yxt-option v-for="option in rangeOptions" :key="option.value" :label="option.label" :value="option.value" />
            </yxt-select>
            <!-- 请输入项目名称搜索 -->
            <yxt-input
              v-model="projectSearch.name"
              class="yxtbiz-selector__input pull-left"
              :placeholder="$t('pc_biz_o2o_training_placeholder_inputName' /* 请输入项目名称搜索 */)"
              searchable
              maxlength="50"
              style="width: 200px;"
              @search="projectNameSearch"
            />
          </div>
          <!-- table -->
          <div class="yxtbiz-selector__main mt16">
            <!-- 加key以后换行自动跳到table页头 -->
            <yxt-table
              ref="refTable"
              :key="'projectTable' + page"
              v-loading="loading"
              class="yxtbiz-selector__table"
              :data="projectList"
              :default-sort="{ prop: 'releaseDate', order: 'desc' }"
              @select-all="selectAll"
              @select="select"
              @sort-change="sortChange"
            >
              <yxt-table-column :show-overflow-tooltip="false" type="selection" width="38" align="right" clear-padding="left-right" />
              <!-- 项目名称 -->
              <yxt-table-column :label="$t('pc_biz_ojt_lbl_projectname')" :show-overflow-tooltip="false">
                <template slot-scope="scope">
                  <div class="sign-flex-style">
                    <yxt-tooltip class="overflow" effect="dark" :open-filter="true" :content="scope.row.name" placement="top">
                      <span>{{ scope.row.name }}</span>
                    </yxt-tooltip>
                    <!-- 0项目 1项目模板 2:集团化项目 -->
                    <span v-if="scope.row.type === 2" class="sign-text-style">{{ $t('pc_biz_ls_group') /* 集团*/ }}</span>
                  </div>
                </template>
              </yxt-table-column>
              <!-- 项目分类 -->
              <yxt-table-column v-if="fromType !== 'sp'" :label="$t('pc_biz_lbl_prjCatalog')" :show-overflow-tooltip="false" align="center">
                <template slot-scope="scope">
                  <div class="sign-flex-style">
                    <yxt-tooltip class="overflow" effect="dark" :open-filter="true" :content="scope.row.sceneName" placement="top">
                      <span>{{ scope.row.sceneName || '--' }}</span>
                    </yxt-tooltip>
                  </div>
                </template>
              </yxt-table-column>
              <!-- 状态 -->
              <yxt-table-column :label="$t('pc_biz_lbl_prjStatus' /*项目状态*/)" prop="status" align="left" width="100">
                <template slot-scope="scope">
                  <span :class="['yxtbiz-selector__project-status', `yxtbiz-selector__project-status--${scope.row.status}`]" />
                  <span>
                    {{ getStatusName(scope.row.status) }}
                  </span>
                </template>
              </yxt-table-column>
              <!-- 发布人 -->
              <yxt-table-column
                v-if="fromType !== 'sp'"
                :label="$t('pc_biz_shoptour_publish_person')"
                show-overflow-tooltip
                popover-html
                prop="releaseUserFullName"
                align="left"
                width="120"
              >
                <template slot-scope="scope">
0                  <yxtbiz-user-name :name="scope.row.releaseUserFullName || '--'" />
                </template>
              </yxt-table-column>
              <!-- 负责人 -->
              <yxt-table-column
                v-if="fromType === 'sp'"
                :label="$t('pc_biz_o2o_principal'/* 负责人 */)"
                show-overflow-tooltip
                popover-html
                prop="principalCnName"
                align="left"
                width="120"
              >
                <template slot-scope="scope">
                  <yxtbiz-user-name :name="scope.row.principalCnName || '--'" />
                </template>
              </yxt-table-column>
              <!-- 发布日期 -->
              <yxt-table-column
                v-if="fromType !== 'sp'"
                :label="$t('pc_biz_ote_lbl_publishdate')"
                prop="releaseDate"
                sortable="custom"
                :formatter="shortDate()"
                align="left"
                width="130"
              />
              <!-- 创建时间 -->
              <yxt-table-column
                v-if="fromType === 'sp'"
                :label="$t('pc_biz_o2o_lbl_createTime'/* 创建时间 */)"
                prop="createTime"
                :sortable="fromType !== 'sp' ? 'custom' : undefined"
                :formatter="shortDate('yyyy-MM-dd HH:mm')"
                align="left"
                width="160"
              />
            </yxt-table>
          </div>
          <div class="mt16 clearfix pr">
            <yxt-button type="text" :loading="selectAllBtnLoading" :disabled="!projectList.length" @click="handleSelectAll">
                {{$t('pc_biz_kng_selectall'/** 筛选全部结果 */)}}
            </yxt-button>
            <yxt-pagination
              class="pull-right"
              :page-size="fromType !== 'sp' ? projectSearch.size : 20"
              :total="count"
              :current-page="page"
              layout="total, prev, pager, next"
              @current-change="pageChange"
            />
          </div>
        </div>
        <!-- 右侧已选项 -->
        <div class="yxtbiz-selector__right">
          <div class="yxtbiz-checked_list">
            <div class="yxtbiz-checked_list__select">
              <!-- 已选择 -->
              {{ $t('pc_biz_ote_lbl_chosenquestions' /*已选择*/) }}:&nbsp;&nbsp;{{ list && list.length }} / {{limit}}
            </div>
            <div class="yxtbiz-checked_list__box">
              <yxt-scrollbar :fit-height="true" class="yxtbiz-checked_list__scrollbar">
                <div v-for="(item, index) in list" :key="item.id" class="yxtbiz-checked_list__item">
                  <yxt-tag disable-transitions size="small" type="info" closable @close="deleteItem(item, index)">
                    <yxt-tooltip :content="item.name" open-filter>
                      <span class="yxtbiz-checked_list__item--tag">{{ item.name }} </span>
                    </yxt-tooltip>
                  </yxt-tag>
                </div>
              </yxt-scrollbar>
            </div>
            <div class="yxtbiz-checked_list__operation">
              <!-- 清除所有 -->
              <yxt-button class="font-size-12 color-gray-9 hover-primary-6" type="text" :disabled="list && list.length === 0" @click="clear">
                {{ $t('pc_biz_shoptour_clear_all' /*清除所有*/ ) }}
              </yxt-button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { dateFormat } from './util';
import { getSceneLevel, getProjectsReport, getProjectsBloc, getProjects4Sp } from './service';

export default{
  name: 'YxtbizProjectSelector',
  props: {
    value: {
      type: Array,
      default: () => {return [];}
    },
    limit: {
      type: Number,
      default: 10000
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    groupSelect: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String,
      default: ''
    },
    orgId: {
      type: String,
      default: ''
    },
    principalAuthCode: {
      type: String,
      default: ''
    },
    fromType: {
      type: String,
      default: 'report' // report 报表用 sp 奇点用
    },
    enableCreate: {
      type: Boolean,
      default: false
    },
    sourceType: {
      type: Number,
      default: 0
    },
    skillModelId: {
      type: String,
      default: ''
    },
    taskSkillModelId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      page: 1,
      tableHeight: 0,
      dataRangeVal: ['', ''], // 初始时间默认值
      createRangeVal: ['', ''],
      endRangeVal: ['', ''],
      selectCategory: null,
      filterText: '',
      // 下拉菜单 模式
      selectTypeArr: [
        { type: 0, name: this.$t('pc_biz_lbl_startEndTime') /** 起止时间模式 */ },
        { type: 2, name: this.$t('pc_biz_lbl_cycleMode') /** 周期模式 */ }
      ],
      // 包含档案导入
      selectImpArr: [
        { type: 0, name: this.$t('pc_biz_ote_lbl_no') /** 否 */ },
        { type: 1, name: this.$t('pc_biz_ote_lbl_yes') /** 是 */ }
      ],
      projectSearch: {
        authCode: (this.groupSelect && this.fromType !== 'sp') ? 'data_manage_platform' : this.dataPermissionCode, // dataPermissionCode
        name: '',
        navCode: (this.groupSelect && this.fromType !== 'sp') ? 'group_data_center' : this.functionCode,
        orgId: this.orgId,
        mainOrgId: '', // 集团版指定要查询的机构
        principalAuthCode: (this.groupSelect || this.fromType === 'sp') ? '' : (this.principalAuthCode || 'task_view_managed_project'), // 负责项目权限
        releaseEndTime: '', // 结束时间
        releaseStartTime: '', // 开始时间
        sceneId: 0,
        size: 50, // 每页数量
        page: 1, // 页码
        sort: 'DESC',
        dataPermCode: this.dataPermissionCode,
        startTime: '',
        endTime: '',
        status: 0,
        timeType: '',
        includeArchive: 0,
        userId: this.userId
      },
      projectCategoryData: [],
      drawerVisible: false,
      loaded: 1,
      loading: false,
      totalPage: 0,
      count: 0,
      projectList: [],
      selected: {},
      selectDraw: false,
      statusName: '', // 项目状态
      typeName: '', // 项目模式
      includeImport: this.$t('pc_biz_ote_lbl_no'), // 包含档案导入
      moreSearch: false, // 更多筛选
      selectAllBtnLoading: false,
      range: 1,
      rangeOptions: [
        {
          label: this.$t('pc_biz_project_selector_range1')/* 我负责的 */,
          value: 1
        },
        {
          label: this.$t('pc_biz_project_selector_range2')/* 我创建的 */,
          value: 2
        },
        {
          label: this.$t('pc_biz_project_selector_range3')/* 我管辖的 */,
          value: 3
        }
      ],
      createPage: null
    };
  },

  watch: {
    value(val) {
      this.onValueChanged(val);
    }
  },

  computed: {
    list() {
      return this.selected && Object.values(this.selected);
    },
    // 项目状态选项
    selectStatusArr() {
      const arr = [
        { type: 2, name: this.$t('pc_biz_global_processing') /* 进行中 */ },
        { type: 3, name: this.$t('pc_biz_global_over') /* 已结束 */ }
      ];
      if (this.fromType !== 'sp') {
        arr.push({ type: 4, name: this.$t('pc_biz_ote_lbl_status5') /* 已归档 */ });
      } else {
        arr.push({ type: 1, name: this.$t('pc_biz_global_unpublished') /* 未发布 */ });
      }
      return arr;
    }
  },

  created() {
    this.open();
    // TODO 优化
    if (this.fromType === 'report') {
      this.$nextTick(()=>{
        const reportSelectDom = document.getElementById('reportSelector');
        reportSelectDom && reportSelectDom.addEventListener('click', (e) => {
          e.stopPropagation();
          e.preventDefault();
          if (e.target.className.indexOf('yxt-range-input') > -1) {
            this.selectDraw = true;
          }
        });
      });
    }
    this.getTableHeight();
  },

  mounted() {
    // 挂载window.onresize事件(动态设置table高度)
    let _this = this;
    window.onresize = () => {
      if (_this.resizeFlag) {
        clearTimeout(_this.resizeFlag);
      }
      _this.resizeFlag = setTimeout(() => {
        _this.getTableHeight();
        _this.resizeFlag = null;
      }, 100);
    };
    this.addCreateListener();
  },
  beforeDestroy() {
    this.removeCreateListener();
  },

  methods: {
    // 项目状态
    onStausSelect($event) {
      this.projectSearch.status = this.selectStatusArr.filter(item => item.name === $event).length ? this.selectStatusArr.filter(item => item.name === $event)[0].type : 0;
      this.getProjectList();
    },

    // 项目模式
    onTypeSelect($event) {
      let timeType = this.selectTypeArr.filter(item=>item.name === $event).length ? this.selectTypeArr.filter(item=>item.name === $event)[0].type : '';
      this.projectSearch.timeType = timeType === 2 ? 1 : timeType;
      this.getProjectList();
    },

    // 包含档案导入
    onImpSelect($event) {
      let includeArchive = this.selectImpArr.filter(item=>item.name === $event).length ? this.selectImpArr.filter(item=>item.name === $event)[0].type : 0;
      this.projectSearch.includeArchive = includeArchive === 1 ? 1 : includeArchive;
      this.getProjectList();
    },

    // 切换查询平台
    orgIdchange(orgInfo) {
      this.projectSearch.mainOrgId = orgInfo.orgId;
      this.selectCategory = null;
      this.filterText = '';
      this.getProjectCategory();
      this.getProjectList(true); // 重新重置请求数据
    },

    // 重新打开的时候初始化 选择内容
    open() {
      this.typeName = '';
      this.includeImport = this.$t('pc_biz_ote_lbl_no');
      this.statusName = '';
      this.dataRangeVal = ['', ''];
      this.createRangeVal = ['', ''];
      this.endRangeVal = ['', ''];
      this.projectSearch.name = '';
      this.selectCategory = null;
      this.filterText = '';
      this.formatModel(this.value);
      this.range = 1;
      !this.groupSelect && this.getProjectList(true);
      this.drawerVisible = true;
      this.selectDraw = false;
      this.projectSearch.releaseStartTime = '';
      this.projectSearch.releaseEndTime = '';
      this.projectSearch.timeType = '';
      this.projectSearch.status = 0;
      this.getProjectCategory();
      this.getProjectList(true);
    },

    hide() {
      this.drawerVisible = false;
      this.selectDraw = false;
    },

    submit() {
      const isLimited = this.list.length > this.limit;
      if (isLimited) {
        this.$message.warning(this.$t('pc_biz_msg_projectcountlimit', [this.limit]));
        return;
      }
      this.outPut('1');
      this.hide();
    },

    // 项目状态
    getStatusName(status) {
      let sName = '';
      switch (status) {
        case 1:
          sName = this.$t('pc_biz_global_unpublished'); // 未发布
          break;
        case 2:
          sName = this.$t('pc_biz_global_processing'); // 进行中
          break;
        case 3:
          sName = this.$t('pc_biz_global_over'); // 已结束
          break;
        case 4:
          sName = this.$t('pc_biz_ote_lbl_status5'); // 已归档
          break;
        case 5:
          sName = this.$t('pc_biz_ls_deleted'); // 已删除
          break;
        case 6:
          sName = this.$t('pc_biz_enroll_lbl_projectstop'); // 已暂停
          break;
        case 7:
          sName = this.$t('pc_biz_lbl_withdrawn'); // 已撤回
          break;
        default:
          break;
      }
      return sName;
    },

    shortDate(format = 'yyyy-MM-dd') {
      return (row, col, val) => {
        if (!val) return '--';
        if (typeof val === 'string') {
          val = val.replace(/\//g, '-');
        }
        return dateFormat(new Date(val), format);
      };
    },

    // 请求项目表格
    getProjectList(isReset) {
      this.moreSearch = true;
      if (isReset) {
        this.page = 1;
        this.projectSearch.page = 1;
      }

      this.projectSearch.name = this.projectSearch.name.trim();
      this.projectSearch.sceneId = this.selectCategory ? this.selectCategory.id : 0;
      this.loading = true;
      let projectUrl;
      if (this.fromType === 'sp') {
        // 奇点
        this.projectSearch.range = this.range;
        projectUrl = getProjects4Sp(this.projectSearch, {
          params: {
            offset: (this.projectSearch.page - 1) * 20,
            limit: 20
          }
        });
      } else {
        // 报表
        projectUrl = this.groupSelect ? getProjectsBloc(this.projectSearch) : getProjectsReport(this.projectSearch);
      }
      projectUrl.then((result) => {
        this.projectList = result.datas;
        this.totalPage = result.paging.pages;
        this.count = result.paging.count;
        this.loaded--;
        this.loading = false;
        this.$nextTick(() => {
          this.setChecked();
        });
      })
        .catch(this.handleError.bind(this))
        .finally(() => {
          this.moreSearch = false;
        });
    },

    // 请求项目分类
    getProjectCategory() {
      getSceneLevel({orgId: this.projectSearch.mainOrgId}).then((result) => {
        this.projectCategoryData = result;
      })
        .catch(this.handleError.bind(this))
        .finally(() => {
          this.moreSearch = false;
        });
    },

    // 对树节点进行筛选时执行的方法，返回 true 表示这个节点可以显示，返回 false 则表示这个节点会被隐藏
    filterProjectNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 项目分类树搜索
    handleSearchCategory(value) {
      this.$refs.tree.filter(value);
    },

    // 项目分类树点击事件
    handleSelectCategory(e) {
      this.selectCategory = e;
      this.$refs.categorySelect.blur();
      this.getProjectList();
    },

    // 项目分类树清除事件
    handleCategoryClear() {
      this.selectCategory = null;
      this.projectSearch.sceneId = 0;
      this.$refs.tree.setCurrentKey();
      this.filterText = '';
      this.$refs.tree.filter();
      this.getProjectList();
    },

    // 修改时间查询
    onChangeDate(dateRange) {
      if (dateRange) {
        this.dataRangeVal = [dateFormat(dateRange[0], 'yyyy-MM-dd'), dateFormat(dateRange[1], 'yyyy-MM-dd')];
        this.projectSearch.releaseStartTime = this.dataRangeVal[0] + ' 00:00:00';
        this.projectSearch.releaseEndTime = this.dataRangeVal[1] + ' 23:59:59';
      } else {
        this.projectSearch.releaseStartTime = '';
        this.projectSearch.releaseEndTime = '';
      }
      this.getProjectList();
    },

    // 修改项目创建查询
    onChangeCreateDate(dateRange) {
      if (dateRange) {
        this.createRangeVal = [dateFormat(dateRange[0], 'yyyy-MM-dd'), dateFormat(dateRange[1], 'yyyy-MM-dd')];
        this.projectSearch.startStartTime = this.createRangeVal[0] + ' 00:00:00';
        this.projectSearch.endStartTime = this.createRangeVal[1] + ' 23:59:59';
      } else {
        this.projectSearch.startStartTime = '';
        this.projectSearch.endStartTime = '';
      }
      this.getProjectList();
    },
    // 项目结束时间
    onChangeEndDate(dateRange) {
      if (dateRange) {
        this.endRangeVal = [dateFormat(dateRange[0], 'yyyy-MM-dd'), dateFormat(dateRange[1], 'yyyy-MM-dd')];
        this.projectSearch.startEndTime = this.endRangeVal[0] + ' 00:00:00';
        this.projectSearch.endEndTime = this.endRangeVal[1] + ' 23:59:59';
      } else {
        this.projectSearch.startEndTime = '';
        this.projectSearch.endEndTime = '';
      }
      this.getProjectList();
    },
    // 输入框搜索项目
    projectNameSearch() {
      this.getProjectList(true);
    },

    sortChange(sort) {
      this.projectSearch.sort = sort.order;
      this.getProjectList();
      return false;
    },

    // 切换页码
    pageChange(page) {
      this.page = page;
      this.projectSearch.page = page;
      this.getProjectList();
    },

    // 选择某个
    select(item, row) {
      const isAdd = item.some(v => {
        return v.id === row.id;
      });
      if (!isAdd) {
        delete this.selected[row.id];
      } else {
        const isLimited = Object.keys(this.selected).length + 1 > this.limit;
        // 判断：如果超出最大数量则不能添加
        if (isLimited) {
          this.$message.warning(this.$t('pc_biz_msg_projectcountlimit', [this.limit]));
          let del_row = item.pop();
          this.$refs.refTable.toggleRowSelection(del_row, false);
          return false;
        }
        this.selected[row.id] = row;
      }
      this.$forceUpdate();
      this.outPut();
    },

    // 全选
    selectAll(data) {
      const isAdd = data.length !== 0;
      if (isAdd) {
        // 添加项目操作
        let number = this.limit - Object.keys(this.selected).length;
        let dataSpareInfo = [];
        data.map(item=>{
          if (!Object.keys(this.selected).includes(item.id)) {
            dataSpareInfo.push(item);
          }
        });
        // 不能用data.length,因为一页里面有俩个未选的情况，再点全选也会报错
        if (number < dataSpareInfo.length) {
          this.$message.warning(this.$t('pc_biz_msg_projectcountlimit', [this.limit]));
          this.projectList.forEach(obj => {
            this.$nextTick(()=>{
              if (!Object.keys(this.selected).includes(obj.id)) {
                this.$refs.refTable.toggleRowSelection(obj, false);
              }
            });
          });
        } else {
          this.projectList.forEach(obj => {
            this.selected[obj.id] = obj;
          });
        }
      } else {
        // 删项目操作
        this.projectList.forEach(obj => {
          delete this.selected[obj.id];
        });
      }
      this.$forceUpdate();
      this.outPut();
    },

    outPut(input = '') {
      const selArray = Object.values(this.selected);
      this.$emit('projectInfo', selArray);
      // input && this.handleToInput(selArray);
      this.onValueChanged(selArray);
    },

    onValueChanged(val) {
      this.formatModel(val);
    },

    formatModel(v) {
      this.selected = {};
      v.forEach(obj => {
        this.selected[obj.id] = obj;
      });
    },

    // 删除已选择
    deleteItem(item) {
      let row = item;
      for (let index = 0; index < this.projectList.length; index++) {
        const element = this.projectList[index];
        if (element.id === item.id) {
          row = element;
          break;
        }
      }
      if (row) {
        this.$refs.refTable.toggleRowSelection(row, false);
      }
      delete this.selected[item.id];
      this.$forceUpdate();
      this.outPut();
    },

    // 清空
    clear() {
      let row = null;
      for (const key in this.selected) {
        if (Object.prototype.hasOwnProperty.call(this.selected, key)) {
          for (let index = 0; index < this.projectList.length; index++) {
            const element = this.projectList[index];
            if (element.id === key) {
              row = element;
              break;
            }
          }
          if (row) {
            this.$refs.refTable && this.$refs.refTable.toggleRowSelection(row, false);
          }
        }
      }
      this.selected = {};
      this.outPut();
    },

    setChecked() {
      this.$nextTick(()=>{
        this.projectList.forEach(obj => {
          if (Object.prototype.hasOwnProperty.call(this.selected, obj.id)) {
            this.$refs.refTable && this.$refs.refTable.toggleRowSelection(obj, true);
          } else {
            this.$refs.refTable && this.$refs.refTable.toggleRowSelection(obj, false);
          }
        });
      });
    },

    resetAll() {
      this.projectList = [];
      this.selected = {};
      this.handleToInput([]);
    },

    beforeClose(done) {
      done(this.list.map(item => item.id).join() !== this.value.map(item => item.id).join());
    },

    handleError(error) {
      error = error.error;
      let msg = error.message;
      this.$message({
        message: msg,
        type: 'error'
      });
    },

    isUndefined(val) {
      return val === undefined ? '' : val;
    },

    getFiteredData(list, originList) {
      let number = this.limit - Object.keys(originList).length;
      let count = 0;
      let checkedList = JSON.parse(JSON.stringify(originList));
      if (number) {
        list.forEach((i, index)=>{
          if (number > count) {
            if (!Object.keys(originList).includes(i.id)) {
              checkedList[i.id] = i;
              count++;
            }
          }
        });
      } else {
        this.$message.warning(this.$t('pc_biz_msg_projectcountlimit', [this.limit]));
      }
      return checkedList;
    },

    // 将selected知识设为true
    selectedTrue(data, refTable) {
      const ids = (this.list || []).map(item => item.id);
      data.forEach(kng => {
        if (ids.includes(kng.id) || ids.includes(kng.kngId)) {
          this.$nextTick(() => {
            refTable.toggleRowSelection(kng, true);
          });
        }
      });
    },

    // 全部筛选结果
    handleSelectAll() {
      this.selectAllBtnLoading = true;
      let params = JSON.parse(JSON.stringify(this.projectSearch));
      params.size = this.limit > 0 ? this.limit : this.count;
      params.page = 1;
      let request;
      if (this.fromType === 'sp') {
        params.range = this.range;
        request = getProjects4Sp(params, {
          params: {
            limit: params.size,
            offset: 0
          }
        });
      } else {
        request = this.groupSelect ? getProjectsBloc(params) : getProjectsReport(params);
      }
      request.then(res => {
        this.selected = this.getFiteredData(res.datas, this.selected);
        this.outPut();
        this.$nextTick(() => this.selectedTrue(this.projectList, this.$refs.refTable));
      }).finally(() =>{
        this.selectAllBtnLoading = false;
      });
    },

    // 计算table高度(动态设置table高度)
    getTableHeight() {
      let tableH = 0; // 距离页面下方的高度
      let tableHeightDetil = window.innerHeight - tableH;
      if (tableHeightDetil <= 300) {
        this.tableHeight = 300;
      } else {
        this.tableHeight = window.innerHeight - tableH;
      }
    },
    addCreateListener() {
      if (!this.enableCreate) {
        return;
      }
      window.addEventListener('message', this.afterProjectCreated);
    },
    removeCreateListener() {
      if (!this.enableCreate) {
        return;
      }
      window.removeEventListener('message', this.afterProjectCreated);
    },
    createProjectHandler() {
      let targetUrl = `${window.location.protocol}//${window.location.host}/o2o/#/createproject`;
      let query = [];
      if (this.sourceType) {
        query.push({
          name: 'sourceType',
          value: this.sourceType
        });
      }
      if (this.skillModelId) {
        query.push({
          name: 'skillModelId',
          value: this.skillModelId
        });
      }
      if (this.taskSkillModelId) {
        query.push({
          name: 'taskSkillModelId',
          value: this.taskSkillModelId
        });
      }
      if (query.length) {
        targetUrl += '?';
        query.forEach((v, i) => {
          targetUrl += `${v.name}=${v.value}`;
          if (i < query.length - 1) {
            targetUrl += '&';
          }
        });
      }
      this.createPage = window.open(targetUrl);
    },
    afterProjectCreated(e) {
      if (!this.enableCreate) {
        return;
      }
      const msg = e && e.data;
      if (!msg || msg.name !== 'projectCreated') {
        return;
      }
      const { projectId, projectName } = msg;
      const isLimited = Object.keys(this.selected).length + 1 > this.limit;
      // 判断：如果超出最大数量则不能添加
      if (isLimited) {
        this.$message.warning(this.$t('pc_biz_msg_projectcountlimit', [this.limit]));
        return;
      }
      this.$set(this.selected, projectId, {
        id: projectId,
        name: projectName
      });
      this.outPut();
      this.getProjectList();
      if (this.createPage) {
        this.createPage.close();
        this.createPage = null;
      }
    }
  }
};
</script>
<style lang='scss' scoped>
// @import '@/components/Selector/selector';
// @import '../style';
// 筛选更多 弹窗style
.yxtbiz-choose-project{
  height: 100%;
}

.pr10 {
  padding-right: 10px;
}

.more-select {
  //position: absolute;
  //z-index: 2010;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  //width: 98%;
  //height: auto;
  //padding-top: 20px;
  background: #fff;
  // box-shadow: 0 4px 24px 0 rgb(0 0 0 / 12%);

  .draw-label {
    width: 100px;
    text-align: right;
  }

  .w330 {
    width: 330px !important;
  }

  .mr14 {
    margin-right: 14px;
  }

  & > div:nth-child(odd) {
    text-align: right;
  }

  & > div {
    flex: 1;
    width: 47%;
    min-width: 47%;
  }
}

.mb4 {
  margin-bottom: 4px;
}

.sign-flex-style {
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .sign-text-style {
    flex-shrink: 0;
    height: 22px;
    margin-left: 8px;
    padding: 0 6px;
    color: #736251;
    font-weight: 400;
    font-size: 12px;
    background: linear-gradient(90deg, #efd8b9 0%, #d7ad85 100%);
    border-radius: 2px;
  }
}

.sign-flex-style-center{
  justify-content: center;
}

.yxtbiz-checked_list__scrollbar ::v-deep .yxt-scrollbar__wrap {
  overflow: auto !important;
  height: 100% !important;
}
</style>
