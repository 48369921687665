import Vue from 'vue';
import Vuex from 'vuex';
import Yxt from 'yxt-pc';
import navManageStore from 'yxt-biz-pc/packages/nav-manage-store';
import setTheme from './theme';
import checkActionPermission from './storeHelper';
import { setLanguage, i18n } from './changeLangs';
import { preCheckFunctions, checkTimeOutFnc, refreshFactors } from './getFactor';
import setCustomScript from './custom-script';
// ！！！勿删checkSafeFunction
import { checkSafeFunction } from './safeCheckUtil';
checkSafeFunction();
// 设置国际化
const setVueLanguage = (options = {}) => {
  if (options.language === false) return true;
  let languageOptions = Object.assign({
    modules: [options.appName],
    appTypes: ['pc']
  }, options.language);
  return setLanguage(languageOptions).then(() => {
    if (languageOptions.callBack && typeof languageOptions.callBack === 'function') {
      return languageOptions.callBack(i18n);
    }
  });
};

// 设置换肤
const setVueTheme = (options = {}, vueOptions = {}) => {
  let themeOptions = Object.assign({
    type: 0,
    studentFlag: 'student',
    version: ''
  }, options.theme || {});
  return setTheme(themeOptions.type, themeOptions.version).then((result) => {
    // 即使设置为false，也必须请求方法，用于设置站点icon
    if (options.theme === false) return result;
    vueOptions && vueOptions.router && typeof vueOptions.router.afterEach === 'function' &&
      vueOptions.router.afterEach((route) => {
        route && route.meta && chanageVueTheme(themeOptions.studentFlag, route.meta.type === 1);
      });
    // 获取信息后，重设基础组件主色调
    Vue.prototype.$changeTheme && Vue.prototype.$changeTheme(window.localStorage.theme);
  });
};

const chanageVueTheme = (studentFlag, show) => {
  if (show) {
    !window.document.querySelector(`body[${studentFlag}]`) && window.document.body.setAttribute(studentFlag, '');
  } else {
    window.document.querySelector(`body[${studentFlag}]`) && window.document.body.removeAttribute(studentFlag);
  }
};

// 获取默认Vue Mixins
const getMixins = () => {
  const u = navigator.userAgent.toLowerCase();
  const r = /opera|opr\/[\d]+/.test(u); // isOpera
  const isIE = !r && /(msie|trident)/.test(u); // isIE
  // IE11兼容
  const IE11RouterFix = {
    methods: {
      hashChangeHandler() {
        let currentPath = window.location.hash.slice(1);
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath);
        }
      }
    },
    mounted() { if (isIE) { window.addEventListener('hashchange', this.hashChangeHandler); } },
    destroyed() { if (isIE) { window.removeEventListener('hashchange', this.hashChangeHandler); } }
  };
  return [IE11RouterFix];
};
// 默认Vue Mixins
const defaultMixins = getMixins();

// 获取配置参数，并预设默认值
const getOptions = (options) => {
  let pluginOptions = options.options || {};
  pluginOptions.source = pluginOptions.source || 501;
  pluginOptions.env = pluginOptions.env ||
    (pluginOptions.domain && pluginOptions.domain.environment) ||
    (window.feConfig && window.feConfig.apiEnv) || 'dev';
  pluginOptions.domain = pluginOptions.domain || (window.feConfig && window.feConfig.common);
  let vueOptions = { ...options };
  delete vueOptions.options;
  vueOptions.mixins = (vueOptions.mixins || []).concat(defaultMixins);
  vueOptions.i18n = i18n;

  return { pluginOptions, vueOptions };
};

// 初始化YXT插件（项目依赖项）
const initPlugs = (pluginOptions, vueOptions) => {
  return window.Promise.all([
    setVueTheme(pluginOptions, vueOptions),
    setVueLanguage(pluginOptions),
    preCheckFunctions(pluginOptions.factor),
    setCustomScript()
  ]);
};
// 初始化VUE组件
const initComponents = (pluginOptions, vueOptions) => {
  Vue.prototype.checkActionPermission = checkActionPermission;
  Vue.$checkTimeOutFnc = Vue.prototype.$checkTimeOutFnc = checkTimeOutFnc;
  Vue.$refreshFactors = Vue.prototype.$refreshFactors = refreshFactors;
  Vue.config.productionTip = false;
  Vue.use(Yxt, { theme: window.localStorage.theme });
  if (vueOptions.store && typeof vueOptions.store.registerModule === 'function' && typeof vueOptions.store.hasModule === 'function') {
    !vueOptions.store.hasModule('navManageStore') && vueOptions.store.registerModule('navManageStore', navManageStore);
    !vueOptions.store.hasModule('IMchat') && vueOptions.store.registerModule('IMchat', window.ImChat || {});
  } else {
    Vue.use(Vuex);
    vueOptions.store = new Vuex.Store({
      modules: {
        navManageStore,
        IMchat: window.ImChat || {}
      }
    });
  }
};
/**
 * 入参：{options, router, store, render, el, ...othenVueOptions}
 * 其中： options = {
    appName: 'core', // 应用名称
    source: 501, // source默认为501 ，可以不传
    domain: process.env.API_CONFIG, // API domains
    theme: { // 皮肤设置：值可以为对象，也可以为false，如果为false则不使用皮肤设置
      type: 2, // 0：pc前台+pc后台 1：pc前台 2:pc后台
      version: ''
    },
    language: { // 国际化相关设置：值可以为对象，也可以为false，如果为false则不使用国际化
      modules: ['core', 'score'],
      appTypes: ['pc'],
      callBack (i18n) { // 国际化设置成功的回调，可以进行兜底操作
        if (process.env.NODE_ENV !== 'development') return
        i18n.fallbackLocale = 'local'
        i18n.fallbackRoot = true
        i18n.mergeLocaleMessage('local', require('@/configs/language/zh-CN.map.js'))
      }
    }
  }
 * 返回： Promess 对象 ，成功会返回 { vue, plugins: { i18n, Yxt, Yxtbiz, store: vueOptions.store } }; VUE实例，及plugins
 * **/
export const YxtInit = (options, hooks) => {
  let { pluginOptions, vueOptions } = getOptions(options);
  initComponents(pluginOptions, vueOptions);
  return initPlugs(pluginOptions, vueOptions)
    .then(async() => {
      if (hooks && typeof hooks.beforeRun === 'function') {
        await hooks.beforeRun();
      }
    })
    .then(() => {
      const vue = new Vue(vueOptions);
      return { vue, plugins: { i18n, store: vueOptions.store } };
    }).catch((e) => {
      console.error(e);
      throw new Error('New YxtVue Error: Init Error');
    });
};

export default YxtInit;
