// import UserName from '../open-data/src/userName';

// /* istanbul ignore next */
// UserName.install = function(Vue) {
//   Vue.component(UserName.name, UserName);
// };

// export default UserName;

// import yxtOpenData from 'yxt-open-data';

// const { UserName } = yxtOpenData;
import { UserName } from 'yxt-open-data/es';
export default UserName;
