import { getOpenData } from 'yxt-open-data/es';
import { genIsMixin, isFunPointEnableShow } from './mixins/is';
import { genStrMixin } from './mixins/string';
import { addResizeListener, removeResizeListener } from 'yxt-biz-pc/src/utils/resize-event';
import dateUtil from 'yxt-biz-pc/src/utils/date';
import {i18n} from './changeLangs';

const getQueryString = name => {
  try {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    r = window.location.hash
      .substr(window.location.hash.indexOf('?') + 1)
      .match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const isIE = () => {
  if (window.navigator.userAgent.indexOf('MSIE') >= 1) {
    return true;
  }
  return !!window.ActiveXObject || 'ActiveXObject' in window;
};

const downloadURL = (url, name = '') => {
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  if ('download' in document.createElement('a')) {
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    // 对不支持download进行兼容
    click(link, (link.target = '_blank'));
  }
};

const click = (node) => {
  try {
    node.dispatchEvent(new MouseEvent('click'));
  } catch (e) {
    const evt = document.createEvent('MouseEvents');
    console.log(evt);
    evt.initMouseEvent(
      'click',
      true,
      true,
      window,
      0,
      0,
      0,
      80,
      20,
      false,
      false,
      false,
      false,
      0,
      null
    );
    node.dispatchEvent(evt);
  }
};

/**
 * 通过blob-网络请求去重新处理文件，主要目的是为了处理IE的文件名乱码问题
 * 目前针对大文件会存在慢的情况不处理，由业务自定义fileName
 * @param {*} name
 * @param {*} url
 * @returns
 */
const downloadFileIE = (name, url) => {
  const handlerArr = ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'ico', 'xls', 'xlsx', 'pdf', 'ppt', 'pptx', 'pps', 'doc', 'docx'];
  const nameStr = name.split('.')[1] && name.split('.')[1].toLowerCase();

  if (!isIE() || !handlerArr.includes(nameStr)) {
    return window.open(url);
  }

  const xhr = new XMLHttpRequest();
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.onload = function() {
    if (xhr.status === 200) {
      const resp = xhr.response;
      const blob = resp.blob ? resp.blob() : new Blob([resp]);
      if ('msSaveOrOpenBlob' in navigator) {
        window.navigator.msSaveOrOpenBlob(blob, name);
      } else {
        const obj = URL.createObjectURL(blob);
        downloadURL(obj, name);
        URL.revokeObjectURL(obj);
      }
    } else {
      console.error('Download failed');
    }
  };

  xhr.onerror = function() {
    console.error('Download failed');
  };
  xhr.send();
};

/**
 * 获取 格式化时间
 * @param {Object} {startTime: String, endTime: String}
 * @returns
 */
const getDateTimeFormatted = ({startTime, endTime} = {}) => {
  const isThisYear = date => new Date().getFullYear() === date.getFullYear();
  const isThisDay = date => {
    const current = new Date();
    return current.getDate() === date.getDate() && current.getMonth() === date.getMonth() && current.getFullYear() === date.getFullYear();
  };
  const formatSingleDateTime = (date, type) => {
    const isToday = isThisDay(date);
    const format = isToday
      ? 'HH:mm'
      : isThisYear(date)
        ? 'MM-dd HH:mm'
        : 'yyyy-MM-dd HH:mm';
    const timeString = dateUtil.format(date, format);
    if (isToday && type === 'start') return i18n.t('pc_biz_today_start' /* 今天 {0} 开始 */, [timeString]);
    if (!isToday && type === 'start') return i18n.t('pc_biz_start_time' /* {0} 开始 */, [timeString]);
    if (isToday && type === 'end') return i18n.t('pc_biz_today_end' /* 今天 {0} 截止 */, [timeString]);
    if (!isToday && type === 'end') return i18n.t('pc_biz_end_time' /* {0} 截止 */, [timeString]);
  };
  const formatDateTimeRange = (startTime, endTime) => {
    const startTimeIsThisYear = isThisYear(startTime);
    const endTimeIsThisYear = isThisYear(endTime);
    const format = startTimeIsThisYear && endTimeIsThisYear ? 'MM-dd HH:mm' : 'yyyy-MM-dd HH:mm';
    return `${dateUtil.format(startTime, format)} ~ ${dateUtil.format(endTime, format)}`;
  };

  if (!startTime && !endTime) return i18n.t('pc_biz_lbl_nolimittime' /* 不限时间 */);
  if (startTime) startTime = new Date(startTime.replace(/-/g, '/')); // 兼容safari
  if (endTime) endTime = new Date(endTime.replace(/-/g, '/'));
  const onlyStartTime = startTime && !endTime;
  const onlyEndTime = !startTime && endTime;
  if (onlyStartTime) return formatSingleDateTime(startTime, 'start');
  if (onlyEndTime) return formatSingleDateTime(endTime, 'end');
  return formatDateTimeRange(startTime, endTime);
};

export default {
  getQueryString,
  getOpenData,
  getUserToken() {
    return localStorage.getItem('token');
  },
  downloadFileIE,
  isIE,
  genIsMixin,
  isFunPointEnableShow,
  genStrMixin,
  addResizeListener,
  removeResizeListener,
  getDateTimeFormatted
};
