import { apiBaseUrl, utilityApi, o2oApi } from 'yxt-biz-pc/packages/api';
import { fetchEventSource } from './ai-tools/fetch'; // 流式API

// 根据key获取翻译
export const getGlobalLangTrans = (langKey) => {
  return o2oApi.get(`global/langTrans?langKey=${langKey}&iso2o=1`);
};

// 生成随机数
export function getUid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = (Math.random() * 16) | 0;
    let v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// 生成随机数
export function getAskReqId() {
  return apiBaseUrl.get('/aiassist/stu/chat/snowflowId', {});
}
/**
 * @description 提问历史查询
 * @author （zengya）
 * @date 2023/07/03 15:20:52
 * @param {*} data
 * @return {*}
 */
export const questionHistory = (data) => {
  return apiBaseUrl.post('/aiassist/stu/chat/chat_history', data);
};
/**
 * @description 提问AI
 * @author （zengya）
 * @date 2023/07/03 15:20:34
 * @param {*} data
 * @return {*}
 */
export const submitQuestion = (data, SourceEvent) => {
  return fetchEventSource(`${window.feConfig.common.apiBaseUrl}aiassist/event_stream/stu/ask_question`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Token': window.localStorage.getItem('token') || '',
      'Source': 501,
      'Yxt-Orgdomain': window.location.host
    },
    body: JSON.stringify(data),
    onmessage: SourceEvent.onmessage,
    onclose: SourceEvent.onclose,
    onerror: SourceEvent.onerror,
    openWhenHidden: true
  });
};
/**
 * @description 回答纠错
 * @author （zengya）
 * @date 2023/07/03 15:20:08
 * @param {*} data
 * @return {*}
 */
export const submitQuestionFeedback = (data) => {
  return apiBaseUrl.post('/aiassist/stu/chat/chat_answer_correct', data);
};
/**
 * @description 回答很棒/回答糟糕
 * @author （zengya）
 * @date 2023/07/03 15:18:34
 * @param {*} data
 * @return {*}
 */
export const submitQuestionLike = (data) => {
  return apiBaseUrl.put(`/aiassist/stu/chat/chat_answer_like?chatContentId=${data.chatContentId}&likeAnswer=${data.likeAnswer}`, {});
};
/**
 * @description 清空提问历史
 * @author （zengya）
 * @date 2023/07/03 15:19:32
 * @return {*}
 */
export const clearHistory = () => {
  return apiBaseUrl.post('/aiassist/stu/chat/reset_chat_history');
};
/**
 * @description 获取助手配置信息
 * @author （zengya）
 * @date 2023/07/03 15:19:50
 * @param {*} data
 * @return {*}
 */
export const getChatSetting = (data) => {
  return apiBaseUrl.get('/aiassist/stu/chat/org_setting', data);
};
/**
 * @description 获取分享链接地址
 * @author （zengya）
 * @date 2023/07/03 15:19:50
 * @param {*} data
 * @return {*}
 */
export const getShareUrl = () => {
  return apiBaseUrl.get('/aiassist/stu/chat/org_chat_share_url', {});
};
/**
 * @description 获取提问热词
 * @author （zengya）
 * @date 2023/09/01 11:43:51
 * @return {*}
 */
export const getWeekHot = () => {
  return apiBaseUrl.get('/aiassist/statistic/week_hot', {});
};

/**
 * @description 停止回答
 * @author （zengya）
 * @date 2023/09/01 11:43:51
 * @return {*}
 */
export const stopQuestion = (data) => {
  return apiBaseUrl.post('/aiassist/stu/chat/stop_question', data);
};
/**
 * @description
 * @author （zengya）
 * @date 2023/09/26 09:35:25
 */
export const todayIsVisit = () => {
  return apiBaseUrl.get('/aiassist/stu/chat/todayIsVisit', {});
};

export const getStaticBaseUrl = () => {
  if (window.feConfig && window.feConfig.common && window.feConfig.common.staticBaseUrl) {
    return window.feConfig.common.staticBaseUrl;
  }
  return 'https://stc.yxt.com/';
};

export function throttle(func, delay) {
  let task = null;
  return function() {
    let args = arguments;
    if (!task) {
      task = setTimeout(() => {
        task = null;
        func.apply(this, args);
      }, delay);
    }
  };
}

export const getShortcutInfo = () => {
  return apiBaseUrl.get('/aiassist/instruct/shortcut_instruction');
};

export const postTreasureList = (body) => {
  return apiBaseUrl.post('/aiassist/treasure/list', body);
};

export const postTreasureSubscribe = (body) => {
  return apiBaseUrl.post('/aiassist/treasure/subscribe', body);
};

// 获取窗口列表
export const getAIChartList = () => {
  return apiBaseUrl.post('/aiassist/multiple/window/list');
};

// 切换窗口
export const switchChat = (params) => {
  return apiBaseUrl.put('/aiassist/multiple/window/switch', params);
};

// 修改窗口名称
export const updateChatName = (params) => {
  return apiBaseUrl.put('/aiassist/multiple/window/update', params);
};

// 删除窗口
export const deleteChat = (params) => {
  return apiBaseUrl.post('/aiassist/multiple/window/remove', params);
};

/**
 * 获取指令示例
 * @param {Object} params
 * @returns
 */
export const getInstructionExamples = params =>apiBaseUrl.get('/aiassist/instruct/call/param', {params});

export const postInspection = (txt) => {
  return utilityApi.post('/sensitives/inspection', {
    flag: 'basic',
    orgId: localStorage.getItem('orgId'),
    txt,
    type: 1
  });
};

