export default {
  // 当前生成的guid
  currentGuid: null,
  // 获取设备号
  getDeviceId() {
    if (!localStorage.getItem('O2OUID')) {
      localStorage.setItem('O2OUID', this.createUuid());
    }
    return localStorage.getItem('O2OUID');
  },
  // 生成guid(32为随机串)
  createUuid() {
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === 'function') {
      d += performance.now(); // use high-precision timer if available
    }
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  },
  //   获取随机数
  getUuid() {
    if (!this.currentGuid) {
      this.currentGuid = this.createUuid();
    }
    return this.currentGuid;
  }
};
