<template>
  <yxt-col class="w-auto">
    <yxt-tag v-if="tag" class="yxtbiz-question-preview__tag">
      {{ label }}
    </yxt-tag>

    <template v-else>{{ label }}</template>
  </yxt-col>
</template>

<script>
export default {
  name: 'TagKeyword',
  props: {
    label: String,
    tag: {
      type: Boolean,
      default: false
    }
  }
};
</script>
