import { udpApi, dmpApi, miscApi } from 'yxt-biz-pc/packages/api';

/**
 * 获取部门树
 * @param query
 * @return {*}
 */
export const getDeptTree = (query) => {
  return udpApi.get('depts/tree', {params: query});
};

/**
 * 获取部门树根节点名称
 * @param query
 * @return {*}
 */
export const getRootName = (query) => {
  return udpApi.get('depts/orgname', {params: query});
};

/**
 * 获取部门树(主管和兼管的部门)
 * @param query
 * @return {*}
 */
export const getManageDeptTree = (query) => {
  return udpApi.get('depts/mgrtree', {params: query});
};

// 获取团队下的部门
export const getTeamDept = async(data) => {
  const res = await dmpApi.post('/team/component/dept', data);
  if (!res) return [];
  if (res.datas) return res;
  if (Array.isArray(res)) {
    return {
      datas: res
    };
  };
  return {
    datas: [res]
  };
};

// 获取机构参数
export const getOrgpParameter = () => {
  const code = 'useDeptTreeV2';
  const orgId = window.localStorage.orgId;
  return miscApi.get(`orgparsetting/code?code=${code}&orgId=${orgId}`);
};
