import { decorateApi } from 'yxt-biz-pc/packages/api';
import { parseComponent } from 'yxt-biz-pc/packages/custom/code-viewer/utils/sfcParser/parser';
/**
 * 设置脚本
 * @param {*} content script
 */
const setScript = function(content) {
  var s = document.getElementById('decorate_custom_script_injection');
  if (s) {
    s.textContent = content;
  } else {
    s = document.createElement('script');
    s.id = 'decorate_custom_script_injection';
    s.textContent = content;
    document.documentElement.appendChild(s);
  }
};
/**
 * 设置样式
 * @param {*} content style
 */
const setStyle = function(content) {
  var s = document.getElementById('decorate_custom_style_injection');
  if (s) {
    s.textContent = content;
  } else {
    s = document.createElement('style');
    s.id = 'decorate_custom_style_injection';
    s.textContent = content;
    document.head.appendChild(s);
  }
};
/**
 * 设置自定义脚本
 */
const setCustomScript = async() => {
  try {
    const res = await decorateApi.get(`/custom/release-codes/types/7?domainName=${window.location.host}`);
    const { body } = res;
    if (body) {
      const { script, styles } = parseComponent(body);
      script && setScript(script.content);
      styles && styles.length > 0 && setStyle(styles.reduce((pre, curr) => {
        return pre + curr.content;
      }, ''));
    }
  } catch (error) {}
};
export default setCustomScript;
