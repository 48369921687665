import { getGroupcorps } from './service.js';

export function setGroupcorps(orgId = localStorage.getItem('orgId')) {
  return new Promise((reslove, reject) => {
    getGroupcorps(orgId).then(res => {
      if (res) {
        Object.keys(res).forEach(key => {
          localStorage.setItem(key, res[key]);
        });
      }
      reslove(res);
    }).catch(err => {
      reject(err);
    });
  });
}
