import { getAuditDetailId } from './src/service';
import { i18n } from '../common-util/changeLangs';
export const goAuditDetail = async(
  formId,
  formType,
  orgId,
  userId,
  name = '_blank'
) => {
  try {
    const data = {
      formId,
      formType,
      orgId: orgId || localStorage.orgId,
      userId: userId || localStorage.userId
    };
    const res = await getAuditDetailId(data);
    if (res && res.execId) {
      const url = `${window.location.origin}/audit-center/#/audit-center-detail?execId=${res.execId}&formType=${formType}`;
      const isIos = /iP(hone|ad|od)/i.test(window.navigator.userAgent); // 是否Ios设备
      // ios设备兼容处理
      if (isIos) {
        window.location.href = url;
      } else {
        window.open(url, name);
      }
    } else {
      return Promise.reject({
        code: '',
        globalCode: '',
        key: 'apis.audit.user.no.auditing.id',
        message: i18n.t('apis.audit.user.no.auditing.id').d('审核单不存在')
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
