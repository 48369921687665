<template>
  <div>
    <!-- 关键字搜索 -->
    <!-- <div class="d-in-block w200 mr12 mb12">
        <yxt-input v-model="kngInput"
                  :placeholder="$t('pc_biz_kng_lbl_kngTitle')"
                  searchable
                  @search="searchLists(1)"></yxt-input>
      </div> -->
    <!-- 选择课程分类 -->
    <select-popover
      ref="selectPopover"
      :selectOpition="selectOpition"
      class="d-in-block w200 mr12 mb12"
      clearable
    >
      <div class="yxtbiz-kng-tree table_tree" style="">
        <yxt-input
          v-model="filterKngTree"
          :placeholder="$t('pc_biz_kng_lbl_searchCatalog_source')"
          searchable
          @search="searchCatalog"
        ></yxt-input>
        <yxt-tree
          ref="filterTree"
          v-loading="treeLoading"
          style="width: 100%;"
          class="yxtbiz-biz-tree-funs mt16"
          node-key="id"
          :empty-text="$t('pc_biz_kng_lbl_noCatalog')"
          :class="kngTreeData.length > 0 ? 'd-in-block' : ''"
          :data="kngTreeData"
          :props="defaultProps"
          :filter-node-method="filterNode"
          :default-expanded-keys="treeExpand"
          :expand-on-click-node="false"
          @node-click="ClickTreeNode"
        >
        </yxt-tree>
      </div>
    </select-popover>
    <yxt-select
      v-if="kngSelectType"
      v-model="kngValue"
      class="w120 mr12 mb12"
      :placeholder="$t('pc_biz_kng_lbl_kngType_source')"
      @change="searchLists"
      clearable
    >
      <yxt-option
        v-for="item in options"
        :key="item.value + Math.random()"
        :label="item.label"
        :value="item.value"
      >
        <span class="font-size-14">{{ item.label }}</span>
      </yxt-option>
    </yxt-select>
    <!-- 所有来源，自主上传、商城购买 -->
    <yxt-select
      v-else
      v-model="kngSource"
      class="w120 mr12 mb12"
      :placeholder="$t('pc_biz_kng_lbl_course_source')"
      @change="searchLists"
      clearable
    >
      <yxt-option
        v-for="item in sourceTypes"
        :key="item.value + Math.random()"
        :label="item.label"
        :value="item.value"
      >
        <span class="font-size-14">{{ item.label }}</span>
      </yxt-option>
    </yxt-select>

    <!-- 关键字搜索 -->
    <div class="d-in-block w200 mr12 mb12">
      <yxt-input
        v-model="kngInput"
        :placeholder="
          $t(
            kngSelectType
              ? 'pc_biz_kng_lbl_kngTitle_courseware_source'
              : 'pc_biz_kng_lbl_kngTitle_course_source'
          )
        "
        searchable
        @search="searchLists(1)"
      >
      </yxt-input>
    </div>
    <yxt-table
      ref="stb"
      v-loading="kngListLoading"
      class="mt12"
      :data="KngTableData"
      :row-key="getRowKey"
      selected-row-by-key="isSelectedRow"
      @select-all="selectChange"
      @select="selectChange"
    >
      <!-- @select-all="handleKngSelectAll" -->
      <yxt-table-column
        type="selection"
        :selectable="selectable"
        width="40"
        :reserve-selection="true"
        :show-overflow-tooltip="false"
      ></yxt-table-column>
      <yxt-table-column
        prop="title"
        :label="$t('pc_biz_kng_lbl_title')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span :class="'kng_' + scope.row.fileType"></span>
          <span
            class="v-mid font-size-14 yxtbiz-knglistsource-tb-title"
            @click="preview(scope.row)"
          >
            {{ scope.row.title }}
          </span>
        </template>
      </yxt-table-column>
      <yxt-table-column
        prop="catalogName"
        :label="$t('pc_biz_ote_lbl_classification')"
      >
      </yxt-table-column>
    </yxt-table>

    <div v-if="totalKng > 10" class="mt24">
      <yxt-pagination
        class="pull-right"
        :total="totalKng"
        :page-size.sync="perKngPage"
        :current-page.sync="currentKngPage"
        show-on-single-page
        layout="total, prev, pager, next"
        @current-change="handleKngCurrentChange"
        @size-change="handleKngSizeChange"
      >
      </yxt-pagination>
    </div>
  </div>
</template>

<script>
import SelectPopover from '../../check-person-range/src/components/SelectPopover';
import api from './service.js';
import { Table, TableColumn, Tree, Select, Option, Pagination } from 'yxt-pc';
import { enmuFunctions } from '../../function-points';
import commonUtil from '../../common-util';

export default {
  name: 'YxtbizSelectKngSource',
  components: {
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtTree: Tree,
    YxtSelect: Select,
    YxtOption: Option,
    YxtPagination: Pagination,
    SelectPopover
  },
  props: {
    // 默认搜索的知识类型
    kngSelectType: {
      type: Number,
      default: 0
    },
    value: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: Function,
      default: () => true
    }
  },
  data() {
    return {
      // 分类树过滤变量
      filterKngTree: '',
      // 搜索内容变量
      kngInput: '',
      // 分类树选择框标题
      selectOpition: {
        placeholder: this.$t('pc_biz_kng_lbl_selectCatalog_source'),
        checkWord: ''
      },
      // 分类树loading参数
      treeLoading: true,
      // 分类树
      kngTreeData: [],
      // 分类树默认属性
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      // 分类树默认展开节点
      treeExpand: [''],
      // 课程类型选择框的值
      kngValue: '',
      // 课程类型列表
      options: this.getKngOptions(),
      kngSource: 0,
      sourceTypes: [
        { value: 0, label: this.$t('pc_biz_kng_lbl_all_source'/** 全部来源 */) },
        { value: 1, label: this.$t('pc_biz_kng_lbl_upload_self'/** 自主上传 */) },
        { value: 2, label: this.$t('pc_biz_kng_lbl_shop_mall'/** 商城购买 */) }
      ],
      // table内容的loading
      kngListLoading: true,
      // table数据列表
      KngTableData: [],
      // 分页数据
      totalKng: 0,
      perKngPage: 10,
      currentKngPage: 1,

      // TODO
      kngTreeId: '', // 左侧知识树点击时候保存的id

      kngChecked: true, // 隐藏知识
      currentKng: 1,
      kngSort: '',
      kngSortType: '',
      collegeIdSearch: this.collegeId,

      creatTimes: [] // 创建时间
    };
  },
  async created() {
    this.init();
  },
  methods: {
    // 加载初始化
    init() {
      this.getKngTree();
      this.getKngLists(null, 1);
    },
    // 搜索操作
    searchLists() {
      this.perKngPage = 10;
      this.currentKng = this.currentKngPage = 1;
      this.getKngLists(this.kngTreeId);
    },
    // 过滤分类树的筛选条件
    searchCatalog(value) {
      this.$refs.filterTree.filter(value);
    },
    // 知识库目录树筛选的方法
    filterNode(value, data) {
      if (!value) return true;
      let name = data.label.toLowerCase();
      let cValue = value.toLowerCase();
      return name.indexOf(cValue) !== -1;
    },
    // 点击左侧树的节点的事件
    ClickTreeNode(obj, node, _this) {
      if (obj.id === obj.collegeId) {
        // 选择的学院
        this.kngTreeId = '';
      } else {
        this.kngTreeId = obj.id;
      }
      this.collegeIdSearch = obj.collegeId;
      this.$refs['selectPopover'].cancel();
      this.selectOpition.checkWord = obj.id ? obj.label : '';
      this.getKngLists(this.kngTreeId);
    },
    //   isBuy: functionValue >= 2 && functionValue <= 4, // 已购买 且 未过期
    //   isDisabled: functionValue >= 5, // 已购买 且 禁用了
    //   isBought: functionValue >= 2, // 已购买
    //   isOut: functionValue >= 5, // 购买过但已过期
    //   val: functionValue,
    //   isHide: [0, 1, 3, 5].includes(functionValue), // 隐藏
    //   isAsh: functionValue === 6, // 置灰
    //   isShow: functionValue === 2 // 显示
    // 课程类型列表
    getKngOptions() {
      let options = [
        { value: 1, label: this.$t('pc_biz_knglib_lbl_doc') }, // 文档
        { value: 2, label: this.$t('pc_biz_knglib_lbl_video') }, // 视频
        { value: 3, label: this.$t('pc_biz_knglib_lbl_audio') }, // 音频
        { value: 4, label: this.$t('pc_biz_knglib_lbl_weike') }, // 微课
        { value: 5, label: 'SCORM' },
        { value: 6, label: 'HTML' },
        { value: 7, label: this.$t('pc_biz_kng_lbl_ziptype') }
      ];
      // 处理外链
      const newLinkCode = commonUtil.checkTimeOutFnc(
        enmuFunctions.SHOW_NEWLINK
      );
      // functionValue >= 2 已购买, 过期不会影响展示
      if (newLinkCode >= 2) {
        options.push({ value: 8, label: this.$t('pc_biz_knglib_lbl_newlink') });
      }
      // 处理隐藏【微课】
      const showNewWeike = commonUtil.checkTimeOutFnc(
        enmuFunctions.SHOW_NEW_WEIKE
      );
      const showOldWeike = commonUtil.checkTimeOutFnc(
        enmuFunctions.SHOW_OLD_WEIKE
      );
      showNewWeike !== 2 && showOldWeike !== 2 && options.splice(3, 1);
      return options;
    },
    // 获取table的row-key
    getRowKey(row) {
      return row.id;
    },
    // table选中项目发生变化
    selectChange(list) {
      list.forEach(item => {
        item.name = item.title;
      });
      this.$emit('select', list);
    },
    // // 表格标题颜色
    // handlerTitleColor(row) {
    //   if (!row.visiable || row.deleted) {
    //     return 'color:rgba(153, 153, 153, 0.65);';
    //   }
    //   return 'color: rgba(0, 0, 0, 0.65);';
    // },
    // 知识库目录下一页操作
    handleKngCurrentChange(val) {
      this.currentKng = val;
      this.getKngLists(this.kngTreeId);
    },
    // 获取知识库目录左侧的树
    async getKngTree() {
      this.treeLoading = true;
      let request = {};

      //  TODO 测试代码 待删除
      // localStorage.setItem('token', 'eyJhbGciOiJIUzUxMiJ9.eyJvcmdJZCI6IjdjMTAwMTZlLWU0YzMtNDAxMC1hMWVjLWU3ZjAyODA2OTQ1ZCIsInVzZXJJZCI6IjU3YmUzNWU2LTYxYjctNGRkNi04MDM1LTI4MGI5MjlhNzk5OSIsImNsdXN0ZXJJZCI6InYyZGV2IiwiZXhwIjoxNjQzMDk3ODY4fQ.5qNH6fE9AGIqbM8aX4PCvNTW0r_Q8xkp_JoEuQN1gq6zA7HXffcWrUZ6tg2-s4QyGya-sWvgTS_Af6MRP5pg-g');

      let orgId = localStorage.getItem('orgId');
      if (this.collegeId) {
        request.collegeId = this.collegeId;
      }

      let res = await api.getKngTree(orgId, request);
      if (res && res.length) {
        this.kngTreeData = res;
        if (res.length === 1) {
          this.$nextTick(() => {
            this.treeExpand = [res[0].id];
          });
        }
        this.treeLoading = false;
      }
    },
    // 知识库目录的列表知识
    async getKngLists(kngTreeId, isInit) {
      this.kngListLoading = true;

      let linkParams = this.getLinkParams();
      let bodyParams = this.getBodyParams(
        this.kngInput.trim(),
        this.kngValue,
        this.kngChecked,
        kngTreeId
      );
      bodyParams.collegeId = this.collegeIdSearch;

      // let code = '';
      // const hash = window.location.hash;
      // if (hash) {
      //   code = hash.match(/app=[^&*]+/) && hash.match(/app=[^&*]+/)[0];
      //   code = code && code.split('=');
      //   if (Array.isArray(code) && code[1]) {
      //     code = code[1];
      //   }
      // }
      kngTreeId && (bodyParams.catalogId = kngTreeId);
      // bodyParams.orgId = 'ac0a267c-044e-4a28-b489-6be16e0ab788';
      // localStorage.setItem('token', 'eyJhbGciOiJIUzUxMiJ9.eyJvcmdJZCI6IjU4ODkyNGI4LTFiYWUtNDZkNy04YTRiLTYzOGUxMTdkZThjNCIsInVzZXJJZCI6IjVmZDE5NGNkLTgzNTEtNDg5ZS1iOTRlLTA4MWMyNjc5YzNlOSIsImNsdXN0ZXJJZCI6InYyZGV2IiwiZXhwIjoxNjQzMjYwNzU2fQ.G0plOwxui32CNpZ1PDSlbrAJleA3tpfoLpvS7adpeKV8XIS88qaUPRYZ2DyjsDP23ZO68uupyA5N9uu3hMEs7A');

      if (isInit) {
        // 这里需要和后端确认除了课程以外的类型怎么传
        if (this.kngSelectType !== 999) {
          bodyParams.type = this.kngSelectType;
        }
      }
      // type 传 0 则获取课程类型数据
      // type 传 1~6 则获取对应课件类型数据
      // type 不传则获取所有课件（不包括课程）

      bodyParams.orgId = localStorage.getItem('orgId');
      api.getkngListsSource(linkParams, bodyParams).then(res => {
        console.log(res);
        res.datas.forEach(item => {
          if (this.value.find(val => val.id === item.id)) {
            item.isSelectedRow = true;
          }
        });
        this.KngTableData = res.datas;
        this.totalKng = res.paging.count;
        this.kngListLoading = false;
      });
    },
    // 公共处理linkParams的地方
    getLinkParams(pageIndex) {
      return {
        limit: this.perKngPage,
        offset: (this.currentKng - 1) * this.perKngPage
      };
    },
    getBodyParams(input, value) {
      let bodyParams = {
        title:
          input.indexOf(this.$t('pc_biz_kng_lbl_hidden')) === -1
            ? input
            : input.replace(this.$t('pc_biz_kng_lbl_hidden'), ''),
        type: value.toString()
      };
      if (this.kngSelectType === 0) {
        bodyParams.sourceType = this.kngSource;
        bodyParams.type = 0;
      }
      if (this.creatTimes && this.creatTimes.length > 1) {
        bodyParams.startTime = this.creatTimes[0] + ' 00:00:00';
        bodyParams.endTime = this.creatTimes[1] + ' 23:59:59';
      }
      return bodyParams;
    },
    preview(row) {
      // let kngType = 'source';
      let kngType = 'course';
      let sourceId = '';
      let kngId = '';
      if (!row.type) {
        sourceId = row.id;
      } else {
        kngId = row.id;
      }
      switch (row.type) {
        case 1:
          kngType = 'doc';
          break;
        case 2:
          kngType = 'video';
          break;
        case 3:
          kngType = 'audio';
          break;
        case 4:
          kngType = 'weike';
          break;
        case 5:
          kngType = 'scorm';
          break;
        case 6:
          kngType = 'html';
          break;
        case 7:
          kngType = 'zip';
      }
      window.open(
        `https://${
          window.location.host
        }/kng/#/${kngType}/preview/detail?id=${kngId}&courseId=${sourceId}&previewType=2`
      );
    }
  },
  watch: {
    kngSelectType() {
      this.getKngLists(this.kngTreeId, true);
    },
    value(newValue) {
      this.KngTableData.forEach(item => {
        if (this.$refs.stb) {
          if (newValue.find(val => item.id === val.id)) {
            this.$refs.stb.toggleRowSelection(item, true);
          } else {
            this.$refs.stb.toggleRowSelection(item, false);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.yxtbiz-knglistsource-tb-title {
  color: var(--color-primary-6);
  cursor: pointer;
}
</style>
