import { kngApi } from 'yxt-biz-pc/packages/api';
import { i18n } from 'yxt-i18n/es';
import {Message} from 'yxt-pc';
const noteJump = async(note, manual = false) => {
  const {kngId, courseId, kngVersion} = note;
  const ret1 = await kngApi.post(`knowledge/share/exchange/${kngId}`);
  note.kngId = ret1.kngId;
  if (courseId) {
    const ret2 = await kngApi.post(`knowledge/share/exchange/${courseId}`);
    note.courseId = ret2.kngId;
  }
  const {result} = await kngApi.get(`studynote/checkVersion/${note.kngId}?version=${kngVersion}&courseId=${note.courseId || ''}`);
  if (result === 0) {
    if (manual) return note;
    else {
      localStorage.setItem('kng_note_tag_item', JSON.stringify(note));
      window.open(`/kng/#/scan?kngId=${kngId}&targetId=&targetCode=kng`);
    }
    return;
  }
  Message.error(i18n.t(result === 3 ? 'pc_kng_note_tip_deleted' : 'pc_kng_note_tip_version_change'));
  return Promise.reject(Error(''));
};
export default {
  noteJump
};
