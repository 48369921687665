import commonUtil from '../../packages/common-util';
export function isDef(val) {
  return val !== undefined && val !== null;
}
export function isKorean(text) {
  const reg = /([(\uAC00-\uD7AF)|(\u3130-\u318F)])+/gi;
  return reg.test(text);
}

export function isOpenData() {
  return window.localStorage.sourceCode === '100' || window.localStorage.sourceCode === '104' || window.localStorage.sourceCode === '106' || commonUtil.checkTimeOutFnc('address_book_encryption') === 2;
}
