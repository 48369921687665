
import {
  checkTimeOutFnc
} from './getFactor';

// 旧的12种状态
// const OBJ_DISABLED_VALUES = [2, 8, 11] // 功能禁用的所有值
// const OBJ_HIDDEN_VALUES = [1, 7, 10, 5] // 功能隐藏的所有值
// const OBJ_CUSTOM_VALUES = [3, 9, 12] // 功能自定义的所有值
// const OBJ_ENABLED_VALUES = [4, 5, 6] // 功能启用状态

// 新的8种状态
const OBJ_DISABLED_VALUES = [6]; // 功能置灰的所有值
const OBJ_HIDDEN_VALUES = [0, 1, 3, 5]; // 功能隐藏的所有值
const OBJ_CUSTOM_VALUES = [4, 7]; // 功能自定义的所有值

const OBJ_ENABLED_VALUES = [2, 3, 4, 7]; // 功能可用状态
/**
 * 获取按钮类的状态控制
 * @param {String} funCode 功能点
 * @returns 状态控制策略
 */
export const getFunStatus = (funCode) => {
  try {
    /*
     0.要素下架-隐藏
     1.要素上架-未购买-隐藏

     2.要素上架-已购买未过期（启用）-显示
     3.要素上架-已购买未过期（启用）-隐藏
     4.要素上架-已购买未过期（启用）-自定义

     5.要素上架-已购买已过期（禁用）-隐藏
     6.要素上架-已购买已过期（禁用）-置灰
     7.要素上架-已购买已过期（禁用）-自定义
    */
    const objStatus = checkTimeOutFnc(funCode);

    // console.log(funCode, objStatus)
    // return {
    //   disabled: true,
    //   hidden: false
    // };
    return {
      disabled: OBJ_DISABLED_VALUES.indexOf(objStatus) >= 0,
      hidden: OBJ_HIDDEN_VALUES.indexOf(objStatus) >= 0,
      custom: OBJ_CUSTOM_VALUES.indexOf(objStatus) >= 0,
      enabled: OBJ_ENABLED_VALUES.indexOf(objStatus) >= 0,
      value: objStatus
    };
  } catch (error) {
    return {};
  }
};

/**
 * 根据功能过期禁用重设属性的值
 * @param {*} funCode 功能编号
 * @param {*} resetFun 重设值方法
 */
export const resetValueBystatus = (funCode, resetFun) => {
  let status = getFunStatus(funCode);
  if (status.hidden || status.disabled) {
    if (resetFun && typeof (resetFun) === 'function') {
      resetFun(status);
    }
  }
};
