import { enmuFunctions } from '../function-points/index';
import setTheme from './theme';
import checkActionPermission from './storeHelper';
import {
  getMobileDomain,
  getDomain,
  getShortUrl,
  getStaticCdnUrl,
  parseShortUrl
} from './domain';
import common from './utils';
import {
  setLanguage,
  getLanguage,
  i18n,
  getLangList,
  getLangFiles
} from './changeLangs';
import * as serviceMap from './service';
// import './devtools';    文件中涉及非产线域名，不可以打包到产物中，否则会影响线上环境，会涉及安全问题
import YxtInit from './yxtInit';
import {
  preCheckFunctions,
  checkTimeOutFnc,
  refreshFactors,
  getFactorAllState,
  checkFactorStatus,
  getFactorFunctions,
  getAllFunctionsByFactorCode,
  getAllFunctions,
  getAllFactor
} from './getFactor';
import btnDirectives from './check-buttons';
import setCustomScript from './custom-script';
import { axiosReject, getErrorKeys, errorPage } from './axios-reject';
import Eco from './eco';
import fileUtils from './file-format';
import { setGroupcorps } from './setGroupOrg.js';
import getSensors from './getSensor';
import toThirdAuth from './toThirdAuth';
import { goAuditDetail } from '../sponsor-choose/audit-utils';
import checkHasNav from './checkHasNav';
import kngUtils from './kngUtils';
import DownloadGlobalToast from 'yxt-biz-pc/packages/download-global-toast';
import { productionUtil } from './productionUtil';
import CreateWebSocket from 'yxt-biz-pc/packages/ai-project-assistant/src/base/websocket/index.js';
import WaterMarkCanvas from './watermark-canvas.js';
import { getNavData } from './storeHelper';
import { asyncCheckActionPermission } from './storeHelper';
import { CommonAskIdHandler } from 'yxt-biz-pc/packages/ai-robot/src/utils.js';
import { checkOrgIsHw } from './reportUtils';
import {bindImgPreviewer, removeImgPreviewer} from './bindImagePreview';

export default {
  name: 'YxtbizCommonUtil',
  YxtInit,
  ...serviceMap,
  setTheme,
  checkActionPermission,
  getMobileDomain,
  getDomain,
  getShortUrl,
  common,
  setLanguage,
  getLanguage,
  i18n,
  getLangList,
  getLangFiles,

  preCheckFunctions,
  checkTimeOutFnc,
  getFactorFunctions,
  getAllFunctionsByFactorCode,
  refreshFactors,
  getFactorAllState,
  checkFactorStatus,

  enmuFunctions,
  btnDirectives,
  setCustomScript,
  axiosReject,
  getErrorKeys,
  errorPage,
  Eco,
  setGroupcorps,
  getStaticCdnUrl,
  fileUtils,
  getSensors,
  toThirdAuth,
  goAuditDetail,
  checkHasNav,
  DownloadGlobalToast,
  parseShortUrl,
  productionUtil,
  CreateWebSocket,
  kngUtils,
  WaterMarkCanvas,
  getNavData,
  asyncCheckActionPermission,
  CommonAskIdHandler,
  getAllFunctions,
  getAllFactor,
  checkOrgIsHw,
  bindImgPreviewer,
  removeImgPreviewer
};
