import axios from 'axios';
// import { Api as signupApi } from '@xxbiz/signup';
import config, { setDomainConfig, source, apiEnv } from './configDomain';
import { axiosReject } from '../common-util/axios-reject';
import {
  requestResolve,
  responseResolve,
  responseReject,
  removePending
} from './debounce'; // 去重复
import privateContactAdapter from './privateContactAdapter'; // 通讯录加密
import axiosErrorReport from './axiosErrorReport';
import { productionUtil } from '../common-util/productionUtil';
import { getLanguage } from 'yxt-i18n/es';

console.log('productionUtil-3', productionUtil);
let defaultProduct = productionUtil.getProductionInfo();
let defaultProductCode = defaultProduct.code || '';
let Api = {
  name: 'Api'
  // signupApi
};
let http = {};
let currentLanguage = getLanguage() || 'zh';

const defaultOptions = {
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    source,
    'x-yxt-product': defaultProductCode
  },
  validateStatus: status => {
    return status < 400;
  },
  adapter: privateContactAdapter
};

const getConfig = options => {
  options = Object.assign(defaultOptions, options);
  return options;
};

export const request = {
  resolve: config => {
    if (config.method === 'get') {
      // 给config.data赋值, 使Content-type起作用
      config.data = true;
    }
    config.headers.token = !config.headers.ticket
      ? localStorage.getItem('token')
      : '';
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    // headers添加产品code,支持多产品
    if (config.headers && !config.headers['x-yxt-product']) {
      config.headers['x-yxt-product'] = defaultOptions.headers['x-yxt-product'];
    }

    // 报表接口，若当前机构在华为云则更改接口域名为华为云域名 TODO临时处理
    if (config.baseURL.indexOf('/rpt2') !== -1) {
      config.headers.lang = currentLanguage;
    }
    if (
      config.baseURL.indexOf('/rpt2') !== -1 &&
      (config.url !== 'config/hw/orgs' &&
        !/^\/?config\/[a-zA-Z0-9]+$/.test(config.url)) &&
      localStorage.getItem('hw_report') === 'true'
    ) {
      config.baseURL =
        apiEnv === 'tf-ali-01'
          ? window.feConfig.common.apiBaseUrlHw + 'hwreport/'
          : window.feConfig.common.apiBaseUrlHw + 'rpt2/';
    }

    return config;
  },
  reject: error => {
    // eslint-disable-next-line
    return Promise.reject('req', error);
  }
};

const response = {
  resolve: response => {
    if (response.status === 201) {
      response.data = response.data || {};
      typeof response.data === 'object' &&
        (response.data.Location = response.headers.location);
    }
    return response.data;
  },
  reject: error => {
    axiosErrorReport(error);
    const response = error.response || {};
    let data = response && response.data ? { ...response.data } : {};
    data.status = response.status;
    data.response = response; // 一些项目中需要通过response获取,做兼容
    // eslint-disable-next-line
    return Promise.reject(data);
  }
};

const excludeKeys = ['captchaId'];

// 加入新的api地址
const newApiConfig = {
  dmp: config['dmp'] || `${window.feConfig.common.apiBaseUrl}dmp`,
  websocketclient:
    config['websocketclient'] ||
    `${window.feConfig.common.apiBaseUrl}websocketclient`
};

Object.assign(config, newApiConfig);

// 如果是测评 api，需要改动 default.headers里的x-yxt-product
const isSpevalApi = (key, config) => {
  if (key === 'speval') return config.apiBaseUrl + 'speval';
  return config[key];
};

// 测评会存在没有下发speval的时候
if (!config['speval']) {
  config['speval'] = config.apiBaseUrl + 'speval';
}

for (const key in config) {
  if (excludeKeys.includes(key)) continue;
  const baseURL = isSpevalApi(key, config);
  const options = getConfig({
    baseURL,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      source,
      'x-yxt-product': defaultProductCode,
      ...{ 'Access-Key': key === 'bpaasBaseUrl' ? '31l6EwZb7M' : undefined }
    }
  });
  http[key] = axios.create(options);

  http[key].interceptors.request.use(requestResolve);
  http[key].interceptors.response.use(responseResolve, responseReject);

  http[key].interceptors.request.use(request.resolve, request.reject);
  http[key].interceptors.response.use(response.resolve, response.reject);

  http[key].originGet = http[key].get;
  http[key].get = (url, data = {}, config = {}) => {
    data = data.hasOwnProperty('params')
      ? data
      : Object.assign({ params: data }, config || {});
    return http[key].originGet(url, data);
  };
}
/**
 * AppMaker 自定义axios拦截时候取消默认axios错误拦截
 */
var interceptorErrorReject = {};

/**
 *
 * @param {*} env 废弃
 */
const setConfig = (val, interceptors) => {
  for (const key in config) {
    if (!http[key]) continue;

    const api = http[key];

    if (!api.defaults) continue;

    api.defaults.baseURL = isSpevalApi(key, config);
    api.defaults.headers.source = val || source;
    if (interceptors) {
      interceptorErrorReject[key] &&
        api.interceptors.response.eject(interceptorErrorReject[key]);
      api.interceptors.response.use(interceptors.resolve, interceptors.reject);
    } else {
      interceptorErrorReject[key] = api.interceptors.response.use(
        null,
        axiosReject
      );
    }
  }
};

Api.setConfig = options => {
  setDomainConfig(options.domain);
  setConfig(options.source, options.response);
};

Api.create = options => {
  if (options.headers && !options.headers.source) {
    options.headers.source = defaultOptions.headers.source;
  }
  options = Object.assign({}, defaultOptions, options);
  const api = axios.create(options);
  api.interceptors.request.use(requestResolve);
  api.interceptors.response.use(responseResolve, responseReject);

  api.interceptors.request.use(request.resolve, request.reject);
  api.interceptors.response.use(response.resolve, response.reject);
  return api;
};

let getMedia = () => {
  return config.media;
};

let getNavIcoPath = () => {
  /**
   feConfig.common {
    navIcoPath  : 'https://media-phx.yunxuetang.com.cn/navico/'
    navIcoUpdateTime : 1347786768776,
   }
   */
  return {
    navIcoPath: config.navIcoPath || getMedia() + 'navico/',
    navIcoUpdateTime: config.navIcoUpdateTime
  };
};

function getDomains() {
  return config;
}
const captchaId = config.captchaId;
const envNow = apiEnv; // 只从配置中心取
// 一个空 实例
const emptyInstance = axios.create();

export {
  http,
  envNow,
  getMedia,
  captchaId,
  getDomains,
  getNavIcoPath,
  removePending,
  privateContactAdapter,
  emptyInstance
};

export default Api;
