function checkHasManageNav(navCode) {
  try {
    let datas = JSON.parse(localStorage.nav_datas_2);
    return !!datas[0].datas.find(i=> i.code === navCode && i.showed);
  } catch (error) {
    return false;
  }
}

function checkHasStuNav(navCode) {
  try {
    const datas = JSON.parse(localStorage.nav_datas_1);
    return !!datas[0].datas.find(i=>i.code === navCode && i.showed);
  } catch (error) {
    return false;
  }
}

// 判断是否拥有导航权限
/**
 * navCode:导航code,
 * navTYpe:导航类型 1学员端，2管理端
 */
export default function checkHasNav(navCode, navType) {
  if (navType === 2) return checkHasManageNav(navCode);
  else if (navType === 1) return checkHasStuNav(navCode);
  return checkHasManageNav(navCode) || checkHasStuNav(navCode);
}
