<template>
  <yxt-tag
      disable-transitions
      @close="handleClose(source, index)"
      size="small"
      type="info"
      closable
      :key="source.id"
  >
    <yxt-svg
        class="mr5 v-mid"
        width="14px"
        height="14px"
        :icon-class="imgs[source['_type']]"
    />
    <span v-if="source._type === 0" :class="['tag_span', source.includeAll === 1 ? 'tag_span_width_tail' : '' ]">
      <yxt-tooltip  class="item" effect="dark" placement="top">
        <template slot="content">
          <yxtbiz-dept-name :name="source.combinationName || source.name || source.fullname"></yxtbiz-dept-name>
        </template>
        <yxtbiz-dept-name :name="source.combinationName || source.name || source.fullname"></yxtbiz-dept-name>
      </yxt-tooltip>
    </span>
    <span v-else-if="source._type === 5 || source._type === 6" :class="['tag_span', source.includeAll === 1 ? 'tag_span_width_tail' : '' ]"><yxtbiz-user-name :name="source.combinationName || source.name || source.fullname"></yxtbiz-user-name> </span>
    <span v-else :class="['tag_span', source.includeAll === 1 ? 'tag_span_width_tail' : '' ]">
       <yxt-tooltip  class="item" effect="dark" :content="source.combinationName || source.name || source.fullname" placement="top">
            <span>{{source.combinationName || source.name || source.fullname}}</span>
      </yxt-tooltip>
    </span>
    <span
        v-if="source.includeAll === 1"
        class="ml10"
        style="color: #BFBFBF;"
    >{{source.includeAll === 1 ? $t('biz_udp_subsequent_all') /*后续全部*/: ''}}</span>
  </yxt-tag>
</template>

<script>
  import {Tag} from 'yxt-pc';
  import YxtBizUserName from 'yxt-biz-pc/packages/user-name';
  import YxtBizDeptName from 'yxt-biz-pc/packages/dept-name';

  export default {
    components: {
      YxtTag: Tag,
      YxtBizUserName,
      YxtBizDeptName
    },
    name: 'CheckedListTag',
    props: {
      source: {
        type: Object,
        default: () => {
          return {};
        }
      },
      index: {}
    },
    data() {
      return {
        imgs: [
          'dept',
          'position',
          'usergroup',
          'role',
          'pos-grade',
          'person-center',
          'person-center'
        ]
      };
    },
    methods: {
      handleClose(source, index) {
        this.$emit('close', source, index);
      }
    }
  };
</script>
