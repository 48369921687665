import Vue from 'vue';

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    identify: {
      type: Number
    },
    options: {},
    limit: {
      type: Number,
      default: Infinity
    }
  },
  data() {
    return {
      isSearch: false
    };
  },
  computed: {
    showDeptCount() {
      if (this.options && this.options.count === false) {
        return false;
      }
      return true;
    }
  },
  methods: {
    selectEvent(row, index) {
      if (this.list.length < this.limit) {
        return true;
      } else {
        return this.list.findIndex(item => item.id === row.id) > -1;
      }
    },
    update() {
      if (this.list.length > 0) {
        this.$nextTick(() => {
          this.isSearch = true;
          let ids = this.list.map(item => item.id);
          this.tableData.map(item => {
            if (ids.includes(item.id)) {
              let a = this.list.find(item2 => item2.id === item.id);
              if (!('_type' in a)) {
                Vue.set(a, '_type', this.identify);
              }
              this.$refs.multipleTable && this.$refs.multipleTable.toggleRowSelection(item, true);
            } else {
              this.$refs.multipleTable && this.$refs.multipleTable.toggleRowSelection(item, false);
            }
          });
          this.isSearch = false;
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 全选筛选结果
    selectTotal(data) {
      let ids = this.list.map(item => item.id);
      if (Array.isArray(data) && data.length) {
        data.map(item => {
          if (!ids.includes(item.id)) {
            this.list.push(item);
          }
        });
      }
    },
    // 全选与全不选
    handleSelectAll(selection) {
      let ids = this.list.map(item => item.id);
      if (selection.length) {
        selection.map(item => {
          if (!ids.includes(item.id) && this.list.length < this.limit) {
            this.list.push(item);
          }
        });
        this.update();
      } else {
        let tempChooseMembers2 = JSON.parse(JSON.stringify(this.list));
        this.tableData.map(item => {
          if (ids.includes(item.id)) {
            tempChooseMembers2 = tempChooseMembers2.filter(item2 => item2.id !== item.id);
          }
        });
        // this.list = tempChooseMembers2;
        this.$emit('update', tempChooseMembers2);
      }
    },
    // 单击checkbox
    handleSingleSeletct(selection, row) {
      // 由于table toggleRowSelection会触发该事件执行，所以加把锁
      if (!this.isSearch) {
        // 代表当前页是点击了勾选
        if (!this.list.some(item => item.id === row.id) && this.list.length < this.limit) {
          this.list.push(row);
        } else {
          const members = this.list.filter(item => item.id !== row.id);
          this.$emit('update', members);
        }
      }
      return true;
    },
    // 关闭单个标签
    closeTag(tag) {
      const closeIndex = this.tableData.findIndex(item => item.id === tag.id);
      if (closeIndex >= 0) {
        this.$refs.multipleTable.toggleRowSelection(this.tableData[closeIndex], false);
      }
    }
  }
};
