import { udpApi } from 'yxt-biz-pc/packages/api';

/**
 * 获取用户列表
 * @param query
 * @return {*}
 */
export const getUserList = (config) => {
  config = config === undefined ? {} : config;
  return udpApi.post(`users/search?targetOrgId=${config.targetOrgId}`, config);
};
