<template>
  <div class="fw-600 font-size-20 pr" :class="noBorder ? null : 'border-b-e9 pb20 pl32'">
    <span class="v-mid">上传课件<i class="yui-name-icon v-mid"></i></span>
    <yxt-popover
      placement="bottom-start"
      width="560"
      trigger="hover">
      <uploadFAQ class="p10"></uploadFAQ>
      <i slot="reference" class="yxt-icon-question ml16 v-mid hand color-gray-6"></i>
    </yxt-popover>
  </div>
</template>


<script>
import uploadFAQ from './UploadFAQ';
import {
  Popover
} from 'yxt-pc';
export default {
  name: 'UploadTitle',
  components: {
    uploadFAQ,
    YxtPopover: Popover
  },
  props: {
    noBorder: {
      type: Boolean,
      default: false
    }
  }
};
</script>
