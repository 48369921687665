var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-tag",
    {
      key: _vm.source.id,
      attrs: {
        "disable-transitions": "",
        size: "small",
        type: "info",
        closable: "",
      },
      on: {
        close: function ($event) {
          return _vm.handleClose(_vm.source, _vm.index)
        },
      },
    },
    [
      _c("yxt-svg", {
        staticClass: "mr5 v-mid",
        attrs: {
          width: "14px",
          height: "14px",
          "icon-class": _vm.imgs[_vm.source["_type"]],
        },
      }),
      _vm.source._type === 0
        ? _c(
            "span",
            {
              class: [
                "tag_span",
                _vm.source.includeAll === 1 ? "tag_span_width_tail" : "",
              ],
            },
            [
              _c(
                "yxt-tooltip",
                {
                  staticClass: "item",
                  attrs: { effect: "dark", placement: "top" },
                },
                [
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _c("yxtbiz-dept-name", {
                        attrs: {
                          name:
                            _vm.source.combinationName ||
                            _vm.source.name ||
                            _vm.source.fullname,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("yxtbiz-dept-name", {
                    attrs: {
                      name:
                        _vm.source.combinationName ||
                        _vm.source.name ||
                        _vm.source.fullname,
                    },
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm.source._type === 5 || _vm.source._type === 6
        ? _c(
            "span",
            {
              class: [
                "tag_span",
                _vm.source.includeAll === 1 ? "tag_span_width_tail" : "",
              ],
            },
            [
              _c("yxtbiz-user-name", {
                attrs: {
                  name:
                    _vm.source.combinationName ||
                    _vm.source.name ||
                    _vm.source.fullname,
                },
              }),
            ],
            1
          )
        : _c(
            "span",
            {
              class: [
                "tag_span",
                _vm.source.includeAll === 1 ? "tag_span_width_tail" : "",
              ],
            },
            [
              _c(
                "yxt-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content:
                      _vm.source.combinationName ||
                      _vm.source.name ||
                      _vm.source.fullname,
                    placement: "top",
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.source.combinationName ||
                          _vm.source.name ||
                          _vm.source.fullname
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
      _vm.source.includeAll === 1
        ? _c(
            "span",
            { staticClass: "ml10", staticStyle: { color: "#BFBFBF" } },
            [
              _vm._v(
                _vm._s(
                  _vm.source.includeAll === 1
                    ? _vm.$t("biz_udp_subsequent_all") /*后续全部*/
                    : ""
                )
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }