/**
 * 功能点枚举值的维护
 */
export const enmuFunctions = {
  'UDP_DYNAMIC_USERGROUP': 'udp_dynamic_usergroup', // 动态用户组
  'ADDRESS_BOOK_ENCRYPTION': 'address_book_encryption', // 通讯录加密'
  'TONGXUNLU_JIAMI': 'tongxunlu_jiami', // 通讯录加密
  'IM_CHAT': 'IM_chat', // IM 能力
  'ZY_IM_CHAT': 'self_research_IM', // 自研IM
  'HX_IM_CHAT': 'hx_IM', // 环信IM
  'ONLINE_ClASS_SELECT_CATALOG': 'college_course_mgmt_contents_choose', // 在线课堂提供的选择目录业务组件使用的功能点
  'O2O_TRAIN_TASK_CEIBS': 'o2o_train_task_ceibs', // o2o在ulcd选课时用于检查是否增购 中欧课程模块
  'PREVENT_SWITCH_SCREEN_SET': 'prevent_switch_screen_set', // 发布考试，防作弊限制，【考试切屏限制】选项，prevent_switch_screen_set，模块失效后，选项置灰
  'COMPULSORY_SUBMISSION_SET': 'compulsory_submission_set', // 发布考试，防作弊限制，【无操作强制交卷】选项，compulsory_submission_set，无作弊防切屏时，选项置灰
  'EXAM_CERTIFICATE_GET': 'exam_certificate_get', // 考试获得证书
  'SURVEY_EDIT': 'survey_edit',
  'SURVEY_TEMPLATE_MANAGE': 'trainScene_itemAppraisalTemplates_manage',
  'DISCUSSION_DISCUSSION_MGMT': 'discussion_discussion_mgmt', // 学员web端个人中心-讨论管理的功能点
  'DISCUSSION_MY_DISCUSSION': 'discussion_my_discussion', // 学员web端个人中心-我的讨论的功能点
  'PROJECT_SIGNUP': 'project_signup', // 培训中心-项目中心，报名，【报名表单】字段，要素禁用时隐藏
  'PROJECT_CERTIFICATE_CONDITION': 'project_certificate_condition', // 报名，前置条件，【拥有证书】字段，证书模块失效后隐藏
  'MY_ARCHIVES': 'my_archives', // 我的档案
  'MY_MISTAKES': 'my_mistakes', // 我的错题
  'ONLINE_SERVICE': 'online_service', // 在线客服入口
  'CAPTCHA': 'slider_captcha_code', // 滑块验证码
  'XUANXING_UNIVERSITY_SWITCH': 'xuanxing_university_switch', // 绚星学堂入口
  'SUPPORT_CENTER_SWITCH': 'support_center_switch', // 帮助中心入口
  'REPORT_ASSISTANT_USER': 'report_assistant_user', // 报告助手
  'O2O_SURVEY_APPRAISAL': 'o2o_survey_appraisal', // new-ulcd-identify组件
  'SHOW_NEW_WEIKE': 'hide_weike', // 新微课入口全部隐藏
  'SHOW_OLD_WEIKE': 'Old_and_micro_classes', // 老微课入口全部隐藏
  'SHOW_NEWLINK': 'External_chain_course', // 外链
  'SCORM_DOMAIN_REPLACE': 'SCORM_full_screen_playback', // SCORM全屏播放
  KNG_NOTE: 'learning_note', // 笔记区开关
  // 用户中心
  PERSONAL_CENTER: 'personal_center',
  // 我的勋章
  MY_REWARD_MGMT: 'my_reward_mgmt',
  // 学习笔记
  LEARNING_NOTE: 'learning_note',
  // 项目中心
  O2O_TRAIN_CENTER: 'o2o_train_center',
  // 在线课堂
  ONLINE_KNG_OVERVIEW: 'online_kng_overview',
  // 知识库
  KNGLIB: 'knglib',
  // 我的错题
  ENTER_THE_WRONG_BOOK: 'enter_the_wrong_book',
  // 组织架构
  ORGANIZATIONAL_STRUCTRUE: 'organizational_structure',
  // 课程中的岗位标签
  COURSE_SKILL: 'online_course_skill_label_choose',
  // 课程app缓存
  App_cache_permission_hidden: 'App_cache_permission_hidden',
  // 课程游客
  Tourists_learn: 'Tourists_learn',
  // 课程编辑页讨论区
  Discussion_Enable: 'discussion_enable',
  // 课程的人脸识别
  Face_recognition_configuration_in_class: 'Face_recognition_configuration_in_class',
  // 绚星2.0要素Code
  WEB_PUBLIC: 'web_public',
  // 人才发展要素(奇点)
  TALENTMANAGEMENT: 'talentmanagement',
  // 绚星2.0要素
  XXV2MGMT: 'xxv2mgmt',
  // 供应商
  sdcs_supplier: 'sdcs_supplier',
  kng_tag_display: 'kng_tag_display',
  // 问一问助手(ai助手)
  AI_BOB: 'ai_bob',
  NICKNAME: 'nickname', // 昵称
  UDP_GLOBAL: 'udp_global' // udp国际化要素
};
