var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxt-user-selector-wrap yxt-user-single-wrap" },
    [
      _c(
        "yxt-row",
        { staticClass: "yxt-user-selector-container" },
        [
          _c(
            "yxt-col",
            { staticClass: "yxt-user-tree" },
            [
              _c("dept-tree", {
                ref: "tree",
                attrs: {
                  "wrap-width": 260,
                  enableGroupCorp: _vm.enableGroupCorp,
                  functionCode: _vm.functionCode,
                  dataPermissionCode: _vm.dataPermissionCode,
                  targetOrgId: _vm.userData.targetOrgId,
                  filterable: true,
                },
                on: {
                  nodeClick: _vm.selectDept,
                  "org-change": _vm.handleOrgChange,
                },
              }),
            ],
            1
          ),
          _c(
            "yxt-col",
            { staticClass: "yxt-user-right" },
            [
              _c("yxt-input", {
                staticClass: "mb16",
                staticStyle: { width: "240px" },
                attrs: {
                  placeholder: _vm.$t("pc_biz_te_tip_enternameaccountsearch"),
                  searchable: "",
                },
                on: { search: _vm.searchUser },
                model: {
                  value: _vm.userValue,
                  callback: function ($$v) {
                    _vm.userValue = $$v
                  },
                  expression: "userValue",
                },
              }),
              _c(
                "yxt-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "userTable",
                  staticClass: "yxt-user-table",
                  attrs: { height: _vm.tableHeight, data: _vm.users },
                },
                [
                  _c("template", { slot: "empty" }, [
                    _c(
                      "div",
                      { staticStyle: { "line-height": "normal" } },
                      [_c("yxt-empty")],
                      1
                    ),
                  ]),
                  _c("yxt-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: _vm.$t("pc_biz_udp_lbl_employee"),
                      "popover-html": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.isOpenData
                              ? _c("yxtbiz-user-name", {
                                  attrs: { name: scope.row.fullname },
                                })
                              : _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      scope.row.fullname +
                                        "(" +
                                        scope.row.username +
                                        ")"
                                    ),
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("yxt-table-column", {
                    attrs: {
                      label: _vm.$t("biz_udp_position"),
                      "show-overflow-tooltip": true,
                      formatter: _vm.formatPostion,
                    },
                  }),
                  _c("yxt-table-column", {
                    attrs: {
                      label: _vm.$t("pc_biz_udp_lbl_operation"),
                      width: "108",
                      align: "left",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-right" },
                              [
                                _c(
                                  "yxt-link",
                                  {
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectUser(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.value.id === scope.row.id
                                          ? _vm.$t("biz_udp_cancel_selected")
                                          : _vm.$t("pc_biz_udp_lbl_select")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "yxtbiz-user-selector-mask mt16 clearfix" },
                [
                  _vm.totalPage > 1
                    ? _c("yxt-pagination", {
                        staticClass: "pull-right",
                        attrs: {
                          "page-size": _vm.userData.limit,
                          "current-page": _vm.page,
                          "page-count": _vm.totalPage,
                          "simple-total": "",
                          layout: "total, prev, pager, next",
                          total: _vm.totalCount,
                        },
                        on: {
                          "size-change": _vm.sizeChange,
                          "current-change": _vm.pageChange,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }