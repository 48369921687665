import {getQuesLevel} from './service';

// 判断题 正确、错误
export const QUES_JUDGE_CHOICE = ['pc_biz_ote_lbl_wrong', 'pc_biz_ote_lbl_wright'];
// 试题题型 0-单选题 1-多选题 2-判断题 3-填空题 4-问答题 5-组合题
export const QUES_TYPE_NAMES = ['pc_biz_ote_lbl_questype0', 'pc_biz_ote_lbl_questype1', 'pc_biz_ote_lbl_questype2', 'pc_biz_ote_lbl_questype3', 'pc_biz_ote_lbl_questype4', 'pc_biz_ote_lbl_questype5'];

// 试题难度 0-易 1-中 2-难
// 试题难度全量 0-易 3-较易 1-中 4-较难 2-难
export const QUES_LEVEL_NAMES = {
  0: 'pc_biz_ote_lbl_easy',
  1: 'pc_biz_ote_lbl_medium',
  2: 'pc_biz_ote_lbl_difficult',
  3: 'pc_biz_ote_lbl_littleeasy',
  4: 'pc_biz_ote_lbl_littledifficult',

  // 实际开启的难易度及其顺序，通常用于筛选等菜单。 全量开启时： 0-易 3-较易 1-中 4-较难 2-难  默认： 0-易 1-中 2-难
  // values: [0, 3, 1, 4, 2],
  values: [0, 1, 2],

  // 全量的难易度，用于展示现有数据的难易度。
  fullValues: [0, 3, 1, 4, 2],
  // 减量的难易度
  simpleValues: [0, 1, 2]
};
QUES_LEVEL_NAMES.values = QUES_LEVEL_NAMES.simpleValues;
export const changeLevelType = (all, cb) => {
  const newValues = all ? QUES_LEVEL_NAMES.fullValues : QUES_LEVEL_NAMES.simpleValues;
  if (newValues !== QUES_LEVEL_NAMES.values) {
    QUES_LEVEL_NAMES.values = newValues;
    cb && typeof (cb) === 'function' && cb();
  }
};

/**
 * 初始化机构的试题难度类型
 */
export const initQuesLevelType = async(cb) => {
  try {
    const orgId = window.localStorage.orgId;
    const levelTypeAll = window.sessionStorage.ote_level_type_all || '';

    if (!orgId) return false;

    if (levelTypeAll && levelTypeAll.indexOf(orgId) === 0) {
      changeLevelType(levelTypeAll.split('_')[1] === '1', cb);
      return true;
    }

    await getQuesLevel().then(res => {
      window.sessionStorage.ote_level_type_all = `${orgId}_${res && res.fiveLevel ? 1 : 0}`;
      changeLevelType(res && res.fiveLevel, cb);
      return true;
    });
  } catch (error) {
  }
};

/**
 * 转化为树的数据结构
 * @param {Array} list 数据列表
 * @param {Array} props 数据实体字段，默认为 ['id', 'name', 'parentId']
 */
export const getTreeData = (list, props = ['id', 'name', 'parentId']) => {
  if (!list || list.length === 0) {
    return [];
  }
  let getTreeDatas = (pid) => {
    let datas = [];
    list.forEach(cata => {
      if (cata[props[2]] === pid || (!pid && !cata[props[2]])) {
        let cataOfEl = {
          ...cata,
          id: cata[props[0]],
          // value: cata.id,
          label: cata[props[1]]
        };
        cataOfEl.children = getTreeDatas(cataOfEl[props[0]]);
        // if (!cataOfEl.children || cataOfEl.children.length === 0) {
        //   cataOfEl.children = undefined
        // }
        cata.inTree = true;
        datas.push(cataOfEl);
      }
    });
    return datas;
  };
  let treeData = getTreeDatas('');
  // 存在子节点有权限，父节点无权限的情况
  // 把非根目录但是没父节点权限的目录挂载到根节点下
  list.forEach(cata => {
    if (!cata.inTree) {
      treeData.push({
        ...cata,
        id: cata[props[0]],
        // value: cata.id,
        label: cata[props[1]]
      });
    }
  });
  return treeData;
};

/**
 * 数字转字母
 * @param {string} num 数字
 */
export const convertASCIIForNum = (num) => {
  let itemCode = '';
  num = num + 65;
  if (num > 64 && num < 91) {
    itemCode = String.fromCharCode(num);
  }
  return itemCode;
};

export const isNullOrEmpty = s => {
  return s === null || s === '';
};

export const isString = (str) => {
  return typeof str === 'string';
};

const ReplaceAll = (r, e, t)=> {for (;r.indexOf(e) !== -1;)r = r.replace(e, t);return r.replace(/^\s+|\s+$/g, '');};

export const dateToString = (text, format, notReplace) => {
  if (isNullOrEmpty(text)) {
    return '';
  }
  if (!notReplace && isString(text)) {
    text = text.replace(/\.\d+/, '').replace(/-/g, '/');
  }
  let date = new Date(text);
  if (isNullOrEmpty(date) || isNaN(date.getDate())) {
    return '';
  }
  let dateStr = format;
  dateStr = dateStr
    .replace('yyyy', date.getFullYear())
    .replace('MM', (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1))
    .replace('dd', (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()))
    .replace('HH', (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()))
    .replace('mm', (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()))
    .replace('ss', (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()));
  dateStr = dateStr
    .replace('M', date.getMonth() + 1)
    .replace('d', date.getDate())
    .replace('H', date.getHours())
    .replace('m', date.getMinutes())
    .replace('s', date.getSeconds());
  return dateStr;
};

/**
 * 处理文本、富文本内容
 * @param {String} str 富文本内容
 * @param {Boolean} isInLine 是否需要在行内显示，此时需要处理第一个p
 */
export const cleanStyle = function(str, isInLine) {
  try {
    if (this.xss && typeof (this.xss) === 'function') {
      str = this.xss(str);
    }
  } catch (error) {
  }
  if (!str) {
    return '';
  }
  if (str) {
    if (str.indexOf('<p>') === 0 && str.indexOf('<p>') === str.lastIndexOf('<p>')) {
      // 单个纯粹的P, 直接去除。当成纯文本
      str = str.replace('<p>', '').replace('</p>', '');
    } else if (isInLine) {
      // 需要在行内展示的情况： A. ******
      // 处理原P标签，无样式的默认行替换掉第一行实现ABCD插入, 同时处理间距和富文本保持一致
      str = ReplaceAll(str.replace('<p>', '').replace('</p>', ''), '<p>', '<p class="mv10">');
    } else {
      // 其余情况 去除顶部margin-top来对其，同时处理间距和富文本保持一致
      str = ReplaceAll(str.replace('<p>', '<p class="mt0 mb10">'), '<p>', '<p class="mv10">');
    }
  }
  // // 去除图片中style样式
  // let reg = /(<img.+?>)/g
  // if (str && reg.test(str)) {
  //   str = str.replace(reg, (pattern) => {
  //     return pattern.replace(/\s+style="[^"]*"/g, ' ')
  //   })
  // }
  // 替换&nbsp; 单个空格留空格 多个每2个替换为 空格+&nbsp;
  let reg1 = /&nbsp;/g;
  let reg2 = / {2}/g;
  if (str) {
    str = str.replace(reg1, ' ');
    str = str.replace(reg2, ' &nbsp;');
  }
  // \r\n 改成<br>自动换行
  let brChange = dealLineFeed(str);
  return brChange;
};

/**
 * 处理换行符
 * @param {String} txt 文本
 * @param {Boolean} isNotHtml 是否非Html内容
 */
export const dealLineFeed = (txt, isNotHtml) => {
  if (txt) {
    if (isNotHtml) {
      txt = htmlEncode(txt); // 对多行文本就行编码预防xss
    }
    // 去掉所有的换行符
    txt = txt.replace(/\r\n/g, '<br />');
    txt = txt.replace(/\r/g, '<br />');
    txt = txt.replace(/\n/g, '<br />');
  }
  return txt;
};

export const htmlEncode = function(str) {
  if (str === undefined || str === null || str.length === 0) return '';
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/ /g, '&nbsp;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;')
    .replace(/·/g, '&#183;')
    .replace(/\\/g, '\\\\')
    .replace(/%/g, '%25');
};

export const htmlDecode = function(str) {
  if (str === undefined || str === null || str.length === 0) return '';
  return str
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&nbsp;/g, ' ')
    .replace(/&#39;/g, '\'')
    .replace(/&quot;/g, '"')
    .replace(/&#183;/g, '·')
    .replace(/\\\\/g, '\\')
    .replace(/%25/g, '%');
};

/**
 * 通用的错误处理方法
 * @param {Object} error 错误信息
 */
export const handleError = function(error) {
  if (error && error.error) {
    // 兼容处理
    error = error.error;
  }
  if (error) {
    if (error.key && error.key.indexOf('global.token') >= 0) {
      return; // 不弹出token错误
    }
    let msg = error.message;
    this.$message({
      message: msg,
      type: 'error'
    });
  }
};
