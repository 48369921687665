import { downloadMsgPush, setReadDownloadMsg } from './service';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import { productionUtil } from 'yxt-biz-pc/packages/common-util/productionUtil';
// 下载中心全局提示
class DownloadGlobalToast {
  constructor(vue) {
    this.vue = vue; // 当前vue实例
    this.userId = window.localStorage.userId || ''; // 当前用户id
    this.token = window.localStorage.token || ''; // token
    this.toastVisible = false;
    this.wsConfig = {
      // ws 配置信息
      wsUrl: `${this.getWsBaseUrl()}websocketsvc`, // ws 地址
      heartBeatTimer: 0, // 心跳定时器
      heartBeatTimeout: 180000, // 心跳间隔时间
      reconnectNum: 0, // 已重连次数
      reconnectNumMax: 3, // 重连最大次数
      reconnectTimeout: 5000, // 重连间隔时间
      reconnectTimer: 0 // 定时器
    };
    this.isConnect = false; // 是否建立 ws 连接成功
    this.lockReconnect = false; // 锁定重连，防止再一个重连在没请求完毕时又发起了重连
    this.isHandCloseWs = false; // 手动关闭连接
    if (!window.DOWMLOAD_TOAST_INFO) {
      window.DOWMLOAD_TOAST_INFO = {
        errorMsg: [],
        version: '1.0.0',
        config: this.wsConfig
      };
    }
    if (window._DOWNLOADGLOBALTOAST_) {
      return;
    } else {
      window._DOWNLOADGLOBALTOAST_ = true;
      this.init();
    }
  }

  get uuid() {
    return this.createUuid();
  }
  createUuid() {
    // s随机串
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === 'function') {
      d += performance.now(); // use high-precision timer if available
    }
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
  getWsBaseUrl() {
    const { wsBaseUrl } = window.feConfig.common;
    return wsBaseUrl;
  }
  init() {
    this.createWebScoket();
  }
  createWebScoket() {
    try {
      const ws = new WebSocket(this.wsConfig.wsUrl);
      this.initWsHandle(ws);
    } catch (error) {
      // 报错了就尝试重连
      this.reconnect();
    }
  }
  reconnect() {
    this.wsConfig.reconnectNum += 1;
    if (this.wsConfig.reconnectNum > this.wsConfig.reconnectNumMax) {
      // 已重连次数大于最大重连次数，直接返回
      console.log('3次连接失败，请稍后再试');
      return;
    }
    if (this.lockReconnect) return;
    this.lockReconnect = true;
    if (this.wsConfig.reconnectTimer) clearTimeout(this.wsConfig.reconnectTimer);
    this.wsConfig.reconnectTimer = setTimeout(() => {
      console.log(`重连第${this.wsConfig.reconnectNum}次`);
      this.lockReconnect = false;
      this.createWebScoket();
    }, this.wsConfig.reconnectTimeout);
  }
  initWsHandle(ws) {
    // 加载 ws 连接
    this.wsClose = ws.close;
    ws.onopen = () => {
      this.isConnect = true;
      console.log('连接成功，开始认证');
      this.sendAuthToken(ws);
    };
    ws.onmessage = (res) => {
      if (res.data) {
        const data = JSON.parse(res.data);
        console.log('接收服务器返回的信息', data);
        if (data.project && data.project === 'downapi') {
          // 代表推送了下载成功的消息
          this.getDownloadFinishedMsg();
        }
        if (data.state === 0) {
          // 代表连接成功，把重连次数置为0，用于再次重连时重新计算次数
          downloadMsgPush(this.userId); // 连接成功的时候主动告知下载中心推一次消息，防止在 token 未失效的情况下，下次在进入不会主动推未登录期间产生的消息
          this.wsConfig.reconnectNum = 0;
          this.isConnect = true;
        } if (data.state === 4) {
          this.handleHeartBeat(ws);
        } else {
          this.isConnect = false;
        }
      }
    };
    ws.onclose = (data) => {
      console.log('ws已关闭', data);
      if (this.isHandCloseWs) {
        // 手动关闭不做处理
        this.isHandCloseWs = false;
        return;
      }
      if (this.isConnect) {
        // 如果连接成功了并触发了关闭，那可能是自动断开的，进行重连处理
        this.reconnect();
      } else {
        // 说明是第一次连接就出错了，可能是token、userId不对导致的
        console.error('请检查认证信息是否正确，如：token、userId等');
      }
    };
    ws.onerror = () => {
      console.log('ws报错了');
      // 报错了执行重连机制
      this.reconnect();
    };
  }
  // 开始心跳处理
  handleHeartBeat(ws) {
    if (this.wsConfig.heartBeatTimer) clearTimeout(this.wsConfig.heartBeatTimer);
    this.wsConfig.heartBeatTimer = setTimeout(() => {
      ws.send(JSON.stringify(this.getHeartBeatPrams()));
    }, this.wsConfig.heartBeatTimeout);
  }
  // 获取心跳参数
  getHeartBeatPrams() {
    return {// 心跳监测参数
      messageId: this.uuid,
      type: 'heartbeat', // 心跳类型
      body: {}, // 扩展字段，非必填
      createTime: new Date() // 消息创建时间
    };
  }
  // 心跳监测函数
  heartBeatHandle(ws) {
    if (this.heartBeatTimer) {
      clearTimeout(this.heartBeatTimer);
    }
    this.heartBeatTimer = setTimeout(() => {
      ws.send(JSON.stringify(this.getHeartBeatPrams()));
    }, this.heartBeatTimeout);
  }
  sendAuthToken(ws) {
    // 发送认证
    const param = {
      jwt: this.token,
      project: 'downapi',
      module: 'export',
      id: this.userId,
      instanceId: this.uuid
    };
    ws.send(JSON.stringify(param));
    this.handleHeartBeat(ws);
  }
  createConnect() {
    // 创建链接
    this.isConnect = true;
  }
  toast() {
    if (this.isWhiteListPage()) {
      // 如果是白名单页面，就不弹了
      return false;
    }
    if (this.toastVisible === true) {
      return false;
    }
    this.toastVisible = true;
    const h = this.vue.$createElement;
    const _this = this;
    this.vue.$notify({
      title: i18n.t('pc_success').d('成功'),
      duration: 0,
      message: h('div', null, [
        h('div', null, i18n.t('pc_biz_download_toast_001').d('您导出的文件已生成成功！')),
        h('div', { class: 'download-toast-footer-box'}, [
          h('yxt-button', {
            on: {
              click: () => {
                setReadDownloadMsg(_this.userId);
                _this.toastVisible = false;
                DownloadGlobalToast.gotoDownloadPage();
              }
            },
            props: {
              type: 'primary'
            }
          }, i18n.t('pc_biz_download_toast_002').d('前往查看'))
        ])
      ]),
      type: 'success',
      onClose: () => {
        setReadDownloadMsg(this.userId);
        this.toastVisible = false;
        console.log('点击关闭操作');
      }
    });
  }
  getDownloadFinishedMsg() {
    // 获取下载完成的消息通知
    this.toast();
  }
  isWhiteListPage() {
    // 白名单页面不做弹框
    const whiteList = [
      'down/#/download',
      'report/#/bi/project_detail_screen',
      'report/#/bi/active_detail_screen',
      'report/#/bi/exam_detail_screen',
      'report/#/bi/course_detail_screen',
      'report/#/bi/screen'
    ];
    for (let i = 0; i < whiteList.length; i++) {
      const path = whiteList[i];
      if (window.location.href && window.location.href.includes(path)) {
        return true;
      }
    }
    return false;
  }
  static gotoDownloadPage() {
    // 前往下载页
    console.log('前往下载页');
    const allPath = productionUtil.getProductionInfo().allPath;
    window.open(`${allPath}/down/#/download`, '_blank');
  }
}

export default DownloadGlobalToast;
