/**
 * 格式化 文件尺寸
 * 用于上传文件 size Bytes 转换成 ' Bytes', ' KB', ' MB', ' GB', ' TB' 单位
 * @param {Number} size  单位：KB
 * @returns
 */
const getFileSize = (size) => {
  if (!size) return '';
  let num = 1024.00; // Bytes
  if (size < num) {
    return size.toFixed(2) + 'B';
  }
  if (size < Math.pow(num, 2)) {
    return (size / num).toFixed(2) + 'kB'; // KB
  }
  if (size < Math.pow(num, 3)) {
    return (size / Math.pow(num, 2)).toFixed(2) + 'M'; // M
  }
  if (size < Math.pow(num, 4)) {
    return (size / Math.pow(num, 3)).toFixed(2) + 'GB'; // GB
  }
  if (size < Math.pow(num, 5)) {
    return (size / Math.pow(num, 4)).toFixed(2) + 'TB'; // TB
  }
};
export default {
  getFileSize
};
