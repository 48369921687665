var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "select-popover",
        {
          ref: "selectPopover",
          staticClass: "d-in-block w200 mr12 mb12",
          attrs: { selectOpition: _vm.selectOpition, clearable: "" },
        },
        [
          _c(
            "div",
            { staticClass: "yxtbiz-kng-tree table_tree" },
            [
              _c("yxt-input", {
                attrs: {
                  placeholder: _vm.$t("pc_biz_kng_lbl_searchCatalog_source"),
                  searchable: "",
                },
                on: { search: _vm.searchCatalog },
                model: {
                  value: _vm.filterKngTree,
                  callback: function ($$v) {
                    _vm.filterKngTree = $$v
                  },
                  expression: "filterKngTree",
                },
              }),
              _c("yxt-tree", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.treeLoading,
                    expression: "treeLoading",
                  },
                ],
                ref: "filterTree",
                staticClass: "yxtbiz-biz-tree-funs mt16",
                class: _vm.kngTreeData.length > 0 ? "d-in-block" : "",
                staticStyle: { width: "100%" },
                attrs: {
                  "node-key": "id",
                  "empty-text": _vm.$t("pc_biz_kng_lbl_noCatalog"),
                  data: _vm.kngTreeData,
                  props: _vm.defaultProps,
                  "filter-node-method": _vm.filterNode,
                  "default-expanded-keys": _vm.treeExpand,
                  "expand-on-click-node": false,
                },
                on: { "node-click": _vm.ClickTreeNode },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.kngSelectType
        ? _c(
            "yxt-select",
            {
              staticClass: "w120 mr12 mb12",
              attrs: {
                placeholder: _vm.$t("pc_biz_kng_lbl_kngType_source"),
                clearable: "",
              },
              on: { change: _vm.searchLists },
              model: {
                value: _vm.kngValue,
                callback: function ($$v) {
                  _vm.kngValue = $$v
                },
                expression: "kngValue",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c(
                "yxt-option",
                {
                  key: item.value + Math.random(),
                  attrs: { label: item.label, value: item.value },
                },
                [
                  _c("span", { staticClass: "font-size-14" }, [
                    _vm._v(_vm._s(item.label)),
                  ]),
                ]
              )
            }),
            1
          )
        : _c(
            "yxt-select",
            {
              staticClass: "w120 mr12 mb12",
              attrs: {
                placeholder: _vm.$t("pc_biz_kng_lbl_course_source"),
                clearable: "",
              },
              on: { change: _vm.searchLists },
              model: {
                value: _vm.kngSource,
                callback: function ($$v) {
                  _vm.kngSource = $$v
                },
                expression: "kngSource",
              },
            },
            _vm._l(_vm.sourceTypes, function (item) {
              return _c(
                "yxt-option",
                {
                  key: item.value + Math.random(),
                  attrs: { label: item.label, value: item.value },
                },
                [
                  _c("span", { staticClass: "font-size-14" }, [
                    _vm._v(_vm._s(item.label)),
                  ]),
                ]
              )
            }),
            1
          ),
      _c(
        "div",
        { staticClass: "d-in-block w200 mr12 mb12" },
        [
          _c("yxt-input", {
            attrs: {
              placeholder: _vm.$t(
                _vm.kngSelectType
                  ? "pc_biz_kng_lbl_kngTitle_courseware_source"
                  : "pc_biz_kng_lbl_kngTitle_course_source"
              ),
              searchable: "",
            },
            on: {
              search: function ($event) {
                return _vm.searchLists(1)
              },
            },
            model: {
              value: _vm.kngInput,
              callback: function ($$v) {
                _vm.kngInput = $$v
              },
              expression: "kngInput",
            },
          }),
        ],
        1
      ),
      _c(
        "yxt-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.kngListLoading,
              expression: "kngListLoading",
            },
          ],
          ref: "stb",
          staticClass: "mt12",
          attrs: {
            data: _vm.KngTableData,
            "row-key": _vm.getRowKey,
            "selected-row-by-key": "isSelectedRow",
          },
          on: { "select-all": _vm.selectChange, select: _vm.selectChange },
        },
        [
          _c("yxt-table-column", {
            attrs: {
              type: "selection",
              selectable: _vm.selectable,
              width: "40",
              "reserve-selection": true,
              "show-overflow-tooltip": false,
            },
          }),
          _c("yxt-table-column", {
            attrs: {
              prop: "title",
              label: _vm.$t("pc_biz_kng_lbl_title"),
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { class: "kng_" + scope.row.fileType }),
                    _c(
                      "span",
                      {
                        staticClass:
                          "v-mid font-size-14 yxtbiz-knglistsource-tb-title",
                        on: {
                          click: function ($event) {
                            return _vm.preview(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.title) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("yxt-table-column", {
            attrs: {
              prop: "catalogName",
              label: _vm.$t("pc_biz_ote_lbl_classification"),
            },
          }),
        ],
        1
      ),
      _vm.totalKng > 10
        ? _c(
            "div",
            { staticClass: "mt24" },
            [
              _c("yxt-pagination", {
                staticClass: "pull-right",
                attrs: {
                  total: _vm.totalKng,
                  "page-size": _vm.perKngPage,
                  "current-page": _vm.currentKngPage,
                  "show-on-single-page": "",
                  layout: "total, prev, pager, next",
                },
                on: {
                  "update:pageSize": function ($event) {
                    _vm.perKngPage = $event
                  },
                  "update:page-size": function ($event) {
                    _vm.perKngPage = $event
                  },
                  "update:currentPage": function ($event) {
                    _vm.currentKngPage = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentKngPage = $event
                  },
                  "current-change": _vm.handleKngCurrentChange,
                  "size-change": _vm.handleKngSizeChange,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }