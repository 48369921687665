import { isOpenData } from 'yxt-biz-pc/src/utils/shared';
import OpenMethods from './open-methods';
export default {
  mixins: [OpenMethods],
  props: {
    setCurrentOrg: {
      type: Boolean,
      default: true
    },
    targetOrgId: {
      type: String,
      default: ''
    },
    visibleOrgIds: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    enableGroupCorp: {
      type: Boolean,
      default: false
    },
    visibleOrgSelector: {
      type: Boolean,
      default: true
    },
    // 导航功能权限
    functionCode: {
      type: String,
      default: ''
    },
    // 数据权限code
    dataPermissionCode: {
      type: String,
      default: ''
    },
    childrenIncluded: {
      // 是否开启相关操作功能，“后续全部”、“当前全部”、“当前节点”
      // 1 | 4: 后续全部、当前节点
      // 2: 当前全部、当前节点
      // 3 | true: 后续全部、当前全部、当前节点
      // 4: 后续全部
      // 0 | false: 无
      type: [Boolean, Number],
      default: false
    },
    openSelectSiblingNode: {
      // 是否开启选同级部门
      type: Boolean,
      default: false
    },
    defaultExpandedRoot: {
      // 是否默认展开根节点
      type: Boolean,
      default: true
    },
    setRootDefault: {
      // 设置默认选中根节点并触发node-click
      type: Boolean,
      default: false
    },
    checkedLinkage: { // 勾选父部门, 联动子部门及后续新增子部门 1 联动
      type: String,
      default: '1'
    },
    multiple: {
      // 是否多选
      type: Boolean,
      default: true
    },
    showCheckbox: {
      // 是否开启checkbox
      type: Boolean,
      default: false
    },
    filterable: {
      // 是否开启搜索过滤
      default: false,
      type: Boolean
    },
    functions: {
      // 是否开启功能操作
      default: false,
      type: Boolean
    },
    functionsRender: {
      // 自定义功能按钮
      type: Function,
      default: () => []
    },
    count: {
      // 是否展示部门人员数量
      type: Boolean,
      default: true
    },
    dropdownToBody: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isShowSearch() {
      return this.filterable && !isOpenData();
    },
    checkShowCheckbox() {
      return this.multiple && this.showCheckbox;
    },
    checkFunctions() {
      return this.multiple && this.functions;
    }
  },
  methods: {
    getFunsRenderBtn(data, funsBtnMap) {
      const funsBtns = [];
      if ((this.childrenIncluded === 1 || this.childrenIncluded === 3 || this.childrenIncluded === 4 || this.childrenIncluded === true) && data.hasAllDeptPermission) {
        funsBtns.push(funsBtnMap.funs1); // 加入后续新增操作
      }
      if (this.openSelectSiblingNode) {
        funsBtns.push(funsBtnMap.funs3); // 加入同级部门操作
      }
      if (this.childrenIncluded === 2 || this.childrenIncluded === 3 || this.childrenIncluded === true) {
        funsBtns.push(funsBtnMap.funs2); // 加入当前全部操作
      }
      if (this.childrenIncluded && data.hasAllDeptPermission) {
        funsBtns.push(funsBtnMap.funs4); // 加入当前节点操作
      }
      return funsBtns;
    }
  }
};
