import { i18n } from 'yxt-i18n/es';
import { Message } from 'yxt-pc';
import { productionUtil } from './productionUtil';
const productInfo = productionUtil && productionUtil.getProductionInfo();
// 区分不同产品
const allPath = (productInfo && productInfo.allPath) || '';
// 跳转登录的key
const loginKeys = [
  'global.token.invalid',
  'global.token.empty',
  'global.user.kicked.out',
  'global.user.kicked.app.out',
  'global.user.kicked.pc.out',
  'global.not.authorized'
];

// 用户被踢出的key
const kicedKeys = [
  'global.user.kicked.out',
  'global.user.kicked.app.out',
  'global.user.kicked.pc.out'
];

// ip白名单key
const iplimitKey = 'global.ip.restriction';

function clearCookieForKey() {
  window.clearCookie('isClickMy');
  window.clearCookie('userId');
  window.clearCookie('orgId');
  window.clearCookie('email');
  window.clearCookie('mobile');
  window.clearCookie('headPictureUrl');
  window.clearCookie('isEmailValidated');
  window.clearCookie('isMobileValidated');
  window.clearCookie('token');
  window.clearCookie('isClickMy');
  window.clearCookie('toolbar');
  window.clearCookie('apps');
  window.clearCookie('clientKey');
  window.clearCookie('deviceId');
  // window.clearCookie("returnUrl");
  window.clearCookie('toolbarnew');
}

function clearLocalStorageCache() {
  var storage = window.localStorage;
  var len = storage.length;
  for (var i = len; i >= 0; i--) {
    var key = storage.key(i);
    if (key && key.indexOf('CACHEDATA') > -1) {
      localStorage.removeItem(key);
    }
  }
}
function clearLocalStorageForKey() {
  localStorage.removeItem('userId');
  localStorage.removeItem('orgName');
  localStorage.removeItem('websitepicurl');
  localStorage.removeItem('email');
  localStorage.removeItem('mobile');
  localStorage.removeItem('headPictureUrl');
  localStorage.removeItem('isEmailValidated');
  localStorage.removeItem('isMobileValidated');
  localStorage.removeItem('token');
  localStorage.removeItem('toolbar');
  localStorage.removeItem('apps');
  localStorage.removeItem('isClickMy');
  localStorage.removeItem('clientKey');
  localStorage.removeItem('deviceId');
  localStorage.removeItem('schemeversion');
  localStorage.removeItem('newIndex');
  localStorage.removeItem('toolbarnew');
  sessionStorage.clear();
  localStorage.removeItem('sex');
  localStorage.removeItem('ouId');
  localStorage.removeItem('ouName');
  localStorage.removeItem('positionId');
  localStorage.removeItem('positionName');
  localStorage.removeItem('searchKnowledgeKeywords');
  localStorage.removeItem('oldhuazhuad');
  localStorage.removeItem('examSource');
  localStorage.removeItem('communitySearch');
  localStorage.removeItem('personalPermissionCodes');
  localStorage.removeItem('indexFunctionRoutes');
  localStorage.removeItem('indexLastNotice');
  localStorage.removeItem('masterH5Url');
  localStorage.removeItem('masterPcUrl');
  localStorage.removeItem('mainOrgId');
  localStorage.removeItem('isVisitor');
  clearLocalStorageCache();
}

function getAuthUrlByOrgId(key, aliceKey) {
  const orgId = getQueryString(key) || getQueryString(aliceKey);
  let hash = '/login';
  let href = `${allPath}/#${hash}`;
  if ((window.isDing || window.isWeixin) && orgId) {
    hash = `/scanentry/${orgId}?lru=${encodeURIComponent(
      window.location.href
    )}`;
    href = `${window.location.protocol}//${window.location.host}/#${hash}`;
  }
  return { hash, href };
}

function getQueryString(name) {
  try {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    r = window.location.hash
      .substr(window.location.hash.indexOf('?') + 1)
      .match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return null;
  } catch (e) {
    return null;
  }
}

export const checkLogin = function(errorKey) {
  if (errorKey) {
    if (loginKeys.includes(errorKey)) {
      if (kicedKeys.includes(errorKey)) {
        Message.error(
          i18n
            .t('pc_biz_msg_kickedout')
            .d(
              '检测到账号在其他地方登录，已退出登录，如非本人操作请立即修改密码。'
            )
        );
      }
      try {
        window.clearLocalStorageForKey
          ? window.clearLocalStorageForKey()
          : clearLocalStorageForKey();
      } catch (e) {
        window.clearCookieForKey
          ? window.clearCookieForKey()
          : clearCookieForKey();
      }
      window.localStorage.setItem('returnUrl', window.location.href);
      setTimeout(() => {
        window.location.href = getAuthUrlByOrgId('og', 'orgid').href; // 去登录页
      }, 1000);
    }
  }
};

export const checkIpLimit = function(errorKey) {
  if (errorKey === iplimitKey) {
    window.localStorage.setItem('reUrl', window.location.href);
    window.location.replace(
      `/default-pages/iplimit.html?reUrl=${window.location.href}`
    ); // 不在白名单，跳转到白名单页面
  }
};

export const axiosReject = function(error, errorKey) {
  if (errorKey) {
    checkLogin(errorKey);
    checkIpLimit(errorKey);
  }
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error
  ) {
    switch (error.response.status) {
      case 401:
        checkLogin(error.response.data.error.key);
        break;
      case 403:
        checkIpLimit(error.response.data.error.key);
        break;
      default:
    }
  }
  return Promise.reject(error);
};

export const getErrorKeys = function(type) {
  let res = [];
  switch (type) {
    case 'login':
      res = loginKeys;
      break;
    case 'kicked':
      res = kicedKeys;
      break;
    case 'iplimit':
      res = iplimitKey;
      break;
    default:
      break;
  }
  return res;
};

export const errorPage = function(code) {
  switch (code) {
    case 'unusable':
    case '500':
    case '50x':
    case 'fun-doing':
      return window.location.replace(`/default-pages/${code}.html`);
    default:
      return window.location.replace('/default-pages/404.html');
  }
};
