import Eco from 'yxt-eco/es';
import openGroupChat from './src/groupChat';
/**
 Eco组件 + 额外附加的用法
 Eco = {
    init: init,
    EcoType: EcoType,
    config: config,
    Api: Api
  };
  额外附加：{
    openGroupChat
  }

 */
const eco = {
  ...Eco,
  openGroupChat
};
export default eco;
