import { addResizeListener, removeResizeListener } from 'yxt-biz-pc/src/utils/resize-event';
export default {
  props: {
    showCheckbox: {
      default: false,
      type: Boolean
    },
    checkStrictly: {
      default: false,
      type: Boolean
    },
    filterable: {
      default: false,
      type: Boolean
    },
    functions: {
      default: false,
      type: Boolean
    },
    functionsRender: {
      type: Function,
      default: () => {
        return [];
      }
    },
    defaultExpandedKeys: {
      type: Array,
      default: () => {
        return [''];
      }
    },
    defaultCheckedKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    setRootDefault: {
      type: Boolean,
      default: false
    },
    highlightCurrent: {
      type: Boolean,
      default: true
    },
    defaultDisabled: {
      type: Array,
      default: () => {
        return [];
      }
    },
    treeProps: {
      type: Object,
      default: () => {
        return {};
      }
    },
    count: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: ''
    },
    functionsPlacement: {
      type: String
    },
    wrapWidth: {
      type: [Number, String],
      default: 320
    },
    native: {
      type: Boolean,
      default: false
    },
    dropdownToBody: {
      type: Boolean,
      default: true
    },
    autosize: {
      type: Boolean, Number,
      default: false
    },
    // 对autosize做防抖，减轻性能消耗
    autosizeDebounce: {
      type: Boolean,
      default: true
    },
    debounceDelay: {
      type: Number,
      default: 300
    },
    // 导航功能权限
    functionCode: {
      type: String,
      default: ''
    },
    // 数据权限code
    dataPermissionCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      props: {
        label: 'name',
        children: 'children'
      },
      // data: [],
      // originData: [],
      loading: false,
      filterText: '',
      expandTemp: [],
      expandTempShow: [],
      needUpdateDisabled: false,
      key: 'id',
      rootId: '',
      parentEle: null,
      resizeTimer: null,
      filterNodeRoutingPaths: []
    };
  },
  methods: {
    init() {
      this.loading = true;
      this.data = [];
      this.expandTemp = [...this.defaultExpandedKeys];
      this.dataInit();
    },
    refresh() {
      this.dataInit();
    },
    handlerCheck(...argu) {
      this.$emit('check', ...argu);
    },
    handleCheckChange(...argu) {
      this.$emit('checkChange', ...argu);
    },
    nodeClick(...argu) {
      this.$emit('nodeClick', ...argu);
    },
    nodeExpand(data, node, ref) {
      // console.log('tree.js --- nodeExpand -----: ', data[this.key]);
      this.$emit('nodeExpand', data, node, ref);
      this.expandTemp.push(data[this.key]);
      // console.log(this.expandTemp);
    },
    nodeCollapse(data, node, ref) {
      // console.log('tree.js --- nodeCollapse -----: ', data[this.key]);
      this.$emit('nodeCollapse', data, node, ref);
      this.expandTemp.splice(this.expandTemp.indexOf(data[this.key]), 1);
      // console.log(this.expandTemp);
    },
    filterNode(value, data) {
      if (!value) return true;
      const isFilterNode = data.name.indexOf(value) !== -1;
      if (isFilterNode) {
        console.log(data.name, '当前过滤值', data);
        this.filterNodeRoutingPaths.push(data.routingPath);
      } else {
        if (this.filterNodeRoutingPaths.length) {
          return !!this.filterNodeRoutingPaths.find(item => data.routingPath.includes(item));
        }
      }
      return isFilterNode;
    },
    setDisabled(key, bool = true) {
      let node = this.$refs.tree.getNode(key);
      if (!node) {
        return;
      }
      let data = node.data;
      if (data.disabled !== bool) {
        this.needUpdateDisabled = true;
        data.disabled = bool;
      }
      // if (node.nextSibling) {
      //   this.$refs.tree.remove(node);
      //   this.$refs.tree.insertBefore(data, node.nextSibling);
      // } else if (node.previousSibling) {
      //   this.$refs.tree.remove(node);
      //   this.$refs.tree.insertAfter(data, node.previousSibling);
      // } else if (node.parent) {
      //   this.$refs.tree.remove(node);
      //   this.$refs.tree.append(data, node.parent);
      // } else {
      //   this.$set(this.data, 0, data);
      // }
    },
    setDisabledList() {
      if (!this.showCheckbox) {
        return;
      }
      if (this.defaultDisabled.length === 0) {
        return;
      }
      this.defaultDisabled.forEach(v => {
        this.setDisabled(v);
      });
      if (this.needUpdateDisabled) {
        // fixme 性能
        this.$set(this.data, 0, {...this.data[0]});
      }
    },
    formatCheckNodes(checkNodes) {
      // 对后续新增的数据做处理
      let folllowAll = [];
      let currentAll = [];
      const folllowAllIds = new Set();
      checkNodes.map((item, index) => {
        if (item.includeAll) {
          item.ORDER = index;
          folllowAll.push(item);
          folllowAllIds.add(item.id);
        } else {
          item.ORDER = index;
          currentAll.push(item);
        };
      });
      folllowAll = folllowAll.filter(item => !folllowAllIds.has(item.parentId));
      return [...folllowAll, ...currentAll].sort((a, b) => a.ORDER - b.ORDER);
    },
    getRootCheckedKeys() {
      let root = this.$refs.tree.getNode(this.rootId);
      if (!root) {
        return;
      }
      let res = [];
      this.getCheckedFromList(root.childNodes, res);
      return res;
    },
    getCheckedFromList(nodeList, res) {
      nodeList.forEach(node => {
        if (node.checked) {
          res.push(node.key);
        } else if (node.childNodes.length > 0) {
          this.getCheckedFromList(node.childNodes, res);
        }
      });
    },
    getCheckedNodes() {
      // 如果是包含后续新增的数据，需要去除掉，只返回父级
      return this.formatCheckNodes(this.$refs.tree.getCheckedNodes());
    },
    setCheckedNodes(...argu) {
      return this.$refs.tree.setCheckedNodes(...argu);
    },
    getCheckedKeys(...argu) {
      return this.$refs.tree.getCheckedKeys(...argu);
    },
    setCheckedKeys(...argu) {
      return this.$refs.tree.setCheckedKeys(...argu);
    },
    setCurrentNode(...argu) {
      return this.$refs.tree.setCurrentNode(...argu);
    },
    setCurrentKey(...argu) {
      return this.$refs.tree.setCurrentKey(...argu);
    },
    getCurrentKey(...argu) {
      return this.$refs.tree.getCurrentKey(...argu);
    },
    getCurrentNode(...argu) {
      return this.$refs.tree.getCurrentNode(...argu);
    },
    getNode(...argu) {
      return this.$refs.tree.getNode(...argu);
    },
    append(data, parentNode) {
      // 添加某一个节点
      this.$refs.tree.append(data, parentNode);
    },
    insertBefore(data, refNode) {
      this.$refs.tree.insertBefore(data, refNode);
    },
    insertAfter(data, refNode) {
      this.$refs.tree.insertAfter(data, refNode);
    },
    setChecked(...argu) {
      return this.$refs.tree.setChecked(...argu);
    },
    filter() {
      this.$refs.tree.filter(this.filterText);
    },
    // 动态计算父级宽高
    computedAutosize() {
      if (!this.parentEle) {
        return;
      }
      let bound = window.getComputedStyle(this.parentEle);
      if (this.autosize && this.autosize !== 2) {
        this.$refs.currentEle.style.width = bound.width;
      }
      if (this.autosize && this.autosize !== 1) {
        this.$refs.currentEle.style.height = bound.height;
      }
      let funs = this.$refs.tree.$el;
      if (funs) {
        if (this.filterable) {
          if (this.autosize && this.autosize !== 2) {
            funs.style.width = bound.width;
          }
          if (this.autosize && this.autosize !== 1) {
            funs.style.height = parseInt(bound.height, 10) - 66 + 'px';
          }
        } else {
          if (this.autosize && this.autosize !== 2) {
            funs.style.width = bound.width;
          }
          if (this.autosize && this.autosize !== 1) {
            funs.style.height = parseInt(bound.height, 10) - 10 + 'px';
          }
        }
      }
      if (this.$refs.input) {
        if (this.autosize && this.autosize !== 2) {
          this.$refs.input.$el.style.width = bound.width;
        }
      }
    },
    debounce(fn, during) {
      if (this.resizeTimer) {
        clearTimeout(this.resizeTimer);
      }
      this.resizeTimer = setTimeout(fn, during);
    },
    debounceAutosize() {
      if (this.autosizeDebounce) {
        this.debounce(this.computedAutosize, this.debounceDelay);
      } else {
        this.computedAutosize;
      }
    }
  },
  created() {
    // tree处理大量数据时，不能使用响应式数据
    this.data = [];
    this.originData = [];
  },
  activated() {
    if (this.autosize) {
      this.computedAutosize();
    }
  },
  mounted() {
    this.init();
    if (this.autosize) {
      this.computedAutosize();
      // todo 过多的 autosize 会造成性能问题
      this.parentEle = this.$refs.currentEle.parentNode;
      addResizeListener(this.parentEle, this.debounceAutosize);
    }
  },
  beforeDestroy() {
    if (this.autosize) {
      removeResizeListener(this.parentEle, this.debounceAutosize);
    }
  },
  watch: {
    // filterText(val) {
    //   if (!this.filterable) {
    //     return;
    //   }
    //   // todo 大数据量不适用watch
    //   this.$refs.tree.filter(val);
    // }
  }
};
