<template>
  <div class="yxtbiz-queslib-drawer">
    <div class="yxtbiz-queslib-drawer__treetable">
      <div class="yxtbiz-queslib-drawer__treetable-left">
        <div class="mb16 yxtbiz-question-flex">
          <!-- 题库分类 -->
          <catalogDropdown
            :data="datas"
            size="small"
            class="d-in-block width144"
            :dropToolTip="$t('pc_biz_ote_lbl_classification')"
            :catalogId.sync="treeSearchData.catalogId"
            :catalogName.sync="treeSearchData.catalogName"
            @change="catalogChange"
          ></catalogDropdown>
          <!-- 搜索 -->
          <yxt-input
            class="d-in-block ml12"
            :class="$isChinese ? 'width236' : 'width280'"
            v-model="treeSearchData.keywords"
            :disabled="treeLoading"
            :placeholder="$t('pc_biz_ote_lbl_liborpoint')"
            suffix-icon="yxt-icon-search"
            size="small"
          ></yxt-input>
        </div>
        <yxt-tree
          ref="tree"
          v-loading="treeLoading"
          :filter-node-method="filterNode"
          :data="libandpointsData"
          show-checkbox
          :wrap-width="'100%'"
          node-key="id"
          virtual
          :props="defaultProps"
          @check="handleCheckChange"
        >
          <span slot-scope="{ node, data }" class="custom-tree-node mt8 mb8">
            <span class="d-in-block lh22">{{ node.label }}</span>
            <yxt-tooltip
              v-if="data.shared === 1"
              :max-width="300"
              :content="data.sharedFullname + $t('pc_biz_ote_btn_share')"
            >
              <yxt-svg
                :remote-url="svgRemote"
                icon-class="icon-share"
                class="hand vertical-align-middle"
                width="18px"
                height="18px"
              ></yxt-svg>
            </yxt-tooltip>
            <div v-if="data.updateTime" class="yxtbiz-selector-updatetime color-gray-6 font-size-12 ml24 lh18">{{ $t('pc_biz_ote_lbl_updatetime' /* 更新日期 */) }}：{{ dateToString(data.updateTime, 'yyyy-MM-dd') }}</div>
          </span>
        </yxt-tree>
      </div>
    </div>
  </div>
</template>

<script>
import {
  handleError,
  dateToString
} from './utils';
import rootCatalog from './selector/rootCatalog';
import catalogDropdown from './selector/catalogDropdown';
import * as quesService from './service.js';
import { scrollbarWidth } from 'yxt-pc';
export default {
  name: 'QuesSelectorLeft',
  props: {
    // 页面功能编号（暂时都走的测练这边的权限，暂时不能改）
    functionCode: {
      type: String,
      default: 'ote_ques_lib_mgmt'
    }
  },
  components: {
    catalogDropdown
  },
  mixins: [rootCatalog],
  data() {
    return {
      svgRemote: `${this.$staticBaseUrl}ufd/55a3e0/ote/pc/svg`,
      datas: this.wrapRootCatalog(),
      treeLoading: true,
      treeSearchData: {
        // 题库考核点查询条件
        navCode: this.functionCode,
        keywords: '',
        queryType: '',
        catalogId: '',
        catalogRoutingPath: '',
        catalogName: ''
      },
      libandpointsData: [],

      defaultProps: {
        children: 'children',
        label: 'label'
      },
      points: []
    };
  },

  computed: {
    gutter() {
      return scrollbarWidth();
    },
    scrollWrapHeight() {
      return `calc(100% + ${this.gutter}px)`;
    }
  },

  watch: {
    'treeSearchData.keywords'(val) {
      this.$refs.tree.filter(val.trim());
    }
  },
  created() {
    this.setCatalogType(0);
  },
  mounted() {
    this.getCatalog();
    this.getlibandpointsData();
  },
  methods: {
    handleError,
    dateToString,
    // 获取分类树数据
    getCatalog() {
      quesService.getCatalogListForSelect().then(catalogs => {
        this.datas = this.initCatalogList(catalogs);
      });
    },
    // 获取题库考核点信息
    getlibandpointsData() {
      this.selectQuesList = [];
      quesService
        .getLibAndPoints(this.treeSearchData)
        .then(res => {
          this.libandpointsData = res;
          this.treeLoading = false;
          this.$nextTick(() => {
            this.nodeArry && this.$refs.tree.setCheckedNodes(this.nodeArry);
            if (this.$refs.tree) {
              this.$refs.tree.$el.querySelector('.yxt-scrollbar__wrap').style.height = this.scrollWrapHeight;
            }
          });
        })
        .catch(this.handleError.bind(this));
    },
    // 题库考核点树的关键词筛选
    filterNode(value, data) {
      return !value || data.label.indexOf(value) !== -1;
    },

    // 切换题库分类
    catalogChange(data) {
      this.treeSearchData.catalogId = data.id;
      this.treeSearchData.catalogName = data.name;
      this.treeSearchData.catalogRoutingPath = data.routingPath;
      this.getlibandpointsData();
    },

    // 切换左侧选择的题库范围
    handleCheckChange() {
      this.nodeArry = this.$refs.tree.getCheckedNodes(true, true);
      this.points = this.nodeArry;
    }
  }
};
</script>

