
<template>
  <div class="yxtbiz-upload" @click="handleClick">
    <dragger v-if="drag" :disabled="disabled" :icon-class="iconClass" @file="uploadFiles">
      <slot></slot>
      <slot name="tip" slot="tip"></slot>
    </dragger>
    <slot v-else></slot>
    <form class="yxtbiz-upload__input" ref="form">
      <input type="file" ref="input" @change="handleFileChange" :multiple="multipe" :accept="accept" />
    </form>
    <div v-if="disabled" class="yxtbiz-upload--disabled"></div>
  </div>
</template>

<script>
import Dragger from './dragger';
import { setFileApiSource, setFileApiTicket, getUploadConfig, getUploadConfigNew, generatePath, saveFileInfo, getTencentToken, getMixingInfo, getGroupList, getMainServerList, getUploadLimit, getLocalGroupServiceId } from './service';
import { getFileExtension, getFileType, getFileClass, checkAccept, checkSize, expandAccept, uuid, NetworkInfo, isDataType } from './utils';
import SparkMD5 from 'spark-md5';
import { loadScript } from 'yxt-biz-pc/src/utils/util';
// import { imgBlur } from './handle-image';

function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function createThumbnail(file) {
  // 创建缩略图
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      const IMG = new Image();
      IMG.src = e.target.result;
      IMG.crossOrigin = '*';
      IMG.onload = function() {
        let imgW = 300;
        let imgH = 300;
        if (IMG.width >= IMG.height && IMG.width > imgW) {
          // 原图宽比高大，并且比 300 大，宽设置成 300， 高等比例缩小
          imgH = IMG.height * imgW / IMG.width;
        } else if (IMG.width <= IMG.height && IMG.height > imgH) {
          imgW = IMG.width * imgH / IMG.height;
        } else {
          imgW = IMG.width;
          imgH = IMG.height;
        }
        const canvas = document.createElement('canvas');
        canvas.width = imgW;
        canvas.height = imgH;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(IMG, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL(file.type));
      };
    };
  });
}

const noop = () => {};
const size4M = 1048576 * 4;
const size10M = 1048576 * 10;

export default {
  name: 'YxtbizUpload',
  components: {
    Dragger
  },
  props: {
    isV1: Boolean,
    source: String, // 产线source
    ticket: { type: String, default: '' }, // 后端生成的上传用票据，类似token
    appCode: { // 微应用编号
      type: String,
      required: true
    },
    isOpen: {
      // 是否是公开空间
      type: Boolean,
      default: false
    },
    filters: {
      // 需要过滤的文件类型，逗号隔开，示例：.ppt,.pptx,.xls,.xlsx,.pps
      type: String,
      default: ''
    },
    // 是否返回图片文件的缩略图（目前只支持图片格式）
    openImageThumbnails: {
      type: Boolean,
      default: false
    },
    configKey: { // 上传配置key
      type: String,
      required: true
    },
    fileType: {
      // 上传文件类型，默认自动取文件后缀，传了就表示使用传入的文件后缀名做后续操作（目前在根据文件后缀取不同上传大小限制用到）
      type: String,
      default: ''
    },
    moduleName: {
      type: String,
      required: true
    },
    functionName: {
      type: String,
      required: true
    },
    multipe: Boolean,
    autoUpload: {
      type: Boolean,
      default: true
    },
    drag: Boolean,
    disabled: Boolean,
    convert: {
      type: Boolean,
      default: true
    },
    md5: Boolean,
    disableFilters: String,
    maxSize: {
      type: Number,
      validator: value => {
        return value > 0;
      }
    },
    errorTip: Boolean,
    isLocal: Boolean, // 是否上传到私有云
    localGroupId: { // 私有云服务器组Id
      type: String,
      default: ''
    },
    onReady: {
      type: Function,
      default: noop
    },
    completeUploded: {
      type: Function,
      default: noop
    },
    filesFilter: {
      type: Function,
      default: noop
    },
    filesAdded: {
      type: Function,
      default: noop
    },
    beforeUpload: {
      type: Function,
      default: noop
    },
    onNetworkSpeed: {
      type: Function,
      default: noop
    },
    onProgress: {
      type: Function,
      default: noop
    },
    onUploaded: {
      type: Function,
      default: noop
    },
    onComplete: {
      type: Function,
      default: noop
    },
    onError: {
      type: Function,
      default: noop
    },
    iconClass: String,
    convertKey: Number,
    isBizMixDeploy: {
      type: Boolean,
      default: false
    },
    // 业务传参 是否转码
    transcodeOptions: {
      type: Boolean | Object
    }
  },
  data() {
    return {
      publicReadHost: '', // 私有云空开空间访问地址
      uploadConfig: null,
      isLocalApi: false,
      v1Url: {
        dev: 'https://api-component.yunxuetang.com.cn/v1/',
        stg: 'https://api-component1.yxt.com/v1/',
        prod: 'https://api-component.yxt.com/v1/'
      },
      transcodeObj: {
        image: {
          transcode: 'N',
          private: 'N'
        },
        video: {
          transcode: 'Y',
          private: 'Y'
        },
        audio: {
          transcode: 'Y',
          private: 'Y'
        },
        doc: {
          transcode: 'Y',
          private: 'Y'
        },
        zip: {
          transcode: 'N',
          private: 'Y'
        }
      },
      cloudType: '',
      newLocal: false
    };
  },
  computed: {
    accept() {
      if (/windows.*wxwork/i.test(window.navigator.userAgent)) { // windows企业微信客户端
        return '*';
      }
      return expandAccept(this.filters || (this.uploadConfig && this.uploadConfig.filters));
    },
    uptokenUrl() {
      let baseUrl = window.feConfig && window.feConfig.common && window.feConfig.common.file || 'https://api-phx-di.yunxuetang.com.cn/file/';
      if (this.isV1) {
        baseUrl = this.v1Url[window.feConfig && window.feConfig.apiEnv];
        return `${baseUrl}/token/upload`;
      }
      if (!(/\/$/.test(baseUrl))) baseUrl = baseUrl + '/';
      return `${baseUrl}${(this.isLocal || this.newLocal) || this.isLocalApi ? `token/upload4LocalAll?platform=local&orgId=${window.localStorage.orgId}` : 'token/upload'}`;
    }
  },
  watch: {
    localGroupId(newVal) {
      this.createdInit();
      // this.canUpload = false;
      // getUploadConfigNew(newVal).then(res => {
      //   this.canUpload = true;
      //   this.uploadConfig.local = res;
      //   if (this.bceUploader) { // 如果已经初始化过百度云上传对象，则更新endpoint
      //     this.bceUploader.setOptions({ bos_endpoint: this.uploadConfig.isOpen ? res.openEndpoint : res.secretEndpoint });
      //   }
      // });
    }
  },
  async created() {
    loadScript(`${this.$staticBaseUrl}assets/c304f10a/0b8f1ba9/bce-bos-uploader-lite.js`).then(() => {
      loadScript(`${this.$staticBaseUrl}assets/37608d83/4931f246/cos-js-sdk-v5.js`).then(() => {
        this.createdInit();
      });
    });
  },
  methods: {
    async createdInit() {
      console.log('上传组件初始化～');
      let isLocal = this.isLocal || false;
      if (!this.isLocal && window.feConfig.orgCode) {
        // 组件没有接到私有化参数的情况下，查一下机构参中有没有配置私有化
        const { local, localGroupId } = await getLocalGroupServiceId();
        isLocal = local;
        this.newLocal = local;
        this.localGroupId = localGroupId;
      }

      this.computedFileTranscode();
      if (this.source) { // 如果使用上传组件时传入source，则覆盖项目引用业务组件时传入的source
        setFileApiSource(this.source);
      }

      // 设置上传时接口tickect参数
      this.ticket && setFileApiTicket(this.ticket);

      this.tencentQueue = {}; // 腾讯云文件上传队列
      this.baiduQueue = {}; // 百度云、私有云文件上传队列
      this.networkInfo = new NetworkInfo(); // 上传队列网络信息

      if (this.isV1) { // 1.0上传
        getUploadConfig(this.configKey).then(res => {
          this.canUpload = true;
          this.uploadConfig = {};
          const lowerCaseFirstWord = str => {
            if (!str) return '';
            return str.replace(str[0], str[0].toLowerCase());
          };
          for (let key in res) {
            this.uploadConfig[lowerCaseFirstWord(key)] = res[key];
          }
          if (res.endPoint || res.EndPoint) {
            this.uploadConfig.globalOpenDomain = res.endPoint || res.EndPoint;
          }
          // 以下key上传到公开空间
          if (['ImageConfigKey', 'AudioConfigKey', 'AttachOpenConfigKey'].includes(this.configKey)) {
            this.uploadConfig.isOpen = true;
          }
          // 1.0配置转换为2.0配置
          if (!this.uploadConfig.bucketName && this.uploadConfig.endPoint) {
            this.uploadConfig.bucketName = this.uploadConfig.endPoint.replace('.cdn.bcebos.com', '');
          }
          const domainMatch = (this.uploadConfig.domainUrl || '').match(/\/\/([^/]*)/);
          const baiduDomain = domainMatch && domainMatch.length > 1 ? domainMatch[1] : '';
          const baiduEndPoint = this.uploadConfig.endPoint || (this.uploadConfig.bucketName + '.cdn.bcebos.com');
          this.uploadConfig.baidu = {
            openBucket: this.uploadConfig.bucketName,
            secretBucket: this.uploadConfig.bucketName,
            openEndpoint: baiduEndPoint,
            secretEndpoint: baiduEndPoint,
            openDomain: baiduDomain,
            secretDomain: baiduDomain,
            globalOpenDomain: this.uploadConfig.globalOpenDomain
          };
        });
      } else { // 2.0上传
        const promises = [loadScript(window.feConfig && window.feConfig.common && window.feConfig.common.uploadConfig || 'https://media-phx.yunxuetang.com.cn/dynamic/common/upload-config-keys.js')];
        if (isLocal) { // 上传到私有云，并且有ID
          if (this.localGroupId) {
            promises.push(getUploadConfigNew(this.localGroupId));
          } else {
            console.error('调用私有云上传未传入 localGroupId');
          }
          // this.localGroupId ? promises.push(getUploadConfigNew(this.localGroupId)) : promises.push(getUploadConfigNew());
        } else {
          promises.push(getUploadConfigNew());
        }
        // 获取上传限制配置
        promises.push(getUploadLimit({ orgId: window.localStorage.orgId, configKey: this.configKey }));
        window.Promise.all(promises).then(async([_, res, limitConfig]) => {
          this.canUpload = !isLocal || !!this.localGroupId; // 非私有云上传 或者 有localGroupId 才 canUpload
          res || (res = {});
          const cdnFileConfig = window.uploadConfigKeys[this.configKey] || {}; // 这是cdn上的上传限制配置参数
          let fileLimitCongig = {};
          if (!limitConfig) {
            // 没有就采用cdn上的
            fileLimitCongig = { ...cdnFileConfig };
          } else {
            // 由于获取的配置参数是string类型，先做一次处理
            for (const key in limitConfig) {
              if (Object.hasOwnProperty.call(limitConfig, key)) {
                const value = limitConfig[key];
                if (/^[0-9]+$/.test(value)) {
                  fileLimitCongig[key] = Number(value);
                }
              }
            }
            // 验证有没有开启 isOpen 公开空间配置
            if (this.isOpen) {
              fileLimitCongig.isOpen = true;
            } else {
              // 如果组件没配置则默认使用cdn配置中的参数
              fileLimitCongig.isOpen = cdnFileConfig.isOpen;
            }
            // 验证有没有配置上传文件限制
            if (this.filters) {
              fileLimitCongig.filters = this.filters;
            } else {
              // 没传就采用cdn上的配置
              fileLimitCongig.filters = cdnFileConfig.filters || '*';
            }
          }

          this.uploadConfig = {
            ...res,
            ...fileLimitCongig
          };
          console.log('this.uploadConfig', this.uploadConfig);
          // 上传到私有云的，把上传配置加到local属性
          this.uploadConfig.local = res;
          let mixinRes = await getMixingInfo();
          if (!mixinRes) mixinRes = {};
          let isLocalApi = false;
          let { localSwith, uploadToCloud } = mixinRes;
          if (!uploadToCloud) {
            uploadToCloud = '0';
          }
          if (!localSwith) {
            localSwith = 0;
          }
          // localSwith为空或0 公有云
          // kng、knglib 环境下只跟是否私有化有关
          // 其它业务根据uploadToCloud字段判断是不是纯私有化
          if (localSwith) {
            // 私有云
            if (uploadToCloud === '1') {
              // 开启 uploadToCloud 后，如果传入私有化相关配置走混部私有化，否则纯私有化
              console.log(isLocal && this.localGroupId ? '混部 私有化' : '纯私有化');
              this.cloudType = isLocal && this.localGroupId ? 'local' : 'simpleLocal';
            } else if (uploadToCloud === '0') {
              // 未开启的话根据私有化相关配置走混部私有化或者混部公有云
              console.log(isLocal && this.localGroupId ? '混部 私有化' : '混部 公有云');
              this.cloudType = isLocal && this.localGroupId ? 'local' : 'publicCloud';
            }
            if (['kng', 'knglib'].includes(this.appCode)) {
              // kng、knglib 不走uploadToCloud字段判断
              this.cloudType = isLocal && this.localGroupId ? 'local' : 'publicCloud';
            }
          } else {
            // 公有云
            console.log('公有云');
            this.cloudType = 'publicCloud';
          }
          window.cloudType = this.cloudType;
          // if (!localSwith || localSwith === 0 || (localSwith === 1 && !this.isLocal && this.isBizMixDeploy) || (localSwith === 1 && uploadToCloud === '0' && !this.isBizMixDeploy)) {
          if (this.cloudType !== 'local' && this.cloudType !== 'simpleLocal') {
            // 公有云
            isLocalApi = false;
          } else {
            // 私有云
            isLocalApi = true;
          }
          this.isLocalApi = isLocalApi;
          // this.isLocalApi 为true时再请求其他私有化相关接口
          // if (this.cloudType === 'local') {
          if (this.isLocalApi) {
            // 混部 私有化
            let groupListRes = await getGroupList();
            if (!groupListRes.localUploadConfig || !groupListRes.localUploadConfig.secretDomain) {
              console.error('混部 私有化场景下未正确配置私有化域名, 请检查123平台配置');
            }
            this.uploadConfig.localConfig = groupListRes.localUploadConfig;
            this.uploadConfig.serviceGroupList = groupListRes.serviceGroupList;
            const serviceGroupId = this.localGroupId || groupListRes.serviceGroupList && groupListRes.serviceGroupList.length && groupListRes.serviceGroupList[0].id;
            const mainServerListRes = await getMainServerList(serviceGroupId);
            const result = mainServerListRes.filter(item => {
              return item.serviceType === '1';
            });
            this.groupId = result.length ? result[0].id : (mainServerListRes.length ? mainServerListRes[0].id : 0);
            this.publicReadHost = result.length ? result[0].trascodeHost : ''; // 私有云公开空间访问地址
          }
          //  else if (this.cloudType === 'simpleLocal') {
          //   // 纯私有化 新逻辑
          // }
          this.$nextTick(this.onReady);
        });
      }
    },
    async getUploadLimit() {
      try {
        const res = await getUploadLimit({ orgId: window.localStorage.orgId });
        console.log(res, '== 限制信息 ==');
      } catch (error) {

      }
    },
    async imageThumbnailsHandle(file, bucketConfig, currUuid) {
      // 图片缩略图处理
      // const imgData = await imgBlur(file, 2);
      const imgData = await createThumbnail(file);
      const newFile = dataURLtoFile(imgData, file.name);
      let globalOpenDomain = bucketConfig.globalOpenDomain;
      let endpoint = globalOpenDomain;
      // const newFile = file;
      let name = newFile.name && newFile.name.indexOf('?') ? newFile.name.split('?')[0] : newFile.name;
      if (!this.isV1) {
        newFile.globalTranscodeFlag = {
          transcode: 'N',
          private: 'N'
        };
      }
      newFile.extension = getFileExtension(name);
      newFile.fileType = getFileType(name, newFile.extension);
      newFile.fileClass = getFileClass(name, newFile.extension);

      return new Promise((resolve, reject) => {
        // 公开空间上传
        if (this.isLocalApi) {
          endpoint = bucketConfig.secretEndpoint;
          globalOpenDomain = bucketConfig.globalSecretDomain;
        }
        if (endpoint) {
          endpoint = endpoint.startsWith('http') ? endpoint : 'https://' + endpoint;
        }
        const bceUploader = new window.baidubce.bos.Uploader({
          token: (this.isLocal || this.newLocal) ? window.localStorage.getItem('token') : '', // 必须是私有化的才加入token安全校验，不然公有云hedaer头中加入token会报错
          // 上传配置
          uptoken_url: this.uptokenUrl,
          // bos_bucket: bucket,
          bos_endpoint: endpoint,
          cname_enabled: true,
          bos_task_parallel: 3,
          max_retries: 2,

          // 大文件相关（分片上传相关的参数）
          max_file_size: '2048M',
          bos_multipart_min_size: '10M',
          chunk_size: '4M',
          // 事件
          init: {
            Key: (_, file) => {
              // 如果需要重命名 BOS 存储的文件名称，这个函数返回新的文件名即可
              // 如果这里需要执行异步的操作，可以返回 Promise 对象，Promise.reject 会触发 Error 事件
              return this.getKey(file);
            },
            FileUploaded: async(_, file, info) => {
              if ((this.isLocal || this.newLocal)) {
                file.endPoint = this.uploadConfig.isOpen ? this.uploadConfig.localConfig.openEndpoint : this.uploadConfig.localConfig.secretEndpoint;
              }
              file.keyPath = (info.body.object || info.body.key).substr(this.isLocalApi ? 1 : 4); // 去掉getKey拼接的 /、/v1/
              file.fullUrl = `${globalOpenDomain.startsWith('http') ? globalOpenDomain : 'https://' + globalOpenDomain}/${file.keyPath}`;
              if (this.isLocalApi) file.fullUrl = `${globalOpenDomain.startsWith('http') ? this.publicReadHost : 'https://' + this.publicReadHost}/${file.keyPath}`;
              file.uuid = currUuid; // 因为每次上传百度云会重新生成uuid，因此这里重新赋值成上一次的uuid
              resolve(file);
            },
            Error: (_, error, file) => {
              console.log(error);
              reject(error);
            }
          }
        });
        bceUploader.addFile(newFile);
        bceUploader.start();
      });
    },
    customFileUpload(files) {
      let filesArr = [];
      if (Array.isArray(files)) {
        filesArr = files;
      } else {
        files && filesArr.push(files);
      }
      for (const file of filesArr) {
        file.extension = getFileExtension(file.name);
        file.fileType = getFileType(file.name, file.extension);
        file.fileClass = getFileClass(file.name, file.extension);
        file.globalTranscodeFlag = this.getFileTransCode(file);
        file.uuid = uuid();
        file.queueName = 'baidu';
        file.platform = this.cloudType === 'publicCloud' ? 'baidu' : this.cloudType;
        this.bceUploader = this.bceUploader || this.initBceUploader();
        this.bceUploader.addFile(file);
      }
      this.start();
    },
    computedFileTranscode() {
      if (this.isV1) return;
      // 非kng
      if (!this.isBizMixDeploy) {
        this.transcodeObj = {
          image: {
            transcode: 'N',
            private: 'N'
          },
          video: {
            transcode: 'Y',
            private: 'N'
          },
          audio: {
            transcode: 'Y',
            private: 'N'
          },
          doc: {
            transcode: 'Y',
            private: 'N'
          },
          zip: {
            transcode: 'N',
            private: 'N'
          }
        };
      }
      // kng 私有化场景所有文件都需要转码
      if (this.isBizMixDeploy) {
        let options = {
          zip: {
            transcode: 'Y',
            private: 'Y'
          }
        };
        if ((this.isLocal || this.newLocal)) {
          options.image = {
            transcode: 'Y',
            private: 'Y'
          };
        }
        this.transcodeObj = { ... this.transcodeObj, ...options};
      }
      // 处理参数
      if (isDataType(this.transcodeOptions, 'object')) {
        this.transcodeObj = {
          ...this.transcodeObj,
          ...this.transcodeOptions
        };
      } else if (isDataType(this.transcodeOptions, 'undefined')) {
      } else if (isDataType(this.transcodeOptions, 'boolean')) {
        if (this.transcodeOptions) {
          this.transcodeObj = {
            image: {
              transcode: 'Y',
              private: 'Y'
            },
            video: {
              transcode: 'Y',
              private: 'Y'
            },
            audio: {
              transcode: 'Y',
              private: 'Y'
            },
            doc: {
              transcode: 'Y',
              private: 'Y'
            },
            zip: {
              transcode: 'Y',
              private: 'Y'
            }
          };
        } else {
          console.log('false 不转码');
          this.transcodeObj = {
            image: {
              transcode: 'N',
              private: 'N'
            },
            video: {
              transcode: 'N',
              private: 'N'
            },
            audio: {
              transcode: 'N',
              private: 'N'
            },
            doc: {
              transcode: 'N',
              private: 'N'
            },
            zip: {
              transcode: 'N',
              private: 'N'
            }
          };
        }
      }
    },
    messageError(errorType) {
      if (this.errorTip && errorType) {
        const msgKey = 'pc_biz_upload_msg_' + errorType;
        const msg = this.$t(msgKey);
        msgKey !== msg && this.$message.error(msg);
      }
    },
    start() { // 当 autoUpload 设置为 false 的时候，需要手工调用 start 来开启上传的工作。
      console.log('开始上传～');
      this.networkInfo.start();
      this.bceUploader && this.bceUploader.start(); // 开始百度云上传
      this.startTencent(); // 开始腾讯云上传
    },
    stop() { // 调用 stop 之后，会终止对文件队列的处理。需要注意的是，不是立即停止上传，而是等到当前的文件处理结束（成功/失败）之后，才会停下来。
      this.bceUploader && this.bceUploader.stop(); // 停止百度云上传
      this.stopTencent();
    },
    addFile(file) { // 动态的往上传队列中添加一个文件
      this.uploadFiles([file]);
    },
    addFileList(fileList) { // 动态的往上传队列中添加n个文件
      this.uploadFiles([...fileList]);
    },
    remove(file) { // 删除队列中的一个文件，如果文件正在上传，那么就会中断上传。
      if (file.platform === 'tencent') {
        file && file.abort && file.abort();
      } else {
        this.bceUploader && this.bceUploader.remove(file);
      }
    },
    getKey(file) { // 获取文件上传路径
      return new window.Promise((resolve, reject) => {
        generatePath(this.appCode, file.name, this.configKey, this.moduleName, this.functionName, this.isV1).then(res => {
          // 因为二开接口不需要域名后面的 /v1，百度没有使用到bucketName，所以改动了 bce-bos-uploader-lite.js 11187行，把拼接v1放到了组件内
          if (file.platform === 'tencent') {
            resolve(res.key);
          } else {
            resolve((this.isLocalApi ? '/' : '/v1/') + res.key);
          }
        }).catch((error) => {
          console.error(error);
          reject(new Error('init.Key error'));
        });
      });
    },
    getQueueCompleted() {
      // true 已完成 false 未完成
      return !(Object.keys(this.tencentQueue).length || Object.keys(this.baiduQueue).length);
    },
    handleClick() { // 点击上传区域
      if (!this.canUpload || this.disabled) {
        return;
      }
      this.$refs.input.value = null;
      this.$refs.form.reset(); // ie10清空文件选择
      this.$refs.input.click();
    },
    handleFileChange(e) { // 选择文件变更
      this.uploadFiles(e.target.files);
    },
    uploadFiles(files) { // 上传选择/拖拽的文件
      // 判断文件是否为空
      console.log('开始上传前的验证', files);
      if (!this.canUpload || this.disabled || // 是否可以上传
        !files || files.length === 0 // 文件是否为空
      ) {
        return;
      }

      // 单个上传时，如果选择了多个文件，只取第一个上传
      if (!this.multipe && files.length > 1) {
        files = [files[0]];
      }

      // files类型可能是FileList，做一下兼容
      if (!(files instanceof Array)) {
        files = Array.from(files);
      }

      // 调用业务自定义文件过滤
      files = this.filesFilter(files) || files;

      // 添加到上传队列
      this.addFilesToQueue(files);

      // 如果开启了自动上传
      this.autoUpload && this.start();
    },
    async baseImgData(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          const IMG = new Image();
          IMG.src = e.target.result;
          IMG.crossOrigin = '*';
          IMG.onload = e => {
            // 大量使用可考虑只创建一次
            const Canvas = document.createElement('canvas');
            let w = IMG.width;
            let h = IMG.height;
            Canvas.width = w;
            Canvas.height = h;
            let ctx = Canvas.getContext('2d');
            ctx.drawImage(IMG, 0, 0, w, h);
            resolve(Canvas.toDataURL(file.type));
          };
        };
      });
    },
    async addFilesToQueue(files) { // 添加文件到上传队列
      // 判断文件类型和大小
      console.log('加入文件上传队列~');
      for (const file of files) {
        let name = file.name && file.name.indexOf('?') ? file.name.split('?')[0] : file.name;
        file.extension = getFileExtension(name);
        file.fileType = getFileType(name, file.extension);
        file.fileClass = getFileClass(name, file.extension);

        if (!this.isV1) {
          window.globalTranscodeFlag = this.getFileTransCode(file);
          file.globalTranscodeFlag = this.getFileTransCode(file);
        }
        this.checkFile(file);
        file.uuid = uuid();
        console.log('创建文件uuid：', file.uuid);
        if (!this.isV1 && this.cloudType === 'publicCloud' && file.fileType === 'doc' && this.uploadConfig.platform === 'tencent') { // 非V1，非私有云，文档上传到腾讯云（开启了腾讯云平台）
          file.platform = 'tencent';
          file.queueName = 'tencent';
          this.tencentQueue[file.uuid] = {
            file,
            status: 'waiting'
          };
          this.networkInfo.totalBytes += file.size;
        } else { // 其他上传到百度云，私有云也使用百度云的sdk，只是endpoint是二开的接口
          // file.platform = this.isLocal || this.isLocalApi ? 'local' : 'baidu';
          file.queueName = 'baidu';
          file.platform = this.cloudType === 'publicCloud' ? 'baidu' : this.cloudType;
          this.bceUploader = this.bceUploader || this.initBceUploader();
          this.bceUploader.addFile(file);
        }
      }
      this.filesAdded(files);
    },
    checkFile(file) { // 检查文件是否符合条件

      // 文件名超过200个字符
      let name = file.name && file.name.indexOf('?') ? file.name.split('?')[0] : file.name;
      if (name.length > 200) {
        file.error = 'overlength';
        return;
      }

      // 禁止上传的文件类型
      if (this.disableFilters && checkAccept(file, this.disableFilters)) {
        file.error = 'forbidden';
        return;
      }
      // 检查业务允许上传的文件类型
      if (this.filters && !checkAccept(file, this.filters)) {
        return;
      }
      // 检查配置允许上传的文件类型
      if (!checkAccept(file, this.uploadConfig.filters)) {
        return;
      }

      // 检查业务设置的文件大小限制
      if (this.maxSize && !checkSize(file, this.maxSize)) {
        return;
      }
      // 检查配置的文件大小限制
      const fileType = this.fileType || file.fileType;
      if (!checkSize(file, this.uploadConfig[fileType + 'MaxFileSize'] || this.uploadConfig.maxFileSize)) {
        return;
      }

    },
    async fileBeforeUpload(file) { // 文件开始上传前调用方法
      if (file.error) {
        // 文件上传出错，回调onError方法
        this.queueComplete(file, file.error);
        return false;
      }

      let result = true;
      try {
        // 调用业务方beforeUpload方法
        result = await this.beforeUpload(file);
      } catch (err) {
        result = false;
      }

      if (result !== false) {
        this.computeMd5(file); // 计算文件的Md5值
      }

      return result;
    },
    computeMd5(file) { // 计算文件Md5值
      // 计算md5
      if (this.md5) {
        const md5Promise = new window.Promise((resolve, reject) => {
          const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
          const chunkSize = 2097152;
          const chunks = Math.ceil(file.size / chunkSize);
          const spark = new SparkMD5.ArrayBuffer();
          const fileReader = new FileReader();
          let currentChunk = 0;

          fileReader.onload = function(e) {
            spark.append(e.target.result);
            currentChunk++;

            if (currentChunk < chunks) {
              loadNext();
            } else {
              resolve(spark.end());
            }
          };
          fileReader.onerror = function(error) {
            reject(error);
          };

          function loadNext() {
            const start = currentChunk * chunkSize;
            const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;
            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
          }
          loadNext();
        });
        md5Promise.then(md5Value => {
          file.md5 = md5Value;
        });
      }
    },
    uploadProgress(file, progressData) { // 上传进度改变
      const item = this[this.getQueueName(file.queueName)][file.uuid];
      if (item && progressData) {
        this.networkInfo.loadedBytes += (progressData.loaded - (item.loaded || 0));
        item.status = 'uploading';
        item.percent = progressData.percent;
        item.loaded = progressData.loaded;
        this.onNetworkSpeed(...this.networkInfo.dump()); // 回调上传网络信息
      }
      this.onProgress(file, progressData.percent, progressData);
    },
    fileUploaded(file, isPublic) { // 文件上传成功
      saveFileInfo(file, this.appCode, this.configKey, this.convert, this.isV1, this.convertKey, this.groupId, isPublic).then(res => {
        file.id = res.id;
        console.log('文件中心上传完成', file);
        this.onUploaded(file);
        if (this.getQueueCompleted()) {
          this.completeUploded();
        }
        this.tencentQueue = {};
        this.baiduQueue = {};
      }).catch((err) => {
        console.error('err', err);
        this.onError(new Error('saveFileInfo error'), file);
      });
    },
    initBceUploader() { // 初始化百度云上传对象
      // 设置 上传配置 是上传到 百度云 还是 私有云
      let bucketConfig = (this.isLocal || this.newLocal) ? this.uploadConfig.local : (this.isLocalApi ? this.uploadConfig.localConfig : this.uploadConfig.baidu);
      bucketConfig.globalOpenDomain = bucketConfig.globalOpenDomain || bucketConfig.openDomain;
      bucketConfig.globalSecretDomain = bucketConfig.globalSecretDomain || bucketConfig.secretDomain;
      // let bucket = this.uploadConfig.isOpen ? bucketConfig.globalOpenDomain : bucketConfig.globalSecretDomain;
      let endpoint = this.uploadConfig.isOpen ? bucketConfig.globalOpenDomain : bucketConfig.globalSecretDomain;
      console.log('----', bucketConfig);
      if (this.isLocalApi) {
        // bucket = bucketConfig.secretDomain;
        endpoint = bucketConfig.secretEndpoint;
        bucketConfig.globalOpenDomain = bucketConfig.secretDomain;
        bucketConfig.globalSecretDomain = bucketConfig.secretEndpoint;
      }
      if (this.isV1) {
        endpoint = bucketConfig.globalOpenDomain;
      }
      if (endpoint) {
        endpoint = endpoint.startsWith('http') ? endpoint : 'https://' + endpoint;
      }
      console.log('-- 上传配置 ---', this.uptokenUrl, endpoint);
      const bceUploader = new window.baidubce.bos.Uploader({
        token: (this.isLocal || this.newLocal) ? window.localStorage.getItem('token') : '', // 必须是私有化的才加入token安全校验，不然公有云hedaer头中加入token会报错
        // 上传配置
        uptoken_url: this.uptokenUrl,
        // bos_bucket: bucket,
        bos_endpoint: endpoint,
        cname_enabled: true,
        bos_task_parallel: 3,
        max_retries: 2,

        // 大文件相关（分片上传相关的参数）
        max_file_size: '2048M',
        bos_multipart_min_size: '10M',
        chunk_size: '4M',

        // 事件
        init: {
          FilesAdded: (_, files) => {
            // 当文件被加入到队列里面，调用这个函数
            for (const file of files) {
              this.baiduQueue[file.uuid] = {
                file,
                status: 'waiting'
              };
              this.networkInfo.totalBytes += file.size;
            }
          },
          BeforeUpload: (_, file) => {
            // 当某个文件开始上传的时候，调用这个函数
            // 如果想阻止这个文件的上传，请返回 false
            // 如果这里需要执行异步的操作，可以返回 Promise 对象，Promise.reject 会触发 Error 事件
            return new window.Promise((resolve, reject) => {
              this.fileBeforeUpload(file).then(checkedResult => {
                checkedResult === false ? reject(false) : resolve(true);
              });
            });
          },
          Key: (_, file) => {
            // 如果需要重命名 BOS 存储的文件名称，这个函数返回新的文件名即可
            // 如果这里需要执行异步的操作，可以返回 Promise 对象，Promise.reject 会触发 Error 事件
            return this.getKey(file);
          },
          UploadProgress: (_, file, progress, event) => {
            // 文件的上传进度
            this.uploadProgress(file, {
              percent: progress,
              loaded: event ? event.loaded : file.size * progress
            });
          },
          FileUploaded: async(_, file, info) => {
            console.log('百度云上传成功回调', file.uuid);
            // 文件上传成功之后，调用这个函数
            if (!this.isLocalApi) {
              // file.bucketName = bucket;
            }
            // 分片上传 info.body.object 为 getKey 返回的值，info.body.key 不带 /v1/
            file.keyPath = (info.body.object || info.body.key).substr(this.isLocalApi ? 1 : 4); // 去掉getKey拼接的 /、/v1/
            if ((this.isLocal || this.newLocal)) {
              file.endPoint = this.uploadConfig.isOpen ? this.uploadConfig.localConfig.openEndpoint : this.uploadConfig.localConfig.secretEndpoint;
            }
            /*
              如果不转码：
                conversion是公开空间
                original是私密
              如果转码：
                conversion 转码之后的文件
                original 原文件
            */
            // 混部私有云
            if (this.isLocalApi) {
              // fullUrl 用于图片回显及业务方上传接口入参，不可变更路径
              file.fullUrl = `${bucketConfig.secretDomain}/${file.keyPath}`;
              file.endPoint = this.uploadConfig.localConfig.secretEndpoint;
              file.fileDomain = `${this.isLocalApi ? '' : 'https://'}${bucketConfig.secretDomain}/`;
            } else {
              const openDomain = bucketConfig.openDomain || bucketConfig.globalOpenDomain;
              file.fullUrl = `${this.uploadConfig.isOpen ? (openDomain.startsWith('http') ? openDomain : 'https://' + openDomain) : (bucketConfig.globalSecretDomain.startsWith('http') ? bucketConfig.globalSecretDomain : 'https://' + bucketConfig.globalSecretDomain)}/${file.keyPath}`;
            }
            // 纯私有云
            // file.fileDomain = `${this.isLocalApi ? '' : 'https://'}${this.uploadConfig.isOpen ? bucketConfig.openDomain : bucketConfig.secretDomain}/`;
            file.fileKey = file.keyPath;
            delete this.baiduQueue[file.uuid]; // 上传成功后上传队列中的文件
            if (this.openImageThumbnails && file.fileClass === 'img') {
              // 缩略图相关代码
              const openFile = await this.imageThumbnailsHandle(file, bucketConfig, file.uuid);
              file.fullUrl = openFile.fullUrl;
              file.publicKeyPath = openFile.keyPath;
              this.fileUploaded(file, true);
            } else {
              this.fileUploaded(file);
            }
            window.YxtFeLog && window.YxtFeLog.track('e_component_upload', {
              properties: {
                level: 'info', // 日志等级
                desc: '记录上传相关数据', // 日志描述
                /*
                  isLocal: 业务入参 是否私有化上传
                  localGroupId: isLocal为true时，业务私有化服务器id
                  isBizMixDeploy: kng、knglib专有参数
                  cloudType: 上传云类型
                  isLocalApi: 是否私有云
                  endPoint: 上传前缀
                */
                data: {
                  localGroupId: this.localGroupId,
                  isLocal: (this.isLocal || this.newLocal),
                  isBizMixDeploy: this.isBizMixDeploy,
                  isLocalApi: this.isLocalApi,
                  cloudType: this.cloudType,
                  endPoint: file.endPoint
                }
              }
            });
          },
          UploadComplete: () => {
            // 队列里面的文件上传结束了，调用这个函数
            this.baiduQueue = {};
            this.queueComplete();
          },
          Aborted: (_, __, file) => {
            // 文件终止上传，调用这个函数
            this.networkInfo.totalBytes -= (file.size - (file._previousLoaded || 0));
          },
          Error: (_, error, file) => {
            // 如果上传的过程中出错了，调用这个函数
            console.log(error);
            this.queueComplete(file, error);
          }
        }
      });
      return bceUploader;
    },
    initCosClient() { // 初始化腾讯云上传对象
      const cosClient = new window.COS({
        // Domain: '{Bucket}.cos.{Region}.myqcloud.com',
        FileParallelLimit: 1,
        ProgressInterval: 300, // 控制上传的 onProgress 回调的间隔
        Domain: this.uploadConfig.isOpen ? this.uploadConfig.tencent.globalOpenDomain : this.uploadConfig.tencent.globalSecretDomain, // 自定义源站域名
        Protocol: 'https:', // 请求协议： 'https:' 或 'http:'

        // 获取签名的回调方法
        getAuthorization: (options, callback) => {
          getTencentToken({
            platform: 'tencent',
            bucketName: options.Bucket
          }).then(data => {
            const credentials = data.credentials;
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              XCosSecurityToken: credentials.sessionToken,
              StartTime: data.startTime,
              ExpiredTime: data.expiredTime
            });
          }).catch(() => {
            callback({});
          });
        }
      });
      return cosClient;
    },
    startTencent() { // 开始腾讯云上传
      const files = Object.values(this.tencentQueue);
      if (files.length === 0) { // 没有待上传的文件
        return;
      }

      // 初始化腾讯云上传对象
      this.cosClient = this.cosClient || this.initCosClient();
      const tencentConfig = this.uploadConfig.tencent;
      const bucket = this.uploadConfig.isOpen ? tencentConfig.openBucket : tencentConfig.secretBucket;

      files.forEach(async item => {
        if (item.status !== 'waiting') {
          return;
        }
        const file = item.file;

        if (await this.fileBeforeUpload(file) === false) {
          this.queueComplete(file);
          return;
        }

        try {
          const key = await this.getKey(file); // 获取文件上传路径
          const options = {
            Bucket: bucket,
            Region: tencentConfig.region,
            Key: key,
            Query: {},
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            SliceSize: size4M,
            onTaskReady: (taskId) => {
              // 上传对象put文件以后就会生成taskId，这个事件不能修改状态为上传中
              item.taskId = taskId;
            },
            onProgress: (progressData) => {
              /**
               * 进度回调响应对象属性
               * loaded：已经上传的文件部分大小，以字节（Bytes）为单位
               * total：整个文件的大小，以字节（Bytes）为单位
               * speed：文件的上传速度，以字节/秒（Bytes/s）为单位
               * percent：文件上传的百分比，以小数形式呈现，例如，上传50%即为0.5
               */

              if (progressData.percent > 0) {
                this.uploadProgress(file, {
                  percent: progressData.percent,
                  loaded: progressData.loaded
                });
              }
            }
          };
          options.Query = { ...this.getFileTransCode(file) };

          const callback = (error, data) => {
            console.log('腾讯云上传成功回调');
            this.queueComplete(file, error);
            if (data.statusCode === 200) { // 上传成功
              file.bucketName = bucket;
              file.fullUrl = 'https://' + data.Location;
              console.log(data, '上传put接口返回资源');
              this.fileUploaded(file);
            }
          };

          file.keyPath = key;
          file.abort = () => { // 终止上传方法
            this.cosClient.cancelTask(item.taskId);
            this.networkInfo.totalBytes -= (file.size - (item.loaded || 0));
            this.queueComplete(file);
          };

          // 文件正式上传腾讯云
          if (file.size > size10M) { // 文件大小超过10M，采用分片上传方式
            this.cosClient.sliceUploadFile(options, callback);
          } else {
            this.cosClient.putObject(options, callback);
          }
        } catch (error) {
          this.queueComplete(file, error);
        }

      });
    },
    stopTencent() { // 停止腾讯云上传
      // 上传队列中正在上传的文件不会停止
      Object.values(this.tencentQueue).forEach(item => {
        if (item.status !== 'uploading') {
          item.file.abort();
        }
      });
    },
    getFileTransCode(file) {
      /*
        默认所有文件一律转码 除以下两种特殊场景
        图片(除kng私有云图片需转码) 不转码
        业务标识不转码时 不转码
        以下条件为true不转码 条件为false转码
      */
      if (isDataType(this.transcodeObj[file.fileType], 'undefined')) {
        return {};
      }
      return { ...this.transcodeObj[file.fileType] };
    },
    queueComplete(file, error) {
      if (file) {
        delete this[this.getQueueName(file.queueName)][file.uuid];
      }
      if (error) {
        this.messageError(file.error);
        this.onError(error, file);
      }
      if (Object.keys(this.baiduQueue).length + Object.keys(this.tencentQueue).length === 0) {
        // 队列里面的文件上传结束了
        const networkResult = this.networkInfo.dump();
        this.networkInfo.reset(); // 重置上传网络信息
        if (file) {
          networkResult[0] && this.onNetworkSpeed(networkResult[0], networkResult[1], 0); // 回调上传网络信息，百度云UploadComplete不需要
          file.platform === 'tencent' && this.onComplete();
        } else {
          this.onComplete();
        }
      }
    },
    getQueueName(platform) { // 获取上传队列名，私有云使用的百度云的队列
      return (platform === 'local' ? 'baidu' : platform) + 'Queue';
    }
  }
};
</script>
