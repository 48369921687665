import { sourceCodeEnum } from 'yxt-open-data/es';
import { isOpenData } from 'yxt-biz-pc/src/utils/shared';
import { EDITION_TOOL } from 'yxt-biz-pc/packages/nav-main/src/v2/common';
import { enmuFunctions } from 'yxt-biz-pc/packages/function-points/index';

import { checkTimeOutFnc } from '../getFactor';

// buy enable
export const isFunPointEnable = point => {
  [2, 3, 4].includes(checkTimeOutFnc(point));
};

// hide
export const isFunPointHide = point => {
  // 无 point code 不隐藏元素
  if (!point) return false;
  // 没有匹配状态 hide
  return [undefined, null, 0, 1, 3, 5].includes(checkTimeOutFnc(point));
};

// buy enable show
export const isFunPointEnableShow = point => {
  return checkTimeOutFnc(point) === 2;
};

export function isOpenIM() {
  const isDing = localStorage.sourceCode === sourceCodeEnum.dingding;
  const is_IM_CHAT = checkTimeOutFnc(enmuFunctions.IM_CHAT) === 2;
  const is_HX_IM_CHAT = checkTimeOutFnc(enmuFunctions.HX_IM_CHAT) === 2;
  const is_ZY_IM_CHAT = checkTimeOutFnc(enmuFunctions.ZY_IM_CHAT) === 2;
  let openIM = is_IM_CHAT;
  if (!is_HX_IM_CHAT && !is_ZY_IM_CHAT) openIM = false;
  return isDing || openIM;
}

export const genIsMixin = () => {
  return {
    computed: {
      isOpenData() {
        return isOpenData();
      },
      isOpenIM() {
        return isOpenIM();
      },
      hideUsercenterBtn() {
        return isFunPointHide(enmuFunctions.PERSONAL_CENTER) || EDITION_TOOL;
      }
    },
    methods: {
      isFunPointHide
    }
  };
};
