<template>
  <yxt-select v-model="val"
              :class="customWidthClass ? customWidthClass : 'width-percent-100'"
              :clearable="clearable"
              :placeholder="placeholder"
              @change="selectUserTypeChange">
    <yxt-option v-for="item in list"
                :key="item.value"
                :label="item.label"
                :value="item.value"></yxt-option>
  </yxt-select>
</template>

<script>
export default {
  name: 'CommonDropSelector',
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    value: {
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    },
    customWidthClass: {
      type: String
    }
  },
  data() {
    return {};
  },
  computed: {
    list() {
      return this.dataList;
    },
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    selectUserTypeChange(val) {
      this.$emit('change', val);
    }
  }
};
</script>
