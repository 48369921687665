var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-choose-project" }, [
    _c(
      "div",
      {
        staticClass: "yxtbiz-selector",
        on: {
          click: function ($event) {
            _vm.selectDraw = false
          },
        },
      },
      [
        _c("div", { staticClass: "yxtbiz-selector__left" }, [
          _c(
            "div",
            { staticClass: "clearfix font-size-14" },
            [
              _vm.enableCreate
                ? _c(
                    "yxt-button",
                    {
                      staticClass: "pull-left mr12",
                      attrs: { type: "primary", size: "large" },
                      on: { click: _vm.createProjectHandler },
                    },
                    [_vm._v(_vm._s(_vm.$t("pc_biz_project_create")))]
                  )
                : _vm._e(),
              _vm.groupSelect && this.fromType !== "sp"
                ? _c("yxtbiz-group-org-select", {
                    staticClass: "pull-left mr10 mb10 ml10",
                    attrs: {
                      placeholder: _vm.$t(
                        "pc_biz_kng_lbl_chooseorg" /*请选择机构*/
                      ),
                      "set-current-org": "",
                      "function-code": "group_data_center",
                      "data-permission-code": "data_manage_platform",
                      clearable: false,
                      disabledOrgIds: [],
                      hideOrgIds: [],
                      visibleOrgIds: [],
                      selected: [],
                    },
                    on: { change: _vm.orgIdchange },
                  })
                : _vm._e(),
              _c(
                "yxt-popover",
                {
                  attrs: {
                    "append-to-body": false,
                    "popper-class": "yxtbiz-project-selector__popover",
                    trigger: "manual",
                    placement: "bottom-start",
                    "visible-arrow": false,
                  },
                  model: {
                    value: _vm.selectDraw,
                    callback: function ($$v) {
                      _vm.selectDraw = $$v
                    },
                    expression: "selectDraw",
                  },
                },
                [
                  _c(
                    "yxt-button",
                    {
                      staticClass: "yxtbiz-filter-btn pull-left mr12",
                      attrs: {
                        slot: "reference",
                        plain: "",
                        disabled: _vm.moreSearch,
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.selectDraw = !_vm.selectDraw
                        },
                      },
                      slot: "reference",
                    },
                    [
                      _c("yxt-svg", {
                        staticClass: "v-mid mr2 mb4",
                        attrs: {
                          width: "18px",
                          height: "18px",
                          "icon-class": "filter",
                        },
                      }),
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t("pc_biz_common_lbl_moreFilter" /*更多筛选*/)
                          ) +
                          "\n                "
                      ),
                      _vm.selectDraw
                        ? _c("yxt-svg", {
                            staticClass: "v-mid ml8",
                            attrs: {
                              width: "14px",
                              height: "14px",
                              "icon-class": "arrow-up",
                            },
                          })
                        : _c("yxt-svg", {
                            staticClass: "v-mid ml8",
                            attrs: {
                              width: "14px",
                              height: "14px",
                              "icon-class": "arrow-down",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectDraw,
                          expression: "selectDraw",
                        },
                      ],
                      staticClass:
                        "clearfix font-size-14 more-select show-more",
                      on: {
                        click: function ($event) {
                          _vm.selectDraw = true
                        },
                      },
                    },
                    [
                      _vm.fromType !== "sp"
                        ? _c(
                            "div",
                            { staticClass: "pull-left mb10" },
                            [
                              _c("label", {
                                staticClass: "reportor-form__label draw-label",
                                staticStyle: { "margin-right": "12px" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "pc_biz_project_publish_time" /* 项目发布时间 */
                                    )
                                  ),
                                },
                              }),
                              _c("yxt-date-picker", {
                                staticClass: "reportor-form__content w330",
                                attrs: {
                                  type: "daterange",
                                  "range-separator": _vm.$t(
                                    "pc_biz_ls_to" /* 至 */
                                  ),
                                  "start-placeholder": _vm.$t(
                                    "pc_biz_ls_start" /* 开始日期 */
                                  ),
                                  "end-placeholder": _vm.$t(
                                    "pc_biz_ls_end" /* 结束日期 */
                                  ),
                                },
                                on: { change: _vm.onChangeDate },
                                model: {
                                  value: _vm.dataRangeVal,
                                  callback: function ($$v) {
                                    _vm.dataRangeVal = $$v
                                  },
                                  expression: "dataRangeVal",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.fromType !== "sp"
                        ? _c(
                            "div",
                            { staticClass: "pull-left mb10" },
                            [
                              _c("label", {
                                staticClass: "reportor-form__label draw-label",
                                staticStyle: { "margin-right": "12px" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "pc_biz_project_create_time" /* 项目开始时间 */
                                    )
                                  ),
                                },
                              }),
                              _c("yxt-date-picker", {
                                staticClass: "reportor-form__content w330",
                                attrs: {
                                  type: "daterange",
                                  "range-separator": _vm.$t(
                                    "pc_biz_ls_to" /* 至 */
                                  ),
                                  "start-placeholder": _vm.$t(
                                    "pc_biz_ls_start" /* 开始日期 */
                                  ),
                                  "end-placeholder": _vm.$t(
                                    "pc_biz_ls_end" /* 结束日期 */
                                  ),
                                },
                                on: { change: _vm.onChangeCreateDate },
                                model: {
                                  value: _vm.createRangeVal,
                                  callback: function ($$v) {
                                    _vm.createRangeVal = $$v
                                  },
                                  expression: "createRangeVal",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.fromType !== "sp"
                        ? _c(
                            "div",
                            { staticClass: "pull-left mb10" },
                            [
                              _c("label", {
                                staticClass: "reportor-form__label draw-label",
                                staticStyle: { "margin-right": "12px" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "pc_biz_project_end_time" /* 项目结束时间 */
                                    )
                                  ),
                                },
                              }),
                              _c("yxt-date-picker", {
                                staticClass: "reportor-form__content w330",
                                attrs: {
                                  type: "daterange",
                                  "range-separator": _vm.$t(
                                    "pc_biz_ls_to" /* 至 */
                                  ),
                                  "start-placeholder": _vm.$t(
                                    "pc_biz_ls_start" /* 开始日期 */
                                  ),
                                  "end-placeholder": _vm.$t(
                                    "pc_biz_ls_end" /* 结束日期 */
                                  ),
                                },
                                on: { change: _vm.onChangeEndDate },
                                model: {
                                  value: _vm.endRangeVal,
                                  callback: function ($$v) {
                                    _vm.endRangeVal = $$v
                                  },
                                  expression: "endRangeVal",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "pull-left mb10" },
                        [
                          _c("label", {
                            staticClass: "reportor-form__label draw-label mr14",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("pc_biz_lbl_prjStatus" /* 项目状态*/)
                              ),
                            },
                          }),
                          _c(
                            "yxt-select",
                            {
                              staticClass:
                                "reportor-form__content draw-select w330",
                              attrs: { clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.onStausSelect($event)
                                },
                              },
                              model: {
                                value: _vm.statusName,
                                callback: function ($$v) {
                                  _vm.statusName = $$v
                                },
                                expression: "statusName",
                              },
                            },
                            _vm._l(_vm.selectStatusArr, function (option) {
                              return _c("yxt-option", {
                                key: option.type,
                                attrs: {
                                  label: _vm.$t(option.name),
                                  value: option.name,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pull-left mb10" },
                        [
                          _c("label", {
                            staticClass: "reportor-form__label draw-label mr14",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("pc_biz_lbl_prjCatalog" /* 项目分类*/)
                              ),
                            },
                          }),
                          _c(
                            "yxt-select",
                            {
                              ref: "categorySelect",
                              staticClass:
                                "reportor-form__content draw-select w330",
                              attrs: {
                                "value-key": "id",
                                "prop-label": "name",
                                clearable: "",
                              },
                              on: { clear: _vm.handleCategoryClear },
                              model: {
                                value: _vm.selectCategory,
                                callback: function ($$v) {
                                  _vm.selectCategory = $$v
                                },
                                expression: "selectCategory",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "yxtbiz-project-selector__project-category",
                                  attrs: { slot: "empty" },
                                  slot: "empty",
                                },
                                [
                                  _c("yxt-input", {
                                    staticClass:
                                      "yxtbiz-project-selector__project-category-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "pc_biz_ote_tip_catalogsearch"
                                      ),
                                      searchable: "",
                                    },
                                    on: { search: _vm.handleSearchCategory },
                                    model: {
                                      value: _vm.filterText,
                                      callback: function ($$v) {
                                        _vm.filterText = $$v
                                      },
                                      expression: "filterText",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "yxtbiz-project-selector__project-category-tree",
                                    },
                                    [
                                      _c("yxt-tree", {
                                        ref: "tree",
                                        staticClass: "filter-tree",
                                        attrs: {
                                          data: _vm.projectCategoryData,
                                          "node-key": "id",
                                          props: {
                                            children: "children",
                                            label: "name",
                                          },
                                          "default-expand-all": "",
                                          "filter-node-method":
                                            _vm.filterProjectNode,
                                        },
                                        on: {
                                          "node-click":
                                            _vm.handleSelectCategory,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pull-left mb10" },
                        [
                          _c("label", {
                            staticClass: "reportor-form__label draw-label mr14",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("pc_biz_lbl_prjTimeType" /* 项目模式*/)
                              ),
                            },
                          }),
                          _c(
                            "yxt-select",
                            {
                              staticClass:
                                "reportor-form__content draw-select w330",
                              attrs: { clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.onTypeSelect($event)
                                },
                              },
                              model: {
                                value: _vm.typeName,
                                callback: function ($$v) {
                                  _vm.typeName = $$v
                                },
                                expression: "typeName",
                              },
                            },
                            _vm._l(_vm.selectTypeArr, function (option) {
                              return _c("yxt-option", {
                                key: option.type,
                                attrs: {
                                  label: _vm.$t(option.name),
                                  value: option.name,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.fromType !== "sp"
                        ? _c(
                            "div",
                            { staticClass: "pull-left mb10" },
                            [
                              _c("label", {
                                staticClass:
                                  "reportor-form__label draw-label mr14",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "pc_biz_lbl_includefileimport" /* 包含档案导入*/
                                    )
                                  ),
                                },
                              }),
                              _c(
                                "yxt-select",
                                {
                                  staticClass:
                                    "reportor-form__content draw-select w330",
                                  attrs: { clearable: "" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onImpSelect($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.includeImport,
                                    callback: function ($$v) {
                                      _vm.includeImport = $$v
                                    },
                                    expression: "includeImport",
                                  },
                                },
                                _vm._l(_vm.selectImpArr, function (option) {
                                  return _c("yxt-option", {
                                    key: option.type,
                                    attrs: {
                                      label: _vm.$t(option.name),
                                      value: option.name,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "pull-left" }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "yxt-select",
                {
                  staticClass: "pull-left mr12",
                  staticStyle: { width: "144px" },
                  attrs: { clearable: "" },
                  on: { change: _vm.getProjectList },
                  model: {
                    value: _vm.range,
                    callback: function ($$v) {
                      _vm.range = $$v
                    },
                    expression: "range",
                  },
                },
                _vm._l(_vm.rangeOptions, function (option) {
                  return _c("yxt-option", {
                    key: option.value,
                    attrs: { label: option.label, value: option.value },
                  })
                }),
                1
              ),
              _c("yxt-input", {
                staticClass: "yxtbiz-selector__input pull-left",
                staticStyle: { width: "200px" },
                attrs: {
                  placeholder: _vm.$t(
                    "pc_biz_o2o_training_placeholder_inputName" /* 请输入项目名称搜索 */
                  ),
                  searchable: "",
                  maxlength: "50",
                },
                on: { search: _vm.projectNameSearch },
                model: {
                  value: _vm.projectSearch.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.projectSearch, "name", $$v)
                  },
                  expression: "projectSearch.name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "yxtbiz-selector__main mt16" },
            [
              _c(
                "yxt-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  key: "projectTable" + _vm.page,
                  ref: "refTable",
                  staticClass: "yxtbiz-selector__table",
                  attrs: {
                    data: _vm.projectList,
                    "default-sort": { prop: "releaseDate", order: "desc" },
                  },
                  on: {
                    "select-all": _vm.selectAll,
                    select: _vm.select,
                    "sort-change": _vm.sortChange,
                  },
                },
                [
                  _c("yxt-table-column", {
                    attrs: {
                      "show-overflow-tooltip": false,
                      type: "selection",
                      width: "38",
                      align: "right",
                      "clear-padding": "left-right",
                    },
                  }),
                  _c("yxt-table-column", {
                    attrs: {
                      label: _vm.$t("pc_biz_ojt_lbl_projectname"),
                      "show-overflow-tooltip": false,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "sign-flex-style" },
                              [
                                _c(
                                  "yxt-tooltip",
                                  {
                                    staticClass: "overflow",
                                    attrs: {
                                      effect: "dark",
                                      "open-filter": true,
                                      content: scope.row.name,
                                      placement: "top",
                                    },
                                  },
                                  [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                                ),
                                scope.row.type === 2
                                  ? _c(
                                      "span",
                                      { staticClass: "sign-text-style" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("pc_biz_ls_group") /* 集团*/
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.fromType !== "sp"
                    ? _c("yxt-table-column", {
                        attrs: {
                          label: _vm.$t("pc_biz_lbl_prjCatalog"),
                          "show-overflow-tooltip": false,
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "sign-flex-style" },
                                    [
                                      _c(
                                        "yxt-tooltip",
                                        {
                                          staticClass: "overflow",
                                          attrs: {
                                            effect: "dark",
                                            "open-filter": true,
                                            content: scope.row.sceneName,
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.sceneName || "--"
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          374327601
                        ),
                      })
                    : _vm._e(),
                  _c("yxt-table-column", {
                    attrs: {
                      label: _vm.$t("pc_biz_lbl_prjStatus" /*项目状态*/),
                      prop: "status",
                      align: "left",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", {
                              class: [
                                "yxtbiz-selector__project-status",
                                `yxtbiz-selector__project-status--${scope.row.status}`,
                              ],
                            }),
                            _c("span", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.getStatusName(scope.row.status)) +
                                  "\n                  "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.fromType !== "sp"
                    ? _c("yxt-table-column", {
                        attrs: {
                          label: _vm.$t("pc_biz_shoptour_publish_person"),
                          "show-overflow-tooltip": "",
                          "popover-html": "",
                          prop: "releaseUserFullName",
                          align: "left",
                          width: "120",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v("\n0                  "),
                                  _c("yxtbiz-user-name", {
                                    attrs: {
                                      name:
                                        scope.row.releaseUserFullName || "--",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          584623062
                        ),
                      })
                    : _vm._e(),
                  _vm.fromType === "sp"
                    ? _c("yxt-table-column", {
                        attrs: {
                          label: _vm.$t("pc_biz_o2o_principal" /* 负责人 */),
                          "show-overflow-tooltip": "",
                          "popover-html": "",
                          prop: "principalCnName",
                          align: "left",
                          width: "120",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("yxtbiz-user-name", {
                                    attrs: {
                                      name: scope.row.principalCnName || "--",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1464466852
                        ),
                      })
                    : _vm._e(),
                  _vm.fromType !== "sp"
                    ? _c("yxt-table-column", {
                        attrs: {
                          label: _vm.$t("pc_biz_ote_lbl_publishdate"),
                          prop: "releaseDate",
                          sortable: "custom",
                          formatter: _vm.shortDate(),
                          align: "left",
                          width: "130",
                        },
                      })
                    : _vm._e(),
                  _vm.fromType === "sp"
                    ? _c("yxt-table-column", {
                        attrs: {
                          label: _vm.$t(
                            "pc_biz_o2o_lbl_createTime" /* 创建时间 */
                          ),
                          prop: "createTime",
                          sortable:
                            _vm.fromType !== "sp" ? "custom" : undefined,
                          formatter: _vm.shortDate("yyyy-MM-dd HH:mm"),
                          align: "left",
                          width: "160",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt16 clearfix pr" },
            [
              _c(
                "yxt-button",
                {
                  attrs: {
                    type: "text",
                    loading: _vm.selectAllBtnLoading,
                    disabled: !_vm.projectList.length,
                  },
                  on: { click: _vm.handleSelectAll },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t("pc_biz_kng_selectall" /** 筛选全部结果 */)
                      ) +
                      "\n            "
                  ),
                ]
              ),
              _c("yxt-pagination", {
                staticClass: "pull-right",
                attrs: {
                  "page-size":
                    _vm.fromType !== "sp" ? _vm.projectSearch.size : 20,
                  total: _vm.count,
                  "current-page": _vm.page,
                  layout: "total, prev, pager, next",
                },
                on: { "current-change": _vm.pageChange },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "yxtbiz-selector__right" }, [
          _c("div", { staticClass: "yxtbiz-checked_list" }, [
            _c("div", { staticClass: "yxtbiz-checked_list__select" }, [
              _vm._v(
                "\n              " +
                  _vm._s(_vm.$t("pc_biz_ote_lbl_chosenquestions" /*已选择*/)) +
                  ":  " +
                  _vm._s(_vm.list && _vm.list.length) +
                  " / " +
                  _vm._s(_vm.limit) +
                  "\n            "
              ),
            ]),
            _c(
              "div",
              { staticClass: "yxtbiz-checked_list__box" },
              [
                _c(
                  "yxt-scrollbar",
                  {
                    staticClass: "yxtbiz-checked_list__scrollbar",
                    attrs: { "fit-height": true },
                  },
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "yxtbiz-checked_list__item",
                      },
                      [
                        _c(
                          "yxt-tag",
                          {
                            attrs: {
                              "disable-transitions": "",
                              size: "small",
                              type: "info",
                              closable: "",
                            },
                            on: {
                              close: function ($event) {
                                return _vm.deleteItem(item, index)
                              },
                            },
                          },
                          [
                            _c(
                              "yxt-tooltip",
                              {
                                attrs: {
                                  content: item.name,
                                  "open-filter": "",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "yxtbiz-checked_list__item--tag",
                                  },
                                  [_vm._v(_vm._s(item.name) + " ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "yxtbiz-checked_list__operation" },
              [
                _c(
                  "yxt-button",
                  {
                    staticClass: "font-size-12 color-gray-9 hover-primary-6",
                    attrs: {
                      type: "text",
                      disabled: _vm.list && _vm.list.length === 0,
                    },
                    on: { click: _vm.clear },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t("pc_biz_shoptour_clear_all" /*清除所有*/)
                        ) +
                        "\n              "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }