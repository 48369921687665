<template>
  <div class="yxtbiz-media-container">
    <div
      class="yxtbiz-media-container__player"
      :class="{
        'yxtbiz-media-container__player--transcoding': tranStatus !== 2
      }"
    >
      <yxtbiz-video
        v-if="!needReload && tranStatus === 2"
        ref="player"
        :type="playerType"
        :options="options"
        :starttime="starttime"
        :autoStart="autoStart"
        :width="width"
        :height="height"
        :playRate="playRate"
        :isShowWatermark="isShowWatermark"
        :isNeedToken="isNeedToken"
        :watermarkObj="watermark"
        isOpenMutiple
        :ticket="ticket"
        @onBeforePlay="onBeforePlay"
        @onReady="onReady"
        @onPlay="onPlay"
        @onPause="onPause"
        @onComplete="onComplete"
        @onError="onError"
        @onFullscreen="onFullscreen"
        @onSeek="onSeek"
        @onTime="onTime"
        @onQualityChange="onQualityChange"
        @onPlaybackRate="onPlaybackRate"
      >
      </yxtbiz-video>
      <div
        v-else
        class="bg-black"
        :style="{
          width: width + 'px',
          height: height + 'px'
        }"
      ></div>
      <div v-if="tranStatus !== 2" class="transcoding-tips">
        <yxt-svg
          v-if="tranStatus === 3"
          width="14"
          height="14"
          icon-class="message-error"
        />
        <yxt-svg v-else width="14" height="14" icon-class="message-error" />
        <span class="ml4">{{
          tranStatus === 3
            ? (playerType === 'audio'
                ? $t('pc_biz_ote_lbl_audio')
                : $t('pc_biz_ote_lbl_video')) +
              $t('pc_biz_ote_msg_tanslate_error')
            : $t('pc_biz_ote_lbl_objtranscoding', [(playerType === 'audio'
                ? $t('pc_biz_ote_lbl_audio')
                : $t('pc_biz_ote_lbl_video'))])
        }}</span>
      </div>
      <span v-if="$slots.action" class="player-action-button">
        <slot name="action"></slot>
      </span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    type: {
      type: [String, Number],
      default: 'video'
    },
    options: {
      type: Array,
      default: () => []
    },
    starttime: {
      type: Number,
      default: 0
    },
    width: {
      type: String,
      default: '400'
    },
    height: {
      type: String,
      default: '300'
    },
    playRate: {
      type: Boolean,
      default: false
    },
    autoStart: {
      type: Boolean,
      default: false
    },
    isShowWatermark: {
      type: Boolean,
      default: false
    },
    watermark: {
      type: Object,
      default: () => {}
    },
    tranStatus: {
      type: Number,
      default: 0
    },
    playerKey: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      isNeedToken: true,
      needReload: false,
      exporting: this.$route.query.exporting
    };
  },
  computed: {
    ...mapState({
      ticket: state => state.ticket
    }),
    playerType() {
      if (this.type === 2 || this.type === 'audio') {
        return 'audio';
      } else {
        return 'video';
      }
    }
  },
  beforeDestroy() {
    this.$refs.player && this.$refs.player.dispose();
    this.clearMediaPlayerOnPlay();
  },
  methods: {
    clearMediaPlayerOnPlay() {
      if (window.__MEDIA_PLAYER_ONPLAY__ === this) {
        window.__MEDIA_PLAYER_ONPLAY__ = null;
      }
    },
    onBeforePlay(event) {
      this.$emit('onBeforePlay', event);
    },
    onReady(event) {
      this.$emit('onReady', event);
    },
    onPlay(event) {
      if (
        window.__MEDIA_PLAYER_ONPLAY__ &&
        window.__MEDIA_PLAYER_ONPLAY__ !== this
      ) {
        window.__MEDIA_PLAYER_ONPLAY__.$refs.player.pause();
      }
      window.__MEDIA_PLAYER_ONPLAY__ = this;
      this.$emit('onPlay', event);
    },
    onPause(event) {
      this.clearMediaPlayerOnPlay();
      this.$emit('onPause', event);
    },
    onComplete(event) {
      this.clearMediaPlayerOnPlay();
      this.$emit('onComplete', event);
    },
    onError(event) {
      this.clearMediaPlayerOnPlay();
      this.$emit('onError', event);
    },
    onFullscreen(event) {
      this.$emit('onFullscreen', event);
    },
    onSeek(event) {
      this.$emit('onSeek', event);
    },
    onTime(event) {
      this.$emit('onTime', event);
    },
    onQualityChange(event) {
      this.$emit('onQualityChange', event);
    },
    onPlaybackRate(event) {
      this.$emit('onPlaybackRate', event);
    },
    pause() {
      this.$refs.player.pause();
    },
    play() {
      this.$refs.player.play();
    },
    seek(time) {
      this.$refs.player.seek(time);
    },
    stop() {
      this.$refs.player.stop();
    },
    // 销毁播放器
    dispose() {
      this.$refs.player.dispose();
    },
    getPlayer() {
      const player = this.$refs.player.getPlayer();
      return player;
    }
  },
  watch: {
    playerKey() {
      this.needReload = true;
      this.$nextTick(() => {
        this.needReload = false;
      });
    }
  }
};
</script>
