<template>
  <div class="yxtbiz-live-selector">
    <div class="yxtbiz-live-selector__left">
      <div class="yxtbiz-live-selector__filter clearfix font-size-14">
        <yxt-button  v-if="allowCreate" @click="createLive" type="primary" class="mr12">{{ $t('pc_biz_ls_create') }}</yxt-button>
        <yxt-popover
          :append-to-body="false"
          placement="bottom-start"
          :visible-arrow="false"
          v-model="showMoreFilter"
        >
          <yxt-button slot="reference" plain class="yxtbiz-filter-btn mr12">
            <yxt-svg
              width="20px"
              height="20px"
              icon-class="filter"
              class="v-top"
            />
            <span class="ml8 v-top">{{
                $t('pc_biz_common_lbl_moreFilter')
              }}</span>
            <i
              class="ml8"
              :class="
                showMoreFilter ? 'yxt-icon-arrow-up' : 'yxt-icon-arrow-down'
              "
            ></i>
          </yxt-button>
          <div>
            <yxtbiz-group-org-select
              v-if="groupSelect"
              class="mr12 mb16"
              :clearable="false"
              :multiple="false"
              :functionCode="orgFunctionCode"
              :dataPermissionCode="orgDataPermissionCode"
              @change="changeOrg"
              setCurrentOrg
            ></yxtbiz-group-org-select>
            {{$t('pc_biz_ls_starttime')}}：<yxt-date-picker
            class="mr12 mb16"
            @change="changeDate"
            v-model="publishDate"
            format="yyyy-MM-dd"
            type="daterange"
            :range-separator="$t('pc_biz_ls_to')"
            :start-placeholder="$t('pc_biz_ls_start')"
            :end-placeholder="$t('pc_biz_ls_end')">
          </yxt-date-picker>
            {{$t('pc_biz_ls_chooseStatus')}}：<yxt-select class="mr12 mb12" v-model="listParams.roomStatus" multiple collapse-tags :placeholder="$t('pc_biz_live-please-choose')" clearable>
              <yxt-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                class="mr12"
                @change="resetCurrent"
              ></yxt-option>
            </yxt-select>

          </div>
        </yxt-popover>
        <yxt-input
          style="width:208px;"
          :placeholder="$t('pc_biz_ls_search')"
          v-model="searchText"
          searchable
          @search="liveNameSearch"
          maxlength="100"
        ></yxt-input>
      </div>
      <yxt-table
        :ref="refTable"
        :height="tableHeight"
        class="yxtbiz-live-selector__main mt16"
        v-loading="loading"
        :data="liveList"
        @select-all="selectAll"
        @select="select"
        :default-sort="{prop: 'beginTime',  order: ''}"
        @sort-change="sortChange"
      >
        <yxt-table-column
          type="selection"
          :show-overflow-tooltip="false"
          width="30"
          align="right"
          clear-padding="left-right"
        ></yxt-table-column>
        <yxt-table-column
          prop="roomName"
          :label="$t('pc_biz_ls_name')"
          align="left"
        >
          <template slot-scope="scope">
            <div class="yxtbiz-exam-arrange-selector__name">
              <yxt-tooltip
                placement="top"
                open-filter
                :content="scope.row.roomName"
                effect="dark">
                <div class="ellipsis">{{scope.row.roomName}}</div>
              </yxt-tooltip>
              <span
                class="ml8 yxtbiz-exam-arrange-selector__grouptag"
                v-if="scope.row.orgType === orgType.ISORG">{{ $t('pc_biz_ls_group') }}</span>
            </div>
          </template>
        </yxt-table-column>
        <yxt-table-column
          :label="$t('pc_biz_ls_creater')"
          prop="createUserName"
          :formatter="createFormatter"
          width="80"
        ></yxt-table-column>
        <yxt-table-column
          :label="$t('pc_biz_ls_teacher')"
          :formatter="teacherFormatter"
          width="80"
        ></yxt-table-column>
        <yxt-table-column
          :label="$t('pc_biz_ls_status')"
          prop="roomStatus"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <yxt-tag
              is-status-tag
              :type="scope.row.roomStatus === roomStatus.living
                  ? 'success'
                  : 'failure'
                "
              size="mini"
            >{{ getStatusName(scope.row.roomStatus) }}</yxt-tag
            >
          </template>
        </yxt-table-column>
        <!-- 开始时间 -->
        <yxt-table-column
          prop="beginTime"
          :label="$t('pc_biz_ls_starttime')"
          sortable="custom"
          width="180"
          :formatter="createTimeFormatter"
        ></yxt-table-column>
      </yxt-table>
      <div class="mt16 clearfix">
        <yxt-button type="text" :loading="selectAllBtnLoading" :disabled="!liveList.length" @click="selectAllPage">
          {{$t('pc_biz_live_selectall'/** 全部筛选结果 */)}}
        </yxt-button>
        <yxt-pagination
          class="pull-right"
          :page-size="paging.limit"
          :total="paging.count"
          :current-page="paging.current"
          :page-count="paging.totalPage"
          @size-change="sizeChange"
          @current-change="pageChange"
          layout="total, prev, pager, next"
        ></yxt-pagination>
      </div>
    </div>
    <div class="yxtbiz-live-selector__right">
      <div v-if='maxCount!==-1'>{{$t('pc_biz_ls_choosed-limit' /*  已选择：{0}/{1} */ , [selectedList.length,maxCount])}}</div>
      <div v-else>{{$t('pc_biz_ls_choosed' /* 已选择{0}项 */, [selectedList.length])}}</div>
      <yxt-scrollbar :fit-height="true" class='yxtbiz-live-selector__selectList'>
        <check-list :data="selectedList">
          <template slot-scope="scope">
            <yxt-tag
              @close="deleteItem(item)"
              class="mr5"
              size="small"
              type="info"
              style="margin-bottom:5px;"
              closable
              :key="item.id"
              v-for="item in scope.list">
              <yxt-tooltip :content="item.roomName " placement="top" open-filter>
                <span class="yxtbiz-user-selector-tag">{{ item.roomName }}</span>
              </yxt-tooltip>
            </yxt-tag>
          </template>
        </check-list>
      </yxt-scrollbar>
      <yxt-button
        type='text'
        class="yxtbiz-user-clear font-size-12 color-gray-9 hover-primary-6 "
        @click="clear"
        :disabled="selectedList.length === 0"
      >{{ $t('pc_biz_ls_clearAll') }}</yxt-button>
    </div>

    <yxtbiz-create-live
      showType="drawer"
      :visible.sync="createrVisible"
      @onSuccess="onCreateSuccess"
    >
    </yxtbiz-create-live>
  </div>
</template>

<script>
import { Pagination, Table, TableColumn } from 'yxt-pc';
import CheckList from '../../live-selector/src/components/checkList';
import { fetchTliveList } from './service';
import resizeTable from '../mixins/resizeTable';
import dateUtil from 'yxt-biz-pc/src/utils/date';

const ROOM_STATUS = {
  noStart: 0,
  living: 1,
  ended: 2,
  deleted: -1
};
// 集团版、非集团版
const ORGTYPE = {
  ISORG: 1,
  NOTORG: 0
};

export default {
  name: 'YxtbizLiveSelector',
  components: {
    YxtPagination: Pagination,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    CheckList
  },
  mixins: [resizeTable],
  props: {
    // 是否允许创建直播，功能暂未实现，不支持使用
    allowCreate: {
      type: Boolean,
      default: false
    },
    // 列表直播状态
    liveStatus: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 当前选则的对象
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 组件一次做提供数据（默认不限制）
    maxCount: {
      type: Number,
      default: 10000
    },
    // 直播类型
    sourceType: {
      type: Array,
      default: []
    },
    // 是否集团化，支持跨机构选择
    groupSelect: {
      type: Boolean,
      default: false
    },
    functionCode: {
      type: String,
      default: ''
    },
    orgFunctionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    orgDataPermissionCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      publishDate: [],
      tableHeight: 0,
      createrVisible: false,
      statusList: [
        {
          value: ROOM_STATUS.deleted,
          label: this.$t('pc_biz_ls_deleted' /* 已删除 */)
        },
        {
          value: ROOM_STATUS.noStart,
          label: this.$t('pc_biz_ls_notStart' /* 未开始 */)
        },
        {
          value: ROOM_STATUS.living,
          label: this.$t('pc_biz_ls_inProgress' /* 进行中 */)
        },
        {
          value: ROOM_STATUS.ended,
          label: this.$t('pc_biz_ls_over' /* 已结束 */)
        }
      ],
      orgType: ORGTYPE,
      roomStatus: ROOM_STATUS,
      refTable: 'stb',
      loading: false,
      liveList: [],
      types: {
        [ROOM_STATUS.living]: this.$t('pc_biz_ls_inProgress' /* 进行中 */),
        [ROOM_STATUS.noStart]: this.$t('pc_biz_ls_notStart' /* 未开始 */),
        [ROOM_STATUS.ended]: this.$t('pc_biz_ls_over' /* 已结束 */),
        [ROOM_STATUS.deleted]: this.$t('pc_biz_ls_deleted' /* 已删除 */)
      },
      searchText: '',
      listParams: {
        beginTimeFrom: '',
        beginTimeTo: '',
        roomStatus: [],
        roomName: '',
        selectOrgId: ''
      },
      listQuery: {
        direction: 'desc',
        orderby: ''
      },
      paging: {
        limit: 20,
        count: 0,
        pages: 0,
        current: 1,
        totalPage: 0
      },
      selectedList: [],
      selected: new Map(),
      selectAllBtnLoading: false,
      showMoreFilter: false
    };
  },
  computed: {
    getQuerys() {
      return {
        ...this.listQuery,
        current: this.paging.current,
        limit: this.paging.limit
      };
    },
    getParams() {
      return {
        ...this.listParams,
        sourceType: this.sourceType,
        dataPermissionCode: this.dataPermissionCode,
        functionCode: this.functionCode,
        groupSelect: this.groupSelect ? 1 : 0,
        orgDataPermissionCode: this.orgDataPermissionCode,
        orgFunctionCode: this.orgFunctionCode
      };
    }
  },
  watch: {
    getQuerys: {
      handler() {
        this.getRoomList();
      },
      deep: true
    },
    getParams: {
      handler() {
        this.getRoomList();
      },
      deep: true
    },
    value(v) {
      this.formatModel(v);
    }
  },
  created() {
    this.listParams.roomStatus = this.liveStatus;
    if (this.liveStatus.length > 0) {
      let statusArr = [];
      this.liveStatus.forEach((i)=>{
        statusArr.push(this.statusList.filter((item)=> item.value === i)[0]);
      });
      this.statusList = statusArr;
    }
    this.formatModel(this.value);
  },
  methods: {
    createLive() {
      this.createrVisible = true;
    },
    changeOrg(org) {
      this.listParams.selectOrgId = org ? org.orgId : undefined;
      this.resetCurrent();
    },
    changeDate() {
      this.listParams.beginTimeFrom = this.publishDate ? this.shortDate(null, null, this.publishDate[0]) : '';
      this.listParams.beginTimeTo = this.publishDate ? this.shortDate(null, null, this.publishDate[1]) : '';
      this.resetCurrent();
    },
    resetCurrent() {
      this.paging.current = 1;
    },
    shortDate(row, col, val) {
      if (!val) return '--';
      if (typeof val === 'string') {
        val = val.replace(/-/g, '/');
      }
      return dateUtil.format(new Date(val), 'yyyy-MM-dd');
    },
    liveNameSearch(value) {
      this.listParams.roomName = value;
      this.resetCurrent();
    },
    async getRoomList() {
      const search = {
        ...this.getParams,
        roomStatus: this.listParams.roomStatus.length === 0 ? this.liveStatus : this.listParams.roomStatus,
        roomName: this.getParams.roomName.trim()
      };
      this.loading = true;
      try {
        const {
          datas,
          paging: { pages, count }
        } = await fetchTliveList(search, this.getQuerys);
        // 如果当前页没有数据,则重新获取最后一页的数据
        if (!datas.length && this.paging.current !== 1) {
          this.paging.current = pages || 1;
          return;
        }
        this.liveList = datas;
        this.paging.pages = pages;
        this.paging.count = count;
        this.$nextTick(() => {
          this.setChecked();
        });
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    // 排序
    async sortChange({ prop, order }) {
      this.paging.current = 1;
      this.listQuery.orderby = order === null ? '' : prop;
      this.listQuery.direction = order || '';
    },
    sizeChange(size) {
      this.paging.limit = size;
      this.paging.current = 1;
    },
    pageChange(page) {
      this.paging.current = page;
    },
    createFormatter(row) {
      const createUserName = row.createUserName ? row.createUserName : '--';
      return <yxtbiz-user-name name={createUserName}></yxtbiz-user-name>;
    },
    teacherFormatter(row) {
      const teacher = row.masterUserName ? row.masterUserName : '--';
      return (
        <yxtbiz-user-name name={teacher || ''}></yxtbiz-user-name>
      );
    },
    getStatusName(status) {
      return this.types[status];
    },
    createTimeFormatter(row) {
      return row.beginTime.substring(0, row.beginTime.length - 3);
    },
    select(item, row) {
      let isAdd = item.some(v => {
        return v.id === row.id;
      });
      if (!isAdd) {
        this.selected.delete(row.id);
      } else {
        if (this.selected.size < this.maxCount) {
          this.selected.set(row.id, row);
        } else {
          this.$message.error(this.$t('pc_biz_ls_maxCount' /* 最多可以选择{0}项 */, [this.maxCount]));
          this.$refs['stb'].toggleRowSelection(row, false);
        }
      }
      this.outPut();
    },
    selectAll(items) {
      if (!(this.selected.size + items.length > this.maxCount)) {
        let isAdd = items.length !== 0;
        this.liveList.forEach(obj => {
          if (!isAdd) {
            this.selected.delete(obj.id);
          } else {
            this.selected.set(obj.id, obj);
          }
        });
        this.outPut();
      } else {
        // 取出在所有选中的list中与之前选中的值的差集取消选中
        let newArrLists = [];
        let idArr = this.selectedList.map(item => item.id);
        newArrLists = items.filter(list => {
          return !idArr.includes(list.id);
        });
        newArrLists.forEach(item => {
          this.$nextTick(() => {
            this.$refs['stb'].toggleRowSelection(item, false);
          });
        });
        this.$message.error(this.$t('pc_biz_ls_maxCount' /* 最多可以选择{0}项 */, [this.maxCount]));
      }
    },
    setChecked() {
      this.liveList.forEach(obj => {
        if (this.selected.has(obj.id)) {
          this.$refs['stb'].toggleRowSelection(obj, true);
        } else {
          this.$refs['stb'].toggleRowSelection(obj, false);
        }
      });
    },
    outPut() {
      this.selectedList = Array.from(this.selected.values());
      this.$emit('select', this.selectedList);
    },
    getSelected() {
      return Array.from(this.selected.values());
    },
    isNumber(value) {
      return typeof value === 'number' && !isNaN(value);
    },
    deleteItem(item) {
      let row = item;
      for (let index = 0; index < this.liveList.length; index++) {
        const element = this.liveList[index];
        if (element.id === item.id) {
          row = element;
          break;
        }
      }
      if (row) {
        this.$refs['stb'].toggleRowSelection(row, false);
      }
      this.selected.delete(item.id);
      this.outPut();
    },
    clear() {
      let row = null;
      this.selected.forEach((_, key)=>{
        if (this.selected.has(key)) {
          for (let index = 0; index < this.liveList.length; index++) {
            const element = this.liveList[index];
            if (element.id === key) {
              row = element;
              break;
            }
          }
          if (row) {
            this.$refs['stb'].toggleRowSelection(row, false);
          }
        }
      });
      this.selected.clear();
      this.outPut();
    },
    formatModel(v) {
      this.selected.clear();
      v.forEach(obj => {
        this.selected.set(obj.id, obj);
      });
      this.selectedList = Array.from(this.selected.values());
    },
    onCreateSuccess() {
      this.paging.current !== 1 ? this.paging.current = 1 : this.getRoomList();
    },
    async selectAllPage() {
      this.selectAllBtnLoading = true;
      const curretCount = this.selectedList.length;
      if (curretCount < this.maxCount) {
        const search = {
          ...this.getParams,
          roomStatus: this.listParams.roomStatus.length === 0 ? this.liveStatus : this.listParams.roomStatus,
          roomName: this.getParams.roomName.trim()
        };
        const query = {...this.getQuerys, current: 1, limit: this.paging.count > this.maxCount ? this.maxCount : this.paging.count};
        try {
          const { datas } = await fetchTliveList(search, query);
          for (let obj of datas) {
            if (this.selected.size < this.maxCount && !this.selected.has(obj.id)) {
              this.selected.set(obj.id, obj);
            }
            if (this.selected.size === this.maxCount) break;
          }
          this.setChecked();
          this.outPut();
        } catch (error) {
          console.log(error);
        } finally {
          this.selectAllBtnLoading = false;
        }
      } else {
        this.$message.error(this.$t('pc_biz_ls_maxCount' /* 最多可以选择{0}项 */, [this.maxCount]));
        this.selectAllBtnLoading = false;
      }
    }
  }
};
</script>
