<template>
  <div>
    <p class="fw-600 font-size-14 text-59">上传须知：</p>
    <p v-for="u in uploadFAQ" :key="u" class="color-gray-8 font-size-12 mt10">{{ u }}</p>
    <p class="fw-600 font-size-14 text-59 mt20">支持的格式大小：</p>
    <p v-for="s in supportFileFAQ" :key="s" class="color-gray-8 font-size-12 mt10">{{ s }}</p>
  </div>
</template>
ß
<script>
export default {
  name: 'UploadFAQ',
  data() {
    return {
      uploadFAQ: [
        '1. 在创建课程时上传的知识，默认为隐藏状态，只在课程内可见，保存在课程目录下',
        '2. 一次最多同时上传10个文件',
        '3. 请勿上传已设置加密或只读的文档，否则系统将无法转换而报错'
      ],
      supportFileFAQ: [
        '1. 文档：.doc、.docx、.ppt、.pptx、.xls、.xlsx、.pps、.pdf；单个文档小于10MB',
        '2. 图片： .jpg、.jpeg、.gif、.png、.bmp、.ico；单个图片小于10MB',
        '3. 视频：.wmv、.mp4、.flv、.avi、.rmvb、.mpg、.mkv、.mov；单个视频小于1GB',
        '4. 音频：.w4v、.m4a、.wma、.wav、.mp3、.amr；单个音频小于200MB'
        // '5. 压缩包： .zip、.rar；单个压缩包小于1GB，压缩包仅支持pc端'
      ]
    };
  }
};
</script>
