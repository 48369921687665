import { o2oApi, hwbaseApi } from 'yxt-biz-pc/packages/api';
import qs from 'qs';

// o2o 获取当前用户其数据权限下的作业模板列表，按更新时间倒序
export const getO2oTemplatePaged = (query) => {
  return o2oApi.get(`/homework/template/paged-templates?${qs.stringify(query)}`);
};

// ulcd 获取当前用户其数据权限下的作业模板列表，按更新时间倒序
export const getUlcdTemplatePaged = (query) => {
  return hwbaseApi.get(`/template/paged-tempaltes?${qs.stringify(query)}`);
};

// 获取指定作业模板的详情
export const getTemplateDetail = (query) => {
  return o2oApi.get(`/homework/template/detail?${qs.stringify(query)}`);
};
