<!--
 * @Author: “周晓清“ “zhouxq@yxt.com”
 * @Date: 2021-08-31 10:16:11
 * @LastEditors: “周晓清“ “zhouxq@yxt.com”
 * @LastEditTime: 2022-07-14 11:00:06
 * @FilePath: \yxt-biz-pc\packages\teacher-selector\src\main.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="yxtbiz-teacher-selector" :class="{'yxtbiz-teacher-selector-padding': showPadding}">
    <yxt-tabs
      v-if="setTabs.length > 1"
      @tab-click="tabClick"
      v-model="activeName"
    >
      <yxt-tab-pane
        :key="index"
        v-for="(tab, index) in setTabs"
        :name="tab.value"
      >
        <span slot="label">{{ tab.label }}</span>
      </yxt-tab-pane>
    </yxt-tabs>
    <yxt-single-user
      class="yxtbiz-teacher-selector__user" 
      v-model="user" 
      v-if="isSelectUser&&singleMode" 
      :dataPermissionCode="selectUserPermissionCode" 
      :functionCode="functionCode"
    />
    <yxt-user-selector
      class="yxtbiz-teacher-selector__user"
      v-if="isSelectUser && !singleMode"
      :limit="500"
      :data="users"
      :tabs="['persons']"
      @change="changeFn"
      :functionCode="functionCode"
      :dataPermissionCode="selectUserPermissionCode"
    ></yxt-user-selector>
    <div class="yxtbiz-teacher-container font-size-14" v-if="!isSelectUser">
      <!-- <yxtbiz-auth-selector type="2" class="mr12 d-in-block pull-left"  width="144" @change="authChange" size="" placeholder="讲师范围"></yxtbiz-auth-selector> -->
      <!-- 选择平台机构 -->
      <div class="yxtbiz-teacher-container__flex">
        <yxtbiz-group-org-select
          v-if="selectOrg && this.activeName !== 'giveLesson'"
          class="mr12"
          setCurrentOrg
          :disabledOrgIds="disabledOrgIds"
          :selectFirst="true"
          :clearable="false"
          :multiple="false"
          :functionCode="functionCode"
          :dataPermissionCode="dataPermissionCode"
          @change="changeOrg"
        ></yxtbiz-group-org-select>
        <!-- 讲师类型 -->
        <yxt-select
          v-if="type === -1 && this.activeName !== 'giveLesson'"
          v-model="teType"
          class="mr12"
          :placeholder="$t('pc_biz_te_tip_teachertype')"
          style="width: 144px"
          @change="getTeList(true)"
        >
          <yxt-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></yxt-option>
        </yxt-select>
        <select-popover
          v-if="teType !== '1'"
          class="mr12"
          :style="{ width: this.activeName !== 'giveLesson' ? '144px' : '180px' }"
          ref="selectPopover"
          is-dept
          :selectOpition="selectOpition"
        >
          <dept-tree
            :functionCode="functionCode"
            :dataPermissionCode="dataPermissionCode"
            :filterable="true"
            :visibleOrgSelector="false"
            :targetOrgId="teSearch.orgId"
            :enableGroupCorp="selectOrg"
            :count="deptCount"
            @nodeClick="nodeClick"
          ></dept-tree>
        </select-popover>
        <!-- 选择级别 -->
        <yxt-select
          v-if="this.activeName !== 'giveLesson'"
          v-model="levelVal"
          class="mr12"
          style="width: 144px"
          clearable
          :placeholder="$t('pc_biz_te_selete_level')"
          @change="changeLevel"
        >
          <yxt-option
            v-for="item in levelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </yxt-select>
        <!-- 选择讲师状态 -->
        <yxt-select
          v-if="isShowTeStatus"
          v-model="teStatusVal"
          class="mr12"
          style="width: 144px"
          clearable
          :placeholder="$t('pc_biz_te_status')"
          @change="changeLevel"
        >
          <yxt-option
            v-for="item in teStatuslList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </yxt-select>
        <!-- 请输入姓名/账号搜索 -->
        <yxt-input
          :style="{ width: this.activeName !== 'giveLesson' ? '183px' : '240px' }"
          :placeholder="teType === '1' ? $t('pc_biz_te_tip_enternamesearch') : $t('pc_biz_te_tip_enternameaccountsearch')"
          v-model="teSearch.keyword"
          searchable
          maxlength="50"
          @search="teNameSearch"
        >
        </yxt-input>
      </div>
    </div>
    <div class="yxtbiz-teacher-selector__main mt16 mb16" v-if="!isSelectUser">
      <yxt-table
        ref="stb"
        class="yxtbiz-teacher-selector__table"
        @select-all="selectAll"
        @select="select"
        v-loading="loading"
        :key="`t${renderCount}`"
        :data="teaList"
        :height="tableHeight"
        :default-sort="{ prop: 'updateTime', order: 'desc' }"
        @sort-change="sortChange"
      >
        <yxt-table-column
          v-if="!isSingle"
          :selectable="selectable"
          :show-overflow-tooltip="false"
          class-name="yxtbiz-table-selection"
          type="selection"
          width="40"
        ></yxt-table-column>
        <!-- 讲师姓名/账号 -->
        <yxt-table-column
          :label="teType === '1' ? $t('pc_biz_te_lbl_teachername') : $t('pc_biz_te_lbl_teachernameaccount')"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            <div class="yxtbiz-teacher-flex-center">
              <yxt-tooltip effect="dark" placement="top" :open-filter="true">
                <div slot="content">
                  <span
                    ><yxtbiz-user-name :name="scope.row.fullname" />{{
                      scope.row.type === 0 || teType !== '1' ? `（${scope.row.username}）` : ''
                    }}</span
                  >
                </div>
                <div class="ellipsis nowrap">
                  <span
                    ><yxtbiz-user-name :name="scope.row.fullname" />{{
                      scope.row.type === 0 || teType !== '1' ? `（${scope.row.username}）` : ''
                    }}</span
                  >
                </div>
              </yxt-tooltip>
              <yxt-tag v-if="scope.row.share" size="mini" class="ml4">{{
                $t('pc_biz_te_lbl_group_share' /* 共享 */)
              }}</yxt-tag>
            </div>
          </template>
        </yxt-table-column>
        <!-- 部门 -->
        <yxt-table-column
          v-if="!shareSource && teType !== '1'"
          show-overflow-tooltip
          popover-html
          :label="$t('pc_biz_te_lbl_department')"
          prop="departmentName"
          align="left"
        >
          <template slot-scope="scope">
            <yxtbiz-dept-name
              :name="scope.row.departmentName || '--'"
            /> </template
        ></yxt-table-column>
        <!-- 级别 -->
        <yxt-table-column
          show-overflow-tooltip
          :label="$t('pc_biz_te_lbl_level')"
          prop="levelName"
          align="left"
        >
          <template slot-scope="scope">
            {{ scope.row.levelName || '--' }}
          </template>
        </yxt-table-column>
        <!-- 状态（针对讲师预约） -->
        <yxt-table-column
          v-if="showSubscribeStatus"
          show-overflow-tooltip
          :label="$t('pc_biz_tcm_status')"
          prop="haveSameTime"
          align="left"
        >
          <template slot-scope="scope">
            {{ scope.row.haveSameTime ? $t('pc_biz_lbl_has_arranged') : $t('pc_biz_lbl_no_arrange') }}
          </template>
        </yxt-table-column>
        <!-- 讲师状态 -->
        <yxt-table-column
          v-if="isShowTeStatus"
          show-overflow-tooltip
          :label="$t('pc_biz_tcm_testatus')"
          prop="disabled"
          align="left"
        >
          <template slot-scope="scope">
            {{ scope.row.disabled ? $t('pc_biz_te_disable') : $t('pc_biz_te_enable') }}
          </template>
        </yxt-table-column>
        <!-- 操作 -->
        <yxt-table-column
          v-if="isSingle"
          :label="$t('pc_biz_te_lbl_operation')"
          width="100"
          align="left"
        >
          <template slot-scope="scope">
            <!-- 取消选择/选择 -->
            <yxt-link
              @click="selectTea(scope.row)"
              :underline="false"
              type="primary"
              >{{
                value &&
                (value.id === scope.row.id ||
                  (value[0] && value[0].id === scope.row.id))
                  ? $t('pc_biz_te_btn_cancelselect')
                  : $t('pc_biz_te_btn_select')
              }}</yxt-link
            >
          </template>
        </yxt-table-column>
        <template slot="empty">
          <div style="line-height: normal">
            <yxt-empty
              :empty-text="
                activeName === 'certifiedLecturer'
                  ? $t(
                      'pc_biz_te_no_certification_lecturer' /*暂无认证讲师，您可以在讲师库选择其他讲师*/
                    )
                  : $t('pc_biz_lbl_nodata' /*暂无数据*/)
              "
            />
          </div>
        </template>
      </yxt-table>
      <div class="mt16 clearfix pr">
        <yxt-pagination
          class="pull-right"
          :page-size="teSearch.limit"
          :total="count"
          :current-page="page"
          layout="total, prev, pager, next"
          @current-change="pageChange"
          @size-change="sizeChange"
        ></yxt-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getTeList,
  GetTeChooseType,
  getTeLevelList,
  getGroupTeLevelList,
  getOfflineTeList,
  getRptTeList
} from './service';
import dateUtil from 'yxt-biz-pc/src/utils/date';
import resizeTable from '../../user-selector/mixins/resizeTable';
import SingleUser from 'yxt-biz-pc/packages/single-user';
import UserName from 'yxt-biz-pc/packages/user-name';
import DeptName from 'yxt-biz-pc/packages/dept-name';
import { Table, TableColumn, Pagination, Link, Select, Option } from 'yxt-pc';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import SelectPopover from '../../check-person-range/src/components/SelectPopover.vue';
import deptTree from '../../dept-tree';

export default {
  name: 'YxtbizTeacherSelector',
  components: {
    YxtSingleUser: SingleUser,
    YxtbizUserName: UserName,
    YxtbizDeptName: DeptName,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtLink: Link,
    YxtSelect: Select,
    YxtOption: Option,
    SelectPopover,
    deptTree
  },
  mixins: [resizeTable],
  props: {
    // 是否显示讲师状态
    isShowTeStatus: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array | Object,
      default: () => {
        return {};
      }
    },
    type: {
      type: Number,
      default: -1
    },
    // 是否显示授课员工
    allowSelectTeachUser: {
      type: Boolean,
      default: false
    },
    // 是否可以有选人模式
    allowSelectUser: {
      type: Boolean,
      default: false
    },
    // 是否强制单选模式， 默认会根据传入的数据类型修改。true时强制单选模式
    singleMode: {
      type: Boolean,
      default: false
    },
    functionCode: {
      type: String,
      default: ''
    },
    // 是否切换到新组件模式 0-否 1-是 控制禁用/删除/预备的讲师是否显示，1为新组件模式，不显示
    newReq: {
      type: Boolean,
      default: false
    },
    // 是否显示预备讲师(默认不显示预备) 0-否 1-是 （预备讲师显示的控制优先级高于newReq）
    prepared: {
      type: Boolean,
      default: false
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    selectUserPermissionCode: {
      type: String,
      default: ''
    },
    // 跨机构的数据权限点（集团版）
    selectOrgPermissionCode: {
      type: String,
      default: ''
    },
    courseId: {
      type: String,
      default: ''
    },
    // 是否包含所有类型的数据（禁用的）
    showAll: {
      type: Boolean,
      default: false
    },
    // 是否是共享资源（集团版）
    shareSource: {
      type: Boolean,
      default: false
    },
    // 是否支持跨机构选择（集团版）
    selectOrg: {
      type: Boolean,
      default: false
    },
    // 是否是集团版
    enableGroupCorp: {
      type: Boolean,
      default: false
    },
    // 增加表格是否可选参数
    selectable: {
      type: Function,
      default: () => true
    },
    // 针对预约讲师需求
    showSubscribeStatus: {
      type: Boolean,
      default: false
    },
    subscribeStartTime: {
      type: String,
      default: ''
    },
    subscribeEndTime: {
      type: String,
      default: ''
    },
    needPadding: {
      type: Boolean,
      default: false
    },
    // 是否报表专用
    isRpt: {
      type: Boolean,
      default: false
    },
    // 控制部门筛选条件是否显示数量，同部门组件的count属性
    deptCount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      teStatusVal: '',
      teStatuslList: [
        {
          value: 0,
          label: this.$t('pc_biz_te_enable') // 启用
        },
        {
          value: 2,
          label: this.$t('pc_biz_te_disable') // 禁用
        }
      ],
      renderCount: 0,
      page: 1,
      refTable: 'stb',
      tableHeight: 0,
      teSearch: {
        type: -1,
        queryType: 1,
        keyword: '',
        keywordType: 0,
        limit: 10,
        offset: 0,
        orderby: 'updateTime',
        direction: 'DESC',
        navCode: this.functionCode,
        dataPermissionCode: this.dataPermissionCode,
        deptId: '', // 部门id
        levelId: '' // 级别id
      },
      loaded: 1,
      loading: false,
      totalPage: 0,
      count: 0,
      teaList: [],
      teType: '0',
      options: [
        {
          value: '0',
          label: this.$t('pc_biz_te_lbl_internallecturer') // 内部讲师
        },
        {
          value: '1',
          label: this.$t('pc_biz_te_lbl_externallecturer') // 外部讲师
        }
      ],
      cerTempList: [],
      selected: {},
      user: {},
      users: [],
      isSingle: true, // m2只有单选模式
      isSingleVal: true,
      isCanSelectUser: false,
      isSelectUser: false,
      setTabs: [
        {
          label: this.$t('biz_te_official_teach'),
          value: 'teacher'
        }
      ],
      activeName: this.courseId ? 'certifiedLecturer' : 'teacher',
      teSelected: [],
      teData: [],
      firstFlag: false,
      selectOpition: {
        placeholder: i18n.t('pc_biz_te_dept' /* 选择部门 */),
        checkWord: ''
      },
      levelList: [], // 级别列表
      levelVal: '',
      query: {
        showNoClassification: '',
        orgId: ''
      }
    };
  },
  computed: {
    // 开启集团且弱管理
    noStrong() {
      return (
        window.localStorage.getItem('enableGroupCorp') === 'true' &&
        window.localStorage.getItem('manageMode') === '1'
      );
    },
    // 集团服务下的级别接口
    groupLevel() {
      return this.shareSource || this.selectOrg || this.enableGroupCorp;
    },
    // 针对o2o选择讲师是否可选人员配置（/te/externalfacade/o2o/choose/type）
    // 根据needpadding对无tab样式做特殊处理
    showPadding() {
      return this.needPadding && this.setTabs.length < 2;
    }
  },
  created() {
    this.isSingleVal =
      !this.value || (!this.value.length && this.value.length !== 0);
    this.isSingle = this.singleMode || this.isSingleVal;
    this.teData = this.value;
    if (this.singleMode) {
      this.user = this.teData[0] || {};
    }
    this.formatModel(this.value);
    this.initChooseType();
    !this.selectOrg && this.getTeList();
    !this.selectOrg && this.getLevelList();
  },
  methods: {
    shortDate(row, col, val, index) {
      if (!val) return '';
      return dateUtil.format(new Date(val), 'yyyy-MM-dd');
    },
    filterNode(value, data) {
      return !value || data.label.indexOf(value) !== -1;
    },
    // 列表数据
    getTeList(isReset) {
      // 报表专用
      if (this.isRpt) {
        if (isReset) {
          this.page = 1;
          this.teSearch.offset = 0;
        }
        this.teSearch.type = this.type === -1 ? this.teType || -1 : this.type;
        this.newReq && (this.teSearch.newReq = 1);
        this.prepared && (this.teSearch.prepared = 1);
        this.teSearch.keyword = this.teSearch.keyword.trim();
        this.teSearch.rptReq = this.showAll ? 1 : 0;
        this.loading = true;
        // 是否查询有讲师授课数据的员工 0-否 1-是
        this.teSearch.selectEmp = this.activeName === 'giveLesson' ? 1 : 0;
        // 继承老的请求体，不考虑课程、不考虑预约讲师；重写报表请求体
        const rptSearchBody = {
          dataPermissionCode: this.teSearch.dataPermissionCode,
          deptId: this.teSearch.type === '1' ? '' : this.teSearch.deptId,
          keyword: this.teSearch.keyword,
          levelId: this.teSearch.levelId,
          limit: this.teSearch.limit,
          navCode: this.teSearch.navCode,
          offset: this.teSearch.offset,
          tabType: this.teSearch.selectEmp,
          teType: this.teSearch.type
        };
        // 继承集团版入参，适应接口后期扩展
        (this.shareSource || this.selectOrg || this.enableGroupCorp) && (rptSearchBody.groupReq = 1); // 是否是集团请求 0-否 1-是
        this.shareSource && (rptSearchBody.selectShare = 1); // 是否显示共享讲师 0: 默认显示 1：不显示（共享资源不显示）
        this.selectOrg && (rptSearchBody.selectOrg = 1); // 是否需要跨平台显示讲师（集团化适用）0-否 1-是

        getRptTeList(rptSearchBody)
          .then(result => {
            this.teaList = result.datas.map(item => {
              item.id = item.teUserId;
              return item;
            });
            this.totalPage = result.paging.pages;
            this.count = result.paging.count;
            this.loaded--;
            this.loading = false;
            this.$nextTick(() => {
              this.setChecked();
            });
          })
          .catch(e => {
            this.loading = false;
            console.log(e);
          });
      } else {
        // 非报表使用，逻辑保留
        if (isReset) {
          this.page = 1;
          this.teSearch.offset = 0;
        }
        this.teSearch.type = this.type === -1 ? this.teType || -1 : this.type;
        this.newReq && (this.teSearch.newReq = 1);
        this.prepared && (this.teSearch.prepared = 1);
        this.teSearch.keyword = this.teSearch.keyword.trim();
        this.teSearch.rptReq = this.showAll ? 1 : 0;
        (this.shareSource || this.selectOrg || this.enableGroupCorp) &&
          (this.teSearch.groupReq = 1); // 是否是集团请求 0-否 1-是
        this.shareSource && (this.teSearch.selectShare = 1); // 是否显示共享讲师 0: 默认显示 1：不显示（共享资源不显示）
        this.selectOrg && (this.teSearch.selectOrg = 1); // 是否需要跨平台显示讲师（集团化适用）0-否 1-是
        this.loading = true;
        if (this.activeName === 'certifiedLecturer' || this.courseId) {
          this.teSearch.courseId = this.courseId;
        }
        // 是否查询有讲师授课数据的员工 0-否 1-是
        this.teSearch.selectEmp = this.activeName === 'giveLesson' ? 1 : 0;
        // 预约讲师接收预约时段参数
        if (this.showSubscribeStatus) {
          this.teSearch.apptStartDate = this.subscribeStartTime || '';
          this.teSearch.apptEndDate = this.subscribeEndTime || '';
        }
        if (this.teSearch.type === '1') {
          this.teSearch.deptId = '';
        }
        if (this.activeName !== 'certifiedLecturer' && this.teSearch.deptId) {
          this.teSearch.includeChildDept = 1;
        } else {
          delete this.teSearch.includeChildDept;
        }
        if (this.isShowTeStatus) {
          this.teSearch.disable = this.teStatusVal || this.teStatusVal === 0 ? this.teStatusVal : 1;
        }
        let teApi =
          this.activeName === 'certifiedLecturer' ? getOfflineTeList : getTeList;
        teApi(this.teSearch)
          .then(result => {
            this.teaList = result.datas;
            this.totalPage = result.paging.pages;
            this.count = result.paging.count;
            this.loaded--;
            this.loading = false;
            this.$nextTick(() => {
              this.setChecked();
            });
          })
          .catch(e => {
            this.loading = false;
            console.log(e);
          });
      }
    },
    // 多选是否显示tab
    initChooseType() {
      GetTeChooseType()
        .then(result => {
          if (this.allowSelectTeachUser) {
            this.setTabs = [
              ...this.setTabs,
              {
                label: this.$t('biz_te_give_lesson_user'), // 授课员工
                value: 'giveLesson'
              }
            ];
          }
          this.isCanSelectUser = this.allowSelectUser && result.pattern === 0;
          if (this.isCanSelectUser) {
            this.setTabs = [
              ...this.setTabs,
              {
                label: this.$t('biz_te_user'), // 员工
                value: 'person'
              }
            ];
          }
          if (this.courseId) {
            this.setTabs.unshift({
              label: this.$t('pc_te_lbl_certified_lecture'), // 认证讲师
              value: 'certifiedLecturer'
            });
            this.activeName = 'certifiedLecturer';
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    teNameSearch() {
      this.teSearch.searchKey = this.teCatalogSearch;
      this.getTeList(true);
    },
    authChange(value) {
      // 1 我创建与负责的, 2 直属员工创建与负责的, 3 所辖部门员工创建与负责的
      this.teSearch.queryType = value;
      this.getTeList(true);
    },
    sortChange(sort) {
      this.teSearch.direction = sort.order;
      this.teSearch.orderby = sort.prop;
      this.getTeList();
      return false;
    },
    sizeChange(size) {
      this.teSearch.limit = size;
      this.getTeList(true);
    },
    pageChange(page) {
      this.page = page;
      this.teSearch.offset = (page - 1) * this.teSearch.limit;
      this.getTeList();
    },
    selectTea(item) {
      if (this.isSingleVal) {
        if (this.value && this.value.id) {
          if (item) {
            if (item.id === this.value.id) {
              this.$emit('input', null);
              this.$emit('select', null);
              this.teData = null;
            } else {
              this.$emit('input', item);
              this.$emit('select', item);
              this.teData = item;
            }
          } else {
            this.$emit('input', this.value);
            this.$emit('select', this.value);
            this.teData = this.value;
          }
        } else {
          if (item) {
            this.$emit('input', item);
            this.$emit('select', item);
            this.teData = item;
          } else {
            this.$emit('input', null);
            this.$emit('select', null);
            this.teData = null;
          }
        }
      } else {
        // 兼容之前的多选模式的数组型数据源&& 不是选人场景不可以反选选人的值
        if (item) {
          // 判断是否取消
          if (this.value.length && this.value[0].id === item.id) {
            this.$emit('input', []);
            this.$emit('select', []);
            this.teData = [];
          } else {
            this.$emit('input', item ? [item] : []);
            this.$emit('select', item ? [item] : []);
            this.teData = item ? [item] : [];
          }
        } else {
          this.$emit('input', []);
          this.$emit('select', []);
          this.teData = [];
        }
        // if (
        //   this.value.length > 0 &&
        //   !(item && item.isUser && !this.isSelectUser)
        // ) {
        // } else {
        //   this.$emit('input', item ? [item] : []);
        //   this.$emit('select', item ? [item] : []);
        //   this.teData = item ? [item] : [];
        // }
      }
    },
    selectUser() {
      if (this.user && this.user.id) {
        this.selectTea({
          id: this.user.id,
          fullname: this.user.fullname,
          isUser: true
        });
      } else {
        if (this.teData.length) {
          this.selectTea(this.teData[0]);
        } else {
          this.selectTea();
        }
      }
    },
    getCatasOfTree(arrNew, pId, cataAll) {
      cataAll.forEach(cata => {
        if (cata.parentId === pId || (!pId && !cata.parentId)) {
          cata.label = cata.name;
          cata.children = [];
          this.getCatasOfTree(cata.children, cata.id, cataAll);
          arrNew.push(cata);
        }
      });
    },
    // 选择某个
    select(item, row) {
      let isAdd = item.some(v => {
        return v.id === row.id;
      });
      if (!isAdd) {
        delete this.selected[row.id];
      } else {
        this.selected[row.id] = row;
      }
      this.outPut();
    },
    // 全选
    selectAll(items) {
      let isAdd =
        items.filter(item => {
          return this.selectable(item);
        }).length !== 0;
      this.teaList.forEach(obj => {
        if (this.selectable(obj)) {
          if (!isAdd) {
            delete this.selected[obj.id];
          } else {
            this.selected[obj.id] = obj;
          }
        }
      });
      this.outPut();
    },
    // 删除已选择
    deleteItem(item) {
      let row = item;
      for (let index = 0; index < this.teaList.length; index++) {
        const element = this.teaList[index];
        if (element.id === item.id) {
          row = element;
          break;
        }
      }
      if (row) {
        this.$refs['stb'].toggleRowSelection(row, false);
      }
      delete this.selected[item.id];
      this.$forceUpdate();
      this.outPut();
    },
    // 清空
    clear() {
      let row = null;
      for (const key in this.selected) {
        if (this.selected.hasOwnProperty(key)) {
          for (let index = 0; index < this.teaList.length; index++) {
            const element = this.teaList[index];
            if (element.id === key) {
              row = element;
              break;
            }
          }
          if (row) {
            this.$refs['stb'].toggleRowSelection(row, false);
          }
        }
      }
      this.selected = {};
      this.outPut();
    },
    outPut() {
      this.teSelected = Object.values(this.selected);
      let reArry = this.reduceFn([...this.teSelected]);
      this.$emit('input', reArry);
      this.$emit('select', reArry);
      this.teData = reArry;
    },
    setChecked() {
      if (this.isSelectUser) {
        return;
      }
      this.teaList.forEach(obj => {
        if (this.selected.hasOwnProperty(obj.id)) {
          this.$refs['stb'].toggleRowSelection(obj, true);
        } else {
          this.$refs['stb'] && this.$refs['stb'].toggleRowSelection(obj, false);
        }
      });
    },
    formatModel(v) {
      this.selected = {};
      if (v) {
        if (this.isSingle) {
          // 单选入参v也存在array类型
          if (Object.prototype.toString.call(v) === '[object Object]') {
            if (v.id) {
              this.selected[v.id] = v;
            }
          } else {
            v.forEach(obj => {
              this.selected[obj.id] = obj;
            });
          }
        } else {
          v.forEach(obj => {
            this.selected[obj.id] = obj;
          });
        }
      }
    },
    // tab切换后重新勾选上已选择的讲师
    tabClick() {
      this.teStatusVal = '';
      this.selectOpition.checkWord = '';
      this.teSearch.deptId = ''; // 清空部门选项
      this.teType = this.type === -1 ? '0' : this.type; // 清除讲师已选类型
      this.levelVal = ''; // 清除已选讲师等级
      this.teSearch.keyword = '';
      if (this.activeName !== 'person') this.getTeList();
      this.isSelectUser = this.activeName === 'person';
      this.firstFlag = true;
      if (this.teData && this.teData.length > 0) {
        if (!this.singleMode) {
          this.users = this.teData;
        }
        // 切换到选人tab后，数据不一致清空user
        if (this.isSelectUser && this.user && this.user.id && this.teData[0].id !== this.user.id) {
          this.user = {};
        }
      } else {
        this.users = [];
        this.user = {};
      }
      this.formatModel(this.teData);
      this.$nextTick(() => {
        this.setChecked();
      });
    },
    // 选择员工
    changeFn(users) {
      this.teSelected = Object.values(this.selected);
      if (users && users.length > 0) {
        let listArry = [];
        users.forEach(e => {
          if (e && e.fullname) {
            listArry.push({
              id: e.id,
              fullname: e.fullname,
              isUser: true
            });
          }
        });
        let reArry = this.reduceFn([...listArry]);
        this.$emit('input', reArry);
        this.$emit('select', reArry);
        this.teData = reArry;
      } else {
        this.$emit('input', []);
        this.$emit('select', []);
        this.teData = [];
      }
    },
    // 数组根据ID去重
    reduceFn(arryData) {
      // return arryData;
      let obj = {};
      return arryData.reduce((item, next) => {
        obj[next.id] ? '' : (obj[next.id] = true && item.push(next || ''));
        return item;
      }, []);
    },
    // 跨机构选择
    changeOrg(org) {
      this.teSearch.orgId = org.orgId; // 机构id
      this.selectOpition.checkWord = '';
      this.teSearch.deptId = ''; // 清空部门选项
      this.teType = this.type === -1 ? '0' : this.type; // 清除讲师已选类型
      this.levelVal = ''; // 清除已选讲师等级
      this.getTeList(true);
      this.getLevelList();
    },
    // 选择部门
    nodeClick(a) {
      this.teSearch.deptId = a.id;
      this.selectOpition.checkWord = a.id ? a.name : '';
      this.$refs['selectPopover'].cancel();
      this.getTeList(true);
    },
    // 获取级别列表
    getLevelList() {
      this.query.orgId =
        this.noStrong && this.selectOrg ? this.teSearch.orgId : ''; // 弱管理根据机构变化orgId,否则传''
      let api = this.groupLevel ? getGroupTeLevelList : getTeLevelList;
      api(this.groupLevel ? this.query : null).then(levelIds => {
        this.levelList = levelIds || [];
      });
    },
    // 选择级别
    changeLevel() {
      this.teSearch.levelId = this.levelVal === '-1' ? '' : this.levelVal;
      this.getTeList(true);
    }
  },
  watch: {
    activeName(newVal) {
      if (newVal && newVal === 'person') this.getTeList(true);
    },
    teaList() {
      this.renderCount++;
    },
    teCatalogSearch(val) {
      this.$refs.tree.filter(val);
    },
    value(v) {
      this.formatModel(v);
      this.setChecked();
    },
    user() {
      this.selectUser();
    }
  }
};
</script>
