<template>
  <div class="yxtbiz-survey-selector">
    <div class="yxtbiz-survey-selector__left">
      <div class="yxtbiz-survey-selector__filter clearfix font-size-14">
        <yxtbiz-group-org-select
          v-if="groupSelect"
          class="mr12"
          :clearable="false"
          :multiple="false"
          :functionCode="orgFunctionCode"
          :dataPermissionCode="orgDataPermissionCode"
          @change="changeOrg"
          setCurrentOrg
        ></yxtbiz-group-org-select>
        <yxt-popover
          :append-to-body="false"
          popper-class="yxtbiz-survey-selector__popover"
          placement="bottom-start"
          :visible-arrow="false"
          v-model="showMoreFilter"
        >
          <yxt-button slot="reference" plain class="yxtbiz-filter-btn mr12">
            <yxt-svg
              width="20px"
              height="20px"
              icon-class="filter"
              class="v-top"
            />
            <span class="ml8 v-top">{{
              $t('pc_biz_common_lbl_moreFilter')
            }}</span>
            <i
              class="ml8"
              :class="
                showMoreFilter ? 'yxt-icon-arrow-up' : 'yxt-icon-arrow-down'
              "
            ></i>
          </yxt-button>
          <yxt-form :inline="true">
            <yxt-row :gutter="20">
              <yxt-col :span="12">
                <!-- 调查发布日期 -->
                <yxt-form-item
                  label-width="124px"
                  :label="$t('pc_biz_survey_lbl_surveypublishtime')"
                >
                  <yxt-date-picker
                    @change="changeDate"
                    v-model="publishDate"
                    format="yyyy-MM-dd"
                    type="daterange"
                    :range-separator="$t('pc_biz_survey_lbl_to')"
                    :start-placeholder="$t('pc_comp_datepicker_startDate')"
                    :end-placeholder="$t('pc_comp_datepicker_endDate')"
                  >
                  </yxt-date-picker>
                </yxt-form-item>
              </yxt-col>
              <yxt-col :span="12">
                <!-- 调查状态 -->
                <yxt-form-item
                  label-width="124px"
                  :label="$t('pc_biz_survey_lbl_surveystatus')"
                >
                  <yxt-select
                    v-model="surveySearch.statusList"
                    clearable
                    multiple
                    size="large"
                    :placeholder="$t('pc_select_placeholder')"
                    @change="changeSource"
                  >
                    <yxt-option
                      v-for="item in statusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></yxt-option>
                  </yxt-select>
                </yxt-form-item>
              </yxt-col>
            </yxt-row>
            <yxt-row :gutter="20" class="mt16">
              <yxt-col :span="12">
                <!-- 调查开始日期 -->
                <yxt-form-item
                  label-width="124px"
                  :label="$t('pc_biz_survey_lbl_surveystarttime')"
                >
                  <yxt-date-picker
                    @change="changeDate"
                    v-model="startDate"
                    format="yyyy-MM-dd"
                    type="daterange"
                    :range-separator="$t('pc_biz_survey_lbl_to')"
                    :start-placeholder="$t('pc_comp_datepicker_startDate')"
                    :end-placeholder="$t('pc_comp_datepicker_endDate')"
                  >
                  </yxt-date-picker>
                </yxt-form-item>
              </yxt-col>
              <yxt-col :span="12">
                <!-- 调查来源 -->
                <yxt-form-item
                  label-width="124px"
                  :label="$t('pc_biz_survey_lbl_surveyorigin')"
                >
                  <yxt-select
                    v-model="surveySearch.froms"
                    clearable
                    multiple
                    size="large"
                    :placeholder="$t('pc_select_placeholder')"
                    @change="changeSource"
                  >
                    <yxt-option
                      v-for="item in sourceList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></yxt-option>
                  </yxt-select>
                </yxt-form-item>
              </yxt-col>
            </yxt-row>
            <yxt-row :gutter="20" class="mt16" v-if="!groupSelect">
              <yxt-col :span="12">
                <!-- 调查分类 -->
                <yxt-form-item
                  label-width="124px"
                  :label="$t('pc_biz_survey_lbl_surveycatalog')"
                >
                  <yxt-select
                    v-model="surveySearch.cateIdentifyIds"
                    clearable
                    size="large"
                    multiple
                    :placeholder="$t('pc_select_placeholder')"
                    @change="changeSource"
                  >
                    <yxt-option
                      v-for="item in categoryList"
                      :key="item.cateIdentifyId"
                      :label="item.name"
                      :value="item.cateIdentifyId"
                    ></yxt-option>
                  </yxt-select>
                </yxt-form-item>
              </yxt-col>
              <yxt-col :span="12"> </yxt-col>
            </yxt-row>
          </yxt-form>
        </yxt-popover>
        <yxt-input
          :placeholder="$t('pc_biz_survey_tip_surveynamesearch')"
          v-model="surveySearch.keyword"
          searchable
          @search="surveyNameSearch"
          :class="`yxtbiz-survey-selector__input${$isChinese ? '' : '-en'}`"
          maxlength="100"
        >
        </yxt-input>
      </div>
      <div class="yxtbiz-survey-selector__main mt16">
        <yxt-table
          :ref="refTable"
          class="yxtbiz-survey-selector__table"
          v-loading="loading"
          :data="surveysList"
          :height="tableHeight"
          @select-all="selectAll"
          @select="select"
          :default-sort="{ prop: 'updateTime', order: 'desc' }"
          @sort-change="sortChange"
        >
          <yxt-table-column
            type="selection"
            :show-overflow-tooltip="false"
            width="30"
            align="right"
            clear-padding="left-right"
          ></yxt-table-column>
          <yxt-table-column
            :show-overflow-tooltip="false"
            :label="$t('pc_biz_survey_lbl_surveyname')"
          >
            <template slot-scope="scope">
              <div class="yxtbiz-survey-selector__name">
                <yxt-tooltip
                  placement="top"
                  open-filter
                  :content="scope.row.name"
                  effect="dark"
                >
                  <div class="ellipsis">{{ scope.row.name }}</div>
                </yxt-tooltip>
              </div>
            </template>
          </yxt-table-column>
          <yxt-table-column
            :label="$t('pc_biz_survey_lbl_classification')"
            show-overflow-tooltip
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.cateName || '--' }}
            </template>
          </yxt-table-column>
          <yxt-table-column
            :label="$t('pc_biz_survey_lbl_state')"
            prop="status"
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              <span
                :class="[
                  'yxtbiz-survey-selector__status',
                  `yxtbiz-survey-selector__status--${scope.row.status}`
                ]"
              ></span>
              <span>
                {{ getStatusName(scope.row.status) }}
              </span>
            </template>
          </yxt-table-column>
          <yxt-table-column
            :label="$t('pc_biz_survey_lbl_publishperson')"
            show-overflow-tooltip
            popover-html
            prop="publishFullname"
            align="left"
            width="100"
          >
            <template slot-scope="scope">
              <yxtbiz-user-name :name="scope.row.publishFullname || '--'" />
            </template>
          </yxt-table-column>
          <yxt-table-column
            :label="$t('pc_biz_survey_lbl_publishdate')"
            prop="publishTime"
            sortable="custom"
            :formatter="shortDate"
            align="left"
            width="130"
          ></yxt-table-column>
        </yxt-table>
      </div>
      <div class="mt16 clearfix yxtbiz-survey-selector__pager">
        <yxt-button
          :loading="loadingAll"
          :disabled="!count"
          type="text"
          size="large"
          @click="selectAllNopage"
        >
          {{ $t('pc_biz_survey_btn_allselect') }}
        </yxt-button>
        <div class="flex-1">
          <yxt-pagination
            class="pull-right"
            :page-size="surveySearch.limit"
            :total="count"
            :current-page="page"
            @size-change="sizeChange"
            @current-change="pageChange"
            layout="total, prev, pager, next"
            :page-count="totalPage"
          ></yxt-pagination>
        </div>
      </div>
    </div>
    <div class="yxtbiz-survey-selector__right">
      <div v-if="max" class="mb12">
        {{ $t('pc_biz_survey_lbl_chosenquestions') }}：{{
          selectedList.length
        }}/{{ max }}
      </div>
      <yxt-scrollbar :fit-height="true">
        <yxt-tag
          @close="deleteItem(item)"
          class="mr5 mb5"
          size="small"
          type="info"
          closable
          :key="item.id"
          v-for="item in selectedList"
        >
          <yxt-tooltip :content="item.name" :openFilter="true">
            <span class="yxtbiz-user-selector-tag">{{ item.name }}</span>
          </yxt-tooltip>
        </yxt-tag>
      </yxt-scrollbar>
      <yxt-button
        type="text"
        class="yxtbiz-user-clear font-size-12 color-gray-9 hover-primary-6"
        @click="clear"
        :disabled="Object.values(this.selected).length === 0"
        >{{ $t('pc_biz_survey_btn_clearall') }}</yxt-button
      >
    </div>
  </div>
</template>

<script>
const SurveyStatusLan = [
  '',
  '',
  'pc_biz_survey_lbl_going', // 进行中
  'pc_biz_survey_lbl_projectover', // 已结束
  '',
  'pc_biz_survey_lbl_archived' // 已归档
];

const SurveyOriginLan = [
  'pc_biz_survey_lbl_originsurvey', // 独立调查
  '',
  'pc_biz_survey_lbl_origino2o', // 培训项目
  '',
  'pc_biz_survey_lbl_origingwnl', // 人才发展
  'pc_biz_survey_lbl_originface' // 面授课程
];

import {
  getCategoryListData,
  getSurveyListData,
  getSurveyListDataNoPage
} from './service';
import resizeTable from '../mixins/resizeTable';
import dateUtil from 'yxt-biz-pc/src/utils/date';
import { Table, TableColumn, Pagination } from 'yxt-pc';
export default {
  name: 'YxtbizSurveySelector',
  components: {
    YxtPagination: Pagination,
    YxtTable: Table,
    YxtTableColumn: TableColumn
  },
  mixins: [resizeTable],
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    max: {
      type: Number,
      default: 0
    },
    functionCode: {
      type: String,
      default: ''
    },
    orgFunctionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    orgDataPermissionCode: {
      type: String,
      default: ''
    },
    // 是否发布人权限范围
    publisherScope: {
      type: Boolean,
      default: false
    },
    // 是否集团化，支持跨机构选择
    groupSelect: {
      type: Boolean,
      default: false
    },
    // 每页限制条数
    limit: {
      type: Number,
      default: 50
    }
  },
  data() {
    return {
      isShowSurveyCode: false, // 是否显示考试编码
      refTable: 'stb',
      showMoreFilter: false,
      loadingAll: false,
      tableHeight: 0,
      page: 1,
      publishDate: [],
      startDate: [],
      surveysList: [],
      categoryList: [],
      statusList: SurveyStatusLan.map((v, i) => ({
        value: i,
        label: this.$t(v)
      })).filter(t => t.label),
      sourceList: SurveyOriginLan.map((v, i) => ({
        value: i,
        label: this.$t(v)
      })).filter(t => t.label),
      surveySearch: {
        keyword: '',
        orgId: '',
        limit: 50,
        offset: 0,
        current: 0,
        orderby: 'createTime',
        direction: 'desc',
        statusList: [],
        froms: [],
        cateIdentifyIds: [],
        navCode: this.functionCode || this.orgFunctionCode,
        dataPermissionCode:
          this.dataPermissionCode || this.orgDataPermissionCode
      },
      loading: false,
      totalPage: 0,
      count: 0,
      selectedList: [],
      selected: {}
    };
  },
  created() {
    !this.groupSelect && this.initCategoryListData();
    this.formatModel(this.value);
    !this.groupSelect && this.getSurveyList();
  },
  methods: {
    // 分类列表查询
    initCategoryListData() {
      getCategoryListData().then(data => {
        this.categoryList = data.data;
      });
    },
    changeOrg(org) {
      this.surveySearch.queryOrgId = org ? org.orgId : undefined;
      this.getSurveyList(true);
    },
    saveFilter() {
      this.getSurveyList(true);
      this.showMoreFilter = false;
    },
    changeDate() {
      this.surveySearch.publishTimeBegin = !this.publishDate
        ? ''
        : this.shortDateForApi(this.publishDate[0]);
      this.surveySearch.publishTimeEnd = !this.publishDate
        ? ''
        : this.shortDateForApi(this.publishDate[1], true);
      this.surveySearch.startTimeBegin = !this.startDate
        ? ''
        : this.shortDateForApi(this.startDate[0]);
      this.surveySearch.startTimeEnd = !this.startDate
        ? ''
        : this.shortDateForApi(this.startDate[1], true);
      this.getSurveyList(true);
    },
    changeSource() {
      this.getSurveyList(true);
    },
    // 考试安排状态
    getStatusName(status) {
      let sName = this.$t(SurveyStatusLan[status]);
      return sName || '--';
    },
    shortDate(row, col, val, index) {
      if (!val) return '--';
      if (typeof val === 'string') {
        val = val.replace(/-/g, '/');
      }
      return dateUtil.format(new Date(val), 'yyyy-MM-dd');
    },
    shortDateForApi(val, isEnd) {
      if (!val) return '';
      if (typeof val === 'string') {
        val = val.replace(/-/g, '/');
      }
      return new Date(val).getTime() + (isEnd ? 24 * 1000 * 60 * 60 : 0); //  dateUtil.format(new Date(val), 'yyyy-MM-dd');
    },
    getSurveyList(isReset) {
      if (isReset) {
        this.page = 1;
        this.surveySearch.offset = 0;
      }
      this.surveySearch.current = this.page;
      this.surveySearch.limit = this.limit;
      this.surveySearch.keyword = this.surveySearch.keyword.trim();
      this.surveySearch.groupFuncReq = this.groupSelect ? 1 : 0;
      this.surveySearch.permFlag = this.publisherScope ? 1 : 0;
      this.loading = true;
      getSurveyListData(this.surveySearch).then(result => {
        this.surveysList = result.datas;
        this.totalPage = result.paging.pages;
        this.count = result.paging.count;
        this.$nextTick(() => {
          this.setChecked();
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    surveyNameSearch() {
      this.getSurveyList(true);
    },
    sortChange(sort) {
      this.surveySearch.direction = sort.order;
      this.surveySearch.orderby = sort.prop;
      this.getSurveyList();
      return false;
    },
    sizeChange(size) {
      this.surveySearch.limit = size;
      this.getSurveyList(true);
    },
    pageChange(page) {
      this.page = page;
      this.surveySearch.offset = (page - 1) * this.surveySearch.limit;
      this.getSurveyList();
    },
    select(item, row) {
      let isAdd = item.some(v => {
        return v.id === row.id;
      });
      if (!isAdd) {
        delete this.selected[row.id];
      } else {
        if (!this.max || this.max > Object.values(this.selected).length) {
          this.selected[row.id] = row;
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('pc_biz_survey_msg_maxselectnum', [this.max])
          });
          this.$refs[this.refTable].toggleRowSelection(row, false);
        }
      }
      this.outPut();
    },
    selectAll(items) {
      let isAdd = items.length !== 0;
      let overNum = false;
      this.surveysList.forEach(obj => {
        if (!isAdd) {
          delete this.selected[obj.id];
        } else {
          if (!this.max || this.max > Object.values(this.selected).length) {
            this.selected[obj.id] = obj;
          } else {
            overNum = true;
            !this.selected[obj.id] &&
              this.$refs[this.refTable].toggleRowSelection(obj, false);
          }
        }
      });
      overNum &&
        this.$message({
          type: 'warning',
          message: this.$t('pc_biz_survey_msg_maxselectnum', [this.max])
        });
      this.outPut();
    },
    selectAllNopage() {
      this.loadingAll = true;
      getSurveyListDataNoPage({
        groupFuncReq: this.groupSelect ? 1 : 0,
        navCode: this.surveySearch.navCode,
        dataPermissionCode: this.surveySearch.dataPermissionCode,
        orderby: this.surveySearch.orderby,
        direction: this.surveySearch.direction,
        keyword: this.surveySearch.keyword.trim(),
        publishTimeBegin: this.surveySearch.publishTimeBegin,
        publishTimeEnd: this.surveySearch.publishTimeEnd,
        startTimeBegin: this.surveySearch.startTimeBegin,
        startTimeEnd: this.surveySearch.startTimeEnd,
        statusList: this.surveySearch.statusList,
        froms: this.surveySearch.froms,
        cateIdentifyIds: this.surveySearch.cateIdentifyIds,
        permFlag: this.publisherScope ? 1 : 0
      })
        .then(result => {
          let overNum = false;
          result.forEach(obj => {
            if (!this.max || this.max > Object.values(this.selected).length) {
              this.selected[obj.id] = obj;
              const tableRow = this.surveysList.find(r => r.id === obj.id);
              tableRow &&
                this.$refs[this.refTable].toggleRowSelection(tableRow, true);
            } else {
              overNum = true;
            }
          });

          overNum &&
            this.$message({
              type: 'warning',
              message: this.$t('pc_biz_survey_msg_maxselectnum', [this.max])
            });
          this.outPut();
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    deleteItem(item) {
      let row = item;
      for (let index = 0; index < this.surveysList.length; index++) {
        const element = this.surveysList[index];
        if (element.id === item.id) {
          row = element;
          break;
        }
      }
      if (row) {
        this.$refs['stb'].toggleRowSelection(row, false);
      }
      delete this.selected[item.id];
      this.$forceUpdate();
      this.outPut();
    },
    clear() {
      let row = null;
      for (const key in this.selected) {
        if (this.selected.hasOwnProperty(key)) {
          for (let index = 0; index < this.surveysList.length; index++) {
            const element = this.surveysList[index];
            if (element.id === key) {
              row = element;
              break;
            }
          }
          if (row) {
            this.$refs['stb'].toggleRowSelection(row, false);
          }
        }
      }
      this.selected = {};
      this.outPut();
    },
    outPut() {
      this.selectedList = Object.values(this.selected);
      this.$emit('input', this.selectedList);
      this.$emit('select', this.selectedList);
    },
    setChecked() {
      this.surveysList.forEach(obj => {
        if (this.selected.hasOwnProperty(obj.id)) {
          this.$refs['stb'].toggleRowSelection(obj, true);
        } else {
          this.$refs['stb'].toggleRowSelection(obj, false);
        }
      });
    },
    formatModel(v) {
      this.selected = {};
      v.forEach(obj => {
        this.selected[obj.id] = obj;
      });
      this.selectedList = Object.values(this.selected);
    }
  },
  watch: {
    value(v) {
      this.formatModel(v);
    }
  }
};
</script>
