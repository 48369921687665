import { o2oApi, o2orptApi } from 'yxt-biz-pc/packages/api';

// 获取培训分类列表树
export const getSceneLevel = (params) => {
  return o2oApi.get('scene/level/list', params);
};

// 项目列表（报表专用）
export const getProjectsReport = (params) => {
  return o2orptApi.get('external/projects/report/module', params);
};

// 项目列表（集团报表专用）
export const getProjectsBloc = (params) => {
  return o2oApi.get('external/projects/bloc/report', params);
};

// 项目列表（奇点专用）
export const getProjects4Sp = (data, config) => {
  return o2oApi.post('project/sp/choose', data, config);
};
