var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-media-container" }, [
    _c(
      "div",
      {
        staticClass: "yxtbiz-media-container__player",
        class: {
          "yxtbiz-media-container__player--transcoding": _vm.tranStatus !== 2,
        },
      },
      [
        !_vm.needReload && _vm.tranStatus === 2
          ? _c("yxtbiz-video", {
              ref: "player",
              attrs: {
                type: _vm.playerType,
                options: _vm.options,
                starttime: _vm.starttime,
                autoStart: _vm.autoStart,
                width: _vm.width,
                height: _vm.height,
                playRate: _vm.playRate,
                isShowWatermark: _vm.isShowWatermark,
                isNeedToken: _vm.isNeedToken,
                watermarkObj: _vm.watermark,
                isOpenMutiple: "",
                ticket: _vm.ticket,
              },
              on: {
                onBeforePlay: _vm.onBeforePlay,
                onReady: _vm.onReady,
                onPlay: _vm.onPlay,
                onPause: _vm.onPause,
                onComplete: _vm.onComplete,
                onError: _vm.onError,
                onFullscreen: _vm.onFullscreen,
                onSeek: _vm.onSeek,
                onTime: _vm.onTime,
                onQualityChange: _vm.onQualityChange,
                onPlaybackRate: _vm.onPlaybackRate,
              },
            })
          : _c("div", {
              staticClass: "bg-black",
              style: {
                width: _vm.width + "px",
                height: _vm.height + "px",
              },
            }),
        _vm.tranStatus !== 2
          ? _c(
              "div",
              { staticClass: "transcoding-tips" },
              [
                _vm.tranStatus === 3
                  ? _c("yxt-svg", {
                      attrs: {
                        width: "14",
                        height: "14",
                        "icon-class": "message-error",
                      },
                    })
                  : _c("yxt-svg", {
                      attrs: {
                        width: "14",
                        height: "14",
                        "icon-class": "message-error",
                      },
                    }),
                _c("span", { staticClass: "ml4" }, [
                  _vm._v(
                    _vm._s(
                      _vm.tranStatus === 3
                        ? (_vm.playerType === "audio"
                            ? _vm.$t("pc_biz_ote_lbl_audio")
                            : _vm.$t("pc_biz_ote_lbl_video")) +
                            _vm.$t("pc_biz_ote_msg_tanslate_error")
                        : _vm.$t("pc_biz_ote_lbl_objtranscoding", [
                            _vm.playerType === "audio"
                              ? _vm.$t("pc_biz_ote_lbl_audio")
                              : _vm.$t("pc_biz_ote_lbl_video"),
                          ])
                    )
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.$slots.action
          ? _c(
              "span",
              { staticClass: "player-action-button" },
              [_vm._t("action")],
              2
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }