var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "trainCatalogSelector" } },
    [
      _c(
        "yxt-popover",
        {
          attrs: {
            placement: "bottom-start",
            "popper-class": "yxtbiz-pop-cata-selector",
            width: _vm.width,
            trigger: "click",
            disabled: _vm.disabled,
            "visible-arrow": false,
          },
          on: {
            show: function ($event) {
              _vm.iconChange = false
            },
            hide: function ($event) {
              _vm.iconChange = true
            },
          },
          model: {
            value: _vm.selecting,
            callback: function ($$v) {
              _vm.selecting = $$v
            },
            expression: "selecting",
          },
        },
        [
          _c(
            "div",
            [
              _c("yxt-input", {
                staticClass: "mb16",
                attrs: {
                  placeholder:
                    _vm.searchToolTip || _vm.$t("pc_biz_ote_tip_catalogsearch"),
                  disabled: _vm.disabled,
                  size: _vm.size,
                  searchable: "",
                },
                model: {
                  value: _vm.filterText,
                  callback: function ($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText",
                },
              }),
              _c("div", { staticClass: "height300" }, [
                _c(
                  "div",
                  { staticClass: "yxtbiz-pop-cata-selector__tree-scroll" },
                  [
                    _c("yxt-tree", {
                      ref: "tree",
                      staticClass: "filter-tree",
                      attrs: {
                        refs: "tree",
                        fitHeight: true,
                        "wrap-width": _vm.width,
                        data: _vm.data,
                        props: _vm.defaultProps,
                        "filter-node-method": _vm.filterNode,
                        "node-key": "id",
                        "current-node-key": _vm.catalogIdselected,
                        "default-expanded-keys": [_vm.catalogIdselected],
                      },
                      on: { "node-click": _vm.selectCatalog },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "text-left font-size-12 hand",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c("yxt-input", {
                staticClass: "hand ote-input-hand",
                attrs: {
                  disabled: _vm.disabled,
                  placeholder: _vm.$t(_vm.dropToolTip),
                  "suffix-icon":
                    _vm.iconChange == true
                      ? "yxt-icon-arrow-down"
                      : "yxt-icon-arrow-up",
                  value: _vm.catalogNameSelected || _vm.catalogName,
                  readonly: "",
                  size: _vm.size,
                  type: "text",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }