import { fileApi, miscApi, http } from 'yxt-biz-pc/packages/api';
import axios from 'axios';

function getOrgId() {
  return window.localStorage && window.localStorage.getItem('orgId');
}

export const setFileApiSource = (source) => {
  fileApi.defaults.headers.source = source;
};
export const setFileApiTicket = (ticket) => {
  fileApi.defaults.headers.ticket = ticket;
};

// 获取机构混部的基础信息，机构是否只开启了只允许上传内网课件
export const getMixingInfo = () => {
  return fileApi.get(`/local/baseConfigByIdNew?orgid=${getOrgId()}`);
};

// 获取机构的服务器组
export const getGroupList = () => {
  return fileApi.get(`local/serviceGroupUploadConfigByOrgid?orgid=${getOrgId()}`);
};

// 根据服务器组的id,获取上传域名
export const getSecretEndpointById = (groupName) => {
  return fileApi.get(`local/uploadConfig?orgid=${getOrgId()}&groupName=${groupName}`);
};

// 获取服务器组的主服务器
export const getMainServerList = (groupId) => {
  return fileApi.get(`local/serviceConfigById?orgid=${getOrgId()}&groupId=${groupId}`);
};

// 获取私有化读取域名接口
export const getPrivateUploadConfig = (groupId) => {
  return fileApi.get(`local/privateUploadConfig?orgid=${getOrgId()}`);
};

// 标记转码失败
export const putKngTransError = (data, appCode) => {
  return http[appCode] && http[appCode].put('upload/kng/status', Array.isArray(data) ? data : [data]);
};

// 发起本地转码
export async function postLocalTranscode(endpoint, files, appCode) {
  function pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }
  const callbackUrl = http[appCode] ? http[appCode].defaults.baseURL + 'file/trans/callback' : '';
  if (!callbackUrl) return;
  files.forEach(f => {
    f.callbackUrl = callbackUrl;
  });
  // 转换时间戳
  const date = new Date();
  let iSOString;
  if (date.toISOString) {
    iSOString = date.toISOString();
  } else {
    iSOString = date.getUTCFullYear() +
        '-' + pad(date.getUTCMonth() + 1) +
        '-' + pad(date.getUTCDate()) +
        'T' + pad(date.getUTCHours()) +
        ':' + pad(date.getUTCMinutes()) +
        ':' + pad(date.getUTCSeconds()) +
        '.' + (date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
        'Z';
  }

  // 先获取身份
  const result = await fileApi.get('token/upload4Front', {
    params: {
      platform: 'local',
      headers: { 'x-bce-date': iSOString.replace(/\.\d+Z$/, 'Z'), 'Content-Length': JSON.stringify(files).length }
    }
  });
  const transResult = await axios.post(endpoint + '/receive/tsc', files, { headers: { 'x-bce-date': result['x-bce-date'], Authorization: result.signature } });
  return transResult;
};

export const getUploadConfig = (configKey) => {
  return fileApi.get('/config/get/' + configKey);
};

export const getUploadConfigNew = (localGroupId) => {
  return localGroupId ? fileApi.get('/local/uploadConfig?groupName=' + localGroupId) : fileApi.get('/config/upload');
};

export const generatePath = (appCode, fileName, configKey, moduleName, functionName, isV1) => {
  let param = {
    appCode,
    filename: window.encodeURIComponent(fileName),
    modulename: moduleName,
    functionname: functionName
  };
  if (isV1) {
    param.orgcode = window.localStorage.getItem('orgCode') || '';
    param.Source = 501;
    param.Token = window.localStorage.getItem('token') || '';
    param.configKey = configKey;
  }
  return fileApi.get('/config/key', param);
};

export const saveFileInfo = (file, appCode, configKey, isConvert, isV1, convertKey, groupId, isPublic) => {
  const otherParams = {};
  if (isPublic) {
    otherParams.remark = file.publicKeyPath;
  }
  if (isV1) {
    return fileApi.post('/fileinfo/create', {
      appCode: appCode,
      configId: configKey,
      fileOriginName: window.encodeURIComponent(file.name),
      mimeType: file.type,
      fileType: file.fileType,
      fileExt: file.extension,
      fileSize: ~~(file.size / 1024),
      fileUrl: file.keyPath,
      isConvert: convertKey || (isConvert ? (file.fileType === 'image' ? 0 : 1) : 0),
      ...otherParams
    });
  }
  return fileApi.post('/fileInfo/create', {
    appCode: appCode,
    configCode: configKey,
    originName: window.encodeURIComponent(file.name),
    mimeType: file.type || file.fileType,
    type: file.fileType,
    ext: file.extension,
    size: ~~(file.size / 1024),
    url: file.keyPath,
    isConvert: convertKey || (isConvert ? (file.fileType === 'image' ? 0 : 1) : 0),
    md5: file.md5,
    platform: file.platform,
    bucketName: file.bucketName,
    masterServiceId: groupId,
    ...otherParams
  });
};

export const getTencentToken = (params) => {
  return fileApi.get('/token/upload4Front', { params });
};

// 获取上传时一些文件限制配置
export const getUploadLimit = (params) => {
  return fileApi.get('/config/uploadLimit', { params });
};

// 获取私有化机构服务器组id参数配置
export const getLocalGroupServiceId = async() => {
  const code = 'privateServiceGroupId';
  const orgId = window.localStorage.orgId;
  try {
    const res = await miscApi.get(`orgparsetting/code?code=${code}&orgId=${orgId}`);
    if (res && res.value && res.value !== '0') {
      return {
        local: true,
        localGroupId: res.value
      };
    } else {
      return {
        local: false,
        localGroupId: null
      };
    }
  } catch (error) {
    return {
      local: false,
      localGroupId: null
    };
  }
};
