import {
  qidaApi
} from 'yxt-biz-pc/packages/api';

/**
 *
 * @param { String } url  授权完成之后的目标页
 * @param { String } userID 用户id 默认localStorage中取
 * @param { String } orgId  机构id 默认localStorage中取
 * @param { String } clientType 设备类型 默认0（pc）
 * @returns { Promise }
 */

function toThirdAuth(url, userID, orgId, clientType) {
  return qidaApi.post('/auth/third/url', {
    userId: userID || window.localStorage.getItem('userId'),
    loginMode: 1,
    clientType: clientType || 0,
    orgId: orgId || window.localStorage.getItem('orgId'),
    returnUrl: url
  });
}

export default toThirdAuth;
