import { getUid } from './service';

export function timestampToTime(timestamp) {
  function addZero(num) {
    return num < 10 ? '0' + num : num;
  }
  var date = new Date(timestamp || Date.now());
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var minute = date.getMinutes();
  var second = date.getSeconds();
  return year + '-' + addZero(month) + '-' + addZero(day) + ' ' + addZero(hour) + ':' + addZero(minute) + ':' + addZero(second);
}

/**
 * 解析 markdown img
 * @param {String} text
 * @returns
 */
const parseMarkdownImg = text => text.replace(/!\[(.*?)\]\((.*?)\)/g, '<img alt="$1" src="$2">');

/**
 * 解析markdown文本
 * @param {String} text
 * @returns
 */

// 处理释放资源逻辑
export const CommonAskIdHandler = function() {
  let askId = '';

  const handleBeforeUpload = function() {
    if (!askId) return;

    navigator.sendBeacon(`${window.feConfig.common.apiBaseUrl}aiassist/throttle/vacate/${askId}`, new Blob([JSON.stringify({
      token: window.getLocalStorage('token')
    })], { type: 'application/json' }));
    askId = '';
  };

  this.destoryReleaseAsset = function() {
    handleBeforeUpload();
    this.destroyUpload();
  };

  this.init = () => {
    window.addEventListener('beforeunload', handleBeforeUpload);
  };

  this.destroyUpload = () => {
    window.removeEventListener('beforeunload', handleBeforeUpload);
  };

  this.getAskId = function() {
    if (!askId) {
      askId = getUid();
    }
    return askId;
  };

  this.setAskId = function(id) {
    askId = id || getUid();
  };

  this.init();
};

export const getParentNode = (el, name) => {
  if (!el || el.tagName.toLowerCase() === 'body') {
    return null;
  }
  if (!el.classList.contains(name)) {
    return getParentNode(el.parentNode, name);
  }
  return el;
};
export const parseMarkdown = text => {
  text = text || '';
  if (window.marked) {
    text = text.replace(/<br>/g, '\n');
    return window.marked.parse(text) || text;
  }
  return parseMarkdownImg(text) || text;
};
export const markdownParse = text => {
  text = text || '';
  if (window.marked) {
    text = text.replace(/<br>/g, '\n\n'); // marked使用双换行解析更友好， 配置breaks参数不太友好
    return window.marked.parse(text) || text;
  }
  return parseMarkdownImg(text) || text;
};
