import { oteApi } from 'yxt-biz-pc/packages/api';

// 获取考试安排列表
export const getExamArrangeList = (query) => {
  return oteApi.get('/arrange/list/select', { params: query });
};

// 获取考试安排列表, 不分页
export const getExamArrangeListNopage = (query) => {
  return oteApi.get('/arrange/list/select/all', { params: query });
};

// 获取考试编码配置
export const getExamCodeSetting = () => {
  return oteApi.get('/field/projectcode');
};
