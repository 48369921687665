import { decorateApi } from 'yxt-biz-pc/packages/api';

/**
 * 读取并设置主题色
 */
let setTheme = async() => {
  try {
    const orgId = window.localStorage.orgId || '';
    const domain = window.location.host;

    // 换icon 可异步
    decorateApi.get(`orgconfig/support?orgId=${orgId}&domainName=${domain}`).then((orgConfig) => {
      if (orgConfig && orgConfig.webIconUrl) {
        changeIcon(orgConfig.webIconUrl);
      }
    }, (e) => {
      console.log(e);
    });

    const colorInfo = await decorateApi.get(
      `/orgconfig/skins?domainName=${domain}&orgId=${orgId}`
    );
    // 换色
    if (colorInfo && colorInfo.skinValue) {
      let color = colorInfo.skinValue;
      const grayMode = colorInfo.grayToggle;
      // 装修皮肤设置预览使用
      const regRes = window.location.hash.match(/skinValue=(.*)/);
      const urlSkinValue = regRes ? regRes[1] : regRes;
      if (urlSkinValue) color = urlSkinValue;
      if (window.setLocalStorage) {
        window.setLocalStorage('theme', color);
        window.setLocalStorage('grayMode', grayMode);
      } else {
        window.localStorage.theme = color;
        window.localStorage.grayMode = grayMode;
      }
    }
  } catch (error) {}
};

/**
 * 更换网站icon
 * @param {String} url 网站icon的地址
 */
let changeIcon = (url) => {
  return new window.Promise((resolve, reject) => {
    let head = document.getElementsByTagName('head')[0];
    let link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = url;
    link.onload = () => resolve();
    link.onerror = () => reject();
    head.appendChild(link);
  });
};

export default setTheme;
