<template>
  <div class="yxtbiz-dept-tree-v2" v-loading="loading">
    <div class="yxtbiz-dept-tree-v2-search" :style="{ height: contentHeight + 'px' }">
      <yxt-row :type="selectorAlign ? '' : 'flex'">
        <yxtbiz-group-org-select
          v-if='enableGroupCorp && visibleOrgSelector'
          @change='handleOrgChange'
          :selectFirst='true'
          :setCurrentOrg="setCurrentOrg"
          :functionCode="functionCode"
          :dataPermissionCode="dataPermissionCode"
          :clearable="false"
          :targetOrgId="targetOrgId"
          :visibleOrgIds="visibleOrgIds"
          :disabled="disabled"
          size="small"
          class='mr12 mb12'
          :style='selectorAlign ? "width: 100%" : ""'
        />
        <yxt-input
          class="yxtbiz-dept-tree__input"
          :placeholder="placeholder"
          ref="input"
          size="small"
          v-if="isShowSearch"
          style="max-width: 320px;"
          v-model="filterText"
          @search="filter"
          searchable
        >
        </yxt-input>
      </yxt-row>
    </div>
    <div v-show="isSearchTree" class="yxtbiz-dept-tree-v2-content" :style="{ height: `calc(100% - ${contentHeight}px)` }">
      <BaseTree
        ref="searchTree"
        :data="searchData"
        :props="props"
        node-key="id"
        wrap-width="100%"
        :highlight-current="false"
        :check-strictly="checkStrictly"
        :show-checkbox="checkShowCheckbox"
        @node-click="nodeClick"
        :functions="checkFunctions"
        :expand-on-click-node="false"
        :openScroll="true"
        :dropdownToBody="dropdownToBody"
        :functionsRender="functionsRenderMid"
        :default-expanded-keys="searchTreeExpandedKeys"
        @check="nodeCheck"
        @check-change="checkChange"
        virtual
        @node-expand="nodeExpand"
        @node-collapse="nodeCollapse"
      >
        <template slot-scope="{ data, node }">
          <span class="yxtbiz-dept-tree-v2-radio" v-if="!multiple && data.hasAllDeptPermission">
            <yxt-radio v-model="deptTreeSingle" :label="data.id"></yxt-radio>
          </span>
          <yxtbiz-dept-name :name="node.label"></yxtbiz-dept-name>
          <span>{{ count && data.id ? ` (${data.userCount})` : '' }}</span>
        </template>
      </BaseTree>
    </div>
    <div v-show="!isSearchTree" class="yxtbiz-dept-tree-v2-content" :style="{ height: `calc(100% - ${contentHeight}px)` }">
      <BaseTree
        ref="tree"
        node-key="id"
        wrap-width="100%"
        :highlight-current="false"
        :check-strictly="checkStrictly"
        :data="treeData"
        :props="props"
        :show-checkbox="checkShowCheckbox"
        @node-click="nodeClick"
        :functions="checkFunctions"
        :expand-on-click-node="false"
        :openScroll="true"
        :dropdownToBody="dropdownToBody"
        :functionsRender="functionsRenderMid"
        :default-expanded-keys="expandedKeys"
        virtual
        @check="nodeCheck"
        @check-change="checkChange"
        @node-expand="nodeExpand"
        @node-collapse="nodeCollapse"
      >
        <template slot-scope="{ data, node }">
          <span class="yxtbiz-dept-tree-v2-radio" v-if="!multiple && data.hasAllDeptPermission">
            <yxt-radio v-model="deptTreeSingle" :label="data.id"></yxt-radio>
          </span>
          <yxtbiz-dept-name :name="node.label"></yxtbiz-dept-name>
          <span>{{ count && data.id ? ` (${data.userCount || 0})` : '' }}</span>
        </template>
      </BaseTree>
    </div>
  </div>
</template>
<script>
import DeptTreeMixin from './dept-tree-mixins';
import { getTree, getTreeChilds, treeSearch, transformDeptIds, getOrgDeptSelectLimit } from './service';
import BaseTree from './base-tree';
import { i18n } from '../../common-util/changeLangs';

export default {
  name: 'YxtbizDeptTreeV2',
  components: {
    BaseTree
  },
  mixins: [DeptTreeMixin],
  props: {
    placeholder: {
      type: String,
      default: function() {
        return i18n.t('biz_udp_deptmentname');
      }
    },
    selectorAlign: {
      type: Boolean,
      default: false
    },
    checkStrictly: {
      type: Boolean,
      default: true
    },
    defaultCheckedKeys: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchTreeExpandedKeys: [],
      loading: false,
      currentOrgId: '',
      currentOrgName: '',
      treeSelectLimit: 5000, // 部门选择上限，默认5000
      treeRootNode: null, // 整棵树的根节点
      deptRootNode: null, // 部门根节点
      isFirstLoad: true,
      lazy: true,
      filterText: '',
      searchedFilterText: '', // 已点击搜索的文字
      isSearchTree: false,
      deptTreeSingle: null,
      searchData: [],
      treeData: [],
      baseData: [],
      expandedKeys: [],
      props: {
        label: 'name',
        isLeaf: data => {
          return !data.h;
        },
        lazyChildren: 'h'
      },
      checkedSearchMap: new Map(), // 搜索时的已选中数据
      checkedMap: new Map(), // 已选部门的集合（该集合只包含已加载完成的数据）
      checkedAllCacheMap: new Map(), // 全选部门的缓存（由于全选操作是直接接口拉数据，会存在选中的数据不存在已加载数据中，故需要建立全选缓存）
      loadedDataCache: {} // 已加载数据缓存
    };
  },
  created() {
    this.currentOrgId = this.targetOrgId;
    this.initData();
    this.getSelectLimit();
  },
  computed: {
    contentHeight() {
      let height = 40;
      if (this.enableGroupCorp && this.visibleOrgSelector && this.isShowSearch) {
        height = 80;
      } else if (!this.isShowSearch && !this.enableGroupCorp) {
        height = 0;
      }
      return height;
    },
    currTreeRef() {
      if (this.isSearchTree) {
        return this.$refs.searchTree;
      } else {
        return this.$refs.tree;
      }
    }
  },
  methods: {
    async getSelectLimit() {
      const res = await getOrgDeptSelectLimit();
      if (res && res.value && !isNaN(Number(res.value))) {
        this.treeSelectLimit = Number(res.value);
      }
    },
    async initData() {
      const res = await this.getData();
      this.loadedDataCache['root'] = res;
      if (!res.length) return;
      this.treeData = res;
      this.deptRootNode = res[0];
      // this.expandedKeys = [res[0].id];
      if (!res[0].hasAllDeptPermission) res[0].nocheck = true; // 根节点没数据权限
      this.setCheckedsTreeStatusForLoaded(res);
      if (this.checkedMap.size) {
        // 已选区有数据，做一次更新
        this.setCustomCheckedKeys();
      }
      if (this.defaultExpandedRoot) {
        this.$nextTick(() => {
          const rootNode = this.currTreeRef.getNode(res[0].id);
          if (this.setRootDefault) {
            this.currTreeRef.setCurrentKey(res[0].id);
            this.nodeClick(this.deptRootNode, rootNode);
          }
          this.nodeExpand(res[0], rootNode);
        });
      }
      this.$nextTick(() => {
        this.$emit('inited');
      });
    },
    async refreshNode(node, data) {
      const res = await this.getData(data.id);
      if (this.isSearchTree) {
        const newRes = res.filter((item) => {
          if (item.name.includes(this.searchedFilterText)) {
            return true;
          }
          return false;
        });
        if (node.data.shortPath) {
          // 说明是最短路径，在这下的节点做全量刷新
          this.currTreeRef.updateKeyChildren(data.id, res.map(item => ({ ...item, shortPath: 1 })));
        } else {
          this.currTreeRef.updateKeyChildren(data.id, newRes);
        }
        const treeNode = this.$refs.tree.getNode(data.id); // 部门树节点
        if (treeNode) {
          this.$refs.tree.updateKeyChildren(data.id, res);
          treeNode.expand();
        }

      } else {
        this.currTreeRef.updateKeyChildren(data.id, res);
      }
      node.expand();
    },
    async nodeExpand(data, node) {
      if (node.childNodes && node.childNodes.length) {
        return;
      }
      const res = await this.getData(data.id);
      this.loadedDataCache[data.id] = res;
      this.setCheckedsTreeStatusForLoaded(res);
      const currNode = this.checkedMap.get(data.id);
      if (currNode && currNode.includeAll) {
        // 如果当前节点是后续新增状态，才会对展开的子节点做后续新增数据处理
        res.forEach(item => {
          item.includeAll = 1;
          item.includeAllChild = true;
          item.disabled = true;
          this.checkedMapSet(item.id, item);
        });
        this.setCustomCheckedKeys();
      }
      this.currTreeRef.updateKeyChildren(data.id, res);
      if (this.checkedMap.size) {
        // 已选区有数据，做一次更新
        this.setCustomCheckedKeys();
      }
      node.expand();
      this.$emit('node-expand', data, node);
    },
    nodeCollapse(data, node) {
      this.$emit('node-collapse', data, node);
    },
    checkChange(...argu) {
      this.$emit('checkChange', ...argu);
    },
    clearNode() {
      this.clear();
      this.$emit('clearNode');
    },
    handleOrgChange(data) {
      console.log(data, '------');
      this.currentOrgId = data.orgId;
      this.currentOrgName = data.orgName;
      this.filterText = '';
      this.searchedFilterText = '';
      this.refresh(true);
      this.$emit('org-change', data);
    },
    AddOrgIdForTree(treeNodes) {
      for (const item of treeNodes) {
        if (item.children && item.children.length) {
          this.AddOrgIdForTree(item.children);
        } else {
          item.orgId = this.currentOrgId;
          item.orgName = this.currentOrgName;
        }
      }
    },
    checkedMapSet(key, value) {
      if (this.enableGroupCorp) {
        value.orgId = this.currentOrgId;
        value.orgName = this.currentOrgName;
      }
      if (this.isSearchTree) {
        this.checkedSearchMap.set(key, value);
        if (!this.checkedMap.has(key)) {
          this.checkedMap.set(key, value);
        }
      } else {
        // 非搜索下直接覆盖掉原先的值
        const node = this.currTreeRef.getNode(key);
        if (node) {
          node.data.disabled = value.disabled;
        }
        this.checkedMap.set(key, value);
      }
    },
    getVersion() {
      // 当前树组件版本信息
      return 'v2';
    },
    nodeClick(data, node, nodeComponent) {
      // 点击节点事件
      this.deptTreeSingle = data.id;
      this.$emit('nodeClick', data, node, nodeComponent);
    },
    async filter(value) {
      if (value) {
        this.isSearchTree = true;
        await this.getSearchTreeData();
      } else {
        this.isSearchTree = false;
        this.checkedMap.forEach(item => {
          const node = this.currTreeRef.getNode(item.id);
          if (node) {
            const data = Object.assign(node.data, {
              disabled: item.disabled,
              includeAll: item.includeAll,
              includeAllChild: item.includeAllChild
            });
            node.data = data;
            this.checkedMap.set(item.id, data);
          }
          console.log(node, '---- filter ----');
        });
        this.setCustomCheckedKeys();
      }
    },
    async getSearchTreeData() {
      try {
        this.loading = true;
        this.searchData = [];
        const res = await treeSearch({
          targetOrgId: this.currentOrgId,
          keyword: this.filterText,
          navCode: this.functionCode,
          dataPermissionCode: this.dataPermissionCode
        });
        this.searchedFilterText = this.filterText;
        this.loading = false;
        const datas = res.datas;
        if (this.enableGroupCorp) {
          // tree数据加上orgId
          this.AddOrgIdForTree(datas);
        }
        const searchTreeExpandedKeys = [];
        if (datas && datas.length) {
          this.transformSearchData(datas, searchTreeExpandedKeys);
        }
        this.searchData = datas;
        const checkedData = this.getCheckedNodes();
        this.setSearchTreeChecked(checkedData, this.searchData);
        this.$refs.searchTree.cleardefaultExpandedKeys();
        this.$nextTick(() => {
          this.searchTreeExpandedKeys = searchTreeExpandedKeys;
        });
      } catch (error) {
        if (error && error.message) {
          this.$message.error(error.message);
        }
        this.loading = false;
        this.isSearchTree = false;
      }
    },
    setSearchTreeChecked(checkedData, treeData) {
      const map = {};
      this.setSearchTreeDataMap(treeData, map);
      const checkedNodes = [];
      checkedData.forEach((item => {
        if (map[item.id]) {
          Object.assign(map[item.id], {
            disabled: item.disabled,
            includeAll: item.includeAll,
            includeAllChild: item.includeAllChild
          });
          checkedNodes.push(map[item.id]);
          if (map[item.id].includeAll) {
            for (const key in map) {
              if (map[key].routingPath.includes(item.routingPath)) {
                if (map[key].id !== item.id) {
                  map[key].disabled = true;
                  map[key].includeAll = 1;
                  map[key].includeAllChild = 1;
                  checkedNodes.push(map[key]);
                }
              }
            }
          }
        }
      }));
      checkedNodes.forEach(item => {
        if (!this.checkedMap.has(item.id)) {
          console.log('-- 123123 -- 12312321 ---');
          this.checkedMap.set(item.id, item);
        }
        // this.checkedSearchMap.set(item.id, item);
      });
      this.$nextTick(() => {
        // 这里由于直接在 this.searchData = datas 后做了设置选中操作，如果不用nextTick会导致调用
        // this.currTreeRef.setCheckedNodes方法时，tree树还没有生成Node节点，导致设置失败
        if (!checkedNodes.length) {
          this.currTreeRef.setCheckedKeys([]);
        } else {
          console.log(checkedNodes, '--- setSearchTreeChecked ---');
          this.currTreeRef.setCheckedNodes([...checkedNodes]);
        }
      });
    },
    setSearchTreeDataMap(treeData, map) {
      treeData.forEach(item => {
        map[item.id] = item;
        if (item.children && item.children.length) {
          this.setSearchTreeDataMap(item.children, map);
        }
      });
    },
    transformSearchData(data, searchTreeExpandedKeys) {
      data.forEach((node) => {
        Object.assign(node, this.transformNode(node));
        if (node.h && node.children && !node.children.length) {
          // 为搜索最短路径，加入标识
          node.shortPath = 1;
        }
        if (node.children && node.children.length) {
          searchTreeExpandedKeys.push(node.id);
          this.transformSearchData(node.children, searchTreeExpandedKeys);
        }
      });
    },
    async getData(parentId = '') {
      this.loading = true;
      const res = await getTree({
        targetOrgId: this.currentOrgId,
        parentId: parentId,
        navCode: this.functionCode,
        dataPermissionCode: this.dataPermissionCode
      });
      this.loading = false;
      if (this.enableGroupCorp) {
        // tree数据加上orgId
        this.AddOrgIdForTree(res.datas);
      }
      return res.datas.map(item => this.transformNode(item)) || [];
    },
    setAllCheckedCache(data) {
      // 设置全选缓存（只有点击当前全部操作时才设置，原因是选全部操作是调接口返回，可能已加载的数据中不存在）
      data.forEach(node => {
        if (!this.checkedMap.has(node.id)) {
          // 已选中集合中不存在的才加入全选缓存中
          this.checkedAllCacheMap.set(node.id, node);
        }
      });
    },
    delAllCheckedCache(currNode, includeAll = false) {
      // 删除全选缓存区数据
      if (this.checkedAllCacheMap.size === 0) return;
      if (includeAll) {
        // 表示删除该节点下所有数据
        const routingPath = currNode.routingPath;
        this.checkedAllCacheMap.forEach((value, key) => {
          const valueRoutingPath = value.routingPath || '';
          let isIncludeNode = valueRoutingPath.includes(routingPath);
          if (this.enableGroupCorp) {
            isIncludeNode = isIncludeNode && currNode.orgId === value.orgId;
          }
          if (isIncludeNode) {
            this.checkedAllCacheMap.delete(key);
          }
        });
        return;
      }
      // 删除全选缓存中某个数据
      this.checkedAllCacheMap.delete(currNode.id);
    },
    setChecked(checked, data) {
      // 设置单个选中状态
      if (checked) {
        data.includeAll = 0;
        this.checkedMapSet(data.id, data);
      } else {
        this.checkedMap.delete(data.id);
        this.checkedAllCacheMap.delete(data.id);
      }
      this.currTreeRef.setChecked(data.id, checked);
      this.$emit('change', this.getCheckedNodes());
    },
    setSearchIncludeAllNodes(data, includeAll, del) {
      // 设置搜索状态下搜索树的状态
      console.log('---- setSearchIncludeAllNodes ----');
      if (!this.isSearchTree) return;
      const checkeds = this.currTreeRef.getCheckedNodes();
      const currNode = this.currTreeRef.getNode(data.id);
      const currIncludeAllChecked = this.getSearchIncludeAllData(currNode.childNodes, includeAll);
      console.log(currIncludeAllChecked, '-------');
      currIncludeAllChecked.forEach(item => {
        if (!this.checkedMap.has(item.id)) {
          this.checkedMapSet(item.id, item);
        }
      });
      if (includeAll) {
        this.$nextTick(() => {
          console.log('currIncludeAllChecked', currIncludeAllChecked, checkeds);
          this.currTreeRef.setCheckedNodes([data, ...checkeds, ...currIncludeAllChecked]);
        });
      } else {
        const newCheckeds = [];
        checkeds.forEach(item => {
          if (!item.routingPath.includes(data.routingPath)) {
            newCheckeds.push(item);
          }
        });
        this.currTreeRef.setCheckedNodes([newCheckeds]);
        console.log('currIncludeAllChecked --- 0', checkeds);
      }
    },
    // 获取选中的部门节点（不含虚拟部门节点）
    getCheckedDeptNodes() {
      return this.getCheckedNodes();
    },
    getSearchIncludeAllData(childNodes, includeAll) {
      const res = [];
      for (let i = 0; i < childNodes.length; i++) {
        childNodes[i].data.includeAll = includeAll;
        childNodes[i].data.includeAllChild = includeAll;
        childNodes[i].data.disabled = !!includeAll;
        res.push(childNodes[i].data);
        if (childNodes[i].childNodes && childNodes[i].childNodes.length) {
          const curRes = this.getSearchIncludeAllData(childNodes[i].childNodes, includeAll);
          if (curRes && curRes.length) {
            res.push(...curRes);
          }
        }
      }
      return res;
    },
    setIncludeAllNodes(data) {
      // 设置当前及后续新增操作
      this.setSearchIncludeAllNodes(data, 1);
      data.includeAll = 1;
      data.includeAllChild = 0;
      data.disabled = false;
      this.checkedMapSet(data.id, data);
      if (this.loadedDataCache[data.id]) {
        this.setCurrChildNodesForCache(data.id, data.includeAll);
      }
      const node = this.currTreeRef.getNode(data.id);
      this.$emit('includeChanged', node, data, 1);
    },
    setCurrChildNodesForCache(id, includeAll = 0) {
      // 根据已加载数据缓存和当前节点，设置在其之下的所有节点数据
      if (this.loadedDataCache[id]) {
        this.loadedDataCache[id].forEach(node => {
          const cnode = this.currTreeRef.getNode(node.id);
          if (cnode) cnode.data.disabled = !!includeAll;
          node.disabled = !!includeAll;
          node.includeAll = includeAll;
          node.includeAllChild = !!includeAll;
          this.checkedMapSet(node.id, node);
          if (this.loadedDataCache[node.id]) {
            this.setCurrChildNodesForCache(node.id, includeAll);
          }
        });
      }
    },
    deleteCurrChildNodes(currNode) {
      // 删除当前已选节点下所有已选的节点
      if (currNode) {
        this.setSearchIncludeAllNodes(currNode, 0, true);
        this.checkedMap.forEach(node => {
          let isIncludeNode = node.routingPath.includes(currNode.routingPath);
          if (this.enableGroupCorp) {
            isIncludeNode = isIncludeNode && currNode.orgId === node.orgId;
          }
          if (isIncludeNode) {
            // 如果节点属于该节点下，删除掉
            node.disabled = false;
            this.checkedMap.delete(node.id);
          }
        });
      }
    },
    resetTreeStatus() {
      // 重置树的状态
      this.checkedMap.forEach(node => {
        const cnode = this.currTreeRef.getNode(node.id);
        if (cnode) cnode.data.disabled = false;
        node.disabled = false;
        node.includeAll = false;
        node.includeAllChild = false;
      });
    },
    getCheckedsKey() {
      // 获取已经选择的 key
      let checkedkeys = [];
      if (this.enableGroupCorp) {
        // 集团版下每次只设置当前机构下的选中值
        this.checkedMap.forEach(item => {
          if (item.orgId === this.currentOrgId) {
            checkedkeys.push(item.id);
          }
        });
      } else {
        checkedkeys = [...Array.from(this.checkedMap.keys())];
      }
      if (this.isSearchTree) {
        checkedkeys = [...Array.from(this.checkedMap.keys()), ...Array.from(this.checkedAllCacheMap.keys())];
      }
      return checkedkeys;
    },
    setCustomCheckedKeys() {
      // 设置整棵树的选中状态
      this.currTreeRef.setCheckedKeys(this.getCheckedsKey());
      this.$emit('change', this.getCheckedNodes());
    },
    clear() {
      // 清空所有选择
      this.deptTreeSingle = null;
      this.resetTreeStatus();
      this.checkedMap.clear();
      this.checkedAllCacheMap.clear();
      this.currTreeRef.setCheckedKeys([]);
    },
    isSelectLimit() {
      if (this.getCheckedNodes().length >= this.treeSelectLimit) {
        this.$confirm(`${i18n.t('pc_biz_depttreev2_01').d('已超出最大选择上限，上限值为：')}${this.treeSelectLimit}，${i18n.t('pc_biz_depttreev2_02').d('请重新选择！')}`, i18n.t('pc_biz_depttreev2_03').d('超出上限'), {
          type: 'error',
          showCancelButton: false
        });
        return true;
      }
      return false;
    },
    residueSelectLimit() {
      // 剩余可选择数量
      return this.treeSelectLimit - this.getCheckedNodes().length;
    },
    nodeCheck(data, status) {
      // 节点checkbox选择
      console.log(data, '--12-3-123-12-3-12');
      if (!this.checkedMap.has(data.id)) {
        if (this.isSelectLimit()) {
          this.currTreeRef.setChecked(data.id, false);
          return;
        }
        if (this.childrenIncluded === 2) {
          // 只开启了当前全部，点击checkbox就直接按当前全部处理
          this.selectCurrAllNode(null, data);
          this.$emit('check', data, status);
          return;
        }
        // 开始后续新增操作
        this.setIncludeAllNodes(data);
        // 同样也是如果存在全选缓存数据，也需要根据当前节点做下清除
        this.delAllCheckedCache(data, true);
      } else {
        this.deleteCurrChildNodes(data);
        this.delAllCheckedCache(data, true);
        this.$emit('check', data, status);
      }
      console.log(this.checkedMap, this.checkedAllCacheMap, 'nodeCheck');
      this.setCustomCheckedKeys();
    },
    functionsRenderMid(node, data) {
      // 节点右侧操作区render函数，提供默认的一些操作
      const funsBtns = [...this.functionsRender(node, data)]; // 操作功能渲染函数

      const funsBtnMap = {
        funs1: {
          disabled: data.disabled,
          label: i18n.t('biz_udp_deptment_subsequent').d('当前及后续新增部门'),
          handler: (node, data) => {
            if (this.isSelectLimit()) return;
            this.delAllCheckedCache(data, true);
            this.setIncludeAllNodes(data);
            this.setCustomCheckedKeys();
          }
        },
        funs2: {
          label: i18n.t('biz_udp_all').d('当前全部'),
          disabled: data.disabled,
          handler: async(node, data) => {
            if (this.isSelectLimit()) return;
            this.selectCurrAllNode(node, data);
          }
        },
        funs3: {
          label: i18n.t('pc_biz_sibling_dept').d('当前同级部门'),
          disabled: data.disabled,
          handler: (node, data) => {
            if (this.isSelectLimit()) return;
            // 选择同级部门
            const { parent } = node;
            const pData = this.currTreeRef.getNode(parent.data.id);
            const childsData = this.loadedDataCache[parent.data.id];
            if (pData && childsData.length) {
              childsData.forEach(node => {
                this.checkedMapSet(node.id, node);
              });
            } else {
              // 说明是根节点
              if (data.nocheck) return; // 说明当前根节点没有选中权限，直接返回不做操作
              node.setChecked(true);
              this.checkedMapSet(data.id, data);
            }
            this.setCustomCheckedKeys();
          }
        },
        funs4: {
          disabled: data.disabled,
          label: node.checked ? i18n.t('biz_udp_cancel_dept').d('取消当前节点部门') : i18n.t('biz_udp_current_dept').d('当前节点部门'),
          handler: (node, data) => {
            if (!node.checked && this.isSelectLimit()) return;
            if (node.checked && data.includeAll) {
              // 取消时判断该节点是不是包含后续新增，包含就去除掉子节点所有选中效果
              this.setCurrChildNodesForCache(data.id);
              this.deleteCurrChildNodes(data);
              this.setCustomCheckedKeys();
            } else {
              this.setChecked(!node.checked, data);
            }
            this.$emit('check', data, node, null);
          }
        },
        funs5: {
          label: i18n.t('biz_udp_clear_selected').d('清空选择'),
          handler: () => {
            this.clear();
          }
        }
      };

      funsBtns.push(...this.getFunsRenderBtn(data, funsBtnMap));

      if (this.deptRootNode.id === data.id && !data.hasAllDeptPermission) {
        // 部门根节点且无操作权限
        funsBtns.push(funsBtnMap.funs5);
      }
      return funsBtns;
    },
    async selectCurrAllNode(node, data) {
      // 选择当前全部
      data.includeAll = 0;
      data.disabled = false;
      this.checkedMapSet(data.id, data);
      this.loading = true;
      const res = await getTreeChilds({
        targetOrgId: this.currentOrgId,
        parentId: data.id,
        navCode: this.functionCode,
        dataPermissionCode: this.dataPermissionCode
      });
      this.loading = false;
      let datas = res.datas;
      if (this.enableGroupCorp) {
        // tree数据加上orgId
        this.AddOrgIdForTree(datas);
      }
      this.setCurrChildNodesForCache(data.id, 0, 0); // 先把已加载数据中的选择状态处理成全选
      this.setAllCheckedCache(datas.map(item => this.transformNode(item))); // 设置全选缓存
      this.setCustomCheckedKeys();
    },
    transformNode(targetNode) {
      // 转换数据，因为新接口返回的数据为缩写过后的字段，暴露出去的时候需要还原
      if (!targetNode) return false;
      const data = {
        ...targetNode,
        id: targetNode.i,
        name: targetNode.n,
        includeAll: targetNode.includeAll,
        userCount: targetNode.c,
        routingPath: targetNode.r,
        hasAllDeptPermission: targetNode.a
      };
      return data;
    },
    setCheckedsTreeStatusForLoaded(loadData) {
      // 数据加载后设置已选树的状态
      if (!this.checkedAllCacheMap.size) return;
      loadData.forEach((node) => {
        const curNode = this.checkedAllCacheMap.get(node.id);
        if (curNode) {
          // 说明已选缓存区存在对应的数据，把它移动到已选区中去
          node.includeAll = curNode.includeAll;
          this.checkedMapSet(node.id, node);
          this.checkedAllCacheMap.delete(node.id);
        }
      });
    },
    setCheckedDeptIds(...argu) {
      return this.$refs.tree.setCheckedKeys(argu[0], argu[1]);
    },
    getCheckedDeptIds(...argu) {
      return this.$refs.tree.getCheckedKeys(...argu);
    },
    setDefaultCheckeds(data) {
      console.log(data, '----------- setDefaultCheckeds --------------');
      if (!this.multiple && data && data.length) {
        // 单选，直接设置
        this.deptTreeSingle = data[0].id;
        return;
      }
      // 设置默认选中值
      if (data) {
        const noinfodepts = []; // 信息不全的部门id，特指没有routingpath的，这些数据无法进行父子关系匹配
        data.forEach(item => {
          if (typeof item === 'string') {
            this.checkedAllCacheMap.set(item, { id: item });
            noinfodepts.push(item);
          } else {
            let node = item;
            if (Object.isSealed(item)) {
              node = { ...item };
            }
            if (!node.routingPath) {
              noinfodepts.push(item.id);
            }
            this.checkedAllCacheMap.set(item.id, node);
          }
        });
        console.log(noinfodepts, '----------- noinfodepts --------------');
        if (noinfodepts.length) {
          this.handleDefaultDeptsInfo(noinfodepts);
        }
      }
    },
    async handleDefaultDeptsInfo(noinfodepts) {
      // 补全信息不足的数据
      console.log('开始补全默认选中数据的信息');
      const res = await transformDeptIds({ ids: noinfodepts });
      if (res && res.datas) {
        res.datas.forEach(item => {
          if (this.checkedAllCacheMap.has(item.i)) {
            const node = this.checkedAllCacheMap.get(item.i);
            this.checkedAllCacheMap.set(item.i, Object.assign(node, { routingPath: item.r }));
          }
        });
      }
    },
    deleteSingleNodeStatus(node, currkey) {
      // 删除单个节点数据（除非是后续新增，否则不涉及子节点操作）
      if (!node) {
        // 说明这个节点不存在，是全选出来的数据，直接删除掉全选缓存节点
        this.checkedAllCacheMap.delete(currkey);
        if (this.isSearchTree) {
          this.checkedMap.delete(currkey);
        }
        this.currTreeRef.setChecked(currkey, false);
        if (this.enableGroupCorp) {
          // 集团版下有可能数据在 checkedMap 已选区，也要处理下
          const currNode = this.checkedMap.get(currkey);
          if (currNode) {
            this.deleteCurrChildNodes(currNode);
          }
        }
        return;
      }
      console.log(node, 'deleteSingleNodeStatus');
      const currNode = this.checkedMap.get(node.data.id);
      if (currNode.includeAll) {
        // 后续新增的
        this.nodeCheck(currNode);
      } else {
        if (this.checkedMap.has(currNode.id)) {
          this.checkedMap.delete(currNode.id);
        } else {
          this.checkedAllCacheMap.delete(currNode.id);
        }
        if (node) node.setChecked(false);
      }
    },
    getCheckedNodes() {
      // 获取已选中的数据
      const checkedList = [];
      this.checkedMap.forEach(value => {
        if (!value.includeAllChild) {
          checkedList.push({...value});
        }
      });
      this.checkedAllCacheMap.forEach((value, key) => {
        if (!this.checkedMap.has(key)) {
          checkedList.push({...value});
        }
      });
      return checkedList;
    },
    seeLoadData() {
      return this.loadedDataCache;
    },
    seeData() {
      return this.checkedMap;
    },
    refresh(onlyRefreshData) {
      // onlyRefreshData 是否只更新数据源，不涉及已选数据操作
      this.isFirstLoad = true;
      this.isSearchTree = false;
      this.treeData = [];
      this.loadedDataCache = {};
      if (!onlyRefreshData) {
        this.checkedMap.clear();
        this.checkedAllCacheMap.clear();
        this.currTreeRef.setCheckedKeys([]);
      }
      this.initData();
    }
  },
  mounted() {
    console.log('组件加载完成');
    this.$emit('loaded', this);
    if (this.defaultCheckedKeys && this.defaultCheckedKeys.length) {
      this.setDefaultCheckeds(this.defaultCheckedKeys);
    }
  }
};
</script>
<style lang="scss" scoped>
.yxtbiz-dept-tree-v2 {
  height: 100%;
  width: 100%x;
  .yxtbiz-dept-tree-v2-search {
    height: 40px;
  }
  .yxtbiz-dept-tree-v2-content {
    /* overflow: auto; */
  }
  &-radio {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    width: 20px;
  }
}
</style>
<style lang="scss">
.yxtbiz-dept-tree-v2 {
  .tree-scroll-wrap {
    height: 100% !important;
    overflow: auto !important;
  }
  .yxt-scrollbar {
    height: 100% !important;
  }
}
.yxtbiz-base-tree-node {
  display: inline-block;
}
</style>
