import { rpt2Api } from '../api';
import { apiEnv } from '../api/configDomain';

// 判断当前机构是否要走华为云
const checkOrgIsHw = async() => {
  try {
    const res = await rpt2Api.get('config/hw/orgs');
    const orgId = localStorage.getItem('orgId');
    let isHw = false;
    if (res && res.orgs && orgId) {
      isHw = res.orgs.split(',').includes(orgId) || (apiEnv === 'tcprod' && res.orgs.indexOf('all-hw-orgs') !== -1);
    }
    localStorage.setItem('hw_report', isHw);
  } catch (e) {}
};

export { checkOrgIsHw };
