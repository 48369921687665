import { auditCenterApi } from 'yxt-biz-pc/packages/api';
// 审核类型 机构级
export const getmplbaseinfo = tmplCode => {
  return auditCenterApi.get(`/audittmplinfo/getmplbaseinfo/${tmplCode}`);
};
// 是否有发起人自选 项目级
export const getprojmplbaseinfo = (tmplCode, projId) => {
  return auditCenterApi.get(
    `/audittmplinfo/getmplbaseinfo/${tmplCode}/${projId}`
  );
};
// 发起人自选组件接口 机构级
export const selectUser = tmplCode => {
  return auditCenterApi.get(`/selectuser/${tmplCode}`);
};
// 发起人自选组件接口 项目级
export const selectProjUser = (tmplCode, projId) => {
  return auditCenterApi.get(`/selectuser/${tmplCode}/${projId}`);
};
// 发起人自选组件接口
export const getNodeUserList = data => {
  return auditCenterApi.post('/user/list', data);
};

// 获取指定人进行中的审核详情id
export const getAuditDetailId = (data) => {
  return auditCenterApi.get(
    `/audit/auditingId?formId=${data.formId}&formType=${data.formType}&orgId=${data.orgId}&userId=${data.userId}`
  );
};
