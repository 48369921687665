var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { pl16: _vm.subQues } },
    [
      _c("span", {
        attrs: { "data-rich-text": "1" },
        domProps: { innerHTML: _vm._s(_vm.cleanStyle(_vm.quesBody.content)) },
      }),
      _vm.quesBody.fileId && _vm.quesBody.quesType > 1
        ? _c("media-player", {
            staticClass: "mt16",
            attrs: {
              type: _vm.quesBody.quesType,
              options: _vm.playerOptions,
              tranStatus: _vm.quesBody.tranStatus,
            },
          })
        : _vm._e(),
      _vm.quesBody.type === 5
        ? _c(
            "yxt-row",
            [
              _c(
                "yxt-row",
                { staticClass: "mv16 flex" },
                [
                  _c("tag-keyword", {
                    attrs: {
                      tag: "",
                      label: _vm.$t("pc_biz_ote_lbl_assesspoint" /* 考点 */),
                    },
                  }),
                  _c("tag-keyword", {
                    staticClass: "color-gray-9",
                    attrs: { label: _vm.pointNames },
                  }),
                ],
                1
              ),
              _c(
                "yxt-row",
                { staticClass: "mt16 flex" },
                [
                  _c("tag-keyword", {
                    attrs: {
                      tag: "",
                      label: _vm.$t("pc_biz_ote_lbl_queslevel" /* 难度 */),
                    },
                  }),
                  _c("tag-keyword", {
                    staticClass: "color-gray-9",
                    attrs: { label: _vm.getLevelType(_vm.quesBody.levelType) },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.quesBody.type === 5
        ? _c("yxt-divider")
        : _c(
            "yxt-row",
            { staticClass: "pl16 pt8" },
            [
              _vm.quesBody.type === 0
                ? _c(
                    "yxt-radio-group",
                    {
                      staticClass: "yxtbiz-question-preview__radio-group",
                      attrs: { direction: "row", disabled: true },
                    },
                    _vm._l(_vm.quesBody.questionItems, function (item, index) {
                      return _c(
                        "yxt-radio",
                        {
                          key: item.id,
                          staticClass: "yxtbiz-question-preview__radio",
                          attrs: { label: item.id },
                        },
                        [
                          _c("div", { staticClass: "flex" }, [
                            _c(
                              "span",
                              { staticClass: "yxtbiz-flex-shrink-0" },
                              [
                                _vm._v(
                                  _vm._s(_vm.convertASCIIForNum(index)) + "."
                                ),
                              ]
                            ),
                            _c("span", {
                              staticClass: "ml4",
                              attrs: { "data-rich-text": "1" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.cleanStyle(item.itemContent, true)
                                ),
                              },
                            }),
                          ]),
                          item.itemPlay && item.itemPlay.fileId
                            ? _c("media-player", {
                                staticClass: "mt16",
                                attrs: {
                                  type: item.itemPlay.type,
                                  options: _vm.playerMediaOptions(
                                    item.itemPlay
                                  ),
                                  tranStatus: item.itemPlay.tranStatus,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.quesBody.type === 1
                ? _c(
                    "yxt-checkbox-group",
                    {
                      staticClass: "yxtbiz-question-preview__checkbox-group",
                      attrs: { direction: "row", disabled: true },
                      model: {
                        value: _vm.correctAnswers,
                        callback: function ($$v) {
                          _vm.correctAnswers = $$v
                        },
                        expression: "correctAnswers",
                      },
                    },
                    _vm._l(_vm.quesBody.questionItems, function (item, index) {
                      return _c(
                        "yxt-checkbox",
                        {
                          key: item.id,
                          staticClass: "yxtbiz-question-preview__checkbox",
                          attrs: { label: item.id },
                        },
                        [
                          _c("div", { staticClass: "flex" }, [
                            _c(
                              "span",
                              { staticClass: "yxtbiz-flex-shrink-0" },
                              [
                                _vm._v(
                                  _vm._s(_vm.convertASCIIForNum(index)) + "."
                                ),
                              ]
                            ),
                            _c("span", {
                              staticClass: "ml4",
                              attrs: { "data-rich-text": "1" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.cleanStyle(item.itemContent, true)
                                ),
                              },
                            }),
                          ]),
                          item.itemPlay && item.itemPlay.fileId
                            ? _c("media-player", {
                                staticClass: "mt16",
                                attrs: {
                                  type: item.itemPlay.type,
                                  options: _vm.playerMediaOptions(
                                    item.itemPlay
                                  ),
                                  tranStatus: item.itemPlay.tranStatus,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.quesBody.type === 2
                ? _c(
                    "yxt-radio-group",
                    {
                      staticClass: "yxtbiz-question-preview__radio-group",
                      attrs: { direction: "row", disabled: true },
                    },
                    [
                      _c(
                        "yxt-radio",
                        {
                          staticClass: "yxtbiz-question-preview__radio",
                          attrs: { label: 1 },
                        },
                        [
                          _vm._v(
                            "\n        A." +
                              _vm._s(
                                _vm.quesBody.judgeCorrectOptionContent
                                  ? _vm.quesBody.judgeCorrectOptionContent
                                  : _vm.$t("pc_biz_ote_lbl_wright")
                              ) +
                              "\n      "
                          ),
                        ]
                      ),
                      _c(
                        "yxt-radio",
                        {
                          staticClass: "yxtbiz-question-preview__radio",
                          attrs: { label: 0 },
                        },
                        [
                          _vm._v(
                            "\n        B." +
                              _vm._s(
                                _vm.quesBody.judgeWrongOptionContent
                                  ? _vm.quesBody.judgeWrongOptionContent
                                  : _vm.$t("pc_biz_ote_lbl_wrong")
                              ) +
                              "\n      "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.quesBody.type === 4 &&
              _vm.quesBody.keywords &&
              _vm.quesBody.keywords.length
                ? _c(
                    "yxt-row",
                    { staticClass: "mt8 mb16 flex" },
                    [
                      _c("tag-keyword", {
                        attrs: {
                          tag: "",
                          label: _vm.$t("pc_biz_ote_keyword" /* 关键字 */),
                        },
                      }),
                      _c("tag-keyword", {
                        staticClass: "color-gray-9",
                        attrs: {
                          label: _vm.quesBody.keywords
                            .map((item) => item.name)
                            .join(";"),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "yxt-row",
                { staticClass: "mv16 flex" },
                [
                  _c("tag-keyword", {
                    attrs: {
                      tag: "",
                      label: _vm.$t("pc_biz_ote_lbl_itemanswer" /* 答案 */),
                    },
                  }),
                  _vm.quesBody.type === 0
                    ? _c("yxt-col", { staticClass: "w-auto color-gray-9" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.convertASCIIForNum(_vm.correctAnswer)) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm.quesBody.type === 1
                    ? _c(
                        "yxt-col",
                        { staticClass: "w-auto color-gray-9" },
                        _vm._l(_vm.correctAnswers, function (item) {
                          return _c("span", { key: item }, [
                            _vm._v(_vm._s(_vm.convertASCIIForNum(item)) + " "),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.quesBody.type === 2
                    ? _c("yxt-col", { staticClass: "w-auto color-gray-9" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.quesBody.judgeAnswer === 0 ? "B" : "A") +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm.quesBody.type === 3
                    ? _c(
                        "yxt-col",
                        { staticClass: "w-auto color-gray-9" },
                        [
                          _vm.quesBody.fillItems &&
                          _vm.quesBody.fillItems.length
                            ? _vm._l(
                                _vm.quesBody.fillItems,
                                function (item, index) {
                                  return _c("div", { key: item.id }, [
                                    _vm.quesBody.fillItems.length > 1
                                      ? _c("span", [
                                          _vm._v(_vm._s(index + 1) + "."),
                                        ])
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.mergeAnswerFun(item))),
                                    ]),
                                    index + 1 !== _vm.quesBody.fillItems.length
                                      ? _c("span", [_vm._v(";")])
                                      : _vm._e(),
                                  ])
                                }
                              )
                            : _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("pc_biz_ote_lbl_none" /* 暂无 */)
                                  )
                                ),
                              ]),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.quesBody.type === 4
                    ? _c("yxt-col", { staticClass: "w-auto color-gray-9" }, [
                        _vm.quesBody.answerContent ||
                        (_vm.quesBody.answerPlay &&
                          _vm.quesBody.answerPlay.fileId)
                          ? _c(
                              "div",
                              [
                                _vm.quesBody.answerContent
                                  ? _c("span", {
                                      attrs: { "data-rich-text": "1" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.cleanStyle(
                                            _vm.quesBody.answerContent,
                                            true
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm.quesBody.answerPlay &&
                                _vm.quesBody.answerPlay.fileId
                                  ? _c("media-player", {
                                      class: _vm.quesBody.answerContent
                                        ? "mt16"
                                        : "",
                                      attrs: {
                                        type: _vm.quesBody.answerPlay.type,
                                        options: _vm.playerMediaOptions(
                                          _vm.quesBody.answerPlay
                                        ),
                                        tranStatus:
                                          _vm.quesBody.answerPlay.tranStatus,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("pc_biz_ote_lbl_none" /* 暂无 */))
                              ),
                            ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              !_vm.subQues
                ? _c(
                    "yxt-row",
                    { staticClass: "mv16 flex" },
                    [
                      _c("tag-keyword", {
                        attrs: {
                          tag: "",
                          label: _vm.$t(
                            "pc_biz_ote_lbl_assesspoint" /* 考点 */
                          ),
                        },
                      }),
                      _c("tag-keyword", {
                        staticClass: "color-gray-9",
                        attrs: { label: _vm.pointNames },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.subQues
                ? _c(
                    "yxt-row",
                    { staticClass: "mv16 flex" },
                    [
                      _c("tag-keyword", {
                        attrs: {
                          tag: "",
                          label: _vm.$t("pc_biz_ote_lbl_queslevel" /* 难度 */),
                        },
                      }),
                      _c("tag-keyword", {
                        staticClass: "color-gray-9",
                        attrs: {
                          label: _vm.getLevelType(_vm.quesBody.levelType),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "yxt-row",
                { staticClass: "mt16 flex" },
                [
                  _c("tag-keyword", {
                    attrs: {
                      tag: "",
                      label: _vm.$t("pc_biz_ote_lbl_quesanalyze" /* 解析 */),
                    },
                  }),
                  _c("yxt-col", { staticClass: "w-auto color-gray-9" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showExplain,
                            expression: "showExplain",
                          },
                        ],
                        ref: "nowrapEle",
                        staticClass: "parsing-content",
                      },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "nowrap-3": _vm.isShowMoreText && !_vm.isShowMore,
                            },
                          },
                          [
                            _c("span", {
                              attrs: { "data-rich-text": "1" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.cleanStyle(_vm.quesBody.explainText)
                                ),
                              },
                            }),
                            _vm.quesBody.explainPlay &&
                            _vm.quesBody.explainPlay.fileId
                              ? _c("media-player", {
                                  class: _vm.cleanStyle(
                                    _vm.quesBody.explainText
                                  )
                                    ? "mt16"
                                    : "",
                                  attrs: {
                                    type: _vm.quesBody.explainPlay.type,
                                    options: _vm.playerMediaOptions(
                                      _vm.quesBody.explainPlay
                                    ),
                                    tranStatus:
                                      _vm.quesBody.explainPlay.tranStatus,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.isShowMoreText
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "text-center color-primary-6 font-size-14 hand mt4 lh22 mb0",
                                on: {
                                  click: function ($event) {
                                    _vm.isShowMore = !_vm.isShowMore
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.isShowMore
                                        ? _vm.$t("pc_biz_ote_btn_putaway")
                                        : _vm.$t("pc_biz_ote_btn_expand")
                                    )
                                  ),
                                ]),
                                _c("span", {
                                  staticClass: "ml3",
                                  class: _vm.isShowMore
                                    ? "yxt-icon-arrow-up"
                                    : "yxt-icon-arrow-down",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.showExplain,
                            expression: "!showExplain",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.$t("pc_biz_ote_lbl_noquesanalysis")))]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
      _vm._l(_vm.quesBody.subQuesList, function (item, index) {
        return _c("ques-view-info", {
          key: item.id,
          attrs: {
            quesId: item.quesId,
            quesBody: item,
            subQues: true,
            showDivider: index < _vm.quesBody.subQuesList.length - 1,
          },
        })
      }),
      _vm.showDivider ? _c("yxt-divider") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }