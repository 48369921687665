<template>
  <div class="yxtbiz-exam-arrange-selector">
    <div class="yxtbiz-exam-arrange-selector__left">
      <!-- 筛选 -->
      <div class="yxtbiz-exam-arrange-selector__filter clearfix font-size-14">
        <yxtbiz-group-org-select
          v-if="groupSelect"
          class="mr12 mb16"
          :clearable="false"
          :multiple="false"
          :functionCode="orgFunctionCode"
          :dataPermissionCode="orgDataPermissionCode"
          @change="changeOrg"
          setCurrentOrg
        ></yxtbiz-group-org-select>
        <yxt-popover
          :append-to-body="false"
          popper-class="yxtbiz-exam-arrange-selector__popover"
          placement="bottom-start"
          :visible-arrow="false"
          v-model="showMoreFilter"
        >
          <yxt-button slot="reference" plain class="yxtbiz-filter-btn mr12 mb16">
            <yxt-svg
              width="20px"
              height="20px"
              icon-class="filter"
              class="v-top"
            />
            <span class="ml8 v-top">{{
              $t('pc_biz_common_lbl_moreFilter' /** 更多筛选 */)
            }}</span>
            <i
              class="ml8"
              :class="
                showMoreFilter ? 'yxt-icon-arrow-up' : 'yxt-icon-arrow-down'
              "
            ></i>
          </yxt-button>
          <yxt-form label-position="top">
            <yxt-row :gutter="20">
              <yxt-col :span="12">
                <yxt-form-item
                  :label-width="archives && loopExam ? '124px': '100px'"
                  :label="$t('pc_biz_ote_lbl_publishdate')"
                >
                  <yxt-date-picker
                    @change="changeDate"
                    v-model="publishDate"
                    format="yyyy-MM-dd"
                    type="daterange"
                    :range-separator="$t('pc_biz_ote_lbl_to')"
                    :start-placeholder="$t('pc_biz_ote_lbl_starttime')"
                    :end-placeholder="$t('pc_biz_ote_lbl_endtime')"
                  >
                  </yxt-date-picker>
                </yxt-form-item>
              </yxt-col>
              <yxt-col :span="12">
                <yxt-form-item
                  label-width="124px"
                  :label="$t('pc_biz_ote_lbl_source')"
                >
                  <yxt-select
                    v-model="examSearch._sourceType"
                    clearable
                    size="large"
                    :placeholder="$t('pc_biz_ote_lbl_pleaseselect')"
                    @change="changeSource"
                  >
                    <yxt-option
                      v-for="item in sourceList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></yxt-option>
                  </yxt-select>
                </yxt-form-item>
              </yxt-col>
            </yxt-row>
            <yxt-row :gutter="20" class="mt16" v-if="byReportDB && (!loopExam || archives)">
              <yxt-col :span="12" v-if="!loopExam">
                <yxt-form-item
                  label-width="100px"
                  :label="$t('pc_biz_ote_lbl_arrangetype')"
                >
                  <yxt-select
                    v-model="examSearch.arrangeType"
                    clearable
                    size="large"
                    :placeholder="$t('pc_biz_ote_lbl_pleaseselect')"
                    @change="changeSource"
                  >
                    <yxt-option
                      v-for="item in typeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></yxt-option>
                  </yxt-select>
                </yxt-form-item>
              </yxt-col>
              <yxt-col :span="12" v-if="archives">
                <yxt-form-item
                  label-width="124px"
                  :label="$t('pc_biz_ote_lbl_containsarchive')"
                >
                  <yxt-select
                    v-model="examSearch.archiveFlag"
                    size="large"
                    :placeholder="$t('pc_biz_ote_lbl_pleaseselect')"
                    @change="changeSource"
                  >
                    <yxt-option
                      :label="$t('pc_biz_ote_lbl_yes')"
                      :value="1"
                    ></yxt-option>
                    <yxt-option
                      :label="$t('pc_biz_ote_lbl_no')"
                      :value="0"
                    ></yxt-option>
                  </yxt-select>
                </yxt-form-item>
              </yxt-col>
            </yxt-row>
          </yxt-form>
        </yxt-popover>
        <yxt-input
          class="mb16"
          :class="$isChinese ? 'w208' : 'w320'"
          :placeholder="
            $t(
              isShowExamCode
                ? 'pc_biz_ote_tip_examnamesearch2'
                : 'pc_biz_ote_tip_examnamesearch'
            )
          "
          v-model="examSearch.keyword"
          searchable
          @search="examNameSearch"
          maxlength="100"
        >
        </yxt-input>
      </div>
      <!-- 表格 -->
      <div class="yxtbiz-exam-arrange-selector__main">
        <yxt-table
          :ref="refTable"
          class="yxtbiz-exam-arrange-selector__table"
          :class="{
            'check-indeterminate': isIndeterminateHead
          }"
          v-loading="loading"
          :data="examsList"
          :height="tableHeight"
          @select-all="selectAll"
          @select="select"
          :row-class-name="getRowClass"
          :default-sort="{ prop: 'updateTime', order: 'desc' }"
          :tree-props="{children: '_children', hasChildren: 'hasChildren'}"
          @sort-change="sortChange"
        >
          <yxt-table-column
            type="expand"
            v-if="getIsSelectLoopChild()"
            width="46"
            clear-padding="right"
          >
            <template slot-scope="props">
              <table
                cellspacing="0"
                cellpadding="0"
                border="0"
                class="yxtbiz-exam-arrange-selector__table-row"
              >
                <tr
                  class="yxt-table__row"
                  :key="row.id"
                  v-for="row in props.row.children"
                >
                  <td
                    rowspan="1"
                    colspan="1"
                    data-row="0"
                    data-column="0"
                    class="yxt-table_1_column_1"
                  >
                    <div class="cell">
                      <yxt-checkbox
                        v-model="row.checked"
                        @change="
                          checked => {
                            selectChildren(row, checked);
                          }
                        "
                        >{{
                          $t('pc_biz_ote_lbl_bantchindex', [row.batchIndex])
                        }}</yxt-checkbox
                      >
                    </div>
                  </td>
                </tr>
              </table>
            </template>
          </yxt-table-column>
          <yxt-table-column
            type="selection"
            :show-overflow-tooltip="false"
            :width="getIsSelectLoopChild() ? 16 : 30"
            clear-padding="left-right"
            align="right"
            :selectable="checkRowSelectable"
          ></yxt-table-column>
          <yxt-table-column
            :show-overflow-tooltip="false"
            :label="$t('pc_biz_ote_lbl_examname')"
            min-width="130"
          >
            <template slot-scope="scope">
              <span class="yxtbiz-exam-arrange-selector__name">
                <yxt-tooltip
                  placement="top"
                  open-filter
                  :content="scope.row.name"
                  effect="dark"
                >
                  <div class="ellipsis">{{ scope.row.name }}</div>
                </yxt-tooltip>
                <span
                  class="ml8 yxtbiz-exam-arrange-selector__grouptag"
                  v-if="[1, 2].indexOf(scope.row.groupDataType) >= 0"
                  >{{ $t('pc_biz_ote_lbl_grouporg') }}</span
                >
              </span>
            </template>
          </yxt-table-column>
          <yxt-table-column
            v-if="isShowExamCode"
            :label="examCodeName"
            show-overflow-tooltip
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.projectCode || '--' }}
            </template>
          </yxt-table-column>
          <yxt-table-column
            :label="$t('pc_biz_ote_lbl_status')"
            prop="status"
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              <span
                :class="[
                  'yxtbiz-exam-arrange-selector__status',
                  `yxtbiz-exam-arrange-selector__status--${scope.row.status}`
                ]"
              ></span>
              <span>
                {{ getStatusName(scope.row.status) }}
              </span>
            </template>
          </yxt-table-column>
          <yxt-table-column v-if="!byReportDB"
            :label="$t('pc_biz_ote_lbl_arranger')"
            show-overflow-tooltip
            popover-html
            prop="createFullname"
            align="left"
            width="120"
          >
            <template slot-scope="scope">
              <yxtbiz-user-name :name="scope.row.createFullname" />
            </template>
          </yxt-table-column>
          <yxt-table-column v-else
            :label="$t('pc_biz_ote_lbl_publishperson')"
            show-overflow-tooltip
            popover-html
            prop="releaseUserName"
            align="left"
            width="80"
          >
            <template slot-scope="scope">
              <yxtbiz-user-name :name="scope.row.releaseUserName" />
            </template>
          </yxt-table-column>
          <yxt-table-column
            :label="$t('pc_biz_ote_lbl_publishdate')"
            prop="releasedTime"
            sortable="custom"
            :formatter="shortDate"
            align="left"
            :width="$isChinese ? '130' : '160'"
          ></yxt-table-column>
        </yxt-table>
      </div>
      <div class="mt16 clearfix yxtbiz-exam-arrange-selector__pager">
        <yxt-button
          :loading="loadingAll"
          :disabled="!count"
          type="text"
          @click="selectAllNopage"
        >
          {{ $t('pc_biz_ote_btn_allselect') }}
        </yxt-button>
        <div class="flex-1">
          <yxt-pagination
            class="pull-right"
            :page-size="examSearch.limit"
            :total="count"
            :current-page="page"
            @size-change="sizeChange"
            @current-change="pageChange"
            layout="total, prev, pager, next"
            :page-count="totalPage"
          ></yxt-pagination>
        </div>
      </div>
    </div>
    <!-- 已选择 -->
    <div class="yxtbiz-exam-arrange-selector__right">
      <div v-if="max" class="mb12">
        {{ $t('pc_biz_ote_lbl_chosenquestions') }}：{{ selectedList.length }}/{{
          max
        }}
      </div>
      <yxt-scrollbar :fit-height="true">
        <yxt-tag
          @close="deleteItem(item)"
          class="mr5 mb5"
          size="small"
          type="info"
          closable
          :key="item.id"
          v-for="item in selectedList"
        >
          <yxt-tooltip :content="item.name" :openFilter="true">
            <span class="d-block yxtbiz-user-selector-tag">{{ item.name }}</span>
          </yxt-tooltip>
        </yxt-tag>
      </yxt-scrollbar>
      <yxt-button
        type="text"
        class="yxtbiz-user-clear font-size-12 color-gray-9 hover-primary-6"
        @click="clear"
        :disabled="Object.values(this.selected).length === 0"
        >{{ $t('pc_biz_ote_btn_clearall') }}</yxt-button
      >
    </div>
  </div>
</template>

<script>
// 考试安排来源 0-单独安排 1-项目安排 2-课程安排 3-练习 4-人才发展 5-面授工具
export const ARRANGE_SOURCE = [
  'pc_biz_ote_lbl_arrangetypedefault',
  'pc_biz_ote_lbl_arrangetypeproject',
  'pc_biz_ote_lbl_arrangetypecourse',
  '',
  'pc_biz_ote_lbl_talentdevelopment',
  'pc_biz_ote_lbl_fliptool'
];
// 考试安排类型 0-线上单场考试 1-线上循环考试 2-线下考试
export const ARRANGE_TYPE = ['pc_biz_ote_lbl_online_exam', 'pc_biz_ote_lbl_online_loop_exam', 'pc_biz_ote_lbl_offlineExam'];
import {
  getExamArrangeList,
  getExamCodeSetting,
  getExamArrangeListNopage
} from './service';
import resizeTable from '../mixins/resizeTable';
import dateUtil from 'yxt-biz-pc/src/utils/date';
import { Table, TableColumn, Pagination } from 'yxt-pc';
export default {
  name: 'YxtbizExamArrangeSelector',
  components: {
    YxtPagination: Pagination,
    YxtTable: Table,
    YxtTableColumn: TableColumn
  },
  mixins: [resizeTable],
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    max: {
      type: Number,
      default: 0
    },
    functionCode: {
      type: String,
      default: ''
    },
    orgFunctionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    orgDataPermissionCode: {
      type: String,
      default: ''
    },
    // 是否集团化，支持跨机构选择
    groupSelect: {
      type: Boolean,
      default: false
    },
    // 每页限制条数
    limit: {
      type: Number,
      default: 50
    },
    // 是否发布人权限范围
    publisherScope: {
      type: Boolean,
      default: false
    },
    // 通过报表的库查询数据。支持考试类型、循环考试、档案数据的查询
    byReportDB: {
      type: Boolean,
      default: false
    },
    // 只查询循环考试的主考信息。（需要通过报表的库查询才支持）
    loopExam: {
      type: Boolean,
      default: false
    },
    // 是否可包含档案数据。（需要通过报表的库查询才支持）
    archives: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSelectLoopChild: true, // 循环的时候勾选子的
      isShowExamCode: false, // 是否显示考试编码
      examCodeName: this.$t('pc_biz_ote_lbl_examcode'),
      refTable: 'stb',
      showMoreFilter: false,
      loadingAll: false,
      tableHeight: 0,
      page: 1,
      publishDate: [],
      examsList: [],
      // 来源
      sourceList: [
        {
          value: 0,
          label: this.$t(ARRANGE_SOURCE[0]) // 单独安排
        },
        {
          value: 1,
          label: this.$t(ARRANGE_SOURCE[1]) // 项目安排
        },
        {
          value: 2,
          label: this.$t(ARRANGE_SOURCE[2]) // 课程安排
        },
        {
          value: 4,
          label: this.$t(ARRANGE_SOURCE[4]) // 人才发展
        },
        {
          value: 5,
          label: this.$t(ARRANGE_SOURCE[5]) // 面授工具
        }
      ],
      // 类型
      typeList: ARRANGE_TYPE.map((lan, i) => ({
        value: i,
        label: this.$t(lan)
      })),
      examSearch: {
        keyword: '',
        orgId: '',
        limit: 50,
        offset: 0,
        orderby: 'releasedTime',
        arrangeType: '',
        direction: 'DESC',
        archiveFlag: 0,
        navCode: this.functionCode || this.orgFunctionCode,
        dataPermissionCode:
          this.dataPermissionCode || this.orgDataPermissionCode
      },
      loading: false,
      totalPage: 0,
      count: 0,
      selectedList: [],
      selected: {}
    };
  },
  computed: {
    isIndeterminateHead() {
      return (
        this.isSelectLoopChild &&
        this.examsList.find(p => p.children && p.children.find(c => c.checked))
      );
    }
  },
  created() {
    this.formatModel(this.value);
    !this.groupSelect && this.getExamList();
    this.initExamCodeSetting();
  },
  methods: {
    getIsSelectLoopChild() {
      return this.isSelectLoopChild && this.examsList.find(p => p.children);
    },
    initExamCodeSetting() {
      getExamCodeSetting().then(result => {
        this.examCodeName = result;
        this.isShowExamCode = !!result;
      });
    },
    getExamList(isReset) {
      if (isReset) {
        this.page = 1;
        this.examSearch.offset = 0;
      }
      this.examSearch.limit = this.limit;
      this.examSearch.keyword = this.examSearch.keyword.trim();
      this.examSearch.groupFuncReq = this.groupSelect ? 1 : 0;
      this.examSearch.dimensionType = this.loopExam ? 1 : 0;
      this.examSearch.sourceType = this.examSearch._sourceType === '' ? -1 : this.examSearch._sourceType;
      this.examSearch.isReportDataSource = this.byReportDB ? 1 : 0;
      this.examSearch.permFlag = this.publisherScope ? 1 : 0;
      this.loading = true;
      getExamArrangeList(this.examSearch)
        .then(result => {
          this.examsList = result.datas;
          this.totalPage = result.paging.pages;
          this.count = result.paging.count;
          this.loading = false;
          this.$nextTick(() => {
            this.setChecked();
          });
        })
        .catch(e => {
          console.log(e);
        });
    },
    /**
     * 是否超出数量限制
     */
    isNotOverNum() {
      return !this.max || this.max > Object.values(this.selected).length;
    },
    checkRowSelectable(row, index) {
      return !(
        row.arrangeType === 1 &&
        this.isSelectLoopChild &&
        (!row.children || row.children.length === 0)
      );
    },
    /**
     * 处理行样式。表格无子项时隐藏展开收起箭头，表格行的半选样式处理
     */
    getRowClass({ row }) {
      if (row.children && row.children.length > 0) {
        if (row.children.find(c => c.checked)) return 'check-indeterminate';
      } else {
        return 'hide-expand';
      }
      return '';
    },
    // 考试安排状态
    getStatusName(status) {
      return this.$t('pc_biz_ote_lbl_status' + status);
    },
    shortDate(row, col, val, index) {
      if (!val) return '--';
      if (typeof val === 'string') {
        val = val.replace(/-/g, '/');
      }
      return dateUtil.format(new Date(val), 'yyyy-MM-dd');
    },
    changeOrg(org) {
      this.examSearch.queryOrgId = org ? org.orgId : undefined;
      this.getExamList(true);
    },
    changeDate() {
      this.examSearch.startTime = this.publishDate
        ? this.shortDate(null, null, this.publishDate[0])
        : '';
      this.examSearch.endTime = this.publishDate
        ? this.shortDate(null, null, this.publishDate[1])
        : '';
      this.getExamList(true);
    },
    changeSource() {
      this.getExamList(true);
    },
    examNameSearch() {
      this.getExamList(true);
    },
    sortChange(sort) {
      this.examSearch.direction = sort.order;
      this.examSearch.orderby = sort.prop;
      this.getExamList();
      return false;
    },
    sizeChange(size) {
      this.examSearch.limit = size;
      this.getExamList(true);
    },
    pageChange(page) {
      this.page = page;
      this.examSearch.offset = (page - 1) * this.examSearch.limit;
      this.getExamList();
    },
    /**
     * 获取数据后的 表格选中状态处理
     */
    setChecked() {
      this.examsList.forEach(obj => {
        if (this.selected.hasOwnProperty(obj.id)) {
          this.$refs['stb'].toggleRowSelection(obj, true);
        } else {
          this.$refs['stb'].toggleRowSelection(obj, false);
        }
        // 子的选中
        obj.children &&
          obj.children.forEach(chd => {
            chd.parentId = obj.id;
            chd.name = `${obj.name}-${this.$t('pc_biz_ote_lbl_bantchindex', [
              chd.batchIndex
            ])}`;
            if (this.selected.hasOwnProperty(chd.id)) {
              // this.$set(chd, 'checked', true);
              this.setChildrenCheck(chd, true);
            }
          });
      });
    },
    select(item, row) {
      let isAdd = item.some(v => {
        return v.id === row.id;
      });
      const overed = this.setRowCheck(row, isAdd);
      if (overed) {
        this.$message({
          type: 'warning',
          message: this.$t('pc_biz_ote_msg_maxselectnum', [this.max])
        });
        this.$refs[this.refTable].toggleRowSelection(row, false);
      }
      this.outPut();
    },
    selectChildren(obj, checked) {
      if (!checked || this.isNotOverNum()) {
        this.setChildrenCheck(obj, checked, true);
      } else {
        this.setChildrenCheck(obj, false, true);
        this.$message({
          type: 'warning',
          message: this.$t('pc_biz_ote_msg_maxselectnum', [this.max])
        });
      }
      this.outPut();
    },
    selectAll(items) {
      let isAdd = items.length !== 0;
      let overed = false;
      this.examsList.forEach(obj => {
        if (!this.checkRowSelectable(obj)) return;
        overed = this.setRowCheck(obj, isAdd);

        if (!overed) return;

        // 全选时需要把多余超过的取消勾选
        if (obj.children) {
          obj.children.find(c => !c.checked) &&
            this.$refs[this.refTable].toggleRowSelection(obj, false);
        } else {
          !this.selected[obj.id] &&
            this.$refs[this.refTable].toggleRowSelection(obj, false);
        }
      });
      overed &&
        this.$message({
          type: 'warning',
          message: this.$t('pc_biz_ote_msg_maxselectnum', [this.max])
        });
      this.outPut();
    },
    selectAllNopage() {
      this.loadingAll = true;
      getExamArrangeListNopage({
        groupFuncReq: this.groupSelect ? 1 : 0,
        sourceType:
          this.examSearch._sourceType === '' ? -1 : this.examSearch._sourceType,
        navCode: this.examSearch.navCode,
        dataPermissionCode: this.examSearch.dataPermissionCode,
        orderby: this.examSearch.orderby,
        direction: this.examSearch.direction,
        keyword: this.examSearch.keyword.trim(),
        startTime: this.examSearch.startTime,
        endTime: this.examSearch.endTime,
        dimensionType: this.loopExam ? 1 : 0,
        archiveFlag: this.examSearch.archiveFlag,
        arrangeType: this.examSearch.arrangeType,
        isReportDataSource: this.byReportDB ? 1 : 0,
        permFlag: this.publisherScope ? 1 : 0,
        limit: this.max
      })
        .then(result => {
          let overed = false;
          result.forEach(obj => {
            overed = this.setRowCheck(obj, true, true);
          });

          overed &&
            this.$message({
              type: 'warning',
              message: this.$t('pc_biz_ote_msg_maxselectnum', [this.max])
            });
          this.outPut();
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    deleteItem(item) {
      this.removeCheck(item);
      this.$forceUpdate();
      this.outPut();
    },
    clear() {
      for (const key in this.selected) {
        if (this.selected.hasOwnProperty(key)) {
          this.removeCheck(this.selected[key]);
        }
      }
      this.selected = {};
      this.outPut();
    },
    setChildrensCheck(childrens, checked) {
      let overed = false;
      childrens.forEach(chd => {
        if (checked) {
          if (this.isNotOverNum()) {
            this.setChildrenCheck(chd, true);
          } else {
            overed = true;
          }
        } else {
          this.setChildrenCheck(chd, false);
        }
      });
      return overed;
    },
    setChildrenCheck(obj, checked, byCheckbox) {
      if (checked === obj.checked && !byCheckbox) {
        return;
      }
      // obj.checked = checked;
      if (byCheckbox) {
        setTimeout(() => {
          this.$set(obj, 'checked', checked);
        });
      } else {
        this.$set(obj, 'checked', checked);
      }
      let parent = this.examsList.find(r => r.id === obj.parentId);
      if (!checked) {
        delete this.selected[obj.id];
        // 去除父的勾选
        parent && this.$refs['stb'].toggleRowSelection(parent, false);
      } else {
        this.selected[obj.id] = obj;
        parent &&
          !parent.children.find(c => !c.checked) &&
          this.$refs['stb'].toggleRowSelection(parent, true);
      }
    },
    removeCheck(row) {
      if (row.parentId) {
        this.setChildrenCheck(row, false);
      } else {
        for (let index = 0; index < this.examsList.length; index++) {
          const element = this.examsList[index];
          if (element.id === row.id) {
            row = element;
            break;
          }
        }
        if (row) {
          this.$refs['stb'].toggleRowSelection(row, false);
        }
        delete this.selected[row.id];
      }
    },
    setRowCheck(row, checked, needCheckRow) {
      let overed = false;
      if (!checked) {
        delete this.selected[row.id];
        // 删除子的选择
        this.isSelectLoopChild &&
          row.children &&
          this.setChildrensCheck(row.children, false);
      } else {
        if (!this.loopExam && !this.checkRowSelectable(row)) {
          return overed;
        }
        if (this.isSelectLoopChild && row.children && row.children.length > 0) {
          const tableRow = this.examsList.find(r => r.id === row.id) || row;
          overed = this.setChildrensCheck(tableRow.children, true);
        } else {
          if (this.isNotOverNum()) {
            this.selected[row.id] = row;

            if (needCheckRow) {
              const tableRow = this.examsList.find(r => r.id === row.id);
              tableRow &&
                this.$refs[this.refTable].toggleRowSelection(tableRow, true);
            }
          } else {
            overed = true;
          }
        }
      }
      return overed;
    },
    outPut() {
      this.selectedList = Object.values(this.selected);
      this.$emit('input', this.selectedList);
      this.$emit('select', this.selectedList);
    },
    formatModel(v) {
      this.selected = {};
      v.forEach(obj => {
        if (this.isSelectLoopChild) {
          let origin;
          this.examsList.forEach(p => {
            if (origin) return;
            const o = p.children && p.children.find(c => c.id === obj.id);
            if (o) origin = o;
          });
          if (origin) origin.checked = true;
          this.selected[obj.id] = origin || obj;
        } else {
          this.selected[obj.id] = obj;
        }
      });
      this.selectedList = Object.values(this.selected);
    }
  },
  watch: {
    value(v) {
      this.formatModel(v);
    },
    loopExam: {
      immediate: true,
      handler(v) {
        this.isSelectLoopChild = !v;
        if (v) {
          this.examSearch.arrangeType = 1;
        } else {
          this.examSearch.arrangeType = '';
        }
      }
    }
  }
};
</script>
