import { getStaticCdnUrl } from '../../../common-util/domain';
import { productionUtil } from '../../../common-util/productionUtil';
export const staticBaseUrlPath = `${getStaticCdnUrl().staticBaseUrl}`;
export const CorePath = `${getStaticCdnUrl().staticBaseUrl}ufd/2338a3/core/pc`;
export const CoreSvgPath = CorePath + '/svg';
export const OssPath = `${getStaticCdnUrl().imagesBaseUrl}`;
// export const NavsStuScript = `${OssPath}/dynamic/application/common/navs-stu.js`;

export const navVersion = 'nav_version';

console.log('productionUtil-1', productionUtil);

export const defaultProductInfo = productionUtil.getProductionInfo();
// 获取当前产品的全路径
export const defaultProductAllPath = defaultProductInfo.allPath;
// 获取产品路径
export const defaultProductPath = defaultProductInfo.path
  ? `/${defaultProductInfo.path}`
  : '';
// 根据产品的path区分不同的缓存
export const defaultPath = defaultProductInfo.path
  ? `${defaultProductInfo.path}_`
  : '';
