// 树组件开放出去的方法

export default {
  methods: {
    setCheckedNodes(...argu) {
      return this.currTreeRef.setCheckedNodes(...argu);
    },
    getCheckedKeys(...argu) {
      return this.currTreeRef.getCheckedKeys(...argu);
    },
    setCheckedKeys(...argu) {
      return this.currTreeRef.setCheckedKeys(...argu);
    },
    setCurrentNode(...argu) {
      return this.currTreeRef.setCurrentNode(...argu);
    },
    setCurrentKey(...argu) {
      return this.currTreeRef.setCurrentKey(...argu);
    },
    getCurrentKey(...argu) {
      return this.currTreeRef.getCurrentKey(...argu);
    },
    getCurrentNode(...argu) {
      return this.currTreeRef.getCurrentNode(...argu);
    },
    remove(data) {
      // 删除某一个节点
      this.currTreeRef.remove(data);
    },
    append(data, parentNode) {
      // 添加某一个节点
      this.currTreeRef.append(data, parentNode);
    },
    getNode(data) {
      // 根据data或者key拿到 Tree 组件中的 node
      return this.currTreeRef.getNode(data);
    },
    insertBefore(data, refNode) {
      this.currTreeRef.insertBefore(data, refNode);
    },
    insertAfter(data, refNode) {
      this.currTreeRef.insertAfter(data, refNode);
    }
  }
};
