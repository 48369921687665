// 课件的文件类型 fileType
export const KNG_FILE_TYPE = {
  IMAGE: 'img', // 图片
  WEIKE: 'xuanyes',
  WORD: 'word',
  ZIP: 'zip',
  AUDIO: 'audio',
  VIDEO: 'video',
  EXCEL: 'excel',
  SCORM: 'scorm',
  HTML: 'html',
  PPT: 'ppt',
  PDF: 'pdf',
  NEW_LINK: 'newlink',
  COURSE: 'course',
  EXAM: 'exam',
  PRACTICE: 'practice',
  QUESTIONNAIRE: 'questionnaire'
};

// 知识类型 0.课程、1.文档、2.视频、3.音频、4.微课、5.SCORM、6.HTML、7.压缩包、8.外链
export const KNG_TYPE = {
  COURSE: 0,
  DOC: 1,
  VIDEO: 2,
  AUDIO: 3,
  XUANYES: 4,
  WEIKE: 4,
  SCORM: 5,
  HTML: 6,
  ZIP: 7,
  NEWLINK: 8,
  PRACTICE_IN_CLASS: 88, // 随堂练习
  EXAMINE: 89, // 问题
  PRACTICE: 90, // 练习
  EXAM: 91 // 考试
};
