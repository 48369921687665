/**
 * 安全设置，校验符合安全中心的绑定，在调用组件时自执行
 * 新的axios实例，只走成功的校验
 */
const whiteList = ['#/bindpwd', '#/bindmobile', '#/bindemail', '#/thirdlogin'];
import config from '../api/configDomain';
import axios from 'axios';
import { productionUtil } from './productionUtil';
const api = axios.create({
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    source: 501,
    token: localStorage.token
  }
});
export const checkSafeFunction = () => {
  try {
    const token = localStorage.token || '';
    const checkResult = sessionStorage.getItem('safeCheckResult');
    // 如果没有token 或者没有hash跳过检验
    if (!token || whiteList.includes(location.hash) || window.checkSafe) {
      return;
    } else if (checkResult && checkResult.length) {
      const checkResult = JSON.parse(checkResult);
      dispatchPage(checkResult);
    } else {
      window.checkSafe = true; // 定义变量请求只执行一次
      api
        .get(`${config.core}userinfo/config`)
        .then(res => {
          const checkResult = res.data;
          sessionStorage.setItem(
            'safeCheckResult',
            JSON.stringify(checkResult)
          );
          dispatchPage(checkResult);
        })
        .catch(err => {
          console.log(err);
        });
    }
  } catch (error) {}
};

const dispatchPage = checkResult => {
  try {
    const isIsv =
      localStorage.sourceCode === '104' || localStorage.sourceCode === '106';
    const productInfo = productionUtil && productionUtil.getProductionInfo();
    // 区分不同产品
    const allPath = (productInfo && productInfo.allPath) || '';
    // 生态免登，sso登录，不需要修改默认密码，不需要定期修改密码
    if (
      (checkResult.changeDefaultPwd &&
        localStorage.getItem('defaultPwd') === 'true') ||
      (checkResult.needChangePwdPeriod && !isIsv)
    ) {
      localStorage.setItem('returnUrl', location.href);
      sessionStorage.removeItem('safeCheckResult');
      location.href = `${allPath}/#/bindpwd`;
      return;
    }
    // 绑定手机号
    if (checkResult.bindMobile && !checkResult.mobileValidated) {
      localStorage.setItem('returnUrl', location.href);
      sessionStorage.removeItem('safeCheckResult');
      location.href = `${allPath}/#/bindmobile`;
      return;
    }
    // 绑定邮箱
    if (checkResult.bindEmail && !checkResult.emailValidated) {
      localStorage.setItem('returnUrl', location.href);
      sessionStorage.removeItem('safeCheckResult');
      location.href = `${allPath}/#/bindemail`;
      return;
    }
  } catch (error) {}
};
