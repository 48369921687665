<template>
   <yxt-popover
          :append-to-body="false"
          popper-class="yxtbiz-certificate-selector__popover"
          placement="bottom-start"
          :visible-arrow="false"
           trigger='manual'
          v-model="showMoreFilter"
          @show="showFilter"
        >
          <yxt-button slot="reference" plain class="yxtbiz-filter-btn mr12" @click.stop='showFilterBox'>
            <yxt-svg
              width="20px"
              height="20px"
              icon-class="filter"
              class="v-top"
            />
            <span class="ml8 v-top">{{
              $t('pc_biz_common_lbl_moreFilter').d('更多筛选')
            }}</span>
            <i
              class="ml8"
              :class="
                showMoreFilter ? 'yxt-icon-arrow-up' : 'yxt-icon-arrow-down'
              "
            ></i>
          </yxt-button>
          <yxt-form :inline="true">
            <yxt-row :gutter="20">
              <yxt-col :span="12">
                <yxt-form-item
                  label-width="125px"
                  :label="$t('pc_biz_cer_update_date').d('证书更新日期')"
                >
                  <yxt-date-picker
                    v-model="updateDate"
                    format="yyyy-MM-dd"
                    type="daterange"
                    :range-separator="$t('pc_biz_ote_lbl_to').d('至')"
                    :start-placeholder="$t('pc_biz_ls_start').d('开始日期')"
                    :end-placeholder="$t('pc_biz_ls_end').d('结束日期')"
                  >
                  </yxt-date-picker>
                </yxt-form-item>
              </yxt-col>
              <yxt-col :span="12">
                <yxt-form-item
                  label-width="100px"
                  :label="$t('pc_biz_cer_certificate_state').d('证书状态')"
                >
                  <yxt-select
                    v-model="search.disabled"
                    clearable
                    size="large"
                    :placeholder="$t('pc_biz_ote_lbl_pleaseselect')"
                  >
                    <yxt-option
                      :label="$t('pc_biz_cer_enable').d('启用')"
                      :value="0"
                    ></yxt-option>
                     <yxt-option
                      :label="$t('pc_biz_cer_disable').d('禁用')"
                      :value="1"
                    ></yxt-option>
                  </yxt-select>
                </yxt-form-item>
              </yxt-col>
            </yxt-row>
            <yxt-row :gutter="20" class="mt20">
              <yxt-col :span="12">
                <yxt-form-item
                  label-width="125px"
                  :label="$t('pc_biz_cer_catalog').d('证书分类')"
                >
                   <yxt-select
                    v-model="search.catalogIds"
                    clearable
                    multiple
                    collapse-tags
                    size="large"
                    :placeholder="$t('pc_biz_ote_lbl_pleaseselect')"
                  >
                    <yxt-option
                      v-for="item in catalogList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></yxt-option>
                  </yxt-select>
                </yxt-form-item>
              </yxt-col>
              <yxt-col v-if="otherCertificate" :span="12">
                <yxt-form-item
                  label-width="100px"
                  :label="$t('pc_biz_cer_certificate_type').d('证书类型')"
                >
                  <yxt-select
                    v-model="search.type"
                    clearable
                    size="large"
                    :placeholder="$t('pc_biz_ote_lbl_pleaseselect')"
                  >
                     <yxt-option
                      :label="$t('pc_biz_cer_template_certificate').d('模板证书')"
                      :value="0"
                    ></yxt-option>
                     <yxt-option
                      :label="$t('pc_biz_cer_other_certificate').d('其他证书')"
                      :value="1"
                    ></yxt-option>
                  </yxt-select>
                </yxt-form-item>
              </yxt-col>
              <yxt-col v-if="!otherCertificate" :span='12'>
                  <yxt-form-item  :label="' '" label-width='100px' class='d-in-block'>
                    <yxt-button
                        @click='handleSearch'
                        type='primary'
                      >{{$t('pc_biz_udp_btn_determine')}}</yxt-button>
                      <yxt-button
                        @click='cancelSearch'
                        plain
                        class="mr8"
                        type='default'
                      >{{$t('pc_biz_udp_btn_cancel')}}</yxt-button>
                  </yxt-form-item>
                </yxt-col>
            </yxt-row>
             <yxt-row :gutter="20" class="mt20" v-if="otherCertificate">
              <yxt-col :span='12' :offset="12">
                  <yxt-form-item :label="' '" label-width='100px' class='d-in-block'>
                    <yxt-button
                        @click='handleSearch'
                        type='primary'
                      >{{$t('pc_biz_udp_btn_determine')}}</yxt-button>
                      <yxt-button
                        @click='cancelSearch'
                        plain
                        class="mr8"
                        type='default'
                      >{{$t('pc_biz_udp_btn_cancel')}}</yxt-button>
                  </yxt-form-item>
                </yxt-col>
            </yxt-row>
          </yxt-form>
        </yxt-popover>
</template>
<script>
import dateUtil from 'yxt-biz-pc/src/utils/date';

export default {
  props: {
    // 是否显示其他证书
    otherCertificate: {
      type: Boolean,
      default: false
    },
    // 证书分类
    catalogList: {
      type: Array,
      default: () => []
    },
    oldSearch: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showMoreFilter: false, // 显示更多筛选
      updateDate: [], // 更新时间
      search: {
        updateDate: [], // 更新时间
        disabled: '', // 证书状态 -1 全部 0 启用 1 禁用
        catalogIds: [], // 证书分类
        type: '' // 证书类型 -1 全部 0 模板证书 1 其他证书
      }
    };
  },
  methods: {
    showFilterBox() {
      this.showMoreFilter = !this.showMoreFilter;
    },
    // 更多筛选显示时，重新赋值
    showFilter() {
      const oldSearch = JSON.parse(JSON.stringify(this.oldSearch));
      for (const key in this.search) {
        if (this.search.hasOwnProperty(key)) {
          if (oldSearch[key] !== this.search[key]) {
            this.search[key] = oldSearch[key];
            key === 'updateDate' && (this.updateDate = this.search[key]);
          }
        }
      }
    },
    // 格式化日期
    shortDate(val) {
      if (typeof val === 'string') {
        val = val.replace(/-/g, '/');
      }
      return dateUtil.format(new Date(val), 'yyyy-MM-dd') + ' 00:00:00';
    },
    // 确定
    handleSearch() {
      let date = [];
      if (this.updateDate && this.updateDate.length) {
        date = [this.shortDate(this.updateDate[0]), this.shortDate(this.updateDate[1])];
      }
      this.search.updateDate = date;
      this.$emit('search', this.search);
      this.showMoreFilter = false;
    },
    // 取消
    cancelSearch() {
      this.showMoreFilter = false;
    }
  }
};
</script>
