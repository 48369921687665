/*
 * @Author: 徐昕
 * @Date: 2021-05-19 16:22:00
 * @LastEditTime: 2022-03-11 12:25:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /yxt-biz-pc/packages/tcm-select/src/service.js
 */
import { tcmApi } from 'yxt-biz-pc/packages/api';

// 微课列表
export const getTcmList = (query) => {
  return tcmApi.get('knglib/tcmCourse/page', { params: query });
};
