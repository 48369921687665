var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "yxt-dialog",
        {
          attrs: {
            width: "80%",
            "custom-class": "yxtbiz-file",
            visible: _vm.visiable,
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "destroy-on-close": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiable = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("upload-title", {
            attrs: { slot: "title", noBorder: "" },
            slot: "title",
          }),
          _vm._l(_vm.multipleUploadLists, function (file) {
            return _c(
              "div",
              { key: file.id },
              [
                !file.isDelete
                  ? _c(
                      "yxt-row",
                      { staticClass: "lh88 border-b-e9 ph24" },
                      [
                        _c(
                          "yxt-col",
                          { attrs: { span: 14 } },
                          [
                            _c(
                              "yxt-row",
                              {
                                staticClass: "d-in-block v-mid",
                                staticStyle: { width: "calc(100% - 38px)" },
                              },
                              [
                                _c(
                                  "yxt-col",
                                  {
                                    staticClass: "pr20 flex-align-center",
                                    attrs: {
                                      span:
                                        file.fileUploadErrorMsg.length > 0
                                          ? 13
                                          : 16,
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "v-mid hand",
                                      class: _vm.kngType(file),
                                      attrs: { title: file.extension },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: file.isMutliUploading,
                                            expression: "file.isMutliUploading",
                                          },
                                        ],
                                        staticClass:
                                          "ml20 d-in-block ellipsis v-mid flex-1 no-wrap",
                                        attrs: { title: file.name },
                                      },
                                      [_vm._v(_vm._s(file.name))]
                                    ),
                                    _c("yxt-input", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !file.isMutliUploading,
                                          expression: "!file.isMutliUploading",
                                        },
                                      ],
                                      ref: "file" + file.id,
                                      refInFor: true,
                                      staticClass: "ml20 flex-1 v-mid",
                                      attrs: { maxlength: 50 },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.showInput(file)
                                        },
                                      },
                                      model: {
                                        value: file.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            file,
                                            "name",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "file.name",
                                      },
                                    }),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !file.isMutliUploading,
                                          expression: "!file.isMutliUploading",
                                        },
                                      ],
                                      staticClass:
                                        "yxt-icon-success v-mid ml15 font-size-18 d-in-block yxt-color-success",
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "yxt-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          file.fileUploadErrorMsg.length > 0,
                                        expression:
                                          "file.fileUploadErrorMsg.length > 0",
                                      },
                                    ],
                                    staticClass: "yxt-color-danger",
                                    attrs: {
                                      span:
                                        file.fileUploadErrorMsg.length > 0
                                          ? 11
                                          : 8,
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "yxt-icon-error v-mid mr10 font-size-18 d-in-block yxt-color-danger",
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(file.fileUploadErrorMsg)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "yxt-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: file.fileUploadErrorMsg.length === 0,
                                expression:
                                  "file.fileUploadErrorMsg.length === 0",
                              },
                            ],
                            staticClass: "font-size-12 color-gray-6",
                            attrs: { span: 7 },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "color-gray-6 d-in-block lh30" },
                              [
                                _c("span", { staticClass: "d-in-block w-64" }, [
                                  _vm._v(_vm._s(file.size)),
                                ]),
                                file.isMutliUploading
                                  ? _c("yxt-progress", {
                                      staticClass: "w-170 d-in-block ml10",
                                      attrs: {
                                        percentage: file.percentage,
                                        "stroke-width": 4,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "yxt-col",
                          {
                            staticClass: "text-right",
                            attrs: {
                              span:
                                file.fileUploadErrorMsg.length === 0 ? 3 : 10,
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "color-primary-6 hand",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteMutilFile(file)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "lh34", attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "yxt-row",
                [
                  _c(
                    "yxt-col",
                    {
                      staticClass: "color-gray-8 font-size-16 text-left",
                      attrs: { span: 5 },
                    },
                    [
                      _vm._v("\n          上传成功"),
                      _c(
                        "span",
                        { staticClass: "color-primary-6 font-size-16 pl5 pr5" },
                        [_vm._v(_vm._s(_vm.alreadyUploadFileNum))]
                      ),
                      _vm._v("个文件\n        "),
                    ]
                  ),
                  _c(
                    "yxt-col",
                    { staticClass: "text-center", attrs: { span: 9 } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.hideData,
                              expression: "!hideData",
                            },
                          ],
                        },
                        [
                          _c("i", { staticClass: "yui-speed-icon v-mid" }),
                          _c(
                            "span",
                            {
                              staticClass:
                                "v-mid ml10 font-size-12 color-green-4",
                            },
                            [
                              _vm._v(
                                "上传速度：" + _vm._s(_vm.multipleUploadSpeed)
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticClass: "v-mid ml15 font-size-12" },
                            [
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v(
                                  "剩余时间：" + _vm._s(_vm.multipleRestTime)
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "yxt-col",
                    {
                      staticClass: "text-right",
                      attrs: { span: !_vm.hideData ? 10 : 19 },
                    },
                    [
                      _c(
                        "yxt-button",
                        {
                          attrs: { plain: "" },
                          on: { click: _vm.closeDialog },
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "upload",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.disabled,
                              expression: "!disabled",
                            },
                          ],
                          ref: "bizUpload",
                          attrs: {
                            source: "501",
                            "app-code": "kng",
                            "config-key": "KnowledgeConfigKey",
                            "module-name": "knowledgefiles",
                            "function-name": "files",
                            "files-filter": _vm.multipleFilesFilter,
                            "on-network-speed": _vm.multipleOnNetworkSpeed,
                            "files-added": _vm.multipleFilesAdded,
                            "on-progress": _vm.multipleOnProgress,
                            "on-uploaded": _vm.multipleOnUploaded,
                            "on-error": _vm.multipleOnError,
                            multipe: "",
                          },
                        },
                        [
                          _c("yxt-button", { attrs: { plain: "" } }, [
                            _vm._v("继续上传"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "yxt-button",
                        {
                          staticClass: "ml10",
                          attrs: { type: "primary" },
                          on: { click: _vm.submitAll },
                        },
                        [_vm._v("全部提交")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      !_vm.visiable
        ? _c(
            "div",
            { staticClass: "d-in-block hand" },
            [
              _c(
                "upload",
                {
                  ref: "bizUpload",
                  attrs: {
                    source: "501",
                    "app-code": "kng",
                    "config-key": "KnowledgeConfigKey",
                    "module-name": "knowledgefiles",
                    "function-name": "files",
                    filters: _vm.filters,
                    "files-filter": _vm.filesFilter,
                    "file-filtered": _vm.fileFiltered,
                    "on-network-speed": _vm.onNetworkSpeed,
                    "files-added": _vm.filesAdded,
                    "on-progress": _vm.onProgress,
                    "on-uploaded": _vm.onUploaded,
                    "on-error": _vm.onError,
                    multipe: "",
                  },
                },
                [
                  _c(
                    "yxt-button",
                    {
                      staticClass: "yxtbiz-upload-kng",
                      attrs: { type: "primary" },
                    },
                    [_vm._v("上传课件")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }