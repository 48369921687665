<template>
  <div class="yxtbiz-exam-selector">
    <div class="yxtbiz-exam-selector__left">
      <div>
        <yxt-input
          :placeholder="$t('pc_biz_ote_tip_catalogsearch')"
          searchable
          maxlength="50"
          v-model.trim="examCatalogSearch"
          class="mb16"
        >
        </yxt-input>
      </div>
      <yxt-tree
        ref="tree"
        :data="datasCatalog"
        :props="defaultProps"
        @node-click="selectCatalog"
        node-key="id"
        :wrap-width="260"
        :filter-node-method="filterNode"
        :default-expanded-keys="['root']"
      ></yxt-tree>
    </div>
    <div class="yxtbiz-exam-selector__right">
      <div class="clearfix font-size-14">
        <!-- 全部试卷 -->
        <common-drop-selector
          v-model="examSearch.examRange"
          size="small"
          :clearable="false"
          customWidthClass="width160"
          :dataList="rangeList"
          class="mr12"
          @change="getExamList(true)">
        </common-drop-selector>
        <yxt-input
          :placeholder="$t('pc_biz_ote_tip_exampapersearch')"
          v-model.trim="examSearch.name"
          class="width240"
          searchable
          @search="examNameSearch"
          maxlength="50"
        >
        </yxt-input>
      </div>
      <yxt-table
        ref="examTable"
        :height="tableHeight"
        v-loading="loading"
        class="mt16 yxtbiz-exam-table"
        :data="examsList"
        :default-sort="{ prop: 'updateTime', order: 'desc' }"
        @sort-change="sortChange"
      >
        <yxt-table-column
          :label="$t('pc_biz_ote_lbl_exampapername')"
          :show-overflow-tooltip="true"
          min-width="240"
        >
          <template slot-scope="scope">
            <span
              v-if="
                !scope.row.secrecy ||
                scope.row.shared === 1 ||
                scope.row.createUserId === userId ||
                isAdmin === '1'
              "
              @click="goExamPreview(scope.row.id)"
              type="primary"
              class="yxtbiz-exam-selector__title color-primary-6 hand"
              >{{ scope.row.name }}</span
            >
            <span v-else>{{ scope.row.name }}</span>
          </template>
        </yxt-table-column>
        <yxt-table-column
          :label="$t('pc_biz_ote_lbl_exampapertype')"
          prop="buildType"
          align="left"
          width="140"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.buildType === 0">{{
              $t('pc_biz_ote_lbl_exampapertype0')
            }}</span>
            <span v-if="scope.row.buildType === 1">{{
              $t('pc_biz_ote_lbl_exampapertype1')
            }}</span>
          </template>
        </yxt-table-column>
        <yxt-table-column :show-overflow-tooltip="true"
                          :popover-html="true"
                          :label="$t('pc_biz_lbl_creator'/*创建人*/)"
                          prop="createFullname"
                          width="180">
          <template slot-scope="scope">
            <yxtbiz-user-name :name="scope.row.createFullname" />
            (<yxtbiz-user-name :name="scope.row.createUserName" />)
          </template>
        </yxt-table-column>
        <yxt-table-column
          :label="$t('pc_biz_ote_lbl_updatetime')"
          prop="updateTime"
          width="140"
          sortable="custom"
          :formatter="shortDate"
          align="left"
        ></yxt-table-column>
        <yxt-table-column
          :label="$t('pc_biz_ote_btn_operate')"
          width="100"
          fixed="right"
        >
          <template slot-scope="scope">
            <yxt-button
              type="text"
              class="color-primary-6 hand"
              @click="selectExam(scope.row)"
            >
              {{
                value && value.id === scope.row.id
                  ? $t('pc_biz_ote_btn_cancleselect')
                  : $t('pc_biz_ote_btn_select')
              }}
            </yxt-button>
          </template>
        </yxt-table-column>
      </yxt-table>
      <div class="mt16 clearfix">
        <yxt-pagination
          class="pull-right"
          :page-size="examSearch.limit"
          :total="count"
          :current-page="page"
          layout="total, prev, pager, next"
          @current-change="pageChange"
          @size-change="sizeChange"
        ></yxt-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getExamList, getCatalogList } from './service';
import dateUtil from 'yxt-biz-pc/src/utils/date';
import resizeTable from '../../user-selector/mixins/resizeTable';
import { Table, TableColumn, Pagination, Tooltip, Tree, Link } from 'yxt-pc';
import CommonDropSelector from 'packages/exam-arrange-makeup/src/userMgmt/component/CommonDropSelector';

export default {
  name: 'YxtbizExamSelector',
  components: {
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtTooltip: Tooltip,
    YxtTree: Tree,
    YxtLink: Link,
    CommonDropSelector
  },
  mixins: [resizeTable],
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: '',
          name: ''
        };
      }
    },
    // 试卷种类。 0：不限制  1：只有客观题
    type: {
      type: Number,
      default: 0
    },
    buildType: {
      type: Number,
      default: -1,
      validator: function(value) {
        return [-1, 0, 1].includes(value);
      }
    },
    multimedia: {
      type: Number,
      default: -1,
      validator: function(value) {
        return [-1, 0, 1].includes(value);
      }
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    // 试卷目录的页面编号
    catalogFunctionCode: {
      type: String,
      default: ''
    },
    // 试卷目录的权限编号
    catalogDataPermissionCode: {
      type: String,
      default: ''
    },
    // 是否练习试卷
    practice: {
      type: Number,
      default: 0
    },
    // 是否包含集团共享的资源
    groupShared: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    examCatalogSearch(val) {
      this.$refs.tree.filter(val);
    }
  },
  data() {
    return {
      isAdmin: window.localStorage.admin, // 超管标识
      userId: window.localStorage.userId, // 当前用户ID
      examCatalogSearch: '',
      refTable: 'examTable',
      tableHeight: 0,
      page: 1,
      examSearch: {
        buildType: this.buildType,
        hasSeniorQues: this.multimedia,
        disabled: 0,
        needCheck: this.type === 1 ? 0 : -1,
        keywordType: 0,
        status: 4, // 已发布的
        catalogId: '',
        name: '',
        limit: 10,
        offset: 0,
        orderby: 'updateTime',
        direction: 'DESC',
        navCode: this.functionCode,
        dataPermissionCode: this.dataPermissionCode,
        examRange: 0
      },
      loaded: 2,
      loading: false,
      totalPage: 0,
      count: 0,
      examsList: [],
      datasCatalog: [
        {
          id: 'root',
          label: this.$t('pc_biz_ote_lbl_examcatalog'),
          children: []
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      rangeList: [
        { label: this.$t('pc_ote_lbl_all_exam_mgmt' /* 全部试卷 */), value: 0 },
        { label: this.$t('pc_ote_lbl_createby_me' /* 我创建的 */), value: 1 },
        { label: this.$t('pc_biz_ote_my_charge' /* 我管辖的 */), value: 3 },
        { label: this.$t('pc_ote_lbl_share_with_me' /* 与我共享的 */), value: 2 }
      ]
    };
  },
  created() {
    this.getCatalogList();
    this.getExamList();
  },
  methods: {
    shortDate(row, col, val, index) {
      if (!val) return '';
      if (typeof val === 'string') {
        val = val.replace(/-/g, '/');
      }
      return dateUtil.format(new Date(val), 'yyyy-MM-dd');
    },
    filterNode(value, data) {
      return !value || data.label.indexOf(value) !== -1;
    },
    selectCatalog(item) {
      this.page = 1;
      this.examSearch.offset = 0;
      this.examSearch.catalogId = item.id;
      this.examSearch.routingPath = item.routingPath;
      this.getExamList();
    },
    getCatalogList() {
      getCatalogList({
        navCode: this.catalogFunctionCode,
        dataPermissionCode: this.catalogDataPermissionCode
      })
        .then(catalogs => {
          this.datasCatalog = this.setCatalogLabel([catalogs]);

          this.loaded--;
        })
        .catch(e => {
          console.log(e);
        });
    },
    setCatalogLabel(list) {
      list &&
        list.length > 0 &&
        list.forEach(element => {
          element.label = element.name;
          this.setCatalogLabel(element.children);
        });
      return list;
    },
    getExamList(isReset) {
      if (isReset) {
        this.page = 1;
        this.examSearch.offset = 0;
      }
      this.examSearch.examType = this.practice;
      this.examSearch.name = this.examSearch.name.trim();
      this.examSearch.isFilterShareSource = this.groupShared ? 0 : 1;
      this.loading = true;
      getExamList(this.examSearch)
        .then(result => {
          this.examsList = result.datas;
          this.totalPage = result.paging.pages;
          this.count = result.paging.count;
          this.loaded--;
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
        });
    },
    examNameSearch() {
      this.examSearch.searchKey = this.examCatalogSearch;
      this.getExamList(true);
    },
    sortChange(sort) {
      this.examSearch.direction = sort.order;
      this.examSearch.orderby = sort.prop;
      this.getExamList();
      return false;
    },
    sizeChange(size) {
      this.examSearch.limit = size;
      this.getExamList(true);
    },
    pageChange(page) {
      this.page = page;
      this.examSearch.offset = (page - 1) * this.examSearch.limit;
      this.getExamList();
    },
    authChange(value) {
      // 1 我创建与负责的, 2 直属员工创建与负责的, 3 所辖部门员工创建与负责的
      this.examSearch.queryType = value;
      this.getExamList(true);
    },
    selectExam(item) {
      if (this.value && this.value.id === item.id) {
        this.$emit('input', null);
        this.$emit('select', null);
      } else {
        this.$emit('input', item);
        this.$emit('select', item);
      }
    },
    getCatasOfTree(arrNew, pId, cataAll) {
      cataAll.forEach(cata => {
        if (cata.parentId === pId || (!pId && !cata.parentId)) {
          cata.label = cata.name;
          cata.children = [];
          this.getCatasOfTree(cata.children, cata.id, cataAll);
          arrNew.push(cata);
        }
      });
    },
    goExamPreview(id) {
      window.open('/ote/#/examPaperPreview?examid=' + id);
    }
  }
};
</script>
