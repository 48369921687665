<template>
  <div :class="{'pl16': subQues}">
    <!-- 题干 -->
    <span v-html="cleanStyle(quesBody.content)" data-rich-text="1"></span>
    <media-player
      v-if="quesBody.fileId && quesBody.quesType > 1"
      class="mt16"
      :type="quesBody.quesType"
      :options="playerOptions"
      :tranStatus="quesBody.tranStatus"
    >
    </media-player>
    <!-- 组合题：考点、难度 -->
    <yxt-row v-if="quesBody.type === 5">
      <yxt-row class="mv16 flex">
        <tag-keyword tag :label="$t('pc_biz_ote_lbl_assesspoint' /* 考点 */)"></tag-keyword>
        <tag-keyword class="color-gray-9" :label="pointNames"></tag-keyword>
      </yxt-row>
      <yxt-row class="mt16 flex">
        <tag-keyword tag :label="$t('pc_biz_ote_lbl_queslevel' /* 难度 */)"></tag-keyword>
        <tag-keyword class="color-gray-9" :label="getLevelType(quesBody.levelType)"></tag-keyword>
      </yxt-row>
    </yxt-row>
    <yxt-divider v-if="quesBody.type === 5"></yxt-divider>
    <!-- 组合题之外的选项和考点等 -->
    <yxt-row v-else class="pl16 pt8">
      <!-- 单选题选项 -->
      <yxt-radio-group
        v-if="quesBody.type === 0"
        class="yxtbiz-question-preview__radio-group"
        :direction="'row'"
        :disabled="true"
      >
        <yxt-radio
          v-for="(item, index) in quesBody.questionItems"
          :key="item.id"
          class="yxtbiz-question-preview__radio"
          :label="item.id"
        >
          <div class="flex">
            <span class="yxtbiz-flex-shrink-0">{{ convertASCIIForNum(index) }}.</span>
            <span class="ml4" v-html="cleanStyle(item.itemContent, true)" data-rich-text="1"></span>
          </div>
          <media-player
            v-if="item.itemPlay && item.itemPlay.fileId"
            class="mt16"
            :type="item.itemPlay.type"
            :options="playerMediaOptions(item.itemPlay)"
            :tranStatus="item.itemPlay.tranStatus"
          >
          </media-player>
        </yxt-radio>
      </yxt-radio-group>
      <!-- 多选题选项 -->
      <yxt-checkbox-group
        v-if="quesBody.type === 1"
        v-model="correctAnswers"
        class="yxtbiz-question-preview__checkbox-group"
        :direction="'row'"
        :disabled="true"
      >
        <yxt-checkbox
          v-for="(item, index) in quesBody.questionItems"
          :key="item.id"
          class="yxtbiz-question-preview__checkbox"
          :label="item.id"
        >
          <div class="flex">
            <span class="yxtbiz-flex-shrink-0">{{ convertASCIIForNum(index) }}.</span>
            <span class="ml4" v-html="cleanStyle(item.itemContent, true)"  data-rich-text="1"></span>
          </div>
          <media-player
            v-if="item.itemPlay && item.itemPlay.fileId"
            class="mt16"
            :type="item.itemPlay.type"
            :options="playerMediaOptions(item.itemPlay)"
            :tranStatus="item.itemPlay.tranStatus"
          >
          </media-player>
        </yxt-checkbox>
      </yxt-checkbox-group>
      <!-- 判断题选项 -->
      <yxt-radio-group
        v-if="quesBody.type === 2"
        class="yxtbiz-question-preview__radio-group"
        :direction="'row'"
        :disabled="true"
      >
        <yxt-radio class="yxtbiz-question-preview__radio" :label="1">
          A.{{ quesBody.judgeCorrectOptionContent ? quesBody.judgeCorrectOptionContent : $t('pc_biz_ote_lbl_wright') }}
        </yxt-radio>
        <yxt-radio class="yxtbiz-question-preview__radio" :label="0">
          B.{{ quesBody.judgeWrongOptionContent ? quesBody.judgeWrongOptionContent : $t('pc_biz_ote_lbl_wrong') }}
        </yxt-radio>
      </yxt-radio-group>
      <!-- 针对问答题-关键字 -->
      <yxt-row v-if="quesBody.type === 4 && quesBody.keywords && quesBody.keywords.length" class="mt8 mb16 flex">
        <tag-keyword tag :label="$t('pc_biz_ote_keyword' /* 关键字 */)"></tag-keyword>
        <tag-keyword class="color-gray-9" :label="quesBody.keywords.map(item => item.name).join(';')"></tag-keyword>
      </yxt-row>
      <!-- 答案 -->
      <yxt-row class="mv16 flex">
        <tag-keyword tag :label="$t('pc_biz_ote_lbl_itemanswer' /* 答案 */)"></tag-keyword>

        <!-- 单选题 -->
        <yxt-col v-if="quesBody.type === 0" class="w-auto color-gray-9">
          {{ convertASCIIForNum(correctAnswer) }}
        </yxt-col>
        <!-- 多选题 -->
        <yxt-col v-if="quesBody.type === 1" class="w-auto color-gray-9">
          <span v-for="item in correctAnswers" :key="item">{{ convertASCIIForNum(item) }} </span>
        </yxt-col>
        <!-- 判断题 -->
        <yxt-col v-if="quesBody.type === 2" class="w-auto color-gray-9">
          {{ quesBody.judgeAnswer === 0 ? 'B' : 'A' }}
        </yxt-col>
        <!-- 填空题 -->
        <yxt-col v-if="quesBody.type === 3" class="w-auto color-gray-9">
          <template v-if="quesBody.fillItems && quesBody.fillItems.length">
            <div v-for="(item, index) in quesBody.fillItems" :key="item.id">
              <span v-if="quesBody.fillItems.length > 1">{{ index + 1 }}.</span>
              <span>{{ mergeAnswerFun(item) }}</span>
              <span v-if="index + 1 !== quesBody.fillItems.length">;</span>
            </div>
          </template>
          <div v-else>{{ $t('pc_biz_ote_lbl_none' /* 暂无 */) }}</div>
        </yxt-col>
        <!-- 问答题 -->
        <yxt-col v-if="quesBody.type === 4" class="w-auto color-gray-9">
          <div v-if="quesBody.answerContent || (quesBody.answerPlay && quesBody.answerPlay.fileId)">
            <span v-if="quesBody.answerContent" v-html="cleanStyle(quesBody.answerContent, true)"  data-rich-text="1"></span>
            <media-player
              v-if="quesBody.answerPlay && quesBody.answerPlay.fileId"
              :class="quesBody.answerContent ? 'mt16' : ''"
              :type="quesBody.answerPlay.type"
              :options="playerMediaOptions(quesBody.answerPlay)"
              :tranStatus="quesBody.answerPlay.tranStatus"
            >
            </media-player>
          </div>
          <div v-else>{{ $t('pc_biz_ote_lbl_none' /* 暂无 */) }}</div>
        </yxt-col>
      </yxt-row>
      <!-- 考点 -->
      <yxt-row v-if="!subQues" class="mv16 flex">
        <tag-keyword tag :label="$t('pc_biz_ote_lbl_assesspoint' /* 考点 */)"></tag-keyword>
        <tag-keyword class="color-gray-9" :label="pointNames"></tag-keyword>
      </yxt-row>
      <!-- 难度 -->
      <yxt-row v-if="!subQues" class="mv16 flex">
        <tag-keyword tag :label="$t('pc_biz_ote_lbl_queslevel' /* 难度 */)"></tag-keyword>
        <tag-keyword class="color-gray-9" :label="getLevelType(quesBody.levelType)"></tag-keyword>
      </yxt-row>
      <!-- 解析 -->
      <yxt-row class="mt16 flex">
        <tag-keyword tag :label="$t('pc_biz_ote_lbl_quesanalyze' /* 解析 */)"></tag-keyword>
        <yxt-col class="w-auto color-gray-9">
          <div
            v-show="showExplain"
            ref="nowrapEle"
            class="parsing-content">
            <div :class="{'nowrap-3': isShowMoreText && !isShowMore}">
              <span v-html="cleanStyle(quesBody.explainText)"  data-rich-text="1"></span>
              <media-player
                v-if="quesBody.explainPlay && quesBody.explainPlay.fileId"
                :class="cleanStyle(quesBody.explainText) ? 'mt16' : ''"
                :type="quesBody.explainPlay.type"
                :options="playerMediaOptions(quesBody.explainPlay)"
                :tranStatus="quesBody.explainPlay.tranStatus">
              </media-player>
            </div>
            <div
              v-if="isShowMoreText"
              class="text-center color-primary-6 font-size-14 hand mt4 lh22 mb0"
              @click="isShowMore = !isShowMore"
            >
              <span>{{ isShowMore ? $t('pc_biz_ote_btn_putaway') : $t('pc_biz_ote_btn_expand') }}</span>
              <span class="ml3" :class="isShowMore ? 'yxt-icon-arrow-up' : 'yxt-icon-arrow-down'"></span>
            </div>
          </div>
          <!-- 暂无解析 -->
          <div v-show="!showExplain">{{ $t('pc_biz_ote_lbl_noquesanalysis') }}</div>
        </yxt-col>
      </yxt-row>
    </yxt-row>
    <!-- 组合题子题 -->
    <ques-view-info
      v-for="(item, index) in quesBody.subQuesList"
      :key="item.id"
      :quesId="item.quesId"
      :quesBody="item"
      :subQues="true"
      :showDivider="index < quesBody.subQuesList.length - 1"
    ></ques-view-info>
    <yxt-divider v-if="showDivider"></yxt-divider>
  </div>
</template>
<script>
import { cleanStyle, convertASCIIForNum } from '../utils';
import { QUES_LEVEL_NAMES } from '../utils';
import MediaPlayer from './quesMedia';
import TagKeyword from './tagKeyword';

export default {
  name: 'quesViewInfo',
  components: {
    TagKeyword,
    MediaPlayer
  },
  data() {
    return {
      correctAnswer: -1,
      correctAnswers: [],
      pointNames: '',
      showExplain: true,
      nowrapHeight: 66,
      isShowMore: false,
      isShowMoreText: false
    };
  },
  props: {
    quesId: {
      type: String,
      default: ''
    },
    quesBody: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 是否组合题子题
    subQues: {
      type: Boolean,
      default: false
    },
    // 是否显示分割线：组合题题干与子题、子题与子题之间
    showDivider: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    playerOptions() {
      if (this.quesBody.tranStatus === 2) {
        const options = this.quesBody.playDetails;
        return options.map(option => {
          return {
            fileFullUrl: option.url,
            fileId: this.quesBody.fileId,
            resolution: option.desc
          };
        });
      }
      return [
        {
          fileFullUrl: '',
          label: ''
        }
      ];
    }
  },
  watch: {
    'quesBody.explainText': {
      handler(val) {
        if (val || (this.quesBody.explainPlay && this.quesBody.explainPlay.fileId)) {
          this.$nextTick(() => {
            this.nowrapEleHeight();
          });
        } else {
          this.showExplain = false;
        }
      },
      immediate: true,
      deep: true // 表示开启深度监听
    },
    quesId: () => {
      this.getDetail();
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    cleanStyle,
    getDetail() {
      this.getPointNames();
      this.getCorrectAnswer();
    },
    // 获取考核点名称
    getPointNames() {
      let names = '';
      if (this.quesBody.points && this.quesBody.points.length > 0) {
        this.quesBody.points.forEach(e => {
          names += e.pointName + '；';
        });
      }
      if (names.length > 0) {
        names = names.substring(0, names.length - 1);
      }
      this.pointNames = names;
    },
    // 获取正确答案
    getCorrectAnswer() {
      let crtAns = 0; // 正确答案（单选题）
      let crtAnss = []; // 正确答案（多选题）
      let qt = this.quesBody.type;
      // 各题型选项设置
      if (qt === 0 || qt === 1) {
        // 获取正确答案
        this.quesBody.questionItems.forEach((e, i) => {
          if (qt === 0 && e.answer === 1) {
            crtAns = i;
          }
          if (qt === 1 && e.answer === 1) {
            crtAnss.push(i);
          }
        });
        this.correctAnswer = crtAns;
        this.correctAnswers = crtAnss;
      }
    },
    getLevelType(val) {
      return QUES_LEVEL_NAMES[val] ? this.$t(QUES_LEVEL_NAMES[val]) : '';
    },
    convertASCIIForNum,
    nowrapEleHeight() {
      this.$refs.nowrapEle.offsetHeight > this.nowrapHeight
        ? (this.isShowMoreText = true)
        : (this.isShowMoreText = false);
    },
    playerMediaOptions(mediaPlay) {
      if (mediaPlay.tranStatus === 2) {
        const options = mediaPlay.playDetails;
        return options.map(option => {
          return {
            fileFullUrl: option.url,
            fileId: mediaPlay.fileId,
            resolution: option.desc
          };
        });
      }
      return [{
        fileFullUrl: '',
        label: ''
      }];
    },

    // 合并填空题-answer的备选答案
    mergeAnswerFun(item) {
      const { itemAnswer, itemAnswer1, itemAnswer2, itemAnswer3, itemAnswer4, itemAnswer5 } = item;
      return [itemAnswer, itemAnswer1, itemAnswer2, itemAnswer3, itemAnswer4, itemAnswer5].filter(item => item && item.trim() !== '').join(';');
    }
  }
};
</script>
