import { teApi } from 'yxt-biz-pc/packages/api';

// 讲师试卷列表
export const getTeList = (param) => {
  return teApi.post('/tutor/select', param);
};

// 获取讲师分类列表
export const getCatalogList = () => {
  return teApi.get('/te/catalog/list/select');
};
