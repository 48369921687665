/**
 * get tree node child
 * @param arr
 * @param parentId
 * @param key key for parentId
 * @return {*}
 */
export function getChildNodes(arr, parentId, key = 'parentId') {
  return arr.filter(v => v[key] === parentId);
}

/**
 * get all tree from array
 * @param arr
 * @param parentId
 * @param key key for parentId
 * @return {*}
 */
export function arrayToTree(arr, parentId, key = 'parentId') {
  let temp = [];
  let treeArr = arr;
  treeArr.forEach((item, index) => {
    if (item[key] === parentId) {
      if (arrayToTree(treeArr, treeArr[index].id).length > 0) {
        // 递归调用此函数
        treeArr[index].children = arrayToTree(treeArr, treeArr[index].id);
      }
      temp.push(treeArr[index]);
    }
  });
  return temp;
}

function isObj(obj) {
  return (typeof obj === 'object' || typeof obj === 'function') && obj !== null;
}

export function deepCopy(obj) {
  let tempObj = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    tempObj[key] = isObj(obj[key]) ? deepCopy(obj[key]) : obj[key];
  }
  return tempObj;
}
