var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-select",
    {
      class: _vm.customWidthClass ? _vm.customWidthClass : "width-percent-100",
      attrs: { clearable: _vm.clearable, placeholder: _vm.placeholder },
      on: { change: _vm.selectUserTypeChange },
      model: {
        value: _vm.val,
        callback: function ($$v) {
          _vm.val = $$v
        },
        expression: "val",
      },
    },
    _vm._l(_vm.list, function (item) {
      return _c("yxt-option", {
        key: item.value,
        attrs: { label: item.label, value: item.value },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }