export default {
  data() {
    return {
      // 默认分类，显示是前端自己组装的，root这个id是后端可识别的
      ROOT_CATALOG_ID: 'root',
      CATALOG_TYPE: 0 // 0 表示题库，type: 1 表示试卷
    };
  },
  methods: {
    // 增加最外层默认分类，入参是该根分类下的所有子分类
    wrapRootCatalog(data) {
      return [{
        id: this.ROOT_CATALOG_ID,
        name: this.ROOT_CATALOG_NAME, // 保持和后端数据一致
        label: this.ROOT_CATALOG_NAME, // tree组件识别
        children: data || []
      }];
    },
    initCatalogList(data) {
      return this.setCatalogLabel([data]); // this.wrapRootCatalog(getTreeData(data, ['id', 'name', 'parentId']))
    },
    setCatalogLabel(list) {
      list && list.length > 0 && list.forEach(element => {
        element.label = element.name;
        this.setCatalogLabel(element.children);
      });
      return list;
    },
    // 设置分类值
    setCatalogType(type) {
      this.CATALOG_TYPE = type;
    }
  },
  computed: {
    // 用计算值
    ROOT_CATALOG_NAME() {
      return this.CATALOG_TYPE === 0 ? this.$t('pc_biz_ote_lbl_defaultqueslibcatalog') : this.$t('pc_biz_ote_lbl_examcatalog');
    }
  }
};
