(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("yxt-biz-pc/packages/api"), require("YXTPC"), require("__hack_yxtbiz_I18n"), require("Vue"), require("axios"), require("__hack_yxtbiz_OpenData"), require("__hack_yxtbiz_Factor"), require("Vuex"));
	else if(typeof define === 'function' && define.amd)
		define([, "YXTPC", "__hack_yxtbiz_I18n", "Vue", "axios", "__hack_yxtbiz_OpenData", "__hack_yxtbiz_Factor", "Vuex"], factory);
	else if(typeof exports === 'object')
		exports["YXTBIZSELECTOR"] = factory(require("yxt-biz-pc/packages/api"), require("YXTPC"), require("__hack_yxtbiz_I18n"), require("Vue"), require("axios"), require("__hack_yxtbiz_OpenData"), require("__hack_yxtbiz_Factor"), require("Vuex"));
	else
		root["YXTBIZSELECTOR"] = factory(root["YXTBIZ"]["Api"], root["YXTPC"], root["__hack_yxtbiz_I18n"], root["Vue"], root["axios"], root["__hack_yxtbiz_OpenData"], root["__hack_yxtbiz_Factor"], root["Vuex"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__19__, __WEBPACK_EXTERNAL_MODULE__21__, __WEBPACK_EXTERNAL_MODULE__22__) {
return 