var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-question-selector" },
    [
      _c("div", { staticClass: "yxtbiz-question-selector__treetable" }, [
        _c(
          "div",
          { staticClass: "yxtbiz-question-selector__treetable-main" },
          [
            _c(
              "div",
              {
                staticClass:
                  "yxtbiz-question-flex mt32 yxtbiz-question-flex-wrap",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "yxtbiz-flex-shrink-0 yxtbiz-question-flex-grow mb16",
                  },
                  [
                    _vm.multimedia
                      ? _c("random-select", {
                          ref: "randomSelectRef",
                          staticClass: "d-in-block",
                          attrs: {
                            pointIds: _vm.pointIdsArr,
                            quesTypes: _vm.quesTypes,
                          },
                          on: { setQuestion: _vm.setQuestion },
                        })
                      : _vm._e(),
                    _c(
                      "yxt-button",
                      {
                        staticClass: "ml8",
                        attrs: { type: "second" },
                        on: { click: _vm.openSelectorDrawer },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "pc_biz_ote_lbl_choose_queslib_point" /* 选择题库/考点 */
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "yxtbiz-question-flex yxtbiz-question-flex-wrap mb16",
                  },
                  [
                    _c(
                      "yxt-select",
                      {
                        staticClass: "mr8 width144",
                        attrs: {
                          clearable: "",
                          size: "small",
                          placeholder: _vm.$t("pc_biz_ote_lbl_questiontype"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.filterAllQues(true)
                          },
                        },
                        model: {
                          value: _vm.quesSearchData._questionType,
                          callback: function ($$v) {
                            _vm.$set(_vm.quesSearchData, "_questionType", $$v)
                          },
                          expression: "quesSearchData._questionType",
                        },
                      },
                      _vm._l(_vm.typeList, function (item) {
                        return _c("yxt-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                    _c(
                      "yxt-select",
                      {
                        staticClass: "mr8 width144",
                        attrs: {
                          clearable: "",
                          size: "small",
                          placeholder: _vm.$t("pc_biz_ote_lbl_queslevel"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.filterAllQues(true)
                          },
                        },
                        model: {
                          value: _vm.quesSearchData._levelType,
                          callback: function ($$v) {
                            _vm.$set(_vm.quesSearchData, "_levelType", $$v)
                          },
                          expression: "quesSearchData._levelType",
                        },
                      },
                      _vm._l(_vm.levelTypeList, function (item) {
                        return _c("yxt-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                    _c("yxt-input", {
                      staticClass: "width240",
                      attrs: {
                        searchable: "",
                        size: "small",
                        placeholder: _vm.$t("pc_biz_ote_lbl_searchques"),
                      },
                      on: {
                        search: function ($event) {
                          return _vm.filterAllQues(true)
                        },
                      },
                      model: {
                        value: _vm.quesSearchData.keyWord,
                        callback: function ($$v) {
                          _vm.$set(_vm.quesSearchData, "keyWord", $$v)
                        },
                        expression: "quesSearchData.keyWord",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "yxt-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "examTable",
                attrs: { data: _vm.selectTableList, height: _vm.tableHeight },
                on: {
                  "select-all": _vm.quesSelectedOprateAll,
                  select: _vm.selectionChange,
                },
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "empty" }, slot: "empty" },
                  [
                    _c("yxt-empty", [
                      _c("span", { attrs: { slot: "text" }, slot: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "pc_biz_ote_lbl_tips_queslib_point" /* 请选择题库或考点 */
                            )
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mt16" },
                        [
                          _c(
                            "yxt-button",
                            {
                              attrs: { type: "second" },
                              on: { click: _vm.openSelectorDrawer },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pc_biz_ote_lbl_go_select" /* 去选择 */
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c("yxt-table-column", {
                  attrs: {
                    type: "selection",
                    "show-overflow-tooltip": false,
                    width: "38",
                    align: "right",
                    selectable: _vm.checkRowSelectable,
                    "class-name":
                      "yxtbiz-question-selector__treetable-checkbox",
                    "clear-padding": "left-right",
                  },
                }),
                _c("yxt-table-column", {
                  attrs: {
                    "show-overflow-tooltip": false,
                    label: _vm.$t("pc_biz_ote_lbl_quesname"),
                    prop: "summary",
                    "min-width": "170",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.summary
                            ? _c(
                                "div",
                                { staticClass: "yxtbiz-table-row" },
                                [
                                  _c("audio-video-icon", {
                                    staticClass: "mr5 vertical-align-middle",
                                    attrs: { type: scope.row.quesType },
                                  }),
                                  _c(
                                    "yxt-tooltip",
                                    {
                                      staticClass:
                                        "yxtbiz-table-row__name color-primary-6 hand",
                                      attrs: {
                                        "open-filter": "",
                                        "max-width": 300,
                                        effect: "dark",
                                        content: scope.row.summary,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ellipsis",
                                          class: {
                                            "color-bf cursor-normal":
                                              !_vm.checkRowSelectable(
                                                scope.row
                                              ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.oprate(scope.row, 2)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(scope.row.summary) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !scope.row.summary
                            ? _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.oprate(scope.row, 2)
                                    },
                                  },
                                },
                                [
                                  _c("audio-video-icon", {
                                    staticClass: "mr5 vertical-align-middle",
                                    attrs: { type: scope.row.quesType },
                                  }),
                                  _c("yxt-svg", {
                                    staticClass: "hand vertical-align-middle",
                                    attrs: {
                                      "remote-url": _vm.svgRemote,
                                      "icon-class": "ques-image",
                                      width: "20px",
                                      height: "20px",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "font-size-12 color-bf" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.isNull(scope.row.libName)) +
                                "\n            "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("yxt-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    label: _vm.$t("pc_biz_ote_lbl_assessmentpoint"),
                    prop: "pointNames",
                    width: _vm.$isChinese ? "150" : "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: {
                                "color-bf": !_vm.checkRowSelectable(scope.row),
                              },
                            },
                            [_vm._v(_vm._s(scope.row.pointNames))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("yxt-table-column", {
                  attrs: {
                    "show-overflow-tooltip": false,
                    label: _vm.$t("pc_biz_ote_lbl_questiontype"),
                    prop: "type",
                    width: _vm.$isChinese ? "90" : "190",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: {
                                "color-bf": !_vm.checkRowSelectable(scope.row),
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.getQuestionType(scope.row.type))
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("yxt-table-column", {
                  attrs: {
                    "show-overflow-tooltip": false,
                    label: _vm.$t("pc_biz_ote_lbl_queslevel"),
                    prop: "levelType",
                    width: _vm.$isChinese ? "80" : "120",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: {
                                "color-bf": !_vm.checkRowSelectable(scope.row),
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getQuestionLevalType(scope.row.levelType)
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c(
                  "yxt-row",
                  {
                    staticClass:
                      "mt16 text-right yxtbiz-question-selector__pager",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "yxtbiz-question-selector__pager-left" },
                      [
                        _c(
                          "yxt-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.selectTableList &&
                                  _vm.selectTableList.length,
                                expression:
                                  "selectTableList && selectTableList.length",
                              },
                            ],
                            attrs: {
                              loading: _vm.loadingAll,
                              type: "text",
                              size: "large",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addAllBasketData()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("pc_biz_ote_btn_allselect")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "yxtbiz-question-selector__pager-right" },
                      [
                        _c("yxt-pagination", {
                          staticClass: "pull-right",
                          attrs: {
                            "page-size": _vm.pageSize,
                            total: _vm.selectTableListCount,
                            "current-page": _vm.currentPage,
                            "simple-total": "",
                            layout: "total, prev, pager, next",
                          },
                          on: { "current-change": _vm.handleCurrentChange },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "yxtbiz-question-selector__basket" }, [
          _c(
            "span",
            {
              staticClass: "yxtbiz-question-selector__basket-open",
              on: {
                click: function ($event) {
                  return _vm.openBasketBox()
                },
              },
            },
            [
              _c("yxt-svg", {
                attrs: {
                  "remote-url": _vm.svgRemote,
                  "icon-class": "exam-closeBacket",
                  width: "13px",
                  height: "10px",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "vertical-text hand",
              on: { click: _vm.openBasketBox },
            },
            [
              _c("div", { staticClass: "ml12" }, [
                _c(
                  "span",
                  { staticClass: "color-gray-8" },
                  [
                    _c(
                      "yxt-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          "max-width": 300,
                          content: _vm.$t("pc_biz_ote_lbl_quesbasket"),
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "yxt-badge",
                          {
                            staticClass: "item",
                            attrs: {
                              max: 999,
                              value: _vm.basketBoxData.selectData.length,
                              top: "-4px",
                            },
                          },
                          [
                            _c("yxt-svg", {
                              attrs: {
                                "remote-url": _vm.svgRemote,
                                "icon-class": "ques-basket",
                                width: "16px",
                                height: "16px",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "yxt-drawer",
        {
          attrs: {
            size: "640px",
            title: _vm.$t("pc_biz_ote_lbl_quesbasket"),
            wrapperClosable: true,
            "append-to-body": true,
            "before-close": _vm.handleClose,
            visible: _vm.basketBoxData.isShow,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.basketBoxData, "isShow", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "yxtbiz-question-basket" }, [
            _c("div", { staticClass: "lh20 mb16" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("pc_biz_ote_lbl_chosenquestions")) +
                  "：\n        " +
                  _vm._s(_vm.basketBoxData.selectData.length) +
                  _vm._s(_vm.$t("pc_biz_ote_lbl_questi")) +
                  "\n      "
              ),
            ]),
            _c(
              "div",
              { staticClass: "mb16" },
              [
                _c(
                  "yxt-button",
                  {
                    attrs: { plain: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.batchRemoveBasketData.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("pc_biz_ote_btn_batchremove")) +
                        "\n        "
                    ),
                  ]
                ),
                _c(
                  "yxt-button",
                  {
                    attrs: { plain: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.removeAllBasketData.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("pc_biz_ote_lbl_clearall")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {},
              [
                _c(
                  "yxt-select",
                  {
                    staticClass: "mr12 mb16 width144",
                    attrs: {
                      size: "small",
                      clearable: "",
                      placeholder: _vm.$t("pc_biz_ote_lbl_questiontype"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.getBasketSelectData()
                      },
                    },
                    model: {
                      value: _vm.basketBoxData.searchData._questionType,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.basketBoxData.searchData,
                          "_questionType",
                          $$v
                        )
                      },
                      expression: "basketBoxData.searchData._questionType",
                    },
                  },
                  _vm._l(_vm.typeList, function (item) {
                    return _c("yxt-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
                _c(
                  "yxt-select",
                  {
                    staticClass: "mr12 mb16 width144",
                    attrs: {
                      size: "small",
                      clearable: "",
                      placeholder: _vm.$t("pc_biz_ote_lbl_queslevel"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.getBasketSelectData()
                      },
                    },
                    model: {
                      value: _vm.basketBoxData.searchData._levelType,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.basketBoxData.searchData,
                          "_levelType",
                          $$v
                        )
                      },
                      expression: "basketBoxData.searchData._levelType",
                    },
                  },
                  _vm._l(_vm.levelTypeList, function (item) {
                    return _c("yxt-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
                _c("yxt-input", {
                  staticClass: "mb16 width200",
                  attrs: {
                    size: "small",
                    searchable: "",
                    placeholder: _vm.$t("pc_biz_ote_lbl_searchques"),
                  },
                  on: { search: _vm.filterSelectedQues },
                  model: {
                    value: _vm.basketBoxData.searchData.keyWord,
                    callback: function ($$v) {
                      _vm.$set(_vm.basketBoxData.searchData, "keyWord", $$v)
                    },
                    expression: "basketBoxData.searchData.keyWord",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {},
              [
                _c(
                  "yxt-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticClass: "clazz_width_100",
                    attrs: {
                      data: _vm.basketBoxData.currentSelectData,
                      "default-sort": {
                        prop: "updateTime",
                        order: "descending",
                      },
                    },
                    on: { "selection-change": _vm.basketBoxSelectionChange },
                  },
                  [
                    _c("yxt-table-column", {
                      attrs: {
                        type: "selection",
                        "show-overflow-tooltip": false,
                        width: "38",
                        align: "right",
                        "clear-padding": "left-right",
                      },
                    }),
                    _c("yxt-table-column", {
                      attrs: {
                        label: _vm.$t("pc_biz_ote_lbl_quesname"),
                        prop: "summary",
                        "show-overflow-tooltip": false,
                        "min-width": "300px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.summary
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "yxtbiz-table-row",
                                      on: {
                                        click: function ($event) {
                                          return _vm.oprate(scope.row, 2)
                                        },
                                      },
                                    },
                                    [
                                      _c("audio-video-icon", {
                                        staticClass:
                                          "mr5 vertical-align-middle",
                                        attrs: { type: scope.row.quesType },
                                      }),
                                      _c(
                                        "yxt-tooltip",
                                        {
                                          staticClass:
                                            "yxtbiz-table-row__name color-primary-6 hand",
                                          attrs: {
                                            "open-filter": "",
                                            "max-width": 300,
                                            effect: "dark",
                                            content: scope.row.summary,
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "ellipsis" },
                                            [_vm._v(_vm._s(scope.row.summary))]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !scope.row.summary
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.oprate(scope.row, 2)
                                        },
                                      },
                                    },
                                    [
                                      _c("audio-video-icon", {
                                        staticClass:
                                          "mr5 vertical-align-middle",
                                        attrs: { type: scope.row.quesType },
                                      }),
                                      _c("yxt-svg", {
                                        staticClass:
                                          "hand vertical-align-middle",
                                        attrs: {
                                          "remote-url": _vm.svgRemote,
                                          "icon-class": "ques-image",
                                          width: "20px",
                                          height: "20px",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "font-size-12 color-bf" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.isNull(scope.row.libName)) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("yxt-table-column", {
                      attrs: {
                        label: _vm.$t("pc_biz_ote_btn_operate"),
                        align: "left",
                        width: "80",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "yxt-link",
                                {
                                  attrs: {
                                    underline: false,
                                    type:
                                      scope.row.status != 2
                                        ? "text"
                                        : "primary",
                                    disabled: scope.row.status != 2,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.quesSelectedOprate(
                                        scope.row,
                                        1
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("pc_biz_ote_btn_delete")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c(
                      "yxt-row",
                      { staticClass: "mt16 text-right lh30" },
                      [
                        _c("yxt-pagination", {
                          staticClass: "pull-right",
                          attrs: {
                            "page-size": _vm.basketBoxData.searchData.limit,
                            total: _vm.basketBoxData.filterData.length,
                            "current-page":
                              _vm.basketBoxData.searchData.current,
                            layout: "total, prev, pager, next",
                          },
                          on: { "current-change": _vm.quesHandleCurrentChange },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "yxt-drawer",
        {
          ref: "quesViewDrawer",
          attrs: {
            size: "640px",
            title: _vm.$t("pc_biz_ote_lbl_quesview"),
            visible: _vm.quesDetailData.dialogVisible,
            "before-close": _vm.handleClose,
            destroyOnClose: true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.quesDetailData, "dialogVisible", $event)
            },
          },
        },
        [_c("ques-view", { attrs: { id: _vm.quesDetailData.id } })],
        1
      ),
      _c(
        "yxt-drawer",
        {
          attrs: {
            size: "480px",
            title: _vm.$t("pc_biz_ote_lbl_choose_queslib_point"),
            visible: _vm.quesLeftDrawer.visible,
            "before-close": _vm.handleClose,
            destroyOnClose: true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.quesLeftDrawer, "visible", $event)
            },
          },
        },
        [
          _c("ques-selector-left", {
            ref: "selectorLeft",
            attrs: { functionCode: _vm.functionCode },
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "yxt-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.quesLeftDrawer.visible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_ote_btn_cancle" /* 取消 */)))]
              ),
              _c(
                "yxt-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmLeftPoints },
                },
                [_vm._v(_vm._s(_vm.$t("pc_biz_ote_btn_confirm" /* 确定 */)))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }