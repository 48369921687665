var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.options.length > 1
    ? _c(
        "div",
        [
          _c(
            "label",
            { staticClass: "font-size-14 color-gray-10" },
            [_vm._t("default")],
            2
          ),
          _c(
            "yxt-select",
            {
              style: _vm.style,
              attrs: { size: _vm.size },
              on: { change: _vm.change },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("yxt-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }