var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowSearch
    ? _c(
        "div",
        {
          staticClass: "yxtbiz-select-catelog",
          staticStyle: { height: "100%" },
        },
        [
          _vm.isShowSearch
            ? _c("yxt-input", {
                attrs: {
                  placeholder: _vm.$t(
                    "pc_biz_kng_lbl_search_category_name" /* 搜索分类名称 */
                  ),
                  maxlength: "20",
                  searchable: "",
                },
                on: { search: _vm.searchCatalog },
                model: {
                  value: _vm.catalogName,
                  callback: function ($$v) {
                    _vm.catalogName = $$v
                  },
                  expression: "catalogName",
                },
              })
            : _vm._e(),
          _c("yxt-tree", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.treeLoading,
                expression: "treeLoading",
              },
            ],
            ref: "kngTree",
            staticClass: "select-kng-catalog__calc-height mt16",
            attrs: {
              data: _vm.kngTreeData,
              props: _vm.defaultProps,
              "wrap-width": _vm.wrapWidth,
              "current-node-key": _vm.currentId,
              "node-key": "id",
              "filter-node-method": _vm.filterCatalogNode,
              "default-checked-keys": _vm.checkLists,
              "default-expanded-keys": _vm.expandCatalog,
              "check-strictly": _vm.checkStrictly,
              "highlight-current": !_vm.isNeedCheck,
              "show-checkbox": _vm.isNeedCheck,
            },
            on: {
              "node-click": _vm.nodeClick,
              "current-change": _vm.currentNodeChange,
            },
          }),
        ],
        1
      )
    : _c("yxt-tree", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.treeLoading,
            expression: "treeLoading",
          },
        ],
        ref: "kngTree",
        staticClass: "select-kng-catalog mt16",
        attrs: {
          data: _vm.kngTreeData,
          props: _vm.defaultProps,
          "wrap-width": _vm.wrapWidth,
          "current-node-key": _vm.currentId,
          "node-key": "id",
          "filter-node-method": _vm.filterCatalogNode,
          "default-checked-keys": _vm.checkLists,
          "default-expanded-keys": _vm.expandCatalog,
          "check-strictly": _vm.checkStrictly,
          "highlight-current": !_vm.isNeedCheck,
          "show-checkbox": _vm.isNeedCheck,
        },
        on: {
          "node-click": _vm.nodeClick,
          "current-change": _vm.currentNodeChange,
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }