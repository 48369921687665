<template>
  <span class="yxtbiz-base-tree-slot">
    <yxt-tooltip
      placement="top-start"
      :max-width="500"
    >
      <span slot="content"><yxtbiz-dept-name :name="node.label" /></span>
      <span><slot :data="data" :node="node">{{ node.label }}</slot></span>
    </yxt-tooltip>
    <span class="tree-tools" @click.stop>
      <yxt-dropdown
        v-if="functions && funs.length > 0"
        custom-elm
        :dropdownToBody="dropdownToBody"
        @visible-change="visibleChange"
      >
        <span class="color-gray-6 hover-primary-6 yxtbiz-base-tree-slot-icon"
          ><yxt-svg width="16px" height="16px" icon-class="set"
        /></span>
        <yxt-dropdown-menu slot="dropdown">
          <yxt-dropdown-item
            v-for="(funsItem, index) in funs"
            :key="index"
            :disabled="funsItem.disabled || false"
            @click.native="dropdownItemHandle(funsItem)"
          >
            <span
              ><span>{{ funsItem.label }}</span></span
            >
          </yxt-dropdown-item>
        </yxt-dropdown-menu>
      </yxt-dropdown>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    node: {
      type: Object,
      default: () => {
        return {};
      }
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    functionsRender: {
      type: Function,
      default: () => []
    },
    functions: {
      type: Boolean,
      default: true
    },
    placement: {},
    settingIcon: {
      default: 'yxt-icon-setting'
    },
    dropdownToBody: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      funs: []
    };
  },
  created() {
    this.showDropdown();
  },
  methods: {
    dropdownItemHandle(funsNode) {
      if (funsNode.disabled) return;
      if (funsNode.handler && typeof funsNode.handler === 'function') {
        funsNode.handler(this.node, this.data);
      }
    },
    visibleChange(bool) {
      if (bool) {
        this.showDropdown();
      }
    },
    showDropdown() {
      const funsRender = this.functionsRender(this.node, this.data);
      if (funsRender && funsRender.length) {
        this.funs = funsRender;
      }
    }
  },
  computed: {},
  mounted() {},
  watch: {}
};
</script>
<style lang="scss" scoped>
.yxtbiz-base-tree-slot {
  display: flex;
  align-items: center;
  .tree-tools {
    display: flex;
    align-items: center;
  }
  .yxtbiz-base-tree-slot-icon {
    display: flex;
    align-items: center;
  }
}
</style>
