import { oteApi } from 'yxt-biz-pc/packages/api';

// 获取试卷列表
export const getExamList = (query) => {
  return oteApi.get('/exam/list/select', { params: query });
};

// 获取试卷分类列表
export const getCatalogList = (query) => {
  return oteApi.get('/exam/catalog/list/select', { params: query });
};

// 获取试卷列表
export const getExamListOG = (query) => {
  return oteApi.get('/exam/list/published', { params: query });
};

// 获取试卷分类列表
export const getCatalogListOG = () => {
  return oteApi.get('/exam/catalog/list/all');
};
