<template>
  <div v-loading="!isLoad" class="yxtbiz-question-preview">
    <ques-view-info
      v-if="isLoad"
      :quesId="id"
      :quesBody="quesBody"
    ></ques-view-info>
    <div v-if="showUpdater" class="yxtbiz-question-preview__updater mt32">
      <div class="yxtbiz-question-preview__updater-left">
        {{ $t('pc_biz_ote_lbl_updater' /* 更新人 */) }}
      </div>
      <div class="yxtbiz-question-preview__updater-right">
        <yxtbiz-user-name :name="quesBody.updateFullname" />
      </div>
    </div>
    <div v-if="showUpdater" class="yxtbiz-question-preview__updater mt16">
      <div class="yxtbiz-question-preview__updater-left">
        {{ $t('pc_biz_ote_lbl_updatetime' /* 更新日期 */) }}
      </div>
      <div class="yxtbiz-question-preview__updater-right">
        {{ dateToString(quesBody.updateTime, 'yyyy-MM-dd HH:mm:ss') }}
      </div>
    </div>
  </div>
</template>
<script>
import { getQuesDetail } from './service.js';
import quesViewInfo from './preview/quesViewInfo';
import { handleError, dateToString } from './utils';
// import ueImagePreview from './ueImagePreview';
export default {
  components: {
    quesViewInfo
  },
  // mixins: [ueImagePreview],
  name: 'YxtbizQuestionPreview',
  data() {
    return {
      correctAnswer: 0,
      correctAnswers: [],
      pointNames: '',
      quesBody: {
        type: -1, // 题型 0-单选题 1-多选题 2-判断题 3-填空题 4-问答题
        pointIds: [], // 考核点ids
        points: [], // 考核点id name
        levelType: -1, // 难度
        content: '', // 题干（富文本）
        summary: '', // 题干摘要（文本）
        answerContent: '', // 问答题正确答案
        judgeAnswer: -1, // 判断题正确答案
        explainText: '', // 解析（富文本）
        questionItems: [], // 答题项/选项
        fillItems: [], // 填空题答案
        keywords: [], // 关键字（仅问答题）
        status: 0
      },
      radio: 3,
      showExplain: true,
      nowrapHeight: 66,
      isShowMore: false,
      isShowMoreText: false,
      isLoad: false
    };
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    quesInfo: {
      type: Object,
      default: null
    },
    showUpdater: {
      type: Boolean,
      default: true
    }
  },
  created() {
    if (!this.quesInfo) {
      this.getDetail();
    } else {
      this.quesBody = this.quesInfo;
      this.isLoad = true;
    }
  },
  methods: {
    handleError,
    dateToString,
    // 获取试题详情
    getDetail() {
      if (this.id) {
        getQuesDetail(this.id)
          .then(res => {
            this.quesBody = res;
            this.isLoad = true;
          })
          .catch(this.handleError.bind(this));
      }
    }
  }
};
</script>
