<template>
  <div ref="currentEle" class="yxtbiz-dept-tree" :class="{'yxtbiz-dept-tree--autosize': autosize}" v-loading="loading">
    <yxt-row :type='selectorAlign ? "" : "flex"'>
      <yxtbiz-group-org-select
        v-if='enableGroupCorp && visibleOrgSelector'
        @change='handleOrgChange'
        :selectFirst='true'
        :setCurrentOrg="setCurrentOrg"
        :functionCode="functionCode"
        :dataPermissionCode="dataPermissionCode"
        :clearable="false"
        :targetOrgId="targetOrgId"
        :visibleOrgIds="visibleOrgIds"
        :disabled="disabled"
        :size="size"
        class='mr12 mb12'
        :style='selectorAlign ? "width: 100%" : ""'
      />
      <yxt-input
        class="yxtbiz-dept-tree__input"
        v-if="filterable && !isOpenData"
        ref="input"
        :placeholder="placeholder"
        v-model="filterText"
        :size="size"
        :style="{width: inputW}"
        @search="filter"
        searchable
      >
      </yxt-input>
    </yxt-row>
    <yxtbiz-funs-tree
      ref="tree"
      :props="props"
      :highlight-current="highlightCurrent"
      :node-key="nodeKey"
      :check-strictly="checkStrictly"
      :show-checkbox="multiple && showCheckbox"
      :default-expanded-keys="expandTempShow"
      @node-click="nodeClick"
      :functions="multiple && functions"
      :wrap-width="wrapWidth"
      :functionsRender="functionsRenderMid"
      @node-expand="nodeExpand"
      @node-collapse="nodeCollapse"
      :filter-node-method="filterNode"
      @check="handlerCheck"
      @check-change="handleCheckChange"
      count
      :native="native"
      :placement="functionsPlacement"
      :default-checked-keys="defaultCheckedKeys"
      :dropdownToBody="dropdownToBody"
      :virtual="virtual"
      :keeps="50"
      :buffer="10"
    >
      <template
        slot-scope="{data, node}">
        <span class="yxtbiz-dept-tree-radio" v-if="!multiple && data.hasAllDeptPermission">
         <yxt-radio v-model="deptTreeSingle" :label="data.id"></yxt-radio>
        </span>
        <yxtbiz-dept-name :name="node.label"></yxtbiz-dept-name>
        <span v-if="core === 'team'">{{(count && data.id && data.hasAllDeptPermission) ? `(${data.userCount})` : ''}}</span>
        <span v-else v-show="deptCountAvailable">{{(count && data.id) ? `(${data.userCount})` : ''}}</span>
      </template>
    </yxtbiz-funs-tree>
  </div>
</template>

<script>
import { getDeptTree, getTeamDept } from './service';
import treeMixin from 'yxt-biz-pc/src/mixins/manualTree';
import { deepCopy } from 'yxt-biz-pc/src/utils/tree';
import YxtBizDeptName from 'yxt-biz-pc/packages/dept-name';
import { isOpenData } from 'yxt-biz-pc/src/utils/shared';
import { i18n } from '../../common-util/changeLangs';

const DEPT_API = {
  common: getDeptTree, // 普通部门树接口
  team: getTeamDept // 团队部门树接口
};

export default {
  name: 'YxtbizDeptTreeV1',
  components: {
    YxtBizDeptName
  },
  mixins: [treeMixin],
  props: {
    childrenIncluded: {
      type: [Boolean, Number],
      default: false
    },
    placeholder: {
      type: String,
      default: function() {
        return i18n.t('biz_udp_deptmentname');
      }
    },
    multiple: {
      type: Boolean,
      default: true
    },
    leafFilterLevel: {
      type: Number
    },
    openSelectSiblingNode: {
      // 是否开启选同级部门
      type: Boolean,
      default: false
    },
    defaultExpandedKeys: {
      type: Array,
      default: () => {
        return [];
      }
    },
    defaultExpandedRoot: {
      type: Boolean,
      default: true
    },
    scope: {
      default: '0'
    },
    customDataHandler: {
      type: Function
    },
    virtual: {
      type: Boolean,
      default: true
    },
    inputWidth: {
      type: Number
    },
    isHalfStrictly: {
      type: Boolean,
      default: true
    },
    checkedLinkage: { // 勾选父部门, 联动子部门及后续新增子部门 1 联动
      type: String,
      default: '1'
    },
    list: {
      type: Array,
      default: () => []
    },
    enableGroupCorp: {
      type: Boolean,
      default: false
    },
    visibleOrgSelector: {
      type: Boolean,
      default: true
    },
    targetOrgId: {
      type: String | Number
    },
    visibleOrgIds: {
      type: Array,
      default: () => []
    },
    selectorAlign: {
      type: Boolean,
      default: false
    },
    setCurrentOrg: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    nodeKey: {
      // node节点唯一标识的属性
      type: String,
      default: 'distinctId'
    },
    teamId: {
      // 团队部门组件的团队id
      type: String,
      default: ''
    },
    core: {
      // 部门树的数据来源，common 表示通用部门数据，team 表示团队的部门数据
      type: String,
      default: 'common'
    }
  },
  watch: {
    targetOrgId(value) {
      this.currentOrgId = value;
      this.dataInit();
    },
    checkedLinkage(value) {
    }
  },
  computed: {
    inputW() {
      if (this.inputWidth) {
        return this.inputWidth + 'px';
      }
      return isNaN(this.wrapWidth) ? this.wrapWidth : this.wrapWidth + 'px';
    }
  },
  data() {
    return {
      deptTreeSingle: '', // 单选
      currentOrgId: '',
      idDistinctIdMap: {},
      distinctIdIdMap: {},
      rootId: '',
      isCheckChangedHandlerMap: {}, // 在勾选的的时候保存该节点是否处理过的对象
      selectAllLoading: false, // 选择全部的loading
      isOpenData: isOpenData(),
      deptCountAvailable: false, // 是否展示部门数量
      teamQueryParams: {} // 为团队部门组件时所需要的参数
    };
  },
  created() {
    this.currentOrgId = this.targetOrgId;
    this.props = {...this.props, ...this.treeProps};
    if (this.core === 'team') {
      this.teamQueryParams = {
        type: 3,
        teamId: this.teamId
      };
      if (this.teamId === 'lineManager') {
        this.teamQueryParams.type = 1;
      }
      if (this.teamId === 'deptManager') {
        this.teamQueryParams.type = 2;
      }
    }
  },
  methods: {
    getVersion() {
      // 当前树组件版本信息
      return 'v1';
    },
    nodeClick(...argu) {
      const [curr] = argu;
      this.deptTreeSingle = curr.id;
      this.$emit('nodeClick', ...argu);
    },
    handleOrgChange(data) {
      this.currentOrgId = data.orgId;
      this.filterText = '';
      this.dataInit();
      this.$emit('org-change', data);
    },
    AddOrgIdForTree(treeNodes) {
      for (const item of treeNodes) {
        if (item.children && item.children.length) {
          this.AddOrgIdForTree(item.children);
        } else {
          item.orgId = this.currentOrgId;
        }
      }
    },
    dataInit() {
      const apiName = this.core || 'common';
      DEPT_API[apiName]({ targetOrgId: this.currentOrgId, parentId: '', scope: this.scope, navCode: this.functionCode, dataPermissionCode: this.dataPermissionCode, ...this.teamQueryParams })
        .then(res => {
          this.deptCountAvailable = res.datas[0] && res.datas[0].deptCountAvailable;
          this.rootId = res.datas[0] ? (res.datas[0].distinctId || res.datas[0].id) : '';
          if (this.enableGroupCorp) {
            // tree数据加上orgId
            this.AddOrgIdForTree(res.datas);
          }
          this.setDistinctIdMap(res.datas[0]);
          this.handlerDataForTree(res.datas);
          this.$emit('inited');
          this.loading = false;
          this.filterText = '';
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clear() {
      this.setCheckedKeys([]);
      this.handlerDataForTree(this.originData);
    },
    clearNode() {
      this.clear();
      this.$nextTick(() => {
        this.$emit('clearNode');
      });
    },
    handlerDataForTree(datas) {
      if (this.customDataHandler) {
        this.originData = this.customDataHandler(datas) || datas;
      } else {
        this.originData = datas;
      }
      if (this.originData) {
        if (this.originData[0] && !this.originData[0].hasAllDeptPermission) {
          // 根路径没有权限，只做展示
          this.originData[0].nocheck = true;
        }
        this.data = this.leafFilterLevel ? this.getLeafFilterLevelData(this.originData) : this.originData;
        this.originData = deepCopy(this.originData);
        this.$refs.tree && this.$refs.tree.setHugeData(this.data);
        this.$nextTick(() => {
          if (this.defaultExpandedRoot) {
            this.expandTemp.push(this.rootId);
            this.expandTempShow = [...this.expandTemp];
          }
          if (this.$refs.tree) {
            if (this.setRootDefault) {
              this.$refs.tree.setCurrentKey(this.rootId);
              this.nodeClick(this.data[0], this.$refs.tree.getNode(this.rootId), this, true);
            }
            if (this.showCheckbox) {
              this.setDisabledList();
            }
          }
        });
      }
    },
    functionsRenderMid(node, data) {
      // if (this.checkedLinkage === '1') return [];
      let arr = [...this.functionsRender(node, data)];
      if (!this.childrenIncluded || !this.checkStrictly || !this.showCheckbox || (data.parent && data.parent.includeAll === 1)) {
        return arr;
      }
      if ((this.childrenIncluded === 1 || this.childrenIncluded === 3 || this.childrenIncluded === true) && data.hasAllDeptPermission) {
        arr.push({
          label: this.$t('biz_udp_deptment_subsequent'), // 当前及后续新增部门
          handler: this.setChildrenInclude,
          disabled: !!(node.parent && node.parent.data.includeAll)
        });
      }
      if (this.openSelectSiblingNode && data.parentId) {
        arr.push({
          label: this.$t('pc_biz_sibling_dept').d('当前同级部门'),
          handler: this.selectSiblingNode
        });
      }
      if (this.childrenIncluded === 2 || this.childrenIncluded === 3 || this.childrenIncluded === true) {
        arr.push({
          label: this.$t('biz_udp_all'),
          handler: this.setChildrenWithout,
          disabled: !!(node.parent && node.parent.data.includeAll)
        });
      }
      if (this.childrenIncluded !== 0 && data.hasAllDeptPermission) {
        arr.push({
          label: node.checked ? this.$t('biz_udp_cancel_dept') : this.$t('biz_udp_current_dept'),
          handler: this.setCheckedDeptId4Funs,
          disabled: !!(node.data.includeAll)
        });
      }
      if (this.childrenIncluded === 4 && data.hasAllDeptPermission) {
        arr = [];
        arr.push({
          label: this.$t('biz_udp_deptment_subsequent'), // 当前及后续新增部门
          handler: this.setChildrenInclude,
          disabled: !!(node.parent && node.parent.data.includeAll)
        });
      }
      if (node.key === this.rootId && !data.hasAllDeptPermission) {
        arr.push({
          label: this.$t('biz_udp_clear_selected'),
          handler: this.clearNode
        });
      }
      return arr;
    },
    selectSiblingNode(node, data) {
      // 选择同级部门
      const pData = this.$refs.tree.getNode(data.parentId);
      console.log(pData, '---------');
      if (pData && pData.childNodes.length) {
        pData.childNodes.forEach(childNode => {
          childNode.setChecked(true);
        });
        this.$emit('selectSiblingChange', pData.data.children);
      } else {
        // 说明是根节点
        if (data.nocheck) return; // 说明当前根节点没有选中权限，直接返回不做操作
        node.setChecked(true);
        this.$emit('selectSiblingChange', [data]);
      }
    },
    setChildrenInclude(node, data, includeAll = 1, f) {
      // 子节点中是否有虚拟部门联动的节点被选为后续全部
      if (this.checkHasVnodeIncluded(node)) {
        this.$message.error(this.$t('biz_udp_dept_locked'));
        return;
      }
      let datas = this.getDescendantList(node, includeAll);
      let userChecked = this.$refs.tree.getCheckedNodes();
      if (!f) {
        const setDatas = [...datas, ...userChecked];
        this.$refs.tree.setCheckedNodes(setDatas);
      } else {
        if (this.checkedLinkage === '1') {
          // 再开启了tree组件父子不联动的情况下，后续新增下，点击搜索过滤后并清除当前后续新增节点，
          // 再对搜索结果清空，由于组件父子不联动，导致搜索过滤后的节点被清除了，而其它的选中状态、禁用状态还在，因此需要做处理
          const { routingPath } = data;
          const treeData = [];
          const resetTreeData = userChecked.filter(item => {
            if (item.routingPath.includes(routingPath)) {
              item.disabled = false;
              return true;
            } else {
              treeData.push({ ...item });
              return false;
            }
          });
          this.$refs.tree.setCheckedNodes(resetTreeData);
          this.$refs.tree.setCheckedNodes(treeData); // 重新设置选中状态，只设置没被取消选中的
        }
      }
      console.log('setChildrenInclude', datas);
      this.$emit('includeChanged', node, data, 1);
    },
    setChildrenWithout(node, data) {
      // 子节点中是否有虚拟部门联动的节点被选为后续全部
      if (this.checkHasVnodeIncluded(node)) {
        this.$message.error(this.$t('biz_udp_dept_locked'));
        return;
      }
      let datas = this.getDescendantList(node, 0);
      let userChecked = this.$refs.tree.getCheckedNodes();
      this.$refs.tree.setCheckedNodes([...datas, ...userChecked]);
      this.$emit('includeChanged', node, data, 0);
    },
    checkHasVnodeIncluded(node) {
      if (node.key === this.rootId) {
        return false;
      }
      if (this.idDistinctIdMap[node.data.id]) {
        let keys = this.idDistinctIdMap[node.data.id];
        let vnodeIncluded = false;
        for (let i = 0; i < keys.length; i++) {
          let keyNode = this.$refs.tree.getNode(keys[i]);
          if (!keyNode || !keyNode.parent || keys[i] === node.data.distinctId) {
            continue;
          }
          if (keyNode.parent.data.includeAll === 1) {
            vnodeIncluded = true;
            break;
          }
        }
        // 如果是虚拟部门就不往下检查了
        return vnodeIncluded;
      }
      let childFlag = false;
      for (let i = 0; i < node.childNodes.length; i++) {
        childFlag = this.checkHasVnodeIncluded(node.childNodes[i]);
        if (childFlag) {
          break;
        }
      }
      return childFlag;
    },
    setChildrenIncludeByDeptId(deptId) {
      if (!this.checkStrictly || !this.showCheckbox || !this.childrenIncluded) {
        return;
      }
      let key = deptId;
      let distinctIds = this.idDistinctIdMap[deptId];
      if (distinctIds) {
        key = distinctIds[0];
      }
      let node = this.$refs.tree.getNode(key);
      if (node && node.data) this.setChildrenInclude(node, node.data);
    },
    // 新的设置后续选中的方法，可用于处理大数据量
    setChildrenIncludeByDeptIdV2(argu) {
      if (!this.checkStrictly || !this.showCheckbox || !this.childrenIncluded) {
        return;
      }
      const depts = argu.map(item => {
        let key = item.id;
        let distinctIds = this.idDistinctIdMap[item.id];
        if (distinctIds) {
          key = distinctIds[0];
        }
        let node = this.$refs.tree.getNode(key);
        return node || item;
      }).filter(node => {
        return node.data && !this.checkHasVnodeIncluded(node);
      });
      const setIncludeCheckeds = [];
      depts.forEach(node => {
        if (node.data.id !== this.rootId || node.data.hasAllDeptPermission) {
          if (this.idDistinctIdMap[node.data.id] && this.idDistinctIdMap[node.data.id].length > 0) {
            let vdeptIds = this.transIdsToKeys([node.data.id]);
            vdeptIds.forEach(vdeptId => {
              let vNode = this.$refs.tree.getNode(vdeptId);
              vNode.data.includeAll = 1;
              setIncludeCheckeds.push(vNode.data);
            });
          } else {
            node.data.includeAll = 1;
            setIncludeCheckeds.push(node.data);
          }
        }
        this.getChildRecuV2(node, setIncludeCheckeds);
        this.$emit('includeChanged', node, node.data, 1);
      });
      const userChecked = this.$refs.tree.getCheckedNodes();
      this.$refs.tree.setCheckedNodes([...setIncludeCheckeds, ...userChecked]);
    },
    getChildRecuV2(node, setIncludeCheckeds) {
      if (!node.childNodes) {
        return;
      }
      node.childNodes.forEach(n => {
        if (!n.visible) {
          return;
        }
        n.data.disabled = true;
        n.data.includeAll = 1;
        if (this.idDistinctIdMap[n.data.id] && this.idDistinctIdMap[n.data.id].length > 0) {
          let vdeptIds = this.transIdsToKeys([n.data.id]);
          vdeptIds.forEach(vdeptId => {
            let vNode = this.$refs.tree.getNode(vdeptId);
            if (!vNode) {
              return;
            }
            vNode.data.includeAll = 1;
            setIncludeCheckeds.push(vNode.data);
          });
        } else {
          setIncludeCheckeds.push(n.data);
        }
        if (n.childNodes.length > 0) {
          this.getChildRecuV2(n, setIncludeCheckeds);
        }
      });
    },
    setChildrenWithoutByDeptId(deptId) {
      if (!this.checkStrictly || !this.showCheckbox || !this.childrenIncluded) {
        return;
      }
      let distinctIds = this.idDistinctIdMap[deptId];
      if (!distinctIds) {
        return;
      }
      let node = this.$refs.tree.getNode(distinctIds[0]);
      this.setChildrenWithout(node, node.data);
    },
    // 设置下级所有节点的状态
    getDescendantList(node, includeAll = -1) {
      let res = [];
      if (!node.visible) {
        return;
      }
      if (node.data.id !== this.rootId || node.data.hasAllDeptPermission) {
        if (this.idDistinctIdMap[node.data.id] && this.idDistinctIdMap[node.data.id].length > 0) {
          let vdeptIds = this.transIdsToKeys([node.data.id]);
          vdeptIds.forEach(vdeptId => {
            let vNode = this.$refs.tree.getNode(vdeptId);
            if (includeAll === 1) {
              vNode.data.includeAll = includeAll;
            } else if (includeAll === 0) {
              vNode.data.includeAll = includeAll;
            }
            res.push(vNode.data);
          });
        } else {
          if (includeAll === 1) {
            node.data.includeAll = includeAll;
          } else if (includeAll === 0) {
            node.data.includeAll = includeAll;
          }
          res.push(node.data);
        }
      }
      this.getChildRecu(node, res, includeAll);
      return res;
    },
    getChildRecu(node, arr, includeAll) {
      if (!node.childNodes) {
        return;
      }
      node.childNodes.forEach(n => {
        if (!n.visible && this.checkedLinkage !== '1') {
          // 加一个逻辑，如果是后续新增的情况下，对搜索后的树结果进行勾选时，其未显示的子部门还是进行后续新增的逻辑处理并置灰不可选
          return;
        }
        if (includeAll === 1) {
          n.data.disabled = true;
          n.data.includeAll = includeAll;
        } else if (includeAll === 0) {
          n.data.disabled = false;
          n.data.includeAll = includeAll;
        }
        if (this.idDistinctIdMap[n.data.id] && this.idDistinctIdMap[n.data.id].length > 0) {
          let vdeptIds = this.transIdsToKeys([n.data.id]);
          vdeptIds.forEach(vdeptId => {
            let vNode = this.$refs.tree.getNode(vdeptId);
            if (!vNode) {
              return;
            }
            if (includeAll === 1) {
              vNode.data.includeAll = includeAll;
            } else if (includeAll === 0) {
              vNode.data.includeAll = includeAll;
            }
            arr.push(vNode.data);
          });
        } else {
          if (includeAll === 1) {
            node.data.includeAll = includeAll;
          } else if (includeAll === 0) {
            node.data.includeAll = includeAll;
          }
          arr.push(n.data);
        }
        if (n.childNodes.length > 0) {
          this.getChildRecu(n, arr, includeAll);
        }
      });
    },
    getLeafFilterLevelData(datas) {
      if (this.leafFilterLevel === 1) {
        datas[0].children.forEach(v => {
          v.children = [];
        });
        return datas;
      } else {
        return datas;
      }
    },
    setDistinctIdMap(nodeData) {
      if (!nodeData) {
        return;
      }
      if (!nodeData.distinctId) return;
      if ((nodeData.id !== nodeData.distinctId) && (nodeData.id || nodeData.distinctId)) {
        if (this.idDistinctIdMap[nodeData.id]) {
          this.idDistinctIdMap[nodeData.id] = this.idDistinctIdMap[nodeData.id].concat([nodeData.distinctId]);
        } else {
          this.idDistinctIdMap[nodeData.id] = [nodeData.id, nodeData.distinctId];
        }
        if (nodeData.distinctId) this.distinctIdIdMap[nodeData.distinctId] = nodeData.id;
        this.distinctIdIdMap[nodeData.id] = nodeData.id;
      }
      nodeData.children.forEach(v => {
        this.setDistinctIdMap(v);
      });
    },
    transIdsToKeys(ids) {
      let keys = [];
      ids.forEach(id => {
        if (this.idDistinctIdMap[id]) {
          keys.push(...this.idDistinctIdMap[id]);
        } else {
          keys.push(id);
        }
      });
      return keys;
    },
    transKeysToIds(keys) {
      let ids = {};
      keys.forEach(key => {
        if (this.distinctIdIdMap[key]) {
          if (!ids[this.distinctIdIdMap[key]]) {
            ids[this.distinctIdIdMap[key]] = this.distinctIdIdMap[key];
          }
        } else {
          ids[key] = key;
        }
      });
      let res = [];
      Object.keys(ids).forEach((id) => {
        res.push(id);
      });
      return res;
    },
    setDisabledList() {
      if (!this.showCheckbox) {
        return;
      }
      if (this.defaultDisabled.length === 0) {
        return;
      }
      this.defaultDisabledKeys = this.transIdsToKeys(this.defaultDisabled);
      this.defaultDisabledKeys.forEach(v => {
        this.setDisabled(v);
      });
      if (this.needUpdateDisabled) {
        // fixme 性能
        this.$set(this.data, 0, {...this.data[0]});
      }
    },
    getCheckedDeptIds(...argu) {
      return this.transKeysToIds(this.$refs.tree.getCheckedKeys(...argu));
    },
    setCheckedDeptIds(...argu) {
      return this.$refs.tree.setCheckedKeys(this.transIdsToKeys(argu[0]), argu[1]);
    },
    setSingleCheckedDeptId(node) {
      // 设置单选
      if (node && node.id) {
        this.deptTreeSingle = node.id;
      } else {
        this.deptTreeSingle = '';
      }
    },
    setCheckedDeptId4Funs(node) {
      let keys = this.transIdsToKeys([node.data.id]);
      keys.forEach(key => {
        this.$refs.tree.setChecked(key, !node.checked);
      });
      this.$emit('check', node.data, node, null);
    },
    getCurrentDeptId(...argu) {
      let currentKey = this.$refs.tree.getCurrentKey(...argu);
      return currentKey ? this.transKeysToIds([currentKey]) : currentKey;
    },
    handleCheckChange(...argu) {
      this.$emit('checkChange', ...argu);
    },
    handlerCheck(...argu) {
      // 检查变更里是否有虚拟部门
      let node = this.$refs.tree.getNode(argu[0]);
      if ((this.childrenIncluded !== 1 && this.childrenIncluded !== 3 && this.childrenIncluded !== 4 && this.childrenIncluded !== true) || !node.data.hasAllDeptPermission) {
        // 这里通过 childrenIncluded 参数来确定勾选部门时是否采用后续新增模式
        this.checkedLinkage = null;
      }

      this.isCheckChangedHandlerMap = {};
      if (this.checkedLinkage === '1') {
        if (node.checked) {
          this.setChildrenInclude(node, node.data, node.checked ? 1 : 0, false);
        } else {
          this.handleVNodeInCheckChange(node, node.checked);
          this.setChildrenInclude(node, node.data, 0, true);
          this.$emit('check', ...argu);
        }
      } else {
        this.handleVNodeInCheckChange(node, node.checked);
        this.setChildrenInclude(node, node.data, 0, true);
        this.$emit('check', ...argu);
      }
    },
    // 递归设置check不包括自己
    setNodeChildrenCheckedExcludeSelf(node, status) {
      if (!node.visible) {
        return;
      }
      if (node.childNodes.length) {
        node.childNodes.forEach(childNode => {
          childNode.setChecked(status);
          this.setNodeChildrenCheckedExcludeSelf(childNode, status);
        });
      }
    },
    // 点击check的时候要同步修改下级中所有的虚拟部门
    // 2020.04.23 应仝玉虎强烈要求，在checkStrictly时要同步修改子级选中状态
    handleVNodeInCheckChange(node, checked) {
      if (this.isCheckChangedHandlerMap[node.data.id]) {
        // 防重复处理
        return;
      }
      if (!node.visible) {
        return;
      }
      this.isCheckChangedHandlerMap[node.data.id] = true;
      if (this.idDistinctIdMap[node.data.id]) {
        this.syncVNodeCheck(node.data.id, checked);
      }
      if (this.checkStrictly) {
        // return;
        node.setChecked(checked);
      }
      this.isHalfStrictly && node.childNodes.forEach(child => {
        this.handleVNodeInCheckChange(child, checked);
      });
    },
    // 同步虚拟部门勾选
    syncVNodeCheck(id, status) {
      let distinctIds = this.idDistinctIdMap[id];
      distinctIds.forEach(distinctId => {
        this.$refs.tree.setChecked(distinctId, status, false);
      });
    },
    // 获取选中的部门节点（不含虚拟部门节点）
    getCheckedDeptNodes() {
      return this.$refs.tree.getCheckedNodes().filter(data => {
        if (data.distinctId) return data.distinctId === data.id;
        return data.id;
      });
    },
    // 取消筛选后所有结果的选中状态
    cancelFilterChecked() {
      let node = this.$refs.tree.getNode(this.rootId);
      this.setNodeChildrenChecked(node, 0);
      this.$emit('clearNode', node, node.data);
    },
    // 递归设置check
    setNodeChildrenChecked(node, status) {
      if (!node.visible) {
        return;
      }
      node.setChecked(status);
      if (node.childNodes.length) {
        node.childNodes.forEach(childNode => {
          this.setNodeChildrenChecked(childNode, status);
        });
      }
    },
    filter() {
      const keyword = typeof this.filterText === 'string' ? this.filterText.trim() : '';
      this.filterNodeRoutingPaths = [];
      this.$refs.tree.filter(keyword);
    }
  },
  mounted() {
  }
};
</script>
<style lang="scss" scoped>
  .yxtbiz-dept-tree {
    height: 100%;
  }
  .yxtbiz-dept-tree-radio {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    width: 20px;
  }
</style>
