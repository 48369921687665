<template>
  <div>
    <yxt-dialog
      width="80%"
      custom-class="yxtbiz-file"
      :visible.sync="visiable"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      append-to-body
      @close="closeDialog">
      <upload-title slot="title" noBorder></upload-title>

      <!-- 具体信息 -->
      <div v-for="file in multipleUploadLists" :key="file.id">
        <yxt-row v-if="!file.isDelete" class="lh88 border-b-e9 ph24">
          <yxt-col :span="14">
            <yxt-row class="d-in-block v-mid" style="width: calc(100% - 38px);">
              <yxt-col :span="file.fileUploadErrorMsg.length > 0 ? 13 : 16" class="pr20 flex-align-center">
                <span :title="file.extension" class="v-mid hand" :class="kngType(file)"></span>
                <span v-show="file.isMutliUploading" class="ml20 d-in-block ellipsis v-mid flex-1 no-wrap" :title="file.name">{{ file.name }}</span>
                <yxt-input v-show="!file.isMutliUploading"
                           :ref="'file' + file.id"
                           v-model.trim="file.name"
                           class="ml20 flex-1 v-mid"
                           :maxlength="50"
                           @blur="showInput(file)"></yxt-input>
                <i v-show="!file.isMutliUploading" class="yxt-icon-success v-mid ml15 font-size-18 d-in-block yxt-color-success"></i>
              </yxt-col>

              <yxt-col v-show="file.fileUploadErrorMsg.length > 0" :span="file.fileUploadErrorMsg.length > 0 ? 11 : 8" class="yxt-color-danger">
                <i class="yxt-icon-error v-mid mr10 font-size-18 d-in-block yxt-color-danger"></i>
                <span>{{ file.fileUploadErrorMsg }}</span>
              </yxt-col>
            </yxt-row>
          </yxt-col>
          <yxt-col v-show="file.fileUploadErrorMsg.length === 0" :span="7" class="font-size-12 color-gray-6">
            <div class="color-gray-6 d-in-block lh30">
              <span class="d-in-block w-64">{{ file.size }}</span>
              <yxt-progress v-if="file.isMutliUploading"
                            :percentage="file.percentage"
                            :stroke-width="4"
                            class="w-170 d-in-block ml10"></yxt-progress>
            </div>
          </yxt-col>
          <yxt-col :span="file.fileUploadErrorMsg.length === 0 ? 3 : 10" class="text-right">
            <span class="color-primary-6 hand" @click="deleteMutilFile(file)">删除</span>
          </yxt-col>
        </yxt-row>
      </div>
      <div slot="footer" class="lh34">
        <yxt-row>
          <yxt-col :span="5" class="color-gray-8 font-size-16 text-left">
            上传成功<span class="color-primary-6 font-size-16 pl5 pr5">{{ alreadyUploadFileNum }}</span>个文件
          </yxt-col>
          <yxt-col :span="9" class="text-center">
            <div v-show="!hideData">
              <i class="yui-speed-icon v-mid"></i>
              <span class="v-mid ml10 font-size-12 color-green-4">上传速度：{{ multipleUploadSpeed }}</span>
              <span class="v-mid ml15 font-size-12"><span class="ml10">剩余时间：{{ multipleRestTime }}</span></span>
            </div>
          </yxt-col>
          <yxt-col :span="!hideData ? 10 : 19" class="text-right">
            <yxt-button plain @click="closeDialog">取 消</yxt-button>
            <upload
              v-show="!disabled"
              ref="bizUpload"
              source="501"
              app-code="kng"
              config-key="KnowledgeConfigKey"
              module-name="knowledgefiles"
              function-name="files"
              :files-filter="multipleFilesFilter"
              :on-network-speed="multipleOnNetworkSpeed"
              :files-added="multipleFilesAdded"
              :on-progress="multipleOnProgress"
              :on-uploaded="multipleOnUploaded"
              :on-error="multipleOnError"
              multipe>
              <yxt-button plain>继续上传</yxt-button>
            </upload>
            <yxt-button type="primary" class="ml10" @click="submitAll">全部提交</yxt-button>
          </yxt-col>
        </yxt-row>
      </div>
    </yxt-dialog>
    <div v-if="!visiable" class="d-in-block hand">
      <upload
        ref="bizUpload"
        source="501"
        app-code="kng"
        config-key="KnowledgeConfigKey"
        module-name="knowledgefiles"
        function-name="files"
        :filters="filters"
        :files-filter="filesFilter"
        :file-filtered="fileFiltered"
        :on-network-speed="onNetworkSpeed"
        :files-added="filesAdded"
        :on-progress="onProgress"
        :on-uploaded="onUploaded"
        :on-error="onError"
        multipe>
        <yxt-button type="primary" class="yxtbiz-upload-kng">上传课件</yxt-button>
      </upload>
    </div>
  </div>
</template>


<script>
import * as utils from '../utils';
import upload from '../../../upload';
import uploadTitle from './UploadTitle';
import api from '../service';
import {
  Dialog,
  Progress
} from 'yxt-pc';
export default {
  name: 'UploadKng',
  components: {
    upload,
    uploadTitle,
    YxtDialog: Dialog,
    YxtProgress: Progress
  },
  props: {
    uploadType: {
      type: [String, Number],
      default: 8
    },
    disabled: {
      type: Boolean
    },
    catalogId: {
      type: String,
      default: ''
    },
    courseId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visiable: false,
      files: [],
      // 多文件上传
      deleteNum: 369, // 初始默认数据
      multipleUploadLists: [],
      multipleRestTime: '', // 多文件上传剩余时间
      multipleUploadSpeed: '', // 多文件上传速度
      multipleAlreadyFileSize: '', // 多文件上传已上传文件字节数
      hideData: false, // 是否隐藏上传的数据
      filters: '',
      configKeySize: 0, // 重新上传的大小
      configKeyType: [] // 重新上传的类型
    };
  },
  computed: {
    // 已经上传成功的文件数量排除删除的
    alreadyUploadFileNum() {
      let num = this.multipleUploadLists.filter(item => {
        return !item.isDelete && !item.isMutliUploading;
      });
      return num.length;
    },
    uploadNum() {
      return (this.disabled && parseInt(this.uploadType, 10) !== 0) ? 1 : 10;
    }
  },
  mounted() {
    this.getConfigKey(this.uploadType);
  },
  methods: {
    // 获取公共方法里的知识类型
    kngType(file) {
      return utils.getKngType(file);
    },
    // 进行文件过滤
    filesFilter(files) {
      return files.length > this.uploadNum ? files.splice(0, this.uploadNum) : files;
    },
    fileFiltered(file) {
      if (!this.configKeyType.includes(file.extension)) {
        this.$message.error('上传文件类型不符合要求！');
      } else if (file.size >= this.configKeySize) {
        this.$message.error(`文件大小${utils.handleFileSize(file.size)[0]}，超过限制！`);
      }
      file.abort();
    },
    // 文件被加入到队列事件
    filesAdded(files) {
      this.visiable = true;
      // 所有文件进行赋值
      this.files = files;
      this.multipleUploadLists = files.map(file => {
        return this.setObj(file);
      });
      // 进行需要的数据处理
      this.multipleUploadLists.forEach(item => {
        item.name = item.name.slice(0, item.name.lastIndexOf('.'));
        item.size = utils.handleFileSize(item.size)[0];
      });
      this.deleteNum = this.multipleUploadLists.length;
    },
    // 文件上传速度事件
    onNetworkSpeed(bytes, time, pendings) {
      this.setNetwork(bytes, time, pendings);
    },
    // 上传进度事件
    onProgress(file, progress, event) {
      this.multipleUploadLists.forEach(v => {
        if (v.id === file.uuid) {
          v.percentage = parseInt(progress.toFixed(2) * 100, 10);
        }
      });
    },
    // 文件上传成功事件
    onUploaded(file) {
      // 判断多文件上传时，每个文件上传完成将上传中状态改变为上传完成
      if (this.multipleUploadLists.length) {
        this.multipleUploadLists.forEach(v => {
          if (v.id === file.uuid) {
            v.isMutliUploading = false;
            v.fileSize = file.size;
            v.fileId = file.id;
          }
        });
      }
      let isAllUploadSuccess = this.multipleUploadLists.filter(v => {
        return v.isMutliUploading === true && v.isDelete !== true;
      });
      if (isAllUploadSuccess.length === 0) {
        this.hideData = true;
      }
    },
    // 上传出错事件
    onError(error, file) {
      console.log(error);
    },
    // 展示Input-有file代表多文件
    showInput(file) {
      if (file.name === '' || file.name === undefined) {
        this.$message.error('课件名称不能为空');
      }
    },

    // 删除多文件
    deleteMutilFile(file) {
      this.$confirm('是否确认删除该文件？', '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: ' 取 消',
        showClose: false,
        type: 'warning'
      }).then(() => {
        // 进行上传中文件的取消上传
        this.files.forEach(cValue => {
          if (cValue.uuid === file.id) {
            cValue.abort();
          }
        });
        this.$message.success('删除成功！');
        this.multipleUploadLists.forEach(v => {
          if (v.id === file.id) {
            v.isDelete = true;
            this.deleteNum--;
          }
        });
        if (this.deleteNum === 0) {
          this.closeDialog();
        }
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },

    multipleFilesFilter(files) {
      let noDelteLists = this.multipleUploadLists.filter(v => {
        return !v.isDelete;
      });
      let newLength = noDelteLists.length + files.length;
      if (newLength > this.uploadNum) {
        this.$message.warning(`最多同时上传${this.uploadNum}个文件！`);
      }
      return files.splice(0, this.uploadNum - noDelteLists.length);
    },

    multipleFilesAdded(files) {
      this.hideData = false; // 批量添加时候为false

      let addFileLists = files.map(file => {
        let fileObj = this.setObj(file);
        fileObj.name = fileObj.name.slice(0, fileObj.name.lastIndexOf('.'));
        fileObj.size = utils.handleFileSize(fileObj.size)[0];
        return fileObj;
      });

      this.files = this.files.concat(files);
      this.multipleUploadLists = this.multipleUploadLists.concat(addFileLists);

      // 这里deleteNum的数量应该是第一次上传后没有点击删除按钮的文件数量，所以排除isDelete为true的
      let removeFirstLists = this.multipleUploadLists.filter(file => {
        return !file.isDelete;
      });
      this.deleteNum = removeFirstLists.length;
    },
    multipleOnNetworkSpeed(bytes, time, pendings) {
      this.setNetwork(bytes, time, pendings);
    },
    multipleOnProgress(file, progress, event) {
      this.multipleUploadLists.forEach(v => {
        if (v.id === file.uuid) {
          v.percentage = parseInt(progress.toFixed(2) * 100, 10);
        }
      });
    },
    multipleOnUploaded(file) {
      this.multipleUploadLists.forEach(v => {
        if (v.id === file.uuid) {
          v.isMutliUploading = false;
          v.fileSize = file.size;
          v.fileId = file.id;
        }
      });

      /**
       * 上传完成后隐藏下方上传的速率和需要的时间
       * 当前正在上传的&未被删除的知识数量等于0说明上传完成
       */
      let isAllUploadSuccess = this.multipleUploadLists.filter(v => {
        return v.isMutliUploading === true && v.isDelete !== true;
      });
      if (isAllUploadSuccess.length === 0) {
        this.hideData = true;
      }
    },
    multipleOnError(error, file) {
      console.log(error);
    },

    // 设置当前上传对象基本信息
    setObj(file) {
      let obj = {};
      obj.id = file.uuid;
      obj.name = file.name;
      obj.size = file.size;
      obj.fileType = file.fileType;
      obj.fileClass = file.fileClass;
      obj.extension = file.extension;
      obj.percentage = 0;
      obj.isShowNameInput = false;
      obj.isDelete = false;
      obj.isMutliUploading = true;
      obj.coverUrl = utils.getDefaultKngTypeCover(utils.getCoverImgType({
        fileType: file.fileType,
        fileClass: file.fileClass
      }));
      if (file.error) {
        if (file.error === 'oversize') {
          obj.fileUploadErrorMsg = `文件大小${utils.handleFileSize(file.size)[0]}，超过限制！`;
        } else if (file.error === 'forbidden') {
          obj.fileUploadErrorMsg = '文件类型不符合要求！';
        }
      } else {
        obj.fileUploadErrorMsg = '';
      }
      return obj;
    },
    // 设置速度
    setNetwork(bytes, time, pendings) {
      this.multipleAlreadyFileSize = utils.handleFileSize(bytes)[0];
      let multipleRestFileSize = utils.handleFileSize(bytes)[1];
      let multipleRestUnit = utils.handleFileSize(bytes)[2];
      // 上传的速度
      this.multipleUploadSpeed = (parseInt(multipleRestFileSize, 10) / (parseInt(time, 10) / 1000)).toFixed(2) + ' ' + multipleRestUnit + '/ S';
      // 剩余时间
      let multipleRTime = Math.floor(parseInt(pendings, 10) / (parseInt(bytes, 10) / (parseInt(time, 10) / 1000)));
      this.multipleRestTime = utils.restUploadTime(multipleRTime);
    },
    // 关闭弹框
    closeDialog() {
      // 进行数据的清空
      this.files = [];
      this.deleteNum = 369;
      this.multipleUploadLists = [];
      this.multipleRestTime = '';
      this.multipleUploadSpeed = '';
      this.multipleAlreadyFileSize = '';
      this.visiable = false;
      this.hideData = false;
    },

    submitAll() {
      // 优先判断是否有上传的文件
      let isNoUploadLists = this.multipleUploadLists.filter(item => {
        return item.isMutliUploading && !item.isDelete && item.fileUploadErrorMsg.length === 0;
      });
      if (isNoUploadLists.length) {
        this.$message.error('有文件正在上传中，无法提交！');
        return;
      }

      // 判断名字为空
      let nullName = this.multipleUploadLists.filter(item => {
        return !item.name;
      });
      if (nullName.length) {
        this.$message.error('课件名称不能为空');
        return;
      }

      this.uploadMultiple();
    },

    async uploadMultiple() {
      const res = await api.getKngTree({ pmType: 4 });

      if (!res.catalogTree.length) {
        this.$message.error('当前无目录，请先去后台创建目录!');
        return;
      }

      // 未被删除的 & 未有报错信息的 & 有fileId
      let resultLists = this.multipleUploadLists.filter(item => {
        return !item.isDelete && !item.fileUploadErrorMsg && item.fileId;
      });

      if (!resultLists.length) {
        this.$emit('submit', []);
        this.closeDialog();
        return;
      }

      let ulcdCreateKngReqs = resultLists.map(result => {
        return {
          catalogId: this.catalogId || res.catalogTree[0].id,
          coverUrl: result.coverUrl,
          fileId: result.fileId,
          fileSize: result.fileSize,
          fileType: utils.comFileType(result),
          title: result.name.slice(0, 50),
          type: utils.comType(result.fileType),
          contributorsName: localStorage.fullname || '',
          contributorsUser: localStorage.userId || ''
        };
      });

      api.postUlcdUpload(ulcdCreateKngReqs).then(data => {
        this.$emit('submit', data);
        this.closeDialog();
      });
    },
    getConfigKey(type) {
      if (this.disabled) {
        this.configKeySize = utils.getSizeType(type)[0];
        this.configKeyType = utils.getSizeType(type)[1];
        this.filters = this.configKeyType.join(',');
      } else {
        this.configKeySize = 0;
        this.configKeyType = [];
        this.filters = '';
      }
    }
  },
  watch: {
    uploadType(v) {
      this.getConfigKey(v);
    }
  }
};
</script>
