<template>
  <div class="more_position">
    <div class="table_wrapper">
      <div class="table_wrapper_search">
          <yxt-select v-model="selectType" :placeholder="$t('biz_udp_please_select')"
          style="width: 200px;"
          @change="getPositionData(true)">
              <yxt-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                ></yxt-option>
          </yxt-select>
        <yxt-input
          class="keyword ml12"
          @keyup.enter.native="getPositionData(true)"
          v-model="params.searchKey"
          :placeholder="$t('pc_biz_tcm_tip_search')"
          style="width: 280px;"
          searchable
          @search='getPositionData(true)'
        >
        </yxt-input>
      </div>
      <yxt-table
        ref="multipleTable"
        class="person-table"
        @select="handleSingleSeletct"
        @select-all="handleSelectAll"
        row-key="id"
        :height="tableHeight"
        v-loading="loading"
        :data="tableData"
        style="width:100%;"
      >
        <yxt-table-column
          type="selection"
          :show-overflow-tooltip='false'
          :selectable="selectEvent"
          class-name="table-selection-odd"
          width="40"
        >
        </yxt-table-column>
        <yxt-table-column
          show-overflow-tooltip
          :label="$t('biz_ability_abilityName')"
          min-width="120"
          prop="name"
        >
        </yxt-table-column>
        <yxt-table-column
          :label="$t('biz_ability_abilityType')"
          min-width="100"
        >
        <template slot-scope="{ row }">{{getAbilityType(row.general)}}</template>
        </yxt-table-column>
        <yxt-table-column
          :label="$t('biz_ability_abilityDesc')"
          min-width="320"
          show-overflow-tooltip
          prop="description"
        >
        </yxt-table-column>
        <template slot="empty">
          <div style="line-height:normal">
            <yxt-empty />
          </div>
        </template>
      </yxt-table>
      <div class="yxtbiz-page__content">
        <yxt-pagination
          small
          class="pages"
          :page-size="params.limit"
          :page-sizes="[5, 10, 20, 30, 50, 100]"
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          layout="total, prev, pager, next"
          @current-change="handleCurrentChange"
          :total="total"
          simple-total
        >
        </yxt-pagination>
      </div>
    </div>
    <!-- <div class="list">
      <checked-list @clear="clear" @close="closeTag" :list="tempCheckedPositons" />
    </!-->
  </div>
</template>

<script>
import { SkillSortList, spSkillSortList } from '../service';
import echoMixin from '../mixin/echo';
import resizeTable from '../../../user-selector/mixins/resizeTable';
import { Table, TableColumn, Pagination, Empty, Select, Option } from 'yxt-pc';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import { preCheckFunctions, checkTimeOutFnc } from '../../../common-util/getFactor';

export default {
  components: {
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtSelect: Select,
    YxtOption: Option,
    YxtEmpty: Empty
  },
  name: 'AbilitySelector',
  mixins: [echoMixin, resizeTable],
  data() {
    return {
      loadingAll: false,
      refTable: 'multipleTable',
      currentPage: 1,
      total: 0,
      loading: true,
      tableHeight: 0,
      params: {
        searchKey: '',
        deptId: '',
        limit: 20,
        offset: 0,
        scope: 0
      },
      tableData: [],
      formatPostion: row => {
        if (row.positionName) {
          return row.positionName;
        }
        return row.positionSet
          .map(item => {
            return item.name;
          })
          .join(';');
      },
      typeOptions: [
        {
          label: i18n.t('pc_biz_knglib_lbl_all'),
          value: -1
        },
        {
          label: i18n.t('biz_gwnl_generalAblity'),
          value: 1
        },
        {
          label: i18n.t('biz_gwnl_professionalAblity'),
          value: 0
        }
      ],
      selectType: -1
    };
  },
  created() {
    preCheckFunctions(['talentmanagement']);
  },
  mounted() {
    this.getPositionData();
  },
  methods: {
    getAbilityType(general) {
      let matched = this.typeOptions.find(type => type.value === general);
      return matched ? matched.label : '';
    },
    async getPositionData(reset = false) {
      let data = {
        keyword: this.params.searchKey,
        general: this.selectType === -1 ? -2 : this.selectType,
        status: 1
      };
      let params = {
        limit: this.params.limit,
        offset: reset
          ? 0
          : this.params.offset
      };
      this.loading = true;
      const codeState = checkTimeOutFnc('talentmanagement');
      const serviceFunc = codeState === 2 ? spSkillSortList : SkillSortList;
      serviceFunc(data, params)
        .then((res) => {
          if (res) {
            this.tableData = res.datas.map(data => {
              let { description, general, name, id } = data;
              return {
                description,
                general,
                name,
                id,
                _type: 0
              };
            });
            this.total = res.paging.count;
            this.update();
          }
          this.loading = false;
        }).catch((e) => {
          console.error(e);
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.params.offset = (val - 1) * this.params.limit;
      this.getPositionData();
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.params.limit = val;
      this.getPositionData();
    }
  }
};
</script>
