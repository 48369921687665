var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "fw-600 font-size-14 text-59" }, [
        _vm._v("上传须知："),
      ]),
      _vm._l(_vm.uploadFAQ, function (u) {
        return _c(
          "p",
          { key: u, staticClass: "color-gray-8 font-size-12 mt10" },
          [_vm._v(_vm._s(u))]
        )
      }),
      _c("p", { staticClass: "fw-600 font-size-14 text-59 mt20" }, [
        _vm._v("支持的格式大小："),
      ]),
      _vm._l(_vm.supportFileFAQ, function (s) {
        return _c(
          "p",
          { key: s, staticClass: "color-gray-8 font-size-12 mt10" },
          [_vm._v(_vm._s(s))]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }