import Vue from 'vue';
import { isString, isObject } from 'yxt-biz-pc/src/utils/types';

const hasOwnProperty = Object.prototype.hasOwnProperty;

export function noop() {};

export function hasOwn(obj, key) {
  return hasOwnProperty.call(obj, key);
};

function extend(to, _from) {
  for (let key in _from) {
    to[key] = _from[key];
  }
  return to;
};

export function toObject(arr) {
  var res = {};
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]) {
      extend(res, arr[i]);
    }
  }
  return res;
};

export const getValueByPath = function(object, prop) {
  prop = prop || '';
  const paths = prop.split('.');
  let current = object;
  let result = null;
  for (let i = 0, j = paths.length; i < j; i++) {
    const path = paths[i];
    if (!current) break;

    if (i === j - 1) {
      result = current[path];
      break;
    }
    current = current[path];
  }
  return result;
};

export function getPropByPath(obj, path, strict) {
  let tempObj = obj;
  path = path.replace(/\[(\w+)\]/g, '.$1');
  path = path.replace(/^\./, '');

  let keyArr = path.split('.');
  let i = 0;
  for (let len = keyArr.length; i < len - 1; ++i) {
    if (!tempObj && !strict) break;
    let key = keyArr[i];
    if (key in tempObj) {
      tempObj = tempObj[key];
    } else {
      if (strict) {
        throw new Error('please transfer a valid prop path to form item!');
      }
      break;
    }
  }
  return {
    o: tempObj,
    k: keyArr[i],
    v: tempObj ? tempObj[keyArr[i]] : null
  };
};

export const generateId = function() {
  return Math.floor(Math.random() * 10000);
};

export const valueEquals = (a, b) => {
  // see: https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
  if (a === b) return true;
  if (!(a instanceof Array)) return false;
  if (!(b instanceof Array)) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i !== a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const escapeRegexpString = (value = '') => String(value).replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');

// TODO: use native Array.find, Array.findIndex when IE support is dropped
export const arrayFindIndex = function(arr, pred) {
  for (let i = 0; i !== arr.length; ++i) {
    if (pred(arr[i])) {
      return i;
    }
  }
  return -1;
};

export const arrayFind = function(arr, pred) {
  const idx = arrayFindIndex(arr, pred);
  return idx !== -1 ? arr[idx] : undefined;
};

// coerce truthy value to array
export const coerceTruthyValueToArray = function(val) {
  if (Array.isArray(val)) {
    return val;
  } else if (val) {
    return [val];
  } else {
    return [];
  }
};

export const isIE = function() {
  return !Vue.prototype.$isServer && !isNaN(Number(document.documentMode));
};

export const isEdge = function() {
  return !Vue.prototype.$isServer && navigator.userAgent.indexOf('Edge') > -1;
};

export const isFirefox = function() {
  return !Vue.prototype.$isServer && !!window.navigator.userAgent.match(/firefox/i);
};

export const autoprefixer = function(style) {
  if (typeof style !== 'object') return style;
  const rules = ['transform', 'transition', 'animation'];
  const prefixes = ['ms-', 'webkit-'];
  rules.forEach(rule => {
    const value = style[rule];
    if (rule && value) {
      prefixes.forEach(prefix => {
        style[prefix + rule] = value;
      });
    }
  });
  return style;
};

export const kebabCase = function(str) {
  const hyphenateRE = /([^-])([A-Z])/g;
  return str
    .replace(hyphenateRE, '$1-$2')
    .replace(hyphenateRE, '$1-$2')
    .toLowerCase();
};

export const capitalize = function(str) {
  if (!isString(str)) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const looseEqual = function(a, b) {
  const isObjectA = isObject(a);
  const isObjectB = isObject(b);
  if (isObjectA && isObjectB) {
    return JSON.stringify(a) === JSON.stringify(b);
  } else if (!isObjectA && !isObjectB) {
    return String(a) === String(b);
  } else {
    return false;
  }
};

export const arrayEquals = function(arrayA, arrayB) {
  arrayA = arrayA || [];
  arrayB = arrayB || [];

  if (arrayA.length !== arrayB.length) {
    return false;
  }

  for (let i = 0; i < arrayA.length; i++) {
    if (!looseEqual(arrayA[i], arrayB[i])) {
      return false;
    }
  }

  return true;
};

export const isEqual = function(value1, value2) {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return arrayEquals(value1, value2);
  }
  return looseEqual(value1, value2);
};

export const isEmpty = function(val) {
  // null or undefined
  if (val == null) return true;

  if (typeof val === 'boolean') return false;

  if (typeof val === 'number') return !val;

  if (val instanceof Error) return val.message === '';

  switch (Object.prototype.toString.call(val)) {
    // String or Array
    case '[object String]':
    case '[object Array]':
      return !val.length;

    // Map or Set or File
    case '[object File]':
    case '[object Map]':
    case '[object Set]': {
      return !val.size;
    }
    // Plain Object
    case '[object Object]': {
      return !Object.keys(val).length;
    }
  }

  return false;
};

export function rafThrottle(fn) {
  let locked = false;
  return function(...args) {
    if (locked) return;
    locked = true;
    window.requestAnimationFrame(_ => {
      fn.apply(this, args);
      locked = false;
    });
  };
}

export function objToArray(obj) {
  if (Array.isArray(obj)) {
    return obj;
  }
  return isEmpty(obj) ? [] : [obj];
}

export function loadScript(src, allowOnlyOne) {
  loadScript._cachePromises = loadScript._cachePromises || {};
  const cachePromise = loadScript._cachePromises[src];
  // 若同一脚本 只允许加载一次 且 已有缓存
  if (allowOnlyOne && cachePromise) {
    return cachePromise;
  }

  const loadPromise = new window.Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => {
      reject(false);

      // 加载失败，允许再次加载
      if (allowOnlyOne) {
        document.head.removeChild(script);
        delete loadScript._cachePromises[src];
      }
    };
    script.onreadystatechange = () => {
      if (
        script.readyState === 'loaded' ||
        script.readyState === 'complete'
      ) {
        resolve(true);
      }
    };
    // 默认 允许重复加载脚本
    if (!allowOnlyOne) {
      document.head.appendChild(script);
    } else {
      const scriptList = Array.from(
        document.head.querySelectorAll('script')
      );
      const isExists = scriptList.some(script => script.src === src);
      if (!isExists) {
        document.head.appendChild(script);
      }
    }
  });

  loadScript._cachePromises[src] = loadPromise;

  return loadPromise;
}

function isFunction(val) {
  return typeof val === 'function';
}

/**
 *
 * @param {Function} fn 普通函数
 * @param {Object}} context 上下文对象
 * @returns promiseFn
 * eg:
 * const promiseFn = promisify((v1, resole, reject) => fetch({success: resolve}))
 * promiseFn(val1)
 */
export function promisify(fn, context = null) {
  return function(...args) {
    if (!isFunction(fn)) {
      console.warn('fn is not function', fn);
      return Promise.resolve(fn);
    }

    return new Promise((resolve, reject) => {
      return fn.apply(context, [...args, resolve, reject]);
    }).catch(err => {
      console.error('promisify error: ', err);
      return false;
    });
  };
}

/**
 * 大数组转换为二维数组
 * @param {Array} list 数组
 * @param {Number} size 块大小
 * @returns chunks 二维数组
 */
export function toChunks(list, size = 10) {
  if (!Array.isArray(list)) {
    console.warn('list not array:', list);
    return list;
  }
  const cloneList = list.slice();
  const chunks = [];
  while (cloneList.length) {
    chunks.push(cloneList.splice(0, size));
  }
  return chunks;
}

/**
 * 解析url参数 得到query对象
 * @param {String} url
 * @returns object query
 */
export function getQuery(url) {
  url = url || location.href;
  getQuery._cache = getQuery._cache || {};
  const cacheQuery = getQuery._cache[url];
  if (cacheQuery) {
    return cacheQuery;
  }

  let search = url.split('?')[1] || '';
  search = search.replace(/^\s+|\s+$/g, '');

  const query = {};
  search.length && search.split('&').forEach(part => {
    const [key, val] = part.split('=');
    const value = val == null ? val : decodeURIComponent(val);
    query[key] = value;
  });
  getQuery._cache[url] = query;
  return query;
}

export function eachKey(obj, fn) {
  Object.keys(obj).forEach(key => fn(obj[key], key, obj));
}

export function toQueryStr(query = {}) {
  let result = [];
  eachKey(query, (val, key) => {
    result.push(`${key}=${encodeURIComponent(val)}`);
  });
  return result.join('&');
}

export function addQuery(url, newQuery) {
  const oldQuery = getQuery(url);
  const queryStr = toQueryStr(Object.assign(oldQuery, newQuery));
  return url.split('?')[0] + '?' + queryStr;
}

/**
 * 从对象提取所需字段
 * @param {object} data
 * @param {array} keys
 * @returns object
 * eg:
 * pick({name: 'zhang', age: 12, color: 'blue'}, ['name']) => {name: 'zhang'}
 * pick({name: 'zhang', age: 12, color: 'blue'}, ['name', ['age', 'year']]) => {name: 'zhang', year: 12}
 */
export function pick(data, keys) {
  const result = {};
  keys.forEach(key => {
    let newKey = key;
    if (Array.isArray(key)) {
      const [okey, nkey] = key;
      key = okey;
      newKey = nkey;
    }
    result[newKey] = data[key];
  });
  return result;
}

export function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(() => resolve(true), ms);
  });
}

export function createLogger(subject) {
  return {
    log(...args) {
      console.log(`%c[${subject}] `, 'color: green;', ...args);
    },
    warn(...args) {
      console.warn(`[${subject}] `, ...args);
    }
  };
}

function isPlainObject(val) {
  return Object.prototype.toString.call(val).slice(8, -1) === 'Object';
}

function getStorage(type) {
  const mStorage = window[type]; // localStorage or sessionStorage
  return {
    get(key, isParse) {
      let value = mStorage[key];
      if (isParse) {
        const jsonRe = /^\{.*\}$|^\[.*\]$/;
        const numRe = /^\d+$/;
        const boolRe = /true|false/;
        const parsable = [ jsonRe, numRe, boolRe ].some(re => re.test(value));
        if (parsable) {
          try {
            value = JSON.parse(value);

          } catch (e) {
            console.error('parse storage value err：', e);
          }
        }
      }
      return value;
    },
    set(key, val, options = {}) {
      const values = isPlainObject(key) ? key : { [key]: val };
      options = isPlainObject(key) ? val || {} : options;
      const skipNil = options.skipNil === undefined ? true : options.skipNil;
      eachKey(values, (value, field) => {
        if (skipNil && value == null) {
          return;
        }
        mStorage[field] = Array.isArray(value) || isPlainObject(value) ? JSON.stringify(value) : value;
      });
    },
    remove(keys) {
      if (Array.isArray(keys) === false) {
        keys = [ keys ];
      }

      const delValue = mStorage[keys[0]];
      keys.forEach(key => mStorage.removeItem(key));
      return delValue;
    }
  };
}

// 返回防抖函数
export function debounce(fn, ms, ctx) {
  let timer;
  return function(...args) {
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(ctx, args);
    }, ms || 100);
  };
}

var [ storage, session ] = [ 'localStorage', 'sessionStorage' ].map(getStorage);
export { storage, session };
