<template>
  <yxtf-svg
    v-if="iconClassName"
    class="audio-video-icon"
    :remote-url="mediaUrl"
    :icon-class="iconClassName"
    :width="width + 'px'"
    :height="height + 'px'"
  ></yxtf-svg>
</template>
<script>
export default {
  name: 'audioVideoIcon',
  data() {
    return {
      mediaUrl: `${this.$staticBaseUrl}ufd/3f5568/common/pc_foreground/svg`,
      iconTypes: [
        {
          key: 0,
          value: ''
        },
        {
          key: 1,
          value: 'f_kng-img'
        },
        {
          key: 2,
          value: 'f_kng-audio'
        },
        {
          key: 3,
          value: 'f_kng-video'
        }
      ]
    };
  },
  props: {
    type: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  },
  computed: {
    iconClassName() {
      // 试题类型；0：文字；1：图片；:2：音频；3：视频
      if (this.type >= 0) {
        return this.iconTypes[this.type].value;
      }
      return '';
    }
  },
  created() {},
  methods: {}
};
</script>
