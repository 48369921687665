import axios from 'axios';

const pendingMap = new Map();

function jshash(str) {
  let hash = 0;
  for (var i = str.length - 1; i >= 0; i--) {
    hash ^= (hash << 5) + str.charCodeAt(i) + (hash >> 2);
  }
  return hash & 0x7FFFFFF;
}
/**
 * 生成每个请求唯一的键
 * @param {*} config
 * @returns string
 */
function getPendingKey(config) {
  let {url, params, data} = config;
  if (typeof data === 'string') {
    data = JSON.parse(data); // response里面返回的config.data是个字符串对象
  }
  return jshash([url, JSON.stringify(params), JSON.stringify(data)].join(''));
}

/**
 * 储存每个请求唯一值, 也就是cancel()方法, 用于取消请求
 * @param {*} config
 */
export function addPending(config) {
  const pendingKey = getPendingKey(config);
  config.cancelToken = config.cancelToken || new axios.CancelToken((cancel) => {
    if (!pendingMap.has(pendingKey)) {
      pendingMap.set(pendingKey, cancel);
    }
  });
}
/**
 * 删除重复的请求
 * @param {*} config
 */
export function removePending(config) {
  const pendingKey = getPendingKey(config);
  if (pendingMap.has(pendingKey)) {
    const cancelToken = pendingMap.get(pendingKey);
    cancelToken(pendingKey);
    pendingMap.delete(pendingKey);
  }
}

export function requestResolve(config) {
  if (config.allowRepeat ||
    ['post', 'delete'].indexOf(config.method) === -1 ||
    (!config.url.match(/\/add/) && config.url.match(/(list|info|export)$/))) {
    config.allowRepeat = true;
  }

  // eslint-disable-next-line
  config.allowRepeat = (['post', 'delete'].indexOf(config.method) == -1) || config.allowRepeat;
  if (!config.allowRepeat) {
    removePending(config);
    addPending(config);
  }
  return config;
}

export function responseResolve(response) {
  if (response.config && !response.config.allowRepeat) {
    response.config && removePending(response.config);
  }
  return response;
}

export function responseReject(err) {
  err.config && removePending(err.config);
  if (err.message && /^\d+$/.test(err.message)) {
    return new Promise((res, rej) => {});
  }
  return Promise.reject(err);

}
