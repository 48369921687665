import SelectKng from './src/main.vue';

/* istanbul ignore next */
SelectKng.install = function(Vue) {
  Vue.component(SelectKng.name, SelectKng);
};

SelectKng.setConfig = (config) => {
  SelectKng.env = config.env || 'dev';
};

export default SelectKng;
