// import { createPopper } from '@popperjs/core';
import Popper from 'yxt-biz-pc/src/utils/popper.js';
import { i18n } from './changeLangs';
import { addClass, removeClass } from 'yxt-biz-pc/src/utils/dom';

import {
  PopupManager
} from 'yxt-biz-pc/src/utils/popup';
// 0.要素下架-隐藏 1.要素上架-未购买-隐藏 2.要素上架-已购买未过期（启用）-显示 3.要素上架-已购买未过期（启用）-隐藏 4.要素上架-已购买未过期（启用）-自定义
// 5.要素上架-已购买已过期（禁用）-隐藏 6.要素上架-已购买已过期（禁用）-置灰 7.要素上架-已购买已过期（禁用）-自定义
// none - 隐藏  disabled - 禁用  custom - 自定义 show - 显示
const enumStatus = { // 按钮对应枚举值
  0: 'none',
  1: 'none',
  2: 'show',
  3: 'none',
  4: 'custom',
  5: 'none',
  6: 'disabled',
  7: 'custom'
};

const CLASS_CHECK_BUTTONS_TIP = 'yxtbiz-check-buttons__tip-default'; // 新增tip的className
const CLASS_CHECK_BUTTONS_NONE = 'yxtbiz-check-buttons__none'; // 隐藏按钮
const CLASS_CHECK_BUTTONS_EVENTS = 'yxtbiz-check-buttons__events'; // 按钮css的阻止事件
const CLASS_CHECK_BUTTONS_DIV = 'yxtbiz-check-buttons__div'; // 新增div的ClassName
const CLASS_IS_DiSABLED = 'is-disabled'; // 不可用样式的className
const I18n_TIP_DISABLED_TITLE = 'pc_biz_tip_disabled_title'; // tip的多语言key

const checkButtons = { // 校验按钮指令
  bind(el) {
    el.show = (event, tipDiv) => {
      event.stopPropagation();
      document.body.appendChild(tipDiv);
      const popperVM = new Popper(el, tipDiv, {
        placement: 'top'
      });
      popperVM.onCreate(() => {
        if (Popper) {
          if (Popper._popper) {
            Popper._popper.style.zIndex = PopupManager.nextZIndex();
          }
        } else {
          this.createPopper();
        }
      });
      tipDiv.style.top = '-10px';
    };
    el.hide = () => {
      let tipDiv = document.getElementsByClassName(CLASS_CHECK_BUTTONS_TIP)[0];
      tipDiv && tipDiv.parentNode.removeChild(tipDiv);
    };
    el.removeDiv = () => {
      let divArr = el.getElementsByTagName('div');
      let childNodes = el.childNodes;
      for (let i = 0; i < divArr.length; i++) {
        for (let k = 0;k < childNodes.length;k++) {
          if (divArr.length[i] === childNodes[k]) {
            el.removeChild(divArr[i]);
          }
        }
      }
      let tipDiv = document.getElementsByClassName(CLASS_CHECK_BUTTONS_TIP)[0];
      tipDiv && tipDiv.parentNode.removeChild(tipDiv);
    };
  },
  inserted(el, binding, vnode) {
    const { value } = binding;
    checkType(el, value);
  },
  update(el, binding, vnode) {
    const { oldValue, value } = binding;
    if (enumStatus[oldValue] !== enumStatus[value]) {
      let divElArr = el.getElementsByTagName('div');
      for (let i = 0; i < divElArr.length; i++) {
        el.removeChild(divElArr[i]);
      }
      removeClass(el, CLASS_IS_DiSABLED);
      checkType(el, value);
    }
  },
  unbind(el) {
    let tipDiv = document.getElementsByClassName(CLASS_CHECK_BUTTONS_TIP)[0];
    tipDiv && tipDiv.parentNode.removeChild(tipDiv);
    el.removeDiv();
  }
};

const checkType = (el, value) => {
  switch (enumStatus[value]) {
    case 'none':
      addClass(el, CLASS_CHECK_BUTTONS_NONE);
      removeClass(el, CLASS_CHECK_BUTTONS_EVENTS);
      el.removeDiv();
      break;
    case 'show':
      removeClass(el, CLASS_CHECK_BUTTONS_NONE);
      removeClass(el, CLASS_CHECK_BUTTONS_EVENTS);
      if (el.disabled) addClass(el, CLASS_IS_DiSABLED);
      el.removeDiv();
      break;
    case 'custom':
      removeClass(el, CLASS_CHECK_BUTTONS_NONE);
      removeClass(el, CLASS_CHECK_BUTTONS_EVENTS);
      if (el.disabled) addClass(el, CLASS_IS_DiSABLED);
      el.removeDiv();
      break;
    case 'disabled':
      removeClass(el, CLASS_CHECK_BUTTONS_NONE);
      let btnDiv = document.createElement('div'); // 在按钮上新增遮罩（由于按钮的时间屏蔽）
      let tipDiv = document.createElement('div'); // 创建tooltip
      btnDiv.className = CLASS_CHECK_BUTTONS_DIV; // 定义基础样式
      tipDiv.className = CLASS_CHECK_BUTTONS_TIP; // 定义基础样式
      tipDiv.textContent = i18n.t(I18n_TIP_DISABLED_TITLE);
      el.style.position = 'relative';
      el.appendChild(btnDiv);
      addClass(el, CLASS_IS_DiSABLED);
      addClass(el, CLASS_CHECK_BUTTONS_EVENTS);
      // el.disabled = true;
      btnDiv.addEventListener('mouseover', (event) => el.show(event, tipDiv));
      btnDiv.addEventListener('click', (event) => el.show(event, tipDiv));
      btnDiv.addEventListener('mouseout', el.hide);
      break;
    default:
      break;
  }
};

const directives = {};

directives.install = Vue => {
  Vue.directive('checkButtons', checkButtons);
};

export default directives;
