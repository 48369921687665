var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-exam-arrange-selector" }, [
    _c("div", { staticClass: "yxtbiz-exam-arrange-selector__left" }, [
      _c(
        "div",
        {
          staticClass:
            "yxtbiz-exam-arrange-selector__filter clearfix font-size-14",
        },
        [
          _vm.groupSelect
            ? _c("yxtbiz-group-org-select", {
                staticClass: "mr12 mb16",
                attrs: {
                  clearable: false,
                  multiple: false,
                  functionCode: _vm.orgFunctionCode,
                  dataPermissionCode: _vm.orgDataPermissionCode,
                  setCurrentOrg: "",
                },
                on: { change: _vm.changeOrg },
              })
            : _vm._e(),
          _c(
            "yxt-popover",
            {
              attrs: {
                "append-to-body": false,
                "popper-class": "yxtbiz-exam-arrange-selector__popover",
                placement: "bottom-start",
                "visible-arrow": false,
              },
              model: {
                value: _vm.showMoreFilter,
                callback: function ($$v) {
                  _vm.showMoreFilter = $$v
                },
                expression: "showMoreFilter",
              },
            },
            [
              _c(
                "yxt-button",
                {
                  staticClass: "yxtbiz-filter-btn mr12 mb16",
                  attrs: { slot: "reference", plain: "" },
                  slot: "reference",
                },
                [
                  _c("yxt-svg", {
                    staticClass: "v-top",
                    attrs: {
                      width: "20px",
                      height: "20px",
                      "icon-class": "filter",
                    },
                  }),
                  _c("span", { staticClass: "ml8 v-top" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("pc_biz_common_lbl_moreFilter" /** 更多筛选 */)
                      )
                    ),
                  ]),
                  _c("i", {
                    staticClass: "ml8",
                    class: _vm.showMoreFilter
                      ? "yxt-icon-arrow-up"
                      : "yxt-icon-arrow-down",
                  }),
                ],
                1
              ),
              _c(
                "yxt-form",
                { attrs: { "label-position": "top" } },
                [
                  _c(
                    "yxt-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "yxt-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "yxt-form-item",
                            {
                              attrs: {
                                "label-width":
                                  _vm.archives && _vm.loopExam
                                    ? "124px"
                                    : "100px",
                                label: _vm.$t("pc_biz_ote_lbl_publishdate"),
                              },
                            },
                            [
                              _c("yxt-date-picker", {
                                attrs: {
                                  format: "yyyy-MM-dd",
                                  type: "daterange",
                                  "range-separator":
                                    _vm.$t("pc_biz_ote_lbl_to"),
                                  "start-placeholder": _vm.$t(
                                    "pc_biz_ote_lbl_starttime"
                                  ),
                                  "end-placeholder": _vm.$t(
                                    "pc_biz_ote_lbl_endtime"
                                  ),
                                },
                                on: { change: _vm.changeDate },
                                model: {
                                  value: _vm.publishDate,
                                  callback: function ($$v) {
                                    _vm.publishDate = $$v
                                  },
                                  expression: "publishDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "yxt-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "yxt-form-item",
                            {
                              attrs: {
                                "label-width": "124px",
                                label: _vm.$t("pc_biz_ote_lbl_source"),
                              },
                            },
                            [
                              _c(
                                "yxt-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    size: "large",
                                    placeholder: _vm.$t(
                                      "pc_biz_ote_lbl_pleaseselect"
                                    ),
                                  },
                                  on: { change: _vm.changeSource },
                                  model: {
                                    value: _vm.examSearch._sourceType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.examSearch,
                                        "_sourceType",
                                        $$v
                                      )
                                    },
                                    expression: "examSearch._sourceType",
                                  },
                                },
                                _vm._l(_vm.sourceList, function (item) {
                                  return _c("yxt-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.byReportDB && (!_vm.loopExam || _vm.archives)
                    ? _c(
                        "yxt-row",
                        { staticClass: "mt16", attrs: { gutter: 20 } },
                        [
                          !_vm.loopExam
                            ? _c(
                                "yxt-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "yxt-form-item",
                                    {
                                      attrs: {
                                        "label-width": "100px",
                                        label: _vm.$t(
                                          "pc_biz_ote_lbl_arrangetype"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "yxt-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            size: "large",
                                            placeholder: _vm.$t(
                                              "pc_biz_ote_lbl_pleaseselect"
                                            ),
                                          },
                                          on: { change: _vm.changeSource },
                                          model: {
                                            value: _vm.examSearch.arrangeType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.examSearch,
                                                "arrangeType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "examSearch.arrangeType",
                                          },
                                        },
                                        _vm._l(_vm.typeList, function (item) {
                                          return _c("yxt-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.archives
                            ? _c(
                                "yxt-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "yxt-form-item",
                                    {
                                      attrs: {
                                        "label-width": "124px",
                                        label: _vm.$t(
                                          "pc_biz_ote_lbl_containsarchive"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "yxt-select",
                                        {
                                          attrs: {
                                            size: "large",
                                            placeholder: _vm.$t(
                                              "pc_biz_ote_lbl_pleaseselect"
                                            ),
                                          },
                                          on: { change: _vm.changeSource },
                                          model: {
                                            value: _vm.examSearch.archiveFlag,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.examSearch,
                                                "archiveFlag",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "examSearch.archiveFlag",
                                          },
                                        },
                                        [
                                          _c("yxt-option", {
                                            attrs: {
                                              label:
                                                _vm.$t("pc_biz_ote_lbl_yes"),
                                              value: 1,
                                            },
                                          }),
                                          _c("yxt-option", {
                                            attrs: {
                                              label:
                                                _vm.$t("pc_biz_ote_lbl_no"),
                                              value: 0,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("yxt-input", {
            staticClass: "mb16",
            class: _vm.$isChinese ? "w208" : "w320",
            attrs: {
              placeholder: _vm.$t(
                _vm.isShowExamCode
                  ? "pc_biz_ote_tip_examnamesearch2"
                  : "pc_biz_ote_tip_examnamesearch"
              ),
              searchable: "",
              maxlength: "100",
            },
            on: { search: _vm.examNameSearch },
            model: {
              value: _vm.examSearch.keyword,
              callback: function ($$v) {
                _vm.$set(_vm.examSearch, "keyword", $$v)
              },
              expression: "examSearch.keyword",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "yxtbiz-exam-arrange-selector__main" },
        [
          _c(
            "yxt-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: _vm.refTable,
              staticClass: "yxtbiz-exam-arrange-selector__table",
              class: {
                "check-indeterminate": _vm.isIndeterminateHead,
              },
              attrs: {
                data: _vm.examsList,
                height: _vm.tableHeight,
                "row-class-name": _vm.getRowClass,
                "default-sort": { prop: "updateTime", order: "desc" },
                "tree-props": {
                  children: "_children",
                  hasChildren: "hasChildren",
                },
              },
              on: {
                "select-all": _vm.selectAll,
                select: _vm.select,
                "sort-change": _vm.sortChange,
              },
            },
            [
              _vm.getIsSelectLoopChild()
                ? _c("yxt-table-column", {
                    attrs: {
                      type: "expand",
                      width: "46",
                      "clear-padding": "right",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "yxtbiz-exam-arrange-selector__table-row",
                                  attrs: {
                                    cellspacing: "0",
                                    cellpadding: "0",
                                    border: "0",
                                  },
                                },
                                _vm._l(props.row.children, function (row) {
                                  return _c(
                                    "tr",
                                    {
                                      key: row.id,
                                      staticClass: "yxt-table__row",
                                    },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "yxt-table_1_column_1",
                                          attrs: {
                                            rowspan: "1",
                                            colspan: "1",
                                            "data-row": "0",
                                            "data-column": "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "cell" },
                                            [
                                              _c(
                                                "yxt-checkbox",
                                                {
                                                  on: {
                                                    change: (checked) => {
                                                      _vm.selectChildren(
                                                        row,
                                                        checked
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: row.checked,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "checked",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.checked",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pc_biz_ote_lbl_bantchindex",
                                                        [row.batchIndex]
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1562509624
                    ),
                  })
                : _vm._e(),
              _c("yxt-table-column", {
                attrs: {
                  type: "selection",
                  "show-overflow-tooltip": false,
                  width: _vm.getIsSelectLoopChild() ? 16 : 30,
                  "clear-padding": "left-right",
                  align: "right",
                  selectable: _vm.checkRowSelectable,
                },
              }),
              _c("yxt-table-column", {
                attrs: {
                  "show-overflow-tooltip": false,
                  label: _vm.$t("pc_biz_ote_lbl_examname"),
                  "min-width": "130",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          { staticClass: "yxtbiz-exam-arrange-selector__name" },
                          [
                            _c(
                              "yxt-tooltip",
                              {
                                attrs: {
                                  placement: "top",
                                  "open-filter": "",
                                  content: scope.row.name,
                                  effect: "dark",
                                },
                              },
                              [
                                _c("div", { staticClass: "ellipsis" }, [
                                  _vm._v(_vm._s(scope.row.name)),
                                ]),
                              ]
                            ),
                            [1, 2].indexOf(scope.row.groupDataType) >= 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "ml8 yxtbiz-exam-arrange-selector__grouptag",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("pc_biz_ote_lbl_grouporg"))
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isShowExamCode
                ? _c("yxt-table-column", {
                    attrs: {
                      label: _vm.examCodeName,
                      "show-overflow-tooltip": "",
                      align: "left",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.projectCode || "--") +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2171020037
                    ),
                  })
                : _vm._e(),
              _c("yxt-table-column", {
                attrs: {
                  label: _vm.$t("pc_biz_ote_lbl_status"),
                  prop: "status",
                  align: "left",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          class: [
                            "yxtbiz-exam-arrange-selector__status",
                            `yxtbiz-exam-arrange-selector__status--${scope.row.status}`,
                          ],
                        }),
                        _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getStatusName(scope.row.status)) +
                              "\n            "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              !_vm.byReportDB
                ? _c("yxt-table-column", {
                    attrs: {
                      label: _vm.$t("pc_biz_ote_lbl_arranger"),
                      "show-overflow-tooltip": "",
                      "popover-html": "",
                      prop: "createFullname",
                      align: "left",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("yxtbiz-user-name", {
                                attrs: { name: scope.row.createFullname },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2234491372
                    ),
                  })
                : _c("yxt-table-column", {
                    attrs: {
                      label: _vm.$t("pc_biz_ote_lbl_publishperson"),
                      "show-overflow-tooltip": "",
                      "popover-html": "",
                      prop: "releaseUserName",
                      align: "left",
                      width: "80",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("yxtbiz-user-name", {
                              attrs: { name: scope.row.releaseUserName },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
              _c("yxt-table-column", {
                attrs: {
                  label: _vm.$t("pc_biz_ote_lbl_publishdate"),
                  prop: "releasedTime",
                  sortable: "custom",
                  formatter: _vm.shortDate,
                  align: "left",
                  width: _vm.$isChinese ? "130" : "160",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt16 clearfix yxtbiz-exam-arrange-selector__pager" },
        [
          _c(
            "yxt-button",
            {
              attrs: {
                loading: _vm.loadingAll,
                disabled: !_vm.count,
                type: "text",
              },
              on: { click: _vm.selectAllNopage },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("pc_biz_ote_btn_allselect")) +
                  "\n      "
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "flex-1" },
            [
              _c("yxt-pagination", {
                staticClass: "pull-right",
                attrs: {
                  "page-size": _vm.examSearch.limit,
                  total: _vm.count,
                  "current-page": _vm.page,
                  layout: "total, prev, pager, next",
                  "page-count": _vm.totalPage,
                },
                on: {
                  "size-change": _vm.sizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "yxtbiz-exam-arrange-selector__right" },
      [
        _vm.max
          ? _c("div", { staticClass: "mb12" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("pc_biz_ote_lbl_chosenquestions")) +
                  "：" +
                  _vm._s(_vm.selectedList.length) +
                  "/" +
                  _vm._s(_vm.max) +
                  "\n    "
              ),
            ])
          : _vm._e(),
        _c(
          "yxt-scrollbar",
          { attrs: { "fit-height": true } },
          _vm._l(_vm.selectedList, function (item) {
            return _c(
              "yxt-tag",
              {
                key: item.id,
                staticClass: "mr5 mb5",
                attrs: { size: "small", type: "info", closable: "" },
                on: {
                  close: function ($event) {
                    return _vm.deleteItem(item)
                  },
                },
              },
              [
                _c(
                  "yxt-tooltip",
                  { attrs: { content: item.name, openFilter: true } },
                  [
                    _c(
                      "span",
                      { staticClass: "d-block yxtbiz-user-selector-tag" },
                      [_vm._v(_vm._s(item.name))]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          1
        ),
        _c(
          "yxt-button",
          {
            staticClass:
              "yxtbiz-user-clear font-size-12 color-gray-9 hover-primary-6",
            attrs: {
              type: "text",
              disabled: Object.values(this.selected).length === 0,
            },
            on: { click: _vm.clear },
          },
          [_vm._v(_vm._s(_vm.$t("pc_biz_ote_btn_clearall")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }