<template>
  <div style="height:100%" class="yxtbiz-select-catelog-source">
    <div style="width:200px">
      <yxt-input
        v-model="catalogName"
        width="200"
        :placeholder='$t("pc_biz_kng_lbl_search_category_name"/* 搜索分类名称 */)'
        maxlength="20"
        size="small"
        searchable
        @search="searchCatalog"
      />
    </div>
    <yxt-tree 
      class="select-kng-catalog__calc-height mt16"
      ref="kngTree"
      wrap-width="613px"
      v-loading="treeLoading"
      :data="kngTreeData"
      :props="defaultProps"
      :current-node-key="currentId"
      node-key="id"
      @node-click="nodeClick"
      @current-change="currentNodeChange"
      @check="checkChange"
      :filter-node-method="filterCatalogNode"
      :default-checked-keys="checkLists"
      :default-expanded-keys="expandCatalog"
      :check-strictly="checkStrictly"
      :highlight-current="!isNeedCheck"
      :show-checkbox="isNeedCheck">
    </yxt-tree>
  </div>
</template>

<script>
import api from './service';

import {
  Tree
} from 'yxt-pc';

export default {
  name: 'YxtbizSelectKngCatelogSource',
  components: {
    YxtTree: Tree
  },
  props: {
    // 学院ID 如果不传获取全部的目录
    collegeId: String,
    // 根（学院级目录）目录是否可选，
    rootCanCheck: {
      type: Boolean,
      default: false
    },
    // 根目录是否可以通过点击选中
    rootCanSelect: {
      type: Boolean,
      default: false
    },
    checkLists: {
      type: Array,
      default: () => []
    },
    isNeedCheck: {
      type: Boolean,
      default: true
    },
    checkId: {
      type: String,
      default: ''
    },
    checkStrictly: {
      type: Boolean,
      default: true
    },
    isExpandCatalog: {
      type: Boolean,
      default: false
    },
    disabledList: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      treeLoading: true, // loading-tree
      kngTreeData: [], // 目录树的数据
      defaultProps: {
        children: 'children', // 默认子集的字段
        label: 'label', // 默认标题的字段
        nocheck: this.nocheckFilter,
        disabled: this.checkDisabled
      },
      singleTree: {},
      // 不可选的列表 用于过滤不可点击
      nocheckList: [],
      // 需要展开节点的列表
      expandCatalog: [],
      catalogName: '',
      currentId: ''
    };
  },
  created() {

    this.getCatalogInfo();
  },
  methods: {
    // 获取知识库目录
    async getCatalogInfo() {
      this.treeLoading = true;
      let request = {
      };

      let orgId = localStorage.getItem('orgId');
      if (this.collegeId) {
        request.collegeId = this.collegeId;
      }
      try {
        let res = await api.getKngTreeNew(orgId, request);
        if (res && res.length) {
          this.kngTreeData = res;
          if (this.isExpandCatalog) {
            // 展开第一层目录
            res.forEach(item => {
              this.expandCatalog.push(item.id);
            });
          }
          // 如果要选中第一个学院
          if (this.currentFirstNode && !this.isNeedCheck) {
            // 取出第一个节点id设置选中
            this.$nextTick(() => {
              this.$refs.kngTree && this.$refs.kngTree.setCurrentKey(res[0].id);
              this.currentNodeChange(res[0]);
            });
            // 不展开任何目录
            this.expandCatalog = [];
          }
          // 如果传了选中节点
          if (this.currentCategoryId) {
            this.$nextTick(()=>{
              this.$refs.kngTree && this.$refs.kngTree.setCurrentKey(this.currentCategoryId);
              if (this.$refs.kngTree) {
                let node = this.$refs.kngTree.getNode(this.currentCategoryId);
                // 展开选中节点的父级节点
                this.expandCatalog = [node.data.parentId];
              }
            });
          } else if (this.collegeId && this.sourceType === 'te') { // 如果传了collegeId 而且是讲师那边调用的
            let item = res.length && res[0];
            item = item.children && item.children.length && item.children[0];
            let currentId = item && item.id;
            if (currentId) {
              this.$nextTick(() => {
                this.$refs.kngTree.setCurrentKey(currentId);
                this.expandCatalog = [item.parentId];
              });
            }
          }
          // 如果显示复选框的情况下，默认不可选根目录
          if (this.isNeedCheck && !this.rootCanCheck || !this.isNeedCheck && !this.rootCanSelect) {
            res.forEach(item => {
              this.nocheckList.push(item.id);
            });
          }
          // 单选时设置选中项
          if (!this.isNeedCheck && this.checkId.length > 0) {
            this.$nextTick(() => {
              this.$refs.kngTree.setCurrentKey(this.checkId);
              this.singleTree = this.$refs.kngTree.getCurrentNode();
            });
          }
          this.treeLoading = false;
        }
      } catch (err) {
        this.treeLoading = false;
        console.log(err);
      }
    },
    // 单击目录时的选择
    nodeClick(item) {
      // 如果选择了一级目录 取消选中
      if (this.nocheckList.includes(item.id)) {
        this.$refs.kngTree.setCurrentKey(null);
        return false;
      }
      this.singleTree = item;
      this.$emit('click-node', item.id, item);
    },
    // 获取选中树数据id
    getCheckTree() {
      if (this.isNeedCheck) {
        if (this.$refs.kngTree) {
          let treeLists = this.$refs.kngTree.getCheckedNodes();
          let tempLists = [];
          treeLists.map(item => {
            let obj = {
              label: item.label,
              id: item.id
            };
            tempLists.push(obj);
          });
          return tempLists;
        }
        return [];
      } else {
        return this.singleTree;
      }
    },
    // 检查节点是否显示复选框
    nocheckFilter(data) {
      return this.nocheckList.includes(data.id);
    },
    // 搜索框触发事件
    searchCatalog(value) {
      this.$refs.kngTree && this.$refs.kngTree.filter(value);
    },
    // 根据分类名称过滤分类树
    filterCatalogNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 选中指定节点
    setCurrentNode(categoryId) {
      if (categoryId) {
        this.$nextTick(() => {
          this.$refs.kngTree.setCurrentKey(categoryId);
        });
      }
    },
    // 选择节点变更
    currentNodeChange(data) {
      this.$emit('current-node-change', data.id, data);
    },
    // 获得选中节点
    getCurrentNode() {
      return this.$refs.kngTree.getCurrentNode();
    },
    // 节点被选中时候向外抛出选中列表数据
    checkChange(item, data) {
      this.$emit('check-node-change', data.checkedNodes);
      this.$emit('node-change', [item, data]);
      let arr = [];
      data.checkedNodes.forEach(item => arr.push({ id: item.id, name: item.label}));
      this.checkLists = arr;
      this.$emit('select', arr);
    },
    // 给外面清除全部选中节点的方法
    clearSelection() {
      let tmp = [];
      this.checkLists.filter(item => tmp.push(item.id));
      this.$nextTick(_ => {
      });
      this.$refs.kngTree.setCheckedKeys([]);
    },
    // 给出取消选中指定节点的方法
    cancelTreeSelection(val) {
      let tmp = [];
      this.checkLists.filter(item => {
        if (val.id !== item.id) {
          tmp.push(item.id);
        }
      });
      this.$refs.kngTree.setCheckedKeys(tmp);
    },
    checkDisabled(node) {
      return this.disabledList.includes(node.id);
    }
  },
  watch: {
    checkLists(newValue) {
      this.$refs.kngTree.setCheckedKeys(newValue);
    }
  }
};
</script>
