// import configOldObj from './config';// 待废弃
export const configGovern = c => {
  const plainApisKeyMapping = {
    apiAudit: 'audit',
    basebbs: 'bbs/bbsbase',
    bbsbase: 'bbs/bbsbase',
    bbs: 'bbs',
    bset: 'ssp',
    rank: 'ssp',
    ruledesign: 'ruledesign',
    cc: 'bbs/cc',
    ac: 'ac',
    cer: 'cer',
    core: 'core',
    decorate: 'decorate',
    appGmcenter: 'gmcenterapp',
    archive: 'archive',
    down: 'down',
    engAttend: 'flipapp',
    // engbbs: 'bbs',
    // engeval: 'eval',
    enroll: 'enroll',
    // eval: 'eval',
    // export: 'export',
    flip: 'flipapp',
    hwbase: 'hwbaseapp',
    IM: 'im',
    kng: 'kng',
    knglib: 'knglib',
    studylog: 'studylog',
    msg: 'msg',
    notice: 'notice',
    clock: 'notice',
    or: 'notice',
    infomgmt: 'notice',
    reward: 'ssp',
    signup: 'enrollcenter',
    ssp: 'ssp',
    rewardboss: 'ssp',
    shoptour: 'si',
    o2o: 'o2o',
    o2orpt: 'o2orpt',
    // or: 'or',
    orginit: 'orginit',
    // orgInit: 'orginit',
    ote: 'ote',
    survey: 'teaseng',
    talent: 'talent',
    tcm: 'tcm',
    te: 'te',
    teasbase: 'teasbaseapp',
    tlive: 'tlive',
    // topenyy: 'topenyy',
    udp: 'udp',
    ulcd: 'ulcd',
    utility: 'utility',
    search: 'sls',
    exportPdfTool: 'exporttoolpdf',
    sptalent: 'sptalent',
    speval: 'speval',
    sptalentrv: 'sptalentrv'
    // xt: 'xt',
  };
  const scApisKeyMapping = {
    apiOpcs: 'opcs',
    // exportPdfTool: 'exportpdftool', 不能和，未改造
    file: 'file',
    global: 'misc',
    jf: 'jf',
    jfasync: 'jf',
    jfboss: 'jf',
    misc: 'misc',
    // monitoralert: 'monitoralert',
    // monitordata: 'monitordata',
    pay: 'pay',
    // polestar: 'polestar',
    // sch: 'sch',
    support: 'isvmall',
    isvmall: 'isvmall',
    isvmallboss: 'isvmall/isvmallboss',
    wallet: 'jf',
    walletasync: 'jf'
  };
  const infoApisKeyMapping = {
    // crm: 'crm',
    // op: 'op'
  };
  const socketApisKeyMapping = {
    flipSocket: 'websocketsvc'
  };

  // 报表接口 信创走双集群，其余走单集群
  window.feConfig.envAlias === '1683419934671298561-hw02'
    ? (plainApisKeyMapping.rpt2 = 'rpt2')
    : (scApisKeyMapping.rpt2 = 'rpt2');

  const apiCollection = [
    {
      baseUrlKey: 'apiBaseUrl',
      configMapping: plainApisKeyMapping
    },
    {
      baseUrlKey: 'scApiBaseUrl',
      configMapping: scApisKeyMapping
    },
    {
      baseUrlKey: 'infoBaseUrl',
      configMapping: infoApisKeyMapping
    },
    {
      baseUrlKey: 'wsBaseUrl',
      configMapping: socketApisKeyMapping
    }
  ];
  for (let i = 0; i < apiCollection.length; i++) {
    const { baseUrlKey, configMapping } = apiCollection[i];
    for (let configKey in configMapping) {
      if (c[configKey]) {
        c[configKey] = c[baseUrlKey] + configMapping[configKey] + '/';
      } else {
        c[configKey] = c[baseUrlKey] + '/';
      }
    }
  }
};

configGovern((window.feConfig && window.feConfig.common) || {});
// 强依赖，feConfig 必须提前注入
const config = Object.assign({}, window.feConfig && window.feConfig.common);

/**
 *
 * @param {*} domain
 */
export function setDomainConfig(domain) {
  Object.assign(config, domain);
}

export const apiEnv = (window.feConfig && window.feConfig.apiEnv) || 'prod';
/**
 *
 */
export const source = 501;
/**
 *
 * @param {*} options
 * @returns
 */
export function transformApiParams(options) {
  options = typeof options === 'string' ? { env: options, source } : options;

  if (!options.domain) {
    options.domain = config;
  }

  if (!options.source) {
    options.source = source;
  }

  if (!options.env) {
    options.env = apiEnv;
  }

  return options;
}
/**
 *
 */
export default config;
