<template>
  <div class="yxtbiz-ability-selector">
    <!--<span class="left_border" v-if="tabs.length !== 1"></span>-->
    <!-- <span class="right_border" v-if="tabs.length !== 1"></span> -->
    <!-- <yxt-tabs v-model="activeName" :drawer-top="true">
      <template v-for="(item, index) in tabsOptioin">
        <yxt-tab-pane
          :label="item.label"
          v-if="tabs.includes(item.name)"
          :name="item.name"
          :key="item.name"
        > -->
          <component
            :identify="0"
            ref="ability"
            :is="`CheckAbility`"
            :limit="limit"
            @update="updateList"
            :list="selectedData"
          ></component>
        <!-- </yxt-tab-pane>
      </template>
    </yxt-tabs> -->
    <div class="yxt-list__wrapper">
      <checked-list
        @clear="clear"
        @close="closeTag"
        :limit="limit"
        :list="selectedData"
        :virtualRender="virtualRender"
      />
    </div>
  </div>

</template>

<script>
import CheckAbility from './components/CheckAbility';
import CheckTasks from './components/CheckTasks';
import CheckedList from './components/checkedList';
import { Tabs, TabPane } from 'yxt-pc';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import { AbilitySelectorEnum } from './enum';

export default {
  name: 'YxtbizAbilitySelector',
  components: {
    CheckAbility,
    CheckTasks,
    CheckedList,
    YxtTabs: Tabs,
    YxtTabPane: TabPane
  },
  props: {
    selectedData: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array,
      default: () => [
        'ability',
        'task'
      ]
    },
    virtualRender: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: Infinity
    }
  },
  watch: {
    activeTab: {
      handler(val) {
        this.activeName = this.tabs[val];
      },
      immediate: true
    },
    activeName: {
      handler(val) {
        this.$nextTick(() => {
          this.$refs[this.activeName].update();
        });
      }
    }
  },
  data() {
    return {
      tempArr: [],
      activeName: 'ability',
      tabsOptioin: [
        { name: 'ability', label: i18n.t('biz_ability_positionAbility'), component: 'CheckAbility' }
        // TODO
        // { name: 'task', label: i18n.t('biz_ability_positionTask'), component: 'CheckTasks' }
      ]
    };
  },
  methods: {
    update() {
      this.$refs[this.activeName].update();
    },
    updateList(val) {
      this.$emit('update:selectedData', val);
    },
    closeTag(tag) {
      this.selectedData.splice(this.selectedData.findIndex(data => tag._type === data._type && tag.id === data.id), 1);
      let matchedTab = this.$refs[AbilitySelectorEnum.get(tag['_type'])];
      matchedTab && matchedTab.closeTag(tag);
    },
    clear() {
      this.tempArr = [...this.selectedData];
      this.$emit('update:selectedData', []);
      this.$refs[this.activeName].$refs['multipleTable'].clearSelection();
    }
  }
};
</script>
<style lang="scss" scoped></style>
