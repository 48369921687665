<template>
  <div class="yxtbiz-queslib-preview">
    <yxt-table v-loading="loading" :data="datasList">
      <!-- 试题名称 -->
      <yxt-table-column
        :show-overflow-tooltip="false"
        :label="$t('pc_biz_ote_lbl_quesname')"
        prop="summary"
        :min-width="$isChinese ? '170' : '220'"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.summary" class="yxtbiz-queslib-preview__name">
            <audio-video-icon
              class="mr5 shrink0"
              :type="scope.row.quesType"
            ></audio-video-icon>
            <yxt-tooltip
              open-filter
              class="color-primary-6 hand"
              effect="dark"
              :max-width="300"
              :content="scope.row.summary"
              placement="top"
            >
              <span
                class="ellipsis"
                @click="viewQues(scope.row)"
                >{{ scope.row.summary }}</span
              >
            </yxt-tooltip>
          </div>
          <!-- 题干纯图片 -->
          <span
            v-if="!scope.row.summary"
            @click="viewQues(scope.row)"
            class="yxtbiz-queslib-preview__name"
          >
            <audio-video-icon
              class="mr5 vertical-align-middle"
              :type="scope.row.quesType"
            ></audio-video-icon>
            <yxt-svg
              :remote-url="svgRemote"
              icon-class="ques-image"
              class="hand vertical-align-middle"
              width="18px"
              height="18px"
            ></yxt-svg>
          </span>
        </template>
      </yxt-table-column>
      <!-- 考核点 -->
      <yxt-table-column
        :show-overflow-tooltip="true"
        :label="$t('pc_biz_ote_lbl_assessmentpoint')"
        prop="pointNames"
        :width="$isChinese ? '100' : '140'"
      >
      </yxt-table-column>
      <!-- 题型 -->
      <yxt-table-column
        :show-overflow-tooltip="false"
        :label="$t('pc_biz_ote_lbl_questiontype')"
        prop="type"
        :width="$isChinese ? '90' : '140'"
      >
        <template slot-scope="scope">
          <span>{{ getQuestionType(scope.row.type) }}</span>
        </template>
      </yxt-table-column>
      <!-- 难度 -->
      <yxt-table-column
        :show-overflow-tooltip="false"
        :label="$t('pc_biz_ote_lbl_queslevel')"
        prop="levelType"
        :width="$isChinese ? '80' : '120'"
      >
        <template slot-scope="scope">
          <span>{{ getQuestionLevalType(scope.row.levelType) }}</span>
        </template>
      </yxt-table-column>
    </yxt-table>
    <div class="mt16 clearfix">
      <yxt-pagination
        class="pull-right"
        :page-size="pageSize"
        :total="datasListCount"
        :current-page="currentPage"
        simple-total
        layout="total, prev, pager, next"
        @current-change="handleCurrentChange"
      ></yxt-pagination>
    </div>
    <!-- 试题查看 -->
    <yxt-drawer
      ref="quesViewDrawer"
      size="640px"
      :title="$t('pc_biz_ote_lbl_quesview')"
      :visible.sync="quesDetailData.dialogVisible"
      :before-close="handleClose"
      :destroyOnClose="true"
    >
      <ques-view :id="quesDetailData.id"></ques-view>
    </yxt-drawer>
  </div>
</template>

<script>
import { QUES_TYPE_NAMES, QUES_LEVEL_NAMES } from './utils';
import { getQuesOfQuesLib } from './service';
import { Table, TableColumn, Pagination, Tooltip, Link } from 'yxt-pc';
import catalogDropdown from './selector/catalogDropdown.vue';
import audioVideoIcon from './selector/audioVideoIcon';
import quesView from './quesView.vue';

export default {
  name: 'YxtbizQueslibPreview',
  components: {
    quesView,
    audioVideoIcon,
    catalogDropdown,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtTooltip: Tooltip,
    YxtLink: Link
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      svgRemote: `${this.$staticBaseUrl}ufd/55a3e0/ote/pc/svg`,
      quesDetailData: {
        dialogVisible: false,
        id: ''
      },
      datasList: [],
      datasListCount: 0,
      pageSize: 10,
      currentPage: 0,
      loading: false
    };
  },
  watch: {
    id(v) {
      this.getAllQues();
    }
  },
  created() {
    this.getAllQues();
  },
  methods: {
    // 获取试题
    getAllQues() {
      this.loading = true;
      getQuesOfQuesLib(
        {
          current: this.currentPage,
          limit: this.pageSize,
          orderby: 'updateTime',
          direction: 'DESC'
        },
        {
          skipPermission: true,
          libId: this.id,
          isDuplicate: 0,
          status: -1,
          type: -1,
          levelType: -1
        }
      )
        .then(res => {
          this.datasList = res.datas;
          this.datasListCount = res.paging.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取难度名称
    getQuestionLevalType(val) {
      return QUES_LEVEL_NAMES[val]
        ? this.$t(QUES_LEVEL_NAMES[val])
        : '';
    },
    // 获取题型名称
    getQuestionType(val) {
      return QUES_TYPE_NAMES.length > val ? this.$t(QUES_TYPE_NAMES[val]) : '';
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getAllQues();
    },
    viewQues(e) {
      this.quesDetailData.id = e.id;
      this.quesDetailData.dialogVisible = true;
    },
    handleClose(done) {
      done();
    }
  }
};
</script>
