<template>
  <div class="yxtbiz-certificate-selector">
    <div class="yxtbiz-certificate-selector__left">
      <div class="clearfix font-size-14">
        <filter-condition
          v-if="moreFilter"
          ref="filterBox"
          :oldSearch="cerSearch"
          :otherCertificate="otherCertificate"
          :catalogList="catalogList"
          @search="handleSearch"
        />
        <yxtbiz-group-org-select
          v-if="groupSelect"
          class="mr12"
          :clearable="false"
          :multiple="false"
          @change="changeOrg"
          setCurrentOrg
        ></yxtbiz-group-org-select>
        <yxt-input
          style="width:240px;"
          :placeholder="$t('pc_biz_cer_tip_namesearch')"
          v-model="searchName"
          searchable
          @search="cerNameSearch"
          maxlength="50"
        >
        </yxt-input>
        <!--证书范围-->
        <yxt-select v-if="showRange"
                    v-model="cerSearch.range"
                    class="width144 ml12"
                    @change="handleSearch">
          <yxt-option v-for="(item, index) in range"
                      :key="index"
                      :label="item.label"
                      :value="item.value"></yxt-option>
        </yxt-select>
      </div>
      <div class="yxtbiz-certificate-selector__main mt16">
        <yxt-table
          v-loading="loading"
          :data="cerTempList"
          @select-all="handleSelectAll"
          @select="select"
          ref="stb"
          :height="tableHeight"
          class="yxtbiz-certificate-selector__table"
          :default-sort="{ prop: 'updateTime', order: 'desc' }"
          @sort-change="sortChange"
        >
          <yxt-table-column
            v-if="!isSingle"
            type="selection"
            :show-overflow-tooltip="false"
            width="38"
            align="right"
            clear-padding="left-right"
          ></yxt-table-column>
          <yxt-table-column
            :label="$t('pc_biz_cer_lbl_cername').d('证书名称')"
            min-width="246"
          >
            <template slot-scope="scope">
              <yxt-tooltip
                :open-filter="true"
                :content="scope.row.name"
                class="v-mid d-in-block"
                :class="{ 'yxt-tooltip-tag': scope.row.expired }"
                placement="top">
                <div class="ellipsis nowrap">{{ scope.row.name }}</div>
              </yxt-tooltip>
              <yxt-tag v-if="scope.row.expired" type="failure" size="mini" class="ml8">{{ $t('pc_biz_cer_lbl_expired').d('已过期') }}</yxt-tag>
            </template>
          </yxt-table-column>
          <yxt-table-column
            v-if="columns.includes('catalog')"
            :label="$t('pc_biz_cer_classification').d('分类')"
            min-width="120"
            show-overflow-tooltip
          >
           <template slot-scope="scope">
              {{ scope.row.catalogName || '--' }}
            </template>
          </yxt-table-column>
          <yxt-table-column
            v-if="columns.includes('type')"
            :label="$t('pc_biz_cer_type').d('类型')"
            width="100"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{
                scope.row.type
                  ? $t('pc_biz_cer_other_certificate').d('其他证书')
                  : $t('pc_biz_cer_template_certificate').d('模板证书')
              }}
            </template>
          </yxt-table-column>
          <yxt-table-column
            v-if="columns.includes('state')"
            :label="$t('pc_biz_cer_state').d('状态')"
            width="95"
          >
            <template slot-scope="scope">
              {{
                scope.row.disabled
                  ? $t('pc_biz_cer_disable').d('禁用')
                  : $t('pc_biz_cer_enable').d('启用')
              }}
            </template>
          </yxt-table-column>
          <yxt-table-column
            v-if="validMonth"
            :key="1"
            :label="$t('pc_biz_cer_lbl_valid_month').d('有效期（月）')"
            prop="validMonth"
            width="120"
          ></yxt-table-column>
          <yxt-table-column
            :label="$t('pc_biz_cer_lbl_updatetime').d('更新日期')"
            prop="updateTime"
            sortable="custom"
            :formatter="shortDate"
            align="left"
            width="125"
          ></yxt-table-column>
          <yxt-table-column
            :label="$t('pc_biz_cer_lbl_operate').d('操作')"
            width="100"
            align="left"
            v-if="isSingle"
          >
            <template slot-scope="scope">
              <yxt-link
                @click="selectCertif(scope.row)"
                :underline="false"
                type="primary"
                >{{
                  value && value.id === scope.row.id
                    ? $t('pc_biz_cer_btn_cancleselect')
                    : $t('pc_biz_cer_btn_select')
                }}</yxt-link
              >
            </template>
          </yxt-table-column>
        </yxt-table>
      </div>
      <div
        class="mt16 pr"
        :class="selectAll ? 'yxtbiz-page__content' : 'clearfix'"
      >
        <div v-if="selectAll" class="flex">
          <yxt-button
            type="text"
            class="lh32"
            :class="loadingAll ? 'color-primary-4' : ''"
            @click="loadAll"
            :disabled="!cerTempList.length"
            >{{ $t('biz_udp_all_filter_results') }}</yxt-button
          >
          <div class="yxtbiz-loading__all" v-loading="loadingAll"></div>
        </div>
        <yxt-pagination
          class="pull-right"
          :page-size="cerQuery.limit"
          :total="count"
          :current-page="page"
          @size-change="sizeChange"
          @current-change="pageChange"
          layout="total, prev, pager, next"
          :page-count="totalPage"
        ></yxt-pagination>
      </div>
    </div>
    <div
      class="yxtbiz-certificate-selector__right"
      v-if="!isSingle"
      :class="{ 'yxtbiz-certificate-selector__right-padding': limit }"
    >
      <div v-if="limit" class="mb12">
        {{ $t('pc_biz_ote_lbl_chosenquestions') }}：{{
          Object.values(selected).length
        }}/{{ limit }}
      </div>
      <yxt-scrollbar :fit-height="true">
        <yxt-tag
          @close="deleteItem(item)"
          class="mr5"
          size="small"
          type="info"
          style="margin-bottom:5px"
          closable
          :key="item.id"
          v-for="item in list"
        >
          <yxt-tooltip :content="item.name" :openFilter="true">
            <span class="yxtbiz-user-selector-tag">{{ item.name }}</span>
          </yxt-tooltip></yxt-tag
        >
      </yxt-scrollbar>
      <yxt-button
        type="text"
        class="yxtbiz-user-clear font-size-12 color-gray-9 hover-primary-6"
        @click="clear"
        :disabled="Object.values(selected).length === 0"
        >{{ $t('pc_biz_cer_btn_clearall') }}</yxt-button
      >
    </div>
  </div>
</template>

<script>
import { getCerList, getCatalogList } from './service';
import dateUtil from 'yxt-biz-pc/src/utils/date';
import resizeTable from '../mixins/resizeTable';
import { Table, TableColumn, Pagination, Tooltip, Link, Tag } from 'yxt-pc';
import filterCondition from './components/filterCondition.vue';

export default {
  name: 'YxtbizCertificateSelector',
  components: {
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtPagination: Pagination,
    YxtTooltip: Tooltip,
    YxtLink: Link,
    YxtTag: Tag,
    filterCondition
  },
  mixins: [resizeTable],
  props: {
    value: {
      type: Array | Object,
      default: () => {
        return [];
      }
    },
    functionCode: {
      type: String,
      default: ''
    },
    dataPermissionCode: {
      type: String,
      default: ''
    },
    validMonth: {
      type: Boolean,
      default: false
    },
    // 是否包含集团共享的资源
    groupShared: {
      type: Boolean,
      default: true
    },
    // 是否集团化，支持跨机构选择
    groupSelect: {
      type: Boolean,
      default: false
    },
    // 是否显示更多筛选
    moreFilter: {
      type: Boolean,
      default: false
    },
    showRange: {
      type: Boolean,
      default: false
    },
    // 是否显示其他证书
    otherCertificate: {
      type: Boolean,
      default: false
    },
    // 额外显示的列表列 [catalog, type, state]
    columns: {
      type: Array,
      default: () => []
    },
    // 是否显示全部筛选结果按钮
    selectAll: {
      type: Boolean,
      default: false
    },
    // 选择上限
    limit: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      refTable: 'stb',
      tableHeight: 0,
      page: 1,
      cerQuery: {
        limit: 50,
        offset: 0,
        orderby: 'updateTime',
        direction: 'DESC'
      },
      cerSearch: {
        name: '',
        navCode: this.functionCode,
        dataPermissionCode: this.dataPermissionCode,
        updateDate: [], // 更新时间
        disabled: 0, // 证书状态 -1 全部 0 启用 1 禁用
        catalogIds: [], // 证书分类
        type: 0, // 证书类型 -1 全部 0 模板证书 1 其他证书
        range: 0
      },
      loaded: 1,
      loading: false,
      totalPage: 0,
      count: 0,
      cerTempList: [],
      selected: {},
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      isSingle: true,
      catalogList: [], // 证书分类
      loadingAll: false,
      searchName: '', // 模糊查找的证书名称
      range: [
        {
          value: 2,
          label: this.$t('pc_biz_cer_created_by_me').d('我创建的')
        },
        {
          value: 1,
          label: this.$t('pc_biz_cer_lbl_myresponsible').d('我负责的')
        },
        {
          value: 0,
          label: this.$t('pc_biz_cer_lbl_myjurisdiction').d('我管辖的')
        },
        {
          value: 3,
          label: this.$t('pc_biz_cer_lbl_share_with_me').d('与我共享的')
        }
      ]
    };
  },
  computed: {
    list() {
      return Object.values(this.selected);
    }
  },
  created() {
    this.isSingle =
      !this.value || (!this.value.length && this.value.length !== 0);
    this.formatModel(this.value);
    this.cerSearch.type = this.otherCertificate ? '' : 0;
    this.cerSearch.disabled = this.moreFilter ? '' : 0;
    !this.groupSelect && this.getCerList();
    !this.groupSelect && this.moreFilter && this.getCatalogList();
  },
  methods: {
    // 隐藏更多筛选框
    hideMoreFilter() {
      this.$refs.filterBox && this.$refs.filterBox.cancelSearch();
    },
    // 获取证书分类
    async getCatalogList() {
      this.catalogList = await getCatalogList({
        orgId: this.cerSearch.orgId || ''
      });
    },
    changeOrg(org) {
      this.hideMoreFilter();
      this.cerSearch.orgId = org ? org.orgId : undefined;
      this.moreFilter && (this.cerSearch.catalogIds = []) && this.getCatalogList();
      this.getCerList(true);
    },
    shortDate(row, col, val, index) {
      if (!val) return '';
      if (typeof val === 'string') {
        val = val.replace(/-/g, '/');
      }
      return dateUtil.format(new Date(val), 'yyyy-MM-dd');
    },
    handleSearch(search) {
      this.cerSearch = { ...this.cerSearch, ...search };
      this.getCerList(true);
    },
    getCerList(isReset) {
      if (isReset) {
        this.page = 1;
        this.cerQuery.offset = 0;
      }
      this.cerSearch.isFilterShareSource = this.groupShared ? 0 : 1;
      this.cerSearch.groupFuncReq = this.groupSelect ? 1 : 0;
      this.loading = true;
      const search = {
        ...this.cerSearch,
        type: !this.cerSearch.type && this.cerSearch.type !== 0 ? -1 : this.cerSearch.type,
        disabled:
          !this.cerSearch.disabled && this.cerSearch.disabled !== 0 ? -1 : this.cerSearch.disabled
      };
      getCerList(this.cerQuery, search)
        .then(result => {
          this.cerTempList = result.datas;
          this.totalPage = result.paging.pages;
          this.count = result.paging.count;
          this.loaded--;
          this.loading = false;
          this.$nextTick(() => {
            this.setChecked();
          });
        })
        .catch(e => {
          this.cerTempList = [];
          this.totalPage = 0;
          this.count = 0;
          this.loaded--;
          this.loading = false;
          console.log(e);
        });
    },
    // 全选筛选结果
    async loadAll() {
      if (this.limit && this.limit === Object.values(this.selected).length) {
        this.$message({
          type: 'warning',
          message: this.$t('pc_biz_cer_msg_beyond_limit', [this.limit]).d(
            '证书数量不能超过' + this.limit
          )
        });
        return;
      }

      this.loadingAll = true;
      const limit = 1000;
      const total =
        this.limit && this.limit < this.count ? this.limit : this.count;
      const reqCount = Math.ceil(total / limit); // 循环次数
      const reqArrs = []; // 请求数组
      for (let i = 0; i < reqCount; i++) {
        const params = Object.assign({}, this.cerQuery, {
          limit: limit,
          offset: limit * i
        });
        reqArrs.push(params);
      }
      try {
        const search = {
          ...this.cerSearch,
          type: !this.cerSearch.type && this.cerSearch.type !== 0 ? -1 : this.cerSearch.type,
          disabled:
            !this.cerSearch.disabled && this.cerSearch.disabled !== 0 ? -1 : this.cerSearch.disabled
        };
        const results = await Promise.all(
          reqArrs.map(params => getCerList(params, search))
        );
        const dataSource = [];
        results.forEach(res => {
          dataSource.push(...res.datas);
        });
        let overNum = false;
        dataSource.forEach(obj => {
          if (!this.limit || this.limit > Object.values(this.selected).length) {
            this.selected[obj.id] = obj;
            const tableRow = this.cerTempList.find(r => r.id === obj.id);
            tableRow &&
              this.$refs[this.refTable].toggleRowSelection(tableRow, true);
          } else {
            overNum = true;
          }
        });

        if (!overNum && this.limit && this.count > this.limit) {
          overNum = true;
        }
        overNum &&
          this.$message({
            type: 'warning',
            message: this.$t('pc_biz_cer_msg_maxselectnum', [this.limit]).d(
              '部分证书未被选中，因为选择的证书数量已经超过' + this.limit
            )
          });
        this.outPut();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingAll = false;
      }
    },
    cerNameSearch() {
      this.hideMoreFilter();
      this.cerSearch.name = this.searchName.trim();
      this.getCerList(true);
    },
    sortChange(sort) {
      this.cerQuery.direction = sort.order;
      this.cerQuery.orderby = sort.prop;
      this.getCerList();
      return false;
    },
    sizeChange(size) {
      this.cerQuery.limit = size;
      this.getCerList(true);
    },
    pageChange(page) {
      this.page = page;
      this.cerQuery.offset = (page - 1) * this.cerQuery.limit;
      this.getCerList();
    },
    // 单选时选择
    selectCertif(item) {
      if (item && this.selected[item.id]) {
        delete this.selected[item.id];
        this.$emit('input', null);
        this.$emit('select', null);
      } else {
        this.selected[item.id] = item;
        this.$emit('input', item);
        this.$emit('select', item);
      }
    },
    // 选择某个
    select(item, row) {
      let isAdd = item.some(v => {
        return v.id === row.id;
      });
      if (!isAdd) {
        delete this.selected[row.id];
      } else {
        if (!this.limit || this.limit > Object.values(this.selected).length) {
          this.selected[row.id] = row;
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('pc_biz_cer_msg_beyond_limit', [this.limit]).d(
              '证书数量不能超过' + this.limit
            )
          });
          this.$refs[this.refTable].toggleRowSelection(row, false);
        }
      }
      this.outPut();
    },
    // 全选
    handleSelectAll(items) {
      if (this.limit && this.limit === Object.values(this.selected).length) {
        this.cerTempList.forEach(obj => {
          !this.selected[obj.id] &&
            this.$refs[this.refTable].toggleRowSelection(obj, false);
        });
        this.$message({
          type: 'warning',
          message: this.$t('pc_biz_cer_msg_beyond_limit', [this.limit]).d(
            '证书数量不能超过' + this.limit
          )
        });
        return;
      }

      let isAdd = items.length !== 0;
      let overNum = false;
      this.cerTempList.forEach(obj => {
        if (!isAdd) {
          delete this.selected[obj.id];
        } else {
          if (!this.limit || this.limit > Object.values(this.selected).length) {
            this.selected[obj.id] = obj;
          } else {
            overNum = true;
            !this.selected[obj.id] &&
              this.$refs[this.refTable].toggleRowSelection(obj, false);
          }
        }
      });
      overNum &&
        this.$message({
          type: 'warning',
          message: this.$t('pc_biz_cer_msg_maxselectnum', [this.limit]).d(
            '部分证书未被选中，因为选择的证书数量已经超过' + this.limit
          )
        });
      this.outPut();
    },
    // 删除已选择
    deleteItem(item) {
      let row = item;
      for (let index = 0; index < this.cerTempList.length; index++) {
        const element = this.cerTempList[index];
        if (element.id === item.id) {
          row = element;
          break;
        }
      }
      if (row) {
        this.$refs['stb'].toggleRowSelection(row, false);
      }
      delete this.selected[item.id];
      this.$forceUpdate();
      this.outPut();
    },
    // 清空
    clear() {
      let row = null;
      for (const key in this.selected) {
        if (this.selected.hasOwnProperty(key)) {
          for (let index = 0; index < this.cerTempList.length; index++) {
            const element = this.cerTempList[index];
            if (element.id === key) {
              row = element;
              break;
            }
          }
          if (row) {
            this.$refs['stb'].toggleRowSelection(row, false);
          }
        }
      }
      this.selected = {};
      this.outPut();
    },
    outPut() {
      const selArray = Object.values(this.selected);
      this.$emit('input', selArray);
      this.$emit('select', selArray);
    },
    setChecked() {
      this.cerTempList.forEach(obj => {
        if (this.selected.hasOwnProperty(obj.id)) {
          this.$refs['stb'].toggleRowSelection(obj, true);
        } else {
          this.$refs['stb'].toggleRowSelection(obj, false);
        }
      });
    },
    formatModel(v) {
      this.selected = {};
      if (v) {
        if (this.isSingle) {
          if (v.id) {
            this.selected[v.id] = v;
          }
        } else {
          v.forEach(obj => {
            this.selected[obj.id] = obj;
          });
        }
      }
    }
  },
  watch: {
    value(v) {
      this.formatModel(v);
    }
  }
};
</script>
