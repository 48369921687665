// 用户中心导航假数据，不走权限
import { isOpenData } from 'yxt-biz-pc/src/utils/shared';
import { i18n } from 'yxt-biz-pc/packages/common-util/changeLangs';
import { GetNavBytokenV2, GetAppNavBytoken, getNavVersion } from './service';
// 获取当前产品信息，区分各个产品的缓存
import { defaultPath, defaultProductInfo, defaultProductPath } from './const';

export const navCatchKeyStu = `${defaultPath}nav_datas_1`;
export const navExpireStuKey = `${defaultPath}nav_datas_1_expire`; // 学员端导航过期时间
export const navCatchKeyManagement = `${defaultPath}nav_datas_2`;
export const navExpireManagementKey = `${defaultPath}nav_datas_2_expire`; // 管理端导航过期时间
const initNavVersion = 946684800000; // 以2000-01-01为基准，若新版本时间戳小于该值则不刷新导航缓存
const navCatch = {};

function updateNavCatch() {
  const navExpireManagement = localStorage.getItem(navExpireManagementKey);
  const navExpireStu = localStorage.getItem(navExpireStuKey);
  // 过期时间大于当前时间====》未过期直接取缓存数据
  if (navExpireManagement && Number(navExpireManagement) >= Date.now()) {
    navCatch[navCatchKeyManagement] =
      window.localStorage[navCatchKeyManagement];
  }
  if (navExpireStu && Number(navExpireStu) >= Date.now()) {
    navCatch[navCatchKeyStu] = window.localStorage[navCatchKeyStu];
  }
}

updateNavCatch();

let userNavs = [
  {
    id: '15bff56a-8041-4e18-bda7-c8cbcc49ad701',
    navId: '00b782e2-f070-4b74-b448-edb8d04450b51',
    parentId: null,
    functionCode: 'core_user_center',
    code: 'core_user_center',
    name: 'pc_biz_nav_btn_usercenter', // 用户中心
    pageUrl: '',
    pageType: 0,
    openMode: 0,
    showed: 0,
    navIcon: 'core_user_center',
    appType: 0,
    associationType: 0,
    orderIndex: 3,
    type: 2,
    _fixNav: 1,
    isAllOrgPermission: 1
  },
  {
    id: '044bc6a2-8bb0-42d2-9c47-d63249f781721',
    navId: 'f4b08cd5-e375-4c0c-8ba4-913266341dc71',
    parentId: '15bff56a-8041-4e18-bda7-c8cbcc49ad701',
    functionCode: 'core_user_info',
    code: 'core_user_info',
    name: 'biz_core_personal_info', // '个人资料'
    pageUrl: '/#/userinfo',
    pageType: 1,
    openMode: 0,
    showed: 1,
    appType: 0,
    associationType: 0,
    orderIndex: 5,
    type: 2,
    _fixNav: 1,
    isAllOrgPermission: 1
  },
  {
    id: '044bc6a2-8bb0-42d2-9c47-d63249f781722',
    navId: 'f4b08cd5-e375-4c0c-8ba4-913266341dc72',
    parentId: '15bff56a-8041-4e18-bda7-c8cbcc49ad701',
    functionCode: '',
    code: 'core_account_safe',
    name: 'biz_core_account_safe', // 账号与安全
    pageUrl: '/#/user/accountsafe',
    pageType: 1,
    showed: 1,
    type: 2,
    _fixNav: 1,
    orderIndex: 6,
    appType: 0,
    isAllOrgPermission: 1
  },
  {
    id: '044bc6a2-8bb0-42d2-9c47-d63249f781723',
    navId: 'f4b08cd5-e375-4c0c-8ba4-913266341dc73',
    parentId: '15bff56a-8041-4e18-bda7-c8cbcc49ad701',
    functionCode: '',
    code: 'core_notice_center',
    name: 'biz_core_notice_center', // '通知中心'
    pageUrl: '/#/user/noticecenter',
    pageType: 1,
    showed: 1,
    type: 2,
    _fixNav: 1,
    orderIndex: 7,
    appType: 0,
    isAllOrgPermission: 1
  }
];
// 登录
export const goLogin = () => {
  if (!window.location.origin) {
    window.location.origin =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '');
  }
  // window.location.href = window.location.origin + '/#/login';
  window.location.href = `${defaultProductInfo.allPath}/#/login`;
};

export function generateUserNavs() {
  const parentId = window.localStorage.getItem('orgId');
  if (defaultProductPath) {
    userNavs.forEach(item => {
      item.pageUrl = `${defaultProductPath}${item.pageUrl}`;
    });
  }
  userNavs[0].parentId = parentId;
  return userNavs.filter(v => {
    v.name = i18n.t(v.name);
    return isOpenData()
      ? !['core_user_info', 'core_account_safe'].includes(v.code)
      : true;
  });
}

export const getNavsByType = navType => {
  let cacheNavDatas = null;
  let isLoadedManage = false;
  let cacheNavDatasManage;
  try {
    // 获取当前导航的本地缓存
    if (navType === 2) {
      cacheNavDatas =
        navCatch[navCatchKeyManagement] &&
        JSON.parse(navCatch[navCatchKeyManagement]);
      isLoadedManage = true;
    } else if (navType === 1) {
      // 获取管理端导航及权限
      cacheNavDatasManage =
        navCatch[navCatchKeyManagement] &&
        JSON.parse(navCatch[navCatchKeyManagement]);
      if (
        cacheNavDatasManage &&
        cacheNavDatasManage[0] &&
        cacheNavDatasManage[0].datas
      ) {
        isLoadedManage = true;
      }
    }
  } catch (e) {
    console.error(e);
  }

  return {
    cacheNavDatas,
    cacheNavDatasManage,
    isLoadedManage
  };
};

function getLastNavVersion(key) {
  try {
    let cache = localStorage.getItem(key);
    // eslint-disable-next-line
    if (parseInt(cache, 10) == cache) return parseInt(cache, 10);
    return initNavVersion;
  } catch (e) {
    return initNavVersion;
  }
}

export async function getNavBytokenV2Catch(navType, key, curNavVersion, product) {
  // 更新导航，导航有效期为7天，即有效期在7天内并且接口请求的导航更新的版本<=前端缓存的版本，走缓存，其余调用接口
  if (!product) {
    updateNavCatch();
    const localhostKey = `${defaultPath}nav_datas_`;
    const catchNav =
      navCatch[localhostKey + navType] &&
      JSON.parse(navCatch[localhostKey + navType]);
    const lastNavVersion = getLastNavVersion(key);
    // 新版本时间戳小于等于之前的版本号，则取缓存的导航数据
    if (curNavVersion <= lastNavVersion && catchNav && catchNav[0]) {
      console.log('导航版本未更新  &  有缓存数据，取浏览器缓存数据');
      return catchNav[0];
    }
  }

  const data = await GetNavBytokenV2(navType);
  console.log('导航版本已更新   或    无缓存数据，请求导航接口');
  if (data && data.datas && data.datas.length) {
    data.datas.forEach(nav => {
      if (nav.type === 5 && nav.parentId === localStorage.getItem('orgId')) {
        nav.showed = 0; // 工作台导航不在顶部展示
      }
    });
  }

  if (navType === 2 && data && data.datas && data.datas.length) {
    generateUserNavs().forEach(nav => {
      if (data.datas) {
        data.datas.push(nav);
      }
    });
  }
  if (!product) {
    localStorage.setItem(key, curNavVersion);
  }
  return data;
}

async function getAppNavBytokenCatch(navType, manageKey, curNavVersion) {
  if (navType === 1) return [];
  const localhostKey = `${defaultPath}nav_datas_`;
  // const key = 'nav_last_version_app';
  const key = `${defaultPath}nav_last_version_app`;
  const catchNavStr = navCatch[localhostKey + navType];
  const catchNav = catchNavStr && JSON.parse(catchNavStr);
  const lastNavVersion = getLastNavVersion(key);
  // const curNavVersion = await getSearchPlatformVCatch(navVersion);

  // if (lastNavVersion === curNavVersion && catchNav && catchNav[1]) {
  //   return catchNav[1];
  // }

  // 新版本时间戳小于等于之前的版本号，则取缓存的导航数据
  if (curNavVersion <= lastNavVersion && catchNav && catchNav[1]) {
    console.log('应用导航版本未更新  &  有缓存数据，取浏览器缓存数据');
    return catchNav[1];
  }

  console.log('应用导航版本已更新   或    无缓存数据，请求应用导航接口');
  return GetAppNavBytoken()
    .then(data => {
      localStorage.setItem(key, curNavVersion);
      return data;
    })
    .catch(res => {
      console.error('导航获取失败');
      localStorage.setItem(key, []);
    });
}

// 获取导航版本，获取失败：有缓存取缓存，无缓存请求接口
async function getNavCurrentVersion(navType) {
  try {
    if (!navType) {
      return initNavVersion;
    }
    const { updateTime } = await getNavVersion(navType);
    return updateTime;
  } catch (e) {
    return initNavVersion;
  }
}
export const navUtil = {
  promiseCatch: null,
  getNavsReject: null,
  getNavsAndSetCatch: function(navType) {
    this.promiseCatch = new Promise(async(resolve, reject) => {
      try {
        let svcAll = [];
        console.log('获取导航版本：' + navType);
        const key = `${defaultPath}nav_last_version_`;
        const navVersionKey = key + navType;
        const updateTime = await getNavCurrentVersion(navType);
        if (navType === 1) {
          svcAll.push(getNavBytokenV2Catch(1, navVersionKey, updateTime)); // 后台导航使用m2新接口
          svcAll.push([]);
        } else {
          svcAll.push(getNavBytokenV2Catch(2, navVersionKey, updateTime)); // 后台导航使用m2新接口
          svcAll.push(getAppNavBytokenCatch(2, navVersionKey, updateTime)); // 应用导航数据
        }

        const data = await Promise.all(svcAll);
        const stamp = Date.now() + 7 * 24 * 60 * 60 * 1000;
        // 记录当前导航数据
        if (navType === 1) {
          navCatch[navCatchKeyStu] = localStorage[
            navCatchKeyStu
          ] = JSON.stringify([data[0], data[1]]);
          localStorage.setItem(navExpireStuKey, stamp);
        } else {
          // 前台导航时，额外记录后台导航数据,判断后台权限
          navCatch[navCatchKeyManagement] = localStorage[
            navCatchKeyManagement
          ] = JSON.stringify([data[0], data[1]]);
          localStorage.setItem(navExpireManagementKey, stamp);
        }

        resolve({
          data
        });
      } catch (e) {
        reject(e);
      } finally {
        this.promiseCatch = null;
      }
    });
    return this.promiseCatch;
  },

  clearNavCatch() {
    window.localStorage[navCatchKeyStu] = '';
    window.localStorage[navCatchKeyManagement] = '';
    navCatch[navCatchKeyStu] = '';
    navCatch[navCatchKeyManagement] = '';
  }
};
