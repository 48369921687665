var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    { tag: "component", class: { "is-scrolling": _vm.isScrolling } },
    [
      _c("div", { style: _vm.frontStyle }),
      _vm._t("default", null, { list: _vm.renderList }),
      _c("div", { style: _vm.footerStyle }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }