import { oteApi } from 'yxt-biz-pc/packages/api';

// 获取考试安排列表
export const getPraList = ({ limit, current, orderby, direction, groupFuncReq, offset, queryOrgId, ...body }) => {
  return oteApi.post('/pra/list/select/report', body, { params: { limit, current, offset, orderby, direction, groupFuncReq, queryOrgId } });
};

// 获取考试安排列表, 不分页
export const getPraListNopage = ({ limit, current, orderby, direction, groupFuncReq, offset, queryOrgId, ...body }) => {
  return oteApi.post('/pra/list/select/all', body, { params: { limit, current, offset, orderby, direction, groupFuncReq, queryOrgId } });
};

// 获取考试编码配置
export const getExamCodeSetting = () => {
  return oteApi.get('/field/projectcode');
};
