import { teApi, kngApi } from 'yxt-biz-pc/packages/api';

// 拼接URL参数
const linkSubString = (url, data) => {
  if (url === null || url === '') {
    return url;
  }
  let queryString = '';
  if (typeof data === 'object') {
    for (var i in data) {
      queryString += i + '=' + data[i] + '&';
    }
  }
  if (url.indexOf('?') > url.indexOf('/')) {
    url += '&';
  } else {
    url += '?';
  }
  if (queryString !== '') {
    queryString = queryString.substr(0, queryString.length - 1);
  }
  url += queryString;
  return url;
};

export default {
  // 获取线下课列表
  getCourseList(urlObj, bodyObj) {
    return teApi.post(linkSubString('/te/offline/select', urlObj), bodyObj);
  },
  // 获取知识库列表数据
  getKngLists(queryParams, bodyParams) {
    return teApi.post(linkSubString('/external/knglist', queryParams), bodyParams);
  },
  // 获取知识库目录树
  getKngTree(bodyParams) {
    // return kngApi.post('kngCatalog/treeCache', bodyParams);
    return kngApi.post('kngCatalog/tree', bodyParams);
  }
};
