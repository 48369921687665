var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checked_list" },
    [
      _vm.list.length > 0
        ? _c(
            "div",
            { staticClass: "checked_list_box" },
            [
              !_vm.virtualRender
                ? _c("yxt-scrollbar", { attrs: { "fit-height": true } }, [
                    _c(
                      "div",
                      { staticStyle: { height: "100%" } },
                      _vm._l(_vm.list, function (source) {
                        return _c(
                          "yxt-tag",
                          {
                            key: source.id,
                            staticClass: "mb8 tag",
                            attrs: {
                              "disable-transitions": "",
                              size: "mini",
                              type: "info",
                              closable: "",
                            },
                            on: {
                              close: function ($event) {
                                return _vm.handleClose(source)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "tag_span" }, [
                              _vm._v(_vm._s(source.name)),
                            ]),
                          ]
                        )
                      }),
                      1
                    ),
                  ])
                : _c(
                    "div",
                    { staticStyle: { height: "100%" } },
                    [
                      _c("yxtbiz-virtual-list", {
                        staticStyle: { height: "100%", overflow: "hidden" },
                        attrs: {
                          "is-scrollbar": "",
                          "data-sources": _vm.list,
                          "data-key": "id",
                          "estimate-size": 32,
                          keeps: 50,
                          "item-tag": "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ source }) {
                                return [
                                  _c(
                                    "yxt-tag",
                                    {
                                      key: source.id,
                                      staticClass: "mb8 tag",
                                      attrs: {
                                        "disable-transitions": "",
                                        size: "mini",
                                        type: "info",
                                        closable: "",
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.handleClose(source)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "tag_span" }, [
                                        _vm._v(_vm._s(source.name)),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1363310159
                        ),
                      }),
                    ],
                    1
                  ),
            ],
            1
          )
        : _c("yxt-empty", { staticStyle: { top: "calc(50% - 133px)" } }),
      _c(
        "div",
        { staticClass: "operation" },
        [
          _c(
            "yxt-button",
            {
              staticClass: "font-size-14 color-gray-9 hover-primary-6",
              attrs: { type: "text", disabled: _vm.list.length === 0 },
              on: { click: _vm.clear },
            },
            [_vm._v(_vm._s(_vm.$t("biz_udp_clear_all")))]
          ),
          _vm.showLimit
            ? _c("div", { staticClass: "limit" }, [
                _vm._v(_vm._s(_vm.list.length || "")),
                _vm.list.length > 0
                  ? _c("span", [_vm._v(_vm._s(`/${_vm.limit}`))])
                  : _c("span", [_vm._v(_vm._s(`0/${_vm.limit}`))]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }