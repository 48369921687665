var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.iconClassName
    ? _c("yxtf-svg", {
        staticClass: "audio-video-icon",
        attrs: {
          "remote-url": _vm.mediaUrl,
          "icon-class": _vm.iconClassName,
          width: _vm.width + "px",
          height: _vm.height + "px",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }