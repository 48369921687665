<template>
  <div class="yxtbiz-select-newkng">
    <!-- 知识库目录开始 -->
    <div class="clearfix yxtbiz-select-newkng__tb flex-1">
      <div class="yxtbiz-select-newkng__operate">
        <yxt-select v-model="dataOrigin"
          class=""
          >
          <yxt-option :value="0" :label="$t('pc_biz_knglib_lbl_allow_import'/** 允许引用 */)">
            <span class="font-size-14">{{ $t('pc_biz_knglib_lbl_allow_import'/** 允许引用 */) }}</span>
          </yxt-option>
          <yxt-option :value="1" :label="$t('pc_biz_knglib_lbl_create_by_me'/** 我创建的 */)">
            <span class="font-size-14">{{ $t('pc_biz_knglib_lbl_create_by_me'/** 我创建的 */) }}</span>
          </yxt-option>
        </yxt-select>
        <select-popover v-if="dataOrigin === 0" ref="selectPopover" :selectOpition="selectOpition" class="d-in-block w210 ml12">
          <div class="yxtbiz-select-newkng__tree">
            <yxt-input v-model="filterKngTree"
                      :placeholder="$t('pc_biz_knglib_lbl_pleaseSearch')"
                      searchable
                      @search="searchCatalog"></yxt-input>
            <yxt-tree ref="filterTree"
                      v-loading="treeLoading"
                      class="yxtbiz-biz-tree-funs mt16"
                      node-key="id"
                      :empty-text="$t('pc_biz_kng_lbl_noCatalog')"
                      :class="kngTreeData.length > 0 ? 'd-in-block' : ''"
                      :data="kngTreeData"
                      :filter-node-method="filterNode"
                      :default-expanded-keys="['']"
                      :expand-on-click-node="false"
                      @node-click="ClickTreeNode">
            </yxt-tree>
          </div>
        </select-popover>
        <yxt-select v-model="kngType"
                    class="ml12"
                    :placeholder="$t('pc_biz_knglib_lbl_allType')"
                    clearable>
          <yxt-option v-for="item in options"
                      :key="item.value + Math.random()"
                      :label="item.label"
                      :value="item.value">
            <span class="font-size-14">{{ item.label }}</span>
          </yxt-option>
        </yxt-select>
        <div class="d-in-block ml12">
          <yxt-input v-model.trim="keyword"
                    :placeholder="$t('pc_biz_knglib_lbl_searchKngName')"
                    maxLength="20"
                    searchable
                    clearable
                    class="yxtbiz-select-newkng__search"
                    @clear="searchLists"
                    @search="searchLists"></yxt-input>
        </div>
      </div>
      <div class="flex-1 h0 pb1 mt16" style="width: 692px">
        <yxt-table ref="kngTable"
                v-loading="kngListLoading"
                height="100%"
                :data="KngTableData"
                :row-key="getRowKey"
                @select="singleCancel"
                @selection-change="selectionChange"
                @select-all="handleKngSelectAll"
                @sort-change="kngSortChange">
        <yxt-table-column type="selection"
                          width="40"
                          reserve-selection
                          :show-overflow-tooltip='false'></yxt-table-column>
        <yxt-table-column prop="title" :label="$t('pc_biz_kng_lbl_title')" width="350">
          <template slot-scope="scope">
            <div :id="'divname-'+ scope.row.id" class="yxtbiz-select-newkng__tb-row">
              <yxtf-svg
                class="yxtbiz-select-newkng__tb-row-tag mr12"
                width="24px"
                height="24px"
                :icon-class="'icons/f_kng-' + scope.row.fileType" />
              <yxt-tooltip
                :content="scope.row.title + scope.row.fileExt"
                open-filter
                effect="dark"
                class="yxtbiz-select-newkng__tb-row-name"
                placement="top-start"
                :max-width="500">
                <span class="font-size-14 ellipsis d-in-block">{{ scope.row.title + scope.row.fileExt }}</span>
              </yxt-tooltip>
            </div>
          </template>
        </yxt-table-column>

        <yxt-table-column prop="libName"
                          :label="$t('pc_biz_knglib_lbl_libName')"
                          width="140"
                          :sort-orders="sortOrders">
        </yxt-table-column>

        <yxt-table-column prop="updateFullname"
                          :label="$t('pc_biz_knglib_lbl_updateFullname')"
                          width="120"
                          :sortable="SOURCE_100_WXISV? false : 'custom'"
                          :sort-orders="sortOrders"
                          :show-overflow-tooltip="false">
          <template slot-scope="scope">
            <yxt-tooltip
              open-filter
              placement="top"
              :max-width="300"
              effect="light">
              <div class="ellipsis">
                <yxtbiz-user-name :name="scope.row.updateFullname" />
              </div>
              <div slot="content">
                <yxtbiz-user-name :name="scope.row.updateFullname" />
              </div>
            </yxt-tooltip>
          </template>
        </yxt-table-column>

        <yxt-table-column prop="updateTime"
                          :label="$t('pc_biz_knglib_lbl_updateTime')"
                          width="170"
                          sortable="custom"
                          :sort-orders="sortOrders">
          <template slot-scope="scope">
            <span>{{ dateToString(scope.row.updateTime, 'yyyy-MM-dd HH:mm') }}</span>
          </template>
        </yxt-table-column>
      </yxt-table>
      </div>
      <div class="mt16 flex-center-end" v-if="totalKng > 0">
        <div class="flex-1"></div>
        <yxt-pagination :total="totalKng"
                        show-on-single-page
                        :page-size.sync="limit"
                        :current-page.sync="pageIndex"
                        layout="total, prev, pager, next, sizes"
                        @size-change="handleKngSizeChange"
                        @current-change="handleKngCurrentChange"/>
      </div>

    </div>
    <div class="yxtbiz-select-newkng__right">
      <!-- 已选择 -->
      <div class="font-size-14 color-gray-10">
        <div v-if="max" class="d-in-block">
          <span>{{$t('pc_biz_kng_lbl_selected')}}</span>
          <span>{{ checkIdLists.length }}</span>
          <span> / </span>
          <span>{{ max }}</span>
        </div>
        <div v-else class="d-in-block">
          <span>{{$t('pc_biz_kng_lbl_selectedCount', [checkIdLists.length])}}</span>
        </div>
      </div>
      <div class="yxtbiz-select-newkng__right-main mt12">
        <yxtbiz-virtual-list class="yxtbiz-hp100" is-scrollbar :estimate-size="34" :keeps="50" :data-sources="checkIdLists" data-key="id" >
          <div slot-scope="{source}">
            <yxt-tag type="info"
                     closable
                     @close="closeKng(source)">
              <yxt-tooltip :content="source.title" open-filter>
                <span class="kng-tag-size">{{ source.title }}</span>
              </yxt-tooltip>
            </yxt-tag>
          </div>
        </yxtbiz-virtual-list>
      </div>
      <div class="mt12">
        <yxt-button :disabled="checkIdLists.length === 0"
                    type="text"
                    @click="clearAllSelection">
                    {{$t('pc_biz_kng_btn_clearAll')}}
        </yxt-button>
      </div>
    </div>

  </div>
</template>

<script>
import SelectPopover from '../../check-person-range/src/components/SelectPopover';
import api from './service.js';
import { Drawer, Empty, Scrollbar, Table, TableColumn, Tree, Select, Option, Pagination, Tag, Tooltip } from 'yxt-pc';
export default {
  name: 'YxtbizSelectNewkng',
  components: {
    YxtDrawer: Drawer,
    YxtEmpty: Empty,
    YxtScrollbar: Scrollbar,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtTree: Tree,
    YxtSelect: Select,
    YxtOption: Option,
    YxtPagination: Pagination,
    YxtTag: Tag,
    YxtTooltip: Tooltip,
    SelectPopover
  },
  props: {
    // 最大选择知识数量,0不限制
    max: {
      type: Number,
      default: 0
    },
    // 当前选择的知识
    value: {
      type: Array,
      default: ()=>{
        return [];
      }
    },
    // 是否包含zip
    zip: {
      type: Boolean,
      default: true
    },
    // 是否包含link
    includeLink: {
      type: Boolean,
      default: false
    },
    // 使用组件的项目，'kngLib'-知识库 'onlineClass'-在线课堂
    bizSwitchKey: {
      type: String,
      default: 'onlineClass'
    },
    isAi: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      kngListLoading: false,
      totalKng: 0,
      limit: 20,
      pageIndex: 1,
      keyword: '',
      filterKngTree: '',
      selectionKng: [],
      passKngLists: [],
      KngTableData: [],
      kngTreeData: [],
      bodyParams: {},
      queryParams: {},
      kngType: '',
      selectOpition: {
        placeholder: this.$t('pc_biz_knglib_lbl_pleaseSelect'),
        checkWord: ''
      },
      sortOrders: ['desc', 'asc', null],
      dataOrigin: 0 // 0 允许引用的 1 我创建的
    };
  },
  computed: {
    options() {
      const list = [
        { value: '', label: this.$t('pc_biz_knglib_lbl_all') },
        { value: '1', label: this.$t('pc_biz_knglib_lbl_doc') },
        { value: '2', label: this.$t('pc_biz_knglib_lbl_video') },
        { value: '3', label: this.$t('pc_biz_knglib_lbl_audio') }
      ];
      if (this.zip) {
        list.push({ value: '7', label: this.$t('pc_biz_knglib_lbl_zip')});
      }
      if (this.includeLink) {
        list.push({ value: '6', label: this.$t('pc_biz_knglib_lbl_newlink') });
      }
      return list;
    },
    SOURCE_100_WXISV() {
      return window.localStorage.getItem('sourceCode') && window.localStorage.getItem('sourceCode') === '100';
    },
    checkIdLists() {
      return this.checkKngLists.map(kng => {
        return kng;
        // return {
        //   id: list.id,
        //   title: list.title,
        //   fileType: list.fileType,
        //   fileId: list.fileId
        // };
      });
    },
    checkKngLists() {
      let kngList = [];
      kngList = this.selectionKng.map(item => {
        item.sign = 0;
        return item;
      });
      if (this.max) {
        return this.publicRemoveRepeat([...kngList, ...this.passKngLists]);
      }
      return this.publicRemoveRepeat(kngList);
    }
  },
  created() {
    this.getKngTree();
    this.getKngLists();
  },
  mounted() {
    this.initSelection();
  },
  methods: {
    // 知识库目录的列表知识
    async getKngLists(reset) {
      const { limit, queryParams, bodyParams, keyword, kngType, bizSwitchKey, zip, dataOrigin } = this;
      queryParams.limit = limit;

      if (reset) {
        this.pageIndex = 1;
      }

      queryParams.offset = (this.pageIndex - 1) * limit;

      bodyParams.keyword = keyword;
      bodyParams.kngType = kngType;
      bodyParams.bizSwitchKey = bizSwitchKey;
      bodyParams.isAi = this.isAi ? 1 : 0;
      let ignoreTypes = [];
      if (!zip) ignoreTypes.push('zip');
      if (!this.includeLink) ignoreTypes.push('link');
      bodyParams.ignoreTypes = ignoreTypes;

      this.kngListLoading = true;

      try {
        const res = await api.getKngLists(queryParams, {
          ...bodyParams,
          isShowMine: dataOrigin,
          parentId: dataOrigin === 1 ? '' : bodyParams.parentId
        });
        this.KngTableData = res.datas;
        this.totalKng = res.paging.count;
      } catch (err) {
        console.log(err);
      } finally {
        this.kngListLoading = false;
      }
    },
    // 获取知识库目录左侧的树
    getKngTree() {
      this.treeLoading = true;
      api.getKngTree().then(res => {
        this.treeLoading = false;
        let all = [{
          label: this.$t('pc_biz_knglib_lbl_all'),
          id: '',
          children: res.kngLibTree
        }];
        this.kngTreeData = all;
      }).catch(err => {
        this.treeLoading = false;
        err.error.message && this.$message.error(err.error.message);
      });
    },
    initSelection() {
      this.selectionKng = this.value;
      this.selectionKng.forEach(kng => {
        this.$refs['kngTable'].toggleRowSelection(kng, true);
      });
    },
    // 知识库目录树筛选的方法
    searchCatalog(value) {
      this.$refs.filterTree.filter(value);
    },
    filterNode(value, data) {
      if (!value) return true;
      let name = data.label.toLowerCase();
      let cValue = value.toLowerCase();
      return name.indexOf(cValue) !== -1;
    },
    // 点击左侧树的节点的事件
    ClickTreeNode(obj, node, _this) {
      this.bodyParams.parentId = obj.id;
      this.$refs['selectPopover'].cancel();
      this.selectOpition.checkWord = obj.id ? obj.label : '';
      this.getKngLists(true);
    },
    // 将selected知识设为true
    selectedTrue(data, refTable) {
      if (this.passKngLists.length && this.max) {
        const ids = this.passKngLists.map(item => item.id);

        data.forEach(kng => {
          if (ids.includes(kng.id) || ids.includes(kng.kngId)) {
            this.$nextTick(() => {
              refTable.toggleRowSelection(kng, true);
            });
          }
        });
      }
    },
    handleKngSizeChange() {
      this.searchLists();
    },
    // 知识库目录下一页操作
    handleKngCurrentChange() {
      this.getKngLists();
    },
    // 搜索操作
    searchLists() {
      this.getKngLists(true);
    },
    // 知识库目录选择项触发事件
    selectionChange(selection) {
      this.selectionKng = selection;
    },
    // 当单个选择知识大于最大的值的时候将选中的知识取消勾选,判断只有有当前的页卡才会去清除对应选中的知识
    singleCancel(selection, row) {
      if (this.max) {
        this.singleEvent(this.$refs.kngTable, row);
        this.filterRemove(row);
      }
    },
    // 单个checkbox点击公共事件
    singleEvent(refTable, row) {
      setTimeout(() => {
        if (this.checkIdLists.length > this.max) {
          this.$nextTick(() => {
            refTable.toggleRowSelection(row, false);
          });
        }
      });
    },
    filterRemoveKng(row) {
      this.selectionKng = this.selectionKng.filter(item => item.id !== row.id);
      this.passKngLists = this.passKngLists.filter(item => item.id !== row.id);
    },
    filterRemove(row) {
      if (this.passKngLists.length && this.max) {
        this.filterRemoveKng(row);
      }
    },
    publicRemoveRepeat(selection) {
      let result = [];
      let obj = {};
      // 进行数组里对象的去重
      for (let i = 0; i < selection.length; i++) {
        if (!obj[selection[i].id]) {
          result.push(selection[i]);
          obj[selection[i].id] = true;
        }
      }
      return result;
    },
    // 底部tab，在取消选中时，同时取消其他页卡里相同id的知识
    closeKng(kng) {
      this.checkKngLists.map(v => {
        if (v.id === kng.id || v.kngId === kng.id) {
          this.$nextTick(() => {
            this.$refs.kngTable && this.$refs.kngTable.toggleRowSelection(v, false);
          });
        }
      });

      this.filterRemove(kng);
    },
    // 清除所有选中的知识,判断只有有当前的页卡才会去清除对应选中的知识
    clearAllSelection() {
      this.$refs.kngTable && this.$refs.kngTable.clearSelection();

      this.selectionKng = [];

      if (this.max) {
        this.passKngLists = [];
      }
    },
    removeAllLists(selection, array) {
      if (!this.max) return;
      if (!this.passKngLists.length) return;

      const kngIds = selection.map(item => item.id);
      const selected = array.filter(item => {
        return kngIds.includes(item.id);
      });

      if (!selected.length) {
        let ids = array.map(item => item.id);
        this.passKngLists = this.passKngLists.filter(item => {
          return !ids.includes(item.id);
        });
      }
    },
    // 选中所有知识库目录的知识
    handleKngSelectAll() {
      this.removeAllLists(this.selectionKng, this.KngTableData);
      this.handleSelectAll(this.$refs.kngTable, this.KngTableData, 1);
    },
    // 选中所有的知识公共事件
    handleSelectAll(refTable, data, num) {
      if (this.max) {
        let that = this;
        setTimeout(() => {
          if (this.checkIdLists.length > this.max) {
            // 取出在所有选中的list中与之前选中的值的差集
            let newArrLists = [];
            let idArr = this.oldValue.map(item => item.id);
            newArrLists = this.checkIdLists.filter(list => {
              return !idArr.includes(list.id);
            });

            // 判断列表页和筛选出的差集相同的对象，把它取消选中
            const arrIds = newArrLists.map(item => item.id);
            data.forEach(list => {
              if ((arrIds.includes(list.id) && num === 1) || (arrIds.includes(list.kngId) && num === 2)) {
                that.$nextTick(() => {
                  refTable.toggleRowSelection(list, false);
                });
              }
            });
          }
        });
      }
    },
    // 获取table的row-key
    getRowKey(row) {
      return row.id;
    },
    // 知识列表排序
    kngSortChange(sort) {
      this.queryParams.orderType = sort.order;
      this.queryParams.orderBy = sort.prop;
      this.getKngLists(true);
    },
    dateToString(text, format, notReplace) {
      if (!text) {
        return '';
      }
      if (!notReplace && typeof text === 'string') {
        text = text.replace(/\.\d+/, '').replace(/-/g, '/');
      }
      let date = new Date(text);
      if (!date || date === 'Invalid Date') {
        return '';
      }
      let dateStr = format;
      dateStr = dateStr
        .replace('yyyy', date.getFullYear())
        .replace('MM', (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1))
        .replace('dd', (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()))
        .replace('HH', (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()))
        .replace('mm', (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()))
        .replace('ss', (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()));
      dateStr = dateStr
        .replace('M', date.getMonth() + 1)
        .replace('d', date.getDate())
        .replace('H', date.getHours())
        .replace('m', date.getMinutes())
        .replace('s', date.getSeconds());
      return dateStr;
    }
  },
  watch: {
    value() {
      if (this.value !== this.checkIdLists) {
        this.initSelection();
      }
    },
    kngType() {
      this.searchLists();
    },
    dataOrigin() {
      this.searchLists();
    },
    checkIdLists(v, oldValue) {
      if (v.length > this.max && this.max) {
        this.$message.error(this.$t('pc_biz_kng_tip_overSelected'));
        this.oldValue = oldValue;
      } else {
        this.$emit('input', v);
        this.$emit('selectChange', v);
      }
    }
  }
};
</script>
