<template>
  <div class="yxtbiz-select-course">
     <!-- 知识库目录开始 -->
    <div class="clearfix yxtbiz-select-course__tb">
      <div class="yxtbiz-select-course__operate">
          <select-popover ref="selectPopover" :selectOpition="selectOpition" class="d-in-block w240 mr16">
                  <div class="yxtbiz-kng-tree table_tree">
                    <yxt-input v-model="filterKngTree"
                              :placeholder="$t('pc_biz_kng_lbl_searchCatalog')"
                              searchable
                              @search="searchCatalog"></yxt-input>
                    <yxt-tree ref="filterTree"
                              v-loading="treeLoading"
                              style="width: 100%;"
                              class="yxtbiz-biz-tree-funs mt16"
                              node-key="id"
                              :empty-text="$t('pc_biz_kng_lbl_noCatalog')"
                              :class="kngTreeData.length > 0 ? 'd-in-block' : ''"
                              :data="kngTreeData"
                              :props="defaultProps"
                              :filter-node-method="filterNode"
                              :default-expanded-keys="treeExpand"
                              :expand-on-click-node="false"
                              @node-click="ClickTreeNode">
                    </yxt-tree>
                  </div>
          </select-popover>
        <div class="d-in-block">
          <!-- :placeholder="$t('pc_biz_udp_tip_searchCommon' /*请输入关键字搜索*/)" -->
          <yxt-input v-model.trim="keyword"
                    :placeholder="$t('pc_biz_course_tip_search_kngandteaname').d('请输入课程名称/讲师姓名搜索')"
                    maxLength="20"
                    searchable
                    class="yxtbiz-select-course__search w240"
                    @search="searchLists"></yxt-input>
        </div>
      </div>
      <yxt-table ref="kngTable"
                v-loading="kngListLoading"
                class="mt16"
                :data="KngTableData"
                :row-key="getRowKey"
                @select="singleCancel"
                @selection-change="selectionChange"
                @select-all="handleKngSelectAll">
        <yxt-table-column v-if="!singleMode"
                          type="selection"
                          width="40"
                          reserve-selection
                          :show-overflow-tooltip='false'></yxt-table-column>
        <yxt-table-column prop="courseName"
                          width="278"
                          :label="$t('pc_biz_lbl_course_title' /*课程名称*/)"
                          show-overflow-tooltip>
        </yxt-table-column>

        <yxt-table-column v-if="isShowCourseType"
                          prop="type"
                          :label="$t('pc_biz_lbl_course_type'/*课程类别*/)"
                          width="140">
          <template slot-scope="scope">
            {{ COURSETYPE[scope.row.type] }}
          </template>
        </yxt-table-column>
        <yxt-table-column prop="teacherListName"
                          :label="$t('pc_biz_lbl_certified_lecture' /*认证讲师*/)">
          <template slot-scope="scope">
            <yxt-tooltip
              placement="top"
              open-filter
              effect="dark">
              <div class="ellipsis">
                <yxtbiz-user-name :name="handLecturer(scope.row.teacherListName)" split="," />
              </div>
              <yxtbiz-user-name slot="content" :name="scope.row.teacherListName" split="," />
            </yxt-tooltip>
          </template>
        </yxt-table-column>

        <yxt-table-column prop="courseVersion"
                          :label="$t('pc_biz_lbl_version' /*版本*/)"
                          show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.courseVersion || '--' }}
          </template>
        </yxt-table-column>
        <!-- 操作 -->
        <yxt-table-column v-if="singleMode" :label="$t('pc_biz_te_lbl_operation')" width="100" align="left">
          <template slot-scope="scope">
            <!-- 取消选择/选择 -->
            <yxt-link
              @click="selectTea(scope.row)"
              :underline="false"
              type="primary"
            >{{ value && (value.id === scope.row.id || (value[0] && value[0].id === scope.row.id))? $t('pc_biz_te_btn_cancelselect' /*取消选择*/) : $t('pc_biz_te_btn_select' /*选择*/) }}</yxt-link>
          </template>
        </yxt-table-column>
      </yxt-table>
      <yxt-pagination class=" mt16 pull-right"
                      :total="totalKng"
                      :page-size="limit"
                      :current-page.sync="pageIndex"
                      layout="total, prev, pager, next"
                      @current-change="handleKngCurrentChange">
      </yxt-pagination>
    </div>
    <!-- 已选择 -->
    <div class="font-size-14 color-gray-10 mt16" v-if="!singleMode">
      <div v-if="max" class="d-in-block">
        <span>{{$t('pc_biz_kng_lbl_selected')}}</span>
        <span>{{ checkIdLists.length }}</span>
        <span> / </span>
        <span>{{ max }}</span>
      </div>
      <div v-else class="d-in-block">
        <span>{{$t('pc_biz_kng_lbl_selectedCount', [checkIdLists.length])}}</span>
      </div>
      <yxt-button class="pull-right"
                  :disabled="checkIdLists.length === 0"
                  type="text"
                  @click="clearAllSelection">
        {{$t('pc_biz_kng_btn_clearAll')}}
      </yxt-button>
    </div>
    <div class="yxtbiz-select-course__choiced" v-if="!singleMode">
      <yxt-scrollbar :fit-height="true">
        <yxt-tag v-for="tag in checkIdLists"
                :key="tag.id"
                class="mr10 mb6"
                type="info"
                closable
                @close="closeKng(tag)">
          <yxt-tooltip
              placement="top"
              open-filter
              :content="tag.courseName"
              effect="dark">
              <span class="course-tag-size">{{ tag.courseName }}</span>
          </yxt-tooltip>
        </yxt-tag>
      </yxt-scrollbar>
    </div>
  </div>
</template>

<script>

import SelectPopover from '../../check-person-range/src/components/SelectPopover';
import api from './service.js';
import { Drawer, Empty, Scrollbar, Table, TableColumn, Tree, Select, Option, Pagination, Tag, Tooltip } from 'yxt-pc';
import { getMedia } from 'yxt-biz-pc/packages/api';
import { enmuFunctions } from '../../function-points';
import commonUtil from '../../common-util';

export default {
  name: 'YxtbizSelectCourse',
  components: {
    YxtDrawer: Drawer,
    YxtEmpty: Empty,
    YxtScrollbar: Scrollbar,
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtTree: Tree,
    YxtSelect: Select,
    YxtOption: Option,
    YxtPagination: Pagination,
    YxtTag: Tag,
    YxtTooltip: Tooltip,
    SelectPopover
  },
  props: {
    // 最大选择知识数量,0不限制
    max: {
      type: Number,
      default: 0
    },
    // 当前选择的知识
    value: {
      type: Array | Object,
      default: () => {
        return {};
      }
    },
    typecollegeId: {
      type: String,
      default: ''
    },
    pmType: {
      type: Number,
      default: 0
    },
    // 是否显示课程类别这一列
    isShowCourseType: {
      type: Boolean,
      default: true
    },
    // 课程类型 0 单课程 1系列课 默认-1 查全部
    courseType: {
      type: Number,
      default: -1
    },
    // 是否单选模式， 默认会根据传入的数据类型修改。true时强制单选模式
    singleMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      knglibUrl: getMedia() + 'knglib/pc/svg/',
      totalKng: 0,
      limit: 10,
      offset: 0,
      pageIndex: 1,
      keyword: '',
      filterKngTree: '',
      selectionKng: [],
      passKngLists: [],
      kngListLoading: false,
      KngTableData: [],
      kngTreeData: [],
      collegeIdSearch: '',
      bodyParams: {},
      queryParams: {},
      kngTreeId: '', // 左侧知识树点击时候保存的id
      selectOpition: {
        placeholder: this.$t('pc_biz_lbl_course_catalog'),
        checkWord: ''
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      treeExpand: [''],
      COURSETYPE: {
        0: this.$t('pc_biz_lbl_single_course'/* 单课程 */),
        1: this.$t('pc_biz_lbl_series_course'/* 系列课 */)
      }
    };
  },
  computed: {
    checkIdLists() {
      return this.checkKngLists.map(kng => {
        return kng;
      });
    },
    checkKngLists() {
      let kngList = [];
      if (this.selectionKng && this.selectionKng.map) {
        kngList = this.selectionKng.map(item => {
          item.sign = 0;
          return item;
        });
      }
      if (this.max) {
        return this.publicRemoveRepeat([...kngList, ...this.passKngLists]);
      }
      return this.publicRemoveRepeat(kngList);
    }
  },
  created() {
    this.getKngTree();
    this.getKngLists();
  },
  mounted() {
    this.initSelection();
  },
  methods: {
    // 认证讲师opendata数据展示
    handLecturer(val) {
      return val || '--';
    },
    // 知识库目录的列表知识
    getKngLists(reset) {
      this.KngTableData = [];
      this.kngListLoading = true;
      this.queryParams.limit = this.limit;
      if (reset) {
        this.offset = 0;
        this.pageIndex = 1;
      }
      this.offset = (this.pageIndex - 1) * this.limit;
      this.queryParams.offset = this.offset;
      this.bodyParams = {
        keyword: this.keyword,
        type: this.courseType,
        includeChildCatalog: 1, // 目录查询模式 1 包含子目录查询 0 只查询当前目录
        catalogId: this.kngTreeId // 课程目录id
      };
      api.getCourseList(this.queryParams, this.bodyParams).then(res => {
        this.KngTableData = res.datas;
        this.totalKng = res.paging.count;
        this.kngListLoading = false;
      }).catch(err => {
        this.kngListLoading = false;
        console.log(err);
      });
    },
    // 获取知识库目录左侧的树
    async getKngTree() {
      this.treeLoading = true;

      await commonUtil.preCheckFunctions(enmuFunctions);
      const val = commonUtil.checkTimeOutFnc(enmuFunctions.ONLINE_ClASS_SELECT_CATALOG);

      const interfaceName = this.isNoNeedAuth ? api.getKngTreeReport({ pmType: 1, collegeId: this.collegeIdSearch }) : api.getKngTree({ pmType: this.pmType, collegeId: this.collegeIdSearch });
      interfaceName.then(res => {
        let all = res;
        // 如果未增购企课堂，只显示主学院，如果传参要求显示禁用课堂就不再过滤
        if (val !== undefined && !(val >= 2 && val <= 4) && !this.isNoNeedAuth) {
          all = res.filter(item => {
            return item.mainCollege;
          });
          this.collegeIdSearch = all[0].collegeId;
        }
        this.treeLoading = false;
        this.kngTreeData = all;
        if (all.length === 1) {
          this.$nextTick(() => {
            this.treeExpand = [all[0].id];
          });
        }
      }).catch(err => {
        this.treeLoading = false;
        err.error.message && this.$message.error(err.error.message);
      });
    },
    initSelection() {
      this.selectionKng = this.value;
      if (this.singleMode) return false;
      this.selectionKng.forEach(kng => {
        this.$refs['kngTable'].toggleRowSelection(kng, true);
      });
    },
    // 知识库目录树筛选的方法
    searchCatalog(value) {
      this.$refs.filterTree.filter(value);
    },

    // 知识库目录树筛选的方法
    filterNode(value, data) {
      if (!value) return true;
      let name = data.label.toLowerCase();
      let cValue = value.toLowerCase();
      return name.indexOf(cValue) !== -1;
    },
    // 点击左侧树的节点的事件
    ClickTreeNode(obj, node, _this) {
      this.kngTreeId = obj.id;
      this.collegeIdSearch = obj.collegeId;
      this.$refs['selectPopover'].cancel();
      this.selectOpition.checkWord = obj.id ? obj.label : '';
      this.getKngLists(this.kngTreeId, this.kngSort, this.kngSortType);
    },
    // 将selected知识设为true
    selectedTrue(data, refTable) {
      if (this.passKngLists.length && this.max) {
        const ids = this.passKngLists.map(item => item.id);

        data.forEach(kng => {
          if (ids.includes(kng.id) || ids.includes(kng.kngId)) {
            this.$nextTick(() => {
              refTable.toggleRowSelection(kng, true);
            });
          }
        });
      }
    },
    // 知识库目录下一页操作
    handleKngCurrentChange() {
      this.getKngLists();
    },
    // 搜索操作
    searchLists() {
      this.getKngLists(true);
    },
    // 知识库目录选择项触发事件
    selectionChange(selection) {
      this.selectionKng = selection;
    },
    // 当单个选择知识大于最大的值的时候将选中的知识取消勾选,判断只有有当前的页卡才会去清除对应选中的知识
    singleCancel(selection, row) {
      if (this.max) {
        this.singleEvent(this.$refs.kngTable, row);
        this.filterRemove(row);
      }
    },
    // 单个checkbox点击公共事件
    singleEvent(refTable, row) {
      setTimeout(() => {
        if (this.checkIdLists.length > this.max) {
          this.$nextTick(() => {
            refTable.toggleRowSelection(row, false);
          });
        }
      });
    },
    filterRemoveKng(row) {
      this.selectionKng = this.selectionKng.filter(item => item.id !== row.id);
      this.passKngLists = this.passKngLists.filter(item => item.id !== row.id);
    },
    filterRemove(row) {
      if (this.passKngLists.length && this.max) {
        this.filterRemoveKng(row);
      }
    },
    publicRemoveRepeat(selection) {
      let result = [];
      let obj = {};
      // 进行数组里对象的去重
      for (let i = 0; i < selection.length; i++) {
        if (!obj[selection[i].id]) {
          result.push(selection[i]);
          obj[selection[i].id] = true;
        }
      }
      return result;
    },
    // 底部tab，在取消选中时，同时取消其他页卡里相同id的知识
    closeKng(kng) {
      this.checkKngLists.map(v => {
        if (v.id === kng.id) {
          this.$nextTick(() => {
            this.$refs.kngTable && this.$refs.kngTable.toggleRowSelection(v, false);
          });
        }
      });

      this.filterRemove(kng);
    },
    // 清除所有选中的知识,判断只有有当前的页卡才会去清除对应选中的知识
    clearAllSelection() {
      this.$refs.kngTable && this.$refs.kngTable.clearSelection();

      this.selectionKng = [];

      if (this.max) {
        this.passKngLists = [];
      }
    },
    removeAllLists(selection, array) {
      if (!this.max) return;
      if (!this.passKngLists.length) return;

      const kngIds = selection.map(item => item.id);
      const selected = array.filter(item => {
        return kngIds.includes(item.id);
      });

      if (!selected.length) {
        let ids = array.map(item => item.id);
        this.passKngLists = this.passKngLists.filter(item => {
          return !ids.includes(item.id);
        });
      }
    },
    // 选中所有知识库目录的知识
    handleKngSelectAll() {
      this.removeAllLists(this.selectionKng, this.KngTableData);
      this.handleSelectAll(this.$refs.kngTable, this.KngTableData, 1);
    },
    // 选中所有的知识公共事件
    handleSelectAll(refTable, data, num) {
      if (this.max) {
        let that = this;
        setTimeout(() => {
          if (this.checkIdLists.length > this.max) {
            // 取出在所有选中的list中与之前选中的值的差集
            let newArrLists = [];
            let idArr = this.oldValue.map(item => item.id);
            newArrLists = this.checkIdLists.filter(list => {
              return !idArr.includes(list.id);
            });

            // 判断列表页和筛选出的差集相同的对象，把它取消选中
            const arrIds = newArrLists.map(item => item.id);
            data.forEach(list => {
              if ((arrIds.includes(list.id) && num === 1) || (arrIds.includes(list.kngId) && num === 2)) {
                that.$nextTick(() => {
                  refTable.toggleRowSelection(list, false);
                });
              }
            });
          }
        });
      }
    },
    // 获取table的row-key
    getRowKey(row) {
      return row.id;
    },
    selectTea(item) {
      if (this.value && (!item || this.value.id === item.id)) {
        this.$emit('input', null);
        this.$emit('selectChange', null);
      } else {
        this.$emit('input', item);
        this.$emit('selectChange', item);
      }
    }
  },
  watch: {
    value() {
      if (this.value !== this.checkIdLists && !this.singleMode) {
        this.initSelection();
      }
    },
    checkIdLists(v, oldValue) {
      if (v === undefined || this.singleMode) {
        return;
      }
      if (v.length > this.max && this.max) {
        this.$message.error(this.$t('pc_biz_te_lbl_course_maxlimit').d('选择的课程已达上限'));
        this.oldValue = oldValue;
      } else {
        this.$emit('input', v);
        this.$emit('selectChange', v);
      }
    }
  }
};
</script>
