var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yxtbiz-queslib-preview" },
    [
      _c(
        "yxt-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.datasList },
        },
        [
          _c("yxt-table-column", {
            attrs: {
              "show-overflow-tooltip": false,
              label: _vm.$t("pc_biz_ote_lbl_quesname"),
              prop: "summary",
              "min-width": _vm.$isChinese ? "170" : "220",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.summary
                      ? _c(
                          "div",
                          { staticClass: "yxtbiz-queslib-preview__name" },
                          [
                            _c("audio-video-icon", {
                              staticClass: "mr5 shrink0",
                              attrs: { type: scope.row.quesType },
                            }),
                            _c(
                              "yxt-tooltip",
                              {
                                staticClass: "color-primary-6 hand",
                                attrs: {
                                  "open-filter": "",
                                  effect: "dark",
                                  "max-width": 300,
                                  content: scope.row.summary,
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ellipsis",
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewQues(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.summary))]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !scope.row.summary
                      ? _c(
                          "span",
                          {
                            staticClass: "yxtbiz-queslib-preview__name",
                            on: {
                              click: function ($event) {
                                return _vm.viewQues(scope.row)
                              },
                            },
                          },
                          [
                            _c("audio-video-icon", {
                              staticClass: "mr5 vertical-align-middle",
                              attrs: { type: scope.row.quesType },
                            }),
                            _c("yxt-svg", {
                              staticClass: "hand vertical-align-middle",
                              attrs: {
                                "remote-url": _vm.svgRemote,
                                "icon-class": "ques-image",
                                width: "18px",
                                height: "18px",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("yxt-table-column", {
            attrs: {
              "show-overflow-tooltip": true,
              label: _vm.$t("pc_biz_ote_lbl_assessmentpoint"),
              prop: "pointNames",
              width: _vm.$isChinese ? "100" : "140",
            },
          }),
          _c("yxt-table-column", {
            attrs: {
              "show-overflow-tooltip": false,
              label: _vm.$t("pc_biz_ote_lbl_questiontype"),
              prop: "type",
              width: _vm.$isChinese ? "90" : "140",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getQuestionType(scope.row.type))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("yxt-table-column", {
            attrs: {
              "show-overflow-tooltip": false,
              label: _vm.$t("pc_biz_ote_lbl_queslevel"),
              prop: "levelType",
              width: _vm.$isChinese ? "80" : "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getQuestionLevalType(scope.row.levelType))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt16 clearfix" },
        [
          _c("yxt-pagination", {
            staticClass: "pull-right",
            attrs: {
              "page-size": _vm.pageSize,
              total: _vm.datasListCount,
              "current-page": _vm.currentPage,
              "simple-total": "",
              layout: "total, prev, pager, next",
            },
            on: { "current-change": _vm.handleCurrentChange },
          }),
        ],
        1
      ),
      _c(
        "yxt-drawer",
        {
          ref: "quesViewDrawer",
          attrs: {
            size: "640px",
            title: _vm.$t("pc_biz_ote_lbl_quesview"),
            visible: _vm.quesDetailData.dialogVisible,
            "before-close": _vm.handleClose,
            destroyOnClose: true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.quesDetailData, "dialogVisible", $event)
            },
          },
        },
        [_c("ques-view", { attrs: { id: _vm.quesDetailData.id } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }