import { oteApi } from 'yxt-biz-pc/packages/api';

// 根据权限获取题库考核点树状表
export const getLibAndPoints = (bodyObj) => {
  return oteApi.get('/lib/lib/point/tree', bodyObj);
};

/**
 * 弹框中按指定题库考核点列表，条件查询试题列表
 * @param {Object} data 考核点信息
 */
export const getQuesByPoints = (urlObj, bodyObj) => {
  return oteApi.post('/ques/list4dialog', bodyObj, { params: urlObj });
};

/**
 * 弹框中按指定题库考核点列表，条件查询试题列表(不分页)
 * @param {Object} data 查询条件
 */
export const getNoPageQuesByLibAndPoint = (bodyObj) => {
  return oteApi.post('/ques/list/dialog/nopage', bodyObj);
};

/**
 * 获取试题库分类列表 弹窗选择
 * @param {object} bodyObj 请求参数
 */
export const getCatalogListForSelect = bodyObj => {
  if (!bodyObj) {
    bodyObj = {
      navCode: 'ote_ques_lib_mgmt'
    };
  }
  return oteApi.get('/lib/catalog/list/select', bodyObj);
};

/**
 * 获取试题详情
 * @param {string} qid 试题ID
 */
export const getQuesDetail = (qid) => {
  return oteApi.get(`/ques/${qid}`);
};

/**
 * 获取快速抽题试题最大数量
 * @param params
 */
export const getQuesChoiceQty = (params, selectQStyle, types) => oteApi.post('/ques/choice/qty/' + selectQStyle, {
  types,
  pointIds: params
});

/**
  * 获取快速抽题试题最大数量
  * @param params
  */
export const getQuesChoiceQuestion = params => oteApi.post('/ques/choice/question', params);

/**
 * 获取题库下试题列表
 * @param {string} id 题库ID
 */
export const getQuesOfQuesLib = (params, body) => {
  return oteApi.post(`/ques/list?limit=${params.limit}&current=${params.current}&orderby=${params.orderby}&direction=${params.direction}`, body);
};

/**
 * 获取试题库分类列表 弹窗选择
 * @param {object} bodyObj 请求参数
 */
export const getCatalogListForSelectOG = bodyObj => {
  if (!bodyObj) {
    bodyObj = {
      navCode: 'ote_ques_lib_mgmt'
    };
  }
  return oteApi.get('/lib/catalog/list/all', bodyObj);
};

// 根据权限获取题库列表
export const getQuesLibListOG = (bodyObj) => {
  return oteApi.get('/lib/list/all', bodyObj);
};

/**
 * 获取机构的难度级别
 * @returns "fiveLevel": true 是否为五级难度
 */
export const getQuesLevel = () => {
  return oteApi.get(`/config${(!window.localStorage.token || window.localStorage.isVisitor) ? '/stu' : ''}/quesLevel?orgId=${window.localStorage.orgId}`);
};
