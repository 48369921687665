import { globalApi, udpApi, miscApi } from 'yxt-biz-pc/packages/api';

export const saveLangKey = (module, params) => { // 修改key
  return globalApi.post(`org/langkey/${module}/save`, params);
};

export const getGroupcorps = (orgId) => {
  return udpApi.get(`groupcorps/${orgId}/groupinfo`);
};

const searchPlatformMap = Object.create(null);
// 根据key值查运营平台参数
export const getSearchPlatformVCatch = async(code) => {
  try {
    const v = searchPlatformMap[code];
    if (v) return v;
    searchPlatformMap[code] = miscApi.get(`param/search/${code}`).then((data) => {
      searchPlatformMap[code] = data.value;
      return data.value;
    });
    return searchPlatformMap[code];
  } catch (e) {
    console.error(e);
  }
};

const orgParamterMap = Object.create(null);
// 获取机构参
export const getOrgpParameter = async(code, orgId) => {
  const key = `${orgId}.${code}`;
  const valOld = orgParamterMap[key];
  if (valOld) return valOld;

  const res = await miscApi.get(`orgparsetting/code?code=${code}&orgId=${orgId}`);
  orgParamterMap[key] = res;
  return res;
};
