var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yxt-col",
    { staticClass: "w-auto" },
    [
      _vm.tag
        ? _c("yxt-tag", { staticClass: "yxtbiz-question-preview__tag" }, [
            _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
          ])
        : [_vm._v(_vm._s(_vm.label))],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }