var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yxtbiz-queslib-drawer" }, [
    _c("div", { staticClass: "yxtbiz-queslib-drawer__treetable" }, [
      _c(
        "div",
        { staticClass: "yxtbiz-queslib-drawer__treetable-left" },
        [
          _c(
            "div",
            { staticClass: "mb16 yxtbiz-question-flex" },
            [
              _c("catalogDropdown", {
                staticClass: "d-in-block width144",
                attrs: {
                  data: _vm.datas,
                  size: "small",
                  dropToolTip: _vm.$t("pc_biz_ote_lbl_classification"),
                  catalogId: _vm.treeSearchData.catalogId,
                  catalogName: _vm.treeSearchData.catalogName,
                },
                on: {
                  "update:catalogId": function ($event) {
                    return _vm.$set(_vm.treeSearchData, "catalogId", $event)
                  },
                  "update:catalog-id": function ($event) {
                    return _vm.$set(_vm.treeSearchData, "catalogId", $event)
                  },
                  "update:catalogName": function ($event) {
                    return _vm.$set(_vm.treeSearchData, "catalogName", $event)
                  },
                  "update:catalog-name": function ($event) {
                    return _vm.$set(_vm.treeSearchData, "catalogName", $event)
                  },
                  change: _vm.catalogChange,
                },
              }),
              _c("yxt-input", {
                staticClass: "d-in-block ml12",
                class: _vm.$isChinese ? "width236" : "width280",
                attrs: {
                  disabled: _vm.treeLoading,
                  placeholder: _vm.$t("pc_biz_ote_lbl_liborpoint"),
                  "suffix-icon": "yxt-icon-search",
                  size: "small",
                },
                model: {
                  value: _vm.treeSearchData.keywords,
                  callback: function ($$v) {
                    _vm.$set(_vm.treeSearchData, "keywords", $$v)
                  },
                  expression: "treeSearchData.keywords",
                },
              }),
            ],
            1
          ),
          _c("yxt-tree", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.treeLoading,
                expression: "treeLoading",
              },
            ],
            ref: "tree",
            attrs: {
              "filter-node-method": _vm.filterNode,
              data: _vm.libandpointsData,
              "show-checkbox": "",
              "wrap-width": "100%",
              "node-key": "id",
              virtual: "",
              props: _vm.defaultProps,
            },
            on: { check: _vm.handleCheckChange },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ node, data }) {
                  return _c(
                    "span",
                    { staticClass: "custom-tree-node mt8 mb8" },
                    [
                      _c("span", { staticClass: "d-in-block lh22" }, [
                        _vm._v(_vm._s(node.label)),
                      ]),
                      data.shared === 1
                        ? _c(
                            "yxt-tooltip",
                            {
                              attrs: {
                                "max-width": 300,
                                content:
                                  data.sharedFullname +
                                  _vm.$t("pc_biz_ote_btn_share"),
                              },
                            },
                            [
                              _c("yxt-svg", {
                                staticClass: "hand vertical-align-middle",
                                attrs: {
                                  "remote-url": _vm.svgRemote,
                                  "icon-class": "icon-share",
                                  width: "18px",
                                  height: "18px",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      data.updateTime
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "yxtbiz-selector-updatetime color-gray-6 font-size-12 ml24 lh18",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pc_biz_ote_lbl_updatetime" /* 更新日期 */
                                  )
                                ) +
                                  "：" +
                                  _vm._s(
                                    _vm.dateToString(
                                      data.updateTime,
                                      "yyyy-MM-dd"
                                    )
                                  )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }