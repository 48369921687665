import { preCheckFunctions as factorPreCheckFunctions } from 'yxt-factor/es';
import { enmuFunctions } from '../function-points/index';

import factorES from 'yxt-factor/es';
const { ajaxCode, getAllFunctionsByFactorCode } = factorES;

/**
 * 要素功能点预校验，获取API数据并存储，供之后使用
 */
const preCheckFunctions = (enmu) => {
  let enumArr = changeParams(enmuFunctions).concat(changeParams(enmu));
  return factorPreCheckFunctions(enumArr);
};

const changeParams = (params) => { // 将入参转成数组格式
  let codes = [];
  let isArray = Object.prototype.toString.call(params) === '[object Array]';
  if (params) {
    if (typeof params === 'object') {
      if (isArray) {
        codes = params;
      } else {
        codes = Object.values(params);
      }
    } else if (typeof params === 'string') {
      codes.push(params);
    }
  }
  return codes;
};

/**
 * 获取所有的功能点
 * @returns
 */
const getAllFunctions = () => {
  return JSON.parse(window.localStorage.getItem('yxt_factors')) || {};
};

/**
 * 获取所有的要素
 * @returns
 */
const getAllFactor = () => {
  return JSON.parse(window.localStorage.getItem('yxt_old_factors_data') || '[]');
};

/**
 * 获取该功能点的状态集合
 * @param {*} point
 * @returns
 */
const getFactorAllState = (point) => {
  const localFactors = getAllFunctions();
  return localFactors[point];
};

/**
 * 检查对应的功能点的状态(处理已过期的状态)
 * @param {*} point
 * @returns
 * 0.要素下架-隐藏
 * 1.要素上架-未购买-隐藏
 * 2.要素上架-已购买未过期（启用）-显示
 * 3.要素上架-已购买未过期（启用）-隐藏
 * 4.要素上架-已购买未过期（启用）-自定义
 * 5.要素上架-已购买已过期（禁用）-隐藏
 * 6.要素上架-已购买已过期（禁用）-置灰
 * 7.要素上架-已购买已过期（禁用）-自定义
 */
const checkTimeOutFnc = (point) => {
  const localFactors = getAllFunctions();
  return localFactors[point] && localFactors[point].webState;
};

/**
 * 状态码对应的控制值
 * @param {*} point
 * @returns
 * 置灰 6
 * 隐藏 0, 1, 3, 5
 * 自定义 4, 7
 * 正常 2, 3, 4
 */
const checkFactorStatus = point => {
  const status = checkTimeOutFnc(point);
  return {
    disabled: [6].indexOf(status) > -1,
    hidden: [0, 1, 3, 5].indexOf(status) > -1,
    custom: [4, 7].indexOf(status) > -1,
    enabled: [2, 3, 4].indexOf(status) > -1,
    value: status
  };
};

/**
 * 检查模块权限
 * @param {*} point
 * @returns
 */
const checkModule = (point) => {
  const localFactors = getAllFunctions();
  return localFactors[point];
};

/**
 * 刷新方法
 */
const refreshFactors = (MODULE_FUNCTIONS) => {
};

export { preCheckFunctions, getFactorAllState, getAllFunctions, getAllFactor, checkTimeOutFnc, checkModule, refreshFactors, checkFactorStatus, ajaxCode as getFactorFunctions, getAllFunctionsByFactorCode };
