<template>
  <div class="yxtbiz-check-single-position">
    <div class="table_tree">
      <yxtbiz-group-org-select class='mb12'
                               @change="handleOrgChange"
                               v-if="enableGroupCorp"
                               :selectFirst='true'
                               :clearable="false"></yxtbiz-group-org-select>
      <yxtbiz-pos-tree
        v-if='(enableGroupCorp && params.targetOrgId) || !enableGroupCorp'
        :enableGroupCorp="enableGroupCorp"
        :visibleOrgSelector="false"
        :wrap-width="260"
        ref="posTree"
        :targetOrgId='params.targetOrgId'
        @nodeClick="nodeClick"
      ></yxtbiz-pos-tree>
    </div>
    <div class="table_wrapper">
      <yxt-input
        class="keyword"
        @keyup.enter.native="getPositionData(true)"
        v-model="params.name"
        :size="size"
        style="width: 240px"
        :placeholder="$t('biz_udp_enter_positionname')"
        searchable
        @search='getPositionData(true)'
      >
      </yxt-input>
      <yxt-table
        class="pos_table"
        ref="positionTable"
        row-key="id"
        @select="handleSingleSeletct"
        @select-all="handleSelectAll"
        v-loading="loading"
        :data="tableData"
        :height="tableHeight"

      >
        <yxt-table-column
          :show-overflow-tooltip='false'
          class-name="table-selection-odd"
          v-if="multiple"
          type="selection"
          width="30"
        >
        </yxt-table-column>
        <yxt-table-column
          prop="name"
          show-overflow-tooltip
          :label="$t('biz_udp_pos_name')"
          min-width="180"
        >
        </yxt-table-column>

        <yxt-table-column
          prop="gradeList"
          show-overflow-tooltip
          :label="$t('biz_udp_occupation_level')"
        >
            <template slot-scope="scope">{{ (scope.row.gradeList || []).map(item => item.name).join() || '--' }}</template>
        </yxt-table-column>
        <yxt-table-column
          show-overflow-tooltip
          prop="catalogName"
          :label="$t('biz_udp_pos_category')"
          min-width="180"
        >
          <template slot-scope="scope">
            {{scope.row.catalogName || '--'}}
          </template>
        </yxt-table-column>
        <yxt-table-column
          v-if="!multiple"
          :label="$t('pc_biz_te_lbl_operation')"
          width="108"
          align="right"
        >
          <template slot-scope="scope">
            <yxt-link
                @click="choose(scope.row)"
                :underline="false"
                type="primary"
              >{{ scope.row.id === (checkedPositionsTemp.length ? checkedPositionsTemp[0].id : '') ? $t('biz_udp_cancel_selected'): $t('pc_biz_te_btn_select')}}</yxt-link>
          </template>
        </yxt-table-column>
        <template slot="empty">
          <div style="line-height:normal">
            <yxt-empty />
          </div>
        </template>
      </yxt-table>
      <div class="yxtbiz-user-selector-mask clearfix">
        <yxt-pagination
          class="pull-right mt16"
          :page-size="params.limit"
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          layout="total, prev, pager, next"
          simple-total
          @current-change="handleCurrentChange"
          :total="total"
        >
        </yxt-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import resizeTable from '../../user-selector/mixins/resizeTable';
import { getPositionList } from './service';
import {
  Table,
  TableColumn,
  Link,
  Pagination,
  Empty
} from 'yxt-pc';
export default {
  name: 'YxtbizCheckSinglePosition',
  mixins: [resizeTable],
  components: {
    YxtTable: Table,
    YxtTableColumn: TableColumn,
    YxtLink: Link,
    YxtPagination: Pagination,
    YxtEmpty: Empty
  },
  props: {
    checkedPositions: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    enableGroupCorp: {
      type: Boolean,
      default: false
    },
    targetOrgId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      refTable: 'positionTable',
      keyword: '',
      currentPage: 1,
      total: 0,
      loading: true,
      tableHeight: 0,
      params: {
        name: '',
        catalogId: '',
        limit: 20,
        offset: 0
      },
      tableData: [],
      isSearch: false
    };
  },
  computed: {
    // 格式化传入值
    checkedPositionsTemp() {
      return this.checkedPositions.map(item => {
        if (typeof item === 'string') {
          return {
            id: item
          };
        }
        return item;
      });
    }
  },
  created() {
    this.params.targetOrgId = this.targetOrgId;
  },
  mounted() {
    if (!this.enableGroupCorp || this.targetOrgId) this.getPositionData();
  },
  methods: {
    handleOrgChange(data) {
      this.params.targetOrgId = data.orgId;
      this.params.name = '';
      this.params.catalogId = '';
      this.getPositionData();
    },
    nodeClick(a, b, c) {
      this.params.catalogId = a.id;
      // this.$refs['positionTable'].clearSelection();
      this.getPositionData(true);
    },
    update() {
      this.$nextTick(() => {
        if (this.tableData.length > 0 && this.checkedPositionsTemp.length > 0) {
          this.isSearch = true;
          let ids = this.checkedPositionsTemp.map(item => item.id);
          this.tableData.map(item => {
            if (ids.includes(item.id)) {
              this.$refs['positionTable'].toggleRowSelection(item, true);
            } else {
              this.$refs['positionTable'].toggleRowSelection(item, false);
            }
          });
          this.isSearch = false;
        }
      });
    },
    getPositionData(reset = false) {
      this.loading = true;
      if (reset) {
        this.params.offset = 0;
        this.currentPage = 1;
      }
      getPositionList(this.params)
        .then(res => {
          this.loading = false;
          if (res) {
            this.tableData = res.datas;
            this.total = res.paging.count;
            this.update();
          }
        })
        .catch(e => {
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.params.offset = (val - 1) * this.params.limit;
      this.getPositionData();
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.params.limit = val;
      this.getPositionData();
    },
    choose(row) {
      let arr = [];
      if (this.checkedPositionsTemp.length) {
        if (row.id === this.checkedPositionsTemp[0].id) {
          arr = [];
        } else {
          arr = [row];
        }
      } else {
        arr = [row];
      }
      this.$emit('update:checkedPositions', arr);
      this.$emit('choose', arr);
    },
    // 全选与全不选
    handleSelectAll(selection) {
      let ids = this.checkedPositionsTemp.map(item => item.id);
      if (selection.length) {
        selection.map(item => {
          if (!ids.includes(item.id)) {
            this.checkedPositions.push(item);
          }
        });
      } else {
        let tempChooseMembers2 = JSON.parse(
          JSON.stringify(this.checkedPositionsTemp)
        );
        this.tableData.map(item => {
          if (ids.includes(item.id)) {
            tempChooseMembers2 = tempChooseMembers2.filter(
              item2 => item2.id !== item.id
            );
          }
        });
        this.$emit('update:checkedPositions', [...tempChooseMembers2]);
      }
    },
    // 单击checkbox
    handleSingleSeletct(selection, row) {
      // 由于table toggleRowSelection会触发该事件执行，所以加把锁
      if (!this.isSearch) {
        // 代表当前页是点击了勾选
        if (!this.checkedPositionsTemp.some(item => item.id === row.id)) {
          this.checkedPositions.push(row);
        } else {
          const members = this.checkedPositionsTemp.filter(
            item => item.id !== row.id
          );
          this.$emit('update:checkedPositions', members);
        }
      }
      return true;
    }
  }
};
</script>
<style lang="scss" scoped></style>
