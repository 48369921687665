<template>
  <div
    class="yxtbiz-upload__dragger color-primary-6"
    :class="{
      'yxtbiz-upload__dragger--dragover': dragover
    }"
    @drop.prevent="onDrop"
    @dragover.prevent="onDragover"
    @dragleave.prevent="dragover = false"
  >
    <slot v-if="$slots.default"></slot>
    <template v-else>
      <div v-if='dragover' class='font-size-16 color-gray-10'>{{$t('pc_biz_upload_lbl_dragover')}}</div>
      <div v-else>
        <yxt-svg :icon-class='iconClass' class='yxtbiz-upload__icon color-primary-6'></yxt-svg>
        <div class='color-gray-10 font-size-16'>{{$t('pc_biz_upload_lbl_drag')}}</div>
        <div class='color-gray-6 font-size-14 mt5'>
          <slot name="tip"></slot>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  export default {
    name: 'YxtbizUploadDrag',
    props: {
      disabled: Boolean,
      iconClass: {
        type: String,
        default: 'upload'
      }
    },
    data() {
      return {
        dragover: false
      };
    },
    methods: {
      onDragover() {
        if (!this.disabled) {
          this.dragover = true;
        }
      },
      onDrop(e) {
        if (this.disabled) return;
        this.dragover = false;
        this.$emit('file', e.dataTransfer.files);
      }
    }
  };
</script>
