
/**
 * 从对象提取所需字段
 * @param {object} data
 * @param {array} keys
 * @returns object
 * eg:
 * pick({name: 'zhang', age: 12, color: 'blue'}, ['name']) => {name: 'zhang'}
 * pick({name: 'zhang', age: 12, color: 'blue'}, ['name', ['age', 'year']]) => {name: 'zhang', year: 12}
 */
export function pick(data, keys) {
  const result = {};
  keys.forEach(key => {
    let newKey;
    if (Array.isArray(key)) {
      const [okey, nkey] = key;
      key = okey;
      newKey = nkey;
    } else {
      newKey = key;
    }
    result[newKey] = data[key];
  });
  return result;
}
