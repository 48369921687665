import Api, {
  http,
  envNow,
  getMedia,
  getNavIcoPath,
  captchaId,
  getDomains,
  privateContactAdapter,
  emptyInstance
} from './main';
import config, { configGovern } from './configDomain';

/* istanbul ignore next */
Api.install = function(Vue, configs = {}) {
  Api.setConfig(configs);
};

const {
  archive: archiveApi,
  aueng: auengApi,
  udp: udpApi,
  ote: oteApi,
  orginit: orginitApi,
  cer: cerApi,
  core: qidaApi,
  common: commonApi,
  cc: ccApi,
  file: fileApi,
  survey: surveyApi,
  place: placeApi,
  te: teApi,
  log: logApi,
  decorate: decorateApi,
  utility: utilityApi,
  msg: msgApi,
  kng: kngApi,
  knglib: knglibApi,
  enroll: enrollApi,
  ssp: sspApi,
  news: newsApi,
  o2o: o2oApi,
  o2orpt: o2orptApi,
  bbs: bbsApi,
  basebbs: basebbsApi,
  bset: bsetApi,
  search: searchApi,
  oms: omsApi,
  support: supportApi,
  IM: imApi,
  orginit: orgInit,
  tcm: tcmApi,
  tlive: tliveApi,
  global: globalApi,
  talent: talentApi,
  notice: noticeApi,
  reward: rewardApi,
  kngdiall: kngdiallApi,
  groupUdp: groupUdpApi,
  shareCenter: shareCenterApi,
  apiAudit: auditCenterApi,
  opcs: opcsApi,
  misc: miscApi,
  shoptour: shoptourApi,
  apiOpcs: apiOpcs,
  apiBaseUrl,
  flip: flipApi,
  hwbase: hwbaseApi,
  ulcd: ulcdApi,
  dmp: dmpApi,
  down: downApi,
  pay: payApi,
  websocketclient: websocketclientApi,
  singularBaseUrl,
  studylog: studylogApi,
  bpaasBaseUrl: bpaasBaseApi,
  scApiBaseUrl,
  rpt2: rpt2Api,
  speval: spevalApi
} = http;

export {
  http,
  archiveApi,
  auengApi,
  udpApi,
  oteApi,
  orginitApi,
  cerApi,
  qidaApi,
  commonApi,
  ccApi,
  rpt2Api,
  fileApi,
  surveyApi,
  placeApi,
  teApi,
  logApi,
  decorateApi,
  utilityApi,
  msgApi,
  kngApi,
  knglibApi,
  enrollApi,
  sspApi,
  newsApi,
  o2oApi,
  o2orptApi,
  bbsApi,
  basebbsApi,
  bsetApi,
  searchApi,
  omsApi,
  supportApi,
  imApi,
  config,
  envNow,
  getMedia,
  orgInit,
  tcmApi,
  tliveApi,
  globalApi,
  rewardApi,
  captchaId,
  getDomains,
  getNavIcoPath,
  talentApi,
  noticeApi,
  kngdiallApi,
  groupUdpApi,
  shareCenterApi,
  auditCenterApi,
  opcsApi,
  miscApi,
  shoptourApi,
  apiOpcs,
  apiBaseUrl,
  privateContactAdapter,
  flipApi,
  hwbaseApi,
  ulcdApi,
  dmpApi,
  downApi,
  configGovern,
  payApi,
  websocketclientApi,
  emptyInstance,
  singularBaseUrl,
  studylogApi,
  bpaasBaseApi,
  scApiBaseUrl,
  spevalApi
};

export default Api;
