var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "yxtbiz-tcm-select",
    },
    [
      _c(
        "div",
        { staticClass: "yxtbiz-tcm-select__search" },
        [
          _c("yxt-input", {
            staticClass: "yxtbiz-tcm-select__searchinput",
            attrs: {
              placeholder: _vm.$t("pc_biz_tcm_tip_search"),
              searchable: "",
              maxlength: "50",
            },
            on: { search: _vm.searchName },
            model: {
              value: _vm.courseName,
              callback: function ($$v) {
                _vm.courseName = $$v
              },
              expression: "courseName",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "yxt-table",
            {
              ref: "tcmSelectList",
              staticClass: "yxtbiz-tcm-select__table",
              attrs: {
                loading: _vm.loading,
                data: _vm.tcmList,
                "row-key": _vm.getRowKey,
                virtual: true,
                rowHeight: 100,
                "row-style": _vm.rowStyle,
              },
              on: {
                "row-click": _vm.rowClick,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              !_vm.singleMode
                ? _c("yxt-table-column", {
                    attrs: {
                      type: "selection",
                      "reserve-selection": true,
                      width: "55",
                    },
                  })
                : _vm._e(),
              _c("yxt-table-column", {
                attrs: {
                  prop: "name",
                  label: _vm.$t("pc_biz_tcm_documentname"),
                  "show-overflow-tooltip": false,
                  "min-width": "385px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm.singleMode
                          ? _c(
                              "div",
                              {
                                staticClass: "yxtbiz-tcm-select__single-select",
                              },
                              [
                                _c("yxt-radio", {
                                  attrs: { label: row.id, hideLabel: true },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.radioClick(row.id, row)
                                    },
                                  },
                                  model: {
                                    value: _vm.choose,
                                    callback: function ($$v) {
                                      _vm.choose = $$v
                                    },
                                    expression: "choose",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "yxtbiz-tcm-select__covers" },
                          [
                            _c("yxt-image", {
                              staticClass: "yxtbiz-tcm-select__covers-image",
                              attrs: { src: row.courseCover, fit: "cover" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "yxtbiz-tcm-select__filename" },
                          [
                            _c(
                              "yxt-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "light",
                                  "open-filter": true,
                                  "max-width": "400",
                                  content: row.courseName,
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "yxtbiz-tcm-select__name" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row.courseName) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("yxt-table-column", {
                attrs: {
                  label: _vm.$t("pc_biz_tcm_status"),
                  prop: "courseStatus",
                  "show-overflow-tooltip": false,
                  width: "108px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", {
                          class: [
                            "yxtbiz-tcm-select__dot" + row.courseStatus,
                            "yxtbiz-tcm-select__dot",
                          ],
                        }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.getStatusName(row.courseStatus))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("yxt-table-column", {
                attrs: {
                  prop: "duration",
                  label: _vm.$t("pc_biz_tcm_duration"),
                  "show-overflow-tooltip": true,
                  width: "118px",
                  formatter: _vm.durationFormatter,
                },
              }),
              _c("yxt-table-column", {
                attrs: {
                  prop: "teacherName",
                  label: _vm.$t("pc_biz_tcm_lecturer"),
                  "show-overflow-tooltip": true,
                  width: "98px",
                  "popover-html": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.isOpenData
                          ? _c("yxtbiz-user-name", {
                              attrs: {
                                name:
                                  scope.row.userName === "" ||
                                  !scope.row.userName
                                    ? "--"
                                    : scope.row.userName,
                              },
                            })
                          : _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  `${
                                    scope.row.userName === "" ||
                                    !scope.row.userName
                                      ? "--"
                                      : scope.row.userName
                                  }`
                                ),
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.paging.pages > 1
            ? _c("yxt-pagination", {
                staticClass: "mt12 text-right",
                attrs: {
                  "page-size": _vm.paging.limit,
                  total: _vm.paging.count,
                  "current-page": _vm.paging.current,
                  "simple-total": "",
                  layout: "total, prev, pager, next",
                },
                on: { "current-change": _vm.handlePageChange },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }