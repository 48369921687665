import {
  GetUserInfo,
  GetIsManage,
  getUnReadCnt
} from './service';
import { navUtil } from './userNavs';
import { getGroupOrgInfo } from '../../../group-org-select/src/service';
import { trackError } from './common';
import { getOrgpParameter } from 'yxt-biz-pc/packages/common-util/service';
import { defaultProductAllPath } from './const';
const types = {
  SET_NAVS: 'SET_NAVS',
  SET_USERINFO: 'SET_USERINFO',
  SET_PATH: 'SET_PATH',
  SET_PAGECODE: 'SET_PAGECODE',
  SET_MESSSAGE_COUNT: 'SET_MESSSAGE_COUNT',
  SET_SCHEDULES: 'SET_SCHEDULES',
  SET_PAGECODES: 'SET_PAGECODES',
  SET_COLLAPSE: 'SET_COLLAPSE',
  SET_NAVTYPE: 'SET_NAVTYPE',
  SET_SETLOGO: 'SET_SETLOGO',
  SET_TOPNAVINDEX: 'SET_TOPNAVINDEX',
  SET_UNREAD_NOTICE_CNT: 'SET_UNREAD_NOTICE_CNT',
  SET_NAV_LEFT_LIST: 'SET_NAV_LEFT_LIST',
  SET_WORKBENCHNAVLIST: 'SET_WORKBENCHNAVLIST',
  SET_HIDELOGOUT: 'setHideLogout'
};
const maxLevel = 4; // 当前支持的最大链路层级
// 虚拟的应用主导航
let navApp = {
  id: '_app_all',
  name: '全部应用',
  enName: 'All applications',
  haName: '全部應用',
  appId: '',
  appType: 0,
  associationType: 0,
  catalogId: null,
  code: '_app_all_code',
  functionCode: '_app_all_code',
  keywords: null,
  navIcon: null,
  navId: '',
  navSelecticon: null,
  openMode: 0,
  pageType: 1,
  pageUrl: '',
  parentId: '',
  pointActions: [],
  showed: 1,
  subMenuInfoList: []
};

const errorKey = [
  'global.token.invalid',
  'global.token.empty',
  'global.user.kicked.out',
  'global.user.kicked.app.out',
  'global.user.kicked.pc.out',
  'global.user.kicked.h5.out'
];

const state = {
  navType: 2, // 导航类型 1：pc学员端，2pc管理员，3移动端导航 4boss平台
  logo: window.localStorage.logoUrl, // 网站logo
  nav: null, // 当前导航
  cnavs: [], // 当前导航层级
  navList: [], // 当前类型导航所有导航（列表）
  workbenchFunctionCode: 'modul_workbench', // 工作台的功能编号
  workbenchNavList: [], // 工作台的导航
  navs: {
    id: '',
    appType: 0,
    code: '',
    name: '',
    enName: '',
    haName: '',
    functionCode: '',
    keywords: '',
    navIcon: '',
    openMode: 0,
    pageType: 0,
    pageUrl: '',
    parentId: '',
    showed: 1,
    enableAppMenus: []
  }, // 当前类型导航所有导航（树）
  mainPathname: '',
  userInfo: {},
  messageCount: 0,
  schedules: {
    'configType': 0,
    'scoreType': 1,
    'deptId': '',
    'deptName': '',
    'electiveConfigScore': 100,
    'fullname': '',
    'imgUrl': '',
    'todayScore': 0,
    'majorConfigScore': 100,
    'monthElectiveScore': 10,
    'monthMajorScore': 10,
    'positionNames': [
      {
        'id': '',
        'name': ''
      }
    ],
    'requireScore': 200,
    'totalElectiveScore': 1000,
    'totalMajorScore': 1000,
    'totalScore': 2000,
    'userId': '',
    'username': '',
    'weekElectiveScore': 10,
    'weekMajorScore': 10,
    'yearElectiveScore': 50,
    'yearMajorScore': 50,
    'TODO_todayhour': 0, // TODO 今日学习
    'point': 0, // TODO 今日积分
    'scoreRank': 1, // 排名
    'isHasWorkbench': 0, // 是否有工作台
    'isHasWorkbenchnew': 1, // TODO 工作台new
    'isManage': 0, // 是否有管理后台权限
    'auditCenter': 0, // 是否有审核中心
    'myTeam': 0, // 是否有我的团队
    'studyMgmt': 0 // 是否有教学中心
  }, // 面板信息 http://api-ssp-phx.yunxuetang.com.cn/v2/doc.html#/%E7%A7%AF%E5%88%86%E5%AD%A6%E5%88%86/%E7%94%A8%E6%88%B7%E5%AD%A6%E5%88%86/selfUserScoreUsingGET
  lastUpdateNavStamp: 0,
  lastUpdateUserStamp: 0,
  // lastUpdateScheduleStamp: 0,
  lastUpdateMessageCountStamp: 0,
  pageCodes: ['', '', ''], // 当前页面编号层级
  pageCode: '', // 当前页面编号
  userInfoLoaded: false,
  pagePermission: true, // 当前导航是否有权限
  collapse: false, // 导航是否收起
  topNavZindex: 0, // 顶部导航层级，用户特殊情况覆盖底下抽屉。0时会设置为默认值100
  unReadNoticeCnt: 0,
  sourceCode: window.localStorage.sourceCode,
  navLeftList: [],
  tabOrgInfo: {}, // 集团版tab导航上选中机构信息,
  groupCorpInfo: {
    enableGroupCorp: false,
    groupCorpId: '',
    groupCorpName: '',
    manageMode: 0,
    manageOrgStatus: 0,
    masterOrgId: '',
    masterOrgStatus: 0
  },
  isHideLogout: 0
};

let isLoadingNav = false;

const getPath = mainPathname => {
  let pathname = mainPathname || state.mainPathname;
  let hash = state.hash ? state.hash : location.hash;

  if (hash) {
    if (hash.split('/').length > 2) {
      hash = hash.split('#/')[1];
      hash = '#/' + hash.substring(0, hash.indexOf('/'));
    }
  }

  pathname =
    pathname.lastIndexOf('/') > 0
      ? pathname.substring(0, pathname.lastIndexOf('/') + 1) + hash
      : pathname;

  return pathname;
};

// 获取当前导航链路，查询当前导航及其父导航、爷导航、、、
export const computePagecodes = pagecode => {
  let codes = ['', '', '']; // [...,爷 ,父 ,当前] 导航编号数组
  let cnav = null; // 当前导航信息
  let cnavs = []; //  [...,爷 ,父 ,当前] 导航信息数组

  getNavLevelInfo(1, state.navs.enableAppMenus);
  function getNavLevelInfo(levelIndex, levelNavs) {
    if (!levelNavs || levelNavs.length === 0 || levelIndex > maxLevel) {
      return false;
    }
    for (let i = 0; i < levelNavs.length; i++) {
      let nav = levelNavs[i];
      let isLevelNav = false; // 是否为当前导航链路中的导航
      if (nav.code === pagecode) {
        cnav = nav;
        isLevelNav = true;
      } else {
        isLevelNav = getNavLevelInfo(levelIndex + 1, nav.subMenuInfoList);
      }

      if (isLevelNav) {
        codes[levelIndex - 1] = nav.code;
        cnavs[levelIndex - 1] = nav;
        return true;
      }
    }
    return false;
  }
  // console.log([codes, cnav, cnavs]);
  return [codes, cnav, cnavs];
};

// 登录
const goLogin = () => {
  if (!window.location.origin) {
    window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  }
  window.location.href = `${defaultProductAllPath}/login.html`;
};

const actions = {
  // 更新导航数据
  getNavs: ({ commit, dispatch }, infos) => {
    isLoadingNav = true;

    let pagecode = infos[0];// 当前导航code
    // 传给组件的假数据
    let navDatas = infos[1];// 预览模式传入的假数据
    let navType = infos[2]; // 导航类型 1-学员端 2-管理端
    // setnav 1
    commit(types.SET_NAVTYPE, navType);

    return new Promise(async(resolve, reject) => {
      if (pagecode) commit(types.SET_PAGECODE, pagecode);

      const pathname = getPath(); // 子项目路由

      if (pathname === '/' || pathname === '/#/index') { // 中转页面不更新数据
        trackError({ tag: 'nav_/_index', pathname });
        return false;
      }

      // 传给组件的假数据，不走API。场景：装修后台、导航管理后台
      if (navDatas) {
        // setnav end
        commit(types.SET_NAVS, navDatas);
        dispatch('computePageCodes');
        resolve();
        return;
      }

      if (navUtil.promiseCatch) {
        console.warn('getNavs promiseCatch');
        await navUtil.promiseCatch;
      }

      navUtil.getNavsAndSetCatch(navType)
        .then((res) => {
          const data = res.data;
          // 类型异常跳出，防止业务使用时类型快速切换，导致导航数据不同步问题
          if (navType !== state.navType) {
            console.warn('getNavs 类型快速切换');
            return;
          }
          // setnav end
          commit(types.SET_NAVS, data);
          // 计算是否有当前导航权限
          dispatch('computePageCodes');
          resolve();
        }).catch((e) => {
          // if (e === 'cancel') return;
          if (e && e.error && errorKey.indexOf(e.error.key) >= 0) {
            trackError({ tag: 'api', errorKey: e.error.key });
            goLogin();
          } else {
            trackError({ tag: 'api_error', message: e && e.message || JSON.stringify(e) });
          }
          isLoadingNav = false;
        });
    });
  },
  computePageCodes({ commit }, pagecode) {
    if (pagecode) {
      commit(types.SET_PAGECODE, pagecode);
    }
    pagecode = pagecode || state.pageCode;
    let codesInfo = computePagecodes(pagecode);
    commit(types.SET_PAGECODES, codesInfo);
  },
  getUserInfo: ({ commit }) => {
    GetUserInfo().then(data => {
      let keys = [
        'learnScores',
        'monthScores',
        'totalScores',
        'userDayPoints',
        'userDayScores',
        'userNeedScores',
        'userPoints',
        'yearScores'
      ];
      for (let key in data) {
        if (keys.indexOf(key) > -1) {
          data[key] = Number(data[key]).toFixed(2);
        }
      }
      commit(types.SET_USERINFO, data);
    });
  },
  setPath: ({ commit }, path) => {
    commit(types.SET_PATH, path);
  },
  setPageCode: ({ commit }, pagecode) => {
    commit(types.SET_PAGECODE, pagecode);
  },
  setCollapse({ commit }, status) {
    commit(types.SET_COLLAPSE, status);
  },
  updateFrameWork: ({ dispatch }, pagecode) => {
    console.error('updateFrameWork api 已废弃');
    if (pagecode) {
      navUtil.clearNavCatch();
      dispatch('getNavs', pagecode);
    } else {
      if (!state.lastUpdateNavStamp && state.pagecode) dispatch('getNavs');
    }
  },
  updateLogo: ({ commit }, url) => {
    commit(types.SET_SETLOGO, url);
  },
  updateTopNavZindex: ({ commit }, zindex) => {
    commit(types.SET_TOPNAVINDEX, zindex);
  },
  getUnreadNoticeCnt: ({ commit }) => {
    getUnReadCnt().then(res=>{
      commit(types.SET_UNREAD_NOTICE_CNT, res.count);
    });
  },
  setGroupCorpInfo: ({ commit }) => {
    return getGroupOrgInfo(localStorage.getItem('orgId')).then((res) => {
      commit('setGroupCorpInfo', res);
      if (res) {
        Object.keys(res).forEach(key => {
          localStorage.setItem(key, res[key]);
        });
      }
    });
  },
  /**
   * 是否隐藏退出登录
   */
  getOrgLogoutSetting: async({ commit })=> {
    const res = await getOrgpParameter('isHideLogout', localStorage.orgId);
    if (res && res.code && res.value) commit(types.SET_HIDELOGOUT, Number(res.value));
  },
  setWorkbenchNavList: ({ commit }, data)=> {
    commit(types.SET_WORKBENCHNAVLIST, data);
  },
  async getIsManagement({ commit }) {
    const { manageFlag = 0 } = await GetIsManage();
    commit(types.SET_SCHEDULES, {
      isManage: manageFlag
    });
  }
};

const mutations = {
  [types.SET_WORKBENCHNAVLIST](state, navs) {
    state.workbenchNavList = navs;
  },
  [types.SET_NAVS](state, navs) {
    let navsList = navs.length > 0 ? navs[0].datas : navs.datas;
    let navsAppList = navs.length > 1 ? navs[1].datas : [];
    let orgId = window.localStorage.orgId;
    let navsState = { // 定义导航树的结构
      shortName: '',
      enableAppMenus: []
    };
    let workbenchNavList = [];

    // 递归树导航结构
    const getNav = function getNav(arrNew, pId, navParent) {
      navsList.forEach(nav => {
        if (
          // 有父节点或者是根结点
          nav.parentId === pId ||
            (!pId && (!nav.parentId || nav.parentId === '0' || nav.parentId === orgId))
        ) {
          // tab子导航判断 pageType为2时挂在tab页上
          if (nav.pageType === 2 && navParent) {
            navParent.isHasTabNav = true;
          }
          nav.subMenuInfoList = []; // 子节点列表
          nav.like = true;
          nav.showLike = [];
          // 开始递归
          getNav(nav.subMenuInfoList, nav.id, nav);

          arrNew.push(nav);

          // 工作台导航
          if (nav && nav.type === 5) {
            // 工作台导航数据
            workbenchNavList.push(nav);
          }
        }
        // 遗留-M5V1特殊隐藏，后续产品设计满足了就能去掉
        if (['Course_interaction_mgmt', 'kng_param_set', 'kng_audit'].indexOf(nav.code) >= 0) {
          nav.showed = 0;
        }
      });
    };

    try {
      getNav(navsState.enableAppMenus, '');
      // m2的管理端导航应用部分单独剥离，没挂在导航组里面。这里单独把他们塞进树形的结构里面
      if (state.navType === 2) {
        // 初始化应用导航，和普通导航保持一致
        navsAppList.forEach(app => {
          app.subMenuInfoList = [];
          app.enName = app.name; // api暂未考虑多语言
          app.haName = app.name;
          app.code = app.id;
          app.functionCode = app.id;
        });
        // 提取应用下的子导航
        for (let index = 0; index < navsState.enableAppMenus.length;) {
          const navNow = navsState.enableAppMenus[index];
          navsAppList.forEach(app => {
            if (app.id === navNow.appId && navNow.associationType === 1) {
              app.subMenuInfoList.push(navNow);
              navsState.enableAppMenus.splice(index, 1);
              index--;
            }
          });
          index++;
        }
        // 将有子导航的应用加载到所有应用下
        navApp.subMenuInfoList = [];
        navsAppList.forEach(app => {
          if (app.subMenuInfoList && app.subMenuInfoList.length > 0) {
            navApp.subMenuInfoList.push(app);
          }
        });
        // 将所有应用挂在主导航组内第二个的位置
        if (navApp.subMenuInfoList && navApp.subMenuInfoList.length > 0) {
          // 模拟的APP导航 只在有子应用导航时才有
          let firstShowIndex = navsState.enableAppMenus.findIndex(i=>i.showed);
          firstShowIndex = firstShowIndex > -1 ? firstShowIndex + 1 : 1;
          if (navApp.subMenuInfoList.length > 3) {
            // APP导航>3个时显示全部应用
            navsState.enableAppMenus.splice(firstShowIndex, 0, navApp);
          } else {
            // APP导航<=3个时显示，将app显示在主导航上
            navApp.subMenuInfoList.forEach(d => { d.showed = true; });
            navsState.enableAppMenus.splice(firstShowIndex, 0, ...navApp.subMenuInfoList);
          }
        }
      }
    } catch (e) {
      console.error(e);
      trackError({ tag: 'set_navs', setNavsError: e && e.message || JSON.stringify(e) });
    }

    state.navList = navsList;
    state.navs = navsState;
    console.log('SET_NAVS lastUpdateNavStamp time now');
    state.lastUpdateNavStamp = new Date().valueOf();
    isLoadingNav = false;

    // 工作台数据登导航加载完毕再写入
    state.workbenchNavList = workbenchNavList;
    if (workbenchNavList.length) {
      state.schedules.isHasWorkbench = 1;
    }

    if (navsState.enableAppMenus.length === 0) {
      trackError({ tag: 'enable_menu', navs });
      // 没有任何导航的权限
      // window.location.href = '/#/no-permission';
      window.location.replace(`${window.location.origin}/default-pages/403.html`); // 不在白名单，跳转到白名单页面
    }
  },
  [types.SET_USERINFO](state, userInfo) {
    userInfo.userDayPoints =
      userInfo.userDayPoints || userInfo.userDayPoint || 0;
    state.userInfo = userInfo;
    state.lastUpdateUserStamp = new Date().valueOf();
    state.userInfoLoaded = true;
  },
  [types.SET_PATH](state, path) {
    state.mainPathname = path;
  },
  [types.SET_PAGECODE](state, pagecode) {
    state.pageCode = pagecode;
    state.pagePermission = true; // 重置权限先
  },
  [types.SET_MESSSAGE_COUNT](state, count) {
    state.messageCount = count;
    state.lastUpdateMessageCountStamp = new Date().valueOf();
  },
  [types.SET_SCHEDULES](state, schedules) {

    for (let key in schedules) {
      if (schedules.hasOwnProperty(key)) {
        state.schedules[key] = schedules[key];
      }
    }
    // state.schedules = schedules;
    // if (!schedules.isNotUpdateStamp) {
    //   state.lastUpdateScheduleStamp = new Date().valueOf();
    // }
  },
  [types.SET_PAGECODES](state, codesInfo) {
    const codes = codesInfo[0];
    state.pageCodes = codes;
    if (!isLoadingNav) {
      let newPer = state.navType === 1 ? !!codes[0] : !!codes[1]; // 权限判断
      if (state.navType === 2 && !codes[1]) {
        newPer = !!codes[0]; // 处理管理端首页没有二级导航
        trackError({ tag: 'manager_no_subnav', navType: state.navType });
      }
      state.pagePermission = newPer;
    }
    state.nav = codesInfo[1];
    state.cnavs = codesInfo[2];
  },
  [types.SET_COLLAPSE](state, status) {
    if (!status && status !== false) {
      state.collapse = !state.collapse;
    } else {
      state.collapse = status;
    }
  },
  [types.SET_NAVTYPE](state, type) {
    state.navType = type;
    state.workbenchNavList = [];
    console.log('SET_NAVTYPE lastUpdateNavStamp 0');
    state.lastUpdateNavStamp = 0;
  },
  [types.SET_SETLOGO](state, logo) {
    state.logo = logo || window.localStorage.logoUrl;
  },
  [types.SET_TOPNAVINDEX](state, zindex) {
    state.topNavZindex = zindex || 100;
  },
  [types.SET_UNREAD_NOTICE_CNT](state, cnt) {
    state.unReadNoticeCnt = cnt || 0;
  },
  [types.SET_NAV_LEFT_LIST](state, list) {
    state.navLeftList = list;
  },
  setTabOrgInfoByGroup(state, data) {
    state.tabOrgInfo = data;
  },
  setGroupCorpInfo(state, data) {
    state.groupCorpInfo = data;
  },
  [types.SET_HIDELOGOUT](state, data) {
    state.isHideLogout = data;
  }
};

const getters = {
  // 验证导航是否有权限
  checkPermission: (state) => (functionCode) => {
    let pms = false;
    state.navList.forEach((nav) => {
      if (nav.code === functionCode) {
        pms = true;
      }
    });
    return pms;
  },
  enableGroupCorp(state) {
    return state.groupCorpInfo.enableGroupCorp;
  },
  isISV(state) {
    return state.sourceCode === '104' || state.sourceCode === '106'; // 是否ISV环境
  }
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
