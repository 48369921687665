<template>
  <div class="checked_list">
    <div class="checked_list_box" v-if="list.length > 0">
      <yxt-scrollbar :fit-height="true" v-if="!virtualRender">
        <!-- <div class="checked_list_box_typeLabel">岗位能力</div> -->
        <div style="height: 100%;">
            <yxt-tag
                v-for="source in list"
                disable-transitions
                @close="handleClose(source)"
                size="mini"
                type="info"
                closable
                class="mb8 tag"
                :key="source.id"
            ><span class="tag_span">{{source.name}}</span></yxt-tag>
        </div>
      </yxt-scrollbar>
      <div style="height: 100%;" v-else>
        <!-- <div class="checked_list_box_typeLabel">岗位能力</div> -->
        <yxtbiz-virtual-list is-scrollbar style="height: 100%;overflow: hidden;" :data-sources="list" data-key="id" :estimate-size="32" :keeps="50" item-tag="div">
          <template slot-scope="{source}">
            <yxt-tag
                disable-transitions
                @close="handleClose(source)"
                size="mini"
                type="info"
                closable
                class="mb8 tag"
                :key="source.id"
            ><span class="tag_span">{{source.name}}</span></yxt-tag>
          </template>
        </yxtbiz-virtual-list>
      </div>
    </div>
    <yxt-empty v-else style="top: calc(50% - 133px);" />
    <!-- <span class="check-list__placeholder" v-if="list.length === 0">{{$t('biz_udp_please_select')}}...</span> -->
    
    <div class="operation">
      <yxt-button
        @click="clear"
        class="font-size-14 color-gray-9 hover-primary-6"
        type='text'
        :disabled="list.length === 0"
      >{{$t('biz_udp_clear_all')}}</yxt-button>
      <div v-if="showLimit" class="limit">{{list.length || ''}}<span v-if="list.length > 0">{{`/${limit}`}}</span><span v-else>{{`0/${limit}`}}</span></div>
    </div>
  </div>
</template>

<script>
import Virtual from '../../../virtual-list';
import { Scrollbar, Empty } from 'yxt-pc';
import CheckListTag from '../../../check-person-range/src/components/CheckListTag';
export default {
  components: {
    [Virtual.name]: Virtual,
    YxtScrollbar: Scrollbar,
    CheckListTag,
    YxtEmpty: Empty
  },
  name: 'CheckedList',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    virtualRender: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: Infinity
    }
  },
  data() {
    return {
    };
  },
  methods: {
    handleClose(tag) {
      this.$emit('close', tag);
    },
    clear() {
      this.$emit('clear');
    }
  },
  computed: {
    showLimit() {
      return this.limit && this.limit !== Infinity;
    }
  }
};
</script>

