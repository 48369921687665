/* Automatically generated by './build/bin/build-entry.js' */
import AbilitySelector from '../../packages/ability-selector/index.js';
import AuthSelector from '../../packages/auth-selector/index.js';
import CertificateSelector from '../../packages/certificate-selector/index.js';
import ExamArrangeSelector from '../../packages/exam-arrange-selector/index.js';
import ExamSelector from '../../packages/exam-selector/index.js';
import QuestionSelector from '../../packages/question-selector/index.js';
import SelectCourse from '../../packages/select-course/index.js';
import SelectKng from '../../packages/select-kng/index.js';
import SelectKngCatalog from '../../packages/select-kng-catalog/index.js';
import SelectNewkng from '../../packages/select-newkng/index.js';
import SelectKngCatelogSource from '../../packages/select-kng-catelog-source/index.js';
import SelectKngSource from '../../packages/select-kng-source/index.js';
import TcmSelect from '../../packages/tcm-select/index.js';
import TcmSelectAc from '../../packages/tcm-select-ac/index.js';
import TeacherSelector from '../../packages/teacher-selector/index.js';
import LiveSelector from '../../packages/live-selector/index.js';
import QueslibPreview from '../../packages/queslib-preview/index.js';
import TutorSelector from '../../packages/tutor-selector/index.js';
import SurveyTemplate from '../../packages/survey-template/index.js';
import ProjectSelector from '../../packages/project-selector/index.js';
import HwkTemplateSelector from '../../packages/hwk-template-selector/index.js';
import SurveySelector from '../../packages/survey-selector/index.js';
import PracticeSelector from '../../packages/practice-selector/index.js';
import commonUtil from '../../packages/common-util';

const components = [
  AbilitySelector,
  AuthSelector,
  CertificateSelector,
  ExamArrangeSelector,
  ExamSelector,
  QuestionSelector,
  SelectCourse,
  SelectKng,
  SelectKngCatalog,
  SelectNewkng,
  SelectKngCatelogSource,
  SelectKngSource,
  TcmSelect,
  TcmSelectAc,
  TeacherSelector,
  LiveSelector,
  QueslibPreview,
  TutorSelector,
  SurveyTemplate,
  ProjectSelector,
  HwkTemplateSelector,
  SurveySelector,
  PracticeSelector
];

components.forEach(Component => {
  Component.mixins = Component.mixins || [];
  Component.mixins.push({
    created() {
      // 业务组件埋点统计
      // from：组件名
      // aspect：事件发生描述
      // version：组件库版本
      window.YxtFeLog && window.YxtFeLog.track('e_component', {
        properties: {
          from: Component.name,
          aspect: 'load',
          version: '1.17.14'
        }
      });
    }
  });
});

const setStaticCdnUrl = function(Vue) {
  try {
    if (Vue) {
      const baseCommon = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common);
      Vue.prototype.$imagesBaseUrl = (baseCommon && window.feConfig.common.imagesBaseUrl) || 'https://images.yxt.com/';
      Vue.prototype.$staticBaseUrl = (baseCommon && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/';
      Vue.prototype.$isChinese = ['zh', 'ha'].includes(commonUtil.getLanguage());
    }
  } catch (e) {
    console.log(e);
  }
};

var installed = false;
const install = function(Vue, config = {}) {
  setStaticCdnUrl(Vue);

  if (installed) return;
  installed = true;
  components.forEach(component => {
    Vue.component(component.name, component);
  });
};

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

const allModule = {
  AbilitySelector,
  AuthSelector,
  CertificateSelector,
  ExamArrangeSelector,
  ExamSelector,
  QuestionSelector,
  SelectCourse,
  SelectKng,
  SelectKngCatalog,
  SelectNewkng,
  SelectKngCatelogSource,
  SelectKngSource,
  TcmSelect,
  TcmSelectAc,
  TeacherSelector,
  LiveSelector,
  QueslibPreview,
  TutorSelector,
  SurveyTemplate,
  ProjectSelector,
  HwkTemplateSelector,
  SurveySelector,
  PracticeSelector
};

const exportModule = {
  version: '1.17.14',
  install,
  ...allModule
};

if (window.YXTBIZ) {
  Object.assign(window.YXTBIZ, allModule);
}

export default exportModule;
