import { teApi } from 'yxt-biz-pc/packages/api';

// 讲师列表
export const getTeList = (query) => {
  return teApi.get('/external/te/select', { params: query });
};

// 获取讲师分类列表
export const getCatalogList = () => {
  return teApi.get('/te/catalog/list/select');
};

// 获取讲师选择的类型
export const GetTeChooseType = () => {
  return teApi.get('/externalfacade/o2o/choose/type');
};

// 获取讲师级别
export const getTeLevelList = (showNoClassification) => {
  return teApi.get(`level/list?showNoClassification=${showNoClassification || ''}`);
};

// 获取讲师分类【showNoClassification", value = "是否展示无分类 0不展示 1展示】
export const getGroupTeLevelList = (query) => {
  return teApi.get('group/level/list', { params: query});
};

// 讲师列表
export const getOfflineTeList = (query) => {
  return teApi.get('/external/offline/te/select', { params: query });
};

// 报表专用讲师列表
export const getRptTeList = (query) => {
  return teApi.post('/compo/selection', query);
};
